import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { Avatar, useTheme } from '@material-ui/core'

import { NxEmote, FB } from '@playvs-inc/nexus-components'

import {
  MaddenPlayedWinRateFragment,
  NbaPlayedWinRateFragment,
  PlayedWinRate,
} from '@plvs/graphql'
import { StatsAvatarWrapper } from './RosterRowFilledStats.helpers'
import { useStyles } from './RosterRowFilledStats.styles'

export type StatsAvatarProps = (
  | PlayedWinRate
  | MaddenPlayedWinRateFragment
  | NbaPlayedWinRateFragment
) & {
  assetUri?: string | null
  avatar?: React.ReactElement
  title?: string
}

export const StatsAvatar: React.FC<StatsAvatarProps> = ({
  name,
  played,
  winRate,
  assetUri,
  avatar,
  title,
}) => {
  const theme = useTheme()
  const styles = useStyles()

  const showEasterEgg = winRate >= 80 && played > 3

  return (
    <StatsAvatarWrapper
      tooltipTitle={
        <Box alignItems="center" display="flex" gridGap={theme.spacing(1)}>
          <Box>
            {title && <Box>{title}</Box>}
            <Box>{name}</Box>
            <Box>
              {played} {played > 1 ? 'Games' : 'Game'}
            </Box>
            <Box>{winRate}% Win Rate</Box>
          </Box>

          {showEasterEgg && (
            <NxEmote emoteKey={FB.Shock} height={30} shouldAnimate width={30} />
          )}
        </Box>
      }
    >
      {avatar ? (
        <Avatar className={styles.statsAvatar}>{avatar}</Avatar>
      ) : (
        <Box>{assetUri && <img alt={name} height={32} src={assetUri} />}</Box>
      )}
    </StatsAvatarWrapper>
  )
}
