import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import { Firebob, HamburgerMenu } from '@playvs-inc/nexus-icons'
import { SponsorshipImageName } from '@plvs/const'
import { useProductTypeFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { CompetitionGroup } from '@plvs/graphql/generated'
import { ProductType } from '@plvs/client-data/models'

const useStyles = makeStyles((theme) => ({
  hamburgerMenu: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  hamburger: {
    filter: theme.palette.type === 'dark' ? 'invert(1)' : '',
  },
  fireBob: {
    height: theme.spacing(4.4),
    width: theme.spacing(4.4),
    marginLeft: theme.spacing(1.3) * -1,
  },
  campaignLogo: {
    maxHeight: 48,
  },
  scholasticLogo: {
    marginLeft: theme.spacing(1),
  },
}))

export const MobileMenuIcon: React.FC = () => {
  const classes = useStyles()
  const productType = useProductTypeFn()
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
  const { activeCampaign, orgCompetitionGroup } = useUserIdentityFn()
  const msLogo = (
    <img
      alt="middle-school-logo"
      className={classes.scholasticLogo}
      data-testid="ms-logo"
      height={34}
      src={
        isDarkMode
          ? 'https://s3.amazonaws.com/assets.playvs.com/PlayVS/MSLogo_Dark.svg'
          : 'https://s3.amazonaws.com/assets.playvs.com/PlayVS/MSLogo_Light.svg'
      }
    />
  )
  const hsLogo = (
    <img
      alt="high-school-logo"
      className={classes.scholasticLogo}
      data-testid="hs-logo"
      height={34}
      src={
        isDarkMode
          ? 'https://s3.amazonaws.com/assets.playvs.com/PlayVS/HSLogo_Dark.svg'
          : 'https://s3.amazonaws.com/assets.playvs.com/PlayVS/HSLogo_Light.svg'
      }
    />
  )
  const stadiumLogo = (
    <img
      alt="stadium-logo"
      className={classes.scholasticLogo}
      data-testid="stadium-logo"
      height={24}
      src="https://s3.amazonaws.com/assets.playvs.com/stadium/StadiumLogo.webp"
    />
  )
  const baseHambuger = (
    <img
      alt="hamburger-logo"
      className={classes.hamburger}
      data-testid="hamburger-logo"
      height={24}
      src="https://s3.amazonaws.com/assets.playvs.com/PlayVS/HamburgerMenu.svg"
    />
  )
  const renderIcon = (): React.ReactNode => {
    const lightLogo = activeCampaign?.sponsorshipImages?.find(
      (i) => i.name === SponsorshipImageName.MobileBannerLogo
    )
    const darkLogo = activeCampaign?.sponsorshipImages?.find(
      (i) => i.name === SponsorshipImageName.DarkModeMobileBannerLogo
    )
    const baseLogo = isDarkMode && darkLogo ? darkLogo : lightLogo
    if (baseLogo) {
      return (
        <img
          alt="campaign-logo"
          className={classes.campaignLogo}
          data-testid="campaign-logo"
          src={baseLogo.url}
        />
      )
    }

    return (
      <Firebob
        className={classes.fireBob}
        color={theme.palette.ColorBackgroundAccent}
        data-testid="firebob"
      />
    )
  }

  const renderProductIcon = (): React.ReactNode => {
    if (orgCompetitionGroup === CompetitionGroup.MiddleSchool) {
      return (
        <>
          {baseHambuger}
          {msLogo}
        </>
      )
    }

    // @TODO MATCH-7381 This should go in a render controller.
    if (
      productType === ProductType.Stadium ||
      orgCompetitionGroup === CompetitionGroup.Stadium
    ) {
      return (
        <>
          {baseHambuger}
          {stadiumLogo}
        </>
      )
    }

    return (
      <>
        {baseHambuger}
        {hsLogo}
      </>
    )
  }

  return (
    <Box alignItems="center" display="flex" flexDirection="row">
      {activeCampaign ||
      (!orgCompetitionGroup && productType !== ProductType.Stadium) ? (
        <>
          <HamburgerMenu className={classes.hamburgerMenu} />
          {renderIcon()}
        </>
      ) : (
        <>{renderProductIcon()}</>
      )}
    </Box>
  )
}
