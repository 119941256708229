import { makeStyles, MenuItem, TextField } from '@material-ui/core'
import { stateAbbrs, stateNames } from '@plvs/const'
import { Box } from '@plvs/respawn/features/layout/Box'
import React, { ChangeEvent } from 'react'

const useStyles = makeStyles((theme) => ({
  stateSelector: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    border: `1px solid ${theme.palette.BorderLight}`,
    borderRadius: theme.shape.borderRadius,
  },
  menuItem: {
    color: theme.palette.ColorTextBase,
  },
}))

interface CoachLeadStateSelectorProps {
  selectedAmericanState: string
  setAmericanState: (state: string) => void
}

export const CoachLeadStateSelectorComponent: React.FC<CoachLeadStateSelectorProps> = React.forwardRef(
  (props, ref) => {
    const { setAmericanState, selectedAmericanState } = props
    const classes = useStyles()

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
      setAmericanState((e.target.value as unknown) as string)
      e.stopPropagation()
    }

    return (
      <Box>
        <TextField
          fullWidth
          inputProps={{ className: classes.stateSelector }}
          inputRef={ref}
          label="State*"
          name="state"
          onChange={handleChange}
          select
          SelectProps={{
            MenuProps: {
              PaperProps: { style: { maxHeight: 400 } },
            },
          }}
          value={selectedAmericanState}
          variant="filled"
        >
          {Object.entries(stateNames)
            .filter(([abbr]) => {
              return stateAbbrs.some((state) => state === abbr)
            })
            .map(([abbr, name]) => (
              <MenuItem key={abbr} className={classes.menuItem} value={abbr}>
                {name}
              </MenuItem>
            ))}
        </TextField>

        {/* 
        Will switch to this once autocomplete dropdown selection is create in nexus GP-3135
        <NxSelect
          ref={ref}
          defaultValue={selectedAmericanState}
          fullWidth
          label="State*"
          name="state"
          onChange={handleChange}
          style={{ height: '54px' }}
        >
          {Object.entries(stateNames)
            .filter(([abbr]) => {
              return stateAbbrs.some((state) => state === abbr)
            })
            .map(([abbr, name]) => (
              <NxSelectOption key={abbr} value={abbr}>
                {name}
              </NxSelectOption>
            ))}
        </NxSelect> */}
      </Box>
    )
  }
)
