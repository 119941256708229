import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Autocomplete } from '@material-ui/lab'

import {
  NxButton,
  NxTypography,
  NxFirebobMessage,
} from '@playvs-inc/nexus-components'

import {
  GameAssistantSelection,
  useGetCharacterSelectionOptionsByEsportIdQuery,
  useSubmitGameAssistantStepSelectionsMutation,
} from '@plvs/graphql'
import {
  sortByDisplayName,
  SelectionOption,
  FirebobMessage,
  createPlayerObject,
  getPickForGameOrdinal,
  PlayerSelection,
} from '@plvs/utils'
import { logger } from '@plvs/rally/logging'

import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { SelectedCharacters } from '@plvs/rally/features/match/assistant/steps/smash/components/selectedCharacters/SelectedCharacters'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantContext } from '../../../useMatchAssistant'

export const TeamSelect: React.FC = () => {
  const {
    isMyTurn,
    currentStep,
    gameOrdinal,
    match,
    setActionsTakenInSteps,
    actionsTakenInSteps,
    teamId: myTeamId,
    myTeam,
    esportSlug,
    isMyTeamHome,
  } = useMatchAssistantContext()
  const { id: stepId = '' } = currentStep?.step ?? {}

  const {
    match: { canParticipate, canSpectate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const [hasSubmitted, setHasSubmitted] = useState(false)

  const {
    data: characterData,
    loading: characterDataLoading,
  } = useGetCharacterSelectionOptionsByEsportIdQuery({
    variables: {
      input: match?.esport?.id ?? '',
    },
    skip: !match?.esport?.id,
  })

  const [
    submitGameAssistantStep,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const selectionOptions = characterData?.characterSelectionOptionsByEsportId?.selectionOptions.find(
    (option) => option.gameOrdinal === gameOrdinal
  )
  const sortedOptions = sortByDisplayName(
    selectionOptions?.selectionOptions
  ) as SelectionOption[]

  const [selectedTeam, setSelectedTeam] = useState<SelectionOption>()

  useEffect(
    function clearSelections() {
      setSelectedTeam(undefined)
    },
    [currentStep?.step?.id]
  )

  const isNextDisabled = !selectedTeam || canSpectate || updateLoading

  const handleNext = async (): Promise<void> => {
    if (isNextDisabled) {
      return
    }

    setActionsTakenInSteps({
      ...actionsTakenInSteps,
      [stepId]: new Date().toISOString(),
    })

    const selectedOptions = [selectedTeam].filter((selection) =>
      Boolean(selection)
    )

    logger.debug('[TeamSelect | handleNext]', selectedOptions)

    try {
      await submitGameAssistantStep({
        variables: {
          input: {
            stepId,
            selectionOptions: selectedOptions.map((option) => ({
              selectionOptionId: option?.id,
            })),
          },
        },
      })
      setHasSubmitted(true)
    } catch (err) {
      logger.error(err)
    }
  }

  const teamPick = getPickForGameOrdinal({
    options: (currentStep?.characterPicks || []) as GameAssistantSelection[],
    teamId: myTeamId,
    gameOrdinal: currentStep?.step?.gameOrdinal,
  })
  const selectedPlayerObj: PlayerSelection = createPlayerObject({
    team: { name: myTeam?.name, logoUrl: myTeam?.avatarUrl },
    selectionOption: teamPick?.selectionOption || selectedTeam,
  })

  const isWaitingForOpponent = (canParticipate && !isMyTurn) || hasSubmitted
  const isSelectDisabled =
    canSpectate || updateLoading || isWaitingForOpponent || hasSubmitted

  const firebobMessage = canParticipate
    ? `${FirebobMessage.TeamSelect} ${gameOrdinal}`
    : FirebobMessage.Spectator

  const getAutocompleteProps = ({
    label,
    onChange,
    options,
  }: {
    label: string
    onChange: React.Dispatch<React.SetStateAction<SelectionOption | undefined>>
    options: SelectionOption[]
  }): Record<string, unknown> => ({
    disabled: isSelectDisabled,
    fullWidth: true,
    getOptionLabel: (option): string => option.displayName,
    renderOption: (params): React.ReactElement => {
      return <NxTypography variant="body1">{params.displayName}</NxTypography>
    },
    renderInput: (params): React.ReactElement => (
      <TextField {...params} label={label} variant="outlined" />
    ),
    onChange: (
      _event: React.ChangeEvent<unknown>,
      value: SelectionOption | null
    ): void => {
      onChange(value || undefined)
    },
    options,
  })

  return (
    <WaitTillLoaded
      loading={characterDataLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <Box mb={3}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore Getting needed props from ...spread */}
        <Autocomplete
          data-cy="team-select"
          defaultValue={teamPick?.selectionOption}
          {...getAutocompleteProps({
            label: 'Search NBA Teams',
            onChange: setSelectedTeam,
            options: sortedOptions,
          })}
        />
      </Box>

      <SelectedCharacters
        awayPlayer={!isMyTeamHome ? selectedPlayerObj : undefined}
        esportSlug={esportSlug}
        hideBlindPick
        homePlayer={isMyTeamHome ? selectedPlayerObj : undefined}
      />

      <MatchAssistantFooter>
        <NxFirebobMessage message={firebobMessage} />

        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        {canParticipate && (
          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label="Confirm Team"
            loading={isWaitingForOpponent || updateLoading}
            onClick={handleNext}
            variant="primary"
          />
        )}
      </MatchAssistantFooter>
    </WaitTillLoaded>
  )
}
