import initFacebook from './facebook/initFacebook'
import initIntercom from './intercom/initIntercom'
import initLinkedin from './linkedin/initLinkedin'
import initSegment from './segment/initSegment'
import initSnapchat from './snapchat/initSnapchat'

/*
Initialization  Dev|QA |Prod
----------------------------
Facebook        OFF|OFF|ON
Linkedin        OFF|OFF|ON
Segment         OFF|ON |ON
Sentry          OFF|ON |ON
Snapchat        OFF|OFF|ON

Type of key     Dev|QA |Prod
----------------------------
Facebook        PRD|PRD|PRD
Linkedin        PRD|PRD|PRD
Segment         DEV|DEV|PRD
Sentry          DEV|STG|PRD
Snapchat        PRD|PRD|PRD

Integrated via Segment
---------------------------
https://app.segment.com/playvs/overview

- Amplitude
- AutopilotHQ
- FullStory
- GA
- HEAP
- Intercom
- Zopim
*/

interface InitAnalyticsIds {
  facebookPixelId?: string
  intercomKey?: string
  linkedinPixelId?: string
  segmentKey?: string
  segmentSource: 'checkpoint' | 'rally' | 'stadium'
  isProduction: boolean
  snapchatPixelId?: string
}

export default ({
  facebookPixelId,
  intercomKey,
  linkedinPixelId,
  segmentKey,
  segmentSource,
  isProduction,
  snapchatPixelId,
}: InitAnalyticsIds): void => {
  // allow different environments to easily control initialization
  if (linkedinPixelId) initLinkedin(linkedinPixelId)
  if (intercomKey) initIntercom(intercomKey)
  if (facebookPixelId) initFacebook(facebookPixelId)
  if (segmentKey) initSegment(segmentKey, segmentSource, isProduction)
  if (snapchatPixelId) initSnapchat(snapchatPixelId)
}
