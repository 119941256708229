import React from 'react'

import { defaultTo0, isGameActive, isMatchActive } from '@plvs/utils'
import { MatchHeroCountdownContainer } from '@plvs/rally/components/match'
import {
  EsportSlug,
  GameStatus,
  MatchFormat,
  MatchStatus,
  PhaseType,
  useGetMatchPhaseTypeAndScheduleStartsAtQuery,
  useGetMatchScoreForHeaderQuery,
} from '@plvs/graphql'
import { VsMatchFaceoff } from '@plvs/rally/components/match/faceoff/VsMatchFaceoff'
import { reject } from 'ramda'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { HeroMatch } from '@plvs/respawn/features/match-lobby/types/Lobby.types'
import { Polling } from '@plvs/const'
import { LeaderboardMatchFaceoff } from '@plvs/rally/components/match/faceoff/LeaderboardMatchFaceoff'
import { noop } from 'ramda-adjunct'

interface MatchHeroDetailsContainerProps {
  match: HeroMatch
  countdown?: string
  esportSlug: EsportSlug | null | undefined
  mobile: boolean
  size: 'small' | 'medium' | 'large'
  isSmallBreakpoint: boolean
  breadcrumbHref?: string
}

export const MatchHeroDetailsContainer: React.FC<MatchHeroDetailsContainerProps> = ({
  countdown,
  esportSlug,
  match,
  mobile,
  size,
  isSmallBreakpoint,
  breadcrumbHref,
}) => {
  const { bestOf, seriesBestOf } = match

  const { data, loading } = useGetMatchPhaseTypeAndScheduleStartsAtQuery({
    variables: { matchId: match.id },
    skip: !match.id,
  })

  const includeHeadToHead = match.matchFormat === MatchFormat.HeadToHead

  const {
    data: matchScoreData,
    loading: matchScoreDataLoading,
    startPolling,
    stopPolling,
    refetch,
  } = useGetMatchScoreForHeaderQuery({
    variables: {
      matchId: match.id,
      includeHeadToHead,
    },
    skip: !match.id,
  })

  const status = matchScoreData?.match?.status
  const myPlacingRaw = defaultTo0(
    matchScoreData?.match?.teamContext?.myTeamMatchResults?.[0]?.placing
  )
  const renderedMyPlacing = myPlacingRaw > 0 ? myPlacingRaw : undefined

  const matchResults = matchScoreData?.match?.matchResults
  const homeTeamId = matchResults?.find((x) => x.teamSide === 0)?.teamId ?? ''
  const awayTeamId = matchResults?.find((x) => x.teamSide === 1)?.teamId ?? ''

  const games = data?.match?.games ?? []

  const acceptedGames = reject(
    (game) => game.status === GameStatus.Cancelled,
    games
  )

  const activeIndex = acceptedGames.findIndex((game) => isGameActive(game))

  const isPreseasonMatch =
    data?.match?.slot?.phase?.type === PhaseType.Preseason

  const isPrematch = status === MatchStatus.Scheduled
  const isScrimmage = Boolean(data?.match?.isScrimmage)

  const refetchStatus = async (): Promise<void> => {
    await refetch({ matchId: match.id })
  }

  React.useEffect(() => {
    const matchIsActive = status ? isMatchActive(status) : false

    if (matchIsActive) {
      startPolling(Polling.Slow)
    }
    if (status === MatchStatus.Completed) {
      refetch({ matchId: match.id })
    }
    return () => {
      stopPolling()
    }
  }, [status])

  const renderMatchFaceoffMap = {
    [MatchFormat.HeadToHead]: () => (
      <VsMatchFaceoff
        awayTeamId={awayTeamId}
        bestOf={match.bestOf}
        esportSlug={esportSlug}
        games={acceptedGames}
        homeTeamId={homeTeamId}
        isPreseason={isPreseasonMatch}
        isScrimmage={isScrimmage}
        isSmallBreakpoint={isSmallBreakpoint}
        matchId={match.id}
        mobile={mobile}
        prematch={isPrematch}
        size={size}
        team1Score={
          matchScoreData?.match?.headToHeadMatchScores?.team1Score ?? 0
        }
        team2Score={
          matchScoreData?.match?.headToHeadMatchScores?.team2Score ?? 0
        }
        winningTeamId={matchScoreData?.match?.winningTeamId ?? ''}
      />
    ),
    [MatchFormat.Leaderboard]: () => (
      <LeaderboardMatchFaceoff
        isPreseason={isPreseasonMatch}
        isScrimmage={isScrimmage}
        isSmallBreakpoint={isSmallBreakpoint}
        mobile={mobile}
        myPlacing={renderedMyPlacing}
        prematch={isPrematch}
      />
    ),
  }

  const getFaceoffComponent = match.matchFormat
    ? renderMatchFaceoffMap[match.matchFormat]
    : noop

  return (
    <WaitTillLoaded loading={loading || matchScoreDataLoading}>
      <MatchHeroCountdownContainer
        bestOf={bestOf}
        breadcrumbHref={breadcrumbHref}
        countdown={countdown}
        liveLabel={`Game ${activeIndex + 1} of ${bestOf}`}
        matchId={match.id}
        mobile={mobile}
        refetchMatchStatus={refetchStatus}
        seriesBestOf={seriesBestOf}
        size={size}
        status={status}
      >
        {getFaceoffComponent()}
      </MatchHeroCountdownContainer>
    </WaitTillLoaded>
  )
}
