import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'
import { Path } from '@plvs/const'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useProductTypeFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import { ProductType } from '@plvs/client-data/models'
import { AppDrawerChatMessagesToggle } from './chatMessages/AppDrawerChatMessagesToggle'
import { ChatDrawer } from './chatMessages/ChatDrawer'
import { AppDrawerNotificationsToggle } from './notifications/AppDrawerNotificationsToggle'
import { NotificationDrawer } from './notifications/NotificationDrawer'
import { AppDrawerNotificationsToggleV2 } from './notifications/AppDrawerNotificationsToggleV2'

const HIDDEN_PATHS = [Path.CreateScrimmage, Path.Enrollment]

const useStyles = makeStyles((theme) => ({
  toggleContainerAppBar: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2.75),
    zIndex: 10,
  },
  toggleContainerPage: ({
    isCheckpoint,
  }: {
    isCheckpoint: boolean
  }): CreateCSSProperties => {
    const marginRight = theme.spacing(3)
    const marginTop = theme.spacing(2)

    return {
      zIndex: 10,
      position: 'absolute',
      marginRight: isCheckpoint ? undefined : marginRight,
      marginTop: isCheckpoint ? theme.spacing(2) : marginTop,
      marginLeft: isCheckpoint ? theme.spacing(2) : undefined,
    }
  },
}))

interface AppDrawerTogglesProps {
  isInAppBar?: boolean
  isCheckpoint?: boolean
  isPublic?: boolean
}

export const AppDrawerToggles: React.FC<AppDrawerTogglesProps> = ({
  isInAppBar,
  isCheckpoint = false,
  isPublic = false,
}) => {
  const flags = useFlags()
  const classes = useStyles({
    isCheckpoint,
  })
  const productType = useProductTypeFn()
  const location = useLocation()
  const isHiddenOnPage = HIDDEN_PATHS.some((path) =>
    location.pathname.includes(path)
  )

  const { hasMultiOrgAccess } = useUserIdentityFn()

  const shouldRenderNotificationsV2 = flags.notificationsSubscription
    ? !isCheckpoint && !hasMultiOrgAccess
    : false

  const notificationsComponent = shouldRenderNotificationsV2 ? (
    <AppDrawerNotificationsToggleV2
      isInAppBar={isInAppBar}
      NotificationDrawer={NotificationDrawer}
    />
  ) : (
    <AppDrawerNotificationsToggle
      isInAppBar={isInAppBar}
      NotificationDrawer={NotificationDrawer}
    />
  )

  const shouldRenderNotificationsComponent =
    productType === ProductType.Scholastic

  return (
    <Box
      className={
        isInAppBar ? classes.toggleContainerAppBar : classes.toggleContainerPage
      }
      display={isHiddenOnPage ? 'none' : 'flex'}
    >
      {isPublic ? null : (
        <>
          <Box pr={1.5}>
            <AppDrawerChatMessagesToggle
              ChatMessageDrawer={ChatDrawer}
              isInAppBar={isInAppBar}
            />
          </Box>
          {shouldRenderNotificationsComponent ? notificationsComponent : null}
        </>
      )}
    </Box>
  )
}
