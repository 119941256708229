import React, { useState, useMemo } from 'react'
import { Grid, Hidden, makeStyles } from '@material-ui/core'
import {
  CompetitionGroup,
  Provider,
  useGetMyProviderAccountsQuery,
  useGetMyVerificationStatusQuery,
  useGetScheduleConsultLinkQuery,
  useGetUsernameSchoolNameQuery,
  useGetUserTasksQuery,
  VerificationStep,
} from '@plvs/graphql'
import { CountryCode, getNow, hasEnrolledTeams } from '@plvs/utils'
import { EmptyPage } from '@plvs/respawn/components/empty'
import {
  Box,
  PageContentGutter,
  useBreakpointXs,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import clsx from 'clsx'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { TaskCards } from '@plvs/rally/features/home/dashboard/taskCards/Tasks'
import { UpcomingMatches } from '@plvs/rally/features/home/dashboard/UpcomingMatchesDashboardComponent/UpcomingMatches'
import { TeamCardsDashboardContainer } from '@plvs/rally/features/home/dashboard/TeamCardsDashboard/TeamCardsDashboardContainer'
import {
  useSelectedOrganizationFn,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import { getCountryFromState } from '@plvs/rally/components/onboard/helpers'
import {
  NxTypography,
  NxPlayVsBanner,
  PersonaImageVariant,
  NxTextLink,
} from '@playvs-inc/nexus-components'

import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Path, SponsorshipComponentName } from '@plvs/const'
import { Navigate, useNavigate } from 'react-router-dom'
import { SponsorshipComponentWrapper } from '@plvs/respawn/features/sponsorship/SponsorshipComponentWrapper'
import { UnverifiedUserAlert } from './UnverifiedUserAlert'
import {
  CoachLeadComponent,
  SchoolPropType,
} from '../coach/coachLeadGen/CoachLeadComponent'
import { StudentDashboardCoachLeadIncentives } from '../coach/coachLeadGen/StudentDashboardCoachLeadIncentives'
import { DashboardConsultationComponent } from './dashboard/DashboardConsultationComponent'
import { CoachEnrollmentWidget } from './CoachEnrollmentWidget'

import DashboardSpinSignup from './dashboard/DashboardSpinSignup'
import { DashboardSchoolBountyComponent } from './dashboard/DashboardSchoolBountyComponent'
import { MissingEsportsConnectedAccount } from './MissingEsportsConnectedAccount'
import { StudentPromotedEventWidget } from './StudentPromotedEventWidget'

const DashboardNewsComponent = React.lazy(
  () => import('./dashboard/DashboardNewsComponent')
)
const DashboardWebinarsComponent = React.lazy(
  () => import('./dashboard/DashboardWebinarsComponent')
)
const DashboardReferralComponent = React.lazy(
  () => import('./dashboard/DashboardReferralComponent')
)

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.OverlayColorTextBase,
    '&:hover': {
      color: theme.palette.OverlayColorTextBase,
      opacity: '90%',
    },
  },
  notificationsContainer: {
    padding: `0px 0px`,
  },
  coachLeadTitle: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  avatar: {
    height: '128px',
    width: '128px',
  },
  plasmicItemTop: {
    padding: theme.spacing(0),
  },
  plasmicItem: {
    padding: theme.spacing(7, 0, 0),
  },
}))

export const Home: React.FC = () => {
  const [visibleTeamsTimeWindow] = useState(getNow({ minuteDifference: -120 }))
  const isMobile = useBreakpointXs()
  const navigate = useNavigate()

  const { esportSlug } = useSchoolLeagueInfoContext()
  const flags = useFlags()

  const {
    data: providerData,
    loading: providersLoading,
  } = useGetMyProviderAccountsQuery()

  const {
    userId,
    isUserPendingVerificationAtOrg,
    isUserDeniedAtOrg,
    isFacultyAtOrg,
    isPlayerAtOrg,
    isCoachAtOrg,
    loading: identityLoading,
    isParent,
  } = useUserIdentityFn()

  const {
    id: orgId,
    competitionGroup: orgCompetitionGroup,
    loading: selectedOrgLoading,
  } = useSelectedOrganizationFn()

  const { data: userAndSchoolData } = useGetUsernameSchoolNameQuery({
    variables: { size: isMobile ? NxAvatarSize.MD : NxAvatarSize.XL },
  })

  const school = userAndSchoolData?.me?.school
  const isUsersSchoolInUnitedStates =
    school &&
    getCountryFromState(school?.state ?? '') === CountryCode.UnitedStates
  const classes = useStyles()

  const { data: userTaskQueueData } = useGetUserTasksQuery({
    skip: !isFacultyAtOrg || !userId,
    fetchPolicy: 'network-only',
  })

  const {
    data: verificationData,
    loading: verificationLoading,
  } = useGetMyVerificationStatusQuery()

  const studentSchoolHasCoaches =
    (!isFacultyAtOrg && userAndSchoolData?.me?.school?.coaches?.length) ||
    isFacultyAtOrg
  const {
    data: scheduleData,
    loading: scheduleLoading,
  } = useGetScheduleConsultLinkQuery({
    variables: {
      schoolId: orgId,
      userId,
      effectiveAt: visibleTeamsTimeWindow,
    },
    skip: !isFacultyAtOrg || !orgId,
    // in case SF integration is down so we can at least safely fallback
    // to completing onboarding.
    errorPolicy: 'ignore',
  })

  const isCollege = orgCompetitionGroup === CompetitionGroup.College
  const shouldDisplayFacultyGettingStartedTasks = isFacultyAtOrg

  const isDocUploadRequired =
    verificationData?.me?.currentVerification?.step ===
    VerificationStep.DocUpload

  const userPendingVerification = (
    <UnverifiedUserAlert
      isCollege={isCollege}
      isDocUploadRequired={isDocUploadRequired}
      isFacOfOrg={isFacultyAtOrg}
      isPendingVerification={
        isUserPendingVerificationAtOrg && !isUserDeniedAtOrg
      }
    />
  )

  const userProviderAccounts = providerData?.me?.userProviderAccounts
  const playerMissingProviderConnection = useMemo(
    () =>
      (isPlayerAtOrg &&
        !!userProviderAccounts &&
        !userProviderAccounts?.length && <MissingEsportsConnectedAccount />) ??
      null,
    [isPlayerAtOrg, userProviderAccounts]
  )

  const tasks = userTaskQueueData?.getUserTasks ?? []
  const hasEnrolled = hasEnrolledTeams({
    teamEnrollments: scheduleData?.findTeamEnrollmentsByUser?.teamEnrollments,
  })

  const consultationScheduleLink = scheduleData?.school?.salesRep?.calendarLink

  const requiresConsultation =
    isFacultyAtOrg && !hasEnrolled && consultationScheduleLink

  const hasSpinAccount = userProviderAccounts?.some(
    (provider) => provider.providerName === Provider.Spin
  )

  const navigateToSchoolActivity = (): void => {
    navigate(Path.MySchoolActivity)
  }

  if (isParent && !identityLoading) {
    return <Navigate to="/app/parent-dashboard" />
  }

  // NOTE PRO-2339 V2: Passing userRoleNames to children components might not render the correct role; symptom of having
  // more then one organization context on a single page. Need product input.
  return (
    <Box data-testid="home">
      <Esport slug={esportSlug}>
        <WaitTillLoaded
          loading={
            verificationLoading ||
            identityLoading ||
            selectedOrgLoading ||
            providersLoading
          }
          renderErrorState={({ error }): React.ReactElement => (
            <EmptyPage subtitle={error.message} />
          )}
          showSpinnerWhileLoading
        >
          <PageContentGutter>
            <NxPlayVsBanner
              avatar={
                <NxUserAvatar
                  avatarUrl={userAndSchoolData?.me?.avatarUrl ?? ''}
                  hashId={userAndSchoolData?.me?.id ?? ''}
                  size={isMobile ? NxAvatarSize.MD : NxAvatarSize.XL}
                />
              }
              size="large"
              subtitle={
                (userAndSchoolData?.me?.school?.name && (
                  <NxTextLink
                    className={classes.subtitle}
                    label={userAndSchoolData?.me?.school?.name}
                    labelVariant={isMobile ? 'body1' : 'subtitle1'}
                    onClick={navigateToSchoolActivity}
                  />
                )) ??
                ''
              }
              title={`Hi ${userAndSchoolData?.me?.firstName}!`}
              variant={PersonaImageVariant.Explore}
              widget={
                isCoachAtOrg ? (
                  <CoachEnrollmentWidget />
                ) : (
                  flags.rallyPlayerStadiumCallout && (
                    <StudentPromotedEventWidget />
                  )
                )
              }
            />
            <Box mt={4}>
              {/* UNIVERSAL DASHBOARD NOTIFICATIONS */}
              <Hidden smDown>{userPendingVerification}</Hidden>

              {playerMissingProviderConnection}
              {shouldDisplayFacultyGettingStartedTasks && tasks.length > 0 && (
                <TaskCards
                  data-testid="Home__FacultyGettingStartedTasks"
                  tasks={tasks}
                  verificationStatus={verificationData}
                />
              )}

              {requiresConsultation || scheduleLoading ? (
                <DashboardConsultationComponent
                  link={consultationScheduleLink ?? ''}
                />
              ) : (
                (studentSchoolHasCoaches || !isUsersSchoolInUnitedStates) && (
                  <UpcomingMatches
                    isCoach={isCoachAtOrg}
                    isPendingVerification={isUserPendingVerificationAtOrg}
                  />
                )
              )}

              <Box mb={4}>
                <SponsorshipComponentWrapper
                  name={SponsorshipComponentName.DashboardBanner}
                />
              </Box>

              {studentSchoolHasCoaches || !isUsersSchoolInUnitedStates ? (
                <TeamCardsDashboardContainer
                  isCoach={isCoachAtOrg}
                  isPendingVerification={isUserPendingVerificationAtOrg}
                />
              ) : (
                <CoachLeadComponent
                  isFaculty={isFacultyAtOrg}
                  school={school as SchoolPropType}
                  sideView={<StudentDashboardCoachLeadIncentives />}
                  source="student-dashboard-coach-lead"
                  title={
                    <Box>
                      <NxTypography
                        className={classes.coachLeadTitle}
                        variant="h1"
                      >
                        Your school needs a coach!
                      </NxTypography>
                      <NxTypography
                        className={classes.coachLeadTitle}
                        variant="h1"
                      >
                        Refer a faculty member below
                      </NxTypography>
                    </Box>
                  }
                />
              )}
            </Box>
            {flags.spinIntegration && isPlayerAtOrg && !hasSpinAccount && (
              <DashboardSpinSignup />
            )}
            <DashboardSchoolBountyComponent isFacultyAtOrg={isFacultyAtOrg} />
            <Box>
              <Grid container>
                <Grid
                  className={classes.plasmicItemTop}
                  id="DashboardNewsComponent"
                  item
                  xs={12}
                >
                  <React.Suspense fallback={<></>}>
                    <DashboardNewsComponent />
                  </React.Suspense>
                </Grid>

                <Grid
                  className={clsx(classes.plasmicItem, 'lazyload')}
                  id="DashboardWebinarComponent"
                  item
                  xs={12}
                >
                  <React.Suspense fallback={<></>}>
                    <DashboardWebinarsComponent />
                  </React.Suspense>
                </Grid>

                <Grid
                  className={classes.plasmicItem}
                  id="ReferralDashboardComponent"
                  item
                  xs={12}
                >
                  <React.Suspense fallback={<></>}>
                    <DashboardReferralComponent />
                  </React.Suspense>
                </Grid>
              </Grid>
            </Box>
          </PageContentGutter>
        </WaitTillLoaded>
      </Esport>
    </Box>
  )
}
