import React from 'react'
import { chain } from 'ramda'
import dayjs from 'dayjs'
import { UserRoleName, useGetLeagueBySlugQuery } from '@plvs/graphql'
import { HeroGutter, TitleHero } from '@plvs/rally/components/hero'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import { Path } from '@plvs/const'
import {
  Box,
  PageContentGutter,
  useBreakpointXs,
} from '@plvs/respawn/features/layout'
import { Grid, makeStyles, Link } from '@material-ui/core'
import { EmptyPage } from '@plvs/respawn/components/empty'
import { StandingsByEsport } from '@plvs/rally/features/standings/StandingsByEsport'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { RouterLink } from '@plvs/respawn/features/route'
import { NxTypography } from '@playvs-inc/nexus-components'
import { LeagueResources } from './LeagueResources'

const useStyles = makeStyles((theme) => ({
  center: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  banner: {
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 10,
    alignItems: 'center',
    backgroundColor: theme.palette.ColorTextInfo,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stateHeader: {
    marginLeft: theme.spacing(1),
  },
}))

export const LeagueOverview: React.FC<{
  slug: string
  isPublicPage: boolean
}> = ({ slug, isPublicPage = true }) => {
  const classes = useStyles()
  const mobile = useBreakpointXs()

  const { data } = useGetLeagueBySlugQuery({
    variables: { slug },
    skip: !slug,
  })
  const league = data?.leagueBySlug
  const seasons = league?.seasons ?? []
  const phases = chain((season) => season.phases ?? [], seasons)
    .filter((phase) => {
      return dayjs().diff(dayjs(phase.startsAt ?? '')) > 0
    })
    .sort((a, b) => dayjs(b.startsAt ?? '').diff(dayjs(a.startsAt ?? '')))
  const phase = phases.shift()
  const totalTeams = phase?.teams?.length ?? 0
  const publicUrl = `${Path.League}/${slug}`
  const season = seasons.find((s) => s.phases?.some((p) => p.id === phase?.id))

  return slug ? (
    <>
      <HeroGutter>
        <MatchImageCard
          esportSlug={league?.esport?.slug}
          topBanner={
            !isPublicPage && (
              <Box className={classes.banner} py={1}>
                <Link
                  className={classes.link}
                  color="inherit"
                  component={RouterLink}
                  to={publicUrl}
                  underline="none"
                >
                  <NxTypography
                    className={classes.stateHeader}
                    color="inherit"
                    variant="button"
                  >
                    View Public Page
                  </NxTypography>
                </Link>
              </Box>
            )
          }
        >
          <Box className={classes.center} p={4}>
            <TitleHero
              subtitle={`${totalTeams} teams`}
              title={league?.displayName ?? 'League Page'}
            />
          </Box>
        </MatchImageCard>
      </HeroGutter>
      <PageContentGutter>
        <Grid container spacing={3}>
          <Grid item xs={mobile ? 12 : 8}>
            {phase ? (
              <Esport slug={league?.esport?.slug}>
                <StandingsByEsport phase={phase} seasonId={season?.id ?? ''} />
              </Esport>
            ) : (
              <EmptyPage
                showIcon
                subtitle="Check back for standings across leagues and teams"
              />
            )}
          </Grid>
          <Grid item xs={mobile ? 12 : 4}>
            <Box mb={3}>
              <LeagueResources
                esportSlug={league?.esport?.slug}
                userRolesNames={[UserRoleName.Player]}
              />
            </Box>
          </Grid>
        </Grid>
      </PageContentGutter>
    </>
  ) : (
    <></>
  )
}
