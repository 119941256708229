import { Grid, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { NxIconButton, NxTypography } from '@playvs-inc/nexus-components'

import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useGetScheduleConsultLinkQuery } from '@plvs/graphql/generated'
import { openIntercom } from '@plvs/respawn/features/analytics/intercom/intercom'
import { ScheduleConsultationModal } from '@plvs/rally/features/home/dashboard/ConsultationModal/ScheduleConsultationModal'
import { getNow } from '@plvs/utils'
import React, { useState } from 'react'
import { Discord, Coach, Phone, Chat, Linkout } from '@playvs-inc/nexus-icons'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '112px',
    backgroundColor: theme.palette.ColorBackgroundBase,
    padding: theme.spacing(2, 2),
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    width: '105px',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginRight: 'unset',
      width: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  itemContainer: {
    display: 'flex',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
  },
  item: {
    width: '100%',
    minWidth: '99px',
    height: '80px',
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  icon: {
    color: theme.palette.ColorIconAlt2,
    height: '20px',
    width: '20px',
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemOffSet: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
  },
}))

export const TalkToSomeoneContainer: React.FC = () => {
  const classes = useStyles()

  const { isFacultyAtOrg, userId, orgId } = useUserIdentityFn()
  const [visibleTeamsTimeWindow] = useState(getNow({ minuteDifference: -120 }))

  const { data: consultData } = useGetScheduleConsultLinkQuery({
    variables: {
      schoolId: orgId ?? '',
      userId,
      effectiveAt: visibleTeamsTimeWindow,
    },
    skip: !isFacultyAtOrg || !orgId,
    errorPolicy: 'ignore',
  })

  const consultationLink =
    consultData?.school?.salesRep?.calendarLink ?? undefined

  const handleLinkInNewTab = (link: string): void => {
    window.open(link, '_blank', 'noreferrer')
    window?.focus()
  }

  const [isConsultationOpen, setIsConsultationOpen] = useState(false)

  const handleOnOpenConsultation = (): void => {
    setIsConsultationOpen(true)
  }
  const handleOnCloseConsultation = (): void => {
    setIsConsultationOpen(false)
  }

  const mdGridSpacing = consultationLink ? 3 : 4

  return (
    <Box className={classes.wrapper} width="100%">
      <Box alignItems="center" className={classes.title} display="flex">
        <NxTypography variant="h4">Talk to Someone</NxTypography>
      </Box>
      <Grid className={classes.contactContainer} container>
        <Grid item md={mdGridSpacing} xs={6}>
          <Box className={classes.itemContainer}>
            <NxIconButton
              className={classes.item}
              icon={<Discord className={classes.icon} />}
              label={
                <Box alignItems="center" component="span" display="flex">
                  Join our Coach Discord <Linkout color="inherit" />
                </Box>
              }
              onClick={(): void =>
                handleLinkInNewTab(
                  'https://help.playvs.com/en/articles/4919257-discord-guide'
                )
              }
              variant="resource"
              verticalAlign
            />
          </Box>
        </Grid>
        <Grid item md={mdGridSpacing} xs={6}>
          <Box className={classes.itemContainer}>
            <NxIconButton
              className={classes.item}
              icon={<Coach className={classes.icon} />}
              label="Contact a Super Coach"
              onClick={(): void =>
                handleLinkInNewTab('https://playvs.com/super-coaches')
              }
              variant="resource"
              verticalAlign
            />
          </Box>
        </Grid>
        <Grid item md={mdGridSpacing} xs={6}>
          <Box
            className={
              consultationLink ? classes.itemContainer : classes.itemOffSet
            }
          >
            <NxIconButton
              className={classes.item}
              icon={<Chat className={classes.icon} />}
              label="Chat with PlayVS Support"
              onClick={openIntercom}
              variant="resource"
              verticalAlign
            />
          </Box>
        </Grid>
        {consultationLink && (
          <Grid item md={mdGridSpacing} xs={6}>
            <Box className={classes.itemOffSet}>
              <NxIconButton
                className={classes.item}
                icon={<Phone className={classes.icon} />}
                label="Schedule Call with PlayVS Rep"
                onClick={handleOnOpenConsultation}
                variant="resource"
                verticalAlign
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <ScheduleConsultationModal
        handleClose={handleOnCloseConsultation}
        link={consultationLink}
        open={isConsultationOpen}
      />
    </Box>
  )
}
