import React, { useState } from 'react'
import { DialogContent, Button } from '@material-ui/core'
import { DialogHeader } from '@plvs/respawn/features/dialog'
import {
  Metaseason,
  SubstituteTransferDestination,
  TeamMember,
  useFindEligibleMoveTeamsQuery,
  useSwapSubstitutesBetweenTeamsMutation,
} from '@plvs/graphql'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import { ConnectedAccountProviders, cleanGraphQLError } from '@plvs/utils'
import { NxTypography } from '@playvs-inc/nexus-components'
import { RosterRowGroup } from '@plvs/respawn/features/roster/modal/RosterRowGroup'
import { RosterRowData } from '@plvs/respawn/features/roster/modal/RosterRow'
import { BasicRowsSkeleton } from '../skeleton/BasicRowsSkeleton'
import { Box } from '../layout/Box'

const useStyle = makeStyles((theme) => ({
  container: {},
  header: {
    padding: theme.spacing(2),
  },
  headerSubTitleContainer: {
    paddingTop: theme.spacing(0.5),
    color: theme.palette.ColorTextBase,
  },
  dialogContent: {
    padding: `0 ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.ColorBackgroundBase,
    maxHeight: '533px',
  },
  footer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    textTransform: 'none',
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  title: {
    color: theme.palette.ColorTextBase,
    fontWeight: 'bold',
  },
  subTitle: {
    color: theme.palette.ColorTextAlt2,
  },
  step: {
    margin: 'auto 0',
  },
  skeleton: {
    marginBottom: theme.spacing(1),
  },
  emptyState: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(8),
  },
}))

const SUBTITLE_TEXT = [
  'Select a team for this player',
  'Choose an existing player to swap places with',
]

type CachedDestinations = Record<string, SubstituteTransferDestination>

interface MoveTeamDialogProps {
  onClose(): void
  member: Pick<TeamMember, 'id' | 'name' | 'position'>
  onMutationSuccess?(): void
  teamId: string
  metaseason?: Pick<Metaseason, 'endsAt'>
  getUsername: (user: ConnectedAccountProviders) => string
}

export const MoveTeamDialog: React.FC<MoveTeamDialogProps> = ({
  onClose,
  member,
  onMutationSuccess,
  teamId,
  metaseason,
  getUsername,
}) => {
  const styles = useStyle()
  const [selectedTeamId, setSelectedTeamId] = useState<string>()
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>()
  const [teamFull, setTeamFull] = useState<boolean>(false)
  const [step, setStep] = useState<number>(0)
  const { enqueueSnackbar } = useSnackbar()
  const [swapSubstitutesBetweenTeams, { loading: swapping }] =
    useSwapSubstitutesBetweenTeamsMutation({
      onCompleted: () => {
        onMutationSuccess?.()
        onClose?.()
        enqueueSnackbar('Move successful', {
          variant: 'success',
        })
      },
      onError: (error) => {
        enqueueSnackbar(cleanGraphQLError(error.message), {
          variant: 'error',
        })
      },
    })

  const { data: queryData, loading: loadingTeams } =
    useFindEligibleMoveTeamsQuery({
      variables: {
        input: {
          userId: member.id,
          originTeamId: teamId,
          effectiveAt: metaseason?.endsAt,
        },
        id: teamId,
      },
      skip: !teamId || !member.id,
    })

  const originTeam = queryData?.team
  const teamsData =
    queryData?.findEligibleTeamsForSubstituteTransfer.destinations
  const teams = teamsData?.map((destination) => {
    const team = destination?.team
    return {
      id: team?.id,
      title: team.name || '',
      subtitle: `${destination.tradablePlayers.length} Bench Players`,
      avatarSrc: team.school?.logoUrl || '',
      position: '',
    }
  })

  const mappedDestinations: CachedDestinations | undefined = teamsData?.reduce(
    (accum, value) => {
      const { id } = value.team
      return { [id]: value, ...accum }
    },
    {}
  )

  const isTeamFull = (id?: string): boolean => {
    const theId = id || selectedTeamId
    if (!theId || !mappedDestinations || !mappedDestinations[theId]) {
      return false
    }
    return mappedDestinations[theId].requiresTrade
  }

  const continueHandler = (): void => {
    if (step === 0 && isTeamFull()) {
      setStep(1)
    } else {
      const input = {
        originTeamId: teamId,
        originPlayerId: member.id,
        destinationTeamId: selectedTeamId || '',
        destinationPlayerId: selectedPlayerId,
      }
      swapSubstitutesBetweenTeams({ variables: { input } })
    }
  }
  const cancelHandler = (): void => {
    if (step === 1) {
      setSelectedPlayerId(undefined)
      setStep(0)
    } else {
      onClose()
    }
  }

  const changeTeamHandler = (id: string): void => {
    setTeamFull(isTeamFull(id))
    setSelectedTeamId(id)
  }

  const changePlayerHandler = (id: string): void => {
    setSelectedPlayerId(id)
  }

  let buttonText = 'Confirm'
  if (step === 0 && teamFull) {
    buttonText = 'Continue'
  }

  const renderOriginTeam = (): JSX.Element => {
    if (!originTeam) {
      return <NxTypography className={styles.subTitle} />
    }
    return (
      <NxTypography className={styles.subTitle}>
        Current Team - {originTeam?.name}
      </NxTypography>
    )
  }

  const getRosterGroup = (currentStep: number): JSX.Element => {
    if (currentStep === 0) {
      if (teams && teams.length) {
        return (
          <RosterRowGroup
            onChange={changeTeamHandler}
            rowEntries={teams || []}
            selectedId={selectedTeamId}
          />
        )
      }
      return (
        <Box
          className={styles.emptyState}
          data-testid="MoveTeamDialog_EmptyState"
        >
          There are no available to teams to move to
        </Box>
      )
    }
    if (!mappedDestinations) return <></>
    const destination = mappedDestinations[selectedTeamId || '']
    let members: RosterRowData[] = []
    if (destination.requiresTrade) {
      members =
        destination?.tradablePlayers?.map(({ user }) => {
          return {
            id: user.id,
            title: user.name || '',
            subtitle: getUsername(user),
            avatarSrc: user.avatarUrl || '',
            position: '',
            hasConsumedPass: undefined,
          }
        }) || []
    }

    return (
      <RosterRowGroup
        onChange={changePlayerHandler}
        rowEntries={members || []}
        selectedId={selectedPlayerId}
      />
    )
  }

  const getStepText = (currentStep: number, isFull: boolean): string => {
    if (currentStep === 0 && isFull) {
      return 'Step 1 of 2'
    }
    if (currentStep === 1) {
      return 'Step 2 of 2'
    }
    return ''
  }

  return (
    <Box className={styles.container}>
      <DialogHeader
        headerClassname={styles.header}
        subtitle={<>{SUBTITLE_TEXT[step]}</>}
        subtitleClassName={styles.subTitle}
        subtitleContainerClassName={styles.headerSubTitleContainer}
        title="Move Team"
        titleClassName={styles.title}
      />
      <DialogContent className={styles.dialogContent}>
        {loadingTeams ? (
          <BasicRowsSkeleton numRows={1} rowClassname={styles.skeleton} />
        ) : (
          renderOriginTeam()
        )}
        {loadingTeams ? (
          <BasicRowsSkeleton numRows={12} rowClassname={styles.skeleton} />
        ) : (
          getRosterGroup(step)
        )}
      </DialogContent>
      <Box className={styles.footer}>
        <Box className={styles.step} data-testid="MoveTeamDialog_Step">
          {getStepText(step, teamFull)}
        </Box>
        <Box>
          <Button
            className={styles.button}
            data-testid="MoveTeamDialog_Cancel"
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            color="primary"
            data-testid="MoveTeamDialog_Confirm"
            disabled={
              (step === 0 && !selectedTeamId) ||
              (step === 1 && !selectedPlayerId) ||
              swapping
            }
            onClick={continueHandler}
            variant="contained"
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
