import { makeStyles } from '@material-ui/core'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import React from 'react'

import * as analytics from '@plvs/respawn/features/analytics'

import { UserRoleName, School, useGetReferringUserQuery } from '@plvs/graphql'
import { CountryCode, userRolesToUserType } from '@plvs/utils'

import { SchoolGrowthCoachLeadModal } from '@plvs/rally/features/coach/coachLeadGen/SchoolGrowthCoachLeadModal'
import { SchoolGrowthStudentCoachLeadModal } from '@plvs/rally/features/coach/coachLeadGen/SchoolGrowthStudentCoachLeadModal'
import {
  CoachLeadComponent,
  SchoolPropType,
} from '@plvs/rally/features/coach/coachLeadGen/CoachLeadComponent'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { NxButton, NxModal } from '@playvs-inc/nexus-components'

import { ReferUser, StudentOrFaculty } from './ReferUser'

type NxButtonVariant = 'primary' | 'secondary' | 'text' | 'error' | 'success'

const ButtonText = {
  SinglePlayer: 'Invite Player',
  PluralPlayers: 'Invite Players',
  SingleCoach: 'Invite Coach',
  PluralCoaches: 'Invite Coaches',
}
const getButtonText = (
  userRoleName: StudentOrFaculty,
  singular?: boolean
): string => {
  if (userRoleName === UserRoleName.Student) {
    return singular ? ButtonText.SinglePlayer : ButtonText.PluralPlayers
  }
  return singular ? ButtonText.SingleCoach : ButtonText.PluralCoaches
}

const useStyles = makeStyles(() => ({
  modalContent: {
    margin: 0,
  },
}))

export const ReferButton: React.FC<{
  variant?: NxButtonVariant
  refer: StudentOrFaculty
  singular?: boolean
  source?: string
  school?: School
  setAvailableSchool?: boolean
  label?: string
}> = ({
  variant = 'primary',
  refer,
  singular = false,
  source = '',
  school,
  setAvailableSchool,
  label,
}) => {
  const classes = useStyles()
  const { isFacultyAtOrg, orgCountry } = useUserIdentityFn()
  const { data, loading } = useGetReferringUserQuery()

  const userRoleType = userRolesToUserType(data?.me?.roles ?? [])

  const [isReferDialogOpen, setIsReferDialogOpen] = React.useState(false)
  const buttonText = getButtonText(refer, singular)
  const isUsersSchoolInUnitedStates = orgCountry === CountryCode.UnitedStates
  const referPlayer = refer === UserRoleName.Student
  const isFacultyUSReferral = isUsersSchoolInUnitedStates && !referPlayer

  const onClose = (): void => setIsReferDialogOpen(false)

  if (isReferDialogOpen) {
    if (referPlayer) {
      analytics.referPlayerModalDiscovered({
        userRoleType,
      })
    } else {
      analytics.referCoachModalDiscovered({
        userRoleType,
      })
    }
  }

  const sideView = (
    <Box alignItems="center" display="flex" justifyContent="center">
      {isFacultyAtOrg ? (
        <SchoolGrowthCoachLeadModal />
      ) : (
        <SchoolGrowthStudentCoachLeadModal />
      )}
    </Box>
  )

  return (
    <WaitTillLoaded loading={loading}>
      <NxButton
        data-testid="refer-button"
        fullWidth
        label={label ?? buttonText}
        onClick={(): void => setIsReferDialogOpen(true)}
        variant={variant}
      />

      <NxModal
        contentClassName={classes.modalContent}
        onClose={onClose}
        open={isReferDialogOpen}
        size="small"
      >
        {isFacultyUSReferral ? (
          <Box display="flex">
            <CoachLeadComponent
              hideModalPadding
              isBorderless
              isFaculty={isFacultyAtOrg}
              isModalView
              school={school as SchoolPropType}
              setAvailableSchool={setAvailableSchool}
              sideView={sideView}
              source={
                isFacultyAtOrg
                  ? 'faculty-schoolpage-coach-lead'
                  : 'student-schoolpage-coach-lead'
              }
            />
          </Box>
        ) : (
          <ReferUser containerless refer={refer} source={source} />
        )}
      </NxModal>
    </WaitTillLoaded>
  )
}
