import React, { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { EsportSlug } from '@plvs/graphql/types'
import { NxButton, NxDropdownButton } from '@playvs-inc/nexus-components'
import { isMatchCancellableByStatus } from '@plvs/utils'
import {
  CompetitionGroup,
  MatchStatus,
  useGetMatchRescheduleRequestDataForMenuQuery,
  useSetTourViewedMutation,
  Tour as TourEnum,
  useGetMatchStatusQuery,
} from '@plvs/graphql'
// eslint-disable-next-line import/no-unresolved
import Tour from 'shepherd.js/src/types/tour'
import { useLocation } from 'react-router-dom'
import { IntercomArticleMappings, Polling } from '@plvs/const'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { ManageMatchDropdownMenuItems } from '@plvs/respawn/renderController/matchLobby/lobby/types/lobbyRenderController.types'
import {
  useGetManageMatchActions,
  DropdownActionHandlersMap,
} from '../match/useGetManageMatchActions'
import { determineRescheduleStepOnClick } from './ActionAndInfoSection.helpers'
import { Box } from '../layout/Box'

interface ActionAndInfoSectionMenuProps {
  esportSlug: EsportSlug | null | undefined
  competitionGroup: CompetitionGroup | null | undefined
  showMatchLobbyTour: boolean
  showManageMatchSection: boolean
  myTeamId: string
  opposingTeamId: string
  matchId: string
  setIsRescheduleDialogOpen: (isOpen: boolean) => void
  setStep: (step: string) => void
  isMemberOfBothTeams: boolean
  setIsForfeitDialogOpen: (isOpen: boolean) => void
  esportMatchInstructionsArticleId: string
  showIntercomArticle: (articleId: number | string) => void
  matchLobbyTourClicked: (props: {
    userId: string
    initiatedTimestamp: string
    stepNumber: number
    timeStamp: string
    clickTarget: string
  }) => void
  showViewRescheduleItem: boolean
  showRescheduleItem: boolean
  matchLobbyTour: Tour | undefined
  initiatedTimestamp: string | undefined
  setInitiatedTimestamp(time: string): void
  menuItemIds: ManageMatchDropdownMenuItems[]
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})

export const ActionAndInfoSectionMenu: React.FC<
  ActionAndInfoSectionMenuProps
> = ({
  competitionGroup,
  showManageMatchSection,
  showMatchLobbyTour,
  myTeamId,
  opposingTeamId,
  matchId,
  isMemberOfBothTeams,
  setIsRescheduleDialogOpen,
  setIsForfeitDialogOpen,
  setStep,
  esportMatchInstructionsArticleId,
  showIntercomArticle,
  matchLobbyTourClicked,
  showViewRescheduleItem,
  showRescheduleItem,
  matchLobbyTour,
  initiatedTimestamp,
  setInitiatedTimestamp,
  menuItemIds,
  esportSlug,
}) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'), {
    noSsr: true,
  })

  const location = useLocation()
  const isMissionControl = location.pathname.includes('mission-control')
  const isDesktop = useMediaQuery(useTheme().breakpoints.up('md'), {
    noSsr: true,
  })
  const isDesktopOnMissionControl = isDesktop && isMissionControl
  const flags = useFlags()

  const { userId } = useUserIdentityFn()

  const [setTourViewed] = useSetTourViewedMutation()

  const {
    data: matchPollData,
    startPolling,
    stopPolling,
  } = useGetMatchStatusQuery({
    variables: { matchId },
    ssr: false,
    skip: !matchId,
  })

  const { data } = useGetMatchRescheduleRequestDataForMenuQuery({
    variables: { matchId },
    skip: !matchId,
  })

  const matchRescheduleRequests = data?.match?.matchRescheduleRequests ?? []

  const status = matchPollData?.match?.status

  useEffect(() => {
    if (status === MatchStatus.Open || status === MatchStatus.Live) {
      startPolling(Polling.Normal)
    }
    return (): void => stopPolling()
  }, [status])

  const renderMatchLobbyTour =
    isDesktopOnMissionControl &&
    showMatchLobbyTour &&
    !!flags.matchDayOnboardingTour
  const renderManageMatchSection = showManageMatchSection

  const showMatchInstructionItem = isMobile

  const showForfeitItem = status ? isMatchCancellableByStatus(status) : false
  const showDisputeItem =
    status === MatchStatus.Completed || status === MatchStatus.Forfeited

  const conditionMap = {
    [ManageMatchDropdownMenuItems.MatchInstructions]: showMatchInstructionItem,
    [ManageMatchDropdownMenuItems.Reschedule]: showRescheduleItem,
    [ManageMatchDropdownMenuItems.ViewReschedule]: showViewRescheduleItem,
    [ManageMatchDropdownMenuItems.Forfeit]: showForfeitItem,
    [ManageMatchDropdownMenuItems.Dispute]: showDisputeItem,
  }

  const filteredMenuItemIds = menuItemIds.filter((id) =>
    id in conditionMap ? conditionMap[id] : true
  )

  const dropdownActionHandlers: DropdownActionHandlersMap = {
    [ManageMatchDropdownMenuItems.Reschedule]: {
      onClick: (): void => {
        setIsRescheduleDialogOpen(true)
      },
    },
    [ManageMatchDropdownMenuItems.LobbyTour]: {
      onClick: async (): Promise<void> => {
        matchLobbyTourClicked({
          userId,
          initiatedTimestamp: new Date().toISOString(),
          timeStamp: new Date().toISOString(),
          stepNumber: 0,
          clickTarget: 'Start Button',
        })
        await setTourViewed({
          variables: { tour: TourEnum.MatchLobby },
        })
        setInitiatedTimestamp(new Date().toISOString())
        matchLobbyTour?.start()
      },
    },

    [ManageMatchDropdownMenuItems.ViewReschedule]: {
      onClick: () =>
        determineRescheduleStepOnClick({
          matchId,
          matchRescheduleRequests,
          setStep,
          setIsRescheduleDialogOpen,
          myTeamId,
          opposingTeamId,
          isMemberOfBothTeams,
        }),
    },
    [ManageMatchDropdownMenuItems.Forfeit]: {
      onClick: (): void => setIsForfeitDialogOpen(true),
    },
  }

  const renderedMenuItems = useGetManageMatchActions({
    menuItemIds: filteredMenuItemIds,
    esportSlug,
    competitionGroup,
    opposingTeamId,
    matchId,
    dropdownActionHandlers,
    confirmRenderingSections: {
      manageMatchSection: renderManageMatchSection,
      matchLobbyTour: renderMatchLobbyTour,
    },
  })

  return (
    <Box className={classes.buttonContainer}>
      {!isMobile && (
        <>
          <Box pr={2} />
          <NxButton
            disabled={!esportMatchInstructionsArticleId}
            id="match-instructions"
            label="Match Instructions"
            onClick={(): void =>
              showIntercomArticle(
                esportMatchInstructionsArticleId ??
                  IntercomArticleMappings.allArticles
              )
            }
            variant="secondary"
          />
          <Box pr={2} />
        </>
      )}
      <NxDropdownButton
        buttonOnClick={(): void => {
          if (matchLobbyTour?.isActive()) {
            matchLobbyTourClicked({
              userId,
              initiatedTimestamp: initiatedTimestamp ?? '',
              timeStamp: new Date().toISOString(),
              stepNumber: 1,
              clickTarget: 'Next Button',
            })
            matchLobbyTour?.next()
          }
        }}
        className="shepherd-manage-match"
        data-cy="manageMatch"
        fullWidth={isMobile}
        id="manage-match"
        label="Manage Match"
        menuItems={renderedMenuItems}
        MenuPaperProps={{ className: 'shepherd-manage-match-menu' }}
        variant="primary"
      />
    </Box>
  )
}
