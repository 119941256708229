import { MappedMatch } from '@plvs/rally/features/match/schedule/schedule'
import { MatchStatus } from '@plvs/graphql/generated'
import dayjs from 'dayjs'

export const getIsPastMatch = (match: MappedMatch): boolean => {
  const isMatchLive =
    match?.status === MatchStatus.Live || match?.status === MatchStatus.Open

  const now = dayjs()

  return !isMatchLive && dayjs(match?.scheduledStartsAt).isBefore(now)
}
