import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { UserNotificationContentAdapter } from '../notification.types'
import { SchoolSystemMessage } from '../templates/SchoolSystemMessage'

const SchoolDistrictClassificationAdapterContainer: React.FC<{
  notification: UserNotification
}> = ({ notification }) => {
  return (
    <SchoolSystemMessage
      IconComponent={
        <NxSpot
          domain="school"
          height="64px"
          size="small"
          variant="primary"
          width="64px"
        />
      }
      message={notification.contentData.message}
      messageDates={notification.contentData.messageDates}
      url={notification.contentData.url || undefined}
    />
  )
}

export const SchoolDistrictClassificationAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Add District Classification',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <SchoolDistrictClassificationAdapterContainer
        notification={notification}
      />
    )
  },
}
