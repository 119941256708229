import React from 'react'
import { EsportSlug } from '@plvs/graphql/types'

import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { ActionAndInfoSectionMenu } from '@plvs/respawn/features/match-lobby/ActionAndInfoSectionMenu'

import { CompetitionGroup } from '@plvs/graphql/generated'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController/matchLobby/MatchLobbyRenderControllerProvider'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { matchLobbyTourClicked } from '@plvs/respawn/features/analytics'
import { RescheduleMatchDialog } from '@plvs/respawn/features/rescheduleMatch/reschedule'
import { RescheduleMatchStep } from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'
import { SnackbarProvider } from 'notistack'
import { useProductTours } from '@plvs/respawn/features/shepherd/utils/useProductTours'
import { useActionAndInfoSection } from '../../utils/useActionAndInfoSection'
import { ForfeitMatchDialog } from '../../../cancel/ForfeitMatchDialog'

interface ActionAndInfoSectionWrapperProps {
  esportSlug: EsportSlug | null | undefined
  matchId: string
  competitionGroup: CompetitionGroup | null | undefined
}

export const ActionAndInfoSectionWrapper: React.FC<ActionAndInfoSectionWrapperProps> = ({
  esportSlug,
  matchId,
  competitionGroup,
}) => {
  const {
    matchInstructionsArticleId,
    showRescheduleItem,
    showViewRescheduleItem,
    isRescheduleDialogOpen,
    setIsRescheduleDialogOpen,
    step,
    setStep,
    isForfeitDialogOpen,
    setIsForfeitDialogOpen,
    forfeitStep,
    setForfeitStep,
    loading,
    match,
  } = useActionAndInfoSection({ matchId, esportSlug, competitionGroup })

  const {
    matchLobbyTour,
    initiatedTimestamp,
    setInitiatedTimestamp,
  } = useProductTours()

  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()

  const { lobby } = getMatchLobbyRenderControllerState()

  const {
    showManageMatchSection,
    showMatchLobbyTour,
    myTeamIds,
    opposingTeamId,
    isMemberOfMoreThanOneTeam,
    items,
  } = lobby

  const myTeamId = myTeamIds[0]

  return (
    <SnackbarProvider>
      <WaitTillLoaded loading={loading}>
        <ActionAndInfoSectionMenu
          competitionGroup={competitionGroup}
          esportMatchInstructionsArticleId={matchInstructionsArticleId}
          esportSlug={esportSlug}
          initiatedTimestamp={initiatedTimestamp}
          isMemberOfBothTeams={isMemberOfMoreThanOneTeam}
          matchId={matchId}
          matchLobbyTour={matchLobbyTour}
          matchLobbyTourClicked={matchLobbyTourClicked}
          menuItemIds={items}
          myTeamId={myTeamId}
          opposingTeamId={opposingTeamId}
          setInitiatedTimestamp={setInitiatedTimestamp}
          setIsForfeitDialogOpen={setIsForfeitDialogOpen}
          setIsRescheduleDialogOpen={setIsRescheduleDialogOpen}
          setStep={setStep}
          showIntercomArticle={showIntercomArticle}
          showManageMatchSection={showManageMatchSection}
          showMatchLobbyTour={showMatchLobbyTour}
          showRescheduleItem={showRescheduleItem}
          showViewRescheduleItem={showViewRescheduleItem}
        />

        {matchId && (
          <>
            <RescheduleMatchDialog
              closeDialog={(): void => setIsRescheduleDialogOpen(false)}
              esportSlug={esportSlug}
              isDialogOpen={isRescheduleDialogOpen}
              match={match}
              onReschedule={(): void => {}}
              setStep={setStep}
              step={step as RescheduleMatchStep}
              teamId={myTeamId}
            />
            <ForfeitMatchDialog
              closeDialog={(): void => setIsForfeitDialogOpen(false)}
              esportSlug={esportSlug}
              isDialogOpen={isForfeitDialogOpen}
              match={match}
              setStep={setForfeitStep}
              step={forfeitStep}
              teamId={myTeamId}
            />
          </>
        )}
      </WaitTillLoaded>
    </SnackbarProvider>
  )
}
