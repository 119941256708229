import { FormLabel, makeStyles, useTheme } from '@material-ui/core'
import {
  ResourceType,
  useMyOnboardReferralQuery,
  UserRole,
  UserRoleName,
  useGenerateReferralLinkMutation,
} from '@plvs/graphql/generated/graphql'
import * as analytics from '@plvs/respawn/features/analytics'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import React, { useEffect } from 'react'
import { noop } from 'ramda-adjunct'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { assert } from '@plvs/utils'
import { useNavigate } from 'react-router-dom'
import { Path } from '@plvs/const'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'
import { OnboardReferByEmailForm, OnboardReferByLinkForm } from '..'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formLabel: { color: theme.palette.ColorTextBase, fontWeight: 600 },
}))

export const OnboardingInvitePlayer: React.FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()

  // other hooks
  const { data: contextData } = useOnboardingContext()
  const { data, error } = useMyOnboardReferralQuery()
  const [
    generateReferralLink,
    { data: referralLinkData, loading },
  ] = useGenerateReferralLinkMutation()

  const playerLink = referralLinkData?.generateReferralLink
  const { role } = contextData

  const onContinue = (): void => {
    navigate(`${Path.SpawnPoint}/finish`)
  }

  useEffect(() => {
    if (data?.me?.school) {
      const competitionGroup = data?.me?.school?.competitionGroup
      const schoolId = data?.me?.school?.id
      try {
        assert(competitionGroup)
        assert(schoolId)

        generateReferralLink({
          variables: {
            resourceId: schoolId,
            resourceType: ResourceType.System,
            roleName: UserRoleName.User,
            source: 'onboarding_invite',
            medium: 'share_link',
            competitionGroup,
          },
        })
      } catch (err: any) {
        noop(err)
      }
    }
  }, [data?.me?.school])

  useEffect(() => {
    if (error) {
      navigate('finish')
    }
  }, [error])

  return (
    <WaitTillLoaded
      loading={loading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <Box>
        <Box className={classes.container}>
          <QuestionnaireCard
            childWidth={theme.spacing(50)}
            onContinue={onContinue}
            subtitle="Playing is more fun with friends. Invite yours to come join your team or create their own."
            title="Invite Players"
          >
            <Box width="100%">
              <Box textAlign="center">
                <FormLabel className={classes.formLabel}>
                  Share your link
                </FormLabel>
              </Box>
              <OnboardReferByLinkForm
                link={playerLink}
                onClickCopy={(value): void =>
                  analytics.copyInviteLink({
                    inviteLink: value,
                    inviteeUserType: UserRoleName.Player,
                    myUserRoles: [role as Pick<UserRole, 'roleName'>],
                  })
                }
              />
            </Box>
            <Box mt={3} width="100%">
              <Box textAlign="center">
                <FormLabel className={classes.formLabel}>
                  Invite via email
                </FormLabel>
              </Box>
              {/* TODO: Backend currently errors, seems like account not fully
          created at this point.  Need to make api call to fully onboard
        this account before this point */}
              <OnboardReferByEmailForm
                competitionGroup={data?.me?.school?.competitionGroup}
                myUserRoles={[role as Pick<UserRole, 'roleName'>]}
                recipientUserRoleName={UserRoleName.Student}
                schoolId={data?.me?.school?.id}
                source="onboard_invite"
              />
            </Box>
          </QuestionnaireCard>
        </Box>
      </Box>
    </WaitTillLoaded>
  )
}
