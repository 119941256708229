import { Hidden, capitalize } from '@material-ui/core'
import React, { useState } from 'react'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { NxTypography, NxTab, NxTabs } from '@playvs-inc/nexus-components'
import { useDebounce } from 'use-debounce'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { UserRoleName } from '@plvs/graphql/generated'
import { ReferButton } from '@plvs/rally/containers/onboard'
import { DEFAULT_DEBOUNCE_TIME } from '@plvs/const'
import { Box } from '@plvs/respawn/features/layout/Box'
import DeniedPlayersTable from './DeniedPlayersTable'
import UnverifiedUsers from './UnverifiedUsers'
import { PlayersTable } from './PlayersTable'
import { ArchivedPlayersTable } from './ArchivedPlayersTable'
import { ManagePlayersTableHeader } from './ManagePlayersTableHeader'

enum StatusTabs {
  Verified = 'verified',
  Archived = 'archived',
  Denied = 'denied',
}

const TABLES = {
  verified: PlayersTable,
  archived: ArchivedPlayersTable,
  denied: DeniedPlayersTable,
}

export const ManagePlayers: React.FC = () => {
  const flags = useFlags()
  const [playerStatusTab, setPlayerStatusTab] = React.useState(
    StatusTabs.Verified
  )
  const [nameFilter, setNameFilter] = useState('')
  const [totalCount, setTotalCount] = useState(0)
  const [nameFilterDebounce] = useDebounce(nameFilter, DEFAULT_DEBOUNCE_TIME)
  const nameFilterDebouncing = nameFilter !== nameFilterDebounce

  const TableToShow = TABLES[playerStatusTab]
  const handleTabChange = (_: any, newValue: any): void => {
    setPlayerStatusTab(newValue)
    setNameFilter('')
  }

  const tabsComponent = (
    <NxTabs onChange={handleTabChange} size="small" value={playerStatusTab}>
      <NxTab label="Verified Players" value={StatusTabs.Verified} />
      <NxTab label="Archived Players" value={StatusTabs.Archived} />
      <NxTab label="Denied Players" value={StatusTabs.Denied} />
    </NxTabs>
  )

  return (
    <>
      <NxTypography variant="h3">
        {`Verify the following students attend your school${
          flags?.coppaSupport ? '.' : ' and ARE OVER AGE 13.'
        }`}
      </NxTypography>
      <Box mb={3} mt={2}>
        <UnverifiedUsers />
      </Box>
      <Box>
        <MatchCard
          px={0}
          sideContent={
            <Hidden mdDown>
              <ReferButton
                refer={UserRoleName.Student}
                setAvailableSchool
                source="manage_player_page"
                variant="secondary"
              />
            </Hidden>
          }
          tabUnderline={false}
          title={tabsComponent}
        >
          <ManagePlayersTableHeader
            countedUserText={capitalize(playerStatusTab)}
            setNameFilter={setNameFilter}
            totalCount={totalCount}
          />
          <TableToShow
            filters={{ name: nameFilter }}
            filtersDebouncing={nameFilterDebouncing}
            setTotalCount={setTotalCount}
          />
        </MatchCard>
      </Box>
    </>
  )
}
