import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { Navigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { Path } from '@plvs/const'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  useGetSchoolRecommendedScrimmagesQuery,
  useJoinScrimmageMutation,
  CompetitionGroup,
} from '@plvs/graphql'

import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { cleanGraphQLError } from '@plvs/utils'
import { TopRecommendedScrimmages } from '@plvs/rally/components/scrimmage'
import {
  getRefetchScrimmageQueries,
  CreateScrimmageFormInput,
  findTopThreeScrimmages,
} from '@plvs/rally/components/scrimmage/scrimmageHelpers'

import { NxButton } from '@playvs-inc/nexus-components'

dayjs.extend(tz)

interface CreateScrimmageStepBProps {
  onSubmit(): Promise<void>
  onReset(): void
  isSubmitting: boolean
  schoolId: string
  formValues: CreateScrimmageFormInput
  timezone: string | null
  schoolCompetitionGroup: CompetitionGroup | null
}

/*
 * Render top 3 recommended scrimmages, allow joining scrimmage
 */
export const CreateScrimmageStepB = ({
  onSubmit,
  onReset,
  isSubmitting,
  schoolId,
  formValues,
  timezone,
  schoolCompetitionGroup,
}: CreateScrimmageStepBProps): React.ReactElement => {
  const [selectedScrimmageId, setSelectedScrimmageId] = React.useState<string>(
    ''
  )
  const [joinedScrimmage, setJoinedScrimmage] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error>()
  const { enqueueSnackbar } = useSnackbar()
  const startsAt =
    timezone && formValues?.date && formValues?.time
      ? dayjs(formValues.time).tz(timezone).toISOString()
      : null
  const teamId = formValues?.requestingTeamId ?? ''
  const esportSlug = formValues?.esport ?? null

  const {
    data: availableScrimmagesData,
    loading: scrimmageDataLoading,
  } = useGetSchoolRecommendedScrimmagesQuery({
    variables: {
      id: schoolId,
      offset: 0,
      limit: 50,
      teamId,
    },
    skip: !schoolId || !teamId,
  })
  const [submitJoin, { loading: isJoinSubmitting }] = useJoinScrimmageMutation({
    onError: (err): void => setError(err),
    onCompleted: (): void => {
      enqueueSnackbar('Scrimmage joined')
      setJoinedScrimmage(true)
    },
  })

  const availableScrimmages = findTopThreeScrimmages({
    startsAt,
    availableScrimmagesData,
    esportSlug,
    teamId,
    schoolCompetitionGroup,
  })

  const hasNoAvailableScrimmages =
    !scrimmageDataLoading && !availableScrimmages.length

  // Skip to next step if no available scrimmages
  useEffect(() => {
    if (hasNoAvailableScrimmages) onSubmit()
  })

  return (
    <>
      <MatchCard
        py={4}
        title="Want to join one of these?"
        titleDetail="Teams looking near the same time as you."
        wrapperStyle={{ paddingTop: 0 }}
      >
        {error && (
          <div style={{ marginBottom: 40 }}>
            <Banner
              data-testid="scrimmageJoinErrorBanner"
              subtitle={cleanGraphQLError(error.message)}
              title="Unable to join scrimmage"
              type={BannerType.Error}
            />
          </div>
        )}
        {joinedScrimmage && (
          <div style={{ marginBottom: 40 }}>
            <Banner
              data-testid="scrimmageJoinedBanner"
              title="Scrimmage joined"
              type={BannerType.Success}
            />
          </div>
        )}
        <WaitTillLoaded
          loading={scrimmageDataLoading || hasNoAvailableScrimmages}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          <TopRecommendedScrimmages
            availableScrimmages={availableScrimmages}
            data-testid="availableScrimmages"
            selectedScrimmageId={selectedScrimmageId}
            setSelectedScrimmageId={setSelectedScrimmageId}
          />
        </WaitTillLoaded>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          gridGap="1em"
          mt={3}
        >
          <NxButton label="Back" onClick={onReset} variant="secondary" />
          <NxButton
            data-cy="wantToJoinScrimmage"
            data-testid="joinScrimmage"
            disabled={isJoinSubmitting || !selectedScrimmageId || !!error}
            label="Join"
            onClick={(): void => {
              if (teamId)
                submitJoin({
                  refetchQueries: getRefetchScrimmageQueries(schoolId),
                  variables: {
                    input: {
                      id: selectedScrimmageId,
                      opposingTeamId: teamId,
                    },
                  },
                })
            }}
            variant="primary"
          />
          <NxButton
            disabled={isSubmitting || joinedScrimmage}
            label={`${availableScrimmages.length ? 'No, ' : ''}Keep Creating`}
            onClick={onSubmit}
            variant={availableScrimmages.length ? 'secondary' : 'primary'}
          />
        </Box>
      </MatchCard>
      {joinedScrimmage && <Navigate to={Path.Scrimmage} />}
    </>
  )
}
