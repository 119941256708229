import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from '@material-ui/core'
import { NxTypography, Pill } from '@playvs-inc/nexus-components'

import { CaretDown } from '@playvs-inc/nexus-icons'
import React, { useEffect, useState } from 'react'
import { Box, Hidden, useBreakpointSm } from '@plvs/respawn/features/layout'
import { ChecklistGroupPayload } from '@plvs/graphql/generated'
import { ChecklistItem } from './ChecklistItem'

const useStyles = makeStyles((theme) => ({
  checklistContainer: {
    overflow: 'auto',
    borderRadius: theme.mixins.cornerRadius['border-radius-10'],
    boxShadow: theme.shadows[1],
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'unset',
      marginTop: theme.spacing(1),
    },
  },
  expandMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.BorderLight}`,
    borderRadius: theme.mixins.cornerRadius['border-radius-1000'],
  },
  body: {
    padding: 'unset',
  },
  summaryContainer: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}))

interface Props {
  position: number
  checklist: ChecklistGroupPayload
  schoolId: string
  userId: string
  allAvailableEsportsNames?: (string | null)[]
}

export const ChecklistAccordion: React.FC<Props> = ({
  checklist,
  position,
  schoolId,
  userId,
  allAvailableEsportsNames,
}) => {
  const isAllChecked = checklist.items.every((item) => !!item.completedById)
  const [expanded, setExpanded] = useState(!isAllChecked)
  const isMobile = useBreakpointSm()
  const classes = useStyles()
  const step = `STEP ${position}`
  const title = checklist.group ?? ''
  const checklistItems = checklist.items ?? []

  const completedProgress = `${
    checklist.items.filter((item) => item.completedById).length ?? 0
  } / ${checklist.items.length} COMPLETED`

  const handleExpansion = (): void => {
    if (!isMobile) {
      setExpanded(!expanded)
    }
  }

  const expandIcon = isMobile ? null : (
    <Box
      className={classes.expandMore}
      height={38}
      onClick={handleExpansion}
      width={38}
    >
      <CaretDown />
    </Box>
  )

  useEffect(() => {
    if (isMobile) {
      setExpanded(true)
    }
  }, [isMobile])

  return (
    <Box
      className={classes.checklistContainer}
      display="flex"
      flexDirection="column"
      mb={3}
    >
      <Accordion
        defaultExpanded={!isAllChecked}
        expanded={expanded}
        onChange={handleExpansion}
      >
        <AccordionSummary expandIcon={expandIcon}>
          <Box className={classes.summaryContainer} display="flex" width="100%">
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              pr={isMobile ? 1 : 0}
              width="100%"
            >
              <Box alignItems="center" display="flex" flexDirection="row">
                <Pill label={step} size="small" variant="brand" />
                <Hidden smDown>
                  <NxTypography className={classes.title} variant="h4">
                    {title}
                  </NxTypography>
                </Hidden>
              </Box>
              <NxTypography
                colorToken={isAllChecked ? 'ColorTextSuccess' : 'ColorTextAlt2'}
                variant="overline"
              >
                {completedProgress}
              </NxTypography>
            </Box>
            <Hidden mdUp>
              <NxTypography className={classes.title} variant="h4">
                {title}
              </NxTypography>
            </Hidden>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.body}>
          <Box display="flex" flexDirection="column" width="100%">
            {checklistItems?.map(
              (checklistItem): React.ReactElement => {
                return (
                  <ChecklistItem
                    key={checklistItem.slug}
                    allAvailableEsportsNames={allAvailableEsportsNames}
                    isCompleted={!!checklistItem.completedById}
                    item={checklistItem}
                    schoolId={schoolId}
                    step={position}
                    userId={userId}
                  />
                )
              }
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
