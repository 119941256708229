/* eslint-disable no-nested-ternary */
import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  formErrorToString,
  cleanGraphQLError,
  passwordRequirementsValidation,
} from '@plvs/utils'
import { useChangeMyPasswordMutation } from '@plvs/graphql'
import { yupResolver } from '@hookform/resolvers'
import { NxTypography, NxButton, NxTooltip } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout/Box'

const ChangePasswordFormSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  newPassword: passwordRequirementsValidation,
})

interface ChangePasswordInput {
  confirmPassword: string
  newPassword: string
}

interface ChangePasswordFormProps {
  onEdit(value: boolean): void
  onCancel(): void
  isUnderage: boolean
  isEditOpen: boolean
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  onEdit,
  onCancel,
  isUnderage,
  isEditOpen,
}) => {
  const { errors, handleSubmit, register } = useForm<ChangePasswordInput>({
    resolver: yupResolver<ChangePasswordInput>(ChangePasswordFormSchema),
  })
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error>()

  const [mutate] = useChangeMyPasswordMutation()

  const onSubmit = async (input: ChangePasswordInput): Promise<void> => {
    try {
      await mutate({
        variables: {
          input: {
            newPassword: input.newPassword,
          },
        },
      })
      setIsSuccess(true)
      setError(undefined)
    } catch (e: any) {
      setError(e)

      setIsSuccess(false)
    }
  }

  return (
    <Box>
      {isSuccess ? (
        <NxTypography data-cy="password-change-status" variant="body1">
          Your password has been changed
        </NxTypography>
      ) : isEditOpen ? (
        <form
          data-cy="change-password-form"
          method="post"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box display="flex" flexDirection="row" gridGap="1em">
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  error={!!errors.newPassword}
                  fullWidth
                  helperText={formErrorToString(errors.newPassword)}
                  inputRef={register}
                  label="New Password"
                  name="newPassword"
                  type="password"
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  error={!!errors.confirmPassword}
                  fullWidth
                  helperText={formErrorToString(errors.confirmPassword)}
                  inputRef={register}
                  label="Confirm New Password"
                  name="confirmPassword"
                  type="password"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <NxButton
              data-cy="change-password"
              label="Save"
              type="submit"
              variant="primary"
            />
            <NxButton label="Cancel" onClick={onCancel} variant="secondary" />
          </Box>
        </form>
      ) : (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          gridGap="1em"
          justifyContent="space-between"
        >
          <NxTypography variant="h1">········</NxTypography>
          <NxTooltip
            arrow
            placement="top"
            title={
              isUnderage
                ? 'Only your parent can edit your email or password'
                : ''
            }
          >
            <div>
              <NxButton
                data-testid="edit-button"
                disabled={isUnderage}
                label="Edit"
                onClick={(): void => {
                  onEdit(true)
                }}
                variant="secondary"
              />
            </div>
          </NxTooltip>
        </Box>
      )}
      {error ? (
        <NxTypography variant="body1">
          {cleanGraphQLError(error.message)}
        </NxTypography>
      ) : null}
    </Box>
  )
}
