import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'
import {
  NxButton,
  NxFirebobMessage,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { MatchAssistantAlertType } from '@plvs/utils'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { useStyles } from '../../../MatchAssistant.styles'

import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantStepAlertsContext } from '../../../hooks/useMatchAssistantStepAlerts'

interface GamePreviewProps {
  onNext?(): void

  canChangeRoster?: boolean
  isFinal?: boolean
}

export const GamePreview: React.FC<GamePreviewProps> = ({
  onNext,
  canChangeRoster = true,
  children,
  isFinal,
}) => {
  const styles = useStyles()
  const theme = useTheme()
  const {
    currentStep,
    gameOrdinal = 0,
    gameMode,
    selectedStage,
  } = useMatchAssistantContext()

  const {
    match: { canSpectate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const handleNext = (): void => {
    onNext?.()
  }

  const isNextDisabled = canSpectate || !onNext
  const { openStepAlert, getStepAlerts } = useMatchAssistantStepAlertsContext()
  const stepAlertsData = getStepAlerts()

  const header = gameMode ? (
    <>
      <NxTypography variant="overline">{gameMode}</NxTypography>
      <NxTypography variant="h4">
        {selectedStage?.selectionOption?.displayName}
      </NxTypography>
    </>
  ) : (
    <NxTypography variant="h1">
      Prepare for Game {currentStep?.step?.gameOrdinal}
    </NxTypography>
  )

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {!isFinal && (
          <Box
            borderBottom={`1px solid ${theme.palette.BorderLight}`}
            display="flex"
            flex={1}
            flexDirection="column"
            gridGap={theme.spacing(0.5)}
            justifyContent="center"
            mb={3}
            pb={3}
            textAlign="center"
            width="100%"
          >
            {header}
          </Box>
        )}

        {children}
      </Box>

      {!isFinal && (
        <MatchAssistantFooter>
          <NxFirebobMessage
            message={`Go play game ${gameOrdinal} and come back to report the results.`}
          />

          <Box
            className={styles.ctaContainer}
            display="flex"
            gridGap={theme.spacing(1)}
            width="100%"
          >
            {canChangeRoster && (
              <NxButton
                className={styles.cta}
                disabled={stepAlertsData.actionLoading}
                fullWidth
                label="Change Roster"
                loading={stepAlertsData.actionLoading}
                onClick={(): void =>
                  openStepAlert(MatchAssistantAlertType.Reset)
                }
                variant="secondary"
              />
            )}

            <NxButton
              disabled={isNextDisabled}
              fullWidth
              label="Report Results"
              onClick={handleNext}
              variant="primary"
            />
          </Box>
        </MatchAssistantFooter>
      )}
    </>
  )
}
