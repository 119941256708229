import {
  ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS,
  getContextualResourceIdsFromTeams,
  hasRoleForResource,
  isAdminForSystem,
  isCaptainForResource,
  isCoachAtOrganization,
  isMemberOfOrganization,
  isPlayerForResource,
  isTeamOwnerForResource,
} from '@plvs/utils'
import { MatchStatus, UserRoleName } from '@plvs/graphql/generated'
import {
  MatchActionProps,
  MatchActionReturn,
  MatchLobbyComponentProps,
  MatchLobbyComponentReturn,
  MatchTeamsContext,
} from '../types/matchRenderController.types'
import { UserRolesForMatchLobbyRenderController } from '../../MatchLobbyRenderController.types'

export const determineMatchLobbyComponentsRenderForOrgBasedMatch = ({
  userRoles,
  teamsContext,
  shouldRenderMatchAssistant,
  isOnMissionControl,
}: {
  status: MatchStatus | null | undefined
  userRoles: UserRolesForMatchLobbyRenderController
  teamsContext: MatchTeamsContext
  shouldRenderMatchAssistant: boolean
  isOnMissionControl: boolean
}): {
  shouldRenderMatchReporting: boolean
  shouldShowMatchLobbyTour: boolean
  isScrimmageQueueEnabled: boolean
} => {
  const { ids, schoolIds } = getContextualResourceIdsFromTeams(
    teamsContext.teams
  )
  const isMemberOfAnyOrgInMatch = isMemberOfOrganization(userRoles, [
    ...schoolIds,
  ])

  const isCoachForEitherSchoolOrTeamInMatch = hasRoleForResource(
    userRoles,
    [...ids, ...schoolIds],
    UserRoleName.Coach
  )

  return {
    shouldRenderMatchReporting:
      !shouldRenderMatchAssistant && isCoachForEitherSchoolOrTeamInMatch,
    shouldShowMatchLobbyTour:
      shouldRenderMatchAssistant &&
      isMemberOfAnyOrgInMatch &&
      isOnMissionControl,
    isScrimmageQueueEnabled: true,
  }
}

export const determineMatchLobbyComponentsRenderForNonOrgBasedMatch = ({
  userRoles,
  teamsContext,
  shouldRenderMatchAssistant,
  isOnMissionControl,
}: {
  status: MatchStatus | null | undefined
  userRoles: UserRolesForMatchLobbyRenderController
  teamsContext: MatchTeamsContext
  shouldRenderMatchAssistant: boolean
  isOnMissionControl: boolean
}): {
  shouldRenderMatchReporting: boolean
  shouldShowMatchLobbyTour: boolean
  isScrimmageQueueEnabled: boolean
} => {
  const { ids } = getContextualResourceIdsFromTeams(teamsContext.teams)

  const isParticipantInMatch = hasRoleForResource(userRoles, [...ids])

  const isTeamOwnerForHomeTeam = isTeamOwnerForResource(userRoles, [
    teamsContext.teams[0].id,
  ])
  const isTeamOwnerForAwayTeam = isTeamOwnerForResource(userRoles, [
    teamsContext.teams[1]?.id,
  ])
  return {
    shouldRenderMatchReporting:
      !shouldRenderMatchAssistant &&
      (isTeamOwnerForAwayTeam || isTeamOwnerForHomeTeam),
    shouldShowMatchLobbyTour:
      shouldRenderMatchAssistant && isParticipantInMatch && isOnMissionControl,
    isScrimmageQueueEnabled: false,
  }
}

export const determineMatchLobbyComponentsRender = ({
  status,
  userRoles,
  teamsContext,
  isMatchAssistantEnabled,
  competitionGroup,
  isOnMissionControl,
}: MatchLobbyComponentProps): MatchLobbyComponentReturn => {
  const matchIsNotQuarantined = status !== MatchStatus.Quarantined

  const shouldRenderMA =
    teamsContext.teams.length > 1 &&
    isMatchAssistantEnabled &&
    matchIsNotQuarantined

  if (!competitionGroup || status === MatchStatus.Bye) {
    return {
      shouldRenderMatchReporting: false,
      shouldRenderMatchAssistant: false,
      teamsToRenderMatchAssistantFor: [],
      shouldShowMatchLobbyTour: false,
      isScrimmageQueueEnabled: false,
    }
  }

  if (isAdminForSystem(userRoles ?? [])) {
    return {
      shouldRenderMatchReporting: false,
      shouldRenderMatchAssistant: shouldRenderMA,
      teamsToRenderMatchAssistantFor: teamsContext.teams,
      shouldShowMatchLobbyTour: false,
      isScrimmageQueueEnabled: false,
    }
  }

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    const {
      shouldRenderMatchReporting,
      shouldShowMatchLobbyTour,
      isScrimmageQueueEnabled,
    } = determineMatchLobbyComponentsRenderForOrgBasedMatch({
      status,
      userRoles,
      teamsContext,
      shouldRenderMatchAssistant: shouldRenderMA,
      isOnMissionControl,
    })
    return {
      shouldRenderMatchReporting,
      shouldRenderMatchAssistant: shouldRenderMA,
      teamsToRenderMatchAssistantFor: teamsContext.myTeams.length
        ? teamsContext.myTeams
        : teamsContext.teams,
      shouldShowMatchLobbyTour,
      isScrimmageQueueEnabled,
    }
  }

  const {
    shouldRenderMatchReporting,
    shouldShowMatchLobbyTour,
    isScrimmageQueueEnabled,
  } = determineMatchLobbyComponentsRenderForNonOrgBasedMatch({
    status,
    userRoles,
    teamsContext,
    shouldRenderMatchAssistant: shouldRenderMA,
    isOnMissionControl,
  })
  return {
    shouldRenderMatchReporting,
    shouldRenderMatchAssistant: shouldRenderMA,
    teamsToRenderMatchAssistantFor: teamsContext.myTeams.length
      ? teamsContext.myTeams
      : teamsContext.teams,
    shouldShowMatchLobbyTour,
    isScrimmageQueueEnabled,
  }
}

export const determineMatchAssistantActionsForOrgBasedMatch = ({
  userRoles,
  teamsContext,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  teamsContext: MatchTeamsContext
}): MatchActionReturn => {
  const { ids, schoolIds } = getContextualResourceIdsFromTeams(
    teamsContext.teams
  )
  const isCoachOfEitherTeamsSchool = isCoachAtOrganization(userRoles, [
    ...schoolIds,
  ])

  const isCaptainOfEitherTeam = isCaptainForResource(userRoles, [...ids])

  const isPlayerOfEitherTeam = isPlayerForResource(userRoles, [...ids])

  const canParticipate = isCoachOfEitherTeamsSchool || isPlayerOfEitherTeam

  return {
    canManageMatch: isCoachOfEitherTeamsSchool,
    canReport: isCoachOfEitherTeamsSchool || isCaptainOfEitherTeam,
    canParticipate,
    canSpectate: !canParticipate,
    canDisputeMatch: isCoachOfEitherTeamsSchool,
    canManageRoster: isCaptainOfEitherTeam,
    canQueue: canParticipate,
    rolesThatCanManageRosterCopy: ['coaches', 'captains'],
    showRulebookButton: true,
  }
}

export const determineMatchAssistantActionsForNonOrgBasedMatch = ({
  userRoles,
  teamsContext,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  teamsContext: MatchTeamsContext
}): MatchActionReturn => {
  const { ids } = getContextualResourceIdsFromTeams(teamsContext.teams)
  const isOwnerOfAnyTeam = isTeamOwnerForResource(userRoles, [...ids])

  const canParticipate = hasRoleForResource(userRoles, [...ids])

  return {
    canReport: isOwnerOfAnyTeam,
    canManageMatch: isOwnerOfAnyTeam,
    canParticipate,
    canSpectate: !canParticipate,
    canDisputeMatch: isOwnerOfAnyTeam,
    canManageRoster: isOwnerOfAnyTeam,
    canQueue: false,
    rolesThatCanManageRosterCopy: ['owners'],
    showRulebookButton: false,
  }
}

export const determineMatchAssistantActions = ({
  userRoles,
  teamsContext,
  competitionGroup,
}: MatchActionProps): MatchActionReturn => {
  const isAdmin = isAdminForSystem(userRoles)

  if (isAdmin) {
    return {
      canReport: true,
      canManageMatch: true,
      canParticipate: true,
      canSpectate: false,
      canDisputeMatch: true,
      canManageRoster: false,
      canQueue: false,
      rolesThatCanManageRosterCopy: ['coaches'],
      showRulebookButton: true,
    }
  }

  if (!competitionGroup) {
    return {
      canReport: false,
      canManageMatch: false,
      canParticipate: false,
      canSpectate: false,
      canDisputeMatch: false,
      canManageRoster: false,
      canQueue: false,
      rolesThatCanManageRosterCopy: ['coaches'],
      showRulebookButton: false,
    }
  }

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    return determineMatchAssistantActionsForOrgBasedMatch({
      userRoles,
      teamsContext,
    })
  }

  return determineMatchAssistantActionsForNonOrgBasedMatch({
    userRoles,
    teamsContext,
  })
}
