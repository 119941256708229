import { FormLabel, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Colors, theme } from '@plvs/rally/themes'
import * as analytics from '@plvs/respawn/features/analytics'
import { Dialog } from '@plvs/respawn/features/dialog'
import { UserRoleName } from '@plvs/graphql'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { userRolesToUserType } from '@plvs/utils'
import { Box } from '@plvs/respawn/features/layout'
import { OnboardReferByEmailForm } from '../onboard/OnboardReferByEmailForm'

const useStyles = makeStyles({
  label: {
    fontSize: '1.625em',
    color: Colors.Black,
    fontWeight: 400,
  },
  inviteText: {
    marginBottom: theme.spacing(4.25),
    color: Colors.Grey2,
    fontSize: '1.125em',
    fontWeight: 400,
  },
  closeButton: {
    marginTop: theme.spacing(3.5),
    color: Colors.Grey2,
    fontSize: '1.125em',
    fontWeight: 400,
    cursor: 'pointer',
  },
})

export const EmailReferralModal: React.FC<{
  source: string
  invitedRole: UserRoleName.Student | UserRoleName.Fac | UserRoleName.User
  isOpen: boolean
  onCloseEmailModal: () => void
}> = ({ source, invitedRole, isOpen, onCloseEmailModal }) => {
  const { userRoles } = useUserIdentityFn()
  const [isReferDialogOpen, setIsReferDialogOpen] = React.useState(
    !!isOpen && isOpen
  )
  const classes = useStyles()

  const userRoleType = userRolesToUserType(userRoles)

  const onClose = (): void => onCloseEmailModal()

  if (isReferDialogOpen) {
    analytics.referUserModalDiscovered({
      userRoleType,
    })
  }

  useEffect(() => {
    setIsReferDialogOpen(isOpen)
  }, [isOpen])

  return (
    <Dialog
      DialogContentProps={{
        style: {
          padding: `${theme.spacing(3)}px ${theme.spacing(
            2.5
          )}px ${theme.spacing(3)}px ${theme.spacing(3.5)}px`,
        },
      }}
      onClose={onClose}
      open={isReferDialogOpen}
      PaperProps={{
        style: {
          padding: 0,
        },
      }}
      width={420}
    >
      <Box>
        <Box mb={1.5}>
          <FormLabel className={classes.label}>Refer By Email</FormLabel>
        </Box>
        <NxTypography className={classes.inviteText} variant="body1">
          We‘ll send an invite to your colleagues with your link.
        </NxTypography>
        <OnboardReferByEmailForm
          myUserRoles={userRoles}
          recipientUserRoleName={invitedRole}
          source={source}
        />
        <NxTypography
          className={classes.closeButton}
          onClick={(e): void => {
            e.stopPropagation()
            onClose()
          }}
        >
          Close
        </NxTypography>
      </Box>
    </Dialog>
  )
}
