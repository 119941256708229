import { List, makeStyles, useTheme } from '@material-ui/core'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { formatPhoneNumberWithExt } from '@plvs/utils'
import { NxContactCluster, NxTypography } from '@playvs-inc/nexus-components'
import {
  ContentContextPermissions,
  GrantedRosterMenuPermissions,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuHelpersV2'
import {
  ResourceType,
  useGetCoachAdditionalInfoQuery,
  useGetTeamRosterCoachesQuery,
  UserRoleName,
} from '@plvs/graphql/generated'
import { useAppLocationFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import { Location } from '@plvs/client-data/models'
import { AddCoachToTeamDialog } from '@plvs/respawn/features/manage-teams/AddCoachToTeam'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  TeamCoach,
  UserRole,
  RosterTeamCoaches,
} from '../../RosterProvider.types'
import { RosterRowItem } from '../RosterRowItem'
import { RosterRowFilledCoachMenu } from '../menus/RosterRowFilledCoachMenu'
import { RosterListAddItem } from '../RosterListAddItem'

export interface RosterRowCoachesListProps {
  teamId: string
  getPermissions(
    memberRoles: UserRole[],
    contentContext: ContentContextPermissions,
    isSelf?: boolean
  ): GrantedRosterMenuPermissions
  schoolId: string
  disableAction: boolean
  userId: string
  userRoles: UserRole[]
  isCoachForThisOrg: boolean
  isPublicView: boolean
}

const useStyles = makeStyles((theme) => ({
  roleContainer: {
    position: 'relative',
  },
  tooltipI: {
    position: 'relative',
    top: theme.spacing(1),
  },
  additionalInfoShort: {
    lineHeight: '2em',
    maxHeight: '3em',
    boxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
    lineClamp: 2,
  },
  additionalInfoLong: {
    height: 'auto',
  },
  disclosureLink: {
    cursor: 'pointer',
  },
}))

const ContactEntry: React.FC<{
  userId: string
  schoolId: string
  teamId: string
  coach: TeamCoach
  isCoachForAnOrg: boolean
  isLastNode: boolean
  disableAction: boolean
  coaches: TeamCoach[]
  getPermissions(
    memberRoles: UserRole[],
    contentContext: ContentContextPermissions,
    isSelf?: boolean
  ): GrantedRosterMenuPermissions
  onMutationSuccess(): Promise<void>
}> = ({
  coach,
  coaches,
  isCoachForAnOrg,
  isLastNode,
  disableAction,
  getPermissions,
  onMutationSuccess,
  schoolId,
  userId,
  teamId,
}) => {
  const { id, avatarUrl, name, email, additionalInfo, directRoles } = coach

  const location = useAppLocationFn()
  const isInCheckpoint = location === Location.Checkpoint
  const showContactInfo = isInCheckpoint || isCoachForAnOrg

  const additionalInfoElId = useMemo(
    (): string =>
      `RosterRowCoachesList__ContactEntry__AdditionalInfo.${Date.now()}.${Math.random()}`,
    []
  )

  const [showDisclosure, setShowDisclosure] = useState<boolean>(false)
  const [disclosureOpen, setDisclosureOpen] = useState<boolean>(false)

  const theme = useTheme()
  const classes = useStyles()

  const phoneNumber = showContactInfo
    ? formatPhoneNumberWithExt(coach.phone || '', coach.phoneExt)
    : ''

  // Get primary role to display.
  let displayRoleName = ''
  if (directRoles?.some((r) => r.roleName === UserRoleName.Coach)) {
    displayRoleName = 'Coach'
  } else if (
    directRoles?.some((r) => r.roleName === UserRoleName.PrimaryCoach)
  ) {
    displayRoleName = 'Coach'
  } else if (directRoles?.some((r) => r.roleName === UserRoleName.Fac)) {
    displayRoleName = 'Faculty'
  } else if (directRoles?.some((r) => r.roleName === UserRoleName.Captain)) {
    displayRoleName = 'Captain'
  } else if (directRoles?.some((r) => r.roleName === UserRoleName.Owner)) {
    displayRoleName = 'Owner'
  }

  useLayoutEffect(() => {
    const additionalInfoEl = document.getElementById(additionalInfoElId)
    if (
      !!additionalInfoEl &&
      additionalInfoEl.offsetHeight < additionalInfoEl?.scrollHeight
    ) {
      setShowDisclosure(true)
    }
  }, [])

  return (
    <RosterRowItem
      key={id}
      content={
        <Box
          display="flex"
          flexDirection="column"
          gridGap={`${theme.spacing(2)}px`}
        >
          <NxContactCluster
            avatarHashId={id}
            avatarUrl={avatarUrl || undefined}
            email={email ?? ''}
            name={name ?? ''}
            onClickAvatar={
              isInCheckpoint
                ? (): void => {
                    window.open(`/user/${id}`, '_blank', 'noopener')
                  }
                : undefined
            }
            phone={phoneNumber}
            showPhoneAndEmail={showContactInfo}
            subHeader={displayRoleName}
          />
          <Box display="flex" flexDirection="row" gridGap={theme.spacing(2)}>
            {/* // Spacer */}
            <Box
              display="flex"
              flexBasis={`${theme.spacing(6.75)}px`}
              flexDirection="column"
              flexGrow={0}
              flexShrink={0}
            />
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              gridGap={theme.spacing(2)}
            >
              {!!additionalInfo && (
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={`${theme.spacing(1)}px`}
                >
                  <NxTypography colorToken="ColorTextAlt2" variant="overline">
                    Additional Info
                  </NxTypography>
                  <Box>
                    <NxTypography
                      className={
                        disclosureOpen
                          ? classes.additionalInfoLong
                          : classes.additionalInfoShort
                      }
                      id={additionalInfoElId}
                      variant="body3"
                    >
                      {additionalInfo}
                    </NxTypography>
                    {showDisclosure && (
                      <Box
                        className={classes.disclosureLink}
                        onClick={(): void => setDisclosureOpen((old) => !old)}
                      >
                        <NxTypography
                          colorToken="ColorTextLink"
                          variant="body3"
                        >
                          {disclosureOpen ? 'Show Less' : 'Read More'}
                        </NxTypography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      }
      end={
        disableAction ? null : (
          <RosterRowFilledCoachMenu
            coaches={coaches}
            getPermissions={getPermissions}
            member={coach}
            onMutationSuccess={onMutationSuccess}
            schoolId={schoolId}
            teamId={teamId}
            userId={userId}
          />
        )
      }
      isLastNode={isLastNode}
    />
  )
}

export const RosterRowCoachesList: React.FC<RosterRowCoachesListProps> = ({
  teamId,
  getPermissions,
  schoolId,
  disableAction,
  userId,
  userRoles,
  isCoachForThisOrg,
  isPublicView,
}) => {
  const { isCoachAtOrg } = useUserIdentityFn()
  const isCoachForAnOrg = userRoles.some(
    (role) =>
      role.resourceType === ResourceType.Organization &&
      role.roleName === UserRoleName.Coach
  )

  const { data, loading, refetch } = useGetTeamRosterCoachesQuery({
    variables: {
      teamId,
      isPublic: isPublicView,
    },
    skip: !teamId,
  })

  const teamCoaches = (data?.team?.coaches ?? []) as RosterTeamCoaches

  const {
    data: additionalInfoData,
    loading: loadingAdditionalInfo,
    refetch: refetchAdditionalInfo,
  } = useGetCoachAdditionalInfoQuery({
    variables: {
      teamId,
    },
    skip: !isCoachAtOrg || !teamId || loading,
    // There is an edge case where a user is a coach, but is viewing outside
    // of their league group.  This field is only viewable
    // for coaches at the same league group.
    errorPolicy: 'ignore',
  })

  const teamCoachesWithAdditionalInfo = useMemo(() => {
    return teamCoaches?.map((teamCoach) => ({
      ...teamCoach,
      phone: teamCoach?.isPhoneNumberVisible ? teamCoach.phone : null,
      additionalInfo: additionalInfoData?.team?.coaches?.find(
        (coach) => coach.id === teamCoach.id
      )?.additionalInfo,
    }))
  }, [teamCoaches, additionalInfoData])

  const refetchCoachData = async (): Promise<void> => {
    await refetch()
    await refetchAdditionalInfo()
  }

  return (
    <WaitTillLoaded
      loading={loading || loadingAdditionalInfo}
      showSpinnerWhileLoading
    >
      <List>
        {teamCoachesWithAdditionalInfo?.map((coach, i) => (
          <ContactEntry
            key={coach.id}
            coach={coach}
            coaches={teamCoachesWithAdditionalInfo}
            disableAction={disableAction}
            getPermissions={getPermissions}
            isCoachForAnOrg={isCoachForAnOrg}
            isLastNode={i === teamCoachesWithAdditionalInfo.length - 1}
            onMutationSuccess={refetchCoachData}
            schoolId={schoolId}
            teamId={teamId}
            userId={userId}
          />
        ))}
      </List>
      <Box px={1}>
        {!isCoachForThisOrg || disableAction ? null : (
          <RosterListAddItem
            DialogElement={AddCoachToTeamDialog}
            disabledTooltipTitle="Must be a coach at the school to add coaches"
            onMutationSuccess={refetchCoachData}
            schoolId={schoolId}
            teamId={teamId}
            title="Add a Coach"
            users={teamCoachesWithAdditionalInfo}
          />
        )}
      </Box>
    </WaitTillLoaded>
  )
}
