import React, { useEffect, useState } from 'react'
import { TextField, useTheme } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import {
  NxButton,
  NxTypography,
  NxFirebobMessage,
} from '@playvs-inc/nexus-components'

import {
  GameAssistantSelectionOptionFragment,
  useGetCharacterSelectionOptionsByEsportIdQuery,
  useSubmitGameAssistantStepSelectionsMutation,
  GameAssistantSelection,
} from '@plvs/graphql'
import {
  sortByDisplayName,
  SelectionOption,
  FirebobMessage,
  groupByDisplayName,
  createPlayerObject,
  getMaddenPicks,
  PlayerSelection,
} from '@plvs/utils'
import { logger } from '@plvs/rally/logging'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { SelectedCharacters } from '@plvs/rally/features/match/assistant/steps/smash/components/selectedCharacters/SelectedCharacters'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { Playbook } from '../../components'

export const PlaybookSelect: React.FC = () => {
  const theme = useTheme()
  const {
    isMyTurn,
    currentStep,
    gameOrdinal,
    match,
    setActionsTakenInSteps,
    actionsTakenInSteps,
    teamId: myTeamId,
    myTeam,
    esportSlug,
    isMyTeamHome,
  } = useMatchAssistantContext()
  const { id: stepId = '' } = currentStep?.step ?? {}

  const {
    match: { canParticipate, canSpectate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const [hasSubmitted, setHasSubmitted] = useState(false)

  const {
    data: characterData,
    loading: characterDataLoading,
  } = useGetCharacterSelectionOptionsByEsportIdQuery({
    variables: {
      input: match?.esport?.id ?? '',
    },
    skip: !match?.esport?.id,
  })

  const [
    submitGameAssistantStep,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const selectionOptions = characterData?.characterSelectionOptionsByEsportId?.selectionOptions.find(
    (option) => option.gameOrdinal === gameOrdinal
  )

  const groupedOptions = groupByDisplayName(
    selectionOptions?.selectionOptions ?? []
  )
  const [selectedTeam, setSelectedTeam] = useState<SelectionOption>()
  const [selectedOffense, setSelectedOffense] = useState<SelectionOption>()
  const [selectedDefense, setSelectedDefense] = useState<SelectionOption>()

  useEffect(
    function clearSelections() {
      setSelectedTeam(undefined)
      setSelectedOffense(undefined)
      setSelectedDefense(undefined)
    },
    [currentStep?.step?.id]
  )

  const isNextDisabled =
    !selectedTeam ||
    !selectedOffense ||
    !selectedDefense ||
    canSpectate ||
    updateLoading

  const handleNext = async (): Promise<void> => {
    if (isNextDisabled) {
      return
    }

    setActionsTakenInSteps({
      ...actionsTakenInSteps,
      [stepId]: new Date().toISOString(),
    })

    const selectedOptions = [
      selectedTeam,
      selectedOffense,
      selectedDefense,
    ].filter((selection) => Boolean(selection))

    logger.debug('[PlaybookSelect | handleNext]', selectedOptions)

    try {
      await submitGameAssistantStep({
        variables: {
          input: {
            stepId,
            selectionOptions: selectedOptions.map((option) => ({
              selectionOptionId: option?.id,
            })),
          },
        },
      })
      setHasSubmitted(true)
    } catch (err) {
      logger.error(err)
    }
  }

  const { team: teamPick, offense, defense } = getMaddenPicks({
    options: currentStep?.characterPicks as GameAssistantSelection[],
    teamId: myTeamId,
    gameOrdinal: currentStep?.step?.gameOrdinal,
  })
  const selectedPlayerObj: PlayerSelection = createPlayerObject({
    team: { name: myTeam?.name, logoUrl: myTeam?.avatarUrl },
    selectionOption: teamPick?.selectionOption || selectedTeam,
  })

  const isWaitingForOpponent = (canParticipate && !isMyTurn) || hasSubmitted
  const isSelectDisabled =
    canSpectate || updateLoading || isWaitingForOpponent || hasSubmitted

  const firebobMessage = canParticipate
    ? FirebobMessage.PlaybookSelect
    : FirebobMessage.Spectator

  const getAutocompleteProps = ({
    label,
    onChange,
    options,
  }: {
    label: string
    onChange: React.Dispatch<
      React.SetStateAction<GameAssistantSelectionOptionFragment | undefined>
    >
    options: GameAssistantSelectionOptionFragment[]
  }): Record<string, unknown> => ({
    disabled: isSelectDisabled,
    fullWidth: true,
    getOptionLabel: (option): string => option.displayName,
    renderOption: (params): React.ReactElement => {
      return <NxTypography variant="body1">{params.displayName}</NxTypography>
    },
    renderInput: (params): React.ReactElement => (
      <TextField {...params} label={label} variant="outlined" />
    ),
    onChange: (
      _event: React.ChangeEvent<unknown>,
      value: SelectionOption | null
    ): void => {
      onChange(value || undefined)
    },
    options,
  })

  const fields = (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(2)}
      mb={3}
    >
      <NxTypography variant="overline">Team</NxTypography>

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore Getting needed props from ...spread */}
      <Autocomplete
        data-cy="team-select"
        defaultValue={teamPick?.selectionOption}
        {...getAutocompleteProps({
          label: 'Search NFL Teams',
          onChange: setSelectedTeam,
          options: sortByDisplayName(groupedOptions.Team),
        })}
      />

      <NxTypography variant="overline">Playbooks</NxTypography>

      <Box display="flex" gridGap={theme.spacing(1)} width="100%">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore Getting needed props from ...spread */}
        <Autocomplete
          data-cy="offense-select"
          defaultValue={offense?.selectionOption}
          {...getAutocompleteProps({
            label: 'Offense Playbooks',
            onChange: setSelectedOffense,
            options: sortByDisplayName(groupedOptions['Offense Playbook']),
          })}
        />

        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore Getting needed props from ...spread */}
        <Autocomplete
          data-cy="defense-select"
          defaultValue={defense?.selectionOption}
          {...getAutocompleteProps({
            label: 'Defense Playbooks',
            onChange: setSelectedDefense,
            options: sortByDisplayName(groupedOptions['Defense Playbook']),
          })}
        />
      </Box>
    </Box>
  )

  return (
    <WaitTillLoaded
      loading={characterDataLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      {fields}

      <SelectedCharacters
        awayPlayer={!isMyTeamHome ? selectedPlayerObj : undefined}
        esportSlug={esportSlug}
        hideBlindPick
        homePlayer={isMyTeamHome ? selectedPlayerObj : undefined}
      />

      <Box display="flex" justifyContent="center">
        <Playbook
          src={selectedOffense?.assetUri}
          title={selectedOffense?.displayName}
        />
        <Playbook
          src={selectedDefense?.assetUri}
          title={selectedDefense?.displayName}
        />
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage message={firebobMessage} />

        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        {canParticipate && (
          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label="Confirm Selections"
            loading={isWaitingForOpponent || updateLoading}
            onClick={handleNext}
            variant="primary"
          />
        )}
      </MatchAssistantFooter>
    </WaitTillLoaded>
  )
}
