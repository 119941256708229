import React from 'react'
import clsx from 'clsx'
import { includes } from 'ramda'
import { NxEsportIcon } from '@playvs-inc/nexus-components'
import { EsportRating, MatchFormat, MatchStatus } from '@plvs/graphql/generated'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Box } from '@plvs/respawn/features/layout'
import { INVALID_RESULTS_MATCH_STATUSES } from '@plvs/rally/features/explore/LeagueMatches/leagueMatchesHelpers'
import { LiveIndicator } from '@plvs/rally/features/app/drawer/matches/LiveIndicator'
import { Grid, useTheme } from '@material-ui/core'
import { useNxEsportsContext } from '@playvs-inc/nexus-theme'
import {
  MappedMatch,
  MappedMatchTeam,
} from '@plvs/rally/features/match/schedule/schedule'
import { useStyles } from '../MatchRow.styles'
import { MatchRowResults } from '../MatchRowResults'
import { getIsPastMatch } from '../matchRow.helpers'

interface Props {
  match: MappedMatch
  team1: MappedMatchTeam
  team2: MappedMatchTeam
  MultiTeamCluster?: React.ReactNode
}

export const AvatarsRow: React.FC<Props> = ({
  match,
  team1,
  team2,
  MultiTeamCluster,
}) => {
  const theme = useTheme()

  const isMatchLive = match?.status === MatchStatus.Live

  const esportPalettes = useNxEsportsContext()
  const classes = useStyles({
    isMatchLive,
    showEsportAccent: false,
    esportSlug: match?.esport?.slug,
    esportPalettes,
  })

  const esportSlug = match?.esport?.slug

  const matchResults = match?.matchResults ?? []

  const isPastMatch = getIsPastMatch(match)

  const hasValidResults =
    matchResults.length >= 2 &&
    !includes(match?.status, INVALID_RESULTS_MATCH_STATUSES)

  const team1Lost =
    hasValidResults && isPastMatch && matchResults[0]?.placing === 2
  const team2Lost =
    hasValidResults && isPastMatch && matchResults[1]?.placing === 2

  const isRestricted = match?.esport?.rating === EsportRating.Restricted

  const isLeaderboard = match?.matchFormat === MatchFormat.Leaderboard

  return (
    <>
      <Grid item xs={5}>
        <Box display="flex" justifyContent="flex-end">
          <NxUserAvatar
            avatarUrl={isRestricted ? undefined : team1?.avatarUrl}
            className={clsx(
              classes.avatarLeft,
              team1Lost && classes.opaqueTeam
            )}
            hashId={team1?.id ?? ''}
            size={NxAvatarSize.SM}
          />
        </Box>
      </Grid>
      <Grid item xs={2}>
        {isMatchLive ? (
          <LiveIndicator
            isLive={isMatchLive}
            typographyClassName={classes.liveText}
            typographyVariant="body2"
          />
        ) : (
          <Box display="flex" justifyContent="center">
            {isPastMatch ? (
              <Box alignItems="center" display="flex" mx={1}>
                {hasValidResults ? (
                  <MatchRowResults
                    isLeaderboard={isLeaderboard}
                    myPlacing={
                      match?.teamContext?.myTeamMatchResults?.[0]?.placing
                    }
                    team1Result={matchResults?.find(
                      ({ team }) => team?.id === team1?.id
                    )}
                    team2Result={matchResults?.find(
                      ({ team }) => team?.id === team2?.id
                    )}
                  />
                ) : (
                  <NxEsportIcon
                    data-testid="esport-icon"
                    esportSlug={esportSlug ?? ''}
                    mode={theme.palette.type}
                  />
                )}
              </Box>
            ) : (
              <NxEsportIcon
                data-testid="esport-icon"
                esportSlug={esportSlug ?? ''}
                mode={theme.palette.type}
              />
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={5}>
        {isLeaderboard ? (
          <>{MultiTeamCluster && MultiTeamCluster}</>
        ) : (
          <NxUserAvatar
            avatarUrl={isRestricted ? undefined : team2?.avatarUrl}
            className={clsx(
              classes.avatarRight,
              team2Lost && classes.opaqueTeam
            )}
            hashId={team2?.id ?? ''}
            size={NxAvatarSize.SM}
          />
        )}
      </Grid>
    </>
  )
}
