import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'

import { createPlayerObject, PlayerSelection } from '@plvs/utils'
import { GameAssistantSelection } from '@plvs/graphql'
import { SelectedCharacters } from '@plvs/rally/features/match/assistant/steps/smash/components/selectedCharacters/SelectedCharacters'
import { MatchPreview } from '@plvs/rally/features/match/assistant/steps/general/matchPreview/MatchPreview'
import { noop } from 'ramda-adjunct'
import {
  mapTeamRoster,
  useMatchAssistantContext,
} from '../../../useMatchAssistant'
import { PlayersMatchUp } from '../../../PlayersMatchUp'

interface MatchPreviewProps {
  onNext?(): void

  children?: React.ReactNode
  homeTeamPick: GameAssistantSelection | undefined
  awayTeamPick: GameAssistantSelection | undefined
  customCenter?: React.ReactNode
  showOverlayImage?: boolean
}

export const HeadToHeadMatchPreview: React.FC<MatchPreviewProps> = ({
  onNext = noop,
  homeTeamPick,
  awayTeamPick,
  customCenter,
  showOverlayImage,
  children,
}) => {
  const { homeTeam, awayTeam, esportSlug } = useMatchAssistantContext()

  const homePlayerObj: PlayerSelection = createPlayerObject({
    team: { name: homeTeam?.name, logoUrl: homeTeam?.avatarUrl },
    selectionOption: homeTeamPick?.selectionOption,
  })

  const awayPlayerObj: PlayerSelection = createPlayerObject({
    team: { name: awayTeam?.name, logoUrl: awayTeam?.avatarUrl },
    selectionOption: awayTeamPick?.selectionOption,
  })

  return (
    <MatchPreview onNext={onNext}>
      <SelectedCharacters
        awayPlayer={awayPlayerObj}
        customCenter={customCenter}
        esportSlug={esportSlug}
        homePlayer={homePlayerObj}
        showOverlayImage={showOverlayImage}
      />

      <NxTypography variant="overline">Players</NxTypography>

      {children}

      <PlayersMatchUp
        currentPlayersAwayTeam={mapTeamRoster({
          teamRoster: awayTeam?.starters ?? [],
          teamName: awayTeam?.name ?? '',
        })}
        currentPlayersHomeTeam={mapTeamRoster({
          teamName: homeTeam?.name ?? '',
          teamRoster: homeTeam?.starters ?? [],
        })}
      />
    </MatchPreview>
  )
}
