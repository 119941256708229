import React, { ReactElement, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { Path, Polling } from '@plvs/const'
import {
  QueueStatus,
  useAcceptQueueMutation,
  useCheckPlaceInQueueMutation,
  useDeclineQueueMutation,
  useGetTeamOverviewQuery,
  useJoinQueueMutation,
  useLeaveQueueMutation,
} from '@plvs/graphql/generated'
import { EsportSlug } from '@plvs/graphql/types'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useMatchImageLoader } from '@plvs/respawn/features/esport/creator/utils'

import { makeStyles, useTheme } from '@material-ui/core'
import { MatchQueueStartRow } from '@plvs/rally/features/match/queue/MatchQueueStartRow'
import { MatchQueueWaitingRow } from '@plvs/rally/features/match/queue/MatchQueueWaitingRow'
import { MatchQueueOpponentRow } from '@plvs/rally/features/match/queue/MatchQueueOpponentRow'
import {
  acceptMatchQueueClicked,
  declineMatchQueueClicked,
  matchQueueExpired,
} from '@plvs/respawn/features/analytics/segment/segment'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { openIntercom } from '@plvs/respawn/features/analytics/intercom/intercom'
import { EsrbRating } from '@plvs/respawn/features/match-lobby/esrbRating'
import { QueueRenderControllerProvider } from '@plvs/respawn/renderController/queue/QueueRenderControllerProvider'
import { QueuePageWrapper } from './QueuePageWrapper'
import { initializePushMessaging } from './slotQueuePushHelper'

const useStyles = makeStyles((theme) => ({
  gameSubheader: {
    justifyContent: 'space-around',
    width: '25%',
  },
  barTextColor: {
    color: theme.palette.grey[400],
  },
  header: {
    fontSize: '46.18px',
    paddingBottom: theme.spacing(3),
  },
  support: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export const MatchQueuePage: React.FC = () => {
  const { teamId, slug, defaultQueueStatus } = useParams<{
    teamId: string
    slug: EsportSlug
    defaultQueueStatus: QueueStatus
  }>()
  const { userId } = useUserIdentityFn()

  const mobile = useBreakpointSm()
  const { barTextColor, gameSubheader, header, support } = useStyles()
  const theme = useTheme()

  const navigate = useNavigate()

  const {
    data: teamOverviewData,
    loading: teamOverviewLoading,
  } = useGetTeamOverviewQuery({
    variables: { teamId: teamId ?? '' },
    skip: !teamId,
  })
  const myTeam = teamOverviewData?.team

  const {
    name: esportName,
    bestOf,
    matchOverviewFormat,
    loading: esportLoading,
    hasSeries,
    seriesBestOf,
  } = useGeneralEsportAdapter(slug ?? null)

  const { imageSrc } = useMatchImageLoader({
    esportSlug: slug as EsportSlug,
  })

  const [placeId, setPlaceId] = useState('')
  const [opponentTeamId, setOpponentTeamId] = useState('')
  const [matchId, setMatchId] = useState('')
  const [queueStatus, setQueueStatus] = useState(defaultQueueStatus ?? '')

  const { data: opponentTeamOverviewData } = useGetTeamOverviewQuery({
    variables: { teamId: opponentTeamId },
    skip: !opponentTeamId,
  })
  const opponentTeam = opponentTeamOverviewData?.team

  const [joinQueueMutation] = useJoinQueueMutation()
  const [leaveQueueMutation] = useLeaveQueueMutation()
  const [checkQueueMutation] = useCheckPlaceInQueueMutation()
  const [acceptQueueMutation] = useAcceptQueueMutation()
  const [declineQueueMutation] = useDeclineQueueMutation()
  const lobbyUrl = matchId
    ? generatePath(Path.MatchLobby, {
        matchId,
      })
    : ''

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const checkQueueMutate = async (placeId: string): Promise<void> => {
    const { data } = await checkQueueMutation({
      variables: {
        input: {
          placeId,
        },
      },
    })
    const newStatus = data?.checkPlaceInQueue?.status ?? ''
    setQueueStatus(newStatus)
    if (
      newStatus === QueueStatus.AwaitingOpponent ||
      newStatus === QueueStatus.Accepted ||
      newStatus === QueueStatus.FoundOpponent ||
      newStatus === QueueStatus.OpponentAccepted ||
      newStatus === QueueStatus.Pending
    ) {
      setTimeout(() => checkQueueMutate(placeId), Polling.Fast)
    }
    if (newStatus === QueueStatus.OpponentDeclined) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await joinQueueMutate()
    }
    if (data?.checkPlaceInQueue?.opponentTeamId) {
      setOpponentTeamId(data?.checkPlaceInQueue?.opponentTeamId)
    }
    if (data?.checkPlaceInQueue?.matchId) {
      setMatchId(data?.checkPlaceInQueue?.matchId)
    }
  }

  const navigateToMatch = async (): Promise<void> => {
    navigate(lobbyUrl)
  }

  const acceptMatch = async (): Promise<void> => {
    await acceptQueueMutation({
      variables: {
        input: {
          placeId,
        },
      },
    })
    acceptMatchQueueClicked({
      teamId: teamId ?? '',
      userId,
      timeStamp: new Date().toISOString(),
    })
  }

  const declineMatch = async (): Promise<void> => {
    try {
      await declineQueueMutation({
        variables: {
          input: {
            placeId,
          },
        },
      })
    } finally {
      declineMatchQueueClicked({
        teamId: teamId ?? '',
        userId,
        timeStamp: new Date().toISOString(),
      })
      setPlaceId('')
      setQueueStatus('')
    }
  }

  const onMatchExpired = async (): Promise<void> => {
    matchQueueExpired({
      teamId: teamId ?? '',
      userId,
      timeStamp: new Date().toISOString(),
    })
  }

  const leaveQueueMutate = async (): Promise<void> => {
    try {
      await leaveQueueMutation({
        variables: {
          input: {
            placeId,
          },
        },
      })
    } finally {
      setPlaceId('')
      setQueueStatus('')
    }
  }

  const joinQueueMutate = async (): Promise<void> => {
    const { data } = await joinQueueMutation({
      variables: {
        input: {
          teamId: teamId ?? '',
        },
      },
    })
    setPlaceId(data?.joinQueue?.placeId ?? '')
    checkQueueMutate(data?.joinQueue?.placeId ?? '')
  }

  const handleSupportClick = (): void => {
    openIntercom()
  }

  const renderTeamRow = (): ReactElement => {
    if (
      queueStatus === QueueStatus.FoundOpponent ||
      queueStatus === QueueStatus.OpponentAccepted ||
      queueStatus === QueueStatus.Accepted ||
      queueStatus === QueueStatus.Completed
    ) {
      return (
        <MatchQueueOpponentRow
          onAccept={acceptMatch}
          onDecline={declineMatch}
          onExpire={onMatchExpired}
          onReady={navigateToMatch}
          opponentAccepted={
            queueStatus === QueueStatus.OpponentAccepted ||
            queueStatus === QueueStatus.Completed
          }
          opponentTeam={opponentTeam}
          team={myTeam}
          teamAccepted={
            queueStatus === QueueStatus.Accepted ||
            queueStatus === QueueStatus.Completed
          }
        />
      )
    }
    if (
      queueStatus === QueueStatus.AwaitingOpponent ||
      queueStatus === QueueStatus.Pending ||
      queueStatus === QueueStatus.OpponentDeclined
    ) {
      return <MatchQueueWaitingRow team={myTeam} />
    }

    return <MatchQueueStartRow onEnterQueue={joinQueueMutate} team={myTeam} />
  }

  useEffect(() => {
    initializePushMessaging()
  }, [])

  return (
    <QueueRenderControllerProvider>
      <QueuePageWrapper
        backgroundImageSrc={imageSrc}
        esportSlug={slug as EsportSlug}
        loading={teamOverviewLoading || esportLoading}
        pageDataTestId="MatchQueuePage_Page"
        pageTitle="Match Queue"
      >
        <Box display="flex" flexGrow="0">
          <NxTypography
            className={header}
            colorToken="OverlayColorTextBase"
            variant="display"
          >
            Find a Match
          </NxTypography>
        </Box>

        {mobile ? (
          <></>
        ) : (
          <Box
            alignItems="center"
            className={gameSubheader}
            color={theme.palette.OverlayColorTextBase}
            display="flex"
            flexGrow="0"
          >
            <NxTypography color="inherit" variant="overline">
              best of {hasSeries ? seriesBestOf : bestOf}
            </NxTypography>
            <NxTypography className={barTextColor}>|</NxTypography>
            <NxTypography color="inherit" variant="overline">
              {esportName}
            </NxTypography>
            <NxTypography className={barTextColor}>|</NxTypography>
            <NxTypography color="inherit" variant="overline">
              {matchOverviewFormat}
            </NxTypography>
          </Box>
        )}

        {renderTeamRow()}

        {!mobile && (
          <NxTypography
            align="center"
            className={support}
            colorToken="OverlayColorTextBase"
            onClick={handleSupportClick}
            variant="body1"
          >
            Need help?
            <br />
            Chat with our support!
          </NxTypography>
        )}

        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          flexGrow="0"
          gridGap={theme.spacing(1)}
        >
          {queueStatus === QueueStatus.AwaitingOpponent && (
            <NxButton
              data-cy="leaveQueueButton"
              label="Leave Queue"
              onClick={(): Promise<void> => leaveQueueMutate()}
              shouldUseOverlayColors
              variant="secondary"
            />
          )}

          <NxTypography colorToken="OverlayColorTextBase" variant="body1">
            Please stay on this page to remain in the queue. If you&apos;ve been
            in queue for longer than 10 minutes without an update, please go
            back & check your schedule page. We won&apos;t take you out of queue
            unless you click &quot;Leave Queue&quot;.
          </NxTypography>
          {slug && <EsrbRating esportSlug={slug} mt={3} shouldUseLightText />}
        </Box>
      </QueuePageWrapper>
    </QueueRenderControllerProvider>
  )
}
