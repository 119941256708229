import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { Maybe, EsportRating } from '@plvs/graphql/generated/graphql'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { BoxShadow } from '@plvs/rally/themes'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useNavigate } from 'react-router-dom'
import { NotificationPill, PillVariants } from '../../NotificationPill'
import { openNotification } from './helpers'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      boxShadow: BoxShadow.elevation3,
    },
    transition: 'box-shadow 0.5s ease-in-out',
  },
  title: {
    fontWeight: 600,
  },
  pill: {
    height: '1.37rem',
    fontSize: '.7rem',
    letterSpacing: '0.12rem',
    backgroundColor: theme.palette.ColorBackgroundAlt2,
    color: '',
    '&:hover': {
      backgroundColor: theme.palette.ColorBackgroundAlt2,
      borderColor: theme.palette.ColorBackgroundAlt2,
      color: theme.palette.ColorTextAlt,
    },
    '&:active': {
      boxShadow: 'unset',
    },
  },
  logoContainer: {
    padding: '0.5px',
    height: '2.4rem !important',
    width: '2.4rem !important',
    borderColor: theme.palette.BorderLight,
    borderRadius: '50%',
    transition: 'all 0.7s cubic-bezier(0.395, -0.570, 0.000, 1.285)',
    zIndex: 1,
    '&:not(:first-child)': {
      marginLeft: `-1rem`,
    },
    backgroundColor: theme.palette.ColorBackgroundAlt,
  },
  notificationBodyText: {
    lineHeight: '1.1rem',
    fontWeight: 400,
  },
  timeText: {
    alignSelf: 'center',
    color: theme.palette.ColorTextDisabled,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  statusContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

interface LogoProps {
  avatar1Url?: string
  avatar2Url?: string
  team1Id?: string
  team2Id?: string
  esportRating?: EsportRating
}

const TeamLogos: React.FC<LogoProps> = ({
  avatar1Url,
  avatar2Url,
  team1Id,
  team2Id,
  esportRating,
}) => {
  const classes = useStyles()
  return (
    <Box display="flex" mr={1}>
      <NxUserAvatar
        avatarUrl={esportRating === EsportRating.Restricted ? null : avatar1Url}
        className={classes.logoContainer}
        hashId={team1Id ?? ''}
        size={NxAvatarSize.SM}
      />
      <NxUserAvatar
        avatarUrl={esportRating === EsportRating.Restricted ? null : avatar2Url}
        className={classes.logoContainer}
        hashId={team2Id ?? ''}
        size={NxAvatarSize.SM}
      />
    </Box>
  )
}

export const TeamVsTeamMessage: React.FC<{
  avatar1Url: string
  avatar2Url: string
  team1Id?: string
  team2Id?: string
  esportRating?: EsportRating
  message: string
  messageDates: Maybe<string[]>
  onBeforeClick?: () => void
  pillVariant?: PillVariants
  pillText?: string
  receivedAt?: Date
  title?: string
  url?: string
}> = ({
  avatar1Url,
  avatar2Url,
  message,
  messageDates,
  onBeforeClick,
  receivedAt,
  pillVariant,
  pillText,
  title,
  url,
  team1Id,
  team2Id,
  esportRating = EsportRating.General,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const receivedDate = dayjs(receivedAt)

  let timeText = receivedDate.format('MMM DD, YYYY')
  if (receivedDate.diff(dayjs(), 'hours') > -24) {
    timeText = receivedDate.fromNow()
  }

  const formattedMessage = useMemo(() => {
    let updatedContentMessage = message
    messageDates?.forEach((date) => {
      updatedContentMessage = updatedContentMessage.replace(
        `${date}`,
        `${dayjs(date).format('MMMM D @ h:mmA')}`
      )
    })
    return updatedContentMessage
  }, [message, messageDates])

  return (
    <Box
      className={classes.container}
      display="flex"
      onClick={(): void => {
        if (onBeforeClick) {
          onBeforeClick()
        }
        openNotification(navigate, url)
      }}
      pb={2}
      pl={2}
      pr={3}
      pt={3}
    >
      <TeamLogos
        avatar1Url={avatar1Url}
        avatar2Url={avatar2Url}
        esportRating={esportRating}
        team1Id={team1Id}
        team2Id={team2Id}
      />
      <Box>
        <NxTypography className={classes.notificationBodyText} variant="body1">
          {title && <span className={classes.title}>{title}&nbsp;</span>}
          {formattedMessage}
        </NxTypography>
        <Box className={classes.statusContainer} mt={1}>
          {pillVariant && pillText && (
            <NotificationPill pillText={pillText} variant={pillVariant} />
          )}
          <NxTypography className={classes.timeText} variant="overline">
            {timeText}
          </NxTypography>
        </Box>
      </Box>
    </Box>
  )
}
