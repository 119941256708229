import React, { useCallback } from 'react'

import { ChatRole, MatchFormat } from '@plvs/graphql/generated'
import {
  ChatComponentReturn,
  ChatRenderController,
} from './chat/types/chatRenderController.types'
import { determineChatRender } from './chat/utils/chatRenderController.helpers'
import {
  determineMatchAssistantActions,
  determineMatchLobbyComponentsRender,
} from './match/utils/matchRenderController.helpers'
import {
  MatchActionReturn,
  MatchLobbyComponentReturn,
  MatchRenderController,
} from './match/types/matchRenderController.types'
import { MatchLobbyRenderControllerState } from './MatchLobbyRenderController.types'
import {
  LobbyComponentReturn,
  LobbyRenderController,
  ManageMatchDropdownMenuItemsReturn,
  MatchTeamsDisplayDetails,
} from './lobby/types/lobbyRenderController.types'
import {
  determineLobbyComponentsRender,
  determineHomeAndAwayTeamDisplayDetails,
  determineMenuItemIdsForManageMatchDropdown,
  determineHomeAndAwayTeamDisplayDetailsLeaderboard,
} from './lobby/utils/lobbyRenderController.helpers'

export const defaultMatchLobbyRenderControllerState = {
  match: {
    shouldRenderMatchReporting: false,
    shouldRenderMatchAssistant: false,
    teamsToRenderMatchAssistantFor: [],
    shouldShowMatchLobbyTour: false,
    canManageMatch: false,
    canReport: false,
    canParticipate: false,
    canSpectate: false,
    canDisputeMatch: false,
    canManageRoster: false,
    canQueue: false,
    rolesThatCanManageRosterCopy: [],
    showRulebookButton: false,
    isScrimmageQueueEnabled: false,
  },
  chat: {
    shouldRenderChat: false,
    shouldRenderCoachChat: false,
    chatRole: ChatRole.Player,
  },
  lobby: {
    showManageMatchSection: false,
    showMatchLobbyTour: false,
    showActionAndInfoSection: false,
    canManageQueueBannerActions: false,
    showRescheduleMatchAlert: false,
    canManageQueueBannerActionsCopy: '',
    myTeamIds: [],
    opposingTeamId: '',
    opposingTeamIds: [],
    isMemberOfMoreThanOneTeam: false,
    enableScoutingToTeamsPage: false,
    // If applicable, display the home team first, followed by the away team or the list of teams.
    // If applicable, display my team(s) first, followed by the opposing team(s)
    teamDisplayDetails: [],
    items: [],
  },
}

const placeholderAPI = {
  chat: {
    getChatComponentsToRender: (): ChatComponentReturn => ({
      shouldRenderChat: false,
      shouldRenderCoachChat: false,
      chatRole: ChatRole.Player,
    }),
  },
  match: {
    getMatchLobbyComponentsToRender: (): MatchLobbyComponentReturn => ({
      shouldRenderMatchReporting: false,
      shouldRenderMatchAssistant: false,
      teamsToRenderMatchAssistantFor: [],
      shouldShowMatchLobbyTour: false,
      isScrimmageQueueEnabled: false,
    }),
    getMatchAssistantActions: (): MatchActionReturn => ({
      canManageMatch: false,
      canReport: false,
      canParticipate: false,
      // Note that canSpectate is !canParticipate
      canSpectate: false,
      canDisputeMatch: false,
      // canManageMatch || canDisputeMatch
      canManageRoster: false,
      rolesThatCanManageRosterCopy: [],
      canQueue: false,
      showRulebookButton: false,
    }),
  },
  lobby: {
    getLobbyComponentsToRender: (): LobbyComponentReturn => ({
      showManageMatchSection: false,
      showMatchLobbyTour: false,
      myTeamIds: [],
      opposingTeamId: '',
      opposingTeamIds: [],
      canManageQueueBannerActions: false,
      canManageQueueBannerActionsCopy: '',
      isMemberOfMoreThanOneTeam: false,
      showActionAndInfoSection: false,
      showRescheduleMatchAlert: false,
      enableScoutingToTeamsPage: false,
    }),
    getMatchTeamsDisplayDetails: (): MatchTeamsDisplayDetails => ({
      teamDisplayDetails: [],
    }),
    getMenuItemIdsForManageMatchDropdown:
      (): ManageMatchDropdownMenuItemsReturn => ({ items: [] }),
  },
}

export const determineAPI = (
  matchFormat: MatchFormat | null
): UseMatchLobbyRenderControllerContextReturn => {
  switch (matchFormat) {
    case MatchFormat.HeadToHead:
      return {
        chat: {
          getChatComponentsToRender: determineChatRender,
        },
        match: {
          getMatchLobbyComponentsToRender: determineMatchLobbyComponentsRender,
          getMatchAssistantActions: determineMatchAssistantActions,
        },
        lobby: {
          getLobbyComponentsToRender: determineLobbyComponentsRender,
          getMatchTeamsDisplayDetails: determineHomeAndAwayTeamDisplayDetails,
          getMenuItemIdsForManageMatchDropdown:
            determineMenuItemIdsForManageMatchDropdown,
        },
      }
    case MatchFormat.Leaderboard:
      return {
        chat: {
          getChatComponentsToRender: determineChatRender,
        },
        match: {
          getMatchLobbyComponentsToRender: determineMatchLobbyComponentsRender,
          getMatchAssistantActions: determineMatchAssistantActions,
        },
        lobby: {
          getLobbyComponentsToRender: determineLobbyComponentsRender,
          getMatchTeamsDisplayDetails:
            determineHomeAndAwayTeamDisplayDetailsLeaderboard,
          getMenuItemIdsForManageMatchDropdown:
            determineMenuItemIdsForManageMatchDropdown,
        },
      }
    default:
      return placeholderAPI
  }
}

type UseMatchLobbyRenderControllerContextReturn = {
  match: MatchRenderController
  chat: ChatRenderController
  lobby: LobbyRenderController
}

type MatchLobbyRenderControllerContext =
  UseMatchLobbyRenderControllerContextReturn & {
    getMatchLobbyRenderControllerState: () => MatchLobbyRenderControllerState
    setMatchLobbyRenderControllerStateFn: (
      updatedStateValues: Partial<MatchLobbyRenderControllerState>
    ) => void
  }

export const useMatchLobbyRenderControllerContext = (
  matchFormat: MatchFormat | null
): UseMatchLobbyRenderControllerContextReturn => {
  return determineAPI(matchFormat)
}

export const matchLobbyRenderControllerContext =
  React.createContext<MatchLobbyRenderControllerContext>({
    match: {
      getMatchLobbyComponentsToRender: () => ({
        shouldRenderMatchReporting: false,
        shouldRenderMatchAssistant: false,
        teamsToRenderMatchAssistantFor: [],
        shouldShowMatchLobbyTour: false,
        isScrimmageQueueEnabled: false,
      }),
      getMatchAssistantActions: () => ({
        canReport: false,
        canParticipate: false,
        canSpectate: false,
        canDisputeMatch: false,
        canManageMatch: false,
        canManageRoster: false,
        canQueue: false,
        rolesThatCanManageRosterCopy: [],
        showRulebookButton: false,
      }),
    },
    chat: {
      getChatComponentsToRender: () => ({
        shouldRenderChat: false,
        shouldRenderCoachChat: false,
        // Player is the lowest level of permissions in chat.
        chatRole: ChatRole.Player,
      }),
    },
    lobby: {
      getLobbyComponentsToRender: () => ({
        showManageMatchSection: false,
        showMatchLobbyTour: false,
        myTeamIds: [],
        opposingTeamId: '',
        opposingTeamIds: [],
        isMemberOfMoreThanOneTeam: false,
        showActionAndInfoSection: false,
        canManageQueueBannerActions: false,
        canManageQueueBannerActionsCopy: '',
        showRescheduleMatchAlert: false,
        enableScoutingToTeamsPage: false,
      }),
      getMatchTeamsDisplayDetails: () => ({
        teamDisplayDetails: [],
      }),
      getMenuItemIdsForManageMatchDropdown: () => ({
        items: [],
      }),
    },
    getMatchLobbyRenderControllerState: () =>
      defaultMatchLobbyRenderControllerState,
    setMatchLobbyRenderControllerStateFn: () => {},
  })

export const MatchLobbyRenderControllerProvider: React.FC<{
  matchFormat: MatchFormat | null | undefined
}> = ({ matchFormat = MatchFormat.HeadToHead, children }) => {
  const [matchLobbyRenderControllerState, setMatchLobbyRenderControllerState] =
    React.useState<MatchLobbyRenderControllerState>(
      defaultMatchLobbyRenderControllerState
    )

  const getMatchLobbyRenderControllerState =
    useCallback((): MatchLobbyRenderControllerState => {
      return matchLobbyRenderControllerState
    }, [matchLobbyRenderControllerState])

  const setMatchLobbyRenderControllerStateFn = useCallback(
    (newState: Partial<MatchLobbyRenderControllerState>): void => {
      setMatchLobbyRenderControllerState((prevState) => ({
        ...prevState,
        ...newState,
      }))
    },
    []
  )

  const values = useMatchLobbyRenderControllerContext(matchFormat)

  return (
    <matchLobbyRenderControllerContext.Provider
      value={{
        ...values,
        getMatchLobbyRenderControllerState,
        setMatchLobbyRenderControllerStateFn,
      }}
    >
      {children}
    </matchLobbyRenderControllerContext.Provider>
  )
}

export const useMatchLobbyRenderControllerState = (): {
  getMatchLobbyRenderControllerState: () => MatchLobbyRenderControllerState
  setMatchLobbyRenderControllerStateFn: (
    updatedStateValues: Partial<MatchLobbyRenderControllerState>
  ) => void
} => {
  const {
    getMatchLobbyRenderControllerState,
    setMatchLobbyRenderControllerStateFn,
  } = React.useContext(matchLobbyRenderControllerContext)

  return {
    getMatchLobbyRenderControllerState,
    setMatchLobbyRenderControllerStateFn,
  }
}
