import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  Box,
  useBreakpointXs,
  useWindowDimensions,
} from '@plvs/respawn/features/layout'

import {
  NxCardPaginationButtons,
  NxButton,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { CreateCSSProperties } from '@material-ui/styles'
import { appendClasses, getNow, useAutoskipQuery } from '@plvs/utils'
import {
  TeamEnrollment,
  useFindTeamEnrollmentsByUserQuery,
} from '@plvs/graphql'
import { Path } from '@plvs/const'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

import { useTeamsContext } from '@plvs/rally/containers/team/TeamsProvider'
import { isSafari } from '@plvs/rally/core'
import { useNavigate } from 'react-router-dom'
import EmptyCreateTeamCard from './EmptyCreateTeamCard'
import CreateTeam from '../../../../assets/team-cards/create-team.svg'
import { TeamCardsExploreView } from './TeamCardsExploreView/TeamCardsExploreView'
import { EnrolledTeamCards } from './EnrolledTeamCards'

const useStyles = makeStyles((theme) => ({
  teamCardContainer: ({
    isMobile,
  }: {
    isMobile: boolean
  }): CreateCSSProperties => ({
    overflow: 'hidden',
    position: 'relative',
    height: 'max-content',
    width: isMobile ? '100%' : 'calc(100% + 23px)',
    marginBottom: theme.spacing(7),
  }),
  header: ({ isMobile }: { isMobile: boolean }): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: isMobile ? 'space-between' : 'unset',
    marginBottom: theme.spacing(3),
  }),
  title: ({ isMobile }: { isMobile: boolean }): CreateCSSProperties => ({
    fontSize: isMobile ? '1.4rem' : '2rem',
    fontWeight: isMobile ? 'bold' : 700,
  }),
  teamCards: ({ isMobile }: { isMobile: boolean }): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    minHeight: '15rem',
    height: 'max-content',
    width: '100%',
    scrollSnapType: 'x mandatory',
    scrollPadding: '0',
    overflowX: isMobile ? 'auto' : 'hidden',
    overflowY: 'hidden',
    alignItems: 'stretch',
  }),
  scrollControlContainer: {
    display: 'flex',
    position: 'absolute',
    right: '1.5rem',
    top: '.3rem',
    width: '3.75rem',
    height: '1.5rem',
    justifyContent: 'space-between',
  },
  icon: {
    height: '1.5rem',
  },
  flippedIcon: {
    height: '1.5rem',
    transform: 'rotate(180deg)',
  },
  noTeams: {
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px dashed',
    borderRadius: '10px',
    borderColor: theme.palette.BorderLight,
  },
  EmptyContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'max-content',
    maxWidth: '32rem',
    alignItems: 'center',
    padding: '1rem',
  },
  emptyContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  emptyBody: {
    marginTop: theme.spacing(1),
    fontSize: '0.88rem',
    fontWeight: 400,
    paddingRight: theme.spacing(2),
  },
  button: {
    width: theme.spacing(18),
    marginTop: theme.spacing(1.25),
  },
  scrimmageIcon: {
    height: '5rem',
  },
}))

// Used to match css styling 100vw - 21rem
const TEAMS_CONTAINER_SPACING_SUBTRACTION = 336
const MINIMUM_CARD_SIZE = 280

export const TeamCardsDashboardContainer: React.FC<{
  isCoach: boolean
  isPendingVerification: boolean
}> = ({ isCoach, isPendingVerification }) => {
  const { teams } = useTeamsContext()
  const [visibleTeamsTimeWindow] = useState(getNow({ minuteDifference: -120 }))
  const [isCarouselNavVisible, setIsCarouselNavVisible] = useState(false)

  const [isNextAllowed, setIsNextAllowed] = useState(false)

  const { width } = useWindowDimensions()

  const [currentTeamCardViewed, setCurrentTeamCardViewed] = useState(1)

  const { userId, orgId, isFacultyAtOrg } = useUserIdentityFn()

  const navigate = useNavigate()

  const { data, loading } = useAutoskipQuery(
    useFindTeamEnrollmentsByUserQuery,
    {
      variables: {
        schoolId: orgId ?? '',
        userId: userId ?? '',
        effectiveAt: visibleTeamsTimeWindow,
      },
      skip: !userId || !orgId,
    }
  )

  const teamCardsContainerRef = useRef<HTMLDivElement>()
  const isMobile = useBreakpointXs()
  const baseTeams = isFacultyAtOrg
    ? teams.filter((team) =>
        team?.coaches?.some((coach) => coach?.id === userId)
      )
    : teams
  const teamEnrollments =
    data?.findTeamEnrollmentsByUser?.teamEnrollments?.filter((enrolledTeam) => {
      if (isFacultyAtOrg) {
        return baseTeams.some((bt) => bt.id === enrolledTeam?.team?.id)
      }
      return teams.some((team) => team.id === enrolledTeam?.team?.id)
    }) ?? []
  const hasTeams = teamEnrollments.length > 0 || baseTeams.length > 0
  const classes = useStyles({ isMobile })

  const onShowPreviousCard = (): void => {
    if (currentTeamCardViewed > 1) {
      setCurrentTeamCardViewed(currentTeamCardViewed - 1)
    }
  }
  const onShowNextCard = (): void => {
    if (isNextAllowed) {
      setCurrentTeamCardViewed(currentTeamCardViewed + 1)
    }
  }

  const handleNavigateToCreateTeam = (): void => {
    navigate(Path.CreateTeam)
  }

  const teamsToShow = (
    <EnrolledTeamCards
      baseTeams={baseTeams}
      isCoach={isCoach}
      loading={loading}
      teamEnrollments={teamEnrollments as TeamEnrollment[]}
      userId={userId}
    />
  )

  // Side Effects

  useLayoutEffect(() => {
    if (!teamCardsContainerRef.current) {
      return
    }

    const childTaskCards = teamCardsContainerRef.current?.querySelectorAll(
      'div.TeamCard'
    )

    const focusedTaskCard: Element | undefined =
      childTaskCards?.[currentTeamCardViewed - 1]

    if (!focusedTaskCard) {
      return
    }

    if (
      teamCardsContainerRef?.current?.scrollTo &&
      focusedTaskCard instanceof HTMLElement
    ) {
      teamCardsContainerRef.current.scrollTo({
        left: focusedTaskCard.offsetLeft,
        top: 0,
        behavior: isSafari ? undefined : 'smooth',
      })
    }
  }, [currentTeamCardViewed, loading])

  useEffect(() => {
    const calculatedCardSize = (width - TEAMS_CONTAINER_SPACING_SUBTRACTION) / 3
    const cardSize =
      calculatedCardSize < MINIMUM_CARD_SIZE
        ? MINIMUM_CARD_SIZE
        : calculatedCardSize
    const maxViewableCards = Math.floor(
      (teamCardsContainerRef?.current &&
        teamCardsContainerRef?.current?.clientWidth / cardSize) ||
        0
    )

    const numberOfTeams = isFacultyAtOrg
      ? teamEnrollments?.length
      : teamEnrollments?.length + baseTeams.length

    if (
      !isMobile &&
      teamEnrollments?.length > 0 &&
      maxViewableCards < numberOfTeams + 1
    ) {
      setIsCarouselNavVisible(true)
      if (currentTeamCardViewed < numberOfTeams - maxViewableCards + 2) {
        setIsNextAllowed(true)
      } else {
        setIsNextAllowed(false)
      }
    } else {
      setIsCarouselNavVisible(false)
    }

    // Set to initial card if resizing and all cars fit into view
    if (maxViewableCards === numberOfTeams + 1) {
      setCurrentTeamCardViewed(1)
    }
  }, [currentTeamCardViewed, isMobile, teamEnrollments, width])

  // Render

  if (isPendingVerification) {
    return null
  }

  if (!hasTeams) {
    return <TeamCardsExploreView />
  }

  return (
    <Box className={classes.teamCardContainer}>
      <Box className={classes.header}>
        <NxTypography className={classes.title} variant="h1">
          Your Teams
        </NxTypography>
      </Box>
      {/* Team Cards */}
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore `ref` is a valid prop */}
      <Box
        className={appendClasses(
          classes.teamCards,
          hasTeams || loading ? '' : classes.noTeams
        )}
        data-testid="teams-container"
        // This hack is because the typings for Box is missing 'ref' prop.
        // but it actually exists.  https://github.com/mui-org/material-ui/issues/17010
        {...{ ref: teamCardsContainerRef }}
      >
        {loading || hasTeams ? (
          <>
            {teamsToShow}
            {isCoach && <EmptyCreateTeamCard isMobile={isMobile} />}
            <Box pr={300} />
          </>
        ) : (
          <Box className={classes.EmptyContainer}>
            <img
              alt="Icon"
              className={classes.scrimmageIcon}
              src={CreateTeam}
            />
            <Box className={classes.emptyContent} ml={4.75}>
              <NxTypography variant="h4">
                Your teams will display here
              </NxTypography>
              <NxTypography className={classes.emptyBody}>
                You&apos;ll be able to see your teams status on the dashboard
              </NxTypography>
              {isCoach && (
                <NxButton
                  className={classes.button}
                  label="Create Team"
                  onClick={handleNavigateToCreateTeam}
                  variant="secondary"
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
      {isCarouselNavVisible && (
        <Box className={classes.scrollControlContainer}>
          <NxCardPaginationButtons
            enableLeft={currentTeamCardViewed > 1}
            enableRight={isNextAllowed}
            onLeft={onShowPreviousCard}
            onRight={onShowNextCard}
          />
        </Box>
      )}
    </Box>
  )
}
