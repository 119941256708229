import React from 'react'
import { EnrollmentCard } from '@plvs/rally/containers/manageTeams/EnrollmentCard'
import { getCompetitionIntervalName } from '@plvs/utils'
import { makeStyles } from '@material-ui/core'
import {
  NxPlayVsBanner,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'
import { useManageTeamsContext } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'

import { CompetitionInterval } from './CompetitionInterval'
import { MatchTabs } from './MatchTab'

export const useStyles = makeStyles({
  learnMoreButton: {
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
  },
})
export interface MatchHeaderProps {
  isEnrolled: boolean
}

export const MatchHeader: React.FC = () => {
  const { competitionInterval } = useManageTeamsContext()

  const firstDay = competitionInterval?.firstDay
  const lastDay = competitionInterval?.lastDay

  return (
    <>
      <CompetitionInterval />
      <NxPlayVsBanner
        size="large"
        subtitle={`${
          firstDay && lastDay
            ? getCompetitionIntervalName({
                firstDay,
                lastDay,
              })
            : ''
        }`}
        title="Manage Teams"
        variant={PersonaImageVariant.ManageTeams}
        widget={<EnrollmentCard />}
      />
      <MatchTabs />
    </>
  )
}
