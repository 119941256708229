import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { AuthLogo } from './AuthLogo'
import { AuthSideBanner } from './AuthSideBanner'

interface AuthViewProps {
  children: React.ReactNode
  title: string
  // Defaults to signOn
  bannerImageSrc?: string
  // Defaults to AuthLogo
  Logo?: React.ReactNode
  // Defaults to 'corner'
  logoPosition?: 'corner' | 'center'
}

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
  content: {
    height: 'calc(100vh - 240px)',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(13),
    [theme.breakpoints.down('sm')]: {
      height: 584,
      paddingTop: theme.spacing(9),
    },
  },
}))

export const AuthView = ({
  children,
  title,
  bannerImageSrc = 'https://s3.amazonaws.com/assets.playvs.com/PlayVS/sign-on.webp',
  Logo = <AuthLogo />,
  logoPosition = 'corner',
}: AuthViewProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Grid container>
        <Grid item md={6}>
          <AuthSideBanner
            height="100%"
            position={['unset', 'fixed']}
            src={bannerImageSrc}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          {logoPosition === 'corner' && Logo}

          <Box className={classes.content}>
            {logoPosition === 'center' && (
              <Box alignSelf="center" mb={3.5}>
                {Logo}
              </Box>
            )}

            <NxTypography className={classes.title} variant="display2">
              {title}
            </NxTypography>

            <Box>{children}</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
