import { clone } from 'ramda'

import { Esport, EsportRating, EsportSlug } from '@plvs/graphql'

import { EsportRecord } from '@plvs/respawn/features/esport/creator/esportDesignAssets'

export function getSafeGeneralEsportAdapterDetails<T>(
  slug: EsportSlug | null | undefined,
  data: EsportRecord<T>,
  fallback: T | null = null
): T | null {
  if (slug) {
    const details = data[slug]
    return details ?? fallback
  }
  return null
}

export function getIsYouthProgram(esportRating?: EsportRating | null): boolean {
  return esportRating === EsportRating.Restricted
}

type UtilTeam =
  | {
      school:
        | {
            id: string
            name: string | null
          }
        | null
        | undefined
      avatarUrl: string | null
    }
  | null
  | undefined

export function scrubYouthProgramSchool(team: UtilTeam): UtilTeam {
  const scrubbedTeam = clone(team)

  if (scrubbedTeam) {
    scrubbedTeam.avatarUrl = ''
  }

  if (scrubbedTeam?.school) {
    scrubbedTeam.school.name = ''
  }

  return scrubbedTeam
}

export function scrubSchoolIfYouthProgram(
  team: UtilTeam,
  esportRating?: EsportRating | null
): UtilTeam {
  if (getIsYouthProgram(esportRating)) {
    return scrubYouthProgramSchool(team)
  }

  return team
}

export const ESPORTS_WITH_STATS = [
  EsportSlug.RocketLeague,
  EsportSlug.Splatoon,
  EsportSlug.Madden,
  EsportSlug.Madden21PS4,
  EsportSlug.Madden21SolosXboxOne,
  EsportSlug.Madden21XboxOne,
  EsportSlug.NBA2K,
]
export const ESPORTS_WITH_ROSTER_STATS = [
  EsportSlug.StreetFighter,
  EsportSlug.SmashBrosUltimate,
  EsportSlug.SuperSmashBrosUltimateCrew,
  EsportSlug.SuperSmashBrosUltimateSolos,
]

const UNUSED_MADDEN_SLUGS = [
  EsportSlug.Madden21PS4,
  EsportSlug.Madden21XboxOne,
  EsportSlug.Madden21SolosXboxOne,
]
export const ESPORT_SLUGS_DISPLAY = Object.values(EsportSlug).filter(
  (slug) => !UNUSED_MADDEN_SLUGS.includes(slug)
)

export const getOnboardEsportSlugs = (
  esports: Pick<Esport, 'id' | 'slug'>[]
): EsportSlug[] =>
  esports
    .map((esport) => esport?.slug)
    .filter((slug) => Boolean(slug))
    .filter((slug) => ESPORT_SLUGS_DISPLAY.includes(slug))
