import { Box } from '@plvs/respawn/features/layout'
import {
  QuerySortOrder,
  useMyCoachPlayersQuery,
  UserRoleName,
  UserRoleStatusName,
} from '@plvs/graphql'
import { betterCompact } from '@plvs/utils'

import { LoadingSpinner, NxTypography } from '@playvs-inc/nexus-components'
import {
  Column,
  Table,
  TableColumn,
  TablePagination,
  usePagination,
} from '@plvs/rally/components/table'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { ContainerColumn } from '@plvs/rally/containers/table'
import { map } from 'ramda'
import React, { useEffect } from 'react'
import { DEFAULT_PAGINATION_PAGE_LIMIT } from '@plvs/const'

import { getSchoolEmail } from './helpers'

interface Props {
  filters: { name: string }
  filtersDebouncing: boolean
  setTotalCount: (count: number) => void
}

const columns = [
  Column.UserCluster,
  Column.SchoolEmailCopiable,
  Column.UserGradYear,
  Column.UserJoinDate,
  ContainerColumn.UserAccountConnected,
  Column.UserEsports,
  Column.UserVerifiedPlayerMore,
]

export const PlayersTable: React.FC<Props> = ({
  filters,
  setTotalCount,
  filtersDebouncing,
}) => {
  const { page, getNumPages, setPage, offset } = usePagination({
    itemsPerPage: DEFAULT_PAGINATION_PAGE_LIMIT,
  })
  const { selectedEntityId, roles } = useProfileContext()
  const schoolId = selectedEntityId

  const { data, loading } = useMyCoachPlayersQuery({
    variables: {
      input: {
        filters: {
          name: filters.name,
          roles: {
            roleNames: [UserRoleName.Player],
            roleStatuses: [UserRoleStatusName.Approved],
          },
          schoolId,
        },
        options: {
          limit: DEFAULT_PAGINATION_PAGE_LIMIT,
          offset,
          orderby: [{ field: 'createdAt', order: QuerySortOrder.Asc }],
        },
      },
    },
    skip: !schoolId || filtersDebouncing,
  })

  const players = data?.findUsers?.users ?? []
  const coachId =
    roles.find(
      (role) =>
        role.resourceId === selectedEntityId &&
        role.roleName === UserRoleName.Coach
    )?.userId ?? ''

  const playerData = map(
    (player) => ({ ...player, coachId, schoolId }),
    players
  )
    .map((player) => {
      return {
        ...player,
        email: getSchoolEmail(schoolId, player.emails),
      }
    })
    .sort((a, b) => (a?.firstName ?? '').localeCompare(b?.firstName ?? ''))

  const totalCount = data?.findUsers?.totalCount ?? 0
  const numPages = getNumPages(totalCount)

  const columnsToUse =
    numPages > 1
      ? columns.map(
          (column) => ({ ...column, sortable: false } as TableColumn<unknown>)
        )
      : columns

  const playersToShow = betterCompact(playerData)

  useEffect(() => {
    setTotalCount(totalCount)
  }, [data])

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <LoadingSpinner size="large" />
      </Box>
    )
  }

  return (
    <Box data-cy="players-table">
      {/* Table and pagination */}
      {totalCount > 0 ? (
        <Box>
          <Table columns={columnsToUse} data={playersToShow} />
          <Box my={2}>
            <TablePagination
              numPages={numPages}
              page={page}
              setPage={setPage}
              totalCount={playersToShow.length}
            />
          </Box>
        </Box>
      ) : (
        <Box mb={2} mx={3}>
          <NxTypography variant="body2">
            You do not have any verified players.
          </NxTypography>
        </Box>
      )}
    </Box>
  )
}
