import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { Match } from '@plvs/graphql'

dayjs.extend(tz)

export enum MatchStartTimeline {
  MoreThanFifteenMinBefore = 'moreThanFifteenMinBefore',
  LessThanFifteenMinBefore = 'lessThanFifteenMinBefore',
  LessThanOneHourAfter = 'lessThanOneHourAfter',
  MoreThanOneHourAfter = 'moreThanOneHourAfter',
}

export const getMatchStartTimeline = (
  match?: Pick<Match, 'scheduledStartsAt'> | null
): MatchStartTimeline => {
  const start = dayjs(match?.scheduledStartsAt ?? '')
  const fifteenMinutesBeforeStart = dayjs(start).subtract(15, 'minutes')
  const oneHourAfterStart = dayjs(start).add(1, 'hours')
  if (dayjs().isBefore(fifteenMinutesBeforeStart)) {
    return MatchStartTimeline.MoreThanFifteenMinBefore
  }
  if (dayjs().isAfter(fifteenMinutesBeforeStart) && dayjs().isBefore(start)) {
    return MatchStartTimeline.LessThanFifteenMinBefore
  }
  if (dayjs().isAfter(start) && dayjs().isBefore(oneHourAfterStart)) {
    return MatchStartTimeline.LessThanOneHourAfter
  }
  return MatchStartTimeline.MoreThanOneHourAfter
}

export function getBestOfLabel(
  bestOf: number | null | undefined,
  seriesBestOf: number | null | undefined
): string {
  if (seriesBestOf) {
    return `Best of ${seriesBestOf} Series - ${bestOf} Games`
  }

  return `Best of ${bestOf}`
}
