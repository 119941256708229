import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'
import { isEmpty } from 'ramda'

import { NxTypography } from '@playvs-inc/nexus-components'

import { useRosterContext } from '../../RosterProvider'
import { StatsAvatar } from './StatsAvatar'

export interface NBAStatsProps {
  memberId: string
}

export const RosterRowFilledStatsNBA: React.FC<NBAStatsProps> = ({
  memberId,
}) => {
  const theme = useTheme()

  const { statsByPlayer } = useRosterContext()
  const stats = statsByPlayer[memberId]
  const showStats = stats?.nba2kStats && !isEmpty(stats.nba2kStats)

  return showStats ? (
    <Box mr={1}>
      <Box display="flex" gridGap={theme.spacing(0.5)}>
        {stats?.nba2kStats?.mostPlayedTeams?.map((team) => {
          const initials = team?.name.split(' ') ?? []

          return (
            team && (
              <StatsAvatar
                key={team.name}
                avatar={
                  !team.assetUri ? (
                    <NxTypography data-cy="nba-stat-icon" variant="body4">
                      {initials[0][0]}
                      {initials[1][0]}
                    </NxTypography>
                  ) : undefined
                }
                {...team}
              />
            )
          )
        })}
      </Box>
    </Box>
  ) : (
    <></>
  )
}
