import React from 'react'
import { Card, CardContent, makeStyles } from '@material-ui/core'

import { ReferButton } from '@plvs/rally/containers/onboard'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { School, UserRoleName } from '@plvs/graphql'
import { NxTypography } from '@playvs-inc/nexus-components'

import { CreateCSSProperties } from '@material-ui/styles'
import { ScheduleConsultationButton } from './ConsultationModal/ScheduleConsultationButton'

interface StyleProps {
  isMobile?: boolean
}

const useStyles = makeStyles((theme) => ({
  container: ({ isMobile }: StyleProps): CreateCSSProperties => ({
    display: 'flex',
    maxWidth: isMobile ? 'unset' : '327px',
  }),
  title: {
    color: theme.palette.ColorTextBase || theme.palette.text.primary,
    fontSize: '1.3em',
    fontWeight: 600,
  },
  info: {
    color: theme.palette.ColorTextAlt2,
  },
}))

interface Props {
  isFaculty: boolean
  school?: School
  requiresConsultation?: boolean
  consultationLink?: string
}

const SchoolGrowthCard = ({
  isFaculty,
  school,
  requiresConsultation,
  consultationLink,
}: Props): React.ReactElement => {
  const isMobile = useBreakpointXs()
  const classes = useStyles({ isMobile })

  const inviteCoach =
    requiresConsultation && consultationLink ? (
      <ScheduleConsultationButton link={consultationLink} />
    ) : (
      <ReferButton
        refer={UserRoleName.Fac}
        school={school}
        source="my_school_page"
        variant="secondary"
      />
    )

  const invitePlayer = (
    <ReferButton
      refer={UserRoleName.Student}
      school={school}
      source="my_school_page"
    />
  )

  return (
    <Box className={classes.container} minWidth={327} pl={2}>
      <Card data-cy="schoolGrowthCard" data-testid="schoolGrowthCard">
        <CardContent>
          <Box display="flex" flexDirection="column">
            <NxTypography className={classes.title}>
              Grow the PlayVS Community
            </NxTypography>
            <Box mt={1}>
              <NxTypography className={classes.info} variant="body1">
                Invite coaches and players to join PlayVS! You can invite them
                to your school or another school.
              </NxTypography>
            </Box>

            <Box mt={2}>
              {isFaculty ? inviteCoach : invitePlayer}
              <Box mt={1.5}>{isFaculty ? invitePlayer : inviteCoach}</Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default SchoolGrowthCard
