import React from 'react'
import { Grid, makeStyles, useTheme } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'

import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { RadioField } from '@plvs/rally/components/form'
import { Path } from '@plvs/const'
import { Team, Esport, EsportSlug } from '@plvs/graphql'
import { NxButton, NxMatchDetailsCluster } from '@playvs-inc/nexus-components'
import { CreateScrimmageFormInput } from '@plvs/rally/components/scrimmage/scrimmageHelpers'

import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { Box } from '@plvs/respawn/features/layout/Box'
import { ChooseOpponent } from '../ChooseOpponent'

const useStyles = makeStyles(() => ({
  nowrap: {
    whiteSpace: 'nowrap',
  },
}))

dayjs.extend(tz)

export const CreateScrimmageStepC: React.FC<{
  formValues: CreateScrimmageFormInput
  team:
    | (Pick<Team, 'id' | 'name'> & {
        esport: Pick<Esport, 'id' | 'slug' | 'name'>
      })
    | null
  onReset(): void
  onSubmit(input: CreateScrimmageFormInput): Promise<void>
  setSelectedOpponent(input: Team): void
  selectedOpponent?: Team
  isSubmitting: boolean
  scrimmageId?: string
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>
  bestOf?: number
}> = ({
  formValues,
  team,
  onReset,
  onSubmit,
  setSelectedOpponent,
  selectedOpponent,
  isSubmitting,
  setError,
  scrimmageId,
  bestOf,
}) => {
  const theme = useTheme()
  const styles = useStyles()

  const { handleSubmit, register, watch } = useForm<CreateScrimmageFormInput>({
    defaultValues: formValues,
  })

  const watchOpponentType = watch()?.opponentType ?? 'public'
  const title = 'Choose Opponent'
  const subtitle = ''
  const gridContainerSpacing = 4

  if (!team || !formValues.esport) {
    setError(new Error('Missing data'))
    return <></>
  }

  return (
    <>
      <MatchCard py={4} title={title} titleDetail={subtitle}>
        <form
          data-cy="create-scrimmage-form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box>
            <Grid container spacing={gridContainerSpacing}>
              <Grid item sm={6} xs={12}>
                <RadioField
                  defaultValue={formValues.opponentType}
                  inputRef={register}
                  name="opponentType"
                  options={[
                    {
                      label: 'Open to any team',
                      value: 'public',
                    },
                    { label: 'Choose a specific team', value: 'private' },
                  ]}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <NxMatchDetailsCluster
                  bestOf={bestOf}
                  esport={team.esport.slug ?? EsportSlug.LeagueOfLegends}
                  esportName={team.esport.name ?? ''}
                  showAvatar
                  teamAName={team.name ?? ''}
                  teamBName=""
                  timestamp={formValues.time ?? ''}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            gridGap={theme.spacing(1)}
            justifyContent="space-between"
            mt={3}
          >
            <NxButton label="Back" onClick={onReset} variant="secondary" />
            <Box display="flex" flexDirection="row" gridGap={theme.spacing(1)}>
              <NxButton
                href={Path.Scrimmage}
                label="Cancel"
                variant="secondary"
              />
              {watchOpponentType === 'public' ? (
                <NxButton
                  className={styles.nowrap}
                  disabled={!!(isSubmitting || scrimmageId)}
                  label="Publish & Share"
                  type="submit"
                  variant="primary"
                />
              ) : (
                <NxButton
                  data-cy="sendInviteButton"
                  disabled={!selectedOpponent || !!scrimmageId || isSubmitting}
                  label="Send Invite"
                  type="submit"
                  variant="primary"
                />
              )}
            </Box>
          </Box>
        </form>
      </MatchCard>
      <Box pt={3} />
      {watchOpponentType === 'private' &&
        formValues.requestingTeamId &&
        !scrimmageId && (
          <PenaltyBox>
            <ChooseOpponent
              setSelectedOpponent={setSelectedOpponent}
              teamId={formValues.requestingTeamId}
            />
          </PenaltyBox>
        )}
    </>
  )
}
