import React from 'react'

import { StepType } from '@plvs/utils'
import { MatchAssistantAdapterStepValue } from '@plvs/rally/features/match/assistant/adapter/matchAssistantAdapterSteps'
import { MatchAssistantActions } from '@plvs/rally/features/match/assistant/MatchAssistantActions'
import { Spectator } from '@plvs/rally/features/match/assistant/steps/rocketLeague/spectator/Spectator'
import { PlatformCheck } from '@plvs/rally/features/match/assistant/steps/rocketLeague/platformCheck/PlatformCheck'
import { PrepareForInvite } from '@plvs/rally/features/match/assistant/steps/rocketLeague/prepareForInvite/PrepareForInvite'
import { Scoreboard } from '@plvs/rally/features/match/assistant/steps/rocketLeague/scoreboard/Scoreboard'
import type { MatchAssistantAdapterProps } from './MatchAssistantAdapter.types'

export const rocketLeagueMatchAssistantSteps = ({
  subtitle,
  Actions,
  generalSteps,
  isMatchComplete,
}: MatchAssistantAdapterProps): Record<
  StepType | string,
  MatchAssistantAdapterStepValue
> => {
  return {
    ...generalSteps,
    [StepType.Spectator]: [
      {
        step: <Spectator />,
        title: 'Spectator',
        subtitle,
        actions: <MatchAssistantActions />,
      },
    ],
    [StepType.PlatformCheck]: [
      {
        step: <PlatformCheck />,
        title: 'Platform Check',
        subtitle,
        actions: <MatchAssistantActions />,
      },
    ],
    [StepType.PrepareForInvite]: [
      {
        step: <PrepareForInvite />,
        title: 'Match Preview',
        subtitle,
        actions: <MatchAssistantActions />,
      },
    ],
    [StepType.Report]: [
      {
        step: <Scoreboard isFinal={isMatchComplete} />,
        title: isMatchComplete ? 'Final Score' : 'Scoreboard',
        subtitle: isMatchComplete ? '' : subtitle,
        actions: Actions,
      },
    ],
    [StepType.Scoreboard]: [
      {
        step: <Scoreboard isFinal={isMatchComplete} />,
        title: isMatchComplete ? 'Final Score' : 'Scoreboard',
        subtitle: isMatchComplete ? '' : subtitle,
        actions: Actions,
      },
    ],
    // Repeat of this step for the final step
    [StepType.Final]: [
      {
        step: <Scoreboard isFinal />,
        title: 'Final Score',
        subtitle: '',
        actions: Actions,
      },
    ],
  }
}
