import React from 'react'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { Adduser } from '@playvs-inc/nexus-icons'
import { InvitePlayerModal } from './InvitePlayerModal'

interface Props {
  eventId: string
  teamId: string
}

export const InvitePlayerButton: React.FC<Props> = ({ eventId, teamId }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Box p={3} width="100%">
      <NxButton
        fullWidth
        label={
          <Box alignItems="center" display="flex">
            <Adduser />
            <NxTypography variant="body1">Invite Player</NxTypography>
          </Box>
        }
        onClick={(): void => setOpen(true)}
        variant="secondary"
      />
      <InvitePlayerModal
        eventId={eventId}
        onClose={(): void => setOpen(false)}
        open={open}
        teamId={teamId}
      />
    </Box>
  )
}
