import { Fade, useTheme } from '@material-ui/core'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  useMarkUserNotificationTaskOpenedMutation,
  UserNotification,
} from '@plvs/graphql/generated'
import {
  userNotificationTaskOpened,
  userNotificationTaskRead,
} from '@plvs/respawn/features/analytics'
import React, { useCallback, useEffect, useRef } from 'react'
import { Box } from '@plvs/respawn/features/layout/Box'
import { createNotification } from '../../notifications/notification-factory/notificationFactory'
import { useStyles as useDrawerStyles } from '../AppDrawerToggle.styles'

const TASKS_FADEIN_TIME = 1000

const MarkNotificationTaskRead: React.FC<{
  task: UserNotification
}> = ({ task: notification }) => {
  const hasAlreadyNotifiedRef = useRef<boolean>(false)

  const { userId } = useUserIdentityFn()
  useEffect(() => {
    if (notification?.id && !hasAlreadyNotifiedRef.current) {
      userNotificationTaskRead({
        timeStamp: new Date().toDateString(),
        userId: userId || '',
        userNotificationId: notification.id,
        userNotificationType: notification.type,
      })
      hasAlreadyNotifiedRef.current = true
    }
  }, [notification])
  return <></>
}

export const NotificationTasks: React.FC<{
  isAnimating?: boolean
  isMobile: boolean
  onNotificationClick?: () => void
  tasks: UserNotification[]
}> = ({ isAnimating = false, isMobile, onNotificationClick, tasks }) => {
  const { cardContainer, notificationTasks } = useDrawerStyles({ isMobile })
  const theme = useTheme()

  const { userId } = useUserIdentityFn()

  const [
    markNotificationTaskOpened,
  ] = useMarkUserNotificationTaskOpenedMutation()

  const handleNotificationClick = useCallback(
    (task: UserNotification): void => {
      onNotificationClick?.()

      userNotificationTaskOpened({
        timeStamp: new Date().toDateString(),
        userId: userId || '',
        userNotificationId: task.id,
        userNotificationType: task.type,
        firstOpened: !task.openedAt,
      })

      markNotificationTaskOpened({
        variables: {
          notificationId: task.id,
        },
      })
    },
    [
      userNotificationTaskOpened,
      markNotificationTaskOpened,
      onNotificationClick,
      userId,
    ]
  )

  return (
    <Fade in={isAnimating} timeout={{ enter: TASKS_FADEIN_TIME }}>
      <Box className={notificationTasks} ml={isMobile ? 2 : 0}>
        {tasks.map((task) => {
          return (
            <Box
              key={`${task.id}-task`}
              className={cardContainer}
              data-testid="NotificationsDrawer__Notifications__TaskEntry"
              mb={2}
              onClickCapture={(): void => {
                handleNotificationClick(task)
              }}
              position="relative"
            >
              {createNotification(task, theme.palette.type === 'dark')}
              <MarkNotificationTaskRead task={task} />
            </Box>
          )
        })}
      </Box>
    </Fade>
  )
}
