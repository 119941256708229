import React, { useEffect, useState } from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'

import {
  NxTypography,
  NxButton,
  NxFirebobMessage,
  NxScoreboardRows,
} from '@playvs-inc/nexus-components'

import {
  MatchSettleRequestFragment,
  MatchSettleRequestStatus,
  useFetchMatchSettleRequestQuery,
  useGetSmashUltimateStatsQuery,
  useMatchSettleRequestUpdatedSubscription,
  SmashUltimateStatsPlayerFragment,
} from '@plvs/graphql'

import {
  getContextualTeams,
  getPickForGameOrdinal,
  StepType,
} from '@plvs/utils'
import { RosterRowFilledStatsSmash } from '@plvs/respawn/features/roster/rosterChildren/stats'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useStyles } from '../../../MatchAssistant.styles'

interface GameIntermissionProps {
  onNext?(): void
  isSeriesIntermission?: boolean
  buttonText?: string
  firebobMessage?: string
}

export const GameIntermission: React.FC<GameIntermissionProps> = ({
  onNext,
  isSeriesIntermission,
  buttonText = 'Choose Your Character',
  firebobMessage = 'Get ready to select your characters!',
}) => {
  const theme = useTheme()
  const styles = useStyles()
  const {
    currentPlayerOptions,
    matchId,
    gameOrdinal,
    match,
    actionsTakenInSteps,
    setActionsTakenInSteps,
    currentStep,
    currentStepLoading,
    step,
    seriesOrdinal,
    matchSeasonId,
    homeTeam,
    awayTeam,
    characterSelections,
  } = useMatchAssistantContext()

  const { data: smashStats, loading } = useGetSmashUltimateStatsQuery({
    variables: {
      input: {
        playerIds: [...(homeTeam?.roster ?? []), ...(awayTeam?.roster ?? [])]
          .map((roster) => roster.id)
          .filter((userId) => Boolean(userId)),
        seasonId: matchSeasonId,
      },
    },
    skip: !matchSeasonId,
  })

  const smashStatsByPlayer =
    smashStats?.getSmashUltimateMostPlayedStatsByPlayerIds.players.reduce(
      (acc, player) => {
        acc[player.playerId] = player
        return acc
      },
      {} as Record<string, SmashUltimateStatsPlayerFragment>
    ) ?? {}

  const [
    matchSettleRequest,
    setMatchSettleRequest,
  ] = useState<MatchSettleRequestFragment | null>(null)
  const [loadingMatchSettleRequest, setIsLoadingMatchSettleRequest] = useState<
    boolean
  >(true)

  const { data: incomingMatchSettleRequest } = useFetchMatchSettleRequestQuery({
    variables: {
      input: {
        matchId,
        status: MatchSettleRequestStatus.Incomplete,
      },
    },
    skip: !matchId,
    fetchPolicy: 'network-only',
  })

  const matchSettleRequestUpdated = useMatchSettleRequestUpdatedSubscription({
    variables: {
      input: {
        matchId,
      },
    },
    skip: !matchId,
  })

  const seriesResults = matchSettleRequest?.results?.seriesResults
  const hasSeriesResults = Boolean(seriesResults?.length)

  // Only show upcoming player after we've picked the player
  const currentPlayerOptionsDisplay =
    step !== StepType.PlayerSelect
      ? Array({
          leftSide: {
            ...currentPlayerOptions[0],
            subtitles: [
              ...(currentPlayerOptions[0]?.subtitles ?? []),
              {
                title: (
                  <RosterRowFilledStatsSmash
                    memberId={currentPlayerOptions[0]?.id}
                    reverseStats
                    smashStatsByPlayer={smashStatsByPlayer}
                  />
                ),
              },
            ],
          },
          rightSide: {
            ...currentPlayerOptions[1],
            subtitles: [
              ...(currentPlayerOptions[1]?.subtitles ?? []),
              {
                title: (
                  <RosterRowFilledStatsSmash
                    memberId={currentPlayerOptions[1]?.id}
                    reverseStats
                    smashStatsByPlayer={smashStatsByPlayer}
                  />
                ),
              },
            ],
          },
          title: 'Next Up',
          showScore: false,
          customMiddle: (
            <Box className={styles.desktopOnly} px={2}>
              <NxTypography variant="h4">vs</NxTypography>
            </Box>
          ),
        })
      : []

  const handleNext = (): void => {
    if (currentStep?.step?.id) {
      setActionsTakenInSteps({
        ...actionsTakenInSteps,
        [currentStep.step.id]: new Date().toISOString(),
      })
    }
    onNext?.()
  }

  useEffect(() => {
    const updatedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
        ?.matchSettleRequest
    if (updatedMatchSettleRequest) {
      setMatchSettleRequest(updatedMatchSettleRequest ?? null)
      setIsLoadingMatchSettleRequest(false)
    }
  }, [matchSettleRequestUpdated])

  useEffect(() => {
    const returnedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      incomingMatchSettleRequest?.fetchMatchSettleRequest
        ?.matchSettleRequest?.[0]
    if (incomingMatchSettleRequest) {
      setMatchSettleRequest(returnedMatchSettleRequest ?? null)
      setIsLoadingMatchSettleRequest(false)
    }
  }, [incomingMatchSettleRequest])

  const seriesReturn = hasSeriesResults
    ? seriesResults?.map((result) => {
        const currentSeriesOrdinal = result?.ordinalNumber ?? 0
        const gameResults = result?.gameResults.map((game) => {
          const series = match?.series?.[(currentSeriesOrdinal ?? 0) - 1]
          const {
            team1: seriesHomeTeam,
            team2: seriesAwayTeam,
          } = getContextualTeams(series?.teamContext?.teams)

          const homeCharacterPick = getPickForGameOrdinal({
            teamId: seriesHomeTeam?.id,
            gameOrdinal: game.ordinalNumber,
            seriesOrdinal: currentSeriesOrdinal,
            options: characterSelections,
          })
          const leftSide = {
            title: seriesHomeTeam?.roster?.[0]?.name ?? '',
            subtitles: [
              { title: seriesHomeTeam?.name ?? '' },
              {
                title: (
                  <RosterRowFilledStatsSmash
                    memberId={seriesHomeTeam?.roster?.[0].id ?? ''}
                    reverseStats
                    smashStatsByPlayer={smashStatsByPlayer}
                  />
                ),
              },
            ],
            avatarUrl:
              homeCharacterPick?.selectionOption?.assetUri ??
              seriesHomeTeam?.roster?.[0]?.avatarUrl ??
              undefined,
            id: seriesHomeTeam?.id ?? '',
            score: game.winningTeamId === seriesHomeTeam?.id ? 1 : 0,
          }

          const awayCharacterPick = getPickForGameOrdinal({
            teamId: seriesAwayTeam?.id,
            gameOrdinal: game.ordinalNumber,
            seriesOrdinal: currentSeriesOrdinal,
            options: characterSelections,
          })
          const rightSide = {
            title: seriesAwayTeam?.roster?.[0]?.name ?? '',
            subtitles: [
              { title: seriesAwayTeam?.name ?? '' },
              {
                title: (
                  <RosterRowFilledStatsSmash
                    memberId={seriesAwayTeam?.roster?.[0].id ?? ''}
                    reverseStats
                    smashStatsByPlayer={smashStatsByPlayer}
                  />
                ),
              },
            ],
            avatarUrl:
              awayCharacterPick?.selectionOption?.assetUri ??
              seriesAwayTeam?.roster?.[0]?.avatarUrl ??
              undefined,
            id: seriesAwayTeam?.id ?? '',
            score: game.winningTeamId === seriesAwayTeam?.id ? 1 : 0,
          }

          return {
            title: `Game ${game.ordinalNumber}`,
            leftSide,
            rightSide,
          }
        })

        return (
          <Box key={currentSeriesOrdinal} pb={2}>
            <NxTypography className={styles.seriesTitle} variant="body2">
              Series {currentSeriesOrdinal}
            </NxTypography>

            <NxScoreboardRows
              data={gameResults}
              gridGap={theme.spacing(3)}
              requireTitle
            />
          </Box>
        )
      })
    : null

  return (
    <WaitTillLoaded
      loading={loadingMatchSettleRequest || currentStepLoading || loading}
      showSpinnerWhileLoading
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        mb={3}
        position="sticky"
      >
        <NxTypography variant="h1">
          Prepare for{' '}
          {isSeriesIntermission
            ? `Series ${seriesOrdinal}`
            : `Game ${gameOrdinal}`}
        </NxTypography>

        <Box pt={2}>
          <NxScoreboardRows data={currentPlayerOptionsDisplay} requireTitle />
        </Box>
      </Box>

      {seriesReturn && (
        <Box
          borderBottom={`1px solid ${theme.palette.BorderLight}`}
          mx={-3}
          my={4}
        />
      )}

      <Box maxHeight="400px" overflow="auto">
        {seriesReturn}
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage message={firebobMessage} />

        <NxButton
          className={styles.cta}
          fullWidth
          label={buttonText}
          onClick={handleNext}
          variant="primary"
        />
      </MatchAssistantFooter>
    </WaitTillLoaded>
  )
}
