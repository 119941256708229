import { Button } from '@material-ui/core'
import React from 'react'

import { Box } from '@plvs/respawn/features/layout'
import { EsportSlug, ForfeitReason } from '@plvs/graphql'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import {
  NxMatchDetailsCluster,
  NxSelect,
  NxSelectOption,
} from '@playvs-inc/nexus-components'
import { getContextualTeams, LobbyMatch } from '@plvs/utils'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { CancelMatch } from './cancelMatch'

export const ForfeitMatchSelectReasonStep: React.FC<{
  reason: ForfeitReason | undefined
  match: CancelMatch | NonNullable<LobbyMatch>
  onClose(): void
  setReason(input: ForfeitReason): void
  esportSlug: EsportSlug | null | undefined
}> = ({ reason, match, onClose, setReason, esportSlug }) => {
  const { name: esportName } = useGeneralEsportAdapter(esportSlug)
  const matchTime = match.scheduledStartsAt

  const { team1, team2 } = getContextualTeams(match?.teamContext?.teams)
  const team1Name = team1?.name ?? ''
  const team2Name = team2?.name ?? ''

  return (
    <>
      <NxMatchDetailsCluster
        bestOf={match?.bestOf ?? undefined}
        esport={esportSlug ?? ''}
        esportName={esportName}
        showAvatar={false}
        teamAName={team1Name}
        teamBName={team2Name}
        timestamp={matchTime ?? ''}
      />
      <Box pt={2}>
        <NxSelect
          data-cy="forfeit-list"
          fullWidth
          label="Reason for forfeit"
          onChange={(e): void => {
            const newReason = e.target.value as ForfeitReason
            setReason(newReason)
          }}
          value={reason}
        >
          <NxSelectOption value={ForfeitReason.TechnologyIssues}>
            Technology Issues
          </NxSelectOption>
          <NxSelectOption value={ForfeitReason.NotEnoughPlayers}>
            Not enough players
          </NxSelectOption>
          <NxSelectOption value={ForfeitReason.SchoolClosure}>
            School closure
          </NxSelectOption>
          <NxSelectOption value={ForfeitReason.CovidSchoolClosure}>
            COVID-19 School Closure
          </NxSelectOption>
          <NxSelectOption value={ForfeitReason.Other}>Other</NxSelectOption>
        </NxSelect>

        {reason === ForfeitReason.NotEnoughPlayers && (
          <Box py={1}>
            <Banner
              subtitle="We recommend playing the match anyway – it’s great practice!"
              title="Only missing one player?"
              type={BannerType.Warning}
            >
              <Button onClick={onClose} variant="outlined">
                Play Match Anyway
              </Button>
            </Banner>
          </Box>
        )}
      </Box>
    </>
  )
}
