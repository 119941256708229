import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'
import { NxTypography, NxUserCluster } from '@playvs-inc/nexus-components'
import { GamePreview } from '@plvs/rally/features/match/assistant/steps/general/gamePreview/GamePreview'

import { useMatchAssistantContext } from '../../../useMatchAssistant'

interface HeadToHeadGamePreviewProps {
  onNext?(): void
}

export const HeadToHeadGamePreview: React.FC<HeadToHeadGamePreviewProps> = ({
  onNext,
}) => {
  const theme = useTheme()
  const {
    homeTeam,
    awayTeam,
    gameOrdinal,
    charactersByUserId,
  } = useMatchAssistantContext()

  return (
    <GamePreview onNext={onNext}>
      <Box
        alignItems="center"
        display="flex"
        gridGap={theme.spacing(3)}
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(3)}
          justifyContent="flex-end"
          textAlign="right"
        >
          <NxTypography variant="overline">{homeTeam?.name}</NxTypography>

          {homeTeam?.starters?.map((starter) => (
            <NxUserCluster
              key={starter.id}
              alignment="right"
              avatarHashId={starter.id}
              avatarUrl={starter.avatarUrl}
              subHeader={
                charactersByUserId[starter.id]?.[gameOrdinal]?.selectionOption
                  ?.displayName
              }
              subtitles={[{ title: starter.publisherAccountName }]}
              title={starter.name}
            />
          ))}
        </Box>

        <NxTypography variant="h4">vs</NxTypography>

        <Box
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(3)}
          justifyContent="flex-end"
        >
          <NxTypography variant="overline">{awayTeam?.name}</NxTypography>

          {awayTeam?.starters?.map((starter) => (
            <NxUserCluster
              key={starter.id}
              avatarHashId={starter.id}
              avatarUrl={starter.avatarUrl}
              subHeader={
                charactersByUserId[starter.id]?.[gameOrdinal]?.selectionOption
                  ?.displayName
              }
              subtitles={[{ title: starter.publisherAccountName }]}
              title={starter.name}
            />
          ))}
        </Box>
      </Box>
    </GamePreview>
  )
}
