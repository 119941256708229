import React, { useCallback } from 'react'
import {
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  useTheme,
  Hidden,
  Box,
} from '@material-ui/core'
import {
  ResourceType,
  useReferByEmailMutation,
  UserRoleName,
  CompetitionGroup,
  ReferByEmailInput,
  UserRole,
  School,
} from '@plvs/graphql'

import copy from 'clipboard-copy'
import { useSnackbar } from 'notistack'
import {
  NxTypography,
  NxTextInput,
  NxButton,
} from '@playvs-inc/nexus-components'
import { GlobalReferralPlayers } from '@plvs/rally/features/player/playerInvite/GlobalReferralPlayers'
import { Duplicate } from '@playvs-inc/nexus-icons'

import * as analytics from '@plvs/respawn/features/analytics'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  cleanGraphQLError,
  formErrorToString,
  yupEmail,
  yupEmailRequired,
} from '@plvs/utils'
import { yupResolver } from '@hookform/resolvers'
import { useBreakpointSm } from '@plvs/respawn/features/layout'

const useStyles = makeStyles((theme) => ({
  playerInviteContainer: {
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.mixins.boxShadow.elevation2,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  referralLink: {
    backgroundColor: `${theme.palette.ColorBackgroundBase} !important`,
    border: `1px solid ${theme.palette.BorderLight}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    '& .MuiFilledInput-input': {
      padding: 'unset',
    },
    '& input:disabled': {
      color: theme.palette.ColorTextBase,
      padding: 'unset',
    },
  },
  copyAdornment: {
    cursor: 'pointer',
  },
  emailCopy: {
    margin: theme.spacing(2, 0),
    color: theme.palette.ColorTextAlt2,
  },
  emailInput: {
    backgroundColor: `${theme.palette.ColorBackgroundBase} !important`,
    border: `1px solid ${theme.palette.BorderDark}`,
    borderRadius: theme.shape.borderRadius,
  },
  submitButton: {
    zIndex: 10,
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(-1.5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
      height: theme.spacing(6),
    },
  },
}))

const OnboardInviteByEmailFormSchema = yup.object().shape({
  email: yupEmailRequired,
})

type FormInput = Pick<ReferByEmailInput, 'email'>

export const PlayerInviteComponent: React.FC<{
  school?: School
  link: string
}> = ({ school, link }) => {
  const isMobile = useBreakpointSm()
  const theme = useTheme()
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [isBusy, setIsBusy] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error>()
  const [email, setEmail] = React.useState('')

  const [mutate] = useReferByEmailMutation()

  const { enqueueSnackbar } = useSnackbar()
  const { errors, handleSubmit, register, reset, getValues } = useForm<
    FormInput
  >({
    resolver: yupResolver<FormInput>(OnboardInviteByEmailFormSchema),
  })

  const classes = useStyles()

  const copyWithConfirmation = (): void => {
    copy(link)
      .then((): void => {
        enqueueSnackbar('Copied to clipboard', {
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          autoHideDuration: 2000,
        })
      })
      .catch((): void => {
        enqueueSnackbar('Error copying to clipboard', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          persist: true,
        })
      })
  }

  const onFormUpdate = async (): Promise<void> => {
    const values = getValues()
    try {
      await yupEmail.validate(values.email)
      setEmail(values.email)
      setIsBusy(false)
    } catch (e: any) {
      setError(e)
      setIsBusy(true)
    }
  }

  const onSendInvite = useCallback(async (): Promise<void> => {
    setError(undefined)
    setIsBusy(true)

    try {
      await mutate({
        variables: {
          input: {
            resourceId: school?.id ?? '',
            resourceType: ResourceType.Organization,
            email,
            roleName: UserRoleName.Student,
            source: 'global_referral',
            competitionGroup: CompetitionGroup.HighSchool,
          },
        },
      })

      analytics.inviteByEmail({
        inviteeEmails: [email],
        inviteeUserType: UserRoleName.Player,
        myUserRoles: [{ roleName: UserRoleName.Coach } as UserRole],
      })
      setIsSuccess(true)
      reset()
    } catch (e: any) {
      setError(e)
      setIsSuccess(false)
      setIsBusy(false)
    }
  }, [email, school])

  const onSubmit = handleSubmit(
    async (): Promise<void> => {
      return onSendInvite()
    }
  )

  return (
    <Grid className={classes.playerInviteContainer} container>
      <Grid item md={7} xs={12}>
        <GlobalReferralPlayers />
      </Grid>
      <Grid className={classes.linkContainer} item md={5} xs={12}>
        <TextField
          data-testid="PlayerInviteComponent__ReferralLink"
          defaultValue=""
          disabled
          fullWidth
          InputProps={{
            className: classes.referralLink,
            endAdornment: (
              <InputAdornment position="end">
                <Duplicate
                  className={classes.copyAdornment}
                  color={theme.palette.ColorIconAlt}
                  onClick={copyWithConfirmation}
                />
              </InputAdornment>
            ),
          }}
          value={link}
          variant="filled"
        />
        <NxTypography className={classes.emailCopy} variant="body3">
          or via email
        </NxTypography>
        <form noValidate onChange={onFormUpdate} onSubmit={onSubmit}>
          {error && cleanGraphQLError(error.message)}
          <NxTextInput
            ref={register}
            endAdornment={
              isMobile ? (
                <Box />
              ) : (
                <NxButton
                  className={classes.submitButton}
                  disabled={isBusy}
                  label="SUBMIT"
                  onClick={onSendInvite}
                  type="submit"
                  variant="primary"
                />
              )
            }
            fullWidth
            helperText={formErrorToString(errors.email)}
            label="Email"
            name="email"
            placeholder={
              isSuccess ? 'Success! Insert another email' : 'Type email'
            }
            type="text"
            variant="default"
          />

          <Hidden mdUp>
            <NxButton
              className={classes.submitButton}
              disabled={isBusy}
              label="SUBMIT"
              onClick={onSendInvite}
              type="submit"
              variant="primary"
            />
          </Hidden>
        </form>
      </Grid>
    </Grid>
  )
}
