import { makeStyles } from '@material-ui/core'
import { Box, Hidden, useBreakpointXs } from '@plvs/respawn/features/layout'
import {
  NxTypography,
  NxButton,
  NxDropdownButton,
  NxTooltip,
  NxCheckbox,
} from '@playvs-inc/nexus-components'
import React, { useState } from 'react'

import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'

import {
  ChecklistItemPayload,
  refetchGetChecklistQuery,
  useCompleteChecklistItemMutation,
  useGetCheckCompletedUserQuery,
  useGetHelpLinksQuery,
  useUncompleteChecklistItemMutation,
} from '@plvs/graphql/generated'
import { IntercomArticleMappings, PLAYVS_HELP_ARTICLE_REGEX } from '@plvs/const'
import { CreateCSSProperties } from '@material-ui/styles'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { isCoachAtOrganization } from '@plvs/utils'

const useStyles = makeStyles((theme) => ({
  listItemContainer: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.BorderLight}`,
    },
  },
  checkBox: ({
    isCompletedByDifferentCoach,
  }: {
    isCompletedByDifferentCoach: boolean
  }): CreateCSSProperties => ({
    '&.Mui-checked': {
      color: isCompletedByDifferentCoach
        ? theme.palette.ColorIconDisabled
        : theme.palette.ColorIconBase,
    },
  }),
  labelText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'unset',
    },
  },
  button: {
    height: theme.spacing(5.25),
    minWidth: '190px',
    width: '190px',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      width: '100%',
      maxWidth: 'unset',
    },
  },
  mobileLabel: {
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  tooltipType: {
    color: theme.palette.common.white,
  },
}))

interface ChecklistItemProps {
  isCompleted: boolean
  schoolId: string
  userId: string
  item: ChecklistItemPayload
  step: number
  allAvailableEsportsNames?: (string | null)[]
}

export const ChecklistItem: React.FC<ChecklistItemProps> = ({
  isCompleted,
  schoolId,
  userId,
  item,
  step,
  allAvailableEsportsNames,
}) => {
  const isMobile = useBreakpointXs()
  const isCompletedByDifferentCoach = !!(
    isCompleted &&
    item.completedById &&
    userId !== item.completedById
  )
  const [checked, setIsChecked] = useState(isCompleted)
  const classes = useStyles({ isCompletedByDifferentCoach })
  const refetchQueries = [refetchGetChecklistQuery({ schoolId })]
  const { data: linksData } = useGetHelpLinksQuery()

  const { userRoles } = useUserIdentityFn()
  const isCoachForOrg = isCoachAtOrganization(userRoles, [schoolId])

  const { data } = useGetCheckCompletedUserQuery({
    variables: { id: item.completedById ?? '' },
    skip: !isCompleted || !isCompletedByDifferentCoach,
  })

  const [completeChecklistItemMutation] = useCompleteChecklistItemMutation()
  const [uncompleteChecklistItemMutation] = useUncompleteChecklistItemMutation()

  const hasMoreThanOneLink = item.urls.length > 1
  const completionDate = new Intl.DateTimeFormat().format(
    new Date(item?.completedAt as string)
  )
  const completedUserName = `${
    data?.user?.firstName
  } ${data?.user?.lastName?.charAt(0)}`

  const handleChange = async (): Promise<void> => {
    if (checked) {
      await uncompleteChecklistItemMutation({
        awaitRefetchQueries: true,
        refetchQueries,
        variables: {
          schoolId,
          slug: item.slug,
        },
      })
    }

    if (!checked) {
      await completeChecklistItemMutation({
        awaitRefetchQueries: true,
        refetchQueries,
        variables: {
          schoolId,
          slug: item.slug,
        },
      })
    }

    setIsChecked(!checked)
  }

  const onOpenMultiLinks = ({ url }: { url: string }): void => {
    const articleId = PLAYVS_HELP_ARTICLE_REGEX.exec(url)?.groups?.id

    if (articleId) {
      const articleIdToShow =
        articleId !== IntercomArticleMappings.pricingFaq.toString()
          ? articleId
          : linksData?.getLinks?.find(
              (dataLink: { slug: string; url: string }) =>
                dataLink.slug === 'pricing-faqs'
            )?.url ?? IntercomArticleMappings.pricingFaq

      showIntercomArticle(articleIdToShow)
    }
    if (!articleId) {
      window.open(url, '_blank')
    }
  }

  const onNavigateToSingleCta = (): void => {
    onOpenMultiLinks({ url: item.urls[0].url })
  }

  // Check only done on esports dropdown step 3 for active esports
  const isOnEsportDropdownStep = step === 3
  const dropdownUrls = isOnEsportDropdownStep
    ? item.urls.filter((link) =>
        allAvailableEsportsNames?.some(
          (checkedName) =>
            checkedName &&
            link?.label?.includes(
              checkedName?.split(' ')[0].replace(/[^a-zA-Z0-9 ]/g, '')
            )
        )
      )
    : item.urls
  const ctaButton = hasMoreThanOneLink ? (
    <NxDropdownButton
      className={classes.button}
      fullWidth={!!isMobile}
      label={item.cta}
      menuItems={[
        {
          items: [
            ...dropdownUrls.map((itemUrl) => {
              return {
                id: itemUrl.url,
                isExternalLink: false,
                label: itemUrl?.label ?? '',
                onClick: (): void => {
                  onOpenMultiLinks({
                    url: itemUrl.url,
                  })
                },
              }
            }),
          ],
        },
      ]}
      menuWidth={196}
      variant="secondary"
    />
  ) : (
    <NxButton
      className={classes.button}
      label={item.cta}
      onClick={onNavigateToSingleCta}
      variant="secondary"
    />
  )

  return (
    <Box
      className={classes.listItemContainer}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      px={3}
      py={2}
      width="100%"
    >
      <Box display="flex" flexDirection="row" width="100%">
        <NxTooltip
          arrow
          disableFocusListener={isCoachForOrg}
          disableHoverListener={isCoachForOrg}
          disableTouchListener={isCoachForOrg}
          placement="bottom-start"
          title={
            isCoachForOrg ? (
              ''
            ) : (
              <NxTypography className={classes.tooltipType} variant="body3">
                You must be a verified faculty member to complete this task.
              </NxTypography>
            )
          }
        >
          <Box>
            <NxCheckbox
              checked={checked}
              className={classes.checkBox}
              color="default"
              disabled={!isCoachForOrg}
              onChange={handleChange}
            />
          </Box>
        </NxTooltip>
        <Box
          alignItems="center"
          className={classes.mobileLabel}
          display="flex"
          width="100%"
        >
          <Box display="flex" flexDirection="column" width="100%">
            <NxTypography
              className={classes.labelText}
              variant={
                checked || isCompletedByDifferentCoach ? 'body1' : 'body2'
              }
            >
              {item.label}
            </NxTypography>
            {isCompletedByDifferentCoach ? (
              <NxTypography
                className={classes.labelText}
                variant="body4"
              >{`This task was completed for your school by Coach ${completedUserName} on ${completionDate}`}</NxTypography>
            ) : null}
          </Box>
          <Hidden smUp>{ctaButton}</Hidden>
        </Box>
      </Box>
      <Hidden xsDown>{ctaButton}</Hidden>
    </Box>
  )
}
