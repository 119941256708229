export enum StadiumPaths {
  App = '/app',
  Dashboard = '/app/dashboard',
  ParentDashboard = '/app/parent-dashboard',
  ChildSpawnPoint = '/app/child/spawn-point',
  Events = '/app/events',
  Event = '/app/event/:eventId',
  MatchLobby = '/app/match/:matchId',
  Resources = '/app/resources',
  CheckIn = '/app/check-in',
  CheckInMatch = '/app/check-in/:matchId',
  MatchDispute = '/app/match/:matchId/dispute',
  MatchDisputeView = '/app/match/:matchId/view-dispute',
  EventOverview = '/app/event/:eventId/overview',
  EventMatches = '/app/event/:eventId/matches',
  EventStandings = '/app/event/:eventId/standings',
  EventTeam = '/app/event/:eventId/team',
  Logout = '/logout',
  Signup = '/signup',
  ParentSignUp = '/signup-parent',
  ForgotPassword = '/forgot-password',
  Match = '/app/match',
  MatchLobbyRoster = '/app/match/:matchId/teams',
  MissionControl = '/app/match/:matchId/mission-control',
  Account = '/app/account',
  VerifyPhone = '/app/verify-phone/:phoneNumber',
  Settings = '/app/account/settings',
  Schedule = '/app/schedule',
  ResetPassword = '/password-reset/:token',
  QueueLobby = '/app/queue-lobby/:slotId/:teamId',
  Queue = '/slot-queue/:slotId/:teamId/:esportSlug',
}
