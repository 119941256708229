import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  useBreakpointXs,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { Grid, useTheme } from '@material-ui/core'

import { NxComboButton, NxDropdownButton } from '@playvs-inc/nexus-components'
import { Moremenu } from '@playvs-inc/nexus-icons'
import { Path } from '@plvs/const'
import {
  MappedMatch,
  MappedMatchTeam,
} from '@plvs/rally/features/match/schedule/schedule'
import { MatchRow } from '@plvs/rally/features/explore/LeagueMatches/MatchRow/MatchRow'
import { findMatchTourClicked } from '@plvs/respawn/features/analytics'
import dayjs from '@plvs/respawn/init/dayjs'
import {
  useActiveGlobalChatConversationsVar,
  useActiveGlobalWindowsVar,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import { useProductTours } from '@plvs/respawn/features/shepherd/utils/useProductTours'
import { useScheduleRenderControllerContext } from '@plvs/respawn/renderController/schedule/ScheduleRenderControllerProvider'
import { useActionAndInfoSection } from '@plvs/rally/features/match/lobby/utils/useActionAndInfoSection'
import { ManageMatchDropdownMenuItems } from '@plvs/respawn/renderController/matchLobby/lobby/types/lobbyRenderController.types'

import { determineRescheduleStepOnClick } from '@plvs/respawn/features/match-lobby/ActionAndInfoSection.helpers'
import {
  DropdownActionHandlersMap,
  useGetManageMatchActions,
} from '@plvs/respawn/features/match/useGetManageMatchActions'
import { ForfeitMatchDialog } from '@plvs/rally/features/match/cancel/ForfeitMatchDialog'
import { RescheduleMatchDialog } from '@plvs/respawn/features/rescheduleMatch/reschedule'
import { RescheduleMatchStep } from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'
import { onOpenGlobalChat } from '@plvs/rally/features/chat/utils'
import { LeagueMatchTeamCluster } from '@plvs/rally/features/explore/LeagueMatches/LeagueMatchTeamCluster'
import { MatchRowMiddle } from '@plvs/rally/features/explore/LeagueMatches/MatchRow/MatchRowMiddle'
import { EsportRating, MatchFormat } from '@plvs/graphql/generated'
import { includes } from 'ramda'
import { INVALID_RESULTS_MATCH_STATUSES } from '@plvs/rally/features/explore/LeagueMatches/leagueMatchesHelpers'
import { getIsPastMatch } from '@plvs/rally/features/explore/LeagueMatches/MatchRow/matchRow.helpers'
import { MatchRowMobile } from '@plvs/rally/features/explore/LeagueMatches/MatchRow/MatchRowMobile'
import { TeamsVsRow } from '@plvs/rally/features/explore/LeagueMatches/MatchRow/mobile/TeamsVsRow'
import { AvatarsRow } from '@plvs/rally/features/explore/LeagueMatches/MatchRow/mobile/AvatarsRow'
import { MultiTeamCluster } from '@plvs/rally/features/explore/LeagueMatches/MatchRow/MultiTeamCluster'
import {
  determineIsMemberOfBothTeams,
  determineScheduleMatchRowConditions,
  isDropdownDisabled,
} from './ScheduleMatchRow.helpers'

type MatchRowProps = {
  match: MappedMatch
  isQueueMatch?: boolean
  showEsportAccent?: boolean
}

export const ScheduleMatchRow: React.FC<MatchRowProps> = ({
  match,
  isQueueMatch = false,
  showEsportAccent = false,
}) => {
  const theme = useTheme()
  const isMobile = useBreakpointXs()
  const navigate = useNavigate()
  const { userId, userRoles } = useUserIdentityFn()
  const { activeConversations } = useActiveGlobalChatConversationsVar()
  const activeConversationsList = useActiveGlobalWindowsVar()
  const { findMatchTour, initiatedTimestamp } = useProductTours()
  const { schedule } = useScheduleRenderControllerContext()
  const {
    showRescheduleItem,
    showViewRescheduleItem,
    isRescheduleDialogOpen,
    setIsRescheduleDialogOpen,
    step,
    setStep,
    isForfeitDialogOpen,
    setIsForfeitDialogOpen,
    forfeitStep,
    setForfeitStep,
    loading,
  } = useActionAndInfoSection({
    esportSlug: match.esport?.slug,
    matchId: match.id,
    competitionGroup: match.competitionGroup,
  })
  const { id } = match

  const isLeaderboard = match.matchFormat === MatchFormat.Leaderboard

  const isMemberOfBothTeams = determineIsMemberOfBothTeams(match)

  const myTeamId = match?.teamContext?.myTeams[0]?.id
  // A user can be a member of both teams in a match.
  // To determine the opposing team (or simply the other team),
  // filter out the user's team from the match's team list.
  const otherTeam = match?.teamContext?.teams.find(
    (team) => team.id !== myTeamId
  ) as MappedMatchTeam

  const manageMatchMenuItems = React.useMemo(() => {
    return schedule.getMenuItemIdsForSchedulePageDropdown({
      userRoles,
      teamsContext: {
        myTeams: match.teamContext.myTeams,
        teams: match.teamContext.teams,
        otherTeams: match.teamContext.otherTeams,
      },
      competitionGroup: match.competitionGroup,
      matchFormat: match.matchFormat,
    })
  }, [userRoles, id, match.slotId])

  const onNavigateToMatchLobbyClick = (evnt: React.MouseEvent): void => {
    const lobbyUrl = isQueueMatch
      ? `/app/queue-lobby/${match.slotId}/${myTeamId}`
      : `${Path.Match}/${id}`

    if (evnt?.ctrlKey || evnt?.metaKey) {
      window.open(lobbyUrl, '_blank', 'noopener')
    } else {
      navigate(lobbyUrl)
    }

    if (findMatchTour?.isActive()) {
      findMatchTourClicked({
        userId,
        initiatedTimestamp: initiatedTimestamp ?? '',
        timeStamp: dayjs().toISOString(),
        stepNumber: 3,
        clickTarget: 'Complete Button',
      })
      findMatchTour?.complete()
    }
  }

  const disabledDropdown = isDropdownDisabled({
    status: match.status,
    isQueueMatch,
  })

  const dropdownActionHandlers: DropdownActionHandlersMap = {
    [ManageMatchDropdownMenuItems.Reschedule]: {
      onClick: (): void => {
        setIsRescheduleDialogOpen(true)
      },
    },
    [ManageMatchDropdownMenuItems.ViewReschedule]: {
      onClick: () => {
        determineRescheduleStepOnClick({
          matchId: id,
          matchRescheduleRequests: match.matchRescheduleRequests,
          setStep,
          setIsRescheduleDialogOpen,
          myTeamId,
          opposingTeamId: otherTeam?.id,
          isMemberOfBothTeams,
        })
      },
    },
    [ManageMatchDropdownMenuItems.Forfeit]: {
      onClick: (): void => setIsForfeitDialogOpen(true),
    },
    [ManageMatchDropdownMenuItems.JoinQueue]: {
      onClick: onNavigateToMatchLobbyClick,
    },
    [ManageMatchDropdownMenuItems.Open]: {
      onClick: onNavigateToMatchLobbyClick,
    },
    [ManageMatchDropdownMenuItems.Chat]: {
      onClick: () => {
        onOpenGlobalChat({
          uniqueName: id,
          activeConversations,
          activeConversationsList,
        })
      },
    },
  }

  const manageMatchDropdownConditionMap = determineScheduleMatchRowConditions({
    status: match.status,
    team2Id: otherTeam?.id,
    isQueueMatch,
    matchId: id,
    showRescheduleItem,
    showViewRescheduleItem,
  })

  const filteredMenuItemIds = disabledDropdown
    ? []
    : manageMatchMenuItems.items.filter((id) =>
        id in manageMatchDropdownConditionMap
          ? manageMatchDropdownConditionMap[id]
          : true
      )

  const renderedMenuItems = useGetManageMatchActions({
    menuItemIds: filteredMenuItemIds,
    esportSlug: match.esport?.slug,
    competitionGroup: match.competitionGroup,
    opposingTeamId: otherTeam?.id,
    matchId: id,
    dropdownActionHandlers,
    withLabels: false,
  })

  const isRestricted = match?.esport?.rating === EsportRating.Restricted

  const hasValidResults = !includes(
    match?.status,
    INVALID_RESULTS_MATCH_STATUSES
  )

  const isPastMatch = getIsPastMatch(match)

  const matchResults = match?.matchResults ?? []

  const myMatchResults = matchResults?.find(({ team }) => team?.id === myTeamId)
  const oppMatchResults = matchResults?.find(
    ({ team }) => team?.id === otherTeam?.id
  )
  const myTeamLost =
    hasValidResults && isPastMatch && myMatchResults?.placing === 2
  const oppTeamLost =
    hasValidResults && isPastMatch && oppMatchResults?.placing === 2

  if (isMobile) {
    return (
      <MatchRowMobile
        actions={
          <NxDropdownButton
            data-testid="open-match-btn-icon"
            disabled={disabledDropdown}
            icon={<Moremenu height={20} width={20} />}
            menuBackgroundColor={theme.palette.ColorBackgroundBase}
            menuItems={renderedMenuItems}
            // This is a temporary fix. The NxDropdownButton does not merge the styles correctly when passed a class name.
            // https://playvs.atlassian.net/browse/MATCH-7567
            style={{ border: 'none' }}
            variant="secondary"
          />
        }
        isQueueMatch={isQueueMatch}
        match={match}
        showEsportAccent={showEsportAccent}
        team1Id={match?.teamContext?.myTeams[0]?.id}
      >
        <TeamsVsRow
          isLeaderboard={isLeaderboard}
          myTeam={match?.teamContext?.myTeams[0]}
          oppTeam={otherTeam}
          subtitle="Open match for details"
        />
        <AvatarsRow
          match={match}
          MultiTeamCluster={
            <MultiTeamCluster
              shouldHideSubtitle
              shouldHideTitle
              subtitle="Open Match for Details"
              teams={(match?.teamContext?.teams ?? []).filter(
                (team) => team.id !== myTeamId
              )}
            />
          }
          team1={match?.teamContext?.myTeams[0]}
          team2={otherTeam}
        />
      </MatchRowMobile>
    )
  }

  return (
    <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
      <MatchRow
        actions={
          <Box alignItems="flex-end" display="flex" justifyContent="center">
            <NxComboButton
              data-testid="open-match-btn"
              disabled={disabledDropdown}
              label="Open Match"
              menuBackgroundColor={theme.palette.ColorBackgroundBase}
              menuItems={renderedMenuItems}
              primaryOnClick={onNavigateToMatchLobbyClick}
              variant="secondary"
            />
          </Box>
        }
        match={match}
        showEsportAccent={showEsportAccent}
      >
        <Grid item xs={3}>
          <LeagueMatchTeamCluster
            didTeamLose={myTeamLost}
            isLeftSide
            isRestrictedEsport={isRestricted}
            matchStatus={match?.status}
            team={match?.teamContext?.myTeams[0]}
          />
        </Grid>
        <Grid item xs={2}>
          <MatchRowMiddle
            isLeaderboard={isLeaderboard}
            match={match}
            myMatchResults={myMatchResults}
            oppMatchResults={oppMatchResults}
          />
        </Grid>
        <Grid item xs={3}>
          {isLeaderboard ? (
            <MultiTeamCluster
              subtitle="Open Match for Details"
              teams={(match?.teamContext?.teams ?? []).filter(
                (team) => team.id !== myTeamId
              )}
            />
          ) : (
            <LeagueMatchTeamCluster
              didTeamLose={oppTeamLost}
              isLeftSide={false}
              isRestrictedEsport={isRestricted}
              matchStatus={match?.status}
              team={otherTeam}
            />
          )}
        </Grid>
      </MatchRow>
      <ForfeitMatchDialog
        closeDialog={(): void => setIsForfeitDialogOpen(false)}
        esportSlug={match.esport?.slug}
        isDialogOpen={isForfeitDialogOpen}
        match={{
          ...match,
          esportSlug: match.esport.slug ?? null,
          phaseType: match.slot?.phase?.type,
          status: match.status ?? null,
        }}
        setStep={setForfeitStep}
        step={forfeitStep}
        teamId={myTeamId}
      />
      <RescheduleMatchDialog
        closeDialog={(): void => setIsRescheduleDialogOpen(false)}
        esportSlug={match.esport?.slug}
        isDialogOpen={isRescheduleDialogOpen}
        match={{
          ...match,
          esportSlug: match.esport.slug ?? null,
          phaseType: match.slot?.phase?.type,
          status: match.status ?? null,
        }}
        onReschedule={(): void => {}}
        setStep={setStep}
        step={step as RescheduleMatchStep}
        teamId={myTeamId}
      />
    </WaitTillLoaded>
  )
}
