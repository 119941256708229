import React, { Dispatch, SetStateAction, useState } from 'react'
import { Team } from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { EmptyPage } from '@plvs/respawn/components/empty'
import { Column, Table, TablePagination } from '@plvs/rally/components/table'

interface ChooseOpponentTableProps {
  opponents: Array<Column.ScrimmageOpponent>
  numPages: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setSelectedOpponent(input: Pick<Team, 'id' | 'name' | 'state'> | null): void
}

interface RowInfo {
  original: ChooseOpponentTableProps['opponents'][0]
  index: number
}

export const ChooseOpponentTable: React.FC<ChooseOpponentTableProps> = ({
  opponents,
  numPages,
  page,
  setPage,
  setSelectedOpponent,
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1)
  return (
    <>
      {opponents.length ? (
        <>
          <Table
            columns={[
              Column.ScrimmageOpponentTeam(selectedRowIndex),
              Column.ScrimmageOpponentRank,
              Column.EndCap,
            ]}
            data={opponents}
            getTrProps={(
              _state,
              rowInfo: RowInfo | undefined,
              _instance
            ): { onClick(e: React.SyntheticEvent): void } => {
              return {
                onClick: (): void => {
                  if (!rowInfo) return
                  setSelectedOpponent(
                    selectedRowIndex === rowInfo.index ? null : rowInfo.original
                  )
                  setSelectedRowIndex(
                    selectedRowIndex === rowInfo?.index ? -1 : rowInfo?.index
                  )
                },
              }
            }}
            style={{
              cursor: 'pointer',
            }}
          />
          <Box py={3}>
            <TablePagination
              numPages={numPages}
              page={page}
              setPage={setPage}
            />
          </Box>
        </>
      ) : (
        <EmptyPage mb={16} mt={6} showIcon title="No opponents available" />
      )}
    </>
  )
}
