import React, { ComponentType } from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import { appendClasses } from '@plvs/utils'
import { CarretLeft, Close } from '@playvs-inc/nexus-icons'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useStyles as useAppDrawerToggleStyles } from './AppDrawerToggle.styles'

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  closeIcon: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

interface DrawerWrapperProps {
  renderEmptyState: boolean
  EmptyState: React.FC
  isMobile: boolean
  onClose?(): void
  containerTitle: string
  ContainerIcon: ComponentType<{
    width: string
    color: string | undefined
    height: string
  }>
}

export const DrawerWrapper: React.FC<DrawerWrapperProps> = ({
  renderEmptyState,
  EmptyState,
  isMobile,
  onClose,
  containerTitle,
  children,
  ContainerIcon,
}) => {
  const classes = useAppDrawerToggleStyles({ isMobile })
  const { box, closeIcon } = useStyles()
  const theme = useTheme()
  return (
    <Box
      className={appendClasses(
        classes.container,
        renderEmptyState ? classes.emptyContainer : ''
      )}
    >
      {renderEmptyState && (
        <Box className={classes.emptyStateCloseButtonIcon}>
          <Box alignItems="center" className={box} display="flex">
            <CarretLeft color={theme.palette.ColorTextBase} fontSize="1.5rem" />
            <NxTypography className={classes.backText}>Back</NxTypography>
          </Box>

          <Close
            className={closeIcon}
            color={theme.palette.ColorIconBase}
            fontSize="1.3rem"
            onClick={onClose}
          />
        </Box>
      )}
      {!renderEmptyState ? (
        <Box className={classes.backdrop} data-testid="availableItems">
          <Box className={classes.header}>
            {isMobile && (
              <Box
                alignItems="center"
                className={classes.backButton}
                display="flex"
                onClick={onClose}
              >
                <CarretLeft color="ColorTextBase" fontSize="1.5rem" />
                <NxTypography className={classes.backText}>Back</NxTypography>
              </Box>
            )}
            <Box
              alignContent="center"
              alignItems="center"
              className={classes.titleContainer}
              display="flex"
            >
              <ContainerIcon color="ColorTextBase" height="30px" width="30px" />
              <NxTypography className={classes.headerTitle} variant="h1">
                {containerTitle}
              </NxTypography>
              {!isMobile && (
                <Box
                  className={classes.closeButton}
                  data-cy="closeNotifications"
                >
                  <Close
                    color={theme.palette.ColorIconBase}
                    fontSize="1.3rem"
                    onClick={onClose}
                  />
                </Box>
              )}
            </Box>
          </Box>
          {children}
        </Box>
      ) : (
        <EmptyState />
      )}
    </Box>
  )
}
