import {
  CompetitionGroup,
  EsportMissingConnectionCopy,
  EsportPositionsAttributes,
  EsportRating,
  EsportSlug,
  LeagueOfLegendsTeamGameStats,
  LeagueOfLegendsTeamMatchStats,
  Platform,
  Provider,
  RocketLeagueTeamGameStats,
  RocketLeagueTeamMatchStats,
  SmiteTeamGameStats,
  SmiteTeamMatchStats,
} from '@plvs/graphql'
import { CSSProperties, SVGProps } from 'react'
import { ConnectedAccountProviders } from '@plvs/utils'

import { PersonaImageVariant } from '@playvs-inc/nexus-components'

/** ******************************************************************
 * Custom types
 */
export type EsportIconProps = {
  children: React.ReactElement
  viewBox: string
  customStyling?: CSSProperties
}

export type PlatformTypeFormat =
  | 'PC'
  | 'Cross Play'
  | 'Nintendo'
  | 'Xbox One'
  | 'PS4'
  | 'PC/Mac'
  | 'Epic Games'
  | 'PS5'
  | 'Xbox Series X|S'
  | 'Mobile'

export interface UseImageLoaderProps {
  esportSlug: EsportSlug | null
  src?: string
  disableFallback?: boolean
  variant?: PersonaImageVariant
}

export interface UseImageLoaderReturn {
  isLoaded: boolean
  imageSrc: string
}

export type EsportTeamStatsKey =
  | keyof LeagueOfLegendsTeamGameStats
  | keyof RocketLeagueTeamGameStats
  | keyof SmiteTeamGameStats
  | keyof LeagueOfLegendsTeamMatchStats
  | keyof RocketLeagueTeamMatchStats
  | keyof SmiteTeamMatchStats

export interface VersusBar {
  accessor: EsportTeamStatsKey
  label: string
  format?: (value: number) => string | number
}

export enum StatsScope {
  Match = 'match',
  Game = 'game',
  Default = 'default',
}

export enum ObjectivesScope {
  Match = 'match',
  Game = 'game',
}

export interface Objective {
  accessor: string
  description?: string
  Icon?: (input: NxIconProps) => React.ReactElement
}

export interface GameAndMatchObjective {
  game: Objective[]
  match: Objective[]
}

export interface TopPerformerCategoryInterface {
  header: string
  accessor: string
  format?: (stat: number) => string
}

export type IconComponent = React.FC<{
  backgroundColor?: string
  color?: string
  height?: string
  width?: string
}>

export enum MatchOverviewFormat {
  HeadToHead = 'head-to-head',
  TeamBased = 'team-based',
  Leaderboard = 'leaderboard',
}

export interface MapListDetail {
  name: string
  bestOf: number
}

export enum OverwatchMapType {
  Control = 'Control',
  Assault = 'Assault',
  Escort = 'Escort',
  Hybrid = 'Hybrid',
}

// Conversion from EsportColumnComponentNames to Columns is done via leveraging
// getTableColumns in @plvs/rally/containers/table
export enum EsportColumnComponentNames {
  // Generic
  PhasePlayerCluster = 'Phase Player Cluster',
  PlayerPosition = 'Player Position',
  EndCap = 'End Cap',
  HiddenExpander = 'HiddenExpander',
  TeamCluster = 'Team Cluster',
  MatchesWon = 'Matches Won',
  MatchesLost = 'Matches Lost',
  GameRecord = 'Game Record',
  TiebreakerPoints = 'Tiebreaker Points',
  WinStreak = 'Win Streak',
  WinPercentage = 'Win Percentage',
  WinRecord = 'Win Record',
  LostRecord = 'Lost Record',
  RankingPosition = 'Ranking Position',
  UserClusterColumn = 'User Cluster Column',
  MatchRecord = 'Match Record',
  PlayVsScore = 'PlayVS Score',
  SeriesRecord = 'Series Record',
  AvgPlacement = 'Average Placement',
  FirstFinishes = 'First Finishes',
  TopFourFinishes = 'Top 4 Finishes',

  // League of Legends
  LeagueOfLegendsChampion = 'League of Legends Champion',
  LeagueOfLegendsChampions = 'League of Legends Champions',
  LeagueOfLegendsBans = 'League of Legends Bans',
  LeagueOfLegendsKDA = 'League of Legends KDA',
  LeagueOfLegendsKDAAverage = 'League of Legends KDA Average',
  LeagueOfLegendsGPM = 'League of Legends GPM',
  LeagueOfLegendsCS = 'League of Legends CS',
  LeagueOfLegendsDamage = 'League of Legends Damage',
  LeagueOfLegendsWards = 'League of Legends Wards',
  LeagueOfLegendsItems = 'League of Legends Items',
  LeagueOfLegendsGoldEarned = 'League of Legends Gold Earned',
  LeagueOfLegendsEliminationsAverage = 'League of Legends Eliminations Average',
  LeagueOfLegendsAssistsAverage = 'League of Legends Assists Average',
  LeagueOfLegendsCSAverage = 'League of Legends CS Average',
  LeagueOfLegendsTowersDestroyedAverage = 'League of Legends Towers Destroyed Average',
  LeagueOfLegendsRespawnsAverage = 'League of Legends Respawns Average',
  LeagueOfLegendsMostPickedChampion = 'League of Legends Most Picked Champion',
  LeagueOfLegendsGameCluster = 'League of Legends Game Cluster',

  // Rocket League
  RocketLeagueShootingPercentage = 'Rocket League Shooting Percentage',
  RocketLeagueGoals = 'Rocket League Goals',
  RocketLeagueSaves = 'Rocket League Saves',
  RocketLeagueAssists = 'Rocket League Assists',
  RocketLeagueShots = 'Rocket League Shots',
  RocketLeagueAvgShootingPercentage = 'Rocket League AvgShootingPercentage',
  RocketLeagueAvgGoals = 'Rocket League AvgGoals',
  RocketLeagueAvgSaves = 'Rocket League AvgSaves',
  RocketLeagueAvgShots = 'Rocket League AvgShots',
  RocketLeagueAvgAssistsSaves = 'Rocket League AvgAssistsSaves',
  RocketLeagueScore = 'Rocket League Score',
  RocketLeagueGameCluster = 'Rocket League GameCluster',

  // Smite
  SmiteEliminations = 'Smite Eliminations',
  SmiteRespawns = 'Smite Respawns',
  SmiteAssists = 'Smite Assists',
  SmiteGoldEarned = 'Smite Gold Earned',
  SmiteTowersDestroyed = 'Smite Towers Destroyed',
  SmiteGameCluster = 'Smite Game Cluster',
  SmiteKdaAverage = 'Smite Kda Average',

  // MOBA
  MOBAKillParticipation = 'MOBA Kill Participation',
  MOBAGPMAverage = 'MOBA GPM Average',

  // Fortnite
  FortniteTeamCluster = 'Fortnite Team Cluster',
  FortniteRank = 'Fortnite Rank',
  FortnitePoints = 'Fortnite Points',
  FortniteAvgPoints = 'Fortnite AvgPoints',
  FortniteWins = 'Fortnite Wins',
  FortniteWinPercentage = 'Fortnite Win Percentage',
  FortniteAvgPlace = 'Fortnite AvgPlace',
  FortniteAvgElims = 'Fortnite AvgElims',
  FortniteGamesPlayed = 'Fortnite Games Played',
  FortniteTimePlayed = 'Fortnite Time Played',
  FortniteState = 'Fortnite State',
  FortniteGameCluster = 'Fortnite Game Cluster',
  FortniteEliminations = 'Fortnite Eliminations',
}

export interface NxIconProps extends SVGProps<SVGSVGElement> {
  isMobile?: boolean
  color?: string
}

/** ******************************************************************
 * Esport General Adapter
 * All fields are detailed in this document:
 * https://www.notion.so/playvs/How-to-integrate-a-new-esport-WIP-e1050bbe28ac4f07844c82ccc95e40db#231501155b8f4e0daff3049e36499136
 *
 * Any updates to this code should have a corresponding update to documentation!
 */
export type GeneralEsportAdapter = {
  id: string
  slug: EsportSlug | null
  rating: EsportRating
  abbreviation: string
  name: string
  accountProvider: Provider | null
  accountProviderPrettyName: string
  colors: string[]
  positions: EsportPositionsAttributes[]
  bestOf: number
  seriesBestOf: number
  rankingInfoCopy: string
  competitionGroups: CompetitionGroup[]
  missingConnectionCopy: EsportMissingConnectionCopy | null
  matchInstructionsArticleId: string
  isScrimmageEnabled: boolean
  isEsportEnabled: boolean
  isDiscordEnabled: boolean
  hasSubstitutesEnabled: boolean
  isPlayerLedTeamEnabled: boolean
  hasApiIntegration: boolean
  hasSeries: boolean
  blackAndWhiteSvgIcon: EsportIconProps | null
  topPerformerCategories: TopPerformerCategoryInterface[] | null
  phaseTopPerformerCategories: TopPerformerCategoryInterface[] | null
  avatarLogoUrl: string
  disabledAvatarLogoUrl: string
  NexusIcon: React.FC<NxIconProps>
  gameType: string
  gamePlayerStatsColumns: EsportColumnComponentNames[] | null
  matchPlayerStatsColumns: EsportColumnComponentNames[] | null
  phaseStatColumns: EsportColumnComponentNames[] | null
  standingsColumns: EsportColumnComponentNames[] | null
  teamRecentGamesColumns: EsportColumnComponentNames[] | null
  playerStatRankCategory: string
  matchOverviewFormat: MatchOverviewFormat
  standardMapList: MapListDetail[] | null
  playoffMapList: MapListDetail[] | null
  getPositionName: (position: number) => string
  getPositionShortName: (position: number) => string
  getPositionColloquial: (position: number) => string
  getRandomImage: () => string
  getUsername: (user: ConnectedAccountProviders) => string
  getSideColor: (side: number) => string
  getVersusBars: (scope?: StatsScope) => VersusBar[]
  getObjectives: (scope?: ObjectivesScope) => Objective[]
  getIsUserConnected: (user: ConnectedAccountProviders) => boolean
  platformType: PlatformTypeFormat | null
  platformIcon: React.FC | null
  platforms: Platform[]
  loading: boolean
  headerImages: string[]
  hasArena: boolean
  hasStatsEnabled: boolean
  canSettleMatches: boolean
  teamMinStarterSize: number
  teamFormat: number
  teamMaxSize: number
  isPlayerDesignatedMatchSetUp: boolean
  matchInstructionArticleUrl: string
  isGamelessType: boolean
  isFortnite: boolean
  isAccountConnectionOptional: boolean
  largeEsportCardImage: string
  smallEsportCardImage: string
  watermarkUrl: string
  headerBackgroundUrl: string
  themeGradient: string
  rulebookImage: string | null
  rulebookUrl: string
  rulebookArticleId: string
  matchAssistantEnabled: boolean
  hasMatchAssistantScore: boolean
  scrimmageRatings: string[]
  matchAssistantVideoSrc: string
  isYouthProgram: boolean
  scoreDisplayName: string
  theme:
    | {
        color1: string | null
        color2: string | null
        color3: string | null
        color4: string | null
        color5: string | null
        color6: string | null
        mainColor: string | null
        gradientStart: string | null
        gradientEnd: string | null
      }
    | null
    | undefined
}

export type AllEsportsData = GeneralEsportAdapter[]
