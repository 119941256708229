import React from 'react'
import {
  NxIconAvatar,
  NxTooltip,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import { MappedMatchTeam } from '@plvs/rally/features/match/schedule/schedule'
import { makeStyles } from '@material-ui/core'
import { caseInsensitiveSort } from '@plvs/utils'
import { useBreakpointSm } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 54,
    width: 54,
    [theme.breakpoints.down('xs')]: {
      height: 40,
      width: 40,
    },
  },
  icon: ({ hasLabel }: { hasLabel: boolean }): CreateCSSProperties => ({
    marginLeft: hasLabel ? -theme.spacing(0.4) : undefined,
    width: 20,
    height: 20,
    [theme.breakpoints.down('xs')]: {
      width: 16,
      height: 16,
    },
  }),
}))

interface Props {
  avatarPaddingTop?: number
  subtitle: string
  teams: MappedMatchTeam[]
  shouldHideTitle?: boolean
  shouldHideSubtitle?: boolean
}

export const MultiTeamCluster: React.FC<Props> = ({
  avatarPaddingTop = 0,
  subtitle,
  teams,
  shouldHideSubtitle = false,
  shouldHideTitle = false,
}) => {
  const showNumberOfTeamsInMatch = teams.length > 0
  const classes = useStyles({ hasLabel: showNumberOfTeamsInMatch })

  const teamNames = teams
    .map((team) => team.name)
    .filter((name) => name)
    .sort(caseInsensitiveSort)

  const isSm = useBreakpointSm()

  const variant = isSm ? 'body3' : 'body2'

  return (
    <NxUserCluster
      Avatar={
        <NxTooltip
          arrow
          placement="top"
          title={
            <>
              {teamNames.map((t) => (
                <div key={t}>{t}</div>
              ))}
            </>
          }
        >
          <div style={{ paddingTop: avatarPaddingTop }}>
            <NxIconAvatar
              className={classes.avatar}
              iconClassName={classes.icon}
              label={showNumberOfTeamsInMatch ? teams.length : undefined}
              labelTypographyVariant={variant}
            />
          </div>
        </NxTooltip>
      }
      avatarHashId=""
      subtitles={
        shouldHideSubtitle
          ? undefined
          : [{ title: subtitle, subtitleVariant: 'body4' }]
      }
      title={shouldHideTitle ? undefined : 'Multiple Teams'}
    />
  )
}
