import { ApolloError, ApolloQueryResult } from '@apollo/client'
import {
  EsportSlug,
  GetSchoolProviderAccountsQuery,
  GetUserProviderAccountsQuery,
  GetUserProviderAccountsQueryVariables,
  Provider,
  SchoolProvider,
} from '@plvs/graphql'
import { VESL_VA_SLUG } from '@plvs/const'
import { ProductType } from '@plvs/client-data/models'

export interface AccountProviderComponentProps {
  key?: string
  name?: string
  disconnectProfile?: () => void
  providerDetails?: NonNullable<
    NonNullable<GetUserProviderAccountsQuery['me']>['userProviderAccounts']
  >[0]
  apolloError?: ApolloError
  userId: string | undefined
  checkpoint: boolean
  refetchUserProviderAccounts: (
    variables?: GetUserProviderAccountsQueryVariables
  ) => Promise<ApolloQueryResult<GetUserProviderAccountsQuery>>
  isParent?: boolean
}

export interface SchoolAccountProviderComponentProps {
  key?: string
  name?: string
  disconnectProfile?: () => void
  schoolProviderDetails?: NonNullable<
    NonNullable<
      NonNullable<NonNullable<GetSchoolProviderAccountsQuery['me']>['school']>
    >['schoolProviderAccounts']
  >[0]
  apolloError?: ApolloError
  schoolId: string | undefined
  title: string
  subtitle: string
}

export const createConnectAccount = (
  url: string,
  _cookies: any,
  setError: React.Dispatch<
    React.SetStateAction<Error | ApolloError | undefined>
  >
) => async (): Promise<void> => {
  try {
    const response = await fetch(`${url}`, {
      method: 'GET',
      credentials: 'include',
    })
    const { redirect } = await response.json()
    window.location = redirect
  } catch (err: any) {
    setError(err)
  }
}

export const esportSlugToProvider: Record<EsportSlug | string, Provider> = {
  [EsportSlug.LeagueOfLegends]: Provider.Riot,
  [EsportSlug.Smite]: Provider.Smite,
  [EsportSlug.RocketLeague]: Provider.EpicGames,
  [EsportSlug.RocketLeagueGMA]: Provider.EpicGames,
  [EsportSlug.Fortnite]: Provider.EpicGames,
  [EsportSlug.FortniteSolos]: Provider.EpicGames,
  [EsportSlug.Fortnite2]: Provider.EpicGames,
  [EsportSlug.SmashBrosUltimate]: Provider.Nintendo,
  [EsportSlug.Splatoon]: Provider.Nintendo,
  [EsportSlug.Overwatch]: Provider.BattleDotNet,
  [EsportSlug.Valorant]: Provider.Riot,
  [EsportSlug.Hearthstone]: Provider.BattleDotNet,
  [EsportSlug.Fifa21PS4]: Provider.Discord,
  [EsportSlug.Fifa21SolosPS4]: Provider.Discord,
  [EsportSlug.Fifa21XboxOne]: Provider.Discord,
  [EsportSlug.Fifa21SolosXboxOne]: Provider.Discord,
  [EsportSlug.Madden21PS4]: Provider.PlayStationNetwork,
  [EsportSlug.Madden]: Provider.PlayStationNetwork,
  [EsportSlug.Madden21XboxOne]: Provider.PlayStationNetwork,
  [EsportSlug.Madden21SolosXboxOne]: Provider.PlayStationNetwork,
  [EsportSlug.BlackOpsColdWar]: Provider.BattleDotNet,
  [EsportSlug.MarioKart]: Provider.Nintendo,
  [EsportSlug.NBA2K]: Provider.Discord,
  [EsportSlug.FC]: Provider.PlayStationNetwork,
  [EsportSlug.StreetFighter]: Provider.StreetFighter,
  [EsportSlug.MarvelRivals]: Provider.MarvelRivals,
  [EsportSlug.PokemonUnite]: Provider.PokemonUnite,
  [EsportSlug.Chess]: Provider.ChessDotCom,
  [EsportSlug.F1]: Provider.F1,
}

// NOTE: COMP-1758: Only allow players or students to connect to their twitch or youtube account to submit game highlight video links
export const getSupportedProviders = ({
  isPlayerOrStudent,
  flags,
  supportedProviders,
  isVeslVarsityAssociation,
  product,
}: {
  isPlayerOrStudent: boolean
  flags: {
    [key: string]: boolean
  }
  supportedProviders: Provider[]
  isVeslVarsityAssociation: boolean
  product: ProductType
}): Provider[] => {
  let actProvNames = supportedProviders

  if (
    !isPlayerOrStudent ||
    !flags.veslIntegration ||
    !isVeslVarsityAssociation
  ) {
    actProvNames = actProvNames.filter((provider) => provider !== Provider.Vesl)
  }

  if (
    (product === ProductType.Scholastic && !isPlayerOrStudent) ||
    !flags.twitchAccountConnection
  ) {
    actProvNames = actProvNames.filter(
      (provider) => provider !== Provider.Twitch
    )
  }

  if (
    (product === ProductType.Scholastic && !isPlayerOrStudent) ||
    !flags.youtubeAccountConnection
  ) {
    actProvNames = actProvNames.filter(
      (provider) => provider !== Provider.Youtube
    )
  }

  if (!flags.spinIntegration) {
    actProvNames = actProvNames.filter((provider) => {
      return provider !== Provider.Spin
    })
  }

  return actProvNames
}

export const isVesl = (va: { name: string; slug: string }): boolean =>
  va.slug === VESL_VA_SLUG

const ALLOWED_PROVIDER_DISCONNECTS = [
  Provider.Discord,
  Provider.Youtube,
  Provider.Twitch,
  Provider.Spin,
  SchoolProvider.DiscordSchool,
  SchoolProvider.TwitchSchool,
  SchoolProvider.YoutubeSchool,
]

export const canDisconnect = ({
  checkpoint,
  isParent,
  providerName,
}: {
  checkpoint: boolean
  providerName: Provider
  isParent: boolean
}): boolean => {
  return (
    ALLOWED_PROVIDER_DISCONNECTS.includes(providerName as Provider) ||
    checkpoint ||
    isParent
  )
}

export const getConnectTooltip = (
  isParent: boolean,
  checkpoint: boolean
): string => {
  if (isParent || checkpoint) {
    return 'Only players can connect their game accounts'
  }

  return ''
}

export const isConnectDisabled = ({
  checkpoint,
  isParent,
  disabled,
  isOwnUser,
}: {
  checkpoint: boolean
  isParent: boolean
  disabled: boolean | undefined
  isOwnUser: boolean
}): boolean => {
  if (isParent || disabled) {
    return true
  }

  if (checkpoint) {
    return !isOwnUser
  }

  return false
}
