import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'

import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { Path } from '@plvs/const'

import { appendClasses } from '@plvs/utils'
import Sword from '../../../../../assets/upcoming-matches/sword.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'calc(100% / 3)',
    scrollSnapAlign: 'start',
    minWidth: 'min-content',
  },
  matchCard: ({ isMobile }: { isMobile: boolean }): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '33vw',
    minWidth: '16.9rem',
    maxWidth: '20rem',
    height: '100%',
    minHeight: '14.6em',
    maxHeight: '100%',
    padding: isMobile ? '1rem .5rem .88rem' : '1rem 1rem 1.38rem',
    fontFamily: '"TT Commons", Whitney',
    lineHeight: '133%',
    boxShadow: theme.mixins.boxShadow.elevation1,
    border: '2px dashed',
    borderRadius: '10px',
    borderColor: theme.palette.BorderLight,
  }),
  scrimmageIcon: {
    height: '5rem',
    marginBottom: '0.8rem',
  },
  title: {
    marginBottom: '0.8rem',
  },
  body: {
    textAlign: 'center',
    marginBottom: 'auto',
  },
  button: {
    height: '2.3rem',
    textTransform: 'none',
    marginTop: theme.spacing(1.25),
    border: `1px solid ${theme.palette.BorderDark}`,
  },
}))

export const CreateScrimmageExploreCard: React.FC<{
  isCoach: boolean
  isMobile: boolean
  hasManyMatches: boolean
}> = ({ isCoach, isMobile, hasManyMatches }) => {
  const classes = useStyles({ isMobile })

  const title = isCoach ? 'Scrimmage While You Wait' : 'Keep Playing!'
  const bodyContent = isCoach
    ? 'While you are waiting for the next season match, schedule a scrimmage to practice your drills and scope out the competition'
    : 'Enroll into available competitions or leagues on the Explore page to compete.'
  const ctaText = isCoach ? 'View Scrimmages' : 'Explore Competitions'
  const hrefUrl = isCoach ? Path.Scrimmage : Path.Explore

  return (
    <Box
      className={appendClasses(
        classes.container,
        'MatchCard' /* This is used as a locator for '<UpcomingMatches />' component. */
      )}
      data-testid="DashboardMatchCard_MatchCard"
      mr={2.25}
    >
      <Box className={classes.matchCard}>
        <img alt="Icon" className={classes.scrimmageIcon} src={Sword} />
        <NxTypography className={classes.title} variant="h4">
          {hasManyMatches ? 'You Have More Matches' : title}
        </NxTypography>
        <NxTypography className={classes.body} variant="body1">
          {hasManyMatches
            ? 'Click the button below to view all your matches from the Schedule Page'
            : bodyContent}
        </NxTypography>
        <NxButton
          className={classes.button}
          data-testid="cta-button"
          href={hasManyMatches ? Path.Schedule : hrefUrl}
          label={hasManyMatches ? 'View Full Schedule' : ctaText}
          variant="secondary"
        />
      </Box>
    </Box>
  )
}
