import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'
import { LoadingSpinner, NxTypography } from '@playvs-inc/nexus-components'
import { Column, Table } from '@plvs/rally/components/table'
import React from 'react'

import { MinimalCoachEntry } from '../players/helpers'

const DESKTOP_COLUMNS = [
  Column.CoachCluster,
  Column.UserEmailCopiable,
  Column.UserJoinDate,
]

const MOBILE_COLUMNS = [Column.MobileUserCluster]

export const CoachesTable: React.FC<{
  showVerified: boolean
  loading: boolean
  verifiedCoaches: MinimalCoachEntry[]
  deniedCoaches: MinimalCoachEntry[]
}> = ({ showVerified, loading, verifiedCoaches, deniedCoaches }) => {
  const isMobile = useBreakpointSm()
  const theme = useTheme()

  const tdStyles = {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: isMobile ? 0 : theme.spacing(4),
    borderTop: `1px solid ${theme.palette.BorderLight}`,
    paddingRight: isMobile ? 0 : theme.spacing(3),
  }
  const thStyles = {
    paddingTop: isMobile ? 0 : theme.spacing(2),
    paddingBottom: isMobile ? 0 : theme.spacing(2),
    paddingLeft: isMobile ? 0 : theme.spacing(4),
    marginBottom: '-1px',
  }

  const coachData = showVerified ? verifiedCoaches : deniedCoaches
  const desktopColumns = showVerified
    ? [...DESKTOP_COLUMNS, Column.UserVerifiedCoachMore]
    : [...DESKTOP_COLUMNS, Column.UserDeniedCoachMore]

  const mobileColumns = showVerified
    ? [...MOBILE_COLUMNS, Column.UserVerifiedCoachMore]
    : [...MOBILE_COLUMNS, Column.UserDeniedCoachMore]

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <LoadingSpinner size="large" />
      </Box>
    )
  }

  return coachData.length ? (
    <Box data-cy="coaches-table" mb={1} mx={isMobile ? 2 : 0}>
      <Table
        columns={isMobile ? mobileColumns : desktopColumns}
        data={coachData}
        defaultSorted={[{ id: 'league', desc: false }]}
        tdStyles={tdStyles}
        thStyles={thStyles}
      />
    </Box>
  ) : (
    <Box mb={4} mt={3} mx={3}>
      <NxTypography variant="body2">There are no coaches to show</NxTypography>
    </Box>
  )
}
