import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import {
  CurrentGameAssistantStateInput,
  Exact,
  GameAssistantStateFragment,
  GameAssistantStepUpdatedDocument,
  GameAssistantStepUpdatedSubscription,
  GameAssistantStepUpdatedSubscriptionVariables,
  GetCurrentGameAssistantStepByMatchIdQuery,
  useGetCurrentGameAssistantStepByMatchIdQuery,
} from '@plvs/graphql'
import { defaultToEmptyString } from '@plvs/utils'
import { useEffect, useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'

export type OnNewStepProps = {
  prevState: { stepOrdinal: number | undefined; stepId: string | undefined }
  newState: { stepOrdinal: number | undefined }
}

export type MatchAssistantSubscriptionProps = {
  matchId: string
  teamId: string
  onNewStep({ prevState, newState }: OnNewStepProps): void
  onSkipMatchAssistant?(): void
}

export type MatchAssistantSubscriptionReturn = {
  currentStepLoading: boolean
  prevStepId: string
  prevStepData: GameAssistantStateFragment | null | undefined
  currentStep: GameAssistantStateFragment | null | undefined
  refetchCurrentGameAssistantStepByMatchId: (
    variables?:
      | Partial<Exact<{ input: CurrentGameAssistantStateInput }>>
      | undefined
  ) => Promise<ApolloQueryResult<GetCurrentGameAssistantStepByMatchIdQuery>>
}

export function useMatchAssistantSubscription({
  matchId,
  teamId,
  onSkipMatchAssistant,
  onNewStep,
}: MatchAssistantSubscriptionProps): MatchAssistantSubscriptionReturn {
  const [prevStepId, setPrevStepId] = useState('')

  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()
  const matchAssistantActions = getMatchLobbyRenderControllerState().match

  const shouldSkipSubscription =
    !matchId ||
    (!matchAssistantActions.canSpectate &&
      !matchAssistantActions.canParticipate)

  const {
    data: currentStepData,
    loading: currentStepLoading,
    refetch: refetchCurrentGameAssistantStepByMatchId,
    subscribeToMore,
    previousData: prevStepData,
  } = useGetCurrentGameAssistantStepByMatchIdQuery({
    variables: {
      input: {
        matchId: defaultToEmptyString(matchId),
        teamId: defaultToEmptyString(teamId),
      },
    },
    skip: shouldSkipSubscription,
  })

  useEffect(() => {
    if (shouldSkipSubscription) {
      return
    }

    refetchCurrentGameAssistantStepByMatchId({
      input: {
        matchId: defaultToEmptyString(matchId),
        teamId: defaultToEmptyString(teamId),
      },
    })
  }, [teamId, matchId])

  useEffect(() => {
    if (teamId) {
      subscribeToMore<
        GameAssistantStepUpdatedSubscription,
        GameAssistantStepUpdatedSubscriptionVariables
      >({
        document: GameAssistantStepUpdatedDocument,
        variables: {
          input: {
            teamId,
            matchId: matchId ?? '',
          },
        },
        updateQuery: (prev, { subscriptionData }) => {
          const newState =
            subscriptionData?.data?.gameAssistantStepUpdated?.currentState
          const prevState = prev.currentGameAssistantStateByMatchId

          if (!newState?.showMatchAssistant) {
            onSkipMatchAssistant?.()
            // Returning prev only for typing purposes.
            return prev
          }

          if (newState.step?.stepOrdinal !== prevState.step?.stepOrdinal) {
            const p = {
              stepOrdinal: prevState.step?.stepOrdinal,
              stepId: prevState.step?.id,
            }
            const n = { stepOrdinal: newState.step?.stepOrdinal }
            onNewStep({ prevState: p, newState: n })
            setPrevStepId(defaultToEmptyString(p.stepId))
          }

          return {
            ...prev,
            currentGameAssistantStateByMatchId: newState,
          }
        },
      })
    }
  }, [subscribeToMore, teamId, matchId])

  return {
    currentStepLoading,
    prevStepId,
    prevStepData: prevStepData?.currentGameAssistantStateByMatchId,
    currentStep: currentStepData?.currentGameAssistantStateByMatchId,
    refetchCurrentGameAssistantStepByMatchId,
  }
}
