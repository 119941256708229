import React from 'react'
import { find, prop } from 'ramda'
import { mapIndexed } from 'ramda-adjunct'
import { Box } from '@plvs/respawn/features/layout'
import { Game, GameResult, Maybe } from '@plvs/graphql'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  filledCircle: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  unfilledCircle: {
    borderColor: theme.palette.common.white,
  },
}))

const UNPLAYED = 0

export const createWinLossOrUnplayedArray = (
  id: string,
  games: (Pick<Game, 'id'> & {
    gameResults: Maybe<Pick<GameResult, 'id' | 'placing' | 'teamId'>[]>
  })[],
  bestOf: number | null | undefined
): Array<number> =>
  bestOf
    ? mapIndexed((_, i) => {
        const game = prop(i, games)
        if (!game || !game.gameResults) return UNPLAYED
        const gameResult = find(({ teamId }) => teamId === id, game.gameResults)
        if (!gameResult || !gameResult.placing) return UNPLAYED
        return gameResult.placing // 1 (win) or 2 (loss)
      }, new Array(Math.max(bestOf, games.length)))
    : []

const dotSizeMap = {
  small: { width: 12, height: 12 },
  medium: { width: 14, height: 14 },
  large: { width: 18, height: 18 },
}

type DotSize = 'small' | 'medium' | 'large'

interface DotProps {
  filled: boolean
  reverse: boolean
  size: DotSize
}

const Dot: React.FC<DotProps> = ({
  filled,
  reverse,
  size,
}): React.ReactElement => {
  const { filledCircle, unfilledCircle } = useStyles()

  return (
    <Box pl={reverse ? 2 : 0} pr={reverse ? 0 : 2} pt={1}>
      <div
        className={filled ? filledCircle : unfilledCircle}
        style={{
          ...dotSizeMap[size],
          borderRadius: '50%',
          borderStyle: 'solid',
          borderWidth: '2px',
        }}
      />
    </Box>
  )
}

export const getGameCircleColors = (
  teamPlacingArr: Array<number> | undefined,
  size: DotSize,
  reverse = false
): React.ReactNode => {
  return teamPlacingArr ? (
    teamPlacingArr.map((number, i) => {
      // 1 (win) or 2 (loss)
      if (number !== UNPLAYED) {
        return (
          <Dot
            key={`${number}-${i}`.toString()}
            filled={number === 1}
            reverse={reverse}
            size={size}
          />
        )
      }
      return <></>
    })
  ) : (
    <></>
  )
}

export const getSeriesCircles = (
  seriesPlacings: (Maybe<number> | undefined)[],
  size: DotSize,
  reverse = false
): React.ReactNode => {
  const placingsToRender = reverse
    ? seriesPlacings.slice().reverse()
    : seriesPlacings
  return placingsToRender.map((placing, i) => {
    if (placing !== UNPLAYED) {
      // 1 (win) or 2 (loss)
      return (
        <Dot
          key={`${placing}-${i}`.toString()}
          filled={placing === 1}
          reverse={reverse}
          size={size}
        />
      )
    }
    return <></>
  })
}

interface GameResultDotsProps {
  bestOf: number
  teamId: string
  games: (Pick<Game, 'id'> & {
    gameResults: Maybe<Pick<GameResult, 'id' | 'placing' | 'teamId'>[]>
  })[]
  reverse?: boolean
  size: 'small' | 'medium' | 'large'
  seriesPlacings?: (Maybe<number> | undefined)[]
}

export const GameResultDots: React.FC<GameResultDotsProps> = ({
  bestOf,
  teamId,
  games,
  size,
  reverse = false,
  seriesPlacings = [],
}: GameResultDotsProps): React.ReactElement => {
  return (
    <Box
      alignItems="center"
      data-testid="GameResultDots"
      display="flex"
      flexDirection={reverse ? 'row-reverse' : 'row'}
    >
      {seriesPlacings.length
        ? getSeriesCircles(seriesPlacings, size, reverse)
        : getGameCircleColors(
            createWinLossOrUnplayedArray(teamId, games, bestOf),
            size,
            reverse
          )}
      {}
    </Box>
  )
}
