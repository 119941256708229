import { GetOnMatchQueueRedirectDataQuery, MatchStatus } from '@plvs/graphql'
import {
  FlattenedTeam,
  HeroMatch,
  RawHeroMatch,
  RawHeroTeam,
} from '../types/Lobby.types'

export const flattenTeams = (teams: RawHeroTeam[]): FlattenedTeam[] => {
  return teams.map((team) => ({
    competitionGroup: team?.competitionGroup ?? undefined,
    id: team?.id ?? '',
    schoolName: team?.school?.name ?? '',
    name: team?.name ?? '',
    avatarUrl: team?.avatarUrl ?? '',
    schoolId: team?.school?.id ?? '',
  }))
}
export const normalizeMatchForHero = (match: RawHeroMatch): HeroMatch => {
  return {
    id: match?.id ?? '',
    status: match?.status ?? undefined,
    matchFormat: match?.format,
    teamContext: {
      myTeams: flattenTeams(match?.teamContext?.myTeams ?? []),
      otherTeams: flattenTeams(match?.teamContext?.otherTeams ?? []),
      teams: flattenTeams(match?.teamContext?.teams ?? []),
    },
    bestOf: match?.bestOf ?? 0,
    seriesBestOf: match?.seriesBestOf ?? null,
  }
}

export const shouldDetermineMatchResultsForQueue = ({
  status,
  hasQueueData,
  canEnterTheQueue,
}: {
  status: MatchStatus | undefined
  hasQueueData: boolean
  canEnterTheQueue: boolean
}): boolean => {
  const isForfeitedMatch = status === MatchStatus.Forfeited
  return isForfeitedMatch && hasQueueData && canEnterTheQueue
}

export const determineTeamToBeRedirected = ({
  queueRedirectData,
  teamId,
  teams,
}: {
  queueRedirectData: GetOnMatchQueueRedirectDataQuery | undefined
  teamId: string
  teams: HeroMatch['teamContext']['teams']
}): {
  teamToBeRedirectedId: string
  canRedirect: boolean
} => {
  const hasMatchResultsPlacing = (
    queueRedirectData?.match?.matchResults ?? []
  ).some((result) => result.placing !== null)

  const aTeamNeedsResults = (queueRedirectData?.match?.matchResults ?? []).some(
    (result) => result.placing === null
  )

  const losingTeam = queueRedirectData?.match?.matchResults?.find(
    (result) => result.placing === 2 || result.placing === 1
  )

  const myTeamIsNotLosingTeam = teamId !== (losingTeam?.teamId ?? '')

  // Redirect the team who won by forfeit.
  const teamToBeRedirectedId = teams.find(
    (team) => team.id !== losingTeam?.teamId
  )?.id

  if (
    hasMatchResultsPlacing &&
    teamToBeRedirectedId &&
    aTeamNeedsResults &&
    myTeamIsNotLosingTeam
  ) {
    return {
      teamToBeRedirectedId,
      canRedirect: true,
    }
  }

  return {
    teamToBeRedirectedId: '',
    canRedirect: false,
  }
}
