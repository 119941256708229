import React from 'react'
import { StepType } from '@plvs/utils'
import { MatchAssistantAdapterProps } from '@plvs/rally/features/match/assistant/adapter/esports/MatchAssistantAdapter.types'
import { MatchAssistantActions } from '@plvs/rally/features/match/assistant/MatchAssistantActions'
import { MatchAssistantAdapterSteps } from '@plvs/rally/features/match/assistant/adapter/matchAssistantAdapterSteps'
import { LeaderboardGamePreview } from '@plvs/rally/features/match/assistant/steps/general/gamePreview/LeaderboardGamePreview'
import { SoloReporting } from '@plvs/rally/features/match/assistant/steps/reporting/SoloReporting'

export const leaderboardMatchAssistantAdapter = ({
  subtitle,
  generalSteps,
  setStepIndex,
  DisputeResultsButton,
}: Pick<
  MatchAssistantAdapterProps,
  'subtitle' | 'generalSteps' | 'setStepIndex' | 'DisputeResultsButton'
>): MatchAssistantAdapterSteps => {
  return {
    assistantSteps: {
      ...generalSteps,
      [StepType.Report]: [
        {
          step: <LeaderboardGamePreview onNext={setStepIndex} />,
          title: 'Match Preview',
          actions: <MatchAssistantActions />,
        },
        {
          step: <SoloReporting />,
          title: 'Scoreboard',
          subtitle,
          actions: <MatchAssistantActions />,
        },
      ],
      [StepType.Final]: [
        {
          step: <LeaderboardGamePreview isFinal />,
          title: 'Final Placement',
          actions: DisputeResultsButton,
        },
      ],
    },
  }
}
