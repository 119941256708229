import {
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import { NavLink, useLocation } from 'react-router-dom'
import { NxTooltip, NxTypography } from '@playvs-inc/nexus-components'
import { LinkObj } from '@plvs/respawn/renderController/appDrawer/types/appDrawerRenderController.types'

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    width: '100%',
  },
  linkRoot: {
    // Increasing for more rounded view of navigation items making them more prominent - Design
    borderRadius: theme.shape.borderRadius * 1.5,
    color: theme.palette.ColorTextBase,
    height: theme.spacing(6.25),
    width: theme.spacing(30),
    margin: theme.spacing(1, 2.5, 0, 2.5),
    padding: 0,
    position: 'relative',
    cursor: 'pointer',
  },
  activeContainer: {
    position: 'relative',
    transition: 'transform 200ms ease, font-weight 100ms ease',
    color: theme.palette.BorderDark,
    fontWeight: theme.typography.h1.fontWeight,
  },
  linkHover: {
    '&:hover': {
      '& svg': {
        width: theme.spacing(3),
        height: theme.spacing(3),
        opacity: 1,
      },
      backgroundColor: theme.palette.ColorBackgroundAlt,
    },
  },
  linkActive: {
    '&:before': {
      width: theme.spacing(0.5),
      height: theme.spacing(5),
      left: 0,
      top: '5px',
      backgroundColor: theme.palette.ColorIconLink,
      borderRadius: theme.spacing(0, 0.5, 0.5, 0),
      visibility: 'visible',
      position: 'absolute',
      content: `''`,
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    '&:hover': {
      '&:before': {
        backgroundColor: theme.palette.ColorBackgroundAlt,
      },
      backgroundColor: theme.palette.ColorBackgroundAlt,
    },
  },
  textLabel: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
    },
    marginRight: theme.spacing(3),
  },
  navHover: {
    width: '100%',
    marginLeft: theme.spacing(3.5),
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
  link: {
    textDecoration: 'none',
    width: '100%',
    height: '100%',
  },
}))

interface Props {
  isExpanded: boolean
  navigationLinks: LinkObj[]
}

export const AppDrawerLinks = ({
  isExpanded,
  navigationLinks,
}: Props): React.ReactElement => {
  const classes = useStyles()
  const location = useLocation()

  const handleDisabledClick = (e, disabled: boolean): void => {
    if (disabled) {
      e.preventDefault()
    }
  }

  return (
    <Box>
      <List className={classes.list}>
        {navigationLinks.map(
          ({
            label,
            icon: Icon,
            activeIcon: ActiveIcon,
            to,
            handleOnClick,
            isRoutingToCurrLocation,
            secondaryIcon,
            disabled = false,
            className,
          }) => {
            const listItem = (
              <ListItem
                key={to ?? ''}
                className={clsx(classes.linkRoot, classes.linkHover, className)}
                data-cy={`${disabled ? 'disabled' : ''} ${label}`}
                disabled={disabled}
              >
                <NavLink
                  className={({ isActive }): string =>
                    isActive
                      ? clsx(classes.link, classes.linkActive)
                      : classes.link
                  }
                  onClick={
                    disabled
                      ? (e): void => handleDisabledClick(e, true)
                      : handleOnClick
                  }
                  to={to || ''}
                >
                  <Box
                    alignItems="center"
                    className={classes.navHover}
                    display="flex"
                    flexDirection="row"
                    height="100%"
                  >
                    {Icon && ActiveIcon && (
                      <Box alignItems="center" display="flex" mr={1.5}>
                        {!isRoutingToCurrLocation &&
                        !disabled &&
                        to &&
                        location.pathname.includes(to) ? (
                          <ActiveIcon className={classes.icon} />
                        ) : (
                          <Icon className={classes.icon} />
                        )}
                      </Box>
                    )}
                    {isExpanded && (
                      <ListItemText
                        className="active-container"
                        primary={
                          <Box alignItems="center" display="flex">
                            <NxTypography
                              className={clsx(
                                classes.textLabel,
                                to && location.pathname.includes(to)
                                  ? classes.activeContainer
                                  : ''
                              )}
                              data-cy={label}
                            >
                              {label}
                            </NxTypography>
                            {!disabled && secondaryIcon}
                          </Box>
                        }
                      />
                    )}
                  </Box>
                </NavLink>
              </ListItem>
            )

            if (disabled) {
              return (
                <NxTooltip
                  key={to ?? ''}
                  arrow
                  placement="bottom"
                  title="Sign up to unlock this feature"
                >
                  <Box>{listItem}</Box>
                </NxTooltip>
              )
            }

            return listItem
          }
        )}
      </List>
    </Box>
  )
}
