import React from 'react'
import {
  EsportRating,
  useGetLeagueByPublicSlugQuery,
} from '@plvs/graphql/generated'
import { useLeagueDetails } from './LeagueDetailsProvider'
import { LeagueDetailsTeamsTable } from '../LeagueDetailsTeamTable/LeagueDetailsTeamsTable'

export const LeagueDetailsTeams: React.FC = () => {
  const { metaseasonId, publicSlug } = useLeagueDetails()

  const { data } = useGetLeagueByPublicSlugQuery({
    skip: !publicSlug,
    variables: { publicSlug },
  })

  // Derived data
  const selectedLeague = data?.getLeagueByPublicSlug
  const leagueId = data?.getLeagueByPublicSlug?.id ?? ''
  const esportSlug = selectedLeague?.esport?.slug
  const esportRating = selectedLeague?.esport?.rating ?? EsportRating.General

  if (!esportSlug) {
    return null
  }

  return (
    <LeagueDetailsTeamsTable
      esportRating={esportRating}
      esportSlug={esportSlug}
      leagueId={leagueId}
      metaseasonId={metaseasonId}
    />
  )
}
