import React from 'react'
import { TextField, InputAdornment, Grid } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { useFindRecommendedTeamsForScrimmageQuery, Team } from '@plvs/graphql'
import { ChooseOpponentTable } from '@plvs/rally/components/scrimmage/ChooseOpponentTable'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { useStateAbbr } from '@plvs/rally/components/filter'
import { useDebounce } from 'use-debounce'
import { DEFAULT_DEBOUNCE_TIME } from '@plvs/const'
import { NxTypography } from '@playvs-inc/nexus-components'

export const ChooseOpponent: React.FC<{
  teamId: string
  setSelectedOpponent(input: Team): void
}> = ({ teamId, setSelectedOpponent }) => {
  const { selectedStateAbbr, stateAbbrFilter } = useStateAbbr()
  const [keyword, setKeyword] = React.useState<string>('')
  const [page, setPage] = React.useState<number>(1)
  const limit = 30
  const resultsPerPage = 10
  const offset = resultsPerPage * (page - 1)
  const [debouncedKeyword] = useDebounce(keyword, DEFAULT_DEBOUNCE_TIME)

  const { data, loading } = useFindRecommendedTeamsForScrimmageQuery({
    variables: {
      teamId,
      limit,
      filters: {
        keyword: debouncedKeyword,
        state: selectedStateAbbr,
        keywordExcludes: ['test', 'delete', 'playvs'],
        includeHidden: false,
      },
    },
    skip: !teamId,
  })
  const opponents = data?.findRecommendedTeamsForScrimmage ?? []
  const totalPages = Math.ceil(
    (data?.findRecommendedTeamsForScrimmage?.length ?? 0) / resultsPerPage
  )

  const onFilterChange = (): void => {
    setPage(1)
  }
  React.useEffect(onFilterChange, [keyword])
  React.useEffect(onFilterChange, [selectedStateAbbr])
  const opponentsOnPage =
    opponents.length >= resultsPerPage
      ? opponents.slice(offset, offset + resultsPerPage)
      : [...opponents]
  return (
    <>
      <NxTypography variant="h3">Choose a specific team</NxTypography>
      <div style={{ paddingTop: 24 }} />
      <MatchCard px={0}>
        <Grid container spacing={4}>
          <Grid item sm={8} xs={12}>
            <Box pt={3} px={3}>
              <TextField
                key="search"
                autoFocus
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                label="Search by team or school name"
                name="search"
                onChange={(e): void => {
                  setKeyword(e.target.value)
                }}
                type="search"
                value={keyword}
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box pb={2} pt={3} px={3} textAlign="right">
              {stateAbbrFilter}
            </Box>
          </Grid>
        </Grid>

        <WaitTillLoaded
          loading={loading}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          <ChooseOpponentTable
            numPages={totalPages}
            opponents={opponentsOnPage}
            page={page}
            setPage={setPage}
            setSelectedOpponent={setSelectedOpponent}
          />
        </WaitTillLoaded>
      </MatchCard>
    </>
  )
}
