import { MatchFormat, MatchStatus } from '@plvs/graphql/generated'
import { MappedMatch } from '@plvs/rally/features/match/schedule/schedule'
import { ManageMatchDropdownMenuItems } from '@plvs/respawn/renderController/matchLobby/lobby/types/lobbyRenderController.types'
import { isMatchCancellableByStatus } from '@plvs/utils'

type ManageMatchDropdownConditionsMap = Record<
  Partial<ManageMatchDropdownMenuItems>,
  boolean
>
export const determineScheduleMatchRowConditions = ({
  status,
  team2Id,
  isQueueMatch,
  matchId,
  showRescheduleItem,
  showViewRescheduleItem,
}: {
  status: MatchStatus | undefined
  team2Id?: string | null
  isQueueMatch: boolean
  matchId: string
  showRescheduleItem: boolean
  showViewRescheduleItem: boolean
}): ManageMatchDropdownConditionsMap => {
  const isSoloForfeit = status === MatchStatus.Forfeited && !team2Id
  const showScoutOpponentItem = Boolean(team2Id) && !isQueueMatch
  const showForfeitItem = status ? isMatchCancellableByStatus(status) : false
  const showDisputeItem =
    status === MatchStatus.Completed ||
    (status === MatchStatus.Forfeited && Boolean(team2Id))
  const showChat = Boolean(matchId) && !isSoloForfeit
  const manageMatchDropdownConditionMap = {
    [ManageMatchDropdownMenuItems.Reschedule]: showRescheduleItem,
    [ManageMatchDropdownMenuItems.ViewReschedule]: showViewRescheduleItem,
    [ManageMatchDropdownMenuItems.Forfeit]: showForfeitItem,
    [ManageMatchDropdownMenuItems.Dispute]: showDisputeItem,
    [ManageMatchDropdownMenuItems.Scout]: showScoutOpponentItem,
    [ManageMatchDropdownMenuItems.Chat]: showChat,
    [ManageMatchDropdownMenuItems.JoinQueue]: isQueueMatch,
  }

  return manageMatchDropdownConditionMap as ManageMatchDropdownConditionsMap
}

export const isDropdownDisabled = ({
  status,
  isQueueMatch,
}: {
  status: MatchStatus | undefined
  isQueueMatch: boolean
}): boolean => {
  return (
    (status === MatchStatus.Forfeited && isQueueMatch) ||
    status === MatchStatus.Bye
  )
}

export const determineIsMemberOfBothTeams = (match: MappedMatch): boolean => {
  if (match.matchFormat === MatchFormat.Leaderboard) {
    return false
  }

  return (match?.teamContext?.myTeams ?? []).length > 1
}
