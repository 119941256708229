import React, { useCallback, useEffect, useState } from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import {
  NxTypography,
  NxTextInput,
  NxButton,
  NxIconButton,
} from '@playvs-inc/nexus-components'
import { Duplicate } from '@playvs-inc/nexus-icons'
import * as analytics from '@plvs/respawn/features/analytics'
import * as yup from 'yup'
import {
  CompetitionGroup,
  ReferByEmailInput,
  ResourceType,
  UserRole,
  UserRoleName,
  useGenerateReferralLinkMutation,
  useReferByEmailMutation,
} from '@plvs/graphql/generated'

import { useSnackbar } from 'notistack'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'
import copy from 'clipboard-copy'
import { formErrorToString, yupEmail, yupEmailRequired } from '@plvs/utils'
import { Path } from '@plvs/const'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useNavigate } from 'react-router-dom'
import { useAuthRenderControllerContext } from '@plvs/respawn/renderController'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
    width: '100%',
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  link: {
    color: theme.palette.ColorTextLink,
    marginTop: theme.spacing(1),
    textAlign: 'center',
    wordBreak: 'break-all',
    cursor: 'pointer',
  },
  submitButton: {
    marginLeft: theme.spacing(1.25),
    width: '172px',
    height: '48px',
  },
  copyContainer: {
    width: '100%',
    cursor: 'pointer',
  },
  inputTitle: {
    alignSelf: 'flex-start',
  },
  copyButton: {
    height: '47px',
    marginLeft: theme.spacing(1.25),
  },
  separatorText: {
    textAlign: 'center',
    margin: theme.spacing(0.5, 0, 2, 0),
  },
  contineButton: {
    marginTop: theme.spacing(1.5),
  },
}))
const ParentInviteByEmailFormSchema = yup.object().shape({
  email: yupEmailRequired,
})

type FormInput = Pick<ReferByEmailInput, 'email'>

export const OnboardChildOver13: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const { userId } = useUserIdentityFn()
  const { auth } = useAuthRenderControllerContext()
  const { shouldRenderUsernameField } = auth.getAuthComponentsToRender({
    location: window.location.href,
  })

  const [link, setLink] = useState<string>('')
  const [isBusy, setIsBusy] = React.useState<boolean>(false)
  const [email, setEmail] = React.useState('')
  const [isSuccess, setIsSuccess] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [generateReferralLink, { loading }] = useGenerateReferralLinkMutation()
  const [referByEmail] = useReferByEmailMutation()

  const { errors, handleSubmit, register, reset, getValues } =
    useForm<FormInput>({
      resolver: yupResolver<FormInput>(ParentInviteByEmailFormSchema),
    })

  const copyWithConfirmation = (): void => {
    copy(link)
      .then((): void => {
        enqueueSnackbar('Copied to clipboard', {
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          autoHideDuration: 2000,
        })
      })
      .catch((): void => {
        enqueueSnackbar('Error copying to clipboard', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          persist: true,
        })
      })
  }
  const onFormUpdate = async (): Promise<void> => {
    const values = getValues()
    try {
      await yupEmail.validate(values.email)
      setEmail(values.email)
      setIsBusy(false)
    } catch (e: any) {
      setIsBusy(true)
    }
  }

  const onSendInvite = useCallback(async (): Promise<void> => {
    setIsBusy(true)

    try {
      await referByEmail({
        variables: {
          input: {
            resourceId: userId,
            resourceType: ResourceType.System,
            email,
            roleName: UserRoleName.User,
            source: 'parent_child_invite',
            competitionGroup: CompetitionGroup.Open,
          },
        },
      })

      analytics.inviteByEmail({
        inviteeEmails: [email],
        inviteeUserType: UserRoleName.Student,
        myUserRoles: [{ roleName: UserRoleName.Parent } as UserRole],
      })
      enqueueSnackbar('Email Sent!', {
        variant: 'success',
      })
      setIsSuccess(true)
      reset()
    } catch (e: any) {
      setIsSuccess(false)
      setIsBusy(false)
    }
  }, [email])

  const onSubmit = handleSubmit(async (): Promise<void> => {
    return onSendInvite()
  })

  const onContinue = (): void => {
    navigate(Path.ParentDashboard)
  }

  useEffect(() => {
    const generateLink = async (): Promise<void> => {
      const referralLink = await generateReferralLink({
        variables: {
          resourceType: ResourceType.System,
          roleName: UserRoleName.User,
          source: 'parent_child_onboard',
          medium: 'share_link',
        },
      })
      const signUpLink = shouldRenderUsernameField
        ? `${referralLink?.data?.generateReferralLink.split('/su/')[0]}/signup`
        : referralLink?.data?.generateReferralLink
      setLink(signUpLink ?? '')
    }

    generateLink()
  }, [])

  return (
    <div className={classes.root}>
      <QuestionnaireCard childWidth={480} title="Unlock the fun!">
        <NxTypography className={classes.subtitle} variant="body1">
          Children{' '}
          <NxTypography component="span" variant="body2">
            over the age of 13
          </NxTypography>{' '}
          must create their own accounts.
        </NxTypography>
        <WaitTillLoaded
          loading={loading}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          <NxTypography className={classes.inputTitle} variant="body1">
            Share this link to invite your child to sign up:
          </NxTypography>
          <Box
            className={classes.copyContainer}
            display="flex"
            onClick={copyWithConfirmation}
          >
            <NxTextInput
              fullWidth
              label=""
              style={{ padding: theme.spacing(2) }}
              value={link}
            />
            <NxIconButton
              className={classes.copyButton}
              data-testid="copyButton"
              icon={
                <>
                  <Duplicate /> <NxTypography>Copy</NxTypography>
                </>
              }
              variant="secondary"
            />
          </Box>

          {!shouldRenderUsernameField && (
            <>
              <NxTypography className={classes.separatorText} variant="body1">
                OR
              </NxTypography>

              <form
                className={classes.copyContainer}
                noValidate
                onChange={onFormUpdate}
                onSubmit={onSubmit}
              >
                <NxTypography className={classes.inputTitle} variant="body1">
                  Invite your child via email
                </NxTypography>
                <Box
                  className={classes.copyContainer}
                  display="flex"
                  flexDirection="row"
                >
                  <NxTextInput
                    ref={register}
                    data-testid="emailInput"
                    fullWidth
                    helperText={formErrorToString(errors.email)}
                    label=""
                    name="email"
                    placeholder={
                      isSuccess ? 'Success! Insert another email' : ''
                    }
                    style={{ padding: theme.spacing(2) }}
                    type="text"
                    variant="default"
                  />
                  <NxButton
                    className={classes.submitButton}
                    data-testid="submitButton"
                    disabled={isBusy}
                    label="Invite Player"
                    onClick={onSendInvite}
                    type="submit"
                    variant="secondary"
                  />
                </Box>
              </form>
            </>
          )}
        </WaitTillLoaded>
        <NxButton
          className={classes.contineButton}
          fullWidth
          label="Continue To Parent Dashboard"
          onClick={onContinue}
          variant="primary"
        />
      </QuestionnaireCard>
    </div>
  )
}
