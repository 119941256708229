import React from 'react'

import { Grid, Hidden, makeStyles } from '@material-ui/core'
import { NxTypography, Pill, NxIconButton } from '@playvs-inc/nexus-components'

import { Download } from '@playvs-inc/nexus-icons'

import {
  InvoicePayStatus,
  useDownloadSchoolInvoiceQuery,
} from '@plvs/graphql/generated'
import dayjs from '@plvs/respawn/init/dayjs'
import { INVOICE_DATE_FORMAT } from '@plvs/const'
import { Box } from '@plvs/respawn/features/layout/Box'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius * 2.5,
    [theme.breakpoints.down('sm')]: {
      height: 'max-content',
    },
  },
  mobileContainer: {
    padding: theme.spacing(2, 3, 3),
  },
  fieldNames: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  documentName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  mobileDownloadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiGrid-item': {
      padding: 0,
    },
  },
  mobileFieldTitle: {
    color: theme.palette.ColorTextAlt2,
  },
}))

interface SchoolInvoiceProps {
  issueDate: Date | string | null
  dateDue: Date | string | null
  invoiceNumber: string
  paidStatus: InvoicePayStatus
  referenceId: string
  seasonName: string
  totalAmount: string
  schoolId: string
}

export const SchoolInvoice: React.FC<SchoolInvoiceProps> = ({
  issueDate,
  dateDue,
  invoiceNumber,
  paidStatus,
  seasonName,
  totalAmount,
  referenceId,
  schoolId,
}) => {
  const classes = useStyles()
  const { data } = useDownloadSchoolInvoiceQuery({
    variables: {
      input: {
        schoolId,
        invoiceReferenceId: referenceId,
      },
    },
    skip: !schoolId || !referenceId,
  })

  const onDownloadInvoice = (): void => {
    const encodedFile = data?.downloadSchoolInvoice?.encodedFile
    const link = document.createElement('a')
    link.download = data?.downloadSchoolInvoice?.title
      ? `${data?.downloadSchoolInvoice?.title}.pdf`
      : 'invoice.pdf'
    link.href = `data:application/octet-stream;base64,${encodedFile}`
    link.click()
  }

  const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <Box className={classes.container} mb={2} mt={1} py={1}>
      {/* Desktop */}
      <Hidden smDown>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box className={classes.fieldNames} pl={3} width={112}>
            <NxTypography variant="h4">{invoiceNumber}</NxTypography>
          </Box>
          <Box className={classes.fieldNames} width={105}>
            <NxTypography variant="body1">
              {dayjs(issueDate).format(INVOICE_DATE_FORMAT)}
            </NxTypography>
          </Box>
          <Box alignItems="center" display="flex" width={200}>
            <NxTypography className={classes.documentName} variant="body1">
              {`PlayVS Invoice - ${seasonName}`}
            </NxTypography>
          </Box>
          <Box className={classes.fieldNames} width={88}>
            <NxTypography variant="body1">
              {dateDue ? dayjs(dateDue).format(INVOICE_DATE_FORMAT) : '-'}
            </NxTypography>
          </Box>
          <Box className={classes.fieldNames} width={59}>
            <NxTypography variant="body1">
              {formatMoney.format(Number(totalAmount))}
            </NxTypography>
          </Box>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width={136}
          >
            <Pill label={paidStatus} size="medium" variant="success" />
            <Box mx={1}>
              <NxIconButton
                disabled={!data?.downloadSchoolInvoice?.encodedFile}
                icon={<Download />}
                label=""
                onClick={onDownloadInvoice}
                variant="text"
              />
            </Box>
          </Box>
        </Box>
      </Hidden>

      {/* Mobile */}
      <Hidden mdUp>
        <Grid className={classes.mobileContainer} container spacing={3}>
          <Grid item xs={12}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <NxTypography variant="h4">{invoiceNumber}</NxTypography>
              <Pill label={paidStatus} size="medium" variant="success" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexDirection="column" width={95}>
                <NxTypography
                  className={classes.mobileFieldTitle}
                  variant="overline"
                >
                  DATE ISSUED
                </NxTypography>
                <NxTypography variant="body1">
                  {dayjs(issueDate).format(INVOICE_DATE_FORMAT)}
                </NxTypography>
              </Box>
              <Box display="flex" flexDirection="column" maxWidth="50%" ml={5}>
                <NxTypography
                  className={classes.mobileFieldTitle}
                  variant="overline"
                >
                  DESCRIPTION
                </NxTypography>
                <NxTypography variant="body1">{`PlayVS Invoice - ${seasonName}`}</NxTypography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexDirection="column" width={95}>
                <NxTypography
                  className={classes.mobileFieldTitle}
                  variant="overline"
                >
                  DATE DUE
                </NxTypography>
                <NxTypography variant="body1">
                  {dateDue ? dayjs(dateDue).format(INVOICE_DATE_FORMAT) : '-'}
                </NxTypography>
              </Box>
              <Box display="flex" flexDirection="column" ml={5} width={114}>
                <NxTypography
                  className={classes.mobileFieldTitle}
                  variant="overline"
                >
                  AMOUNT
                </NxTypography>
                <NxTypography variant="body1">
                  {formatMoney.format(Number(totalAmount))}
                </NxTypography>
              </Box>
            </Box>
          </Grid>
          <Box
            className={classes.mobileDownloadContainer}
            display="flex"
            justifyContent="flex-end"
            mt={1}
            width="100%"
          >
            <NxIconButton
              disabled={!data?.downloadSchoolInvoice?.encodedFile}
              icon={<Download />}
              label="Download Invoice"
              onClick={onDownloadInvoice}
              variant="text"
            />
          </Box>
        </Grid>
      </Hidden>
    </Box>
  )
}
