import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { Grid, makeStyles } from '@material-ui/core'
import { MappedMatchTeam } from '@plvs/rally/features/match/schedule/schedule'

export const useStyles = makeStyles((theme) => ({
  leftTeam: {
    textAlign: 'right',
  },
  vsText: {
    margin: theme.spacing(0, 1),
    textAlign: 'center',
  },
}))

interface Props {
  isLeaderboard: boolean
  myTeam: MappedMatchTeam
  oppTeam: MappedMatchTeam
  subtitle: string
}

export const TeamsVsRow: React.FC<Props> = ({
  isLeaderboard,
  myTeam,
  oppTeam,
  subtitle,
}) => {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={5}>
        <Box textAlign="right">
          <NxTypography className={classes.leftTeam} noWrap variant="body2">
            {myTeam?.name ?? 'N/A'}
          </NxTypography>
          {myTeam?.school?.name && (
            <NxTypography noWrap variant="body4">
              {myTeam?.school?.name}
            </NxTypography>
          )}
        </Box>
      </Grid>
      <Grid item xs={2}>
        <NxTypography className={classes.vsText} variant="body1">
          VS
        </NxTypography>
      </Grid>
      <Grid item xs={5}>
        <NxTypography noWrap variant="body2">
          {isLeaderboard ? 'Multiple Teams' : oppTeam?.name ?? 'N/A'}
        </NxTypography>
        {isLeaderboard ? (
          <NxTypography noWrap variant="body4">
            {subtitle}
          </NxTypography>
        ) : (
          <>
            {oppTeam?.school?.name && (
              <NxTypography noWrap variant="body4">
                {oppTeam?.school?.name}
              </NxTypography>
            )}
          </>
        )}
      </Grid>
    </>
  )
}
