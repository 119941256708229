import React from 'react'
import { NxTypography, NxUserCluster } from '@playvs-inc/nexus-components'

import {
  defaultToEmptyString,
  MatchAssistantTeam,
  Placing,
  pluralize,
} from '@plvs/utils'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'

interface LeaderboardGamePreviewProps {
  placing: number
  score: number
  team: MatchAssistantTeam
  showScore?: boolean
}

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.ColorTextAlt,
  },
}))

export const GamePreviewTeamRow: React.FC<LeaderboardGamePreviewProps> = ({
  placing,
  score,
  team,
  showScore = false,
}) => {
  const theme = useTheme()
  const styles = useStyles()

  const isPositive = score > 0
  const isNegative = score < 0

  return (
    <Box
      alignItems="center"
      bgcolor={theme.palette.ColorBackgroundAlt}
      borderRadius={theme.mixins.cornerRadius['border-radius-10']}
      display="flex"
      gridGap={theme.spacing(2)}
      p={3}
    >
      <NxTypography variant="overline">{Placing(placing)}</NxTypography>

      <NxTypography variant="overline">
        <NxUserCluster
          avatarHashId={defaultToEmptyString(team?.id)}
          avatarUrl={defaultToEmptyString(team?.avatarUrl)}
          subtitles={[
            {
              title: defaultToEmptyString(team?.school?.name),
              subtitleVariant: 'body4',
              className: styles.subtitle,
            },
          ]}
          title={defaultToEmptyString(team?.name)}
        />
      </NxTypography>

      {showScore && (
        <Box ml="auto">
          <NxTypography variant="body2">
            {isPositive && '+'}
            {isNegative && '-'}
            &nbsp;
            {pluralize(score, 'pt', 'pts')}
          </NxTypography>
        </Box>
      )}
    </Box>
  )
}
