import React, { useEffect } from 'react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { generatePath, useLocation, useNavigate } from 'react-router'
import {
  getContextualResourceIdsFromTeams,
  hasRoleForResource,
  useEsportFavicon,
  useTitle,
} from '@plvs/utils'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  CompetitionGroup,
  MatchStatus,
  PhaseFormat,
  useGetOnMatchQueueRedirectDataQuery,
  useGetTopLevelMatchDataQuery,
  UserRoleName,
} from '@plvs/graphql/generated'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import {
  useMatchLobbyRenderControllerContext,
  useMatchLobbyRenderControllerState,
} from '@plvs/respawn/renderController'
import { MatchLobbyPageProps } from '@plvs/respawn/features/match-lobby/types/Lobby.types'
import {
  determineTeamToBeRedirected,
  normalizeMatchForHero,
  shouldDetermineMatchResultsForQueue,
} from '@plvs/respawn/features/match-lobby/utils/Lobby.helpers'
import { ControlPanel } from '@plvs/respawn/features/match-lobby/ControlPanel'
import { MatchStatsProvider } from '@plvs/respawn/features/match-lobby/MatchStatsContainer'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { EsrbRating } from '@plvs/respawn/features/match-lobby/esrbRating'
import { UserOnlineStatusProvider } from '@plvs/respawn/features/userOnlineStatus/UserOnlineStatusProvider'

import { useSkipMatchAssistant } from '@plvs/respawn/features/match-assistant'
import { Path } from '@plvs/const'

import { head } from 'ramda'
import { MatchRostersContainer } from '../components/matchRostersContainer/MatchRostersContainer'
import { MatchStats } from '../MatchStats'
import { ActionAndInfoSectionWrapper } from '../components/actionAndInfoSection/ActionAndInfoSectionWrapper'
import { MissionControl } from '../MissionControl'
import { generateMatchTitle, shouldShowMatchQueueBanner } from './LobbyHelper'
// This will be moved to respawn in a follow up ticket.
// https://playvs.atlassian.net/browse/MATCH-7067
// Remove this.
import { LobbySkeleton } from './LobbySkeleton'
import { MatchHero } from './MatchHero'

export const Lobby: React.FC<MatchLobbyPageProps> = ({
  matchId,
  breadcrumbHref,
  matchFormat,
}) => {
  const location = useLocation()
  const isMobile = useBreakpointSm()
  const path = location.pathname
  const navigate = useNavigate()

  const flags = useFlags()
  const ldClient = useLDClient()
  const thisEsportHasAMatchAssistant = flags.matchAssistant
  const { userRoles } = useUserIdentityFn()
  const {
    hasSkippedMatchAssistant,
    skipMatchAssistant,
  } = useSkipMatchAssistant(matchId)

  const { data, loading, refetch } = useGetTopLevelMatchDataQuery({
    variables: {
      matchId,
    },
    skip: !matchId,
  })

  const refetchTopLevelMatchData = async (): Promise<void> => {
    await refetch()
  }
  const heroMatch = React.useMemo(() => normalizeMatchForHero(data?.match), [
    data?.match,
  ])

  const { status, teamContext } = heroMatch

  const { myTeams, teams } = teamContext
  const myTeam = head(myTeams)
  const firstTeamInMatch = teams[0]

  const esportSlug = data?.match?.esport?.slug ?? null
  useEsportFavicon({
    esportSlug,
  })
  const esportRating = data?.match?.esport?.rating

  // This value exists on the Team type, not Match.
  // The team at index 0 in the match.teamContext.teams array is always present, but its identity depends on the match format.
  // It could represent the home team, the first listed team, my team, or another team entirely.
  // Regardless, the competition group remains consistent across all teams in the match.
  const competitionGroup = firstTeamInMatch?.competitionGroup
  // Non scholastic product does not currently support queues.
  // Once the queue system is implemented for stadium, this logic will move
  // to the backend.
  const isNotSmartScheduledMatchScholastic =
    data?.match?.slot?.phase?.format !== PhaseFormat.SmartSchedule ||
    competitionGroup === CompetitionGroup.Stadium

  const {
    data: queueRedirectData,
    loading: queueRedirectLoading,
  } = useGetOnMatchQueueRedirectDataQuery({
    variables: {
      matchId,
    },
    skip: isNotSmartScheduledMatchScholastic,
  })

  const { hasApiIntegration, NexusIcon } = useGeneralEsportAdapter(esportSlug)
  const { lobby, chat, match } = useMatchLobbyRenderControllerContext(
    matchFormat
  )
  const {
    setMatchLobbyRenderControllerStateFn,
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()

  const isMatchAssistantEnabled = Boolean(
    thisEsportHasAMatchAssistant &&
      data?.match?.matchAssistantEnabled &&
      !hasSkippedMatchAssistant[matchId]
  )

  const sharedRenderControllerProps = {
    userRoles,
    teamsContext: teamContext,
    competitionGroup,
    isMatchAssistantEnabled,
    status,
  }
  const lobbyComponents = lobby.getLobbyComponentsToRender(
    sharedRenderControllerProps
  )

  const matchActions = match.getMatchAssistantActions(
    sharedRenderControllerProps
  )
  const matchComponents = match.getMatchLobbyComponentsToRender({
    ...sharedRenderControllerProps,
    isOnMissionControl: location.pathname.includes('mission-control'),
  })

  const matchTeamsDisplayDetails = lobby.getMatchTeamsDisplayDetails({
    competitionGroup,
    // Order matters. "My team(s)" should always be first if applicable.
    teams: teamContext.myTeams.length
      ? [...teamContext.myTeams, ...teamContext.otherTeams]
      : [...teamContext.teams],
    esportRating,
  })

  const manageMatchDropDownMenuItemIds = lobby.getMenuItemIdsForManageMatchDropdown(
    {
      competitionGroup,
      userRoles,
      teamsContext: teamContext,
      matchFormat,
    }
  )

  const chatRender = chat.getChatComponentsToRender({
    userRoles,
    competitionGroup,
    myTeams: teamContext.myTeams,
    status,
  })

  const now = Date.now()
  const timeSinceStart =
    (now - new Date(data?.match?.scheduledStartsAt ?? '').getTime()) /
    (60 * 60 * 1000) // time diff in hours

  // This banner will allow a user to enter the scrimmage queue. Scrimmage queues have
  // not been implemented for Stadium or for Leaderboard match format. Note that scrimmage queues are
  // different from the slot lobby queues, which have been implemented for Stadium.
  const showMatchQueueBanner = matchComponents.isScrimmageQueueEnabled
    ? shouldShowMatchQueueBanner(status, !!myTeams.length, timeSinceStart)
    : false

  // For a Leaderboard match format, the display is `${My Team Name} vs Others`.
  // Otherwise, it follows `${My Team Name} vs ${Opponent Team Name}`, or `${Home Team Name} vs ${Away Team Name}`
  // if the user is not associated with of the teams in the match.
  const title = generateMatchTitle({
    hasUserRole: !!myTeams.length,
    myTeam: head(myTeams),
    otherTeams: teamContext.otherTeams,
    teams: teamContext.teams,
    matchFormat,
  })

  useTitle(title)

  useEffect(() => {
    if (loading) {
      return
    }
    const currentState = getMatchLobbyRenderControllerState()
    setMatchLobbyRenderControllerStateFn({
      ...currentState,
      chat: {
        ...currentState.chat,
        ...chatRender,
      },
      match: {
        ...currentState.match,
        ...matchActions,
        ...matchComponents,
      },
      lobby: {
        ...currentState.lobby,
        ...lobbyComponents,
        ...matchTeamsDisplayDetails,
        ...manageMatchDropDownMenuItemIds,
      },
    })
  }, [
    loading,
    competitionGroup,
    isMatchAssistantEnabled,
    esportRating,
    userRoles,
    status,
    hasSkippedMatchAssistant[matchId],
    location.pathname,
    heroMatch.teamContext,
  ])

  useEffect(
    function identifyLaunchDarkly() {
      if (!esportSlug || !ldClient) {
        return
      }

      try {
        ldClient.identify({ kind: 'esport', key: esportSlug })
      } catch (error) {
        // ignore errors from launch darkly
      }
    },
    [ldClient, esportSlug]
  )

  const mappedTeamIdsForDependencyArray = teams.map((team) => team.id)
  const mappedMyTeamIdsForDependencyArray = myTeams.map((team) => team.id)

  // The below logic tracks https://playvs.atlassian.net/browse/MATCH-6996
  // This useEffect will either be completely deleted or
  // greatly reduced in logic once this business logic moves to the backend.
  useEffect(() => {
    const { ids, schoolIds } = getContextualResourceIdsFromTeams(myTeams)
    const canEnterTheQueue =
      hasRoleForResource(
        userRoles,
        [...ids, ...schoolIds],
        UserRoleName.Coach
      ) ||
      hasRoleForResource(userRoles, [...ids, ...schoolIds], UserRoleName.Owner)
    const canMoveForwardWithQueueRedirect = shouldDetermineMatchResultsForQueue(
      {
        status,
        hasQueueData: Boolean(queueRedirectData),
        canEnterTheQueue,
      }
    )

    if (canMoveForwardWithQueueRedirect) {
      const { teamToBeRedirectedId, canRedirect } = determineTeamToBeRedirected(
        {
          queueRedirectData,
          teamId: myTeam?.id ?? '',
          teams,
        }
      )

      if (canRedirect) {
        const queueLobbyPath = generatePath(Path.QueueLobby, {
          slotId: data?.match?.slot?.id ?? '',
          teamId: teamToBeRedirectedId,
        })
        navigate(queueLobbyPath)
      }
    }
  }, [
    Boolean(queueRedirectData),
    queueRedirectLoading,
    status,
    myTeam?.id,
    mappedTeamIdsForDependencyArray,
    mappedMyTeamIdsForDependencyArray,
  ])

  if (loading) {
    return <LobbySkeleton />
  }

  return (
    <Esport slug={esportSlug}>
      {isMobile && lobbyComponents?.showActionAndInfoSection && (
        <ActionAndInfoSectionWrapper
          competitionGroup={competitionGroup}
          esportSlug={esportSlug}
          matchId={matchId}
        />
      )}
      <MatchHero
        breadcrumbHref={breadcrumbHref}
        esportSlug={esportSlug}
        isScrimmage={Boolean(data?.match?.isScrimmage)}
        match={heroMatch}
      />
      <MatchStatsProvider
        hasApiIntegration={hasApiIntegration}
        matchId={matchId}
      >
        <ControlPanel
          ActionAndInfoSectionComponent={ActionAndInfoSectionWrapper}
          competitionGroup={competitionGroup}
          esportSlug={esportSlug}
          matchId={matchId}
          showMatchQueueBanner={showMatchQueueBanner}
        />

        <Box mb={6} ml={2} mr={3}>
          <UserOnlineStatusProvider>
            {path.includes('mission-control') && (
              <MissionControl
                components={data?.match?.league?.sponsorshipComponents}
                esportSlug={esportSlug ?? null}
                isByeMatch={status === MatchStatus.Bye}
                matchId={matchId}
                refetchTopLevelMatchData={refetchTopLevelMatchData}
                skipMatchAssistant={skipMatchAssistant}
                teamId={myTeam?.id ?? ''}
              />
            )}
            {path.includes('teams') && (
              <MatchRostersContainer
                esportSlug={esportSlug}
                matchId={matchId}
              />
            )}
            {path.includes('match-stats') && (
              <MatchStats
                esportLogo={NexusIcon}
                matchId={matchId}
                team1Id={teams[0]?.id}
                team1Name={teams[0]?.name}
                team2Id={teams[1]?.id}
                team2Name={teams[1]?.name}
              />
            )}
            {esportSlug && <EsrbRating esportSlug={esportSlug} mt={5} />}
          </UserOnlineStatusProvider>
        </Box>
      </MatchStatsProvider>
    </Esport>
  )
}
