import React from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'

import { NxEmptyState, NxTypography } from '@playvs-inc/nexus-components'

import { useAutoskipQuery } from '@plvs/utils'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { Table } from '@plvs/rally/components/table'
import { useGetPlayerMatchStatsQuery } from '@plvs/graphql'
import { getTableColumns } from '@plvs/rally/containers/table'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { decoratePlayerRow } from './decorate-stats'

interface PlayerMatchStatsProps {
  matchId: string
  team1Id: string
  team2Id: string
  team1Name: string
  team2Name: string
}

export const PlayerMatchStats: React.FC<PlayerMatchStatsProps> = ({
  matchId,
  team1Id,
  team1Name,
  team2Id,
  team2Name,
}) => {
  const theme = useTheme()
  const {
    matchPlayerStatsColumns,
    getUsername,
    rankingInfoCopy,
  } = useEsportContext()
  const { data: statsData, loading, error } = useAutoskipQuery(
    useGetPlayerMatchStatsQuery,
    {
      variables: { matchId },
    }
  )

  const columns = getTableColumns(
    matchPlayerStatsColumns ?? [],
    rankingInfoCopy
  )

  if (error) {
    throw error
  }

  const playerMatchStats = statsData?.match?.playerMatchStats ?? null
  if (!loading && (!playerMatchStats || !playerMatchStats.length)) {
    return (
      <NxEmptyState
        isStandalone
        spot={
          <NxSpot
            domain="standing"
            height={200}
            size="large"
            variant="secondary"
            width={200}
          />
        }
        subtitle="Check back later."
        title="No stats found"
      />
    )
  }

  const team1MatchStats = playerMatchStats
    ?.filter(({ teamId }) => teamId === team1Id)
    .map(decoratePlayerRow({ getUsername }))
  const team2MatchStats = playerMatchStats
    ?.filter(({ teamId }) => teamId === team2Id)
    .map(decoratePlayerRow({ getUsername }))

  return (
    <WaitTillLoaded
      loading={loading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <>
        <Box pl={3}>
          <NxTypography gutterBottom variant="overline">
            {team1Name}
          </NxTypography>
        </Box>
        <Table columns={columns} data={team1MatchStats} />

        <Box borderBottom={`1px solid ${theme.palette.BorderLight}`} my={3} />

        <Box pl={3}>
          <NxTypography gutterBottom variant="overline">
            {team2Name}
          </NxTypography>
        </Box>
        <Table columns={columns} data={team2MatchStats} />
      </>
    </WaitTillLoaded>
  )
}
