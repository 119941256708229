import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Icon, Icons } from '@plvs/respawn/assets/icon'

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    color: theme.palette.ColorTextBase,
    height: 44,
    width: 48,
  },
  spotStyle: {
    width: 200,
  },
}))

interface EmptyPageProps {
  title?: string | React.ReactNode
  subtitle?: React.ReactNode
  showIcon?: boolean
  mt?: string | number
  mb?: string | number
  icon?: Icons
  customIcon?: React.ReactElement
}

export const EmptyPage: React.FC<EmptyPageProps> = ({
  title = `It's a little empty here`,
  subtitle = 'Come back later!',
  showIcon = false,
  mt = 30,
  mb = 30,
  icon = Icons.megaphone,
  customIcon,
  children,
}) => {
  const { iconStyle } = useStyles()
  const theme = useTheme()
  const iconToShow = customIcon || (
    <Icon className={iconStyle} data-testid="EmptyPage__Icon" icon={icon} />
  )

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      mb={mb}
      mt={mt}
    >
      {showIcon && iconToShow}
      <Box mt={theme.spacing(4)} textAlign="center">
        {typeof title === 'string' ? (
          <NxTypography variant="h4">{title}</NxTypography>
        ) : (
          title
        )}
      </Box>
      <Box
        maxWidth="480px"
        mb={theme.spacing(1)}
        mt={theme.spacing(2)}
        textAlign="center"
        width="100%"
      >
        {typeof subtitle === 'string' ? (
          <NxTypography colorToken="ColorTextAlt" variant="body1">
            {subtitle}
          </NxTypography>
        ) : (
          subtitle
        )}
      </Box>
      {children}
    </Box>
  )
}
