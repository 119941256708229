import { Menu, MenuItem } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import { Filters } from '@playvs-inc/nexus-icons'
import React from 'react'
import { NxIconButton, NxTypography } from '@playvs-inc/nexus-components'
import clsx from 'clsx'

import { Box } from '@plvs/respawn/features/layout/Box'
import { useStyles } from './styles'

interface Option {
  name: string
  id: string | undefined
}

interface Props<T> extends ButtonProps {
  option: T
  options: T[]
  renderValue?: (value: T) => string
  setOption(input: T): void
  className?: string
  shouldUseOverlayColors?: boolean
  buttonClassName?: string
}

export function SelectObject<T extends Option>({
  option,
  options,
  renderValue = ({ name }): string => name,
  setOption,
  className,
  shouldUseOverlayColors = false,
  buttonClassName,
}: Props<T>): React.ReactElement {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const ref = React.useRef() as any

  const openMenu = (): void => {
    setIsMenuOpen(true)
    setAnchorEl(ref.current)
  }
  const closeMenu = (): void => {
    setIsMenuOpen(false)
    setAnchorEl(null)
  }

  const classes = useStyles({ isMenuOpen, shouldUseOverlayColors })
  const disabled = options.length === 0

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore `ref` is a valid prop
    <Box ref={ref} className={className}>
      <NxIconButton
        className={clsx(classes.filterButton, buttonClassName)}
        data-cy={renderValue(option)}
        data-testid="select-object-button"
        disabled={disabled}
        icon={<Filters />}
        label={renderValue(option)}
        onClick={openMenu}
        variant="secondary"
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.menu}
        getContentAnchorEl={null}
        onClose={closeMenu}
        open={isMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {options.map(
          (o, i): React.ReactElement => (
            <MenuItem
              key={o.id}
              data-cy={renderValue(o)}
              onClick={(): void => {
                setOption(options[i])
                closeMenu()
              }}
              selected={o.id === option.id}
              value={i}
            >
              <NxTypography
                color="inherit"
                colorToken="ColorTextBase"
                variant="body3"
              >
                {renderValue(o)}
              </NxTypography>
            </MenuItem>
          )
        )}
      </Menu>
    </Box>
  )
}

export default SelectObject
