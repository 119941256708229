import React, { useState } from 'react'
import {
  CompetitionModel,
  useSchoolEnrollableSeasonsQuery,
} from '@plvs/graphql'
import { competitionModelToDisplay, MinimalMetaseason } from '@plvs/utils'
import dayjs from 'dayjs'
import { useBreakpointXs, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { NxWidget, NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { makeStyles } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEnrollment } from '@plvs/respawn/containers/enrollment/useEnrollment'
import { EnrollmentStatus } from '@plvs/respawn/containers/enrollment/types'
import { EnrollmentModal } from '../EnrollmentModal/EnrollmentModal'
import { getHoursLeft } from './coachEnrollmentCardHelpers'

export const useStyles = makeStyles((theme) => ({
  enrollmentText: {
    marginBottom: theme.spacing(1),
  },
  modalText: {
    color: theme.palette.ColorTextAlt2,
  },
}))

interface Props {
  path: string
  metaseason: MinimalMetaseason | undefined
  title: string
}
export const CoachEnrollmentCard: React.FC<Props> = ({
  path,
  metaseason,
  title,
}) => {
  const flags = useFlags()
  const isMobile = useBreakpointXs()
  const [open, setOpen] = useState<boolean>(false)
  const handleCloseModal = (): void => setOpen(false)

  const navigate = useNavigate()
  const classes = useStyles()

  const {
    id: orgId,
    loading: selectedOrgLoading,
    competitionGroup: orgCompetitionGroup,
  } = useSelectedOrganizationFn()
  const {
    loading: enrollmentLoading,
    status,
    hasAnnualPassInstance,
    competitionModel,
  } = useEnrollment({
    isCoach: true,
    metaseasonId: metaseason?.id ?? '',
    schoolId: orgId,
    includeOrganizationEnrollment: true,
  })

  const { data, loading } = useSchoolEnrollableSeasonsQuery({
    variables: { schoolId: orgId, metaseasonId: metaseason?.id ?? '' },
    skip: selectedOrgLoading || !orgId || !metaseason,
  })

  const seasons = data?.schoolEnrollableSeasons?.seasons ?? []

  const now = dayjs()

  const isLateEnrollment = seasons.every((season) =>
    now.isAfter(season?.suggestedRegistrationEndsAt)
  )

  const hoursLeft = getHoursLeft({ seasons, isLateEnrollment })

  const daysLeft = Math.floor(hoursLeft / 24)

  // Less than 24 hours, use hours remaining.  Else use how many days left
  const enrollmentEndsInTitle =
    hoursLeft < 24 ? `${hoursLeft} hours` : `${daysLeft} days`

  const metaseasonName = metaseason?.name ?? ''
  const shouldRedirectToPlanSelection =
    !flags.freeCompetition &&
    !enrollmentLoading &&
    status === EnrollmentStatus.NotEnrolled &&
    !hasAnnualPassInstance
  const navigationPath = `${path}/${
    hasAnnualPassInstance ||
    flags.freeCompetition ||
    shouldRedirectToPlanSelection
      ? `teams/${competitionModelToDisplay({
          competitionModel: competitionModel ?? CompetitionModel.Rec,
          competitionGroup: orgCompetitionGroup,
        }).toLowerCase()}`
      : `select`
  }`

  return (
    <WaitTillLoaded loading={loading}>
      {hoursLeft > 0 && (
        <>
          <NxWidget
            actions={
              <>
                <NxButton
                  fullWidth
                  label="Manage Enrollment"
                  mb="16px"
                  onClick={(): void => navigate(navigationPath)}
                  variant="primary"
                />
                <NxButton
                  fullWidth
                  label="View Enrollment Schedule"
                  onClick={(): void => setOpen(true)}
                  variant="secondary"
                />
              </>
            }
            title={title}
            width={isMobile ? '100%' : '300px'}
          >
            <NxTypography
              className={classes.enrollmentText}
              variant="subtitle1"
            >
              {isLateEnrollment ? 'Late ' : ''} Enrollment ends in{' '}
              <NxTypography component="span" variant="h4">
                {enrollmentEndsInTitle}
              </NxTypography>
            </NxTypography>
            <NxTypography className={classes.modalText} variant="body3">
              Enrollment deadlines may differ for some of your school&apos;s
              leagues.
            </NxTypography>
          </NxWidget>
          <EnrollmentModal
            handleCloseModal={handleCloseModal}
            open={open}
            path={navigationPath}
            seasons={seasons}
            title={`${metaseasonName} Enrollment Deadlines`}
          />
        </>
      )}
    </WaitTillLoaded>
  )
}
