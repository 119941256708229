import React, { useState } from 'react'
import { NxScoreboardRows, NxTypography } from '@playvs-inc/nexus-components'
import { GamePreview } from '@plvs/rally/features/match/assistant/steps/general/gamePreview/GamePreview'

import { defaultTo0, defaultToEmptyArray, Placing } from '@plvs/utils'
import { mergeRight } from 'ramda'
import { Box } from '@plvs/respawn/features/layout'
import { CaretDown, CaretUp } from '@playvs-inc/nexus-icons'
import { Collapse, makeStyles, useTheme } from '@material-ui/core'
import { GamePreviewTeamRow } from '@plvs/rally/features/match/assistant/steps/general/gamePreview/components/GamePreviewTeamRow'
import { mapGameResultsToScoreboardData } from '@plvs/rally/features/match/assistant/steps/general/gamePreview/LeaderboardGamePreview.helpers'
import { GameStatus } from '@plvs/graphql'
import { useMatchAssistantContext } from '../../../useMatchAssistant'

interface LeaderboardGamePreviewProps {
  onNext?(): void

  isFinal?: boolean
  showScore?: boolean
}

const useStyles = makeStyles(() => ({
  collapse: {
    width: '100%',
  },
}))

export const LeaderboardGamePreview: React.FC<LeaderboardGamePreviewProps> = ({
  onNext,
  isFinal,
  showScore,
}) => {
  const theme = useTheme()
  const styles = useStyles()

  const [expandedGameIndex, setExpandedGameIndex] = useState<number | null>(
    null
  )

  const toggleExpandedGame = (ndx: number): void => {
    setExpandedGameIndex(expandedGameIndex === ndx ? null : ndx)
  }

  const { match, myTeam, teamId: myTeamId } = useMatchAssistantContext()

  const baseMatchup = mapGameResultsToScoreboardData({
    match,
    isFinal: Boolean(isFinal),
    myTeam,
    myTeamId,
  })

  const currentMatchup = [baseMatchup]

  const gamesWithResults = defaultToEmptyArray(
    match?.games
      ?.filter(
        (game) =>
          game.gameResults?.length &&
          [GameStatus.Completed, GameStatus.Settled].includes(game.status)
      )
      .sort((a, b) => defaultTo0(a.ordinalNumber) - defaultTo0(b.ordinalNumber))
  )
  const canExpandGames = Boolean(isFinal)

  return (
    <GamePreview canChangeRoster={false} isFinal={isFinal} onNext={onNext}>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(4)}
        width="100%"
      >
        <NxScoreboardRows data={currentMatchup} />

        {isFinal && (
          <Box borderTop={`1px solid ${theme.palette.BorderLight}`} mx={-3} />
        )}

        {gamesWithResults.map((game, ndx) => {
          const myGameResults = game?.gameResults?.find(
            ({ teamId }) => teamId === myTeamId
          )
          const handleToggle = (): void => toggleExpandedGame(ndx)

          return (
            <Box
              key={game.id}
              alignItems="center"
              display="flex"
              flex={1}
              flexDirection="column"
            >
              <NxScoreboardRows
                data={[
                  mergeRight(baseMatchup, {
                    title: `Game ${game.ordinalNumber}`,
                    customMiddle: (
                      <Box mx={2}>
                        <NxTypography variant="h4">
                          {Placing(myGameResults?.placing)}
                        </NxTypography>
                      </Box>
                    ),
                  }),
                ]}
              />

              {canExpandGames && expandedGameIndex !== ndx ? (
                <CaretDown
                  cursor="pointer"
                  data-testid="LeaderboardGamePreview__ToggleOpen"
                  height={20}
                  onClick={handleToggle}
                  role="button"
                  width={20}
                />
              ) : (
                <CaretUp
                  cursor="pointer"
                  data-testid="LeaderboardGamePreview__ToggleClose"
                  height={20}
                  onClick={handleToggle}
                  role="button"
                  width={20}
                />
              )}

              <Collapse
                className={styles.collapse}
                data-testid="LeaderboardGamePreview__Collapse"
                in={expandedGameIndex === ndx}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gridGap={theme.spacing(1)}
                  mt={2}
                  width="100%"
                >
                  {game?.gameResults
                    ?.slice(0, 10)
                    .map(({ score, placing, teamId }) => {
                      const team = match?.teamContext?.teams?.find(
                        ({ id }) => id === teamId
                      )
                      return (
                        <GamePreviewTeamRow
                          key={teamId}
                          placing={defaultTo0(placing)}
                          score={defaultTo0(score)}
                          showScore={showScore}
                          team={team}
                        />
                      )
                    })}
                </Box>
              </Collapse>
            </Box>
          )
        })}
      </Box>
    </GamePreview>
  )
}
