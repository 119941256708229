// Config Mapping file, unit testing not needed here.
/* istanbul ignore file */

import {
  DISABLE_IN_PRODUCTION,
  Env,
  EnableEnv,
  getApiEnv,
  LOCAL_API_URL,
  LOCAL_WS_URL,
  LOCAL_APM_SERVER_URL,
  NodeEnv,
  getApmRumSampleRate,
} from '@plvs/env'

// If we have `window` then we are in a browser environment and sohuld use that, otherwise use `process.env`.
const isBrowser = typeof window !== 'undefined'
const appropriateEnv = isBrowser
  ? (window?.env as Record<string, string>)
  : process.env

/**
 * Defines available RallyEnv type values.
 */

export interface RallyEnv
  extends Omit<
    Env,
    | 'DEFAULT_SEGMENT_CDN_URL'
    | 'RALLY_PROD_SEGMENT_CDN_URL'
    | 'STADIUM_PROD_SEGMENT_CDN_URL'
  > {
  API_ENV: NodeEnv
  API_URL: string
  WS_URL: string
  GRAPHQL_WS_URL: string
  COOKIE_DOMAIN: string
  ENVIRONMENT_NAME: string
  APM_ENVIRONMENT_NAME: string
  MAINTENANCE?: string
  RIOT_AUTH_URI: string
  DISCORD_AUTH_URI: string
  DISCORD_SCHOOL_AUTH_URI: string
  TWITCH_AUTH_URI: string
  TWITCH_SCHOOL_AUTH_URI: string
  GOOGLE_AUTH_URI: string
  GOOGLE_AUTH_SIGNUP_URI: string
  GOOGLE_VERIFY_URI: string
  STAY_PLUGGED_IN_URI: string
  BATTLEDOTNET_AUTH_URI: string
  EPICGAMES_AUTH_URI: string
  XBOXLIVE_AUTH_URI: string
  FACEBOOK_AUTH_URI: string
  YOUTUBE_AUTH_URI: string
  YOUTUBE_SCHOOL_AUTH_URI: string
  VESL_AUTH_URI: string
  FACEBOOK_PIXEL_ID?: string
  GRAPHQL_URI: string
  INTERCOM_KEY?: string
  LINKEDIN_PIXEL_ID?: string
  SEGMENT_KEY?: string
  SENTRY_DSN: string
  STEAM_AUTH_URI: string
  SNAPCHAT_PIXEL_ID?: string
  ZENDESK_KEY?: string
  FORTNITE_AUTH_URI: string
  SHEERID_PROGRAM_ID: string
  FACULTY_SHEERID_PROGRAM_ID: string
  STUDENT_SHEERID_PROGRAM_ID: string
  ENABLE_SSR: EnableEnv | string
  PLASMIC_TOKEN: string
  PLASMIC_ID: string
  LAUNCHDARKLY_CLIENTID?: string
  // Enable preview mode where live content is loaded from plasmic.
  PLASMIC_PREVIEW: boolean
  TWILIO_ACCOUNT_AUTH_TOKEN?: string
  TWILIO_AUTH_TOKEN?: string
  APM_RUM_ENABLED: string
  APM_SERVER_URL: string
  APM_SAMPLE_RATE: number
  DEFAULT_API_URL: string

  // Feature Flags
  ENABLE_DARK_MODE: boolean
}

const API_URL = appropriateEnv.API_URL || LOCAL_API_URL
const WS_URL = appropriateEnv.WS_URL || LOCAL_WS_URL

const NODE_ENV = (appropriateEnv.NODE_ENV as NodeEnv) || 'development'

/**
 * Define default values when not overriden by deployed ENV.
 */
export const rallyEnv: RallyEnv = {
  // ENV
  COOKIE_DOMAIN: appropriateEnv.COOKIE_DOMAIN || 'localhost',
  OAUTH_COOKIE_DOMAIN: appropriateEnv.COOKIE_DOMAIN || 'localhost',
  IS_BROWSER: isBrowser,
  IS_NODE_ENV_PRODUCTION: NODE_ENV === 'production',
  IS_NODE_ENV_DEVELOPMENT: NODE_ENV === 'development',
  NODE_ENV,
  ENVIRONMENT_NAME: appropriateEnv.ENVIRONMENT_NAME || 'local',

  // __________________________________________________________________________
  // API
  MAINTENANCE: appropriateEnv.MAINTENANCE,
  API_ENV: getApiEnv(API_URL),
  API_URL,
  WS_URL,
  GRAPHQL_WS_URL: `${WS_URL}/graphql`,
  BATTLEDOTNET_AUTH_URI: `${API_URL}/auth/battle-dot-net/oauth/authorizeNoRedirect`,
  DISCORD_AUTH_URI: `${API_URL}/partner_api/discord/v2/authNoRedirect`,
  DISCORD_SCHOOL_AUTH_URI: `${API_URL}/partner_api/schooldiscord/v2/authNoRedirect`,
  EPICGAMES_AUTH_URI: `${API_URL}/auth/epic-games/oauth/authorizeNoRedirect`,
  XBOXLIVE_AUTH_URI: `${API_URL}/auth/xbox-live/oauth/authorizeNoRedirect`,
  FACEBOOK_AUTH_URI: `${API_URL}/auth/facebook-rally`,
  FORTNITE_AUTH_URI: `${API_URL}/auth/epic-games/oauth/authorizeNoRedirect`,
  GRAPHQL_URI: `${API_URL}/graphql`,
  RIOT_AUTH_URI: `${API_URL}/auth/riot/oauth/authorizeNoRedirect`,
  STEAM_AUTH_URI: `${API_URL}/auth/steam/noRedirect`,
  TWITCH_AUTH_URI: `${API_URL}/auth/twitch/oauth/authorizeNoRedirect`,
  TWITCH_SCHOOL_AUTH_URI: `${API_URL}/auth/twitchschool/oauth/authorizeNoRedirect`,
  YOUTUBE_AUTH_URI: `${API_URL}/auth/youtube/oauth/authorizeNoRedirect`,
  YOUTUBE_SCHOOL_AUTH_URI: `${API_URL}/auth/youtubeschool/oauth/authorizeNoRedirect`,
  GOOGLE_AUTH_URI: `${API_URL}/oauth2/google/v1/login`,
  GOOGLE_AUTH_SIGNUP_URI: `${API_URL}/oauth2/google/v1/signup`,
  GOOGLE_VERIFY_URI: `${API_URL}/oauth2/google/v1/verify`,
  STAY_PLUGGED_IN_URI: `${API_URL}/auth/spin/oauth/authorizeNoRedirect`,
  VESL_AUTH_URI: `${API_URL}/auth/vesl/oauth/authorizeNoRedirect`,
  DEFAULT_API_URL: API_URL,

  // __________________________________________________________________________
  // THIRD-PARTY KEYS
  FACEBOOK_PIXEL_ID: appropriateEnv.FACEBOOK_PIXEL_ID,
  INTERCOM_KEY: appropriateEnv.INTERCOM_KEY,
  LINKEDIN_PIXEL_ID: appropriateEnv.LINKEDIN_PIXEL_ID,
  SHEERID_PROGRAM_ID: DISABLE_IN_PRODUCTION
    ? '664fb27a08e7d65774f187b2' // Test Program ID
    : '664fb03908e7d65774f17394', // Production Program ID
  FACULTY_SHEERID_PROGRAM_ID: DISABLE_IN_PRODUCTION
    ? '664fb27a08e7d65774f187b2' // Test Program ID
    : '664fb03908e7d65774f17394', // Production Program ID
  STUDENT_SHEERID_PROGRAM_ID: DISABLE_IN_PRODUCTION
    ? '6079dfdae1da4776cd0df95f'
    : '6079e1aa3fe7513689351567',
  SEGMENT_KEY: appropriateEnv.SEGMENT_KEY,
  SENTRY_DSN: 'https://6affc91010eb4d8d901efb96564091af@sentry.io/1519550',
  SNAPCHAT_PIXEL_ID: appropriateEnv.SNAPCHAT_PIXEL_ID,
  PLASMIC_TOKEN: appropriateEnv.PLASMIC_TOKEN || '',
  PLASMIC_ID: appropriateEnv.PLASMIC_ID || '',
  LAUNCHDARKLY_CLIENTID: appropriateEnv.LAUNCHDARKLY_CLIENTID,

  // __________________________________________________________________________
  // APM
  APM_ENVIRONMENT_NAME: appropriateEnv.APM_ENVIRONMENT_NAME || 'local',
  APM_RUM_ENABLED: appropriateEnv.APM_RUM_ENABLED || 'false',
  APM_SERVER_URL: appropriateEnv.APM_SERVER_URL || LOCAL_APM_SERVER_URL,
  APM_SAMPLE_RATE: getApmRumSampleRate(appropriateEnv.APM_SAMPLE_RATE),

  // __________________________________________________________________________
  // Twilio tokens
  TWILIO_ACCOUNT_AUTH_TOKEN: appropriateEnv.TWILIO_ACCOUNT_AUTH_TOKEN,
  TWILIO_AUTH_TOKEN: appropriateEnv.TWILIO_ACCOUNT_AUTH_TOKEN,

  // __________________________________________________________________________
  // FEATURE FLAGS
  PLASMIC_PREVIEW: DISABLE_IN_PRODUCTION,
  ENABLE_SSR: appropriateEnv.ENABLE_SSR || 'disable',
  ENABLE_DARK_MODE: true,
}

// Note: this is to allow for mocking of environment variables in tests
export function getRallyEnv(): RallyEnv {
  return rallyEnv
}
