import React from 'react'
import { EsportSlug } from '@plvs/graphql/types'
import {
  BracketTeamMatchStatus,
  BracketType,
  EsportRating,
  GetPhaseBracketQuery,
  useGetPhaseBracketQuery,
} from '@plvs/graphql/generated'
import { makeStyles } from '@material-ui/core'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import StandingSecondaryLargeDark from '@plvs/respawn/assets/icons/StandingSecondaryLargeDark.svg'
import StandingSecondaryLarge from '@plvs/respawn/assets/icons/StandingSecondaryLarge.svg'
import { generatePath } from 'react-router-dom'
import { useAppLocationFn } from '@plvs/client-data/hooks'
import { Location } from '@plvs/client-data/models/AppLocation'
import { useEsport as useNexusEsport } from '@playvs-inc/nexus-theme'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { UnknownAvatar } from '@playvs-inc/nexus-icons'
import { groupBy, toPairs } from 'ramda'
import {
  BracketMatch,
  NxBracket,
  getRoundName,
} from '@plvs/respawn/components/bracket/Bracket'
import { EmptyPageWithSpot } from '@plvs/respawn/components/empty/EmptyPageWithSpot'

type Matches = NonNullable<
  GetPhaseBracketQuery['getBracketByPhaseId']
>['bracketMatches']

const useStyles = makeStyles((theme) => ({
  container: {
    overflowX: 'auto',
    maxWidth: '1267px',
    '&::-webkit-scrollbar': {
      // overrides default scrollbar height of 0 in ssr.tsx
      height: '0.5em !important',
      background: theme.palette.ColorBackgroundAlt,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.ColorBackgroundInactive,
    },
  },
  icon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
}))

export function getLinks(
  location: Location,
  isLoggedIn: boolean,
  schoolSlug?: string,
  schoolId?: string,
  teamId?: string,
  matchId?: string
): { schoolLink: string; teamLink: string; matchLink: string } {
  let schoolLink: string = ''
  let matchLink: string = ''
  let teamLink: string = ''
  if (location === Location.Rally) {
    if (schoolSlug) {
      const path = isLoggedIn ? '/app/school/:slug' : '/org/:slug'
      schoolLink = generatePath(path, {
        slug: schoolSlug,
      })
    }
    if (teamId) {
      const path = isLoggedIn ? '/app/team/:id' : '/team/:id'
      teamLink = generatePath(path, {
        id: teamId,
      })
    }
    if (matchId) {
      const path = '/app/match/:id'
      matchLink = generatePath(path, {
        id: matchId,
      })
    }
  } else if (location === Location.Checkpoint) {
    if (schoolId) {
      schoolLink = generatePath('/school/:id/info', {
        id: schoolId,
      })
    }
    if (teamId) {
      teamLink = generatePath('/team/:id', {
        id: teamId,
      })
    }
    if (matchId) {
      matchLink = generatePath('/match/:id', {
        id: matchId,
      })
    }
  } else if (location === Location.Stadium) {
    if (matchId) {
      matchLink = generatePath('/app/match/:id', {
        id: matchId,
      })
    }
  }
  return { schoolLink, teamLink, matchLink }
}

export interface BracketContainerProps {
  phaseId: string
  esportSlug: EsportSlug
  isLoggedIn: boolean
}

export function BracketContainer({
  phaseId,
  esportSlug,
  isLoggedIn,
}: BracketContainerProps): React.ReactElement {
  const classes = useStyles()
  const { data: bracketData, loading } = useGetPhaseBracketQuery({
    variables: { phaseId },
  })
  const esport = useNexusEsport(esportSlug)
  const overlayColor = esport?.palette.baseColor || ''
  const isDoubleElimination =
    bracketData?.getBracketByPhaseId?.type === BracketType.DoubleElimination
  const matches =
    bracketData?.getBracketByPhaseId?.bracketMatches
      .slice()
      .sort((a, b): number => {
        const aIsUpper = a.bracketIndex.includes('upper')
        const bIsUpper = b.bracketIndex.includes('upper')
        if (aIsUpper && bIsUpper) {
          return a.ordinalNumber - b.ordinalNumber
        }
        if (aIsUpper) {
          return -1
        }
        if (bIsUpper) {
          return 1
        }
        return a.ordinalNumber - b.ordinalNumber
      }) ?? []
  const appLocation = useAppLocationFn()
  const { hasSeries, rating, isYouthProgram } =
    useGeneralEsportAdapter(esportSlug)
  const rounds: BracketMatch[][] = getRoundsFromMatches(matches)

  function getRoundsFromMatches(matches: Matches): BracketMatch[][] {
    const processedRounds: BracketMatch[][] = []
    const rounds = toPairs(
      groupBy((match) => match.roundNumber as unknown as string, matches)
    )
    rounds.forEach((roundGrouped, roundIndex) => {
      const processRound: BracketMatch[] = []
      const round = roundGrouped[1]
      round.forEach((match) => {
        const isLower = match.bracketIndex.includes('lower')
        processRound.push({
          id: match.bracketIndex ?? '',
          matchId: match.matchId ?? '',
          isUpper: !isLower,
          name: `${getRoundName(
            roundIndex + 1,
            rounds.length,
            isDoubleElimination,
            !isLower
          )} - Match ${match.ordinalNumber + 1}`,
          nextMatchId: match.nextBracketIndex,
          roundIndex,
          startTime:
            match.match?.scheduledStartsAt ?? match.slot?.startsAt ?? '',
          participants: match.bracketTeams?.map((t) => {
            const result = match.match?.matchResults?.find(
              (x) => x.teamId === t.teamId
            )
            const score = hasSeries ? result?.seriesWon : result?.gamesWon
            const { schoolLink, teamLink, matchLink } = getLinks(
              appLocation,
              isLoggedIn,
              t.team?.school?.slug ?? undefined,
              t.schoolId ?? undefined,
              t.teamId ?? undefined,
              match.matchId ?? undefined
            )
            const hideSchoolDetails =
              isYouthProgram ||
              rating === EsportRating.Restricted ||
              t.matchStatus === BracketTeamMatchStatus.Bye ||
              t.matchStatus === BracketTeamMatchStatus.Tbd
            const isWinner = t.matchStatus === BracketTeamMatchStatus.Win
            const isLoser = t.matchStatus === BracketTeamMatchStatus.Loss
            return {
              id: t.teamId ?? '',
              teamId: t.teamId ?? '',
              seed: t.seed,
              status: t.matchStatus,
              displayName:
                t.matchStatus === BracketTeamMatchStatus.Bye
                  ? 'Bye'
                  : t.team?.name ?? 'TBD',
              picture:
                t.team?.avatarUrl && !hideSchoolDetails ? (
                  <NxUserAvatar
                    avatarUrl={t.team?.avatarUrl}
                    hashId=""
                    size={NxAvatarSize.XS}
                  />
                ) : (
                  <UnknownAvatar className={classes.icon} />
                ),
              schoolName:
                t.team?.school?.name && !(hideSchoolDetails || t.isDeleted)
                  ? t.team?.school?.name
                  : '',
              score: score ?? null,
              schoolId: t.team?.school?.id ?? '',
              isDeleted: t.isDeleted,
              schoolLink,
              teamLink,
              matchLink,
              isWinner,
              isLoser,
            }
          }),
        })
      })
      processedRounds.push(processRound)
    })
    return processedRounds
  }

  return (
    <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
      {rounds?.length ? (
        <Box className={classes.container} data-testid="BracketContainer">
          <NxBracket overlayColor={overlayColor} rounds={rounds} />
        </Box>
      ) : (
        <EmptyPageWithSpot
          spot={StandingSecondaryLarge}
          spotCaption="No standings"
          spotDark={StandingSecondaryLargeDark}
          subtitle="An updated bracket will be available after the first round is played."
          title="Bracket not available"
        />
      )}
    </WaitTillLoaded>
  )
}
