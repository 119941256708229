import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import { Smashbros, Rocketleague } from '@playvs-inc/nexus-icons'
import { NxModal, NxTypography, NxTextLink } from '@playvs-inc/nexus-components'

import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { IntercomArticleMappings } from '@plvs/const'
import { useGetHelpLinksQuery } from '@plvs/graphql/generated'

type EnrollmentPlanSelectionPassInfoModalProps = {
  onClose: () => void
}

const useStyles = makeStyles((theme) => ({
  // Slide the container up a bit so the top text is closer
  // to the title to better match the mockup.
  container: {},
  // Table box around the example
  fallSmashBox: {
    borderLeft: `1px solid ${theme.palette.BorderLight}`,
    borderBottom: `1px solid ${theme.palette.BorderLight}`,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  fallRocketBox: {
    borderLeft: `1px solid ${theme.palette.BorderLight}`,
    borderRight: `1px solid ${theme.palette.BorderLight}`,
    borderBottom: `1px solid ${theme.palette.BorderLight}`,
  },
  SpringSmashBox: {
    borderLeft: `1px solid ${theme.palette.BorderLight}`,
    borderRight: `1px solid ${theme.palette.BorderLight}`,
    borderBottom: `1px solid ${theme.palette.BorderLight}`,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  faqLink: {
    '& p': {
      color: `${theme.palette.ColorTextLink} !important`,
    },
  },
  esportIcon: {
    transform: 'scale(1.5)',
    height: '3em',
  },
  // Used to force override the display for External link component to
  // make it an inline link.
  forceInline: {
    '& p,div,a': {
      display: 'inline',
    },
  },
  invertColor: {
    color: `${theme.palette.ColorTextInvert} !important`,
  },
}))

export const EnrollmentPlanSelectionPassInfoModal: React.FC<EnrollmentPlanSelectionPassInfoModalProps> = ({
  onClose,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { data } = useGetHelpLinksQuery()
  const pricingFaqLink =
    data?.getLinks?.find((link) => link.slug === 'pricing-faqs')?.url ??
    IntercomArticleMappings.pricingFaq

  return (
    <NxModal
      className=""
      data-testid="EnrollmentPlanSelectionPassInfoModal__Modal"
      onClose={(): void => {
        onClose()
      }}
      open
      showTopRightClose
      title="How Do My Passes Work?"
    >
      <Box className={classes.container} display="flex" flexDirection="column">
        <Box>
          <NxTypography variant="body1">
            Your plans total amount of player passes are your schools alotment
            for the entire school year.
          </NxTypography>
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <NxTypography variant="h4">
            How Are Player Passes Redeemed/Used?
          </NxTypography>
          <NxTypography variant="body1">
            Player Passes are redeemed per player per esport per season. A
            player pass becomes billable once they play at least one seasonal
            match, this does not include scrimmages.
          </NxTypography>
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <NxTypography variant="h4">
            Does My Bench Player Use a Player Pass?
          </NxTypography>
          <NxTypography variant="body1">
            Subsitutes or bench players will only redeem a pass once they are
            moved to an active roster and play in one game.
          </NxTypography>
        </Box>
        <Box display="flex" flexDirection="column" mb={4} mt={2}>
          <NxTypography variant="h4">Example:</NxTypography>
          <NxTypography variant="body1">
            A student plays on two esports teams in the Fall season but then
            they decide to just play on one esport team in the Spring season.
            That one student would redeem 3 player passes from your schools
            plan.
          </NxTypography>
        </Box>

        {/* // Pass breakdown table */}
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <NxTypography variant="body2">
            1 Student x 3 Esports in 2 seasons = 3 Player Passes
          </NxTypography>
          <Box display="flex" flexDirection="column" my="1em" width="100%">
            <Box display="flex" flexDirection="row">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="stretch"
                width="50%"
              >
                <Box
                  alignItems="center"
                  bgcolor="ColorBackgroundAccent"
                  color="ColorTextInvert"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  maxHeight="4em"
                  minHeight="2em"
                  minWidth="8em"
                >
                  <NxTypography
                    className={classes.invertColor}
                    variant="overline"
                  >
                    Fall Season
                  </NxTypography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box
                    alignItems="center"
                    className={classes.fallSmashBox}
                    display="flex"
                    flexDirection="column"
                    px={2}
                    width="50%"
                  >
                    <Smashbros
                      className={classes.esportIcon}
                      color={theme.palette.ColorEsportAccentSUBase}
                    />
                    <NxTypography align="center" variant="body1">
                      Super Smash Bros. Team
                    </NxTypography>
                  </Box>
                  <Box
                    alignItems="center"
                    className={classes.fallRocketBox}
                    display="flex"
                    flexDirection="column"
                    px={2}
                    width="50%"
                  >
                    <Rocketleague
                      className={classes.esportIcon}
                      color={theme.palette.ColorEsportAccentRL4}
                    />
                    <NxTypography align="center" variant="body1">
                      Rocket League Team
                    </NxTypography>
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="stretch"
                width="50%"
              >
                <Box
                  alignItems="center"
                  bgcolor="ColorBackgroundAccentAlt2"
                  color="ColorTextInvert"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  maxHeight="4em"
                  minHeight="2em"
                  minWidth="8em"
                >
                  <NxTypography
                    className={classes.invertColor}
                    variant="overline"
                  >
                    Spring Season
                  </NxTypography>
                </Box>
                <Box
                  alignItems="center"
                  className={classes.SpringSmashBox}
                  display="flex"
                  flexDirection="column"
                  px="25%"
                >
                  <Smashbros
                    className={classes.esportIcon}
                    color={theme.palette.ColorEsportAccentSUBase}
                  />
                  <NxTypography align="center" variant="body1">
                    Super Smash Bros. Team
                  </NxTypography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* // End Pass breakdown table */}

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            width="100%"
          >
            <NxTypography variant="body1">
              If you have any pricing questions, please read our{' '}
              <span className={classes.forceInline}>
                <NxTextLink
                  className={classes.faqLink}
                  display="inline"
                  label="Pricing FAQ's"
                  onClick={(): void => {
                    showIntercomArticle(pricingFaqLink)
                  }}
                />
              </span>
              &nbsp; page.
            </NxTypography>
          </Box>
        </Box>
      </Box>
    </NxModal>
  )
}
