import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import {
  NxPlayVsBanner,
  NxTypography,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'

export const useStyles = makeStyles((theme) => ({
  bannerContent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      width: '100%',
      margin: 'auto',
      justifyContent: 'center',
    },
  },
  bannerText: {
    color: theme.palette.OverlayColorTextBase,
  },
}))

export const ParentBanner: React.FC = () => {
  const isMobileView = useBreakpointSm()
  const classes = useStyles()

  return (
    <NxPlayVsBanner
      customContent={
        <Box className={classes.bannerContent} px={5}>
          <NxTypography
            className={classes.bannerText}
            variant={isMobileView ? 'display3' : 'display'}
          >
            Parent Dashboard
          </NxTypography>
          <Box maxWidth={448} mt={2}>
            <NxTypography
              className={classes.bannerText}
              data-testid="parent-dashboard-banner-text"
              variant={isMobileView ? 'subtitle1' : 'body1'}
            >
              Manage the data & privacy of your child.
            </NxTypography>
          </Box>
        </Box>
      }
      hasNoTopNavBar={isMobileView}
      shouldUseDefaultBanner
      size="small"
      title=""
      variant={PersonaImageVariant.Explore}
      widget={undefined}
    />
  )
}
