import React, { memo } from 'react'
import { NxTypography, FB, NxEmote } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'

const useStyles = makeStyles((theme) => ({
  root: {
    '& span': {
      backgroundRepeat: 'no-repeat',
    },
  },
  textbox: {
    backgroundColor: theme.palette.ColorBackgroundTooltip,
    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderRight: `8px solid ${theme.palette.ColorBackgroundTooltip}`,
      position: 'absolute',
      left: -4,
    },
  },
}))

interface Props {
  isParentSignup: boolean
}

export const EligibilityCallout: React.FC<Props> = memo(
  ({ isParentSignup }) => {
    const classes = useStyles()

    return (
      <Box alignItems="center" className={classes.root} display="flex" mt={3}>
        <NxEmote emoteKey={FB.Wave} height={40} width={40} />
        <Box
          alignItems="center"
          borderRadius="4px"
          className={classes.textbox}
          display="flex"
          justifyContent="center"
          maxWidth={isParentSignup ? 280 : 243}
          ml={1}
          padding="4px 10px"
          position="relative"
          width="100%"
        >
          <NxTypography colorToken="OverlayColorTextBase" variant="body4">
            {isParentSignup
              ? 'We use your date of birth to help verify your identity'
              : 'We use your date of birth to determine your eligibility for certain competitions'}
          </NxTypography>
        </Box>
      </Box>
    )
  }
)
