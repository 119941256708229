import { Box } from '@plvs/respawn/features/layout'
import { NxTooltip } from '@playvs-inc/nexus-components'
import React from 'react'

interface DisabledToolTipProps {
  tooltipContent: string
  disabled?: boolean
  children: React.ReactElement
}

export const DisabledToolTip: React.FC<DisabledToolTipProps> = ({
  tooltipContent,
  disabled,
  children,
}) => {
  if (disabled) {
    return <>{children}</>
  }
  return (
    <Box width="100%">
      <NxTooltip arrow title={tooltipContent}>
        <Box>{children}</Box>
      </NxTooltip>
    </Box>
  )
}
