import React from 'react'
import { Path } from '@plvs/const'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { ClockCircle } from '@plvs/rally/assets'
import { openIntercom } from '@plvs/respawn/features/analytics/intercom/intercom'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'
import { Alert } from '@plvs/respawn/features/alert/Alert'
import { useNavigate } from 'react-router-dom'

export const useStyles = makeStyles((theme) => ({
  uploadButton: {
    marginLeft: theme.spacing(1),
    padding: '9px 16px',
    maxWidth: 'max-content',
  },
  contactLink: {
    color: theme.palette.ColorTextLink,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const UploadButton: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const onUploadDoc = async (): Promise<void> => {
    navigate(Path.DocUpload)
  }
  return (
    <Box alignSelf="flex-end" mt={2}>
      <NxButton
        className={classes.uploadButton}
        color="primary"
        data-testid="ExploreLeagues_JoinSchoolButton"
        fullWidth
        label="Upload Identification"
        onClick={onUploadDoc}
        variant="primary"
      />
    </Box>
  )
}

export const UnverifiedUserAlert: React.FC<{
  isPendingVerification: boolean
  isDocUploadRequired: boolean
  isFacOfOrg: boolean
  isCollege: boolean
}> = ({
  isPendingVerification,
  isDocUploadRequired,
  isFacOfOrg,
  isCollege,
}) => {
  const classes = useStyles()

  const alertTitle = isFacOfOrg
    ? 'Your faculty verification is pending'
    : 'Your student verification is pending'
  let subAlertTitle
  if (isFacOfOrg) {
    subAlertTitle = isDocUploadRequired ? (
      'Your employment is still being verified by PlayVS.'
    ) : (
      <Box>
        {`Your employment is still being verified by PlayVS. If you've been waiting longer than
      48 hours, `}
        <NxTypography
          className={classes.contactLink}
          display="inline"
          onClick={(): void => openIntercom()}
          variant="body1"
        >
          contact us.
        </NxTypography>
      </Box>
    )
  } else if (isCollege) {
    subAlertTitle =
      'In order to compete in collegiate leagues, you need either your school documentation to be approved or a verified faculty member from your school to verify you.'
  } else {
    subAlertTitle =
      'In order to compete for your school, you will need a verified faculty member from your school to verify you.'
  }

  if (!isPendingVerification) {
    return null
  }

  return (
    <Box data-cy="pendingVerification" mb={2}>
      <Alert
        avatarSrc={ClockCircle}
        endContent={(isDocUploadRequired && UploadButton) || null}
        subtitle={subAlertTitle}
        title={alertTitle}
      />
    </Box>
  )
}
