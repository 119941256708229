import { makeStyles, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { LeagueFilter } from '@plvs/rally/components/filter/league/LeagueFilter'
import { PhaseFilter } from '@plvs/rally/components/filter/PhaseFilter'
import { HeroGutter } from '@plvs/rally/components/hero'
import {
  Box,
  PageContentGutter,
  useBreakpointXs,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { useMyLeagues } from '@plvs/rally/containers/filter/league/myLeaguesContext'
import React from 'react'
import { sortByStartsAt } from '@plvs/utils'
import { PhaseStatus } from '@plvs/graphql'
import {
  NxEmptyState,
  NxEsportBanner,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'

import { MetaseasonFilter } from '@plvs/rally/components/filter/MetaseasonFilter'
import { EsrbRating } from '@plvs/respawn/features/match-lobby/esrbRating'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { StandingsByEsport } from './StandingsByEsport'
import { StandingsByPlayer } from './StandingsByPlayer'

export const useStyles = makeStyles((theme) => ({
  filters: {
    flexDirection: 'row',
    margin: theme.spacing(0, 2, 2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: theme.spacing(0, 2, 2),
    },
  },
  leagueFilter: {
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      width: '100%',
      marginRight: 0,
    },
  },
  filter: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  phaseFilter: {
    marginLeft: theme.spacing(1),
  },
}))

export const Standings: React.FC = () => {
  const isMobile = useBreakpointXs()

  const classes = useStyles()
  const theme = useTheme()

  const {
    esportSlug,
    league,
    leagues,
    metaseason,
    metaseasons,
    setMetaseason,
    setLeague,
    phase,
    phases,
    season,
    setPhase,
    loading,
  } = useMyLeagues()

  const activePhases = sortByStartsAt(
    phases.filter((p) => p.status === PhaseStatus.Active)
  )

  return (
    <Box color={theme.palette.ColorTextBase}>
      <Esport slug={esportSlug}>
        <HeroGutter mb={isMobile ? 1 : 4}>
          {esportSlug && (
            <NxEsportBanner
              esportSlug={esportSlug}
              size="small"
              subtitle="Top players and teams"
              title="Standings"
              useOverlayImage
              variant={PersonaImageVariant.Standings}
            />
          )}
        </HeroGutter>
        <Box className={classes.filters} display="flex">
          <LeagueFilter
            className={classes.leagueFilter}
            league={league}
            leagues={leagues}
            setLeague={setLeague}
            shouldTruncateName={isMobile}
          />
          <Box display="flex" ml={['none', 'auto']}>
            <MetaseasonFilter
              buttonClassName={classes.filter}
              className={classes.filter}
              metaseason={metaseason}
              metaseasons={metaseasons}
              setMetaseason={setMetaseason}
            />
            <PhaseFilter
              buttonClassName={classes.filter}
              className={clsx(classes.filter, classes.phaseFilter)}
              phase={phase}
              phases={activePhases}
              setPhase={setPhase}
            />
          </Box>
        </Box>
        <PageContentGutter style={{ marginTop: 0 }}>
          <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
            <>
              {phase ? (
                <Box>
                  <StandingsByPlayer phase={phase} />
                  <StandingsByEsport
                    league={league}
                    phase={phase}
                    seasonId={season?.id ?? ''}
                  />
                  {esportSlug && <EsrbRating esportSlug={esportSlug} mt={5} />}
                </Box>
              ) : (
                <NxEmptyState
                  spot={
                    <NxSpot
                      domain="standing"
                      height={200}
                      size="large"
                      variant="secondary"
                      width={200}
                    />
                  }
                  subtitle="Standings will be available once results have been submitted."
                  title="Standings not available yet"
                />
              )}
            </>
          </WaitTillLoaded>
        </PageContentGutter>
      </Esport>
    </Box>
  )
}
