import { useFlags } from 'launchdarkly-react-client-sdk'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import React from 'react'
import { withCookies } from 'react-cookie'
import {
  NxButton,
  NxTextLink,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { colors } from '@playvs-inc/nexus-core'
import spinAccountBanner from '@plvs/rally/assets/banner/spin-account-dashboard-banner.png'
import { StayPluggedIn } from '@playvs-inc/nexus-icons'
import { rallyEnv } from '@plvs/rally/env'
import { CookieProps } from '@plvs/utils'
import { createConnectAccount } from '../../account/connections/utils'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.Gwen[6],
    borderRadius: theme.mixins.cornerRadius['border-radius-10'],
    overflow: 'hidden',
    maxHeight: '185px',
    [theme.breakpoints.down('md')]: {
      maxHeight: 'unset',
    },
  },
  subContainer: {
    width: '100%',
    padding: theme.spacing(3),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  bannerImageStyle: {
    maxHeight: '185px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  connectButton: {
    alignSelf: 'flex-end',
    marginTop: 'auto',
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  spinIcon: {
    marginRight: theme.spacing(2),
    height: '70px',
    width: '100px',
    [theme.breakpoints.down('md')]: {
      height: '88px',
      width: '60px',
      marginBottom: theme.spacing(1.5),
      marginRight: 'unset',
    },
  },
  textLink: {
    textTransform: 'none',
    color: theme.palette.common.white,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.common.white,
      textDecoration: 'underline',
    },
  },
}))

const DashboardSpinSignup: React.FC<CookieProps> = ({ cookies }) => {
  const classes = useStyles()
  const flags = useFlags()
  const connectAccount = createConnectAccount(
    rallyEnv.STAY_PLUGGED_IN_URI,
    cookies,
    () => {}
  )

  const title = flags.freeCompetition
    ? 'Connect a Stay Plugged In account!'
    : 'Connect a Stay Plugged In account to unlock esports scholarships!'

  const description = flags.freeCompetition
    ? 'Stay Plugged In offers valuable services to help you find esports scholarships. Connect your account today!'
    : `If your school chooses a PlayVS Annual Plan, you'll get a Stay Plugged In platinum membership for FREE!`

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="row"
      mb={7}
    >
      <img
        alt="spin-connect"
        className={classes.bannerImageStyle}
        src={spinAccountBanner}
      />

      <Box className={classes.subContainer} display="flex">
        <StayPluggedIn className={classes.spinIcon} color="white" />
        <Box display="flex" flexDirection="column" height="100%">
          <NxTypography colorToken="OverlayColorTextBase" variant="h4">
            {title} &nbsp;
          </NxTypography>
          <NxTypography colorToken="OverlayColorTextBase" variant="body1">
            {description} &nbsp;
            {!flags.freeCompetition ? (
              <NxTextLink
                className={classes.textLink}
                href="https://help.playvs.com/en/articles/8219086-connecting-player-s-stay-plugged-in-accounts-and-platinum-benefits-faq"
                label="View Overview and FAQ"
                labelVariant="body1"
                target="_blank"
              />
            ) : null}
          </NxTypography>
          <NxButton
            className={classes.connectButton}
            fullWidth={false}
            label="Connect Spin Account"
            onClick={connectAccount}
            variant="secondary"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default withCookies(DashboardSpinSignup)
