import React, { useCallback } from 'react'
import {
  SchoolOutlined,
  SchoolFilled,
  TeamsOutlined,
  TeamsFilled,
  ScheduleOutlined,
  ScheduleFilled,
  StandingOutlined,
  StandingFilled,
  ScrimmageOutlined,
  ScrimmageFilled,
  ResourcesOutlined,
  ResourcesFilled,
  Compass,
  DashboardOutlined,
  DashboardFilled,
  CompassFilled,
  AddteamNoHover,
  AddteamHover,
} from '@playvs-inc/nexus-icons'
import { Path } from '@plvs/const'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import { NxTooltip, NxTypography } from '@playvs-inc/nexus-components'
import { useNavigate } from 'react-router-dom'
import { LinkObj } from '@plvs/respawn/renderController/appDrawer/types/appDrawerRenderController.types'
import { useLink } from '@plvs/respawn/features/app-drawer/utils/useLink'
import { StadiumPaths } from '@plvs/stadium/pages/paths'
import { ProductType } from '@plvs/client-data/models'

const useStyles = makeStyles((theme) => ({
  addTeamIcon: {
    height: theme.spacing(3.75),
    width: theme.spacing(3.75),
    alignSelf: 'center',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    opacity: 0,
  },
  tooltip: {
    color: theme.palette.common.white,
  },
}))

const AddTeamButton = (): React.ReactElement => {
  const [iconHovered, setIconHovered] = React.useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()

  const navigateToCreateTeam = (e: React.SyntheticEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    navigate(Path.CreateTeam)
  }

  const handleOnHover = useCallback(() => setIconHovered(true), [iconHovered])

  const handleOnHoverLeave = useCallback(
    () => setIconHovered(false),
    [iconHovered]
  )

  return (
    <NxTooltip
      arrow
      placement="right"
      title={
        <NxTypography className={classes.tooltip}>
          Create a New Team
        </NxTypography>
      }
    >
      <Box
        alignSelf="center"
        display="flex"
        onMouseEnter={handleOnHover}
        onMouseLeave={handleOnHoverLeave}
      >
        {iconHovered ? (
          <AddteamHover
            className={classes.addTeamIcon}
            color={theme.palette.ColorIconDisabled}
            data-testid="AddSchoolIcon__CreateTeamButton--OnHover"
            onClick={navigateToCreateTeam}
          />
        ) : (
          <AddteamNoHover
            className={classes.addTeamIcon}
            color={theme.palette.ColorIconDisabled}
            data-testid="AddSchoolIcon__CreateTeamButton--NoHover"
          />
        )}
      </Box>
    </NxTooltip>
  )
}

export const Dashboard: LinkObj = {
  label: 'Dashboard',
  icon: DashboardOutlined,
  activeIcon: DashboardFilled,
  to: Path.Dashboard,
}

export const Competitions: LinkObj = {
  label: 'Competitions',
  icon: Compass,
  activeIcon: CompassFilled,
  to: Path.Explore,
  className: 'competitions',
}

export const PublicCompetitions: LinkObj = {
  label: 'Competitions',
  icon: Compass,
  activeIcon: CompassFilled,
  to: Path.Registration,
}

export const MySchool: LinkObj = {
  label: 'My School',
  icon: SchoolOutlined,
  activeIcon: SchoolFilled,
  to: Path.MySchoolActivity,
  className: 'mySchool',
}

export const ManageTeams: LinkObj = {
  label: 'Manage Teams',
  icon: TeamsOutlined,
  activeIcon: TeamsFilled,
  to: Path.ManageTeams,
  secondaryIcon: <AddTeamButton />,
  className: 'manageTeams',
}

export const Schedule: LinkObj = {
  label: 'Schedule',
  icon: ScheduleOutlined,
  activeIcon: ScheduleFilled,
  to: Path.ScheduleUpcoming,
  className: 'schedule',
}

export const Standings: LinkObj = {
  label: 'Standings',
  icon: StandingOutlined,
  activeIcon: StandingFilled,
  to: Path.Standings,
  className: 'standings',
}

export const Scrimmage: LinkObj = {
  label: 'Scrimmage',
  icon: ScrimmageOutlined,
  activeIcon: ScrimmageFilled,
  to: Path.Scrimmage,
  className: 'scrimmage',
}

export const Resources: LinkObj = {
  label: 'Resources',
  icon: ResourcesOutlined,
  activeIcon: ResourcesFilled,
  to: Path.Resources,
  isRoutingToCurrLocation: false,
  className: 'resourcesv2',
}

export const ParentDashboard: LinkObj = {
  label: 'Parent Dashboard',
  icon: DashboardOutlined,
  activeIcon: DashboardFilled,
  to: Path.ParentDashboard,
  isRoutingToCurrLocation: false,
}

export const PublicResources: LinkObj = {
  label: 'Resources',
  icon: ResourcesOutlined,
  activeIcon: ResourcesFilled,
  // This is to resolve type errors and a temporary fix.
  to: 'Resources',
  handleOnClick: (): void => {
    window.open('https://help.playvs.com/en/', '_blank', 'noopener')
  },
  isRoutingToCurrLocation: true,
}

export const EventLink: LinkObj = {
  activeIcon: CompassFilled,
  className: 'competitions',
  icon: Compass,
  label: 'Competitions',
  to: StadiumPaths.Events,
}

export const useGetNavigationLinks = ({
  isCoachAtOrg,
  isPlayerAtOrg,
  isCaptainAtOrg,
  isParent,
  isMobile,
  isPublic,
  hasResourcesFlag,
  productType,
}: {
  isCoachAtOrg: boolean
  isPlayerAtOrg: boolean
  isCaptainAtOrg: boolean
  isParent: boolean
  isMobile?: boolean
  isPublic?: boolean
  hasResourcesFlag?: boolean
  productType: ProductType
}): LinkObj[] => {
  const mobileBaseLinks = isMobile ? [Dashboard] : []

  const competitions = useLink(Competitions)
  const mySchool = useLink(MySchool)
  const manageTeams = useLink(ManageTeams)
  const schedule = useLink(Schedule)
  const standings = useLink(Standings)

  if (productType === ProductType.Stadium) {
    if (isParent) {
      return [ParentDashboard]
    }

    // Resource link will be available in Stadium when intercom is implemented.
    const publicLinks = [
      ...mobileBaseLinks,
      EventLink,
      { ...Schedule, disabled: true },
    ]
    const links = [...mobileBaseLinks, EventLink, Schedule]
    return isPublic ? publicLinks : links
  }

  if (isPublic) {
    return [
      PublicCompetitions,
      PublicResources,
      { ...MySchool, disabled: true },
      { ...ManageTeams, disabled: true },
      { ...Schedule, disabled: true },
      { ...Standings, disabled: true },
      { ...Scrimmage, disabled: true },
    ]
  }

  if (isParent) {
    return [ParentDashboard, Resources]
  }

  if (isCoachAtOrg) {
    return [
      ...mobileBaseLinks,
      competitions,
      mySchool,
      manageTeams,
      schedule,
      standings,
      Scrimmage,
      Resources,
    ]
  }

  if (isPlayerAtOrg) {
    const playerLinks = [
      ...mobileBaseLinks,
      Competitions,
      MySchool,
      Schedule,
      Standings,
    ]
    if (isCaptainAtOrg) {
      playerLinks.push(Scrimmage)
    }

    if (hasResourcesFlag) {
      playerLinks.push(Resources)
    }

    return playerLinks
  }

  return [...mobileBaseLinks, Competitions, Schedule, Standings, Resources]
}
