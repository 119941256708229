import React from 'react'
import { EmptyPage } from '@plvs/respawn/components/empty'

import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { withTransaction } from '@elastic/apm-rum-react'
import { MatchScheduleContainer } from './MatchScheduleContainer'
import { SortFilter, MappedMatch } from './schedule'
import { EmptySchedulePage } from './EmptySchedulePage'

interface AllMatchesContainerProps {
  matches: MappedMatch[]
  queueMatches: MappedMatch[]
  error: boolean
  loading: boolean
  isEnrolledInCurrentSeason: boolean
  show: SortFilter
}

const AllMatchesContainerInternal: React.FC<AllMatchesContainerProps> = ({
  matches,
  queueMatches,
  error,
  loading,
  isEnrolledInCurrentSeason,
  show,
}) => {
  if (error)
    return (
      <EmptyPage
        showIcon
        subtitle="Something went wrong, please refresh the page."
      />
    )

  return (
    <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
      {matches.length || queueMatches.length ? (
        <MatchScheduleContainer
          queueMatches={queueMatches}
          show={show}
          teamMatches={matches}
        />
      ) : (
        <EmptySchedulePage
          isEnrolledInCurrentSeason={isEnrolledInCurrentSeason}
        />
      )}
    </WaitTillLoaded>
  )
}

export const AllMatchesContainer = withTransaction(
  'AllMatchesContainer',
  'component'
)(AllMatchesContainerInternal)
