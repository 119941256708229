import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import {
  cleanGraphQLError,
  useAutoskipQuery,
  useDeleteTeamHook,
} from '@plvs/utils'

import {
  refetchGetEventSeasonQuery,
  useDeleteStadiumTeamMutation,
  useGetTeamDeleteInfoQuery,
} from '@plvs/graphql'
import { ApolloError } from '@apollo/client'
import {
  NxAlert,
  NxButton,
  NxModal,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { useRosterCardRenderControllerContext } from '@plvs/respawn/renderController/rosterCard/RosterCardRenderControllerProvider'
import { DeleteTeamType } from '@plvs/respawn/renderController/rosterCard/types/rosterCardController.types'
import { useParams } from 'react-router'

interface DeleteTeamProp {
  onSuccess?(): Promise<void>
  onClose?(): void
  isEnrolled: boolean
  id: string
}

export const DeleteTeam: React.FC<DeleteTeamProp> = ({
  onSuccess,
  id,
  isEnrolled,
  onClose,
}) => {
  const [error, setError] = useState<ApolloError | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { data, loading } = useAutoskipQuery(useGetTeamDeleteInfoQuery, {
    variables: { id },
  })

  const { getRenderControllerState } = useRosterCardRenderControllerContext()
  const {
    shouldShowDeleteTeamDisclaimer,
    deleteTeamSubtitle,
    deleteTeamMutation: deleteTeamMutationType,
  } = getRenderControllerState().rosterCard
  const { eventId } = useParams<{ eventId: string }>()

  const team = data?.team
  const teamName = team?.name

  // mutate
  const [deleteTeamMutation, { loading: isMutating, error: deleteTeamError }] =
    useDeleteTeamHook()

  const [deleteStadiumTeam, { loading: deleteStadiumTeamLoading }] =
    useDeleteStadiumTeamMutation()

  const onClick = async (): Promise<void> => {
    try {
      if (deleteTeamMutationType === DeleteTeamType.deleteScholastic) {
        await deleteTeamMutation(id)
      } else {
        await deleteStadiumTeam({
          variables: { teamId: id },
          awaitRefetchQueries: true,
          refetchQueries: [
            refetchGetEventSeasonQuery({
              seasonId: eventId!,
              isSignedIn: true,
            }),
          ],
        })
      }

      onClose?.()
      await onSuccess?.()
      enqueueSnackbar(`${teamName ?? 'The team'} successfully deleted`, {
        variant: 'success',
      })
    } catch (e: any) {
      setError(e)
    }
  }

  const err = error || deleteTeamError

  const errorMessage =
    err && err.message ? cleanGraphQLError(err.message) : null

  return (
    <WaitTillLoaded loading={loading}>
      <NxModal
        actions={
          <>
            <NxButton label="Cancel" onClick={onClose} variant="text" />
            <NxButton
              data-testid="DeleteTeam_DeleteButton"
              disabled={isMutating || deleteStadiumTeamLoading}
              label="Delete Team"
              onClick={onClick}
              variant="primary"
            />
          </>
        }
        onClose={(): void => {
          if (onClose) onClose()
        }}
        open
        subtitle={<NxTypography>{deleteTeamSubtitle}</NxTypography>}
        titleNode={
          <NxTypography data-testid="DialogHeader_Title" variant="h2">
            {`Delete ${teamName ?? 'this team'}?`}
          </NxTypography>
        }
      >
        {errorMessage && (
          <Box pb={3}>
            <NxAlert
              canClose
              content={
                <NxTypography
                  colorToken="ColorTextAlt2"
                  data-testid="Banner_Subtitle"
                  variant="body1"
                >
                  {errorMessage}
                </NxTypography>
              }
              title={
                <NxTypography data-testid="Banner_Title" variant="h4">
                  Unable to delete team
                </NxTypography>
              }
              variant="error"
            />
          </Box>
        )}
        {shouldShowDeleteTeamDisclaimer && (
          <>
            <NxTypography>
              <strong>Note:</strong> The past matches of this team will be
              preserved. In the future, restoring this team will restore
              previous activity.
            </NxTypography>
            {isEnrolled && (
              <Box pb={2} pl={5} pr={5} pt={1}>
                <NxAlert
                  canClose
                  content={
                    <NxTypography
                      colorToken="ColorTextAlt2"
                      data-testid="Banner_Subtitle"
                      variant="body1"
                    >
                      This team will be unenrolled and will no longer be able to
                      compete in the current season. This can&apos;t be undone.
                    </NxTypography>
                  }
                  title={
                    <NxTypography data-testid="Banner_Title" variant="h4">
                      This team will be deleted and unenrolled
                    </NxTypography>
                  }
                  variant="error"
                />
              </Box>
            )}
          </>
        )}
      </NxModal>
    </WaitTillLoaded>
  )
}
