import { Box } from '@plvs/respawn/features/layout'
import { NxAlert, NxButton } from '@playvs-inc/nexus-components'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'

import { useUnlinkParentFromChildMutation } from '@plvs/graphql/generated'
import { useSnackbar } from 'notistack'
import React from 'react'

interface DisconnectParentAlertProps {
  userId: string
  parentUserId: string
  onDisconnect?: () => void
}

export const DisconnectParentAlert: React.FC<DisconnectParentAlertProps> = ({
  userId,
  parentUserId,
  onDisconnect,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [unlinkParent, { loading }] = useUnlinkParentFromChildMutation()

  const onDisconnectClick = async (): Promise<void> => {
    try {
      const response = await unlinkParent({
        variables: { childUserId: userId, parentId: parentUserId },
      })
      onDisconnect?.()
      if (response.data?.unlinkParentFromChild?.success) {
        enqueueSnackbar(
          'Your account has been successfully unlinked from your parent’s account.',
          { variant: 'success' }
        )
      }
    } catch (e) {
      enqueueSnackbar('Something went wrong, please contact support.', {
        variant: 'error',
      })
    }
  }
  return (
    <NxAlert
      actions={
        <Box>
          <NxButton
            data-cy="unlink-parent-button"
            data-testid="unlink-parent-button"
            disabled={loading}
            label="Unlink from Parent"
            onClick={onDisconnectClick}
            variant="secondary"
          />
        </Box>
      }
      content="You can unlink your account from your parent so you have sole control over your account. Click this button to unlink your account."
      icon={
        <NxSpot
          domain="connectedAccounts"
          height="57px"
          size="small"
          variant="primary"
          width="57px"
        />
      }
      title="You have the option to unlink your account now"
      variant="info"
    />
  )
}
