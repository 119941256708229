import React from 'react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { NxTypography } from '@playvs-inc/nexus-components'

import { Esport, Match, Maybe, MatchTeam } from '@plvs/graphql'
import { getContextualTeams } from '@plvs/utils'

dayjs.extend(tz)

export const MatchReportMatchInfo: React.FC<{
  match: Pick<Match, 'id' | 'bestOf' | 'scheduledStartsAt' | 'status'> & {
    esport: Pick<Esport, 'id' | 'name'>
    teamContext: { teams: Maybe<Pick<MatchTeam, 'id' | 'name'>>[] | null }
  }
}> = ({ match }) => {
  const { team1, team2 } = getContextualTeams(match?.teamContext?.teams)
  return (
    <NxTypography
      colorToken="ColorTextAlt"
      style={{
        minWidth: 200,
        maxWidth: 400,
      }}
    >
      <span data-cy="matchReportMatchInfo" style={{ fontWeight: 'bold' }}>
        {team1?.name ?? '???'} vs {team2?.name ?? '???'}
      </span>
      <br />
      {match.esport.name}, Best of {match.bestOf}
      <br />
      {match.scheduledStartsAt &&
        dayjs(match.scheduledStartsAt)
          .tz(dayjs.tz.guess())
          .format('MMMM Do, h:mm A z')}
    </NxTypography>
  )
}
