import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { format } from 'date-fns-tz'
import { UnknownAvatar } from '@playvs-inc/nexus-icons'
import {
  NxButton,
  NxEsportBanner,
  NxTypography,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'
import { Dayjs } from 'dayjs'

import { EsportSlug } from '@plvs/graphql/types'
import { getBestOfLabel } from '@plvs/rally/features/match/lobby/lobbyUtils'
import { QueueTimer } from '../../queue/QueueTimer'

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    width: '100%',
  },
  detailsWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    '& div': {
      display: 'flex',
      '& p': {
        marginRight: theme.spacing(1),
      },
    },
    alignItems: 'start',
  },
  details: {
    justifyContent: 'space-around',
  },
  schoolName: {
    textTransform: 'uppercase',
  },
  searchMarginHack: {
    visibility: 'hidden',
  },
  barTextColor: {
    color: theme.palette.grey[400],
  },

  titleWrapper: {
    marginBottom: theme.spacing(2),
    alignItems: 'start',
  },
  countdownLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  teamWrapper: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  teamLabel: {
    marginLeft: theme.spacing(1),
  },
}))

export interface MobileQueueLobbyHeaderProps {
  slot?: {
    bestOf: number | null
    startsAt: string | null
    seriesBestOf: number | null
  } | null
  hasSeries: boolean
  statusTitle: string
  statusSubtitle: string
  showCountdown: boolean
  showStatusInfo: boolean
  countdown: Dayjs

  onAccept(): Promise<void>

  onCountdownComplete(): Promise<void>

  slotLobbyQueueDate: string
  esportSlug: EsportSlug | undefined
  time?: string
}

export const MobileQueueLobbyHeader: React.FC<MobileQueueLobbyHeaderProps> = ({
  slot,
  statusTitle,
  statusSubtitle,
  showCountdown,
  showStatusInfo,
  countdown,
  onAccept,
  onCountdownComplete,
  slotLobbyQueueDate,
  esportSlug,
  time,
}) => {
  const classes = useStyles()

  const getBannerContent = (): React.ReactElement => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <Box className={classes.detailsWrapper} position="relative">
          <Box className={classes.details} flexGrow="0">
            <NxTypography colorToken="OverlayColorTextBase" variant="body2">
              {getBestOfLabel(slot?.bestOf, slot?.seriesBestOf)}
            </NxTypography>
            <NxTypography className={classes.barTextColor}>|</NxTypography>
            <NxTypography colorToken="OverlayColorTextBase" variant="body2">
              {slot?.startsAt
                ? format(new Date(slot.startsAt), 'h:mm a')
                : '???'}
            </NxTypography>
            <NxTypography className={classes.barTextColor}>|</NxTypography>
            <NxTypography colorToken="OverlayColorTextBase" variant="body2">
              {slot?.startsAt
                ? format(new Date(slot.startsAt), 'LLL d, y')
                : '???'}
            </NxTypography>
          </Box>
        </Box>
        {showStatusInfo ? (
          <Box
            alignItems="center"
            className={classes.titleWrapper}
            display="flex"
            flexDirection="column"
          >
            <Box mb={2}>
              <NxTypography colorToken="OverlayColorTextBase" variant="body2">
                {statusTitle}
              </NxTypography>
            </Box>

            {showCountdown ? (
              <Box>
                <QueueTimer
                  countdownTime={countdown}
                  onCountdownComplete={onCountdownComplete}
                  slotLobbyQueueDate={slotLobbyQueueDate}
                  variant="h1"
                />
              </Box>
            ) : (
              <></>
            )}

            {time && (
              <NxTypography color="inherit" variant="display2">
                {time}
              </NxTypography>
            )}

            <Box>
              <NxTypography colorToken="OverlayColorTextBase" variant="body3">
                {statusSubtitle}
              </NxTypography>
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Box
          className={classes.teamWrapper}
          display="flex"
          flex="1"
          flexDirection="row"
          marginRight="auto"
        >
          <UnknownAvatar height="55px" width="55px" />
          <NxTypography
            className={classes.teamLabel}
            colorToken="OverlayColorTextBase"
            variant="h1"
          >
            Opponent TBD
          </NxTypography>
        </Box>
        <Box>
          <NxButton
            fullWidth
            label="Find Opponent"
            onClick={async (): Promise<void> => onAccept()}
            shouldUseOverlayColors
            variant="primary"
          />
        </Box>
      </Box>
    )
  }

  return (
    <>
      {esportSlug && (
        <Box data-testid="MobileQueueLobbyHeader_Wrapper" mb={2} pr={2} pt={2}>
          <NxEsportBanner
            additionalContent={
              <Box pt={2} px={2}>
                {getBannerContent()}
              </Box>
            }
            esportSlug={esportSlug}
            size="large"
            useOverlayImage
            variant={PersonaImageVariant.Match}
          />
        </Box>
      )}
    </>
  )
}
