import React, { memo, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { CompetitionGroup, EsportRating, EsportSlug } from '@plvs/graphql'
import {
  NxBreadcrumbs,
  OverlayPill,
  NxEsportBanner,
  PersonaImageVariant,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { noop } from 'ramda-adjunct'
import { Path } from '@plvs/const'
import { IconButton, makeStyles } from '@material-ui/core'
import { CaretLeft } from '@playvs-inc/nexus-icons'
import { getHeroSubtitle, useGetEsportPills } from './ExploreHelpers'

const useStyles = makeStyles((theme) => ({
  sponsorEnrollmentPill: {
    marginLeft: theme.spacing(0),
    letterSpacing: '0.12em',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(12),
    },
  },
  pill: {
    letterSpacing: '0.12em',
  },
  pillsContainer: {
    display: 'flex',
  },
  iconButton: {
    borderRadius: theme.shape.borderRadius * 5,
    border: `1px solid rgba(255, 255, 255, 0.3)`,
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.125),
  },
  icon: {
    color: theme.palette.OverlayColorTextBase,
  },
}))

interface Props {
  competitionGroup: CompetitionGroup | undefined

  enrollmentStatusLabel: string
  enrollmentStatusVariant: 'success' | 'info' | 'disabled'
  esportName: string
  esportRating: EsportRating
  esportSlug: EsportSlug | undefined
  metaseasonName: string
  selectedLeagueName: string
  isPublic: boolean
  sponsorAvatar?: any
  rightSideLogo?: any
  mobileRightSideSponsorLogo?: any
}

export const LeagueDetailsHero: React.FC<Props> = memo(
  ({
    competitionGroup,
    esportName,
    esportRating,
    esportSlug,
    metaseasonName,
    selectedLeagueName,
    isPublic,
    enrollmentStatusLabel,
    enrollmentStatusVariant,
    sponsorAvatar,
    rightSideLogo,
    mobileRightSideSponsorLogo,
  }) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const isMobile = useBreakpointXs()

    const { search } = useLocation()

    const queryParams = useMemo(() => new URLSearchParams(search), [search])

    const redirectText = queryParams.get('text')

    const redirectLink = queryParams.get('redirect')

    const hasRedirect = !!redirectLink

    const pills = useGetEsportPills(esportSlug)

    return (
      <Box mb={isMobile && mobileRightSideSponsorLogo ? 5 : 0}>
        {esportSlug && (
          <>
            <NxEsportBanner
              aboveTitleContent={
                <OverlayPill
                  className={
                    sponsorAvatar ? classes.sponsorEnrollmentPill : classes.pill
                  }
                  label={enrollmentStatusLabel}
                  size="small"
                  variant={enrollmentStatusVariant}
                />
              }
              additionalContent={
                <Box
                  alignItems="center"
                  className={classes.pillsContainer}
                  flexWrap="wrap"
                  gridGap="8px"
                  ml={sponsorAvatar ? [0, 12] : 0}
                  mt={2}
                >
                  {pills?.map((label) => {
                    return (
                      <OverlayPill
                        key={label}
                        className={classes.pill}
                        label={label}
                        size="small"
                        variant="disabled"
                      />
                    )
                  })}
                </Box>
              }
              breadcrumbs={
                hasRedirect ? (
                  <Box alignItems="center" display="flex">
                    <a data-testid="redirect" href={redirectLink || ''}>
                      <IconButton className={classes.iconButton}>
                        <CaretLeft className={classes.icon} />
                      </IconButton>
                    </a>
                    {redirectText && (
                      <NxTypography
                        colorToken="OverlayColorTextBase"
                        variant="body2"
                      >
                        {redirectText}
                      </NxTypography>
                    )}
                  </Box>
                ) : (
                  <>
                    {!isPublic && (
                      <NxBreadcrumbs
                        breadcrumbs={[
                          {
                            label: 'Explore',
                            onClick: (): void => {
                              navigate(Path.Explore)
                            },
                          },
                          {
                            label: esportName,
                            onClick: (): void =>
                              navigate(
                                `/app/explore/leagues-table/${competitionGroup}/${
                                  esportSlug || ''
                                }`
                              ),
                          },
                          { label: selectedLeagueName, onClick: noop },
                        ]}
                        useOverlayColor
                      />
                    )}
                  </>
                )
              }
              esportSlug={esportSlug}
              hasNoTopNavBar={!hasRedirect}
              rightSideSponsorLogo={rightSideLogo}
              size="large"
              sponsorLogo={sponsorAvatar}
              subtitle={getHeroSubtitle({
                competitionGroup: competitionGroup as CompetitionGroup,
                rating: esportRating ?? EsportRating.General,
                metaseasonName: metaseasonName ?? '',
              })}
              title={selectedLeagueName}
              useOverlayImage
              variant={PersonaImageVariant.League}
            />
            {isMobile && (
              <Box ml={-1} position="absolute" width="100%">
                {mobileRightSideSponsorLogo}
              </Box>
            )}
          </>
        )}
      </Box>
    )
  }
)
