import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import {
  NxButton,
  NxFirebobMessage,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { GameCodeCopyButton } from '@plvs/respawn/features/match-lobby/GameCodeCopyButton'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { useStyles } from '../../../MatchAssistant.styles'

const useTournamentCodeStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

interface TournamentCodeProps {
  onNext?(): void
}

export const TournamentCode: React.FC<TournamentCodeProps> = ({ onNext }) => {
  const styles = useStyles()
  const specificStyles = useTournamentCodeStyles()
  const theme = useTheme()
  const { gameOrdinal, lolGameCode } = useMatchAssistantContext()

  const isWaitingForTournamentCode = !lolGameCode

  const nxFirebobMessage = isWaitingForTournamentCode
    ? "Hang tight! We're generating your tournament code"
    : `Grab your code & get ready to play Game ${gameOrdinal}!`

  return (
    <>
      <Box className={specificStyles.container} pb={4}>
        <NxSpot
          domain="league"
          height={100}
          size="large"
          variant="secondary"
          width={100}
        />
        <Box className={specificStyles.container} pt={3}>
          <NxTypography variant="h4">Tournament Code</NxTypography>
          <NxTypography variant="body1">
            Copy and paste the code into your League Client
          </NxTypography>
        </Box>
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage message={nxFirebobMessage} />

        <Box
          className={styles.ctaContainer}
          display="flex"
          gridGap={theme.spacing(1)}
          width="100%"
        >
          <GameCodeCopyButton
            gameOrdinal={gameOrdinal}
            isWaitingForTournamentCode={isWaitingForTournamentCode}
            lolGameCode={lolGameCode}
          />
          <NxButton fullWidth label="Next" onClick={onNext} variant="primary" />
        </Box>
      </MatchAssistantFooter>
    </>
  )
}
