import React from 'react'

import { StepType } from '@plvs/utils'
import { GamePreview as GamePreviewWithScore } from '@plvs/rally/features/match/assistant/steps/madden/gamePreview'
import { TeamSelect } from '@plvs/rally/features/match/assistant/steps/nba2k/teamSelect/TeamSelect'
import { MatchPreview as NBA2kMatchPreview } from '@plvs/rally/features/match/assistant/steps/nba2k/matchPreview/MatchPreview'
import { Scoreboard as ScoreboardWithInputedScore } from '@plvs/rally/features/match/assistant/steps/madden/scoreboard/Scoreboard'
import { MatchAssistantAdapterStepValue } from '@plvs/rally/features/match/assistant/adapter/matchAssistantAdapterSteps'
import { MatchAssistantActions } from '@plvs/rally/features/match/assistant/MatchAssistantActions'
import { TeamReporting } from '@plvs/rally/features/match/assistant/steps/reporting/TeamReporting'
import type { MatchAssistantAdapterProps } from './MatchAssistantAdapter.types'

export const nba2kMatchAssistantSteps = ({
  subtitle,
  generalSteps,
  isMatchComplete,
  match,
  setStepIndex,
  gameOrdinal,
  DisputeResultsButton,
}: MatchAssistantAdapterProps): Record<
  StepType | string,
  MatchAssistantAdapterStepValue
> => {
  return {
    ...generalSteps,
    [StepType.CharacterSelect]:
      gameOrdinal > 1
        ? [
            {
              step: <GamePreviewWithScore onNext={setStepIndex} />,
              title: 'Intermission',
              subtitle,
              actions: <MatchAssistantActions />,
            },
            {
              step: <TeamSelect />,
              subtitle,
              title: 'Team Picker',
              actions: <MatchAssistantActions />,
            },
          ]
        : [
            {
              step: <TeamSelect />,
              subtitle,
              title: 'Team Picker',
              actions: <MatchAssistantActions />,
            },
          ],

    [StepType.Report]: [
      {
        step: <NBA2kMatchPreview onNext={setStepIndex} />,
        title: 'Match Preview',
        subtitle,
        actions: <MatchAssistantActions />,
      },
      {
        step: <TeamReporting />,
        title: 'Scoreboard',
        subtitle: isMatchComplete ? '' : subtitle,
        actions: <MatchAssistantActions />,
      },
    ],
    [StepType.Final]: [
      {
        step: (
          <ScoreboardWithInputedScore isFinal={isMatchComplete} match={match} />
        ),
        title: 'Final Score',
        subtitle: '',
        actions: DisputeResultsButton,
      },
    ],
  }
}
