import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { ChecklistGroupPayload } from '@plvs/graphql/generated'
import { ChecklistAccordion } from './ChecklistAccordion'

const useStyles = makeStyles((theme) => ({
  headerText: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  schoolId: string
  userId: string
  checklist?: Array<ChecklistGroupPayload>
  allAvailableEsportsNames?: (string | null)[]
}

export const ProgramChecklist: React.FC<Props> = ({
  checklist,
  schoolId,
  userId,
  allAvailableEsportsNames,
}) => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" mt={7}>
      <NxTypography className={classes.headerText} variant="h2">
        How to Start Your School&apos;s Esport Program
      </NxTypography>
      <NxTypography className={classes.headerText} variant="subtitle1">
        Complete each section below to get PlayVS esports up and running at your
        school
      </NxTypography>
      {checklist?.map((checklistItem, index) => {
        return (
          <ChecklistAccordion
            key={checklistItem.group}
            allAvailableEsportsNames={allAvailableEsportsNames}
            checklist={checklistItem}
            position={index + 1}
            schoolId={schoolId}
            userId={userId}
          />
        )
      })}
    </Box>
  )
}
