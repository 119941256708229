import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { useImageLoader } from '@plvs/respawn/features/avatar'

interface AuthLogoProps {
  isNasef?: boolean
  width?: number
  height?: number
}

export const AuthLogo = ({
  isNasef,
  width,
  height,
}: AuthLogoProps): React.ReactElement => {
  const theme = useTheme()

  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const NASEFMode = isDarkMode
    ? 'https://s3.amazonaws.com/assets.playvs.com/branding/CIF/NasefDark.webp'
    : 'https://s3.amazonaws.com/assets.playvs.com/branding/CIF/NasefLight.webp'
  const { imageSrc } = useImageLoader({
    src: NASEFMode,
  })

  return isNasef ? (
    <Box display="flex" justifyContent="center">
      <img
        alt="nasef-logo"
        data-testid={`nasef-logo-${theme.palette.type}`}
        height={height || 64}
        src={imageSrc}
        width={width || 188}
      />
    </Box>
  ) : (
    <Box
      alignItems="center"
      display="flex"
      justifyContent={['center', 'unset']}
      marginLeft={[-0.875, 4]}
      minHeight={height || 48}
      mt={4}
    >
      <Box mt={1.4}>
        <img
          alt="playvs-logo"
          data-testid="playvs-logo"
          height={height || 48}
          src="https://s3.amazonaws.com/assets.playvs.com/PlayVS/ScholasticPlayVSLogo.webp"
          width={width || 157}
        />
      </Box>
    </Box>
  )
}
