import React from 'react'
import {
  CompetitionModel,
  EsportSlug,
  IntervalTense,
  useGetLeagueGroupsForSchoolQuery,
} from '@plvs/graphql'
import {
  competitionModelToDisplay,
  MinimalMetaseason,
  RelativeTiming,
} from '@plvs/utils'
import { ManageTeamsEnrollmentCard } from '@plvs/rally/components/manageTeams/ManageTeamsEnrollmentCard'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { useManageTeamsContext } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEnrollment } from '@plvs/respawn/containers/enrollment/useEnrollment'
import { EnrollmentStatus } from '@plvs/respawn/containers/enrollment/types'

interface ManageTeamsEnrollmentCardContainerProps {
  path: string
  title: string
  esportSlug: EsportSlug | null
  metaseason: MinimalMetaseason | undefined
}

export const ManageTeamsEnrollmentCardContainer: React.FC<ManageTeamsEnrollmentCardContainerProps> = ({
  path,
  esportSlug,
  title,
  metaseason,
}) => {
  const { id: schoolId } = useSelectedOrganizationFn()
  const flags = useFlags()
  const {
    id: orgId,
    competitionGroup: orgCompetitionGroup,
  } = useSelectedOrganizationFn()
  const {
    loading: enrollmentLoading,
    status,
    hasAnnualPassInstance,
    competitionModel,
  } = useEnrollment({
    isCoach: true,
    metaseasonId: metaseason?.id ?? '',
    schoolId: orgId,
    includeOrganizationEnrollment: true,
  })

  const { enrolledTeamIds, competitionInterval } = useManageTeamsContext()
  const metaseasonId = metaseason?.id ?? ''
  const { data, loading } = useGetLeagueGroupsForSchoolQuery({
    variables: { id: schoolId ?? '', metaseasonId },
    skip: !metaseasonId || !schoolId,
  })
  const leaguesForEsport = (data?.getActiveLeaguesBySchoolId ?? []).filter(
    (league) => {
      return (
        league?.esport.slug === esportSlug &&
        league?.seasons?.some((season) => season.metaseasonId === metaseasonId)
      )
    }
  )

  const shouldRedirectToPlanSelection =
    !flags.freeCompetition &&
    !enrollmentLoading &&
    status === EnrollmentStatus.NotEnrolled &&
    !hasAnnualPassInstance

  const navigationPath = `${path}/${
    hasAnnualPassInstance ||
    flags.freeCompetition ||
    shouldRedirectToPlanSelection
      ? `teams/${competitionModelToDisplay({
          competitionModel: competitionModel ?? CompetitionModel.Rec,
          competitionGroup: orgCompetitionGroup,
        }).toLowerCase()}`
      : `select`
  }`
  const isNotCurrentSeason = competitionInterval
    ? competitionInterval.tense !== IntervalTense.Current
    : metaseason?.timing !== RelativeTiming.Present
  const futureTense =
    competitionInterval?.tense === IntervalTense.Future &&
    metaseason?.timing === RelativeTiming.Future
  const hideEnrollmentCard = isNotCurrentSeason && !futureTense
  return hideEnrollmentCard ? (
    <></>
  ) : (
    <ManageTeamsEnrollmentCard
      esportSlug={esportSlug}
      hasAnEnrolledTeam={enrolledTeamIds.length > 0}
      leagues={leaguesForEsport}
      loading={loading}
      metaseasonId={metaseasonId}
      path={navigationPath}
      title={title}
    />
  )
}
