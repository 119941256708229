import React from 'react'

import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { NxCheckbox, NxTypography } from '@playvs-inc/nexus-components'

export const useStyles = makeStyles(() => ({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export const ArchiveMatchButton: React.FC<{
  isArchiveMatchChecked: boolean
  setIsArchiveMatchChecked(isArchiveMatchChecked: boolean): void
}> = ({ isArchiveMatchChecked, setIsArchiveMatchChecked }) => {
  const classes = useStyles()
  return (
    <Box className={classes.checkboxContainer}>
      <Box>
        <NxCheckbox
          checked={isArchiveMatchChecked}
          data-testid="archive-match-checkbox"
          name="Archive Match"
          onChange={(): void => {
            setIsArchiveMatchChecked(!isArchiveMatchChecked)
          }}
        />
      </Box>
      <NxTypography>Archive Match</NxTypography>
    </Box>
  )
}
