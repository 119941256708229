import {
  EsportSlug,
  GetGeneralEsportAdapterDetailsQuery,
  useGetGeneralEsportAdapterDetailsQuery,
  useGetTeamsUserAccountConnectionsQuery,
} from '@plvs/graphql'
import { isCurrentSeason, isAccountProviderLinked } from '@plvs/utils'
import { useEffect, useMemo, useState } from 'react'
import { head } from 'ramda'
import { providerPrettyNames } from '@plvs/respawn/features/esport/creator/esportStaticDetails'
import {
  allTeamsAccountStatusVar,
  AllTeamStatusInterface,
  EsportData,
  PlayerStatus,
} from '../models/AllTeamsAccountStatus'

const getEsportDataMapping = (
  esportDetailsData: GetGeneralEsportAdapterDetailsQuery | undefined
): Record<EsportSlug, EsportData> => {
  const empty = {} as Record<EsportSlug, EsportData>
  if (!esportDetailsData) return empty
  return (
    esportDetailsData?.esports?.reduce<Record<EsportSlug, EsportData>>(
      (accum, esport) => {
        return {
          ...accum,
          [esport.slug]: {
            provider: head(esport?.details?.accountProviders ?? []) ?? null,
            minTeamSize: esport?.details?.teamMinStarterSize,
          },
        }
      },
      empty
    ) || empty
  )
}

export const useAllTeamsAccountStatus = (
  teamIds: string[]
): [
  allTeamsAccountStatus: AllTeamStatusInterface | undefined,
  teamAccountStatusLoading: boolean,
  error?: Error
] => {
  const allTeamsAccountStatus = allTeamsAccountStatusVar()
  const {
    data,
    loading: loadingTeams,
    error: accountErr,
  } = useGetTeamsUserAccountConnectionsQuery({
    variables: {
      filters: {
        teamIds,
      },
    },
    skip: !teamIds.length || !teamIds[0],
  })

  const teams = data?.getTeams?.teams || []
  const {
    data: esportData,
    loading: esportDataLoading,
  } = useGetGeneralEsportAdapterDetailsQuery()

  const providerEsportMapping = useMemo<Record<EsportSlug, EsportData>>(() => {
    return getEsportDataMapping(esportData)
  }, [esportData])
  const [teamIdProviderNameMapping] = useState<Record<string, string>>({})
  const teamsStatus = useMemo<AllTeamStatusInterface>(
    () =>
      teams?.reduce<AllTeamStatusInterface>((accum, team) => {
        if (!team || !team.id) return accum
        const providerEsportData = providerEsportMapping[team.esport.slug]
        teamIdProviderNameMapping[team.id] =
          providerPrettyNames[providerEsportData?.provider]
        const allPlayers = team?.roster?.players ?? []
        const filledStarterPositions =
          team?.roster?.formats &&
          head(team?.roster?.formats)?.starters?.filter(
            (starter) => starter.player?.user.id
          )
        const players =
          allPlayers.reduce<PlayerStatus>((playerAccum, player) => {
            const isAcctConnected =
              (providerEsportData &&
                isAccountProviderLinked(
                  player.user,
                  providerEsportData.provider
                )) ||
              false
            const isPlatformSelected =
              team.esport.slug === EsportSlug.RocketLeague ||
              team.esport.slug === EsportSlug.RocketLeagueGMA
                ? player?.user.userEsportPlatforms?.some(
                    (platform) => platform.esportId === team.esport.id
                  ) || false
                : true
            const platformForEsport = player?.user.userEsportPlatforms?.find(
              (platform) => platform.esportId === team.esport.id
            )
            return {
              ...playerAccum,
              [player.user.id]: {
                isAcctConnected,
                isPlatformSelected,
                isFullyConnected: isAcctConnected && isPlatformSelected,
                platform: platformForEsport?.platform,
              },
            }
          }, {} as PlayerStatus) || {}

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const teams = {
          ...accum.teams,
          [team.id]: {
            isFullyConnected: !Object.values(players).some(
              (player) => !player.isFullyConnected
            ),
            areAllAccountsConnected: !Object.values(players).some(
              (player) => !player.isAcctConnected
            ),
            areAllPlatformsSelected: !Object.values(players).some(
              (player) => !player.isPlatformSelected
            ),
            esportProviderName:
              providerPrettyNames[providerEsportData?.provider],
            isMissingPlayers:
              (filledStarterPositions?.length || 0) <
              providerEsportData?.minTeamSize,
            isEnrolled:
              team?.enrolledSeasons?.some((season) =>
                isCurrentSeason(season)
              ) || false,
            players,
          },
        }

        return {
          isFullyConnected: !Object.values(teams).some(
            (t) => t.isEnrolled && !t.isFullyConnected
          ),
          areAllAccountsConnected: !Object.values(teams).some(
            (t) => t.isEnrolled && !t.areAllAccountsConnected
          ),
          areAllPlatformsSelected: !Object.values(teams).some(
            (t) => t.isEnrolled && !t.areAllPlatformsSelected
          ),
          teams,
        }
      }, {} as AllTeamStatusInterface),
    [teams, providerEsportMapping]
  )
  useEffect(() => {
    if (!loadingTeams && !esportDataLoading) {
      allTeamsAccountStatusVar(teamsStatus)
    }
  }, [teamsStatus, loadingTeams, esportDataLoading])

  return [allTeamsAccountStatus, loadingTeams || esportDataLoading, accountErr]
}
