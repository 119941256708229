import React, { useEffect } from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'
import {
  NxButton,
  NxFirebobMessage,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { MatchAssistantAlertType } from '@plvs/utils'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { useStyles } from '../../../MatchAssistant.styles'
import {
  mapTeamRoster,
  useMatchAssistantContext,
} from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantStepAlertsContext } from '../../../hooks/useMatchAssistantStepAlerts'
import { PlayersMatchUp } from '../../../PlayersMatchUp'
import { Scoreboard } from '../scoreboard/Scoreboard'

interface GamePreviewProps {
  onNext?(): void
}

export const GamePreview: React.FC<GamePreviewProps> = ({ onNext }) => {
  const styles = useStyles()
  const theme = useTheme()
  const {
    homeTeam,
    awayTeam,
    gameOrdinal = 1,
    match,
    refetch,
  } = useMatchAssistantContext()

  const {
    match: { canParticipate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const handleNext = (): void => {
    onNext?.()
  }

  const isNextDisabled = !canParticipate || !onNext
  const { openStepAlert, getStepAlerts } = useMatchAssistantStepAlertsContext()
  const stepAlertsData = getStepAlerts()

  useEffect(() => {
    refetch()
  }, [gameOrdinal])

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(3)}
        justifyContent="center"
      >
        <NxTypography variant="h1">Prepare for Game {gameOrdinal}</NxTypography>

        <NxTypography variant="overline">Players</NxTypography>

        <PlayersMatchUp
          currentPlayersAwayTeam={mapTeamRoster({
            teamRoster: awayTeam?.starters ?? [],
            teamName: awayTeam?.name ?? '',
          })}
          currentPlayersHomeTeam={mapTeamRoster({
            teamRoster: homeTeam?.starters ?? [],
            teamName: homeTeam?.name ?? '',
          })}
        />
      </Box>

      {gameOrdinal > 1 && (
        <Box
          borderTop={`1px solid ${theme.palette.BorderLight}`}
          mt={3}
          mx={-3}
          pt={3}
        />
      )}

      <Scoreboard isFinal={false} match={match} />

      <MatchAssistantFooter>
        <NxFirebobMessage message="Take a breather and start when ready!" />

        <Box
          className={styles.ctaContainer}
          display="flex"
          gridGap={theme.spacing(1)}
          width="100%"
        >
          <NxButton
            className={styles.cta}
            disabled={stepAlertsData.actionLoading}
            fullWidth
            label="Change Roster"
            loading={stepAlertsData.actionLoading}
            onClick={(): void => openStepAlert(MatchAssistantAlertType.Reset)}
            variant="secondary"
          />

          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label="Choose Selections"
            onClick={handleNext}
            variant="primary"
          />
        </Box>
      </MatchAssistantFooter>
    </>
  )
}
