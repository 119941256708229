import React from 'react'

import { getPickForGameOrdinal } from '@plvs/utils'
import { GameAssistantSelection } from '@plvs/graphql/generated'
import { HeadToHeadMatchPreview } from '@plvs/rally/features/match/assistant/steps/general/matchPreview/HeadToHeadMatchPreview'
import { useMatchAssistantContext } from '../../../useMatchAssistant'

const BASKETBALL_LOGO_URL =
  'https://s3.amazonaws.com/assets.playvs.com/match-assistant/basketball.png'
interface MatchPreviewProps {
  onNext?(): void
}

export const MatchPreview: React.FC<MatchPreviewProps> = ({ onNext }) => {
  const { currentStep, homeTeam, awayTeam } = useMatchAssistantContext()

  const homeTeamPick = getPickForGameOrdinal({
    options: (currentStep?.characterPicks || []) as GameAssistantSelection[],
    teamId: homeTeam?.id,
    gameOrdinal: currentStep?.step?.gameOrdinal,
  })
  const awayTeamPick = getPickForGameOrdinal({
    options: (currentStep?.characterPicks || []) as GameAssistantSelection[],
    teamId: awayTeam?.id,
    gameOrdinal: currentStep?.step?.gameOrdinal,
  })

  return (
    <HeadToHeadMatchPreview
      awayTeamPick={awayTeamPick}
      customCenter={<img alt="basketball-logo" src={BASKETBALL_LOGO_URL} />}
      homeTeamPick={homeTeamPick}
      onNext={onNext}
      showOverlayImage
    />
  )
}
