import React from 'react'
import { ResourceType, TaskState } from '@plvs/graphql/generated'
import { Box } from '@plvs/respawn/features/layout'
import { Path } from '@plvs/const'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { NxTypography, NxTaskCard } from '@playvs-inc/nexus-components'

import { useNavigate } from 'react-router'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { TaskCtaButton } from '../components/TaskCtaButton'

export const PlayerAccountConnectionTaskCard: React.FC<{
  task: { state: string; id: string; type: string }
  isMobile: boolean
  schoolId?: string
  index: number
}> = ({ task, isMobile, schoolId, index }) => {
  const { setEntity, selectedEntityId } = useProfileContext()
  const navigate = useNavigate()

  const onNavigateToManageConnections = (): void => {
    if (selectedEntityId !== schoolId) {
      setEntity(ResourceType.Organization, schoolId)
    }
    navigate(Path.ManageTeams)
  }

  return (
    <NxTaskCard
      taskBody={
        <>
          <Box mb={1}>
            <NxTypography
              data-cy="ensure-players-connect-accounts"
              variant="h4"
            >
              Check Connected Accounts
            </NxTypography>
          </Box>
          <NxTypography variant={isMobile ? 'body3' : 'subtitle1'}>
            Get your players to connect their accounts
          </NxTypography>
        </>
      }
      taskCta={
        <TaskCtaButton
          buttonText="Manage Teams"
          completed={task.state === TaskState.Completed}
          disabled={task.state === TaskState.Disabled}
          onClick={onNavigateToManageConnections}
          task={task}
          tooltipText={
            task.state === TaskState.Disabled
              ? 'You must be verified before you can view the Manage Teams page'
              : undefined
          }
        />
      }
      taskHeader={
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <NxSpot
            domain="connectedAccounts"
            height={64}
            size="small"
            variant="primary"
            width={64}
          />
          <NxTypography variant="overline">TASK {index}</NxTypography>
        </Box>
      }
    />
  )
}
