/* eslint-disable import/no-restricted-paths */
import React from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  EsportSlug,
  Game,
  GameResult,
  Maybe,
  useGetDataForMatchFaceoffSeriesQuery,
} from '@plvs/graphql'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { defaultToEmptyArray } from '@plvs/utils'
import { DesktopMatchFaceoffTeamAvatar } from './DesktopMatchFaceoffTeamAvatar'
import { DesktopMatchFaceoffTeamName } from './DesktopMatchFaceoffTeamName'
import { MobileMatchFaceoffTeam } from './MobileMatchFaceoffTeam'
import { MatchFaceoffScore } from './MatchFaceoffScore'
import { ScrimmageBadge } from '../../badge'

export type VsMatchFaceoffProps = {
  isScrimmage: boolean
  mobile: boolean
  prematch: boolean
  size: 'small' | 'medium' | 'large'
  isSmallBreakpoint: boolean
  isPreseason?: boolean
  esportSlug: EsportSlug | null | undefined
  matchId: string
  bestOf: number
  games: (Pick<Game, 'id'> & {
    gameResults: Maybe<Pick<GameResult, 'id' | 'placing' | 'teamId'>[]>
  })[]
  team1Score: number
  team2Score: number
  homeTeamId: string
  awayTeamId: string
  winningTeamId: string
}

export const VsMatchFaceoff: React.FC<VsMatchFaceoffProps> = ({
  isScrimmage,
  mobile,
  prematch,
  size,
  isSmallBreakpoint,
  isPreseason = false,
  esportSlug,
  matchId,
  bestOf,
  games,
  team1Score,
  team2Score,
  homeTeamId,
  awayTeamId,
  winningTeamId,
}) => {
  const { hasSeries, hasApiIntegration } = useGeneralEsportAdapter(esportSlug)
  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()
  const { lobby } = getMatchLobbyRenderControllerState()

  const { data, loading } = useGetDataForMatchFaceoffSeriesQuery({
    variables: {
      matchId,
    },
    skip: !matchId || !hasSeries,
  })

  const disableGameDots = !hasApiIntegration && hasSeries

  const homeTeamFromControllerState = lobby.teamDisplayDetails.find(
    (x) => x.id === homeTeamId
  )
  const homeTeam = {
    ...homeTeamFromControllerState,
    id: homeTeamFromControllerState?.id ?? '',
    teamName: homeTeamFromControllerState?.teamName ?? '',
    avatarUrl: homeTeamFromControllerState?.avatarUrl ?? '',
    score: team1Score,
  }

  const awayTeamFromControllerState = lobby.teamDisplayDetails.find(
    (x) => x.id === awayTeamId
  )
  const awayTeam = {
    ...awayTeamFromControllerState,
    id: awayTeamFromControllerState?.id ?? '',
    teamName: awayTeamFromControllerState?.teamName ?? '',
    avatarUrl: awayTeamFromControllerState?.avatarUrl ?? '',
    score: team2Score,
  }

  const serieshomeTeamPlacings = defaultToEmptyArray(
    data?.match?.series?.map((x) => {
      return x.teamContext?.teams[0]?.result?.placing
    })
  )

  const seriesAwayTeaPlacings = defaultToEmptyArray(
    data?.match?.series?.map((x) => {
      return x.teamContext?.teams[1]?.result?.placing
    })
  )

  const mobileRender = (
    <>
      <Box display="flex" justifyContent="space-between" py={3}>
        <MobileMatchFaceoffTeam
          bestOf={bestOf}
          disableGameDots={disableGameDots}
          games={games}
          isHomeTeam
          isWinningTeam={homeTeam.id === winningTeamId}
          seriesPlacings={serieshomeTeamPlacings}
          size={size}
          team={homeTeam}
        />{' '}
        {!prematch && (
          <Box alignItems="center" display="flex" ml={2}>
            <NxTypography
              colorToken="OverlayColorTextBase"
              data-cy="teamScore"
              variant="display"
            >
              {homeTeam.score}
            </NxTypography>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" py={3}>
        <MobileMatchFaceoffTeam
          bestOf={bestOf}
          disableGameDots={disableGameDots}
          games={games}
          isHomeTeam={false}
          isWinningTeam={awayTeam.id === winningTeamId}
          seriesPlacings={seriesAwayTeaPlacings}
          size={size}
          team={awayTeam}
        />
        {!prematch && (
          <Box alignItems="center" display="flex" ml={2}>
            <NxTypography
              colorToken="OverlayColorTextBase"
              data-cy="opponentScore"
              variant="display"
            >
              {awayTeam.score}
            </NxTypography>
          </Box>
        )}
      </Box>
    </>
  )

  const desktopRender = (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        pb={4}
      >
        <DesktopMatchFaceoffTeamAvatar
          bestOf={bestOf}
          disableGameDots={disableGameDots}
          games={games}
          isHomeTeam
          isSmallBreakpoint={isSmallBreakpoint}
          seriesPlacings={serieshomeTeamPlacings}
          showTeamColorBadge={size === 'large'}
          size={size}
          team={homeTeam}
          winningTeam={homeTeam.id === winningTeamId}
        />
        <Box alignItems="center" display="flex" flexDirection="column">
          {!prematch && (
            <MatchFaceoffScore
              awayTeamScore={awayTeam.score}
              homeTeamScore={homeTeam.score}
            />
          )}
          {isScrimmage && (
            <Box mt={1.5}>
              <ScrimmageBadge isPreseason={isPreseason} size="small" />
            </Box>
          )}
        </Box>
        <DesktopMatchFaceoffTeamAvatar
          bestOf={bestOf}
          disableGameDots={disableGameDots}
          games={games}
          isHomeTeam={false}
          isSmallBreakpoint={isSmallBreakpoint}
          reverse
          seriesPlacings={seriesAwayTeaPlacings}
          showTeamColorBadge={size === 'large'}
          size={size}
          team={awayTeam}
          winningTeam={awayTeam.id === winningTeamId}
        />
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box flex={1} flexDirection="column">
          <DesktopMatchFaceoffTeamName
            schoolName={homeTeam.schoolName}
            schoolSlug={homeTeam.schoolSlug}
            teamId={homeTeam.id}
            teamName={homeTeam.teamName}
          />
        </Box>

        <DesktopMatchFaceoffTeamName
          reverse
          schoolName={awayTeam.schoolName}
          schoolSlug={awayTeam.schoolSlug}
          teamId={awayTeam.id}
          teamName={awayTeam.teamName}
        />
      </Box>
    </>
  )

  return (
    <WaitTillLoaded loading={loading}>
      {mobile ? mobileRender : desktopRender}
    </WaitTillLoaded>
  )
}
