import { Button, makeStyles } from '@material-ui/core'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { Path } from '@plvs/const'
import * as analytics from '@plvs/respawn/features/analytics'
import {
  UserRoleName,
  useMyOnboardReferralQuery,
  useGenerateReferralLinkMutation,
  ResourceType,
} from '@plvs/graphql'
import { isCoachAtOrganization, userRoleNamesToUserType } from '@plvs/utils'
import { InfoOutlined } from '@playvs-inc/nexus-icons'

import { NxTooltip, NxTypography } from '@playvs-inc/nexus-components'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { StepState } from './onboardTypes'
import { useUserContent } from '../app'
import { OnboardCard } from './OnboardCard'
import { ReferContinueButton } from './ReferContinueButton'
import { OnboardReferByLinkForm } from './OnboardReferByLinkForm'
import { OnboardReferByEmailForm } from './OnboardReferByEmailForm'
import incentiveImage from './100-dollar-referral.png'
import { generateReferralLinks, getCardProps } from './referUserHelpers'

export type StudentOrFaculty = UserRoleName.Student | UserRoleName.Fac

const facIncentive = {
  image: incentiveImage,
  subtitle: `If a coach signs up with your referral link and competes in the upcoming season, you'll get an Amazon gift card to spend on gear that ups your game.`,
  title: 'Refer a coach',
}

const TOOLTIP_COPY =
  "To comply with children's online privacy laws, players under the age of 13 need their parent to create and manage their account. Use the options below to invite their parents."

interface Props {
  containerless?: boolean
  refer: StudentOrFaculty
  setCompleted?(input: StepState): void
  completed?: StepState
  source: string
}

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
  },
  centered: {
    textAlign: 'center',
  },
  description: {
    color: theme.palette.ColorTextAlt2,
  },
}))

export const ReferUser: React.FC<Props> = ({
  containerless,
  refer,
  setCompleted,
  completed,
  source,
}) => {
  const { isFacultyAtOrg, userRoles, isUnderage } = useUserIdentityFn()
  const isCoachAtOrg = isCoachAtOrganization(
    userRoles,
    userRoles
      ?.filter((role) => role.resourceType === ResourceType.Organization)
      .map((r) => r.resourceId)
  )

  const { loading, data, error } = useMyOnboardReferralQuery()
  const [
    generateReferralLink,
    { loading: referralLoading },
  ] = useGenerateReferralLinkMutation()

  const [links, setLinks] = useState<{
    referralLinkPlayer: string | undefined
    referralLinkParent: string | undefined
  }>({
    referralLinkPlayer: undefined,
    referralLinkParent: undefined,
  })

  const { features } = useUserContent()
  const classes = useStyles()

  const hasFacIncentive =
    (features || []).some(
      ({ slug }) => slug === 'fac-referral-incentives-fall-2020'
    ) && refer === UserRoleName.Fac

  useEffect(() => {
    if (!loading && data?.me?.school) {
      generateReferralLinks({
        schoolId: data.me.school.id,
        competitionGroup: data.me.school?.competitionGroup ?? undefined,
        source,
        setLinks,
        generateReferralLink,
        shouldGenerateParentLink: isFacultyAtOrg || isCoachAtOrg,
      })
    }
  }, [data?.me?.school, isFacultyAtOrg, loading, isCoachAtOrg])

  const props = hasFacIncentive
    ? facIncentive
    : getCardProps(refer, isFacultyAtOrg || isCoachAtOrg)

  const toc = hasFacIncentive ? (
    <Box className={classes.centered} mt={3}>
      <Button
        href="https://playvs.zendesk.com/hc/en-us/articles/360045952033-PlayVS-Fall-2020-Season-Coach-Referral-Incentive-Terms-Conditions"
        target="_blank"
      >
        View Terms & Conditions
      </Button>
    </Box>
  ) : null

  const handleClickCopy = (value: string): void => {
    analytics.copyInviteLink({
      inviteeUserType: userRoleNamesToUserType([refer]),
      inviteLink: value,
      myUserRoles: userRoles,
    })
  }

  if (error) return <div>Error! {error.message}</div>

  return (
    <WaitTillLoaded
      loading={loading || referralLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      {data?.me ? (
        <OnboardCard
          containerless={containerless}
          {...props}
          headerComponent={
            <NxSpot
              domain="emailVerification"
              height="100px"
              size="large"
              variant="primary"
              width="100px"
            />
          }
        >
          <Box my={3}>
            <NxTypography className={classes.text} variant="h4">
              {isUnderage ? 'Invite Players' : 'Invite Players Age 13 and Up'}
            </NxTypography>
            <Box mb={3}>
              <OnboardReferByLinkForm
                link={links.referralLinkPlayer}
                onClickCopy={handleClickCopy}
              />
            </Box>

            <OnboardReferByEmailForm
              myUserRoles={userRoles}
              recipientUserRoleName={UserRoleName.Student}
              schoolId={data?.me?.school?.id}
              source={source}
            />
          </Box>
          {(isFacultyAtOrg || isCoachAtOrg) && (
            <Box>
              <Box alignItems="center" display="flex" mb={1}>
                <NxTypography variant="h4">
                  Players Under Age 13? Invite Their Parents First
                </NxTypography>
                <NxTooltip arrow placement="top" title={TOOLTIP_COPY}>
                  <Box display="flex" ml={0.5}>
                    <InfoOutlined />
                  </Box>
                </NxTooltip>
              </Box>

              <Box mb={3}>
                <OnboardReferByLinkForm
                  link={links.referralLinkParent}
                  onClickCopy={handleClickCopy}
                />
              </Box>
              <OnboardReferByEmailForm
                myUserRoles={userRoles}
                recipientUserRoleName={UserRoleName.Parent}
                schoolId={data?.me?.school?.id}
                source={source}
              />
            </Box>
          )}

          {!containerless && (
            <ReferContinueButton
              completed={completed}
              refer={refer}
              setCompleted={setCompleted}
            />
          )}
          {toc}
        </OnboardCard>
      ) : (
        <Navigate replace to={Path.Dashboard} />
      )}
    </WaitTillLoaded>
  )
}
