import React, { useRef, useState } from 'react'
import { useTheme } from '@material-ui/core'
import { NxIconButton } from '@playvs-inc/nexus-components'
import { acceptableFileTypes } from '@plvs/rally/components/form/attach/attachHelpers'
import { Attach } from '@playvs-inc/nexus-icons'
import { MBToBytesMap } from '@plvs/const'
import { includes } from 'ramda'

const SCREENSHOT_SIZE_LIMIT = '6MB'

interface AttachScreenshotsForGameProps {
  file?: File
  setScreenshotFile(screenshotFile: File): void
}

export const AttachScreenshotsForGame: React.FC<AttachScreenshotsForGameProps> = ({
  file,
  setScreenshotFile,
}) => {
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>()
  const [error, setError] = useState<Error>()

  const onButtonClick = (): void => {
    inputRef?.current?.click()
  }

  const onChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setError(undefined)
    try {
      const file = event?.target?.files?.[0]
      if (file) {
        const { size, type } = file

        if (size > MBToBytesMap[SCREENSHOT_SIZE_LIMIT]) {
          throw new Error(
            `File size must be less than ${SCREENSHOT_SIZE_LIMIT}`
          )
        }

        if (!includes(type, acceptableFileTypes)) {
          throw new Error('File must be jpeg or png.')
        }

        setScreenshotFile(file)
      }
    } catch (err: unknown) {
      setError(err as Error)
    }

    // Resets input[type='file'] to prevent issue where onChange isn't triggered
    // when re-attaching a removed screenshot
    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }
  let label = file ? 'Reattach Screenshot' : 'Attach Screenshot'
  if (error) {
    label = 'Error Attaching Screenshot'
  }

  return (
    <>
      <NxIconButton
        fullWidth
        icon={<Attach color={theme.palette.ColorTextAlt} />}
        label={label}
        onClick={onButtonClick}
        variant="secondary"
      />
      <input
        // @ts-ignore HTMLInputElement is a valid ref type
        ref={inputRef}
        accept={acceptableFileTypes.join(',')}
        data-testid="Attach_Screenshot"
        onChange={onChange}
        style={{ display: 'none' }}
        type="file"
      />
    </>
  )
}
