import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useProductTypeFn } from '@plvs/client-data/hooks'
import { ProductType } from '@plvs/client-data/models'
import { ChildSettingsCard } from './ChildSettingsCard'

type ChildInfoProps = {
  name: string | null | undefined
  dateOfBirth: string | null | undefined
  graduationYear: number | null | undefined
  username: string | null | undefined
  country: string | null | undefined
  state: string | null | undefined
}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    minWidth: '195px',
    marginRight: theme.spacing(3),
  },
}))

export const ChildInfoSettings: React.FC<ChildInfoProps> = ({
  name,
  dateOfBirth,
  graduationYear,
  username,
  country,
  state,
}) => {
  const classes = useStyles()

  const productType = useProductTypeFn()

  const formattedDateOfBirth = useMemo(() => {
    return dateOfBirth ? dayjs(dateOfBirth).format('MMMM D, YYYY') : null
  }, [dateOfBirth])

  const nameCluster = (
    <Box data-testid="name" display="flex" flexDirection="row">
      <NxTypography className={classes.textTitle} variant="body1">
        Name
      </NxTypography>
      <NxTypography variant="body1">{name}</NxTypography>
    </Box>
  )

  const dateOfBirthCluster = (
    <Box data-testid="dob" display="flex" flexDirection="row">
      <NxTypography className={classes.textTitle} variant="body1">
        Date of Birth
      </NxTypography>
      <NxTypography variant="body1">{formattedDateOfBirth}</NxTypography>
    </Box>
  )

  const graduationYearCluster = (
    <Box data-testid="grad-year" display="flex" flexDirection="row">
      <NxTypography className={classes.textTitle} variant="body1">
        Middle School Graduation Year
      </NxTypography>
      <NxTypography variant="body1">{graduationYear}</NxTypography>
    </Box>
  )

  const titleCta = (
    <Link to="edit-info">
      <NxButton
        data-testid="edit-details-button"
        label="Edit"
        variant="secondary"
      />
    </Link>
  )

  const content = [
    { id: 'name', content: nameCluster },
    ...(username
      ? [
          {
            id: 'username',
            content: (
              <Box data-testid="username" display="flex" flexDirection="row">
                <NxTypography className={classes.textTitle} variant="body1">
                  Display Name
                </NxTypography>
                <NxTypography variant="body1">{username}</NxTypography>
              </Box>
            ),
          },
        ]
      : []),
    { id: 'dateOfBirth', content: dateOfBirthCluster },
    ...(country
      ? [
          {
            id: 'country',
            content: (
              <Box data-testid="country" display="flex" flexDirection="row">
                <NxTypography className={classes.textTitle} variant="body1">
                  Country
                </NxTypography>
                <NxTypography variant="body1">{country}</NxTypography>
              </Box>
            ),
          },
        ]
      : []),
    ...(state
      ? [
          {
            id: 'state',
            content: (
              <Box data-testid="state" display="flex" flexDirection="row">
                <NxTypography className={classes.textTitle} variant="body1">
                  State
                </NxTypography>
                <NxTypography variant="body1">{state}</NxTypography>
              </Box>
            ),
          },
        ]
      : []),
    ...(productType === ProductType.Scholastic
      ? [{ id: 'graduationYear', content: graduationYearCluster }]
      : []),
  ]

  return (
    <Box mt={3}>
      <ChildSettingsCard
        customContent={content}
        title="Child Information"
        titleCta={titleCta}
      />
    </Box>
  )
}
