import React from 'react'

import { StepType } from '@plvs/utils'
import { MatchAssistantAdapterStepValue } from '@plvs/rally/features/match/assistant/adapter/matchAssistantAdapterSteps'
import { MatchAssistantActions } from '@plvs/rally/features/match/assistant/MatchAssistantActions'
import { TeamReporting } from '@plvs/rally/features/match/assistant/steps/reporting/TeamReporting'
import { MatchFinalScore } from '@plvs/rally/features/match/dynamic/matchFinalScore/MatchFinalScore'
import { TournamentCode } from '@plvs/rally/features/match/assistant/steps/leagueOfLegends/tournamentCode/TournamentCode'
import { Box } from '@plvs/respawn/features/layout'
import { HeadToHeadGamePreview } from '@plvs/rally/features/match/assistant/steps/general/gamePreview/HeadToHeadGamePreview'
import type { MatchAssistantAdapterProps } from './MatchAssistantAdapter.types'

export const leagueOfLegendsMatchAssistantSteps = ({
  subtitle,
  Actions,
  generalSteps,
  isMatchComplete,
  match,
  setStepIndex,
}: MatchAssistantAdapterProps): Record<
  StepType | string,
  MatchAssistantAdapterStepValue
> => {
  return {
    ...generalSteps,
    [StepType.Report]: [
      {
        step: <TournamentCode onNext={setStepIndex} />,
        title: 'Tournament Code',
        subtitle,
        actions: <MatchAssistantActions />,
      },
      {
        step: <HeadToHeadGamePreview onNext={setStepIndex} />,
        title: 'Game Preview',
        actions: <MatchAssistantActions />,
      },
      {
        step: <TeamReporting />,
        title: 'Scoreboard',
        subtitle: isMatchComplete ? '' : subtitle,
        actions: <MatchAssistantActions />,
      },
    ],
    [StepType.Final]: [
      {
        step: (
          <Box mb={3}>
            <MatchFinalScore match={match} />
          </Box>
        ),
        title: 'Final Score',
        subtitle: '',
        actions: Actions,
      },
    ],
  }
}
