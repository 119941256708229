import { Badge, makeStyles, SwipeableDrawer, useTheme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { NxIconButton } from '@playvs-inc/nexus-components'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { Path } from '@plvs/const'
import clsx from 'clsx'

import React, { ComponentType, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

export enum IconDescription {
  Chat = 'Chat',
  Notifications = 'Notifications',
}

const NO_OVERLAY_PATHS = [
  Path.Settings,
  Path.CreateTeam,
  Path.ConnectionSettings,
  Path.NotificationSettings,
  Path.GlobalRefer,
]

const useStyles = makeStyles((theme) => ({
  iconContainer: ({
    isNotBanneredPage,
    isInAppBar,
  }: {
    isNotBanneredPage: boolean
    isInAppBar?: boolean
  }): CreateCSSProperties => ({
    height: theme.spacing(5),
    width: theme.spacing(5),
    backgroundColor:
      isNotBanneredPage && !isInAppBar
        ? theme.palette.ColorTextBase
        : theme.palette.OverlayColorOpWhite1,
    // Creating circle for button
    borderRadius: theme.shape.borderRadius * 5,
    '&:hover': {
      backgroundColor: isNotBanneredPage
        ? theme.palette.ColorBackgroundInvert
        : theme.palette.OverlayColorOpWhite3,
      opacity: isNotBanneredPage ? 0.9 : 1,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.ColorBackgroundBase,
    },
  }),
  icon: ({ isInAppBar }: { isInAppBar?: boolean }): CreateCSSProperties => ({
    height: isInAppBar ? theme.spacing(3.25) : 'unset',
    width: isInAppBar ? theme.spacing(3.25) : 'unset',
  }),
  anchorTopRight: {
    height: '1.065rem',
    minWidth: '1.13rem',
    transform: 'scale(1) translate(10%, -24%)',
  },
  standardBadge: {
    height: '14px',
    width: '14px',
    minWidth: '14px',
    backgroundColor: theme.palette.OverlayColorBackgroundError,
    color: theme.palette.OverlayColorOpWhite1,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.OverlayColorBackgroundError,
    },
  },
  anchorTopRightCircular: {
    transform: 'scale(1) translate(43%, -27%)',
  },
  badgeDot: {
    backgroundColor: theme.palette.OverlayColorBackgroundError,
    border: 'unset',
  },
}))

interface IconToggleProps {
  childDrawer: ReactNode
  isInAppBar?: boolean
  badgeCount: number
  isRedDotDisplayed: boolean
  Icon: ComponentType<{ className: string; color: string | undefined }>
  onOpen(): void
  onClose(): void
  isOpen: boolean
  iconClassName?: string
  badgeClassName?: string
  iconDescription: string
}

export const IconToggle: React.FC<IconToggleProps> = ({
  childDrawer,
  isInAppBar,
  badgeCount,
  isRedDotDisplayed,
  Icon,
  onOpen,
  onClose,
  isOpen,
  iconClassName,
  badgeClassName,
  iconDescription,
}) => {
  const theme = useTheme()

  const location = useLocation()

  const isNotBanneredPage = NO_OVERLAY_PATHS.some((path) =>
    location.pathname.includes(path)
  )

  const classes = useStyles({ isNotBanneredPage, isInAppBar })

  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  return (
    <>
      <NxIconButton
        className={clsx(classes.iconContainer, 'shepherd-chat-button')}
        color="inherit"
        data-testid="IconToggle__Icon"
        icon={
          <Badge
            badgeContent={badgeCount}
            classes={{
              anchorOriginTopRightRectangular: classes.anchorTopRight,
              anchorOriginTopRightCircular: classes.anchorTopRightCircular,
              badge: clsx(
                isRedDotDisplayed ? classes.badgeDot : classes.standardBadge,
                badgeClassName
              ),
            }}
            data-cy={
              badgeCount > 0
                ? `dotIsDisplayed-${iconDescription}`
                : `dotIsNotDisplayed-${iconDescription}`
            }
            data-testid={
              badgeCount > 0
                ? `dotIsDisplayed-${iconDescription}`
                : `dotIsNotDisplayed-${iconDescription}`
            }
            max={9}
            overlap={isRedDotDisplayed ? 'circular' : 'rectangular'}
            variant={isRedDotDisplayed ? 'dot' : 'standard'}
          >
            <Icon
              className={clsx(classes.icon, iconClassName)}
              color={
                (isNotBanneredPage && !isInAppBar) ||
                (isDarkMode && !isInAppBar)
                  ? theme.palette.ColorTextInvert
                  : theme.palette.ColorTextBase
              }
              data-cy={badgeCount > 0 ? `dotIsDisplayed` : `dotIsNotDisplayed`}
            />
          </Badge>
        }
        onClick={onOpen}
        variant="text"
      />

      <SwipeableDrawer
        anchor="right"
        data-testid="IconToggle__Drawer"
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
      >
        {childDrawer}
      </SwipeableDrawer>
    </>
  )
}
