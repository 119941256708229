import React, { useEffect, useState } from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { Dayjs } from 'dayjs'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FieldValues } from 'react-hook-form'

import { NxTypography } from '@playvs-inc/nexus-components'

import { SessionStorageKey } from '@plvs/const'
import {
  useAuthRenderControllerContext,
  useAuthRenderControllerState,
} from '@plvs/respawn/renderController'
import { PARENT_SIGNUP_RALLY_TEXT } from '@plvs/respawn/features/auth/signup/utils/signup.helpers'
import { LoginLink, PrivacyPolicy } from '../components'
import { SignupEmailForm } from './SignupEmailForm'
import { AgeGate } from './AgeGate'

interface RegistrationFormProps<TMutation> {
  isNasefSignUp?: boolean
  isParentSignup: boolean
  onSubmit(
    input: FieldValues,
    referralToken: string,
    birthday?: string
  ): Promise<TMutation | undefined | null>
  oAuthRedirect: string
  resourceId?: string | null
  roleName?: string
  subtitle?: string
}

export const RegistrationForm = <TInput extends FieldValues, TMutation>({
  isNasefSignUp,
  isParentSignup,
  onSubmit,
  oAuthRedirect,
  resourceId,
  roleName,
  subtitle,
}: RegistrationFormProps<TMutation>): React.ReactElement => {
  const flags = useFlags()

  const { auth } = useAuthRenderControllerContext()
  const {
    setRenderControllerStateFn,
    getRenderControllerState,
  } = useAuthRenderControllerState()
  const authComponents = auth.getAuthComponentsToRender({
    location: window.location.href,
  })
  const {
    shouldRenderAgeGate,
    shouldRenderParentFields,
    shouldRedirectToParentSignup,
    shouldShowPlayerSignup,
    shouldShowFullDemographicForm,
  } = authComponents

  useEffect(
    function updateRenderControllerState() {
      const currentState = getRenderControllerState()
      setRenderControllerStateFn({
        auth: {
          ...currentState,
          ...authComponents,
        },
      })
    },
    [...Object.values(authComponents)]
  )

  const [birthday, setBirthday] = useState<Dayjs | undefined>(undefined)

  useEffect(
    function getComponentsToRender() {
      const components = auth.getAuthComponentsToRender({
        location: window.location.href,
      })
      setRenderControllerStateFn({ auth: components })
    },
    [window.location.href]
  )

  // Clear out any previous registration session data.
  useEffect(() => {
    sessionStorage.removeItem(SessionStorageKey.OnboardingContext)
  }, [])

  const showAgeGate = shouldRenderAgeGate && !birthday

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      {isParentSignup && (
        <Box mx={[2, 0]} my={3} textAlign="center">
          <NxTypography variant="body1">
            {subtitle ?? PARENT_SIGNUP_RALLY_TEXT}
          </NxTypography>
        </Box>
      )}
      {showAgeGate ? (
        <AgeGate
          isParentSignup={isParentSignup}
          setBirthday={setBirthday}
          shouldRedirectToParentSignup={shouldRedirectToParentSignup}
        />
      ) : (
        <SignupEmailForm<TInput, TMutation>
          birthday={birthday}
          isParentSignup={isParentSignup}
          oAuthRedirect={oAuthRedirect}
          onSubmit={onSubmit}
          resourceId={resourceId}
          roleName={roleName}
        />
      )}
      <LoginLink
        isNasefSignUp={Boolean(isNasefSignUp)}
        isParentSignup={isParentSignup}
        shouldRenderParentFields={
          !isParentSignup &&
          shouldRenderParentFields &&
          ((flags.coppaSupport && !shouldShowFullDemographicForm) ||
            (flags.stadiumCoppaSupport && shouldShowFullDemographicForm))
        }
        shouldShowPlayerSignup={shouldShowPlayerSignup}
      />
      <PrivacyPolicy />
    </Box>
  )
}
