import React, { memo } from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import { Tooltip } from '@plvs/rally/components/tooltip'
import { Box, useBreakpointMd } from '@plvs/respawn/features/layout'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { uniq } from 'ramda'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Duplicate } from '@playvs-inc/nexus-icons'
import copy from 'clipboard-copy'
import { useSnackbar } from 'notistack'
import { Coach } from './helper'
import { truncate } from '../explore/LeagueMatches/leagueMatchesHelpers'

const useStyles = makeStyles((theme) => ({
  coach: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  details: {
    color: theme.palette.ColorTextDisabled,
  },
  icon: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
}))

interface Props {
  coach: Coach
  showCoachEmails: boolean
}

export const CoachRow: React.FC<Props> = memo(({ coach, showCoachEmails }) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const coachEmail = coach?.emails?.find((email) => email?.isSchoolEmail)
  const teams = coach.teamsWherePrimaryCoach ?? []

  const isMobile = useBreakpointMd()

  const uniqueTeams = uniq(
    teams.map((team) => {
      const key = `${coach.id}-${team.id}`
      return (
        <NxTypography
          key={key}
          colorToken="OverlayColorTextBase"
          component="span"
          data-testid="SchoolRoster_Coach_Team"
          style={{ display: 'block' }}
          variant="body1"
        >
          {team.name}
        </NxTypography>
      )
    })
  )

  const handleCopy = (): void => {
    copy(coachEmail?.email ?? '')
    enqueueSnackbar('Copied', { variant: 'success' })
  }

  return (
    <Box
      key={coach.id}
      className={classes.coach}
      data-testid="SchoolRoster_Coaches_Coach"
    >
      <Box minHeight={40} minWidth={40}>
        <NxUserAvatar
          avatarUrl={coach?.avatarUrl}
          hashId={coach?.id ?? ''}
          size={NxAvatarSize.SM}
        />
      </Box>
      <Box pl={2}>
        <NxTypography variant={isMobile ? 'subtitle1' : 'body2'}>
          {coach?.name}
        </NxTypography>
        {teams.length >= 1 && (
          <Box
            alignItems="center"
            data-testid="coach-teams"
            display="flex"
            flexDirection="row"
          >
            {teams.length === 1 ? (
              <NxTypography className={classes.details} variant="body3">
                {teams[0].name}
              </NxTypography>
            ) : (
              <Tooltip title={uniqueTeams}>
                <NxTypography className={classes.details} variant="body4">
                  {uniqueTeams.length} teams
                </NxTypography>
              </Tooltip>
            )}
          </Box>
        )}
        {showCoachEmails && (
          <Box alignItems="center" data-testid="coach-email" display="flex">
            <NxTypography variant="body1">
              {truncate(coachEmail?.email ?? '', isMobile ? 28 : 21)}
            </NxTypography>
            <IconButton className={classes.icon} onClick={handleCopy}>
              <Duplicate />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  )
})
