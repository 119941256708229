import React from 'react'
import { useQuery } from '@apollo/client'
import { EmptyPage } from '@plvs/respawn/components/empty'

import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  GetMySchoolDetailsQuery,
  GetMySchoolDetailsDocument,
} from '@plvs/graphql'

import { ITInfoForm } from './ItInfoForm/ITInfoForm'

export const ITInfoFormWrapper = (): React.ReactElement => {
  const { error, loading } = useQuery<GetMySchoolDetailsQuery>(
    GetMySchoolDetailsDocument
  )

  return (
    <WaitTillLoaded
      error={error}
      loading={loading}
      renderErrorState={(_state): React.ReactElement => (
        <EmptyPage subtitle="An error has occurred while loading.  Please try again later." />
      )}
    >
      <ITInfoForm />
    </WaitTillLoaded>
  )
}
