import React from 'react'

import { User } from '@plvs/graphql'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'

export type PlayerClusterProps = {
  player: Pick<User, 'id' | 'avatarUrl' | 'name'> | null
}

export const PlayerCluster: React.FC<PlayerClusterProps> = ({ player }) => {
  return player ? <UserCluster {...player} /> : <>Could not find user</>
}

export type UserClusterProps = Pick<User, 'id' | 'avatarUrl' | 'name'>

export const UserCluster: React.FC<UserClusterProps> = ({
  avatarUrl,
  name,
  id,
}) => {
  const cluster = (
    <Box alignItems="center" display="flex">
      <Box mr={1}>
        <NxUserAvatar
          avatarUrl={avatarUrl}
          hashId={id}
          size={NxAvatarSize.SM}
        />
      </Box>
      <NxTypography variant="body1">{name}</NxTypography>
    </Box>
  )
  return cluster
}
