/* eslint-disable no-labels */
import React, { useEffect, useState } from 'react'
import { uniqBy } from 'ramda'
import { isTruthy } from 'ramda-adjunct'
import dayjs from 'dayjs'
import { ApolloError } from '@apollo/client'
import {
  CompetitionModel,
  ResourceType,
  UserRoleName,
  useGetLeagueMetadataBySchoolIdQuery,
  useGetLeagueMetadataByTeamIdsQuery,
  Maybe,
} from '@plvs/graphql'
import { EsportSlug } from '@plvs/graphql/types'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { isBrowser } from '@plvs/env'
import { LocalStorageKey } from '@plvs/const'
import { removePreseasonPhase } from '@plvs/utils'
import { useOrganizationsContext } from '../../organization/OrganizationsProvider'
import { Provider } from './myLeaguesContext'
import {
  LeagueMetadata,
  MetaseasonMetadata,
  PhaseMetadata,
  SeasonMetadata,
} from './myLeaguesTypes'
import { getDefaultSeason } from './helpers'

type LeagueId = string
type PhaseId = string
type MetaseasonId = string

// Reducer actions
type MyLeaguesActionType =
  | { type: 'dirtyFields' }
  | { type: 'selectOrganization'; organizationId: string | null }
  | { type: 'selectLeague'; leagueId: LeagueId }
  | { type: 'selectPhase'; phaseId: PhaseId }
  | { type: 'selectMetaseason'; metaseasonId: MetaseasonId }
  | {
      type: 'loadedSchoolLeagues'
      schoolLeagues: LeagueMetadata[]
      isCoach: boolean
    }
  | {
      type: 'loadedTeamLeagues'
      teamLeagues: LeagueMetadata[]
    }
  | {
      type: 'phaseInit'
      phaseId: PhaseId
    }

// Picking out the props our reducer keeps track of.
interface MyLeaguesReducerState {
  // Tracking for when fields could potentially need to be recaculated.
  dirtyIncrement: number
  organization: string | undefined

  // provided props
  league?: LeagueMetadata
  leagues: LeagueMetadata[]
  season?: SeasonMetadata
  seasons: SeasonMetadata[]
  metaseason?: Maybe<MetaseasonMetadata>
  metaseasons: MetaseasonMetadata[]
  phase?: PhaseMetadata
  phases: PhaseMetadata[]
  competitionModel?: CompetitionModel

  // leagues
  // Direct to consumer leagues
  teamLeagues: LeagueMetadata[] | null
  // Dev Note: currently below only support school leagues.  When we add support for
  // multi orgs, this will need to be refactored.
  // School Leagues
  schoolLeagues: LeagueMetadata[] | null

  // If user is a verified coach
  isCoach: boolean
}

const INITIAL_STATE: MyLeaguesReducerState = {
  organization: undefined,
  // props provided by this provider
  leagues: [],
  league: undefined,
  season: undefined,
  seasons: [],
  metaseason: undefined,
  metaseasons: [],
  phase: undefined,
  phases: [],

  teamLeagues: null,
  schoolLeagues: null,

  isCoach: false,

  dirtyIncrement: 0,
}

const reducer = (
  state: MyLeaguesReducerState,
  action: MyLeaguesActionType
): MyLeaguesReducerState => {
  switch (action.type) {
    case 'selectOrganization': {
      return {
        ...state,
        organization: action.organizationId ?? undefined,
        dirtyIncrement: state.dirtyIncrement + 1,
      }
    }

    case 'selectLeague': {
      const league = state.leagues.find((x) => x.id === action.leagueId)

      return {
        ...state,
        league: league ?? undefined,
        dirtyIncrement: state.dirtyIncrement + 1,
      }
    }

    case 'selectMetaseason': {
      const metaseason = state.metaseasons.find(
        (m) => m?.id === action.metaseasonId
      )

      return {
        ...state,
        season: undefined,
        metaseason,
        dirtyIncrement: state.dirtyIncrement + 1,
      }
    }

    case 'selectPhase': {
      const phase = state.phases.find((x) => x.id === action.phaseId)

      return {
        ...state,
        phase: phase ?? undefined,
        dirtyIncrement: state.dirtyIncrement + 1,
      }
    }

    case 'loadedSchoolLeagues': {
      // Additionally filter only the leagues that we have a direct
      // or indirect role in the team.
      const mySchoolLeagues = action.schoolLeagues

      return {
        ...state,
        isCoach: action.isCoach,
        schoolLeagues: mySchoolLeagues,
        dirtyIncrement: state.dirtyIncrement + 1,
      }
    }

    case 'loadedTeamLeagues': {
      // Additionally filter only the leagues that we have a direct
      // or indirect role in the team.
      const { teamLeagues } = action

      return {
        ...state,
        teamLeagues,
        dirtyIncrement: state.dirtyIncrement + 1,
      }
    }

    // In the case of dirtyFields, recalculate all provided props in order of:
    // league -> metaseason/season -> phase
    // Dirty fields happens in 2 ways, 1 new data is loaded, or 2, a new selection
    // of a field is made.
    case 'dirtyFields': {
      // choose set of leagues based on school leagues or union of school and team leagues
      let rawLeagues: LeagueMetadata[] = []

      if (state.organization) {
        // Selected organization will see all leagues the school is part of.
        rawLeagues = rawLeagues.concat(state.schoolLeagues ?? [])
      } else {
        // In personal view, users only see teams they have a role in.
        // Such as Owner, Coach, Player
        rawLeagues = rawLeagues.concat(state.teamLeagues ?? [])
      }

      // sort leagues by esport name so they are grouped by esport
      const leagues: LeagueMetadata[] = uniqBy((x) => x.id, rawLeagues)
        .slice()
        .sort((a, b) => {
          const leagueAEsportName = a?.esport?.name || ''
          const leagueBEsportName = b?.esport?.name || ''
          return leagueAEsportName > leagueBEsportName ? 1 : -1
        })

      const leagueHasMatches = leagues.find((l) => {
        const seasons = l?.seasons ?? []
        return seasons.find((s) => {
          const phases = s?.phases ?? []
          return phases.find((p) => {
            return p.hasMatches
          })
        })
      })

      const selectedLeague =
        leagues.find((x) => state.league?.id === x.id) ||
        leagueHasMatches ||
        leagues?.[0]

      const competitionModel = selectedLeague?.competitionModel ?? undefined

      const seasons =
        selectedLeague?.seasons
          ?.slice()
          .sort((a, b) => -dayjs(a.startsAt).diff(dayjs(b.startsAt))) || []
      const metaseasons = seasons.map((s) => s.metaseason)

      const selectedSeason =
        getDefaultSeason({
          league: selectedLeague,
          seasons,
          seasonId: state.season?.id,
          metaseasonId: state.metaseason?.id,
        }) || seasons?.[0]

      const selectedMetaseason = selectedSeason?.metaseason

      const phases = (selectedSeason?.phases ?? [])
        .slice()
        .sort((a, b) => -dayjs(a.startsAt).diff(dayjs(b.startsAt)))
        .filter(removePreseasonPhase)

      // when defaulting to a phase, we prefer ones that have already started.
      const activePhases = phases.filter((p) =>
        dayjs(p.startsAt).isBefore(dayjs())
      )
      const phaseHasMatches = phases?.find((p) => p.hasMatches)

      // Note: because phases are unique to the season.  So we'll attempt to find
      // a matching phase by name instead of ID.
      const selectedPhase =
        phases?.find((x) => x.name === state.phase?.name) ??
        phaseHasMatches ??
        activePhases?.[0] ??
        phases?.[0]

      return {
        ...state,
        dirtyIncrement: 0,
        leagues,
        league: selectedLeague,
        competitionModel,
        metaseason: selectedMetaseason,
        metaseasons,
        seasons,
        season: selectedSeason,
        phases,
        phase: selectedPhase,
      }
    }

    // This works similar to 'selectPhase', but since a phase is inside a
    // season, inside a metaseason, inside a league.  It also sets these
    // params to be compatible with the target init phase.
    case 'phaseInit': {
      const targetPhaseId = action.phaseId

      const allLeagues = [
        ...(state.teamLeagues ?? []),
        ...(state.schoolLeagues ?? []),
      ].filter((x) => !!x)

      let targetLeague: LeagueMetadata | null = null
      let targetSeason: SeasonMetadata | null = null
      let targetPhase: PhaseMetadata | null = null

      allLeagues.some((league) => {
        const seasons = league?.seasons ?? []

        return seasons.some((season) => {
          const phases = season?.phases ?? []
          const selectedPhase = phases.find((phase) => {
            return phase.id === targetPhaseId
          })
          if (selectedPhase) {
            targetPhase = selectedPhase
            targetSeason = season
            targetLeague = league
          }

          return selectedPhase
        })
      })

      if (!targetLeague || !targetSeason || !targetPhase) {
        // The target phase is not found.
        return state
      }

      const targetMetaseason = (targetSeason as SeasonMetadata).metaseason

      return {
        ...state,
        league: targetLeague as LeagueMetadata,
        metaseason: targetMetaseason,
        season: targetSeason as SeasonMetadata,
        phase: targetPhase as PhaseMetadata,
        // Dirty increment is updated here so our 'dirtyFields' handler
        // can update dependent fields that might change due to our
        // selection.
        dirtyIncrement: state.dirtyIncrement + 1,
      }
    }

    default:
      return state
  }
}

const getUserStandingsStorageKey = (userId: string): string =>
  `${LocalStorageKey.StandingsFilters}-${userId}`

export const initializer = (
  initialValue: MyLeaguesReducerState,
  userId: string
): MyLeaguesReducerState => {
  if (isBrowser()) {
    const filters = localStorage.getItem(getUserStandingsStorageKey(userId))
    return filters !== null ? JSON.parse(filters) : initialValue
  }

  return initialValue
}

export type MyLeaguesProviderProps = {
  initialPhaseId?: PhaseId | null
}

/**
 * Provides Context that provides subset of functionality of LeaguesProvider that
 * allows the user to select leagues, metaseason, and phases.
 */
export const MyLeaguesProvider: React.FC<MyLeaguesProviderProps> = ({
  initialPhaseId,
  children,
}) => {
  const { userRoles: roles, userId, teamIds } = useUserIdentityFn()

  // ** State Vars **
  const [state, dispatch] = React.useReducer(
    reducer,
    INITIAL_STATE,
    (initialValue = INITIAL_STATE) => initializer(initialValue, userId)
  )
  const [phaseInitComplete, setPhaseInitComplete] = useState<boolean>(false)

  // aggregates
  const errors: (ApolloError | undefined)[] = []

  // ** Queries **
  // Queries to provide context

  const { organization } = useOrganizationsContext()

  const isOrgCoach = !!roles
    ?.filter((x) => x.resourceType === ResourceType.Organization)
    .filter((x) => x.roleName === UserRoleName.Coach).length

  const {
    data: schoolLeaguesData,
    loading: schoolLeaguesDataLoading,
    error: schoolLeaguesError,
  } = useGetLeagueMetadataBySchoolIdQuery({
    variables: { input: { schoolId: organization?.id ?? '' } },
    skip: !organization?.id,
  })
  errors.push(schoolLeaguesError)

  const {
    data: teamLeaguesData,
    loading: teamLeaguesLoading,
    error: teamLeaguesError,
  } = useGetLeagueMetadataByTeamIdsQuery({
    variables: { input: { teamIds } },
    skip: !teamIds?.length,
  })
  errors.push(teamLeaguesError)

  // ** Computed Props **
  const firstError = errors.filter(isTruthy)?.[0]

  // ** Side Effects **
  // Dev note: When one field affects the other, the dirtyIncrement prop is set on the
  // reducer state which triggers an action to recalculate all derived state fields.

  useEffect(() => {
    if (isBrowser()) {
      localStorage.setItem(
        getUserStandingsStorageKey(userId),
        JSON.stringify(state)
      )
    }
  }, [state])

  // Tracking when a org is selected
  useEffect(() => {
    dispatch({
      type: 'selectOrganization',
      organizationId: organization?.id ?? null,
    })
  }, [organization])

  // If a field becomes dirty, dispatch action to recalculate state.
  useEffect(() => {
    if (state.dirtyIncrement > 0) {
      dispatch({ type: 'dirtyFields' })
    }
  }, [state.dirtyIncrement])

  // tracks league data loading.
  useEffect(() => {
    if (!schoolLeaguesData?.getLeagueMetadataBySchoolId?.payload) {
      return
    }

    const schoolLeagues =
      schoolLeaguesData?.getLeagueMetadataBySchoolId?.payload ?? []

    dispatch({
      type: 'loadedSchoolLeagues',
      schoolLeagues,
      isCoach: isOrgCoach,
    })
  }, [schoolLeaguesData, isOrgCoach])

  useEffect(() => {
    if (!teamLeaguesData?.getLeagueMetadataByTeamIds?.payload) {
      return
    }

    const teamLeagues =
      teamLeaguesData?.getLeagueMetadataByTeamIds?.payload ?? []

    dispatch({
      type: 'loadedTeamLeagues',
      teamLeagues,
    })
  }, [teamLeaguesData])

  // When data is available, trigger our init phase selection init if
  // dependencies are ment.
  useEffect(() => {
    // only run this once so that user can switch between phases later.
    if (!initialPhaseId || phaseInitComplete) {
      return
    }

    const hasData = state.metaseasons.length > 0

    if (!hasData) {
      return // data has not loaded yet.
    }

    dispatch({
      type: 'phaseInit',
      phaseId: initialPhaseId,
    })

    setPhaseInitComplete(true)
  }, [phaseInitComplete, initialPhaseId, state.metaseasons])

  const loading = teamLeaguesLoading || schoolLeaguesDataLoading

  return (
    <Provider
      value={{
        competitionModel: state.competitionModel,
        error: firstError,
        phase: state.phase,
        phases: state.phases,
        league: state.league,
        leagues: state.leagues,
        metaseasons: state.metaseasons,
        metaseason: state.metaseason,
        season: state.season,
        seasons: state.seasons,
        setLeague: (id: string): void =>
          dispatch({ type: 'selectLeague', leagueId: id }),
        setMetaseason: (id: string): void =>
          dispatch({ type: 'selectMetaseason', metaseasonId: id }),
        setPhase: (id: string): void =>
          dispatch({ type: 'selectPhase', phaseId: id }),
        loading,
        esportSlug: state.league?.esport?.slug as EsportSlug,
      }}
    >
      {children}
    </Provider>
  )
}
