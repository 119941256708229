import React, { useState } from 'react'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core'
import RivalFirebobs from '@plvs/rally/assets/icons/firebob-rivals.png'

import {
  NxButton,
  NxTypography,
  NxModal,
  NxTextLink,
  NxCheckbox,
} from '@playvs-inc/nexus-components'
import { ExternalLinks } from '@plvs/const'
import { CheckRequirement } from './CheckRequirement'

const useStyles = makeStyles(() => ({
  checkboxLabel: ({
    hasError,
  }: {
    hasError: boolean
  }): CreateCSSProperties => ({
    cursor: hasError ? 'auto' : 'pointer',
    userSelect: 'none',
  }),
}))

interface Props {
  onJoin: () => void
  onClose: () => void
  open: boolean
  isCoachOrCaptain: boolean
  isConnected: boolean
  isRosterFull: boolean
  isQueueOpen: boolean
}

export const ConfirmationModal: React.FC<Props> = ({
  onJoin,
  onClose,
  open,
  isCoachOrCaptain,
  isConnected,
  isRosterFull,
  isQueueOpen,
}) => {
  const isMobile = useBreakpointXs()
  const [isReady, setIsReady] = useState(false)

  const hasError =
    !isCoachOrCaptain || !isConnected || !isRosterFull || !isQueueOpen
  const disableButton = hasError || !isReady
  const classes = useStyles({ hasError })
  const handleCheck = (): void => {
    if (!hasError) {
      setIsReady(!isReady)
    }
  }

  return (
    <NxModal onClose={onClose} open={open} showTopRightClose size="large">
      <Box alignItems="center" display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width={isMobile ? 'unset' : '432px'}
        >
          <Box alignItems="center" display="flex" flexDirection="column" mb={2}>
            <Box mb={2}>
              <img alt="coach" src={RivalFirebobs} />
            </Box>
            <NxTypography variant="h2">Ready to Play?</NxTypography>
          </Box>
          <Box display="flex" mb={2} textAlign="center">
            <NxTypography variant="body1">
              Teams must be ready to play to join the queue.&nbsp;
            </NxTypography>
            <NxTextLink
              label="Learn More"
              labelVariant="body1"
              onClick={(e: React.SyntheticEvent): void => {
                e.stopPropagation()
                window.open(ExternalLinks.queueFAQ, '_blank', 'noreferrer')
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gridGap={8} mb={2}>
            <CheckRequirement
              errorLabel="Queue is not open."
              isCorrect={isQueueOpen}
              label="Queue is open."
            />
            <CheckRequirement
              errorLabel="Team roster is not filled."
              isCorrect={isRosterFull}
              label="Team roster is filled."
            />
            <CheckRequirement
              errorLabel="You're not a coach or captain of the team."
              isCorrect={isCoachOrCaptain}
              label="You're a coach or captain of the team."
            />
            <CheckRequirement
              errorLabel="Not all players on the roster or bench connected their game accounts."
              isCorrect={isConnected}
              label="All players on the roster and bench connected their game accounts."
            />
          </Box>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            mb={2}
          >
            <NxCheckbox
              checked={isReady}
              disabled={hasError}
              onChange={handleCheck}
            />
            <NxTypography
              className={classes.checkboxLabel}
              colorToken={hasError ? 'ColorTextDisabled' : 'ColorTextBase'}
              onClick={handleCheck}
              variant="body1"
            >
              My team is ready!
            </NxTypography>
          </Box>
          <Box display="flex" width="100%">
            <NxButton
              disabled={disableButton}
              fullWidth
              label="Join Queue"
              onClick={onJoin}
              variant="primary"
            />
          </Box>
        </Box>
      </Box>
    </NxModal>
  )
}
