import { Grid } from '@material-ui/core'
import {
  EsportRating,
  EsportSlug,
  ResourceType,
  useGetMyRolesQuery,
  useGetPhaseResultByTeamIdAndPhaseIdQuery,
  useGetTeamOverviewQuery,
} from '@plvs/graphql'
import { EmptyPage } from '@plvs/respawn/components/empty'
import { useSafeQuery } from '@plvs/utils'
import { HeroGutter, TitleHero } from '@plvs/rally/components/hero'
import { Icons } from '@plvs/rally/components/icon'
import {
  Box,
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { LeagueFilters } from '@plvs/rally/containers/filter/league/LeagueFilters'
import { UserStatsWrapper } from '@plvs/rally/features/stats/user/UserStatsWrapper'
import React from 'react'
import { HeroTabsGutter } from '@plvs/respawn/features/layout/HeroTabsGutter'
import { RosterContainer } from '@plvs/respawn/features/roster/RosterContainer'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { TeamHero } from './TeamHero'
import { MatchHistory } from './MatchHistory'
import { RecentGames } from './RecentGames'
import { useResourceImageProvider } from '../../../../../respawn/src/features/resources/ResourceImageProvider'

export const TeamOverview: React.FC<{
  teamId: string
  path: string
}> = ({ teamId, path }) => {
  const {
    phase,
    esportSlug: slug,
    metaseason,
    loading: leagueInfoLoading,
  } = useSchoolLeagueInfoContext()

  const {
    data: teamOverviewData,
    error: teamOverviewError,
    loading: teamOverviewLoading,
  } = useGetTeamOverviewQuery({
    variables: { teamId },
    skip: !teamId,
  })
  const team = teamOverviewData?.team
  const {
    getResourceImageOfSize,
    updateResources,
    loading: imageLoading,
  } = useResourceImageProvider()
  updateResources({
    resources: [{ id: team?.school?.id, type: ResourceType.Organization }],
  })
  const avatarUrl = getResourceImageOfSize({
    resource: { id: team?.school?.id, type: ResourceType.Organization },
    size: NxAvatarSize.XXL,
  })?.url
  const esportRating = team?.esport?.rating
  const { data: myRolesData } = useGetMyRolesQuery()
  const isAuthenticated = !!myRolesData?.me

  // Certain game types have small teams, in this case we want to order
  // coaches contact 2nd after team roster to keep the layout compact.
  // isGameless type all checks if the game type is battle royale for small teams
  const { isGamelessType: isFortnite } = useGeneralEsportAdapter(slug ?? null)

  const {
    data: rankResults,
    loading: loadingRankResults,
    error: errorRankResults,
  } = useSafeQuery(useGetPhaseResultByTeamIdAndPhaseIdQuery, {
    variables: {
      teamId: team?.id ?? '',
      phaseId: phase?.id ?? '',
    },
    skip: !team?.id || !phase?.id,
  })

  const record = {
    won: rankResults?.phaseResultByTeamIdAndPhaseId?.gamesWon,
    lost: rankResults?.phaseResultByTeamIdAndPhaseId?.gamesLost,
  }

  const isLoading =
    leagueInfoLoading ||
    teamOverviewLoading ||
    loadingRankResults ||
    imageLoading

  if (!team && !isLoading) {
    // This would mean we attempted to load the team but did not find one.
    return (
      <EmptyPage
        icon={Icons.megaphone}
        mb={8}
        mt={8}
        showIcon
        subtitle="Please check your URL"
        title="Content not found"
      />
    )
  }
  if (teamOverviewError) {
    // We may or may not know if there's a team, because the query errored.
    return <PenaltyBox />
  }

  const isPublic = !path.includes('app')
  const schoolName = team?.school?.name ?? ''

  const isRestrictedEsport = team?.esport?.rating === EsportRating.Restricted

  const subtitle = isRestrictedEsport
    ? team?.esport?.name ?? ''
    : `${schoolName} · ${team?.esport.name}`

  // TODO: move this to esports adapter
  const validForTeamPages = [
    EsportSlug.LeagueOfLegends,
    EsportSlug.Fortnite,
    EsportSlug.Smite,
    EsportSlug.RocketLeague,
  ]

  return (
    <Esport slug={slug}>
      <WaitTillLoaded
        error={errorRankResults}
        loading={isLoading}
        LoadingComponent={(): React.ReactElement => {
          return (
            <HeroGutter>
              <MatchImageCard>
                <TitleHero subtitle="Loading..." title="Team Page" />
              </MatchImageCard>
            </HeroGutter>
          )
        }}
        renderErrorState={(): React.ReactElement => {
          return (
            <HeroGutter>
              <MatchImageCard>
                <TitleHero title="Team Page" />
              </MatchImageCard>
            </HeroGutter>
          )
        }}
      >
        {!!teamOverviewData && (
          <>
            <TeamHero
              avatarUrl={avatarUrl}
              esportRating={esportRating}
              esportSlug={slug}
              isPublic={isPublic}
              rank={rankResults?.phaseResultByTeamIdAndPhaseId?.rank}
              record={record}
              subtitle={subtitle}
              teamId={team?.id ?? ''}
              title={team?.name ?? ''}
            />
            <HeroTabsGutter>
              <Box display="flex" justifyContent="flex-end">
                {/* TODO used for now to add phases to each enrolled season */}
                <LeagueFilters
                  showLeagues={false}
                  showPhases
                  showTeams={false}
                />
              </Box>
            </HeroTabsGutter>
            <PageContentGutter>
              <Grid
                alignItems="flex-start"
                container
                direction="row"
                spacing={3}
              >
                {phase && slug && validForTeamPages.includes(slug) && (
                  <Grid
                    data-testid="TeamOverview_RecentGames"
                    item
                    style={{ order: 1 }}
                    xs={12}
                  >
                    <RecentGames phase={phase} teamId={teamId} />
                  </Grid>
                )}

                <Grid
                  data-testid="TeamOverview_Roster"
                  item
                  sm={6}
                  style={{ order: 2 }}
                  xs={12}
                >
                  <RosterContainer
                    metaseason={metaseason}
                    overrideTeamRosterViewPermissions
                    publicView={!isAuthenticated}
                    readOnly
                    showAdditionalContainerActions={false}
                    showCreateScrimmage={false}
                    teamId={teamId}
                  />
                </Grid>

                {!isFortnite && (
                  <Grid
                    data-testid="TeamOverview_MatchHistory"
                    item
                    sm={6}
                    style={{ order: 4 }}
                    xs={12}
                  >
                    <MatchHistory phase={phase} teamId={teamId} />
                  </Grid>
                )}

                {phase &&
                  metaseason &&
                  slug &&
                  validForTeamPages.includes(slug) && (
                    <Grid
                      data-testid="TeamOverview_UserStats"
                      item
                      style={{ order: 5 }}
                      xs={12}
                    >
                      <UserStatsWrapper
                        metaseasonId={metaseason.id}
                        phase={phase}
                        teamId={teamId}
                      />
                    </Grid>
                  )}
              </Grid>
            </PageContentGutter>
          </>
        )}
      </WaitTillLoaded>
    </Esport>
  )
}
