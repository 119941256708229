import { makeStyles } from '@material-ui/core'
import { NxSelect, NxSelectOption } from '@playvs-inc/nexus-components'
import { districtAbbrs, districtClasses } from '@plvs/const'
import React from 'react'

interface DistrictSelectorProps {
  value: string | null
  onChange: React.Dispatch<React.SetStateAction<string | undefined>> | any
  required?: boolean
  fullWidth?: boolean
  label?: string
  callback?: () => void
}

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    color: theme.palette.ColorTextAlt2,
    height: '48px',
    marginBottom: theme.spacing(1.25),
  },
  inputWrapper: {
    '& label': {
      color: theme.palette.ColorTextAlt2,
    },
  },
}))

export const DistrictSelector: React.FC<DistrictSelectorProps> = ({
  label,
  fullWidth = true,
  value,
  onChange,
  callback,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.inputWrapper}>
      <NxSelect
        className={classes.input}
        data-testid="district-select"
        fullWidth={fullWidth}
        label={label ?? 'District Classification'}
        onChange={(e): void => {
          onChange(e.target.value as string)
          if (callback) {
            callback()
          }
        }}
        value={value || ''}
        variant="outlined"
      >
        {Object.entries(districtClasses)
          .filter(([abbr]) => {
            return districtAbbrs.some((district) => district === abbr)
          })
          .map((district) => (
            <NxSelectOption key={district[0]} value={district[0]}>
              {district[1]}
            </NxSelectOption>
          ))}
      </NxSelect>
    </div>
  )
}
