import React, { CSSProperties } from 'react'
import { reduce } from 'ramda'
import { isNumber } from 'ramda-adjunct'
import { makeStyles, useTheme } from '@material-ui/core'
import ReactTable, { Column, TableProps } from 'react-table-v6'
import { NxTypography } from '@playvs-inc/nexus-components'

import { Tooltip } from '@plvs/rally/components/tooltip'
import { appendClasses } from '@plvs/utils'
import { DefaultCell } from './cell'
import { getMinWidth, renderCell } from './helpers'

// CSS
import 'react-table-v6/react-table.css'
import './table.css'

export const DEFAULT_WIDTH = 84

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormatFn = (input: any) => string

export type TableRowProps = {
  row: { _index: number }
}

export const generateTableKey = <T,>(acc: string, obj: T): string => {
  const id = (obj as { id?: string })?.id ?? ''
  return `${acc}-${id}`
}

export interface TableColumn<T> extends Column<T> {
  Cell?(props: T & TableRowProps): React.ReactElement | null
  format?: FormatFn
  Header: React.ReactNode | string | null
  HeaderTooltip?: string
}

interface OurTableProps<T> extends Omit<Partial<TableProps<T>>, 'columns'> {
  columns: TableColumn<T>[]
  hideHeader?: boolean
  affixHeader?: boolean
  defaultEmptyCellValue?: string
  tdStyles?: CSSProperties
  thStyles?: CSSProperties
  pageSize?: number
}

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    border: 'none',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export function Table<T>({
  className,
  columns,
  data = [],
  hideHeader = false,
  affixHeader = false,
  defaultEmptyCellValue = '-',
  getTdProps,
  getTrProps,
  tdStyles = {},
  thStyles = {},
  pageSize = undefined,
  ...rest
}: OurTableProps<T>): React.ReactElement {
  const classes = useStyles()
  const theme = useTheme()

  const tableColumns: Column<T>[] = columns.map(
    ({
      format,
      Header,
      HeaderTooltip,
      maxWidth,
      width,
      minWidth,
      style,
      Cell,
      ...columnRest
    }) => {
      const computedMinWidth = getMinWidth(minWidth, Header)

      return {
        Cell: Cell
          ? renderCell<T & TableRowProps>(Cell)
          : DefaultCell({
              defaultEmptyCellValue,
              format,
              style: style as Record<string, CSSProperties> | undefined,
            }),
        Header: HeaderTooltip ? (
          <Tooltip className={classes.tooltip} title={HeaderTooltip}>
            <NxTypography variant="overline">{Header}</NxTypography>
          </Tooltip>
        ) : (
          <NxTypography variant="overline"> {Header} </NxTypography>
        ),
        style: {
          // sets TD styles
          ...tdStyles,
          background:
            theme.palette.ColorBackgroundBase || theme.palette.background.paper,
          color: theme.palette.ColorTextBase || theme.palette.text.primary,
        },
        headerStyle: {
          // sets TH styles
          display: hideHeader ? 'none' : 'inline-flex',
          background:
            theme.palette.ColorBackgroundBase || theme.palette.background.paper,
          color: theme.palette.ColorTextAlt || theme.palette.text.hint,
          ...thStyles,
        },
        maxWidth:
          maxWidth ||
          width ||
          (isNumber(minWidth) ? undefined : computedMinWidth),
        width,
        // every react-table column must have a minWidth (or width) for sane rendering;
        //  this sets minWidth at a sensible default, based on the Header length.
        minWidth: computedMinWidth,
        ...columnRest,
      }
    }
  )

  const tableClasses = affixHeader
    ? 'rally-table rally-table-affix'
    : 'rally-table'

  const getTrGroupProps = (): {
    style: { border?: number }
  } => ({
    style: {
      border: 0,
    },
  })

  const getThProps = (): {
    style: { borderWidth?: number }
  } => ({
    style: {
      borderWidth: 0,
    },
  })

  const getTableProps = (): { style: { backgroundColor?: string } } => ({
    style: {
      backgroundColor:
        theme.palette.ColorBackgroundBase || theme.palette.background.paper,
    },
  })

  return (
    <ReactTable
      key={reduce<T, string>(generateTableKey, '', data)} // Because ReactTable won't update based on changes in `data`
      className={appendClasses(tableClasses, className, classes.table)}
      columns={tableColumns}
      data={data}
      defaultPageSize={pageSize ?? data.length}
      getTableProps={getTableProps}
      getTdProps={getTdProps}
      getTheadProps={getTrGroupProps}
      getTheadThProps={getThProps}
      getTrGroupProps={getTrGroupProps}
      getTrProps={getTrProps}
      noDataText=""
      showPaginationBottom={false}
      showPaginationTop={!!pageSize}
      {...rest}
    />
  )
}
