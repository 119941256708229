import React, { useState } from 'react'
import { TextField, useTheme } from '@material-ui/core'

import {
  NxButton,
  NxFirebobMessage,
  NxSelectableSingle,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'

import { useSubmitLeaderboardGameResultMutation } from '@plvs/graphql'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { Box } from '@plvs/respawn/features/layout/Box'
import { defaultToEmptyString } from '@plvs/utils'
import { useSnackbar } from 'notistack'
import { useMatchSettleRequestSubscription } from '@plvs/rally/features/match/assistant/hooks/useMatchSettleRequestSubscription'
import { useMatchAssistantContext } from '../../useMatchAssistant'
import { useStyles } from './Reporting.styles'

import { MatchAssistantFooter } from '../../MatchAssistantFooter'

export const SoloReporting: React.FC = () => {
  const theme = useTheme()
  const {
    currentStep,
    match,
    esportSlug,
    teamId: myTeamId,
    teamsById,
    gameOrdinal,
  } = useMatchAssistantContext()
  const myTeam = teamsById[myTeamId]
  const { scoreDisplayName } = useGeneralEsportAdapter(esportSlug)
  const classes = useStyles({
    hasCharacterAsset: false,
  })

  const [score, setScore] = useState<string | null>(null)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const {
    matchSettleRequest,
    loadingMatchSettleRequest,
  } = useMatchSettleRequestSubscription()

  const myGameResults = matchSettleRequest?.results?.seriesResults?.[0]?.gameResults?.find(
    (result) => result.requestedById === myTeamId
  )
  const hasGameResults = Boolean(myGameResults)

  const { enqueueSnackbar } = useSnackbar()

  const [
    submitLeaderboardGameResult,
    { loading: loadingUserMutation },
  ] = useSubmitLeaderboardGameResultMutation()
  const loading = loadingUserMutation

  const isNextDisabled = !score || hasSubmitted
  const isInputDisabled = hasSubmitted || hasGameResults

  const handleSubmit = async (): Promise<void> => {
    try {
      await submitLeaderboardGameResult({
        variables: {
          input: {
            matchId: defaultToEmptyString(match?.id),
            placing: parseInt(defaultToEmptyString(score), 10),
            teamId: defaultToEmptyString(myTeamId),
            gameOrdinal,
          },
        },
      })
      setHasSubmitted(true)
    } catch (err) {
      enqueueSnackbar('Error submitting game result', { variant: 'error' })
    }
  }

  return (
    <Box className={classes.container}>
      <NxTypography variant="h1">
        Enter Your Game {currentStep?.step?.gameOrdinal} {scoreDisplayName}
      </NxTypography>

      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(3)}
        width="100%"
      >
        <NxSelectableSingle
          disabled={isInputDisabled}
          height={`${theme.spacing(11)}px`}
          hideCheckmark
          outerContainerClassName={classes.outerContainer}
          selectableContainerClassName={classes.selectableContainer}
          value={0}
          width="100%"
        >
          {[
            <Box key="my-team" className={classes.selectable}>
              <Box className={classes.playerGradient} />
              <NxUserCluster
                avatarHashId={myTeam?.id ?? ''}
                avatarUrl={myTeam?.avatarUrl ?? undefined}
                className={classes.userCluster}
                compact
                subtitles={[
                  { title: defaultToEmptyString(myTeam?.school?.name) },
                ]}
                title={myTeam?.name ?? '??'}
              />

              <Box display="flex" flex={1} justifyContent="flex-end">
                <TextField
                  className={classes.scoreInput}
                  disabled={isInputDisabled}
                  inputProps={{ min: 0 }}
                  label={scoreDisplayName}
                  name="score"
                  onChange={(evnt): void => {
                    setScore(evnt.target.value)
                  }}
                  type="number"
                  value={score}
                  variant="outlined"
                />
              </Box>
            </Box>,
          ]}
        </NxSelectableSingle>
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage
          message={
            hasSubmitted
              ? 'Waiting on opponents'
              : `Enter your ${scoreDisplayName}`
          }
        />

        <Box
          display="flex"
          gridGap={theme.spacing(2)}
          justifyContent="space-between"
          width="100%"
        >
          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label="Submit"
            loading={loading || loadingMatchSettleRequest}
            onClick={handleSubmit}
            variant="primary"
          />
        </Box>
      </MatchAssistantFooter>
    </Box>
  )
}
