import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import {
  NxAvatar,
  NxIconAvatar,
  NxTooltip,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { Placing } from '@plvs/utils'
import { head } from 'ramda'
import { DesktopMatchFaceoffTeamName } from './DesktopMatchFaceoffTeamName'
import { ScrimmageBadge } from '../../badge'

const useStyles = makeStyles((theme) => ({
  heroTextColor: {
    color: theme.palette.common.white,
  },
  tooltipChild: {
    color: theme.palette.common.white,
    cursor: 'pointer',
  },
}))

export type LeaderboardMatchFaceoffProps = {
  isScrimmage: boolean
  mobile: boolean
  prematch: boolean
  isSmallBreakpoint: boolean
  isPreseason?: boolean
  myPlacing: number | undefined
}

export const LeaderboardMatchFaceoff: React.FC<LeaderboardMatchFaceoffProps> = ({
  isScrimmage,
  mobile,
  prematch,
  isSmallBreakpoint,
  isPreseason = false,
  myPlacing,
}) => {
  const styles = useStyles()
  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()
  const { lobby } = getMatchLobbyRenderControllerState()

  // The `useGetDataForMatchFaceoffSeriesQuery` hook is currently unused for leaderboard match heroes but is utilized for head-to-head match heroes.
  // F1, the only esport with leaderboard-format matches, does not support series.
  // As this hook is designed for series matches, it is not applicable to F1.
  // If other esports are introduced that support leaderboard and series, design changes will be needed to accommodate series placements in leaderboard matches (game dots feature).

  // Ensure there is always a team display details object for the user's team.
  // A race condition might occur while the lobby data is being set, causing the teamDisplayDetails to be undefined.
  // Defaulting here prevents a console error (e.g., "Cannot read property 'id' of undefined").

  const myTeamDisplayDetails = head(lobby.teamDisplayDetails) || {
    id: '',
    avatarUrl: '',
    schoolName: '',
    teamName: '',
  }
  const avatarMeasurements = isSmallBreakpoint ? 56 : 80

  const mobileRender = (
    <>
      <Box display="flex" justifyContent="space-between" py={3}>
        <NxUserCluster
          Avatar={
            <NxAvatar
              avatarHashId={myTeamDisplayDetails.id}
              avatarUrl={myTeamDisplayDetails.avatarUrl}
              hasBorder={false}
              height={avatarMeasurements}
              width={avatarMeasurements}
            />
          }
          avatarHashId={myTeamDisplayDetails.id}
          className={styles.heroTextColor}
          subtitles={[
            {
              title: myTeamDisplayDetails.schoolName,
              className: styles.heroTextColor,
            },
          ]}
          title={myTeamDisplayDetails.teamName}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" py={3}>
        <NxUserCluster
          Avatar={
            <NxIconAvatar
              height={avatarMeasurements}
              label={lobby.numberOfOtherTeamsInMatch ?? ''}
              width={avatarMeasurements}
            />
          }
          avatarHashId="leaderboard"
          className={styles.heroTextColor}
          subtitles={[
            {
              title: myTeamDisplayDetails.schoolName ? 'Multiple Schools' : '',
              className: styles.heroTextColor,
            },
          ]}
          title="Multiple Teams"
        />
      </Box>
    </>
  )

  const desktopRender = (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        pb={4}
      >
        <NxUserCluster
          Avatar={
            <NxAvatar
              avatarHashId={myTeamDisplayDetails.id}
              avatarUrl={myTeamDisplayDetails.avatarUrl}
              hasBorder={false}
              height={avatarMeasurements}
              width={avatarMeasurements}
            />
          }
          avatarHashId={myTeamDisplayDetails.id}
        />
        <Box alignItems="center" display="flex" flexDirection="column">
          {!prematch && myPlacing && (
            <NxTooltip arrow placement="top" title="Your placement">
              <div>
                <NxTypography className={styles.tooltipChild} variant="h2">
                  {Placing(myPlacing)}
                </NxTypography>
              </div>
            </NxTooltip>
          )}
          {isScrimmage && (
            <Box mt={1.5}>
              <ScrimmageBadge isPreseason={isPreseason} size="small" />
            </Box>
          )}
        </Box>
        <NxUserCluster
          Avatar={
            <NxIconAvatar
              height={avatarMeasurements}
              label={lobby.numberOfOtherTeamsInMatch ?? ''}
              width={avatarMeasurements}
            />
          }
          avatarHashId="leaderboard"
        />
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap" pb={1}>
        <Box flex={1} flexDirection="column">
          <DesktopMatchFaceoffTeamName
            schoolName={myTeamDisplayDetails.schoolName}
            schoolSlug={myTeamDisplayDetails.schoolSlug}
            teamId={myTeamDisplayDetails.id}
            teamName={myTeamDisplayDetails.teamName}
          />
        </Box>
        <DesktopMatchFaceoffTeamName
          reverse
          schoolName="Multiple Schools"
          teamName="Multiple Teams"
        />
      </Box>
    </>
  )

  return <>{mobile ? mobileRender : desktopRender}</>
}
