import { GraphQLWsLink } from '@apollo/client/link/subscriptions'

import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { ApolloLink, split } from '@apollo/client'
import { rallyEnv } from '@plvs/rally/env'
import { uploadLink } from './uploadLink'

const HTTPS_HEADER_LENGTH = 'https://'.length
const WS_HEADER = 'wss://'

export const splitLink = (jwt?: string, requestUrl?: string): ApolloLink => {
  const authorization = jwt ? `Bearer ${jwt}` : undefined
  return typeof window !== 'undefined'
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query)
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          )
        },
        new GraphQLWsLink(
          createClient({
            url: requestUrl
              ? `${WS_HEADER}${requestUrl.substring(
                  HTTPS_HEADER_LENGTH
                )}/graphql`
              : rallyEnv.GRAPHQL_WS_URL,

            connectionParams: authorization
              ? {
                  authorization,
                }
              : undefined,
          })
        ),
        uploadLink(jwt, requestUrl)
      )
    : uploadLink(jwt, requestUrl)
}
