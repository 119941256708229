import { makeStyles } from '@material-ui/core'
import { Box, BoxProps } from '@plvs/respawn/features/layout'

import { LoadingSpinner } from '@playvs-inc/nexus-components'
import React from 'react'

interface ErrorProps {
  error: Error
}

interface LoadingSpinnerProps {
  size?: 'small' | 'medium' | 'large'
  color?: 'primary' | 'secondary' | 'error' | 'success'
}

interface WaitTillLoadedProps {
  loading?: boolean
  showSpinnerWhileLoading?: boolean
  error?: Error
  loadingSpinnerProps?: LoadingSpinnerProps
  renderErrorState?: (props: ErrorProps) => React.ReactChild
  LoadingComponent?: React.FC
  loadingContainerProps?: BoxProps
}

const useStyles = makeStyles((theme) => ({
  loadingSpinnerContainer: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
}))

export const WaitTillLoaded: React.FC<WaitTillLoadedProps> = ({
  loading = false,
  showSpinnerWhileLoading,
  children,
  error,
  loadingSpinnerProps,
  renderErrorState,
  LoadingComponent,
  loadingContainerProps,
}) => {
  const { loadingSpinnerContainer } = useStyles()
  const Loading = LoadingComponent ? (
    <LoadingComponent />
  ) : (
    <Box
      className={loadingSpinnerContainer}
      data-cy="spinner"
      data-testid="loading"
      {...loadingContainerProps}
    >
      <LoadingSpinner {...loadingSpinnerProps} />
    </Box>
  )

  if (error)
    return renderErrorState ? <>{renderErrorState({ error })}</> : <></>
  if (loading) return showSpinnerWhileLoading ? <>{Loading}</> : <></>
  return <>{children}</>
}
