import {
  MATCH_PAST_STATUSES,
  ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS,
  getContextualResourceIdsFromTeams,
  hasRoleForResource,
  isAdminForSystem,
  isMemberOfOrganization,
  isTeamOwnerForResource,
} from '@plvs/utils'
import {
  EsportRating,
  MatchFormat,
  MatchStatus,
  UserRoleName,
} from '@plvs/graphql/generated'
import { MenuGroup } from '@playvs-inc/nexus-components'
import { head } from 'ramda'
import {
  coachMenuItemIds,
  genericQueueBannerActionsCopy,
  MatchTeamsDisplayDetailsProps,
  LobbyComponentProps,
  LobbyComponentReturn,
  ManageMatchDropdownMenuItems,
  ManageMatchDropdownMenuItemsReturn,
  MenuItemIdsForManageMatchDropdownProps,
  nonScholasticMenuItemIdsForAllRoles,
  ownerMenuItemIds,
  scholasticMenuItemIdsForAllRoles,
  MatchTeamsDisplayDetails,
  excludedMenuItemIdsLeaderboard,
} from '../types/lobbyRenderController.types'
import { UserRolesForMatchLobbyRenderController } from '../../MatchLobbyRenderController.types'
import { MatchTeamsContext } from '../../match/types/matchRenderController.types'

export const determineTeamIds = ({
  userRoles,
  teamsContext,
}: {
  teamsContext: MatchTeamsContext
  userRoles: UserRolesForMatchLobbyRenderController
}): {
  myTeamIds: Array<string>
  opposingTeamIds: Array<string>
  isMemberOfMoreThanOneTeam: boolean
} => {
  const isAdmin = isAdminForSystem(userRoles ?? [])
  const myTeamIds = teamsContext.myTeams.map((team) => team.id)
  const otherTeamIds = teamsContext.otherTeams.map((team) => team.id)

  if (isAdmin) {
    return {
      myTeamIds: teamsContext.teams.map((team) => team.id),
      opposingTeamIds: [''],
      isMemberOfMoreThanOneTeam: true,
    }
  }

  return {
    myTeamIds,
    opposingTeamIds: otherTeamIds,
    isMemberOfMoreThanOneTeam: teamsContext.myTeams.length > 1,
  }
}

export const determineLobbyComponentsRenderForOrgBasedMatch = ({
  userRoles,
  teamsContext,
  shouldRenderMatchAssistant,
  status,
}: {
  teamsContext: MatchTeamsContext
  userRoles: UserRolesForMatchLobbyRenderController
  shouldRenderMatchAssistant: boolean
  status: MatchStatus | undefined
}): {
  showManageMatchSection: boolean
  showMatchLobbyTour: boolean
  showActionAndInfoSection: boolean
  canManageQueueBannerActions: boolean
  canManageQueueBannerActionsCopy: string
  showRescheduleMatchAlert: boolean
} => {
  const { schoolIds, ids } = getContextualResourceIdsFromTeams(
    teamsContext.teams
  )
  const isMemberOfEitherSchoolInMatch = isMemberOfOrganization(userRoles, [
    ...schoolIds,
  ])

  const userIsCoachForEitherTeam = hasRoleForResource(
    userRoles,
    [...ids, ...schoolIds],
    UserRoleName.Coach
  )

  const isMatchClosed = status && MATCH_PAST_STATUSES.includes(status)

  return {
    showManageMatchSection: userIsCoachForEitherTeam,
    showMatchLobbyTour:
      !isMatchClosed &&
      isMemberOfEitherSchoolInMatch &&
      shouldRenderMatchAssistant,
    showActionAndInfoSection: isMemberOfEitherSchoolInMatch,
    canManageQueueBannerActions: userIsCoachForEitherTeam,
    canManageQueueBannerActionsCopy: userIsCoachForEitherTeam
      ? genericQueueBannerActionsCopy
      : 'After finishing your match or if your opponent forfeits, your coach can now enter your team to play against other teams without worrying about your standings. Ask your coach to find a match!',
    showRescheduleMatchAlert: userIsCoachForEitherTeam,
  }
}

export const determineLobbyComponentsRenderForNonOrgBasedMatch = ({
  userRoles,
  teamsContext,
  shouldRenderMatchAssistant,
  status,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  teamsContext: MatchTeamsContext
  shouldRenderMatchAssistant: boolean
  status: MatchStatus | undefined
}): {
  showManageMatchSection: boolean
  showMatchLobbyTour: boolean
  showActionAndInfoSection: boolean
  canManageQueueBannerActions: boolean
  canManageQueueBannerActionsCopy: string
  showRescheduleMatchAlert: boolean
} => {
  const { ids, schoolIds } = getContextualResourceIdsFromTeams(
    teamsContext.teams
  )
  const isParticipantInMatch = hasRoleForResource(userRoles, [
    ...ids,
    ...schoolIds,
  ])

  const isTeamOwnerOfEitherTeam = isTeamOwnerForResource(userRoles, [...ids])

  const isMatchClosed = status && MATCH_PAST_STATUSES.includes(status)

  return {
    showManageMatchSection: isTeamOwnerOfEitherTeam,
    showMatchLobbyTour:
      !isMatchClosed && isParticipantInMatch && shouldRenderMatchAssistant,
    showActionAndInfoSection: isParticipantInMatch,
    canManageQueueBannerActions: isTeamOwnerOfEitherTeam,
    canManageQueueBannerActionsCopy: isTeamOwnerOfEitherTeam
      ? genericQueueBannerActionsCopy
      : 'After finishing your match or if your opponent forfeits, your team owner can now enter your team to play against other teams without worrying about your standings. Ask your team owner to find a match!',
    showRescheduleMatchAlert: false,
  }
}

export const determineLobbyComponentsRender = ({
  status,
  userRoles,
  teamsContext,
  isMatchAssistantEnabled,
  competitionGroup,
}: LobbyComponentProps): LobbyComponentReturn => {
  if (!teamsContext.teams.length) {
    return {
      showManageMatchSection: false,
      showMatchLobbyTour: false,
      myTeamIds: [],
      opposingTeamIds: [],
      opposingTeamId: '',
      isMemberOfMoreThanOneTeam: false,
      showActionAndInfoSection: false,
      canManageQueueBannerActions: false,
      canManageQueueBannerActionsCopy: '',
      showRescheduleMatchAlert: false,
      enableScoutingToTeamsPage: false,
    }
  }

  const matchIsNotQuarantined = status !== MatchStatus.Quarantined
  const shouldRenderMA = isMatchAssistantEnabled && matchIsNotQuarantined
  const { myTeamIds, opposingTeamIds, isMemberOfMoreThanOneTeam } =
    determineTeamIds({
      teamsContext,
      userRoles,
    })

  if (!competitionGroup || status === MatchStatus.Bye) {
    return {
      showManageMatchSection: false,
      showMatchLobbyTour: false,
      myTeamIds,
      opposingTeamId: head(opposingTeamIds) ?? '',
      opposingTeamIds,
      isMemberOfMoreThanOneTeam,
      showActionAndInfoSection: false,
      canManageQueueBannerActions: false,
      canManageQueueBannerActionsCopy: '',
      showRescheduleMatchAlert: false,
      enableScoutingToTeamsPage: false,
    }
  }

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    const {
      showManageMatchSection,
      showMatchLobbyTour,
      showActionAndInfoSection,
      canManageQueueBannerActions,
      canManageQueueBannerActionsCopy,
      showRescheduleMatchAlert,
    } = determineLobbyComponentsRenderForOrgBasedMatch({
      userRoles,
      teamsContext,
      shouldRenderMatchAssistant: shouldRenderMA,
      status,
    })
    return {
      showManageMatchSection,
      showMatchLobbyTour,
      myTeamIds,
      opposingTeamId: head(opposingTeamIds) ?? '',
      opposingTeamIds,
      isMemberOfMoreThanOneTeam,
      showActionAndInfoSection,
      canManageQueueBannerActions,
      canManageQueueBannerActionsCopy,
      showRescheduleMatchAlert,
      enableScoutingToTeamsPage: true,
    }
  }

  const {
    showManageMatchSection,
    showMatchLobbyTour,
    showActionAndInfoSection,
    canManageQueueBannerActionsCopy,
    canManageQueueBannerActions,
    showRescheduleMatchAlert,
  } = determineLobbyComponentsRenderForNonOrgBasedMatch({
    userRoles,
    teamsContext,
    shouldRenderMatchAssistant: shouldRenderMA,
    status,
  })
  return {
    showManageMatchSection,
    showMatchLobbyTour,
    myTeamIds,
    opposingTeamId: head(opposingTeamIds) ?? '',
    opposingTeamIds,
    isMemberOfMoreThanOneTeam,
    showActionAndInfoSection,
    canManageQueueBannerActions,
    canManageQueueBannerActionsCopy,
    showRescheduleMatchAlert,
    enableScoutingToTeamsPage: false,
  }
}

export const determineHomeAndAwayTeamDisplayDetails = ({
  teams,
  competitionGroup,
  esportRating,
}: MatchTeamsDisplayDetailsProps): MatchTeamsDisplayDetails => {
  if (!competitionGroup) {
    // The property teams comes from the MatchTeamsContext, it will be in order of home and away for head to head.
    return {
      teamDisplayDetails: teams.map((team) => ({
        id: team?.id ?? '',
        teamName: team?.name ?? '',
        avatarUrl: team?.avatarUrl ?? '',
      })),
    }
  }
  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    if (esportRating === EsportRating.Restricted) {
      return {
        teamDisplayDetails: teams.map((team) => ({
          id: team?.id ?? '',
          teamName: team?.name ?? '',
          avatarUrl: '',
          schoolSlug: '',
          schoolName: '',
        })),
      }
    }
    return {
      teamDisplayDetails: teams.map((team) => ({
        id: team?.id ?? '',
        teamName: team?.name ?? '',
        avatarUrl: team?.avatarUrl ?? '',
        schoolSlug: team?.schoolSlug ?? '',
        schoolName: team?.schoolName ?? '',
      })),
    }
  }
  return {
    teamDisplayDetails: teams.map((team) => ({
      id: team?.id ?? '',
      teamName: team?.name ?? '',
      avatarUrl: team?.avatarUrl ?? '',
    })),
  }
}

export const determineHomeAndAwayTeamDisplayDetailsLeaderboard = ({
  teams,
  competitionGroup,
  esportRating,
}: MatchTeamsDisplayDetailsProps): MatchTeamsDisplayDetails => {
  // The first team in the list is designated as "My Team" in Lobby.tsx.
  // While a user can have multiple teams in a leaderboard match, the hero currently defaults to the first team in the array as "My Team" for v1.
  const numberOfOtherTeamsInMatch = teams.length - 1
  const myTeam = head(teams)
  if (!competitionGroup) {
    return {
      teamDisplayDetails: [
        {
          id: myTeam?.id ?? '',
          teamName: myTeam?.name ?? '',
          avatarUrl: myTeam?.avatarUrl ?? '',
        },
      ],
      numberOfOtherTeamsInMatch,
    }
  }
  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    if (esportRating === EsportRating.Restricted) {
      return {
        teamDisplayDetails: [
          {
            id: myTeam?.id ?? '',
            teamName: myTeam?.name ?? '',
            avatarUrl: '',
            schoolSlug: '',
            schoolName: '',
          },
        ],
        numberOfOtherTeamsInMatch,
      }
    }
    return {
      teamDisplayDetails: [
        {
          id: myTeam?.id ?? '',
          teamName: myTeam?.name ?? '',
          avatarUrl: myTeam?.avatarUrl ?? '',
          schoolSlug: myTeam?.schoolSlug ?? '',
          schoolName: myTeam?.schoolName ?? '',
        },
      ],
      numberOfOtherTeamsInMatch,
    }
  }
  return {
    teamDisplayDetails: [
      {
        id: myTeam?.id ?? '',
        teamName: myTeam?.name ?? '',
        avatarUrl: myTeam?.avatarUrl ?? '',
      },
    ],
    numberOfOtherTeamsInMatch,
  }
}

export function getRenderedMenuItemsForManageMatchDropdown(
  menuItems: MenuGroup[],
  menuItemIds: ManageMatchDropdownMenuItems[]
): MenuGroup[] {
  return menuItems
    .map((menuItem) => {
      const filteredItems = menuItem.items.filter((item) =>
        menuItemIds.includes(item.id as ManageMatchDropdownMenuItems)
      )

      return filteredItems.length > 0
        ? { ...menuItem, items: filteredItems }
        : null
    })
    .filter(Boolean) as MenuGroup[]
}

export const filterMenuItemIdsByMatchFormat = ({
  matchFormat,
  menuItemIds,
}: {
  matchFormat: MatchFormat | null | undefined
  menuItemIds: ManageMatchDropdownMenuItems[]
}): ManageMatchDropdownMenuItems[] => {
  switch (matchFormat) {
    case MatchFormat.Leaderboard: {
      return menuItemIds.filter(
        (item) => !excludedMenuItemIdsLeaderboard.includes(item)
      )
    }
    default: {
      return menuItemIds
    }
  }
}

export const determineMenuItemIdsForManageMatchDropdown = ({
  competitionGroup,
  userRoles,
  teamsContext,
  matchFormat,
}: MenuItemIdsForManageMatchDropdownProps): ManageMatchDropdownMenuItemsReturn => {
  const { ids, schoolIds } = getContextualResourceIdsFromTeams(
    teamsContext.myTeams
  )
  if (!competitionGroup) {
    return {
      items: [],
    }
  }

  const isCoachForSomeResource = hasRoleForResource(
    userRoles,
    [...ids, ...schoolIds],
    UserRoleName.Coach
  )

  const isOwnerForSomeResource = hasRoleForResource(
    userRoles,
    [...ids],
    UserRoleName.Owner
  )

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    const initialMenuItemIds = isCoachForSomeResource
      ? [...scholasticMenuItemIdsForAllRoles, ...coachMenuItemIds]
      : scholasticMenuItemIdsForAllRoles
    return {
      items: filterMenuItemIdsByMatchFormat({
        matchFormat,
        menuItemIds: initialMenuItemIds,
      }),
    }
  }

  const initialMenuItemIdsNonScholastic = isOwnerForSomeResource
    ? [...nonScholasticMenuItemIdsForAllRoles, ...ownerMenuItemIds]
    : nonScholasticMenuItemIdsForAllRoles
  return {
    items: filterMenuItemIdsByMatchFormat({
      matchFormat,
      menuItemIds: initialMenuItemIdsNonScholastic,
    }),
  }
}
