import { intersection } from 'ramda'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { UserRoleName } from '@plvs/graphql'
import { LoadingSpinner } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { Path } from '@plvs/const'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { isValidUUID } from '@plvs/utils'
import { ReplaceWithLogin } from './ReplaceWithLogin'

const ALLOWED_APP_REDIRECTS = [
  `${Path.App}/explore/leagues/`,
  `${Path.App}${Path.Profile}`,
  `${Path.Match}`,
]
function getUUIDFromPath(path: string): string | undefined {
  const parts = path.split('/')
  return parts.find((part) => isValidUUID(part))
}

export const AuthorizedContainer = ({
  component,
  allowedRoles = [],
}: {
  component: JSX.Element
  allowedRoles?: UserRoleName[]
}): JSX.Element => {
  const { userId, loading, userRoleNames, userRoles } = useUserIdentityFn()
  const { pathname } = useLocation()
  const contextId = getUUIDFromPath(pathname)
  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexGrow={1}
        justifyContent="center"
      >
        <LoadingSpinner />
      </Box>
    )
  }
  // redirects users to url without '/app/dashboard' in it

  if (
    !userId &&
    ALLOWED_APP_REDIRECTS.some(
      (allowedPath) => pathname.indexOf(allowedPath) > -1
    )
  ) {
    // need to preserve query string params for explore league detail page
    let stripApp = `${pathname.replace('/app', '')}?`

    const params = new URLSearchParams(window.location.search)
    // eslint-disable-next-line no-return-assign
    params.forEach((param, key) => (stripApp += `${key}=${param}&`))
    return <Navigate replace to={stripApp} />
  }
  if (userId) {
    if (
      allowedRoles &&
      (intersection(userRoleNames, allowedRoles).length ||
        !allowedRoles.length) &&
      (!allowedRoles.includes(UserRoleName.Parent) ||
        (allowedRoles.includes(UserRoleName.Parent) &&
          (!contextId ||
            userRoles.map((role) => role.resourceId).includes(contextId))))
    ) {
      return <>{component}</>
    }
    // otherwise, the user does not have the correct roles to access this page
    return <Navigate replace to={Path.App} />
  }

  // if we're not logged in, redirect to the /login page
  return <ReplaceWithLogin />
}
