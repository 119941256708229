import { ProductType } from '@plvs/client-data/models'
import { Provider } from '@plvs/graphql'
import {
  ProvidersComponentProps,
  ProvidersComponentReturn,
} from '../types/providersRenderController.types'

// Temporary comment
export function determineProvidersRender({
  productType,
}: ProvidersComponentProps): ProvidersComponentReturn {
  const defaultProviders = Object.values(Provider).reduce((acc, provider) => {
    acc[provider] = true
    return acc
  }, {})

  const commonExclusions = {
    [Provider.PlayStationNetwork]: false,
    [Provider.XBoxLive]: false,
    [Provider.F1]: false,
  }

  if (productType === ProductType.Stadium) {
    return {
      shouldRender: {
        ...defaultProviders,
        ...commonExclusions,
        [Provider.Spin]: false,
        [Provider.BattleDotNet]: false,
        [Provider.Discord]: false,
      },
    }
  }

  return {
    shouldRender: { ...defaultProviders, ...commonExclusions },
  }
}
