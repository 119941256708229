import React from 'react'

import { StepType } from '@plvs/utils'
import { GamePreview as GamePreviewWithScore } from '@plvs/rally/features/match/assistant/steps/madden/gamePreview'
import { Scoreboard as ScoreboardWithInputedScore } from '@plvs/rally/features/match/assistant/steps/madden/scoreboard/Scoreboard'
import { MatchAssistantAdapterStepValue } from '@plvs/rally/features/match/assistant/adapter/matchAssistantAdapterSteps'
import { MatchAssistantActions } from '@plvs/rally/features/match/assistant/MatchAssistantActions'
import { TeamReporting } from '@plvs/rally/features/match/assistant/steps/reporting/TeamReporting'
import { PlaybookSelect } from '@plvs/rally/features/match/assistant/steps/madden'
import { MatchPreview as MaddenMatchPreview } from '@plvs/rally/features/match/assistant/steps/madden/matchPreview/MatchPreview'
import type { MatchAssistantAdapterProps } from './MatchAssistantAdapter.types'

export const maddenMatchAssistantSteps = ({
  subtitle,
  DisputeResultsButton,
  generalSteps,
  isMatchComplete,
  match,
  setStepIndex,
  gameOrdinal,
}: MatchAssistantAdapterProps): Record<
  StepType | string,
  MatchAssistantAdapterStepValue
> => {
  return {
    ...generalSteps,

    [StepType.CharacterSelect]:
      gameOrdinal > 1
        ? [
            {
              step: <GamePreviewWithScore onNext={setStepIndex} />,
              title: 'Intermission',
              subtitle,
              actions: <MatchAssistantActions />,
            },
            {
              step: <PlaybookSelect />,
              subtitle,
              title: 'Team Picker',
              actions: <MatchAssistantActions />,
            },
          ]
        : [
            {
              step: <PlaybookSelect />,
              subtitle,
              title: 'Team Picker',
              actions: <MatchAssistantActions />,
            },
          ],

    [StepType.Report]: [
      {
        step: <MaddenMatchPreview onNext={setStepIndex} />,
        subtitle,
        title: 'Match Preview',
        actions: <MatchAssistantActions />,
      },
      {
        step: <TeamReporting />,
        title: 'Scoreboard',
        subtitle: isMatchComplete ? '' : subtitle,
        actions: <MatchAssistantActions />,
      },
    ],

    [StepType.Final]: [
      {
        step: (
          <ScoreboardWithInputedScore isFinal={isMatchComplete} match={match} />
        ),
        title: 'Final Score',
        subtitle: '',
        actions: DisputeResultsButton,
      },
    ],
  }
}
