import React, { ReactElement, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import {
  OpenSlotLobbyDetails,
  useGetTeamOverviewQuery,
  useJoinSmartQueueMutation,
  useLeaveSmartQueueMutation,
  useSlotLobbyStatusQuery,
  useCheckPlaceInSmartQueueQuery,
  useGetMatchDetailsQuery,
  MatchStatus,
  SlotLobbyStatus,
  useGetSlotQuery,
  refetchCheckPlaceInSmartQueueQuery,
  SmartQueueStateUpdatedSubscription,
  SmartQueueStateUpdatedSubscriptionVariables,
  SmartQueueStateUpdatedDocument,
  PhaseType,
} from '@plvs/graphql/generated'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { SlotQueueWaitingRow } from '@plvs/rally/features/match/queue/SlotQueueWaitingRow'
import dayjs from 'dayjs'
import { SlotQueueOpponentRow } from '@plvs/rally/features/match/queue/SlotQueueOpponentRow'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'
import { SlotQueueByeRow } from '@plvs/rally/features/match/queue/SlotQueueByeRow'
import {
  continuedQueue,
  leaveQueueButtonClicked,
  pageRedirectFromMatchQueueToMatchLobby,
  scrimmageQueueShown,
} from '@plvs/respawn/features/analytics'
import { Path } from '@plvs/const'

import { useTheme } from '@material-ui/core'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { EsportSlug } from '@plvs/graphql/types'
import { defaultToEmptyString, getContextualTeams } from '@plvs/utils'
import { useStyles } from './slotQueue'
import { QueuePageWrapper } from './QueuePageWrapper'
import { initializePushMessaging } from './slotQueuePushHelper'

const TIMER_DELAYS_IN_MINUTES = {
  [PhaseType.Qualifier]: 5,
}

export const SlotQueuePage: React.FC = () => {
  const theme = useTheme()
  const { teamId, slotId, esportSlug } = useParams<{
    teamId: string
    slotId: string
    esportSlug: string
  }>()
  const mobile = useBreakpointSm()
  const {
    barTextColor,
    gameSubheader,
    pageWrapper,
    leaveQueueButton,
  } = useStyles({ mobile })

  const [hasError, setHasError] = useState<boolean>(false)

  const { userId } = useUserIdentityFn()
  const navigate = useNavigate()

  const {
    data: teamOverviewData,
    loading: teamOverviewLoading,
  } = useGetTeamOverviewQuery({
    variables: { teamId: defaultToEmptyString(teamId) },
    skip: !teamId,
  })

  const myTeam = teamOverviewData?.team

  const { data: slotData } = useGetSlotQuery({
    variables: {
      id: defaultToEmptyString(slotId),
    },
    skip: !slotId,
  })
  const phaseType = slotData?.slot?.phase?.type
  const timerDelayInMinutes =
    (phaseType && TIMER_DELAYS_IN_MINUTES[phaseType]) || 0

  const { data: queueData, refetch } = useSlotLobbyStatusQuery({
    variables: {
      slotId: defaultToEmptyString(slotId),
    },
    skip: !slotId,
  })

  const asOpen = queueData?.slotLobbyStatus.details as OpenSlotLobbyDetails
  const [timeUntilPairing, setTimeUntilPairing] = useState(
    queueData?.slotLobbyStatus.status === SlotLobbyStatus.Open
      ? dayjs(asOpen.nextPairingAt).add(timerDelayInMinutes, 'minutes')
      : dayjs()
  )

  const {
    name: esportName,
    bestOf,
    matchOverviewFormat,
    loading: esportLoading,
    hasSeries,
    seriesBestOf,
  } = useGeneralEsportAdapter(esportSlug as EsportSlug)

  const [joinQueueMutation, { called }] = useJoinSmartQueueMutation()
  const [leaveQueueMutation] = useLeaveSmartQueueMutation()

  const [matchId, setMatchId] = useState('')
  const [leaveDisabled, setLeaveDisabled] = useState(false)

  const { data: matchData } = useGetMatchDetailsQuery({
    variables: { matchId },
    skip: !matchId,
  })

  const lobbyUrl = matchId
    ? generatePath(Path.MatchLobby, {
        matchId,
      })
    : ''

  const { opposingTeam: opponentTeam } = getContextualTeams(
    matchData?.match?.teamContext?.teams
  )

  const didReceiveABye = matchData?.match?.status === MatchStatus.Bye

  const navigateToMatch = async (): Promise<void> => {
    pageRedirectFromMatchQueueToMatchLobby({
      timeStamp: new Date().toISOString(),
      userId,
      teamId: defaultToEmptyString(teamId),
      slotId: defaultToEmptyString(slotId),
      matchId,
    })
    navigate(lobbyUrl)
  }

  const leaveQueueMutate = async (): Promise<void> => {
    leaveQueueButtonClicked({
      timeStamp: new Date().toISOString(),
      userId,
      teamId: defaultToEmptyString(teamId),
      slotId: defaultToEmptyString(slotId),
      matchId,
    })
    await leaveQueueMutation({
      variables: {
        input: {
          slotId: defaultToEmptyString(slotId),
          teamId: defaultToEmptyString(teamId),
        },
      },
    }).then(() => navigate(-1))
  }

  const joinQueue = (): void => {
    joinQueueMutation({
      variables: {
        input: {
          slotId: defaultToEmptyString(slotId),
          teamId: defaultToEmptyString(teamId),
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        refetchCheckPlaceInSmartQueueQuery({
          input: {
            slotId: defaultToEmptyString(slotId),
            teamId: defaultToEmptyString(teamId),
          },
        }),
      ],
    })
  }

  const {
    data: checkPlaceInSmartQueueData,
    subscribeToMore,
    error,
  } = useCheckPlaceInSmartQueueQuery({
    variables: {
      input: {
        slotId: defaultToEmptyString(slotId),
        teamId: defaultToEmptyString(teamId),
      },
    },
    skip: !called || !teamId || !slotId,
  })

  const queueInfo = checkPlaceInSmartQueueData?.checkPlaceInSmartQueue

  const onCountdownComplete = async (): Promise<void> => {
    if (error) {
      setHasError(true)
    }
    continuedQueue({
      timeStamp: new Date().toISOString(),
      userId,
      slotId: defaultToEmptyString(slotId),
      teamId: defaultToEmptyString(teamId),
      matchId,
    })
    const { data: queueRefetchData } = await refetch({
      slotId,
    })
    const asOpenDetails = queueRefetchData?.slotLobbyStatus
      .details as OpenSlotLobbyDetails
    setTimeUntilPairing(
      queueRefetchData?.slotLobbyStatus.status === SlotLobbyStatus.Open
        ? dayjs(asOpenDetails.nextPairingAt).add(timerDelayInMinutes, 'minutes')
        : dayjs()
    )
    setLeaveDisabled(false)
  }

  useEffect(() => {
    joinQueue()
    initializePushMessaging()
  }, [])

  useEffect(() => {
    if (teamId && slotId) {
      subscribeToMore<
        SmartQueueStateUpdatedSubscription,
        SmartQueueStateUpdatedSubscriptionVariables
      >({
        document: SmartQueueStateUpdatedDocument,
        variables: {
          input: {
            teamId,
            slotId,
          },
        },
        updateQuery: (prev, { subscriptionData }) => {
          const newState =
            subscriptionData?.data?.smartQueueStateUpdated?.currentState
          if (!newState) {
            return prev
          }
          return {
            ...prev,
            checkPlaceInSmartQueue: newState,
          }
        },
      })
    }
  }, [slotId, teamId, subscribeToMore])

  useEffect(() => {
    if (queueInfo?.matchId) {
      setMatchId(queueInfo?.matchId)
    }
  }, [queueInfo?.matchId])

  const slotBestOf = hasSeries
    ? slotData?.slot?.seriesBestOf
    : slotData?.slot?.bestOf
  const esportBestOf = hasSeries ? seriesBestOf : bestOf

  const renderTeamRow = (): ReactElement => {
    if (matchId && matchData?.match?.status !== MatchStatus.Bye) {
      const minSeconds = 90
      const maxSeconds = 120
      const secondsUntilRedirect =
        Math.floor(Math.random() * (maxSeconds - minSeconds + 1)) + minSeconds
      const timeUntilRedirect = dayjs().add(secondsUntilRedirect, 'seconds')
      return (
        <SlotQueueOpponentRow
          countdownTime={timeUntilRedirect}
          lobbyUrl={lobbyUrl}
          onCountdownComplete={navigateToMatch}
          opponentTeam={opponentTeam}
          team={myTeam}
        />
      )
    }

    if (didReceiveABye && matchData?.match?.id) {
      scrimmageQueueShown({
        timeStamp: new Date().toISOString(),
        userId,
        slotId: defaultToEmptyString(slotId),
        teamId: defaultToEmptyString(teamId),
        matchId,
      })

      return (
        <SlotQueueByeRow
          esportSlug={esportSlug as EsportSlug}
          matchId={matchData.match.id}
          onCountdownComplete={async (): Promise<void> =>
            setLeaveDisabled(true)
          }
          team={myTeam}
        />
      )
    }

    return (
      <SlotQueueWaitingRow
        countdownTime={timeUntilPairing}
        hasError={hasError}
        onCountdownComplete={onCountdownComplete}
        onTenSecondsLeft={async (): Promise<void> => setLeaveDisabled(true)}
        receivedABye={didReceiveABye}
        team={myTeam}
      />
    )
  }

  return (
    <QueuePageWrapper
      backgroundImageSrc={`https://s3.amazonaws.com/assets.playvs.com/esports/${esportSlug}/Queue.png`}
      esportSlug={esportSlug as EsportSlug}
      loading={teamOverviewLoading || esportLoading}
      pageDataTestId="SlotQueuePage_Page"
      pageTitle="Match Queue"
    >
      <Box
        alignItems="center"
        className={pageWrapper}
        color={theme.palette.OverlayColorTextBase}
        flexDirection="column"
        flexGrow="0"
      >
        <Box display="flex" flexGrow="0" mt={3}>
          <NxTypography color="inherit" variant="display">
            Find a Match
          </NxTypography>
        </Box>

        {mobile ? (
          <></>
        ) : (
          <Box
            className={gameSubheader}
            color={theme.palette.OverlayColorTextBase}
            flexGrow="0"
            mt={2}
          >
            <NxTypography color="inherit" variant="overline">
              best of {slotBestOf ?? esportBestOf}
            </NxTypography>
            <NxTypography className={barTextColor} variant="overline">
              |
            </NxTypography>
            <NxTypography color="inherit" variant="overline">
              {esportName}
            </NxTypography>
            <NxTypography className={barTextColor} variant="overline">
              |
            </NxTypography>
            <NxTypography color="inherit" variant="overline">
              {matchOverviewFormat}
            </NxTypography>
          </Box>
        )}

        {renderTeamRow()}

        {!didReceiveABye && (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            flexGrow="0"
            gridGap={theme.spacing(1)}
          >
            <NxButton
              className={
                !matchId && !leaveDisabled ? undefined : leaveQueueButton
              }
              data-cy="leaveQueueButton"
              data-testid="SlotQueuePage_LeaveButton"
              label="Leave Queue"
              onClick={(): Promise<void> => leaveQueueMutate()}
              shouldUseOverlayColors
              variant="secondary"
            />
            <NxTypography colorToken="OverlayColorTextBase" variant="body1">
              Please stay on this page to remain in the queue. If you&apos;ve
              been in queue for longer than 10 minutes without an update, please
              go back & check your schedule page. We won&apos;t take you out of
              queue unless you click &quot;Leave Queue&quot;.
            </NxTypography>
          </Box>
        )}
      </Box>
    </QueuePageWrapper>
  )
}
