import React, { useState } from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { useSwipeable } from 'react-swipeable'
import { MatchContextProvider } from './useMatchHighlightContext'
import { NxBracketRoundCluster } from './BracketRoundCluster'

export function getRoundName(
  roundNumber: number,
  totalNumRounds: number,
  isDoubleElimination?: boolean,
  isUpper?: boolean
): string {
  let name = ''
  if (isDoubleElimination) {
    name = isUpper ? 'Upper ' : 'Lower '
  }

  if (roundNumber === totalNumRounds) {
    return `${name}Finals`
  }
  if (roundNumber + 1 === totalNumRounds) {
    return `${name}Semi Finals`
  }
  return `${name}Round ${roundNumber}`
}

export interface BracketTeam {
  id: string
  schoolId: string | null
  teamId: string | null
  seed: number | null
  status: string | null
  displayName: string | null
  picture: JSX.Element | null
  schoolName?: string | null
  teamLink: string | null
  schoolLink: string | null
  matchLink: string | null
  score: number | null
  isDeleted: boolean
  isWinner: boolean
  isLoser: boolean
}

export interface BracketMatch {
  id: string
  matchId: string
  name: string
  nextMatchId: string | null
  roundIndex: number
  startTime: string
  participants: BracketTeam[]
  isUpper: boolean
}

export interface BracketProps {
  rounds: BracketMatch[][]
  overlayColor: string
}

export function NxBracket({
  rounds,
  overlayColor,
}: BracketProps): React.ReactElement {
  const [startRound, setStartRound] = useState<number>(0)
  const isMobile = useBreakpointSm()
  const numOfRoundsToShow = isMobile ? 1 : 2
  const totalRounds = rounds.length
  const numRounds = rounds.length
  const roundsToRender = rounds.slice(
    startRound,
    startRound + numOfRoundsToShow
  )
  const firstCol = roundsToRender[0]
  const secondCol = roundsToRender[1] ? roundsToRender[1] : null

  const disableNext = isMobile
    ? startRound === numRounds - 1
    : startRound === numRounds - 2
  const disablePrev = startRound === 0

  const handleNext = (): void => {
    if (!disableNext) {
      setStartRound(startRound + 1)
    }
  }

  const handlePrev = (): void => {
    if (!disablePrev) {
      setStartRound(startRound - 1)
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  })

  return (
    <MatchContextProvider>
      <div {...handlers}>
        <Box display="flex" pt={4} px={2}>
          <NxBracketRoundCluster
            disableNext={disableNext}
            disablePrev={disablePrev}
            firstColumnOfMatches={firstCol}
            handleNext={handleNext}
            handlePrev={handlePrev}
            overlayColor={overlayColor}
            secondColumnOfMatches={secondCol}
            startRoundIndex={startRound}
            totalRounds={totalRounds}
          />
        </Box>
      </div>
    </MatchContextProvider>
  )
}
