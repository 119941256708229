import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { isNil } from 'ramda'
import dayjs from '@plvs/respawn/init/dayjs'
import { PlayVsScoreHeader } from '../header/PlayVsScoreHeader'
import { FALL_2022_DATE } from '../../../features/standings/standingsHelpers'
import { PlayVsAvgPlacementHeader } from '../header/AveragePlacementHeader'

export const MatchRecord = {
  accessor: 'matchesWon',
  Header: (): React.ReactElement => (
    <>
      <NxTypography variant="subtitle1">Match Record</NxTypography>
      <NxTypography variant="overline">WIN-LOSS</NxTypography>
    </>
  ),
  Cell: ({
    row,
  }: {
    row: {
      original: {
        matchesWon?: number
        matchesLost?: number
      }
    }
  }): React.ReactElement => {
    const matchesWon = row.original?.matchesWon
    const matchesLost = row.original?.matchesLost

    return (
      <NxTypography>
        {matchesWon} - {matchesLost}
      </NxTypography>
    )
  },
}

export const SeriesRecord = {
  accessor: 'seriesWon',
  Header: (): React.ReactElement => (
    <>
      <NxTypography variant="subtitle1">Series Record</NxTypography>
      <NxTypography variant="overline">WIN-LOSS</NxTypography>
    </>
  ),
  Cell: ({
    row,
  }: {
    row: {
      original: {
        seriesWon?: number
        seriesLost?: number
      }
    }
  }): React.ReactElement => {
    const seriesWon = row.original?.seriesWon
    const seriesLost = row.original?.seriesLost

    return (
      <NxTypography>
        {seriesWon} - {seriesLost}
      </NxTypography>
    )
  },
}

export const calculatePlayvsScore = ({
  cumulativeScore,
  cumulativeScoreOfOpposition,
}: {
  cumulativeScore: number | null
  cumulativeScoreOfOpposition: number | null
}): string => {
  if (
    isNil(cumulativeScore) ||
    (cumulativeScore === 0 && cumulativeScoreOfOpposition === 0)
  ) {
    return '-'
  }

  if (
    cumulativeScore === 0 &&
    cumulativeScoreOfOpposition &&
    cumulativeScoreOfOpposition > 0
  ) {
    return `${cumulativeScoreOfOpposition}`
  }

  const isCumulativeScoreDecimal = cumulativeScore % 1 !== 0
  if (isCumulativeScoreDecimal) {
    // 19.5 -> .5
    const cumulativeScoreDecimals =
      cumulativeScore - Math.floor(cumulativeScore)

    const numberAddedToOppositionScore = cumulativeScoreDecimals * 1000

    const newOppositionScore =
      (cumulativeScoreOfOpposition || 0) + numberAddedToOppositionScore

    const cumulativeScoreTruncated = Math.trunc(cumulativeScore)

    return `${cumulativeScoreTruncated}${newOppositionScore}`
  }

  let oppositionScore = isNil(cumulativeScoreOfOpposition)
    ? '000'
    : cumulativeScoreOfOpposition.toString()

  const oppositionScoreSize = oppositionScore.length
  if (oppositionScoreSize < 3) {
    const numberOfZeroes = 3 - oppositionScoreSize

    Array.from(Array(numberOfZeroes)).forEach((_) => {
      oppositionScore = `0${oppositionScore}`
    })
  }

  return `${cumulativeScore}${oppositionScore}`
}

export const PlayVsScore = {
  accessor: 'rankingPoints',
  Header: (): React.ReactElement => <PlayVsScoreHeader />,
  Cell: ({
    row,
  }: {
    row: {
      original: {
        cumulativeScore: number | null
        cumulativeScoreOfOpposition: number | null
        startsAt: string
      }
    }
  }): React.ReactElement => {
    // NOTE: before fall 2022, should show "-". COMP-1664
    if (dayjs(row.original.startsAt).isBefore(dayjs(FALL_2022_DATE))) {
      return <NxTypography>-</NxTypography>
    }
    const cumulativeScore = row.original?.cumulativeScore
    const cumulativeScoreOfOpposition =
      row.original?.cumulativeScoreOfOpposition

    const playvsScore = calculatePlayvsScore({
      cumulativeScore,
      cumulativeScoreOfOpposition,
    })

    return <NxTypography>{playvsScore}</NxTypography>
  },
}

export const AvgPlacement = {
  accessor: 'avgPlacement',
  Header: (): React.ReactElement => <PlayVsAvgPlacementHeader />,
  Cell: ({
    row,
  }: {
    row: {
      original: {
        avgPlacement?: number
      }
    }
  }): React.ReactElement => {
    const averagePlacement = row.original?.avgPlacement

    return <NxTypography>{averagePlacement}</NxTypography>
  },
}

export const FirstFinishes = {
  accessor: 'numOfFirstPlace',
  Header: (): React.ReactElement => (
    <NxTypography variant="subtitle1">1st Finishes</NxTypography>
  ),
  Cell: ({
    row,
  }: {
    row: {
      original: {
        numOfFirstPlace?: number
      }
    }
  }): React.ReactElement => {
    const numOfFirstPlace = row.original?.numOfFirstPlace

    return <NxTypography>{numOfFirstPlace ?? '-'}</NxTypography>
  },
  maxWidth: 100,
}

export const TopFourFinishes = {
  accessor: 'numOfTopFourPlace',
  Header: (): React.ReactElement => (
    <NxTypography variant="subtitle1">Top 4 Finishes</NxTypography>
  ),
  Cell: ({
    row,
  }: {
    row: {
      original: {
        numOfTopFourPlace?: number
      }
    }
  }): React.ReactElement => {
    const numOfTopFourPlace = row.original?.numOfTopFourPlace

    return <NxTypography>{numOfTopFourPlace ?? '-'}</NxTypography>
  },
  maxWidth: 100,
}
