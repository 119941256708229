import React from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'
import clsx from 'clsx'

import { NxMiscAssets } from '@playvs-inc/nexus-misc-assets'
import { NxTypography } from '@playvs-inc/nexus-components'

// eslint-disable-next-line no-restricted-imports
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'

import { EsportSlug } from '@plvs/graphql'
import { PlayerSelection } from '@plvs/utils'
import { useStyles } from './SelectedCharacters.styles'

interface SelectedCharactersSectionProps {
  player?: PlayerSelection
  hideBlindPick?: boolean
  esportSlug: EsportSlug | undefined
  showOverlayImage?: boolean
  isHomeTeam?: boolean
}

export const SelectedCharactersSection: React.FC<SelectedCharactersSectionProps> = ({
  player,
  hideBlindPick = false,
  esportSlug = EsportSlug.SmashBrosUltimate,
  showOverlayImage,
  isHomeTeam = true,
}) => {
  const styles = useStyles({ esportSlug, isHomeTeam })
  const theme = useTheme()
  const isMobile = useBreakpointSm()
  const avatarSize = isMobile ? NxAvatarSize.SM : NxAvatarSize.MD

  const hasDetails = player && Boolean(Object.values(player).join(''))
  const playerCharacter = player?.selection
  const hasCharacterAsset = Boolean(playerCharacter?.assetUri)

  return !hasDetails && hideBlindPick ? null : (
    <Box
      className={clsx(styles.characterContainer, styles.gradient)}
      flex={1}
      gridGap={theme.spacing(2)}
    >
      {showOverlayImage && !isMobile && (
        <Box
          className={styles.container}
          data-testid="overlay-img"
          height="100%"
          width="100%"
        />
      )}

      <NxTypography colorToken="OverlayColorTextBase" variant="overline">
        {isHomeTeam ? 'P1' : 'P2'}
      </NxTypography>

      <Box
        alignItems="center"
        display="flex"
        flex={1}
        flexDirection="column"
        gridGap={hasCharacterAsset ? 0 : theme.spacing(2)}
        justifyContent="center"
        textAlign="center"
      >
        {hasDetails ? (
          <>
            {player?.useAvatar ? (
              <Box data-testid="avatar">
                <NxUserAvatar
                  avatarUrl={player?.avatarUrl}
                  data-testid="avatar"
                  hashId={player?.id ?? ''}
                  ringColor="white"
                  showRing
                  size={avatarSize}
                />
              </Box>
            ) : (
              <Box
                mb={hasCharacterAsset ? -2 : 0}
                mt={hasCharacterAsset ? -5 : 0}
              >
                <img
                  alt={playerCharacter?.displayName ?? player?.name}
                  // Assets include padding
                  height={hasCharacterAsset ? 143 : 72}
                  src={playerCharacter?.assetUri ?? player?.avatarUrl}
                />
              </Box>
            )}

            <NxTypography colorToken="OverlayColorTextBase" variant="h4">
              {playerCharacter?.displayName ?? player?.name}
            </NxTypography>
          </>
        ) : (
          <NxMiscAssets.BlindPick
            data-testid="blind-pick"
            height={70}
            width={70}
          />
        )}
      </Box>
    </Box>
  )
}
