import { Notifications } from '@playvs-inc/nexus-icons'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  refetchGetNotificationCenterQuery,
  useGetNotificationCenterSummaryQuery,
  useGetUserNotificationSummarySubscription,
} from '@plvs/graphql/generated'
import * as analytics from '@plvs/respawn/features/analytics'
import React, { useEffect } from 'react'
import { IconToggle, IconDescription } from '../IconToggle'
import { useAnimationStyles } from './animationStyles'

type NotificationDrawerComponent = React.FC<{
  close?: () => void
  uncompletedTaskCount?: number | null
}>

interface AppDrawerNotificationsToggleProps {
  NotificationDrawer: NotificationDrawerComponent
  isInAppBar?: boolean
}

export const AppDrawerNotificationsToggleV2: React.FC<AppDrawerNotificationsToggleProps> = ({
  NotificationDrawer,
  isInAppBar,
}) => {
  const { data, loading } = useGetNotificationCenterSummaryQuery({
    variables: {
      skipTasks: false,
    },
  })
  const { userId } = useUserIdentityFn()

  const userNotificationSummaryResponse = useGetUserNotificationSummarySubscription(
    {
      variables: {
        userId,
      },
      skip: !userId,
    }
  )

  const notificationSummaryData = userNotificationSummaryResponse.data

  const [unreadCount, setUnreadCount] = React.useState<
    number | null | undefined
  >(data?.userNotifications?.unreadCount ?? 0)
  const [uncompletedTaskCount, setUncompletedTaskCount] = React.useState<
    number | null | undefined
  >(data?.getIncompleteNotificationTaskCount ?? 0)

  const classes = useAnimationStyles()
  const [isOpen, setIsOpen] = React.useState(false)

  const open = (): void => {
    // Clear pending badge for notifications by calling mutation to set updated at
    analytics.userNotificationsDrawerOpened({
      userId: userId ?? '',
      timeStamp: new Date().toDateString(),
      notificationsCleared: unreadCount ?? 0,
    })
    setIsOpen(!isOpen)
  }

  const close = (): void => {
    analytics.userNotificationsDrawerClosed({
      userId: userId ?? '',
      timeStamp: new Date().toDateString(),
    })
    setIsOpen(false)
  }

  useEffect(() => {
    const unreadNotificationCount =
      notificationSummaryData?.userNotificationSummary?.unreadNotificationCount
    const notificationTaskCount =
      notificationSummaryData?.userNotificationSummary?.notificationTasksCount

    if (unreadNotificationCount) {
      setUnreadCount(unreadNotificationCount)
    }

    if (notificationTaskCount) {
      setUncompletedTaskCount(
        notificationSummaryData?.userNotificationSummary?.notificationTasksCount
      )
    }

    if (!loading) {
      if (!unreadNotificationCount) {
        setUnreadCount(data?.userNotifications?.unreadCount ?? 0)
      }
      if (!notificationTaskCount) {
        setUncompletedTaskCount(data?.getIncompleteNotificationTaskCount ?? 0)
      }
    }

    refetchGetNotificationCenterQuery({
      // The +1 is there so we can get the cursor of previous
      // result so the automatic merge() function will correctly
      // merge in any past results.
      first:
        (data?.userNotifications?.unreadCount ?? unreadNotificationCount ?? 0) +
        1,
    })
  }, [userNotificationSummaryResponse, loading, isOpen])

  let badgeCount = 0
  let isDotDisplayed = false

  if (unreadCount && unreadCount > 0) {
    badgeCount = unreadCount
  } else if (uncompletedTaskCount && uncompletedTaskCount > 0) {
    // Need to still update number or dot will not show.
    badgeCount = uncompletedTaskCount
    isDotDisplayed = true
  }

  const hasBadgeCount = badgeCount > 0

  return (
    <IconToggle
      badgeClassName={hasBadgeCount ? classes.badgePopup : undefined}
      badgeCount={badgeCount}
      childDrawer={
        <NotificationDrawer
          close={close}
          uncompletedTaskCount={uncompletedTaskCount}
        />
      }
      Icon={Notifications}
      iconClassName={hasBadgeCount ? classes.rotateIcon : undefined}
      iconDescription={IconDescription.Notifications}
      isInAppBar={isInAppBar}
      isOpen={isOpen}
      isRedDotDisplayed={isDotDisplayed}
      onClose={close}
      onOpen={open}
    />
  )
}
