import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { EsportRating, MatchFormat } from '@plvs/graphql/generated'
import { includes } from 'ramda'
import { MatchRow } from './MatchRow/MatchRow'
import { MappedMatch } from '../../match/schedule/schedule'
import { INVALID_RESULTS_MATCH_STATUSES } from './leagueMatchesHelpers'
import { getIsPastMatch } from './MatchRow/matchRow.helpers'
import { LeagueMatchTeamCluster } from './LeagueMatchTeamCluster'
import { MatchRowMiddle } from './MatchRow/MatchRowMiddle'
import { MatchRowMobile } from './MatchRow/MatchRowMobile'
import { TeamsVsRow } from './MatchRow/mobile/TeamsVsRow'
import { AvatarsRow } from './MatchRow/mobile/AvatarsRow'
import { MultiTeamCluster } from './MatchRow/MultiTeamCluster'

export const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  match: MappedMatch
}

export const LeagueMatchRow: React.FC<Props> = ({ match }) => {
  const isMobile = useBreakpointXs()

  const classes = useStyles()

  const isLeaderboard = match.matchFormat === MatchFormat.Leaderboard

  const isRestricted = match?.esport?.rating === EsportRating.Restricted

  const hasValidResults = !includes(
    match?.status,
    INVALID_RESULTS_MATCH_STATUSES
  )

  const isPastMatch = getIsPastMatch(match)

  const matchResults = match?.matchResults ?? []

  const myMatchResults = matchResults?.find(
    ({ team }) => team?.id === match?.teamContext?.teams?.[0]?.id
  )
  const oppMatchResults = matchResults?.find(
    ({ team }) => team?.id === match?.teamContext?.teams?.[1]?.id
  )

  const myTeamLost =
    hasValidResults && isPastMatch && myMatchResults?.placing === 2
  const oppTeamLost =
    hasValidResults && isPastMatch && oppMatchResults?.placing === 2

  if (isMobile) {
    return (
      <MatchRowMobile
        actions={undefined}
        className={classes.row}
        isQueueMatch={false}
        match={match}
        showEsportAccent={false}
        team1Id={match?.teamContext?.teams?.[0]?.id}
      >
        {isLeaderboard ? (
          <Box alignItems="center" display="flex">
            <MultiTeamCluster
              avatarPaddingTop={8}
              subtitle="Multiple Schools"
              teams={match?.teamContext?.teams ?? []}
            />
          </Box>
        ) : (
          <>
            <TeamsVsRow
              isLeaderboard={isLeaderboard}
              myTeam={match?.teamContext?.teams[0]}
              oppTeam={match?.teamContext?.teams[1]}
              subtitle="Multiple Schools"
            />
            <AvatarsRow
              match={match}
              team1={match?.teamContext?.teams[0]}
              team2={match?.teamContext?.teams[1]}
            />
          </>
        )}
      </MatchRowMobile>
    )
  }

  return (
    <MatchRow className={classes.row} match={match} showFullDate>
      {isLeaderboard ? (
        <Grid data-testid="league-match-leaderboard" item xs={8}>
          <Box display="flex" justifyContent="center">
            <MultiTeamCluster
              subtitle="Open Match for Details"
              teams={match?.teamContext?.teams ?? []}
            />
          </Box>
        </Grid>
      ) : (
        <>
          <Grid item xs={3}>
            <LeagueMatchTeamCluster
              didTeamLose={myTeamLost}
              isLeftSide
              isRestrictedEsport={isRestricted}
              matchStatus={match?.status}
              team={match?.teamContext?.teams[0]}
            />
          </Grid>
          <Grid item xs={2}>
            <MatchRowMiddle
              isLeaderboard={isLeaderboard}
              match={match}
              myMatchResults={myMatchResults}
              oppMatchResults={oppMatchResults}
            />
          </Grid>
          <Grid item xs={3}>
            <LeagueMatchTeamCluster
              didTeamLose={oppTeamLost}
              isLeftSide={false}
              isRestrictedEsport={isRestricted}
              matchStatus={match?.status}
              team={match?.teamContext?.teams[1]}
            />
          </Grid>
        </>
      )}
    </MatchRow>
  )
}
