import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { BracketMatch } from './Bracket'
import { BRACKET_MATCH_HEIGHT } from './bracketHelpers'
import { NxBracketMatch } from './BracketMatch'

export type BracketMatchCluster = {
  leftTopMatch: BracketMatch
  leftBottomMatch: BracketMatch
  rightTopMatch?: BracketMatch | null
  rightBottomMatch?: BracketMatch | null
}

export interface BracketMatchClusterProps {
  matchCluster: BracketMatchCluster
  overlayColor: string
  roundIndex: number
}

export function NxBracketMatchCluster({
  matchCluster,
  overlayColor,
  roundIndex,
}: BracketMatchClusterProps): React.ReactElement {
  const hideVertLine =
    matchCluster.leftBottomMatch?.nextMatchId !== matchCluster.rightTopMatch?.id
  const rightColumnHeight =
    matchCluster.leftBottomMatch &&
    !matchCluster.rightBottomMatch &&
    !hideVertLine
      ? BRACKET_MATCH_HEIGHT / 2
      : 0
  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Box>
        <NxBracketMatch
          bottomParty={matchCluster.leftTopMatch.participants[1]}
          esportColor={overlayColor}
          hideVertLine={hideVertLine}
          match={matchCluster.leftTopMatch}
          matchIndex={0}
          numMatchesInNextRound={
            matchCluster.leftBottomMatch && !matchCluster.rightBottomMatch
              ? 1
              : 0
          }
          numMatchesInRound={2}
          roundIndex={roundIndex}
          topParty={matchCluster.leftTopMatch.participants[0]}
        />
        {matchCluster.leftBottomMatch && (
          <NxBracketMatch
            bottomParty={matchCluster.leftBottomMatch.participants[1]}
            esportColor={overlayColor}
            match={matchCluster.leftBottomMatch}
            matchIndex={1}
            numMatchesInNextRound={matchCluster.rightBottomMatch ? 0 : 1}
            numMatchesInRound={2}
            roundIndex={roundIndex}
            topParty={matchCluster.leftBottomMatch.participants[0]}
          />
        )}
      </Box>
      {matchCluster.rightTopMatch && (
        <Box marginTop={`${rightColumnHeight}px`}>
          <NxBracketMatch
            bottomParty={matchCluster.rightTopMatch.participants[1]}
            esportColor={overlayColor}
            match={matchCluster.rightTopMatch}
            matchIndex={0}
            numMatchesInNextRound={0}
            numMatchesInRound={matchCluster.rightBottomMatch ? 2 : 1}
            roundIndex={roundIndex + 1}
            topParty={matchCluster.rightTopMatch.participants[0]}
          />
          {matchCluster.rightBottomMatch && (
            <NxBracketMatch
              bottomParty={matchCluster.rightBottomMatch.participants[1]}
              esportColor={overlayColor}
              match={matchCluster.rightBottomMatch}
              matchIndex={1}
              numMatchesInNextRound={0}
              numMatchesInRound={2}
              roundIndex={roundIndex + 1}
              topParty={matchCluster.rightBottomMatch.participants[0]}
            />
          )}
        </Box>
      )}
    </Box>
  )
}
