import { Grid, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import {
  NxTypography,
  NxButton,
  NxTextArea,
  NxTextInput,
} from '@playvs-inc/nexus-components'

import { formErrorToString } from '@plvs/utils'
import {
  useCreateSchoolMessageMutation,
  CreateSchoolMessageMutationVariables,
  refetchGetAggregateSchoolMessagesQuery,
} from '@plvs/graphql'
import { yupResolver } from '@hookform/resolvers'

import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { School } from './helper'

export type SchoolMessageInput = {
  title: string
  message: string
  schoolId: string
  userId: string
}

const useStyles = makeStyles(() => ({
  input: {
    paddingTop: 0,
  },
  inputContainer: {
    maxHeight: '64px',
  },
}))

export const SchoolMessageForm: React.FC<{
  school: School
}> = ({ school }) => {
  const classes = useStyles()
  const { userId } = useUserIdentityFn()
  const { enqueueSnackbar } = useSnackbar()

  const { errors, handleSubmit, register, reset, formState } = useForm<
    CreateSchoolMessageMutationVariables
  >({
    defaultValues: {
      title: '',
      message: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        message: yup.string().required(),
        title: yup.string(),
      })
    ),
    mode: 'onChange',
  })
  const [error, setError] = React.useState<Error>()

  const [mutate, { loading: isMutating }] = useCreateSchoolMessageMutation()

  const onSubmit = async (
    input: CreateSchoolMessageMutationVariables
  ): Promise<void> => {
    setError(undefined)
    try {
      const response = await mutate({
        refetchQueries: [
          refetchGetAggregateSchoolMessagesQuery({ schoolId: school?.id }),
        ],
        variables: { ...input, schoolId: school?.id ?? '', userId },
      })

      if (response?.data?.createSchoolMessage?.id) {
        reset()

        enqueueSnackbar(`Success! Message posted`, {
          variant: 'success',
        })
      }
    } catch (e: any) {
      setError(e)
      enqueueSnackbar(
        `Could not post message. This may be from an invalid URL in your post. If the problem persists please contact us.`,
        {
          variant: 'error',
        }
      )
      setError(undefined)
    }
  }

  return (
    <form
      data-cy="school-message-form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      {error && error.message && (
        <NxTypography colorToken="ColorTextError">
          Could not save message. Please check that all fields are filled
          correctly.
        </NxTypography>
      )}
      <Box>
        <Grid container spacing={2}>
          <Grid className={classes.inputContainer} item xs={12}>
            <NxTextInput
              ref={register}
              fullWidth
              helperText={formErrorToString(errors.title)}
              label="Post Title (optional)"
              name="title"
              style={{ paddingTop: 0, marginBottom: 0 }}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <NxTextArea
              ref={register}
              fullWidth
              helperText={formErrorToString(errors.message)}
              label="Write a post for all teams"
              name="message"
              rows={5}
              type="text"
            />
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={-1}>
        <NxButton
          disabled={!!error || isMutating || !formState.isValid}
          label="Post"
          type="submit"
          variant="primary"
        />
      </Box>
    </form>
  )
}
