import { useGetMySchoolDetailsQuery } from '@plvs/graphql'
import { EmptyPage } from '@plvs/respawn/components/empty'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import React from 'react'
import { SchoolBasicInfoFormPanel } from './SchoolBasicInfoFormPanel'
import { SchoolConnectedAccountsPanel } from './SchoolConnectedAccountsPanel'
import { SchoolPermissionsFormPanel } from './SchoolPermissionsFormPanel'

/**
 * This is the school info form fields that is seen in the MySchoolSettings page.
 * Each "FormPanel" contains a form that autosaves when fields are edited.
 */
const SchoolInfoForm = (): React.ReactElement => {
  // Using the loading prop to make sure the Apollo cache for my.school is initialized
  // before we mount the individual form components.
  const { loading, error } = useGetMySchoolDetailsQuery()

  return (
    <WaitTillLoaded
      error={error}
      loading={loading}
      loadingSpinnerProps={{ size: 'medium' }}
      renderErrorState={(): React.ReactElement => (
        <EmptyPage subtitle="An error has occurred while loading your school settings.  Please try again at a later time." />
      )}
      showSpinnerWhileLoading
    >
      <SchoolBasicInfoFormPanel />

      <Box mt={5}>
        <SchoolPermissionsFormPanel />
      </Box>

      <Box mt={5}>
        <SchoolConnectedAccountsPanel />
      </Box>
    </WaitTillLoaded>
  )
}

export default SchoolInfoForm
