import { makeStyles } from '@material-ui/core'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'

import {
  ResourceType,
  useGetMatchTeamDetailsQuery,
  UserRoleName,
} from '@plvs/graphql'

import React from 'react'
import {
  NxTypography,
  NxEsportAvatar,
  parseMessage,
} from '@playvs-inc/nexus-components'
import dayjs from '@plvs/respawn/init/dayjs'
import * as analytics from '@plvs/respawn/features/analytics'
import { Attach } from '@playvs-inc/nexus-icons'
import {
  useActiveGlobalChatConversationsVar,
  useActiveGlobalWindowsVar,
  useGlobalUnreadMessagesVar,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import { CreateCSSProperties } from '@material-ui/styles'

import { ConversationsProviderMetadata } from '@plvs/rally/containers/chat/Providers.types'
import { getContextualTeams } from '@plvs/utils'
import { onOpenGlobalChat } from '../../../chat/utils'
import { useStyles as useDrawerStyles } from '../AppDrawerToggle.styles'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      boxShadow: theme.mixins.boxShadow.elevation3,
    },
    transition: 'box-shadow 0.5s ease-in-out',
  },
  chatBodyText: ({
    isMessageUnread,
  }: {
    isMessageUnread: boolean
  }): CreateCSSProperties => ({
    lineHeight: '1.1rem',
    color: isMessageUnread
      ? theme.palette.ColorTextBase
      : theme.palette.ColorTextAlt2,
    fontWeight: isMessageUnread ? 700 : 400,
    height: '100%',
    whiteSpace: 'pre-line',
  }),
  timeText: {
    alignSelf: 'center',
    color: theme.palette.ColorTextDisabled,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  statusContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

interface ChatPreviewCardProps {
  conversation: ConversationsProviderMetadata
  matchId: string
  isMobile: boolean
}
export const ChatPreviewCard: React.FC<ChatPreviewCardProps> = ({
  conversation,
  matchId,
  isMobile,
}) => {
  const { unreadMessagesByConversation } = useGlobalUnreadMessagesVar()
  const { activeConversations } = useActiveGlobalChatConversationsVar()
  const activeConversationsList = useActiveGlobalWindowsVar()
  const classes = useDrawerStyles({ isMobile })
  const uniqueName = conversation.conversation.uniqueName ?? ''
  const isCoachChat = uniqueName.includes('coach')
  const isMessageUnread = unreadMessagesByConversation[uniqueName] > 0
  const { chatBodyText, statusContainer, timeText, container } = useStyles({
    isMessageUnread,
  })
  const { lastMessage } = conversation

  const { userRoles, loading: rolesLoading } = useUserIdentityFn()
  const { data, loading } = useGetMatchTeamDetailsQuery({
    variables: {
      id: matchId,
    },
    skip: !matchId,
  })

  const { team1, team2 } = getContextualTeams(data?.match?.teamContext?.teams)

  const myTeamId = userRoles.find(
    (role) => role.resourceId === team1?.id || role.resourceId === team2?.id
  )?.resourceId

  const myTeam = myTeamId === team1?.id ? team1 : team2
  const myTeamName = myTeam?.name
  const esportSlug = data?.match?.esport?.slug

  const isAdmin = userRoles.some(
    (role) =>
      role.roleName === UserRoleName.Admin &&
      role.resourceType === ResourceType.System
  )

  const adminTitle = `${team1?.name} v. ${team2?.name}`

  const isLastMessageMedia = lastMessage?.type === 'media'

  const lastMessageBody = lastMessage?.body ?? ''

  const updatedAt = dayjs(conversation.chatLastUpdated)

  let formattedDate = updatedAt.format('MMM DD, YYYY')
  if (updatedAt.diff(dayjs(), 'hours') > -24) {
    formattedDate = updatedAt.fromNow()
  }

  const onClick = (): void => {
    onOpenGlobalChat({
      uniqueName,
      activeConversations,
      activeConversationsList,
    })

    analytics.userReadUnreadMessageViaGlobal({
      chatUniqueName: uniqueName,
      location: 'Chat Drawer',
      numberOfUnreadMessagesRead: unreadMessagesByConversation[uniqueName],
    })

    conversation.conversation.setAllMessagesRead()
  }

  const mediaMessage = (
    <Box alignItems="center" display="flex">
      <Attach />
      <Box pl={0.5}>
        <NxTypography className={chatBodyText}>
          You received an attachment
        </NxTypography>
      </Box>
    </Box>
  )

  return (
    <WaitTillLoaded
      loading={loading || rolesLoading}
      loadingSpinnerProps={{ size: 'small' }}
      showSpinnerWhileLoading
    >
      <Box
        className={classes.cardContainer}
        data-cy="chat-preview-box"
        mb={2}
        position="relative"
      >
        <Box
          className={container}
          display="flex"
          onClick={onClick}
          pb={2}
          pl={2}
          pr={3}
          pt={3}
        >
          <Box flex={0}>
            <NxEsportAvatar
              esport={esportSlug}
              iconHeight={16}
              iconWidth={16}
            />
          </Box>

          <Box
            data-cy={myTeamName}
            display="flex"
            flex={1}
            flexDirection="column"
            ml={1}
          >
            <NxTypography variant="body2">
              {isAdmin ? adminTitle : myTeamName}
              {isCoachChat && '  (Coach)'}
            </NxTypography>
            <Box
              data-cy="text-preview"
              maxHeight="2.2rem"
              mb={1.0}
              mt={0.3}
              overflow="hidden"
            >
              <NxTypography className={chatBodyText} component="div">
                {isLastMessageMedia
                  ? mediaMessage
                  : parseMessage(lastMessageBody, {
                      emoteSize: 22,
                      hideLinks: true,
                    })}
              </NxTypography>
            </Box>
            <Box className={statusContainer}>
              <NxTypography className={timeText} variant="overline">
                {formattedDate}
              </NxTypography>
            </Box>
          </Box>
        </Box>
      </Box>
    </WaitTillLoaded>
  )
}
