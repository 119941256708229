import React from 'react'
import tz from 'dayjs/plugin/timezone'
import dayjs from '@plvs/respawn/init/dayjs'
import { isNumber } from 'ramda-adjunct'
import { Box } from '@plvs/respawn/features/layout'
import { Colors } from '@plvs/rally/themes'
import { HeroGutter } from '@plvs/rally/components/hero'
import { makeStyles } from '@material-ui/core'
import { EsportRating, EsportSlug } from '@plvs/graphql'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import {
  NxTypography,
  NxPlayVsBanner,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'

dayjs.extend(tz)

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'left',
  },
  currentTeamContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  opponentContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.Grey1,
  },
  teamLogoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  banner: {
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 10,
    alignItems: 'center',
    backgroundColor: Colors.Sapphire,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stateHeader: {
    marginLeft: theme.spacing(1),
  },
  teamAvatar: {
    paddingLeft: '32px',
  },
  gameIcon: {
    margin: '4px 16px 0',
  },
  matchTime: {
    marginRight: '8px',
  },
  heroIcon: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1.5),
  },
}))

interface TeamHeroProps {
  avatarUrl?: string
  rank?: number | null
  record?: {
    won?: number
    lost?: number
  }
  subtitle?: string
  title: string
  esportSlug?: EsportSlug | null
  esportRating?: EsportRating
  teamId: string
  isPublic: boolean
}

export const TeamHero = ({
  avatarUrl,
  rank,
  record,
  subtitle,
  title,
  esportSlug,
  esportRating = EsportRating.General,
  teamId,
  isPublic,
}: TeamHeroProps): React.ReactElement => {
  const classes = useStyles()

  const { NexusIcon } = useEsportContext()

  const showRecord =
    esportSlug !== EsportSlug.Fortnite &&
    esportSlug !== EsportSlug.FortniteSolos &&
    record &&
    !!record.won &&
    !!record.lost

  const rankingElement = showRecord ? (
    <NxTypography colorToken="OverlayColorTextBase" variant="h2">
      {record?.won} - {record?.lost}{' '}
      {isNumber(rank) ? `· #${rank} in Standings` : `Record`}
    </NxTypography>
  ) : (
    <></>
  )

  return (
    <HeroGutter>
      <NxPlayVsBanner
        additionalContent={isPublic ? undefined : rankingElement}
        avatar={
          <Box>
            <NxUserAvatar
              avatarUrl={
                esportRating === EsportRating.Restricted ? null : avatarUrl
              }
              hashId={teamId}
              size={NxAvatarSize.LG}
            />
          </Box>
        }
        shouldUseDefaultBanner={isPublic}
        size="small"
        subtitle={subtitle}
        title={
          <Box alignItems="center" display="flex">
            {title}
            <NexusIcon
              className={classes.heroIcon}
              height={32}
              mode="dark"
              width={32}
            />
          </Box>
        }
        variant={PersonaImageVariant.Explore}
      />
    </HeroGutter>
  )
}
