import React from 'react'
import { last } from 'ramda'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { useForm } from 'react-hook-form'
import { Grid, capitalize, makeStyles, useTheme } from '@material-ui/core'

import {
  NxButton,
  NxTextLink,
  NxTypography,
  NxSelect,
  NxSelectOption,
} from '@playvs-inc/nexus-components'

import { Esport } from '@plvs/respawn/features/esport/Esport'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'

import { Path } from '@plvs/const'
import {
  Metaseason,
  GetSchoolTeamsByIdQuery,
  CompetitionGroup,
} from '@plvs/graphql'
import { sortByMaybeStartsAt, mapMetaseasonTimingToRelative } from '@plvs/utils'
import { useScrimmageEsportOptions } from '@plvs/rally/containers/scrimmage/ScrimmageEsportOptions'

import {
  CreateScrimmageFormInput,
  CreateScrimmageFormSchema,
} from '@plvs/rally/components/scrimmage/scrimmageHelpers'
import { yupResolver } from '@hookform/resolvers'
import { RosterContainer } from '@plvs/respawn/features/roster/RosterContainer'
import { useDateOptions, useTimeOptions } from '@plvs/rally/components/form'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { RegionalNational } from '@plvs/respawn/assets/icon'
import { Box } from '@plvs/respawn/features/layout/Box'

dayjs.extend(tz)

const useStyles = makeStyles((theme) => ({
  textLink: {
    textTransform: 'lowercase',
    display: 'inline-block',
  },
  subtitle: {
    color: theme.palette.ColorTextAlt2,
  },
}))

export const CreateScrimmageStepA: React.FC<{
  formValues: CreateScrimmageFormInput
  onSubmit(input: CreateScrimmageFormInput): Promise<void>
  teams: NonNullable<NonNullable<GetSchoolTeamsByIdQuery['school']>['teams']>
  schoolCompetitionGroup?: CompetitionGroup | null
  isCoach?: boolean
}> = ({ teams, onSubmit, formValues, schoolCompetitionGroup, isCoach }) => {
  const theme = useTheme()
  const classes = useStyles()
  const {
    errors,
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
  } = useForm<CreateScrimmageFormInput>({
    defaultValues: formValues ?? {
      esport: '',
      rating: '',
    },
    resolver: yupResolver<CreateScrimmageFormInput>(CreateScrimmageFormSchema),
  })

  const watchSelectedEsport = watch('esport', formValues?.esport ?? undefined)
  const watchSelectedTeamId = watch(
    'requestingTeamId',
    formValues?.requestingTeamId ?? ''
  )
  const watchSelectedDate = watch('date', '')

  const { scrimmageRatings } = useGeneralEsportAdapter(watchSelectedEsport)
  const scrimmageEsportOptions = useScrimmageEsportOptions({
    competitionGroups: schoolCompetitionGroup
      ? [schoolCompetitionGroup]
      : undefined,
    selectedEsportSlug: watchSelectedEsport,
  })
  const dateOptions = useDateOptions()
  const timeOptions = useTimeOptions({
    date: watchSelectedDate ?? '',
    timezone: dayjs.tz.guess(),
  })

  const title = 'Create Scrimmage'
  const subtitle =
    'Practice against another team in your league without changing your standings.'
  const rosterWarning =
    'Teams must have a full roster to participate in scrimmages.'
  const gridContainerSpacing = 4
  const esportTeams = watchSelectedEsport
    ? teams.filter((team) => team?.esport?.slug === watchSelectedEsport)
    : []
  const selectedTeam = watchSelectedTeamId
    ? teams.find((team) => team.id === watchSelectedTeamId)
    : null
  const areAllTeamsValidSize = watchSelectedEsport
    ? esportTeams.every(
        (team) =>
          (team.roster?.players?.length ?? 0) >=
          (team.esport?.leagueDefaults?.teamFormat ?? 0)
      )
    : true

  const sortedSeasons = sortByMaybeStartsAt(selectedTeam?.seasons ?? [])
  const latestActiveMetaseason = last(sortedSeasons)?.metaseason as Metaseason
  const minimalMatchMetaseason = latestActiveMetaseason
    ? mapMetaseasonTimingToRelative(latestActiveMetaseason)
    : undefined

  const selectSubtitle = areAllTeamsValidSize ? undefined : rosterWarning
  const emptyState = isCoach ? (
    <NxButton
      href={Path.ManageTeams}
      label="Create New Team"
      variant="primary"
    />
  ) : (
    <NxTypography variant="body1">No teams available</NxTypography>
  )

  return (
    <MatchCard py={4} title={title} titleDetail={subtitle}>
      <form
        data-cy="create-scrimmage-form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box>
          <Grid container spacing={gridContainerSpacing}>
            <Grid item sm={6} xs={12}>
              <NxSelect
                defaultValue={getValues().esport ?? ''}
                error={!!errors.esport}
                fullWidth
                label="Esports"
                name="esport"
                {...((register('esport') as unknown) as Record<
                  string,
                  unknown
                >)}
                onChange={(e): void => {
                  setValue('esport', e.target.value as string)
                }}
              >
                {scrimmageEsportOptions}
              </NxSelect>
            </Grid>
          </Grid>

          <Grid container spacing={gridContainerSpacing}>
            <Grid item sm={6} xs={12}>
              {!esportTeams.length && watchSelectedEsport ? (
                emptyState
              ) : (
                <NxSelect
                  disabled={!watchSelectedEsport}
                  error={!!errors.requestingTeamId}
                  fullWidth
                  label="Team"
                  name="requestingTeamId"
                  subtitle={selectSubtitle}
                  {...((register('requestingTeamId') as unknown) as Record<
                    string,
                    unknown
                  >)}
                  onChange={(e): void => {
                    setValue('requestingTeamId', e.target.value as string)
                  }}
                >
                  <NxSelectOption key="placeholder" value="">
                    Select a team
                  </NxSelectOption>

                  {esportTeams.map((team) => {
                    return (
                      <NxSelectOption
                        key={team.id}
                        disabled={
                          (team.roster?.players?.length ?? 0) <
                          (team.esport?.leagueDefaults?.teamFormat ?? 0)
                        }
                        value={team.id}
                      >
                        <Box
                          alignItems="center"
                          display="flex"
                          gridGap={theme.spacing(1)}
                        >
                          <RegionalNational />

                          {team.name}
                        </Box>
                      </NxSelectOption>
                    )
                  })}
                </NxSelect>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={gridContainerSpacing}>
            <Grid item sm={6} xs={12}>
              <NxSelect
                defaultValue={getValues().rating ?? ''}
                error={!!errors.rating}
                fullWidth
                label="Rating"
                name="rating"
                {...((register('rating') as unknown) as Record<
                  string,
                  unknown
                >)}
                onChange={(e): void => {
                  setValue('rating', e.target.value as string)
                }}
              >
                {scrimmageRatings.map((rating) => (
                  <NxSelectOption
                    key={rating}
                    aria-label={rating}
                    value={rating}
                  >
                    {capitalize(rating)}
                  </NxSelectOption>
                ))}
              </NxSelect>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
              <Box
                display="flex"
                flexDirection={['column', 'row']}
                gridGap={theme.spacing(2)}
              >
                <NxSelect
                  {...((register('date') as unknown) as Record<
                    string,
                    unknown
                  >)}
                  defaultValue={getValues().date ?? ''}
                  disabled={!watchSelectedTeamId}
                  error={!!errors.date}
                  fullWidth
                  label="Date"
                  name="date"
                  onChange={(e): void => {
                    setValue('date', e.target.value as string)
                  }}
                >
                  {dateOptions}
                </NxSelect>

                <NxSelect
                  disabled={!watchSelectedDate}
                  error={!!errors.time}
                  fullWidth
                  {...((register('time') as unknown) as Record<
                    string,
                    unknown
                  >)}
                  defaultValue={getValues().time ?? ''}
                  label="Time"
                  name="time"
                  onChange={(e): void => {
                    setValue('time', e.target.value as string)
                  }}
                >
                  {watchSelectedDate && timeOptions}
                </NxSelect>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          gridGap={theme.spacing(2)}
          justifyContent="flex-end"
          mt={3}
        >
          <NxButton href={Path.Scrimmage} label="Cancel" variant="secondary" />
          <NxButton label="Next" type="submit" variant="primary" />
        </Box>
      </form>
      {!!selectedTeam && latestActiveMetaseason && (
        <Box mt={4}>
          <NxTypography variant="h3">{`Team Roster for ${latestActiveMetaseason.name}`}</NxTypography>
          <Box>
            <NxTypography className={classes.subtitle} variant="body1">
              Scrimmages always pull the most recently updated{' '}
              <NxTextLink
                className={classes.textLink}
                href={`${Path.ManageTeams}?e=${selectedTeam?.esport?.name}`}
                label="roster."
                labelVariant="body1"
              />
            </NxTypography>
          </Box>

          <Esport slug={watchSelectedEsport}>
            <Box ml={-3}>
              <RosterContainer
                metaseason={minimalMatchMetaseason}
                noBoxShadow
                readOnly
                showAdditionalContainerActions={false}
                showCreateScrimmage={false}
                teamId={selectedTeam?.id ?? ''}
              />
            </Box>
          </Esport>
        </Box>
      )}
    </MatchCard>
  )
}
