import { makeStyles, Divider, Paper, useTheme } from '@material-ui/core'
import React, { useEffect } from 'react'
import { CreateCSSProperties } from '@material-ui/styles'
import { NodeEnv } from '@plvs/env'
import { Box, Hidden, useBreakpointSm } from '@plvs/respawn/features/layout'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ApiEnvBadge } from '@plvs/rally/components/badge'
import { AppDrawerLinks } from '@plvs/respawn/features/app-drawer/AppDrawerLinks'
import { RouteComponentProps } from '@plvs/respawn/features/route/WithRouter'
import { useProductTypeFn } from '@plvs/client-data/hooks'
import { useAppDrawerRenderLinks } from '@plvs/respawn/renderController/appDrawer/utils/appDrawerRenderController.helpers'
import { DrawerAnnouncementList } from './DrawerAnnouncementList'
import { ProfileDropdown } from './ProfileDropdown'
import { JoinPlayVs } from './JoinPlayVs'
import { AppDrawerLogo } from './AppDrawerLogo'

interface StyleProps {
  isExpanded?: boolean
  isFullBleedBanner?: boolean
  isPublic: boolean
}

const useStyles = makeStyles((theme) => ({
  appDrawer: {
    minWidth: theme.spacing(40),
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing(35),
    },
    '&>div': {
      overflow: 'hidden',
    },
  },
  apiBadge: {
    top: theme.spacing(8),
    right: theme.spacing(7.125),
    position: 'absolute',
  },
  container: ({ isExpanded }: StyleProps): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    justifyContent: 'space-between',
    height: 'calc(100vh - 64px)',
    zIndex: 2,
    maxHeight: theme.spacing(125),
    overflow: 'auto',
    position: 'fixed',
    top: 0,
    width: isExpanded ? theme.spacing(35) : theme.spacing(12),
    margin: theme.spacing(4, 3),
    padding: 0,
    transition: 'width 200ms ease',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100dvh - 92px)',
      borderRadius: 0,
      top: 92,
      margin: 0,
      justifyContent: 'normal',
    },
  }),
  appDrawerLinks: ({ isPublic }: StyleProps): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isPublic ? 'normal' : 'space-between',
    '&::-webkit-scrollbar': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.ColorBackgroundAlt2,
    },
  }),
  avatarSkeleton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  mobileHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  topShadow: {
    position: 'relative',
    height: theme.spacing(2),
    top: `-${theme.spacing(2)}px`,
    boxShadow: '0px 4px 4px 0px #0000001F',
    marginTop: -3,
  },
  profile: {
    [theme.breakpoints.down('sm')]: {
      width: 280,
    },
  },
}))

export interface UserMinimalUser {
  id: string
  name: string
  avatarUrl?: string | null
}

interface Props extends RouteComponentProps {
  apiEnv?: NodeEnv
  isCoach?: boolean
  isCaptain?: boolean
  isPlayer?: boolean
  isParent?: boolean
  isPublic: boolean
  userNameAndAvatar?: UserMinimalUser | null
  showUpcomingMatches?: boolean
  Logo?: React.ReactElement
  AuthCta?: React.ReactElement
}

export const AppDrawer = ({
  apiEnv,
  isCoach,
  isPlayer,
  isCaptain,
  isParent,
  isPublic,
  userNameAndAvatar,
  showUpcomingMatches = true,
  Logo,
  AuthCta = <JoinPlayVs />,
}: Props): React.ReactElement => {
  const productType = useProductTypeFn()
  const isMobile = useBreakpointSm()
  const flags = useFlags()
  const theme = useTheme()

  const [isExpanded, setIsExpanded] = React.useState(true)

  const classes = useStyles({
    isExpanded,
    isPublic,
  })

  useEffect(() => {
    setIsExpanded(true)
  }, [])

  const { links } = useAppDrawerRenderLinks({
    isCoachAtOrg: isCoach,
    isPlayerAtOrg: isPlayer,
    isCaptainAtOrg: isCaptain,
    isParent,
    isMobile,
    isPublic,
    hasResourcesFlag: flags.playerResourcesPage,
    productType,
  })

  const showApiEnvBadge = apiEnv !== 'production' && Boolean(apiEnv)
  const shouldRenderAnnouncementList =
    userNameAndAvatar?.id && showUpcomingMatches && !isParent
  const userId = userNameAndAvatar?.id
  const drawerLinksHeightVh = isPublic ? '86vh' : '100vh'
  const drawerLinksHeightPx = '292px'

  const drawerLinksMaxHeight = isPublic ? '662px' : '772px'

  return (
    <Box className={classes.appDrawer}>
      <Paper className={classes.container} elevation={1}>
        <Box display="flex" flex={1} flexDirection="column">
          <Hidden mdUp>
            <div className={classes.topShadow} />
          </Hidden>
          {(!isMobile && Logo) ?? <AppDrawerLogo />}
          {/* DO NOT REMOVE "height" CSS.
          The "AppDrawerLinks" section needs a height
          in order to have vertical scrolling working */}
          <Box
            className={classes.appDrawerLinks}
            data-testid="AppDrawer__AppDrawerLinks"
            height={`calc(${drawerLinksHeightVh} - ${drawerLinksHeightPx})`}
            maxHeight={drawerLinksMaxHeight}
            overflow="auto"
          >
            <AppDrawerLinks isExpanded={isExpanded} navigationLinks={links} />

            {showApiEnvBadge && (
              <Box data-testid="api-badge" pl={theme.spacing(0.7)}>
                <ApiEnvBadge apiEnv={apiEnv} />
              </Box>
            )}

            {shouldRenderAnnouncementList && (
              <Box className={classes.mobileHidden} ml={1.875} mt={6}>
                <DrawerAnnouncementList
                  canViewPracticeDay={isCoach || isCaptain}
                />
              </Box>
            )}
          </Box>
        </Box>

        {/* User profile nav */}
        {!isPublic && userId ? (
          <Box className={classes.profile}>
            <Divider
              className={classes.mobileHidden}
              light
              variant="fullWidth"
            />
            <ProfileDropdown userNameAndAvatar={userNameAndAvatar} />
          </Box>
        ) : (
          AuthCta
        )}
      </Paper>
    </Box>
  )
}
