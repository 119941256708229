import React from 'react'
import { Navigate } from 'react-router-dom'
import { CompetitionGroup } from '@plvs/graphql'
import { useUserAttrsContext } from '@plvs/rally/containers/app'
import { Path } from '@plvs/const'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { useOrganizationsContext } from '@plvs/rally/containers/organization/OrganizationsProvider'
import { isCoachAtOrganization } from '@plvs/utils'
import { UserOnlineStatusProvider } from '@plvs/respawn/features/userOnlineStatus/UserOnlineStatusProvider'
import { ManageTeamsProvider } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { ManageTeamsPage } from './ManageTeamsPage'

export const ManageTeamsWrapper: React.FC = () => {
  const {
    organization,
    loading: organizationLoading,
  } = useOrganizationsContext()
  const {
    roles,
    selectedEntityId,
    loading: profileLoading,
  } = useProfileContext()

  const { attributes, loading: loadingAttributes } = useUserAttrsContext()
  const isCoachAtOrg = isCoachAtOrganization(roles, [selectedEntityId])

  const isHighSchool =
    organization?.competitionGroup === CompetitionGroup.HighSchool

  const hasManageTeams = isHighSchool
    ? isCoachAtOrg
    : !!attributes?.hasManageTeams

  return (
    <WaitTillLoaded
      loading={organizationLoading || profileLoading || loadingAttributes}
    >
      {hasManageTeams ? (
        <UserOnlineStatusProvider>
          <ManageTeamsProvider>
            <ManageTeamsPage />
          </ManageTeamsProvider>
        </UserOnlineStatusProvider>
      ) : (
        <Navigate to={Path.Dashboard} />
      )}
    </WaitTillLoaded>
  )
}
