import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'

import { EsportSlug } from '@plvs/graphql'
import { HeroMatch } from '@plvs/respawn/features/match-lobby/types/Lobby.types'
import { MatchHeroDetailsContainer } from './MatchHeroDetailsContainer'

export const MatchHeroContainer: React.FC<{
  countdown?: string
  teamAId?: string
  size: 'small' | 'medium' | 'large'
  match: HeroMatch
  esportSlug: EsportSlug | null | undefined
  breadcrumbHref?: string
}> = ({ countdown, esportSlug, size, match, breadcrumbHref }) => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <MatchHeroDetailsContainer
      breadcrumbHref={breadcrumbHref}
      countdown={countdown}
      esportSlug={esportSlug}
      isSmallBreakpoint={isSmallBreakpoint}
      match={match}
      mobile={isMobile}
      size={size}
    />
  )
}
