import { Box } from '@plvs/respawn/features/layout'
import React from 'react'
import { BracketMatch } from './Bracket'

export const BRACKET_MATCH_HEIGHT = 190
export const BRACKET_DATE_TIME_FORMAT = `h:mm A z, MMM Do, YYYY`

export const handleLinkInNewTab = (link: string | null): void => {
  if (link) {
    window.open(link, '_blank', 'noreferrer')
    window?.focus()
  }
}

export const getRightBottomMatch = (
  secondColumnOfMatches: BracketMatch[] | null | undefined,
  leftTopNextMatchId: string | null,
  leftBottomNextMatchId: string | null
): BracketMatch | null | undefined => {
  if (!secondColumnOfMatches || leftTopNextMatchId === leftBottomNextMatchId) {
    return null
  }
  return secondColumnOfMatches.find((x) => x.id === leftBottomNextMatchId)
}

export function renderVerticalLine(
  height: number,
  color: string,
  numMatchesInRound: number,
  numMatchesInNextRound: number,
  index: number,
  isLastLowerBracketMatch: boolean,
  hideVertLine: boolean,
  className: string
): React.ReactNode {
  const lineHeight = height / 2
  if (numMatchesInNextRound === 0 || isLastLowerBracketMatch || hideVertLine) {
    return <></>
  }
  const down = index % 2 === 0
  if (numMatchesInNextRound === numMatchesInRound) {
    return <></>
  }
  if (down) {
    return (
      <Box
        border={color}
        className={className}
        height={lineHeight + 2}
        mb={`${-1 * lineHeight}px`}
      />
    )
  }
  return (
    <Box
      border={color}
      className={className}
      height={lineHeight}
      mt={`${-1 * lineHeight + 2}px`}
    />
  )
}
