import { makeStyles, Menu, MenuItem } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React from 'react'
import {
  refetchGetCoachesAtMySchoolQuery,
  useVerifyDeniedCoachMutation,
} from '@plvs/graphql'
import { formErrorToString } from '@plvs/utils'
import { NxIconButton, NxTypography } from '@playvs-inc/nexus-components'
import { Moremenu } from '@playvs-inc/nexus-icons'
import { Box } from '@plvs/respawn/features/layout/Box'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 5,
  },
}))
export interface UserDeniedCoachMoreProps {
  id: string
  schoolId: string
}

export const UserDeniedCoachMore: React.FC<UserDeniedCoachMoreProps> = ({
  id,
  schoolId,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const [
    verifyDeniedCoach,
    { loading: verifyingDeniedCoach },
  ] = useVerifyDeniedCoachMutation()

  const onVerifyDeniedCoach = async (): Promise<void> => {
    try {
      await verifyDeniedCoach({
        refetchQueries: [refetchGetCoachesAtMySchoolQuery()],
        awaitRefetchQueries: true,
        variables: {
          userId: id,
          resourceId: schoolId,
        },
      })
    } catch (err: any) {
      enqueueSnackbar(`Could not deny coach ${formErrorToString(err)}`, {
        variant: 'error',
      })
    }
  }

  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <NxIconButton
        aria-label="copy"
        className={classes.iconButton}
        data-cy="denied-coach-more-menu"
        icon={<Moremenu />}
        label=""
        onClick={handleClick}
        variant="text"
      />
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        <MenuItem
          data-cy="denied-coach-more-verify-button"
          disabled={verifyingDeniedCoach}
          onClick={onVerifyDeniedCoach}
        >
          <NxTypography variant="body1">Verify Coach</NxTypography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
