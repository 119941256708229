import React from 'react'
import { Tooltip } from '@plvs/rally/components/tooltip'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { NativeSelectField } from '@plvs/rally/components/form'
import { Button, capitalize } from '@material-ui/core'
import { CompetitionGroup, EsportRating } from '@plvs/graphql'
import { formErrorToString, yupTeamNameValidation } from '@plvs/utils'
import {
  NxTypography,
  NxButton,
  NxTextInput,
  NxTextLink,
} from '@playvs-inc/nexus-components'

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useNavigate } from 'react-router-dom'
import { Path } from '@plvs/const'
import {
  EsportConfig,
  UserCreateTeam,
  CreateTeamFormInput,
  FormOptions,
} from './CreateTeam.types'
import { useStyles } from './CreateTeam.styles'
import { handleJoinSchool } from '../app/profile/menu/AddSchoolMenuItem'
import {
  getCompGroupDisplayLabel,
  getDefaultCreateTeamFormOption,
  getTeamFormatSizeDisplayLabel,
  hasAccessToCompetitionGroup,
} from './CreateTeam.helpers'

export interface CreateTeamFormProps {
  formValues: CreateTeamFormInput
  onSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void>
  selectedEsport: EsportConfig | undefined
  isCreatingTeam: boolean
  user: UserCreateTeam
  onChange(input: CreateTeamFormInput): void
  formOptions: FormOptions
}
export const CreateTeamForm: React.FC<CreateTeamFormProps> = ({
  formValues,
  onSubmit,
  selectedEsport,
  isCreatingTeam,
  user,
  onChange,
  formOptions,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const isMobile = useBreakpointXs()
  const { teamSizeOptions } = formOptions
  const isEsportSelected = !!selectedEsport
  const disableCreateTeam = !isEsportSelected || isCreatingTeam
  const hasAccess = hasAccessToCompetitionGroup({
    selectedEsport,
    selectedCompetitionGroup: formValues.competitionGroup,
    user,
  })
  const selectedCompetitionGroup = formValues.competitionGroup
  const isNotCoachForOrg =
    selectedCompetitionGroup?.competitionGroup ===
      CompetitionGroup.HighSchool &&
    user?.organization?.id === selectedCompetitionGroup.id &&
    !user.isCoachForOrg

  const noAccessMessage = !hasAccess
    ? 'Join your school to unlock offical High School or College leagues'
    : ''

  const isNotCoachMessage = isNotCoachForOrg
    ? `You must be a coach to create a team for ${capitalize(
        user?.organization?.name ?? ' the school'
      )}`
    : ''

  // Default options when a different esport is selected
  const defaultTeamSizeOption = getDefaultCreateTeamFormOption({
    options: teamSizeOptions,
    value: formValues.size ?? 0,
  })
  const showOrg = selectedEsport?.rating !== EsportRating.Restricted

  const { errors, register, formState } = useForm<{
    name: string
  }>({
    defaultValues: {
      name: formValues.name,
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yupTeamNameValidation,
      })
    ),
    mode: 'onChange',
  })

  return (
    <form
      data-cy="create-team-form"
      data-testid="create-team-form"
      noValidate
      onSubmit={onSubmit}
    >
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        maxWidth="400px"
      >
        {showOrg && (
          <Box display="flex" flexDirection="column" mb={isMobile ? 2 : 0}>
            <NxTypography data-testid="CreateTeam_organization" variant="h4">
              Organization
            </NxTypography>
            <NxTypography colorToken="ColorTextAlt2" variant="body2">
              {formValues.selectedEsportGroupId &&
              formValues?.competitionGroup?.competitionGroup
                ? getCompGroupDisplayLabel({
                    label: formValues?.competitionGroup?.competitionGroup,
                    user,
                  })
                : 'N/A'}
            </NxTypography>
          </Box>
        )}
        <Box display="flex" flexDirection="column">
          <NxTypography variant="h4">Platform</NxTypography>
          <NxTextLink
            label="View platforms on Leagues page"
            onClick={(): void => navigate(Path.Explore)}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" maxWidth="400px">
        <Box hidden={hasAccess} maxWidth="70%" pt={2}>
          <Button
            color="primary"
            fullWidth
            onClick={(): void => handleJoinSchool(navigate)}
            variant="contained"
          >
            Join Your School
          </Button>
        </Box>
        <Box pb={1} pt={2}>
          <NxTypography variant="h4">Select Team Size</NxTypography>
        </Box>
        <NativeSelectField
          className={classes.createTeamSelect}
          data-testid="SelectField__TeamSizeSelect"
          disabled={!isEsportSelected || teamSizeOptions.length === 1}
          fullWidth
          InputLabelProps={{ shrink: isEsportSelected }}
          label="Team size"
          name="size"
          onChange={(e): void => {
            onChange({
              ...formValues,
              size: Number(e.target.value),
            })
          }}
        >
          {teamSizeOptions.map((option) => (
            <option
              key={option.value}
              selected={option.value === defaultTeamSizeOption?.value}
              value={option.label}
            >
              {capitalize(
                getTeamFormatSizeDisplayLabel({ label: option.label })
              )}
            </option>
          ))}
        </NativeSelectField>
        <Box pb={1} pt={2}>
          <NxTypography variant="h4">Enter Your Team Name</NxTypography>
        </Box>
        <NxTextInput
          ref={register}
          data-testid="TextField_TeamNameField"
          fullWidth
          helperText={formErrorToString(errors.name)}
          label="Team Name"
          name="name"
          onChange={(e): void => {
            onChange({
              ...formValues,
              name: e.target.value,
            })
          }}
          type="text"
        />
        <Box py={3}>
          <Tooltip
            style={{ display: 'flex' }}
            title={noAccessMessage || isNotCoachMessage}
          >
            <NxButton
              className={classes.createTeamButton}
              color="primary"
              disabled={
                disableCreateTeam ||
                !hasAccess ||
                isNotCoachForOrg ||
                !formState.isValid
              }
              fullWidth
              label="Create Team"
              type="submit"
              variant="primary"
            />
          </Tooltip>
        </Box>
      </Box>
    </form>
  )
}
