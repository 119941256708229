import { Hidden } from '@material-ui/core'
import React, { useEffect } from 'react'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { NxTypography, NxTab, NxTabs } from '@playvs-inc/nexus-components'

import {
  UserRoleName,
  useGetCoachesAtMySchoolQuery,
  useMarkUserNotificationTaskCompletedMutation,
} from '@plvs/graphql/generated'
import { ReferButton } from '@plvs/rally/containers/onboard'
import { Box } from '@plvs/respawn/features/layout/Box'
import { CoachesTable } from './CoachesTable'
import {
  MinimalCoachEntry,
  MinimalEmailEntry,
  getSchoolEmail,
} from '../players/helpers'
import UnverifiedCoachUsers from './UnverifiedUsers'

enum StatusTabs {
  Verified = 'verified',
  Denied = 'denied',
}

export const ManageCoaches: React.FC = () => {
  const [coachStatusTab, setCoachStatusTab] = React.useState(
    StatusTabs.Verified
  )
  const [
    markUserNotificationTaskCompleted,
  ] = useMarkUserNotificationTaskCompletedMutation()

  const { data, loading } = useGetCoachesAtMySchoolQuery()
  const userId = data?.me?.id ?? ''
  const schoolId = data?.me?.school?.id ?? ''
  const queryParameters = new URLSearchParams(window.location.search)
  const notificationId = queryParameters.get('source')

  const verifiedCoaches = (data?.me?.school?.coaches ?? [])
    .map((coach) => ({
      ...coach,
      email: getSchoolEmail(
        schoolId,
        coach.emails as MinimalEmailEntry[] | null
      ),
      schoolId,
    }))
    .filter((user) => user.id !== userId)

  const pendingCoaches = (data?.me?.school?.coachRolePendingUsers ?? []).map(
    (coach) => ({
      ...coach,
      email: getSchoolEmail(
        schoolId,
        coach.emails as MinimalEmailEntry[] | null
      ),
      schoolId,
    })
  )

  const deniedCoaches = (data?.me?.school?.deniedCoaches ?? []).map(
    (coach) => ({
      ...coach,
      email: getSchoolEmail(
        schoolId,
        coach.emails as MinimalEmailEntry[] | null
      ),
      schoolId,
    })
  )

  const handleTabChange = (_: any, newValue: any): void => {
    setCoachStatusTab(newValue)
  }

  const tabsComponent = (
    <NxTabs onChange={handleTabChange} value={coachStatusTab}>
      <NxTab label="Verified Coaches" value={StatusTabs.Verified} />
      <NxTab label="Denied Coaches" value={StatusTabs.Denied} />
    </NxTabs>
  )

  useEffect(() => {
    if (notificationId) {
      markUserNotificationTaskCompleted({
        variables: {
          userNotificationId: notificationId,
        },
      })
    }
  }, [notificationId])

  return (
    <>
      <NxTypography variant="h3">Coach Verification Pending</NxTypography>

      <UnverifiedCoachUsers
        pendingCoaches={pendingCoaches as MinimalCoachEntry[]}
      />

      <Box>
        <MatchCard
          px={0}
          sideContent={
            <Hidden mdDown>
              <ReferButton
                refer={UserRoleName.Fac}
                setAvailableSchool
                source="manage_coach_page"
                variant="secondary"
              />
            </Hidden>
          }
          tabUnderline={false}
          title={tabsComponent}
        >
          <CoachesTable
            deniedCoaches={deniedCoaches as MinimalCoachEntry[]}
            loading={loading}
            showVerified={coachStatusTab === StatusTabs.Verified}
            verifiedCoaches={verifiedCoaches as MinimalCoachEntry[]}
          />
        </MatchCard>
      </Box>
    </>
  )
}
