import { MatchFormat } from '@plvs/graphql'
import { MatchAssistantAdapterProps } from '@plvs/rally/features/match/assistant/adapter/esports/MatchAssistantAdapter.types'
import { leaderboardMatchAssistantAdapter } from '@plvs/rally/features/match/assistant/adapter/general/leaderboardMatchAssistantSteps'
import { headToHeadMatchAssistantAdapter } from '@plvs/rally/features/match/assistant/adapter/general/headToHeadMatchAssistantSteps'
import { MatchAssistantAdapterSteps } from '../matchAssistantAdapterSteps'

type GeneralMatchAssistantAdaptersMap = {
  [key in MatchFormat]: MatchAssistantAdapterSteps
}

// Returns a map of General Match Assistant (GMA) steps for the given match format.
export const generalMatchAssistantAdapter = ({
  subtitle,
  generalSteps,
  isMatchComplete,
  match,
  setStepIndex,
  DisputeResultsButton,
}: MatchAssistantAdapterProps): GeneralMatchAssistantAdaptersMap => {
  return {
    [MatchFormat.Leaderboard]: leaderboardMatchAssistantAdapter({
      generalSteps,
      setStepIndex,
      subtitle,
      DisputeResultsButton,
    }),
    [MatchFormat.HeadToHead]: headToHeadMatchAssistantAdapter({
      subtitle,
      generalSteps,
      isMatchComplete,
      match,
      setStepIndex,
      DisputeResultsButton,
    }),
  }
}
