import { ProductType } from '@plvs/client-data/models'
import {
  SettingsComponentProps,
  SettingsComponentReturn,
} from '../types/settingsRenderController.types'

export function determineSettingsRender({
  productType,
  isParent,
}: SettingsComponentProps): SettingsComponentReturn {
  if (productType === ProductType.Stadium) {
    return {
      shouldRenderSchool: false,
      shouldDisableStadiumEdits: true,
      shouldRenderUsername: true,
      shouldRenderPlayerPhoneNumber: true,
      shouldRenderOAuth: false,
      shouldRenderLocation: true,
    }
  }

  return {
    shouldRenderSchool: !isParent,
    shouldDisableStadiumEdits: false,
    shouldRenderUsername: false,
    shouldRenderPlayerPhoneNumber: false,
    shouldRenderOAuth: true,
    shouldRenderLocation: false,
  }
}
