import { EsportSlug } from '@plvs/graphql'
import { EsportColumnComponentNames } from './types'

/** ******************************************************************
 * React components for esports
 */
export const esportGamePlayerStatColumns: Record<
  EsportSlug,
  EsportColumnComponentNames[]
> = {
  [EsportSlug.LeagueOfLegends]: [
    EsportColumnComponentNames.UserClusterColumn,
    EsportColumnComponentNames.LeagueOfLegendsChampion,
    EsportColumnComponentNames.LeagueOfLegendsKDA,
    EsportColumnComponentNames.LeagueOfLegendsGPM,
    EsportColumnComponentNames.LeagueOfLegendsCS,
    EsportColumnComponentNames.LeagueOfLegendsDamage,
    EsportColumnComponentNames.LeagueOfLegendsWards,
    EsportColumnComponentNames.LeagueOfLegendsItems,
  ],
  [EsportSlug.RocketLeague]: [
    EsportColumnComponentNames.UserClusterColumn,
    EsportColumnComponentNames.RocketLeagueScore,
    EsportColumnComponentNames.RocketLeagueGoals,
    EsportColumnComponentNames.RocketLeagueAssists,
    EsportColumnComponentNames.RocketLeagueSaves,
    EsportColumnComponentNames.RocketLeagueShots,
  ],
  [EsportSlug.Smite]: [
    EsportColumnComponentNames.UserClusterColumn,
    EsportColumnComponentNames.SmiteEliminations,
    EsportColumnComponentNames.SmiteRespawns,
    EsportColumnComponentNames.SmiteAssists,
    EsportColumnComponentNames.MOBAKillParticipation,
    EsportColumnComponentNames.SmiteGoldEarned,
    EsportColumnComponentNames.SmiteTowersDestroyed,
  ],
  [EsportSlug.Fortnite]: [],
  [EsportSlug.FortniteSolos]: [],
  [EsportSlug.SmashBrosUltimate]: [],
  [EsportSlug.Splatoon]: [],
  [EsportSlug.Overwatch]: [],
  [EsportSlug.Valorant]: [],
  [EsportSlug.Hearthstone]: [],
  [EsportSlug.Fifa21PS4]: [],
  [EsportSlug.Fifa21SolosPS4]: [],
  [EsportSlug.Fifa21XboxOne]: [],
  [EsportSlug.Fifa21SolosXboxOne]: [],
  [EsportSlug.Madden21PS4]: [],
  [EsportSlug.Madden]: [],
  [EsportSlug.Madden21XboxOne]: [],
  [EsportSlug.Madden21SolosXboxOne]: [],
  [EsportSlug.BlackOpsColdWar]: [],
  [EsportSlug.MarioKart]: [],
  [EsportSlug.NBA2K]: [],
  [EsportSlug.FC]: [],
  [EsportSlug.Chess]: [],
  [EsportSlug.Chess2]: [],
  [EsportSlug.Chess3]: [],
  [EsportSlug.ChessKids]: [],
  [EsportSlug.SuperSmashBrosUltimateSolos]: [],
  [EsportSlug.SuperSmashBrosUltimateCrew]: [],
  [EsportSlug.RocketleagueDuos]: [],
  [EsportSlug.StreetFighter]: [],
  [EsportSlug.Esport01]: [],
  [EsportSlug.Esport02]: [],
  [EsportSlug.Minecraft]: [],
  [EsportSlug.Fortnite2]: [],
  [EsportSlug.ARAM]: [],
  [EsportSlug.CollegeFootball]: [],
  [EsportSlug.RocketLeagueGMA]: [],
  [EsportSlug.F1]: [],
  [EsportSlug.MarvelRivals]: [],
  [EsportSlug.PokemonUnite]: [],
}

export const esportMatchPlayerStatColumns: Record<
  EsportSlug,
  EsportColumnComponentNames[]
> = {
  [EsportSlug.LeagueOfLegends]: [
    EsportColumnComponentNames.UserClusterColumn,
    EsportColumnComponentNames.LeagueOfLegendsChampions,
    EsportColumnComponentNames.LeagueOfLegendsKDA,
    EsportColumnComponentNames.MOBAGPMAverage,
    EsportColumnComponentNames.LeagueOfLegendsCS,
    EsportColumnComponentNames.LeagueOfLegendsDamage,
    EsportColumnComponentNames.LeagueOfLegendsWards,
  ],
  [EsportSlug.RocketLeague]: [
    EsportColumnComponentNames.UserClusterColumn,
    EsportColumnComponentNames.RocketLeagueScore,
    EsportColumnComponentNames.RocketLeagueGoals,
    EsportColumnComponentNames.RocketLeagueSaves,
    EsportColumnComponentNames.RocketLeagueAssists,
    EsportColumnComponentNames.RocketLeagueShots,
  ],
  [EsportSlug.Smite]: [
    EsportColumnComponentNames.UserClusterColumn,
    EsportColumnComponentNames.SmiteEliminations,
    EsportColumnComponentNames.SmiteRespawns,
    EsportColumnComponentNames.SmiteAssists,
    EsportColumnComponentNames.MOBAKillParticipation,
    EsportColumnComponentNames.MOBAGPMAverage,
    EsportColumnComponentNames.SmiteTowersDestroyed,
  ],
  [EsportSlug.Fortnite]: [],
  [EsportSlug.FortniteSolos]: [],
  [EsportSlug.SmashBrosUltimate]: [],
  [EsportSlug.Splatoon]: [],
  [EsportSlug.Overwatch]: [],
  [EsportSlug.Valorant]: [],
  [EsportSlug.Hearthstone]: [],
  [EsportSlug.Fifa21PS4]: [],
  [EsportSlug.Fifa21SolosPS4]: [],
  [EsportSlug.Fifa21XboxOne]: [],
  [EsportSlug.Fifa21SolosXboxOne]: [],
  [EsportSlug.Madden21PS4]: [],
  [EsportSlug.Madden]: [],
  [EsportSlug.Madden21XboxOne]: [],
  [EsportSlug.Madden21SolosXboxOne]: [],
  [EsportSlug.BlackOpsColdWar]: [],
  [EsportSlug.MarioKart]: [],
  [EsportSlug.NBA2K]: [],
  [EsportSlug.FC]: [],
  [EsportSlug.Chess]: [],
  [EsportSlug.Chess2]: [],
  [EsportSlug.Chess3]: [],
  [EsportSlug.ChessKids]: [],
  [EsportSlug.SuperSmashBrosUltimateSolos]: [],
  [EsportSlug.SuperSmashBrosUltimateCrew]: [],
  [EsportSlug.RocketleagueDuos]: [],
  [EsportSlug.StreetFighter]: [],
  [EsportSlug.Esport01]: [],
  [EsportSlug.Esport02]: [],
  [EsportSlug.Minecraft]: [],
  [EsportSlug.Fortnite2]: [],
  [EsportSlug.ARAM]: [],
  [EsportSlug.CollegeFootball]: [],
  [EsportSlug.RocketLeagueGMA]: [],
  [EsportSlug.F1]: [],
  [EsportSlug.MarvelRivals]: [],
  [EsportSlug.PokemonUnite]: [],
}

export const esportPhaseStatColumns: Record<
  EsportSlug,
  EsportColumnComponentNames[]
> = {
  [EsportSlug.LeagueOfLegends]: [
    EsportColumnComponentNames.PhasePlayerCluster,
    EsportColumnComponentNames.PlayerPosition,
    EsportColumnComponentNames.LeagueOfLegendsKDAAverage,
    EsportColumnComponentNames.LeagueOfLegendsEliminationsAverage,
    EsportColumnComponentNames.LeagueOfLegendsAssistsAverage,
    EsportColumnComponentNames.MOBAKillParticipation,
    EsportColumnComponentNames.LeagueOfLegendsCSAverage,
    EsportColumnComponentNames.MOBAGPMAverage,
    EsportColumnComponentNames.LeagueOfLegendsTowersDestroyedAverage,
    EsportColumnComponentNames.LeagueOfLegendsMostPickedChampion,
    EsportColumnComponentNames.EndCap,
  ],
  [EsportSlug.RocketLeague]: [
    EsportColumnComponentNames.PhasePlayerCluster,
    EsportColumnComponentNames.PlayerPosition,
    EsportColumnComponentNames.RocketLeagueAvgShootingPercentage,
    EsportColumnComponentNames.RocketLeagueAvgGoals,
    EsportColumnComponentNames.RocketLeagueAvgSaves,
    EsportColumnComponentNames.RocketLeagueAvgAssistsSaves,
    EsportColumnComponentNames.RocketLeagueAvgShots,
    EsportColumnComponentNames.EndCap,
  ],
  [EsportSlug.Smite]: [
    EsportColumnComponentNames.UserClusterColumn,
    EsportColumnComponentNames.SmiteEliminations,
    EsportColumnComponentNames.SmiteRespawns,
    EsportColumnComponentNames.SmiteAssists,
    EsportColumnComponentNames.MOBAKillParticipation,
    EsportColumnComponentNames.MOBAGPMAverage,
    EsportColumnComponentNames.SmiteTowersDestroyed,
    EsportColumnComponentNames.EndCap,
  ],
  [EsportSlug.Fortnite]: [
    EsportColumnComponentNames.FortniteTeamCluster,
    EsportColumnComponentNames.FortniteRank,
    EsportColumnComponentNames.FortnitePoints,
    EsportColumnComponentNames.FortniteAvgPoints,
    EsportColumnComponentNames.FortniteWins,
    EsportColumnComponentNames.FortniteWinPercentage,
    EsportColumnComponentNames.FortniteAvgPlace,
    EsportColumnComponentNames.FortniteAvgElims,
    EsportColumnComponentNames.FortniteGamesPlayed,
    EsportColumnComponentNames.FortniteTimePlayed,
    EsportColumnComponentNames.EndCap,
  ],
  [EsportSlug.FortniteSolos]: [],
  [EsportSlug.SmashBrosUltimate]: [],
  [EsportSlug.Splatoon]: [],
  [EsportSlug.Overwatch]: [],
  [EsportSlug.Valorant]: [],
  [EsportSlug.Hearthstone]: [],
  [EsportSlug.Fifa21PS4]: [],
  [EsportSlug.Fifa21SolosPS4]: [],
  [EsportSlug.Fifa21XboxOne]: [],
  [EsportSlug.Fifa21SolosXboxOne]: [],
  [EsportSlug.Madden21PS4]: [],
  [EsportSlug.Madden]: [],
  [EsportSlug.Madden21XboxOne]: [],
  [EsportSlug.Madden21SolosXboxOne]: [],
  [EsportSlug.BlackOpsColdWar]: [],
  [EsportSlug.MarioKart]: [],
  [EsportSlug.NBA2K]: [],
  [EsportSlug.FC]: [],
  [EsportSlug.Chess]: [],
  [EsportSlug.Chess2]: [],
  [EsportSlug.Chess3]: [],
  [EsportSlug.ChessKids]: [],
  [EsportSlug.SuperSmashBrosUltimateSolos]: [],
  [EsportSlug.SuperSmashBrosUltimateCrew]: [],
  [EsportSlug.RocketleagueDuos]: [],
  [EsportSlug.StreetFighter]: [],
  [EsportSlug.Esport01]: [],
  [EsportSlug.Esport02]: [],
  [EsportSlug.Minecraft]: [],
  [EsportSlug.Fortnite2]: [],
  [EsportSlug.ARAM]: [],
  [EsportSlug.CollegeFootball]: [],
  [EsportSlug.RocketLeagueGMA]: [],
  [EsportSlug.F1]: [],
  [EsportSlug.MarvelRivals]: [],
  [EsportSlug.PokemonUnite]: [],
}

export const esportStandingsColumns: Record<
  string,
  EsportColumnComponentNames[]
> = {
  [EsportSlug.Fortnite]: [
    EsportColumnComponentNames.FortniteRank,
    EsportColumnComponentNames.FortniteTeamCluster,
    EsportColumnComponentNames.FortnitePoints,
    EsportColumnComponentNames.FortniteAvgPoints,
    EsportColumnComponentNames.FortniteAvgPlace,
    EsportColumnComponentNames.FortniteAvgElims,
    EsportColumnComponentNames.FortniteGamesPlayed,
    EsportColumnComponentNames.FortniteState,
    EsportColumnComponentNames.HiddenExpander,
    EsportColumnComponentNames.EndCap,
  ],
  [EsportSlug.FortniteSolos]: [
    EsportColumnComponentNames.FortniteRank,
    EsportColumnComponentNames.FortniteTeamCluster,
    EsportColumnComponentNames.FortnitePoints,
    EsportColumnComponentNames.FortniteAvgPoints,
    EsportColumnComponentNames.FortniteAvgPlace,
    EsportColumnComponentNames.FortniteAvgElims,
    EsportColumnComponentNames.FortniteGamesPlayed,
    EsportColumnComponentNames.FortniteState,
    EsportColumnComponentNames.HiddenExpander,
    EsportColumnComponentNames.EndCap,
  ],
  [EsportSlug.F1]: [
    EsportColumnComponentNames.RankingPosition,
    EsportColumnComponentNames.TeamCluster,
    EsportColumnComponentNames.AvgPlacement,
    EsportColumnComponentNames.FirstFinishes,
    EsportColumnComponentNames.TopFourFinishes,
  ],
}

export const defaultStandingsSeriesColumns: EsportColumnComponentNames[] = [
  EsportColumnComponentNames.RankingPosition,
  EsportColumnComponentNames.TeamCluster,
  EsportColumnComponentNames.MatchRecord,
  EsportColumnComponentNames.PlayVsScore,
  EsportColumnComponentNames.SeriesRecord,
  EsportColumnComponentNames.GameRecord,
]

export const defaultStandingsColumns: EsportColumnComponentNames[] = [
  EsportColumnComponentNames.RankingPosition,
  EsportColumnComponentNames.TeamCluster,
  EsportColumnComponentNames.MatchRecord,
  EsportColumnComponentNames.PlayVsScore,
  EsportColumnComponentNames.GameRecord,
]

export const esportTeamRecentGamesColumns: Record<
  EsportSlug,
  EsportColumnComponentNames[]
> = {
  [EsportSlug.LeagueOfLegends]: [
    EsportColumnComponentNames.LeagueOfLegendsGameCluster,
    EsportColumnComponentNames.LeagueOfLegendsChampions,
    EsportColumnComponentNames.LeagueOfLegendsBans,
    EsportColumnComponentNames.LeagueOfLegendsKDAAverage,
    EsportColumnComponentNames.LeagueOfLegendsGoldEarned,
    EsportColumnComponentNames.LeagueOfLegendsCS,
    EsportColumnComponentNames.EndCap,
  ],
  [EsportSlug.RocketLeague]: [
    // TODO: Currently hidden until we can repair the backend queries.
    // EsportColumnComponentNames.RocketLeagueGameCluster,
    // EsportColumnComponentNames.RocketLeagueScore,
    // EsportColumnComponentNames.RocketLeagueShootingPercentage,
    // EsportColumnComponentNames.RocketLeagueShots,
    // EsportColumnComponentNames.RocketLeagueGoals,
    // EsportColumnComponentNames.RocketLeagueAssists,
  ],
  [EsportSlug.Smite]: [
    // TODO: Currently hidden until we can repair the backend queries.
    // EsportColumnComponentNames.SmiteGameCluster,
    // EsportColumnComponentNames.SmiteKdaAverage,
    // EsportColumnComponentNames.SmiteGoldEarned,
  ],
  [EsportSlug.Fortnite]: [
    // TODO: Currently hidden until we can repair the backend queries.
    // EsportColumnComponentNames.FortniteGameCluster,
    // EsportColumnComponentNames.FortnitePoints,
    // EsportColumnComponentNames.FortniteEliminations,
    // EsportColumnComponentNames.FortniteRank,
  ],
  [EsportSlug.SmashBrosUltimate]: [],
  [EsportSlug.Splatoon]: [],
  [EsportSlug.Overwatch]: [],
  [EsportSlug.Valorant]: [],
  [EsportSlug.Hearthstone]: [],
  [EsportSlug.FortniteSolos]: [],
  [EsportSlug.Fifa21PS4]: [],
  [EsportSlug.Fifa21SolosPS4]: [],
  [EsportSlug.Fifa21XboxOne]: [],
  [EsportSlug.Fifa21SolosXboxOne]: [],
  [EsportSlug.Madden21PS4]: [],
  [EsportSlug.Madden]: [],
  [EsportSlug.Madden21XboxOne]: [],
  [EsportSlug.Madden21SolosXboxOne]: [],
  [EsportSlug.BlackOpsColdWar]: [],
  [EsportSlug.MarioKart]: [],
  [EsportSlug.NBA2K]: [],
  [EsportSlug.FC]: [],
  [EsportSlug.Chess]: [],
  [EsportSlug.Chess2]: [],
  [EsportSlug.Chess3]: [],
  [EsportSlug.ChessKids]: [],
  [EsportSlug.SuperSmashBrosUltimateSolos]: [],
  [EsportSlug.SuperSmashBrosUltimateCrew]: [],
  [EsportSlug.RocketleagueDuos]: [],
  [EsportSlug.StreetFighter]: [],
  [EsportSlug.Esport01]: [],
  [EsportSlug.Esport02]: [],
  [EsportSlug.Minecraft]: [],
  [EsportSlug.Fortnite2]: [],
  [EsportSlug.ARAM]: [],
  [EsportSlug.CollegeFootball]: [],
  [EsportSlug.RocketLeagueGMA]: [],
  [EsportSlug.F1]: [],
  [EsportSlug.MarvelRivals]: [],
  [EsportSlug.PokemonUnite]: [],
}
