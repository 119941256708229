import React, { useContext, useEffect, useMemo, useState } from 'react'
import { noop } from 'ramda-adjunct'

import {
  NxScoreboardSide,
  NxUserClusterProps,
} from '@playvs-inc/nexus-components'
import { useAppLocationFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import { Location } from '@plvs/client-data/models'
import * as analytics from '@plvs/respawn/features/analytics'
import {
  EsportSlug,
  GameAssistantSelection,
  GameAssistantSelectionFragment,
  GameAssistantTurnType,
  MatchStatus,
  useGetMatchForMatchAssistantQuery,
} from '@plvs/graphql'

import {
  defaultTo0,
  defaultToEmptyString,
  getPickForGameOrdinal,
  getTeamAndRosterForSteps,
  PlayerSide,
  stepToStepType,
  StepType,
} from '@plvs/utils'

import { LocalStorageKey } from '@plvs/const'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'

import {
  OnNewStepProps,
  useMatchAssistantSubscription,
} from '@plvs/rally/features/match/assistant/hooks/useMatchAssistantSubscription'
import { indexBy, prop } from 'ramda'
import {
  LobbySeries,
  MatchAssistantProviderProps,
  UseMatchAssistantReturn,
} from './useMatchAssistant.types'
import { mapSeriesToScoreboardRowArray } from './MatchAssistant.helpers'
import { esportSlugToProvider } from '../../account/connections/utils'
import { getDisplayForTeamsInMatchAssistant } from '../lobby/VsMissionControl.helpers'

const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24

export function mapTeamRoster({
  teamRoster,
  teamName,
}: {
  teamName?: string
  teamRoster: {
    id: string
    name: string
    avatarUrl: string
    publisherAccountName: string
  }[]
}): NxUserClusterProps[] {
  return teamRoster.map((player) => ({
    title: defaultToEmptyString(player.name),
    subtitles: [
      { title: player.publisherAccountName || defaultToEmptyString(teamName) },
    ],
    avatarUrl: defaultToEmptyString(player.avatarUrl),
    avatarHashId: player.id,
  }))
}

export function useMatchAssistant({
  matchId,
  myTeamId: teamId,
  esport,
  refetch,
  skipMatchAssistant,
}: MatchAssistantProviderProps): UseMatchAssistantReturn {
  const { id: esportId, hasSeries, rating, name } = useGeneralEsportAdapter(
    esport
  )

  // The below logic is for analytics only
  const [actionsTakenInSteps, setActionsTakenInSteps] = useState<
    Record<string, string>
  >({})
  const [stepTimestamps, setStepTimestamps] = useState<
    Record<
      string,
      {
        fromStepToStep: string
        timeActionWasResolved: string
      }
    >
  >({})

  const location = useAppLocationFn()
  const isInCheckpoint = location === Location.Checkpoint

  const [showMatchAssistantIntro, setShowMatchAssistantIntro] = useState(true)
  // This state is for firing the event to trigger the NPS Wootric survey
  const [submittedUserIdForNPS, setSubmittedUserIdForNPS] = useState('')
  const { userId } = useUserIdentityFn()

  const {
    data: matchData,
    refetch: refetchMatch,
  } = useGetMatchForMatchAssistantQuery({
    variables: {
      matchId: defaultToEmptyString(matchId),
      includeSeries: hasSeries,
    },
    skip: !matchId,
  })

  const match = {
    ...matchData?.match,
    esport: {
      id: esportId,
      slug: esport,
      rating,
      name,
    },
  }

  const indexNameSpace = `${LocalStorageKey.MatchAssistantStepIndex}-${teamId}`
  const stepNameSpace = `${LocalStorageKey.MatchAssistantStep}-${teamId}`
  const gameOrdinalNameSpace = `${LocalStorageKey.MatchAssistantGameOrdinal}-${teamId}`

  const [stepIndex, setStepIndex] = useState<number>(() => {
    return defaultTo0(Number(localStorage.getItem(indexNameSpace)))
  })

  const [gameOrdinal, setGameOrdinal] = useState<number>(
    () => Number(localStorage.getItem(gameOrdinalNameSpace)) ?? 1
  )

  const setStepIndexInLocalStorage = (input: number): void => {
    setStepIndex(input)
    localStorage.setItem(indexNameSpace, input.toString())
  }

  const setGameOrdinalInLocalStorage = (input: number): void => {
    setGameOrdinal(input)
    localStorage.setItem(gameOrdinalNameSpace, input.toString())
  }

  const homeTeam = match?.teamContext?.teams?.[0]
  const awayTeam = match?.teamContext?.teams?.[1]

  const refinedHomeTeam = getDisplayForTeamsInMatchAssistant({
    team: homeTeam,
    esportRating: rating,
  })

  const refinedAwayTeam = getDisplayForTeamsInMatchAssistant({
    team: awayTeam,
    esportRating: rating,
  })

  const analyticsFn = ({ prevState, newState }: OnNewStepProps): void => {
    if (prevState.stepId && newState) {
      setStepTimestamps({
        ...stepTimestamps,
        [prevState.stepId]: {
          fromStepToStep: `${prevState.stepOrdinal} to ${newState.stepOrdinal}`,
          timeActionWasResolved: new Date().toISOString(),
        },
      })
    }
  }

  const {
    prevStepId,
    prevStepData,
    currentStepLoading,
    currentStep,
    refetchCurrentGameAssistantStepByMatchId,
  } = useMatchAssistantSubscription({
    matchId: defaultToEmptyString(matchId),
    teamId,
    onSkipMatchAssistant: skipMatchAssistant,
    onNewStep: analyticsFn,
  })

  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()
  const matchAssistantActions = getMatchLobbyRenderControllerState().match

  useEffect(() => {
    if (prevStepId && actionsTakenInSteps[prevStepId]) {
      analytics.matchAssistantStepTimestamps({
        ...stepTimestamps[prevStepId],
        timeActionWasTaken: actionsTakenInSteps[prevStepId],
      })
    } else if (
      prevStepData?.step?.stepOrdinal !== currentStep?.step?.stepOrdinal &&
      actionsTakenInSteps[prevStepData?.step?.stepOrdinal ?? '']
    ) {
      const prevState = {
        stepId: prevStepData?.step?.id ?? '',
        stepOrdinal: prevStepData?.step?.stepOrdinal ?? 0,
      }
      const newState = {
        stepOrdinal: defaultTo0(currentStep?.step?.stepOrdinal),
      }
      analyticsFn({ prevState, newState })
      analytics.matchAssistantStepTimestamps({
        ...stepTimestamps[prevStepData?.step?.id ?? ''],
        timeActionWasTaken: actionsTakenInSteps[prevStepData?.step?.id ?? ''],
      })
    }
  }, [
    prevStepId,
    actionsTakenInSteps[prevStepId],
    prevStepData?.step?.stepOrdinal,
    currentStep?.step?.stepOrdinal,
  ])

  const step =
    match?.status === MatchStatus.Completed ||
    match?.status === MatchStatus.Forfeited
      ? StepType.Final
      : stepToStepType({
          selectionOptionType: currentStep?.step?.selectionOptionType,
          stepType: currentStep?.step?.type ?? '',
          isComplete: currentStep?.matchDetails?.isComplete ?? false,
        })

  const [stepState, setStepState] = useState<string | null>(
    () => localStorage.getItem(stepNameSpace) ?? step
  )

  const myTeam = match?.teamContext?.myTeams?.find(({ id }) => id === teamId)
  const myRoster = currentStep?.roster
  const opponentRoster = currentStep?.opponentRoster
  const homeTeamRoster =
    homeTeam?.id === myRoster?.teamId ? myRoster : opponentRoster
  const awayTeamRoster =
    awayTeam?.id === myRoster?.teamId ? myRoster : opponentRoster

  const homeTeamWithRoster = useMemo(
    () =>
      getTeamAndRosterForSteps(
        homeTeamRoster,
        refinedHomeTeam,
        esportSlugToProvider[esport as EsportSlug]
      ),
    [homeTeamRoster, refinedHomeTeam?.id, esport]
  )
  const awayTeamWithRoster = useMemo(
    () =>
      getTeamAndRosterForSteps(
        awayTeamRoster,
        refinedAwayTeam,
        esportSlugToProvider[esport as EsportSlug]
      ),
    [awayTeamRoster, refinedAwayTeam?.id, esport]
  )
  const myTeamWithRoster = useMemo(
    () =>
      getTeamAndRosterForSteps(
        myRoster,
        myTeam,
        esportSlugToProvider[esport as EsportSlug]
      ),
    [awayTeamRoster, refinedAwayTeam?.id, esport]
  )

  const matchSeasonId = match?.slot?.phase?.season?.metaseasonId ?? ''

  const currentSeries = currentStep?.currentSeries

  const currentPlayers = [
    ...(currentSeries?.teamContext?.teams[0]?.roster || []),
    ...(currentSeries?.teamContext?.teams[1]?.roster || []),
  ]
  const currentPlayerOptions: NxScoreboardSide[] = mapSeriesToScoreboardRowArray(
    {
      homeTeamId: homeTeam?.id,
      series: (currentSeries ? [currentSeries] : []) as LobbySeries[],
    }
  )

  const isMyTeamHome = homeTeam?.isMyTeam || homeTeam?.id === teamId
  const selectionSide = isMyTeamHome ? PlayerSide.Home : PlayerSide.Away

  const currentTurn = currentStep?.step?.turn || GameAssistantTurnType.All

  const isMyTurn = currentStep?.step?.teamId === teamId

  const options = currentStep?.characterPicks as GameAssistantSelection[]
  const myTeamSelection = getPickForGameOrdinal({
    gameOrdinal,
    seriesOrdinal: currentStep?.step?.seriesOrdinal,
    teamId,
    options,
  })

  const opposingTeam =
    homeTeam?.id === teamId ? awayTeamWithRoster : homeTeamWithRoster
  const opposingTeamId = opposingTeam?.id
  const opposingTeamSelection = getPickForGameOrdinal({
    gameOrdinal,
    seriesOrdinal: currentStep?.step?.seriesOrdinal,
    teamId: opposingTeamId,
    options,
  })

  const homeCharacter = isMyTeamHome ? myTeamSelection : opposingTeamSelection
  const awayCharacter = isMyTeamHome ? opposingTeamSelection : myTeamSelection

  const selectedStage = currentStep?.mapPicks?.find(
    (map) =>
      map.gameAssistantStep?.gameOrdinal === currentStep?.step?.gameOrdinal
  )

  const shouldRenderMatchAssistant = match?.status !== MatchStatus.Quarantined

  const matchDoesNotOpenSoon =
    Date.parse(match?.scheduledStartsAt ?? '') - Date.now() >= ONE_DAY_IN_MS
  const isFallback = step === StepType.Initial && !currentStepLoading
  const shouldRenderIntro =
    !isInCheckpoint && (isFallback || matchDoesNotOpenSoon)

  const charactersByUserId: Record<string, GameAssistantSelectionFragment> =
    currentStep?.characterPicks?.reduce((acc, pick) => {
      if (pick.userId) {
        acc[pick.userId] = {
          ...(acc[pick.userId] || {}),
          [defaultTo0(pick.gameAssistantStep?.gameOrdinal)]: pick,
        }
      }
      return acc
    }, {}) || {}

  useEffect(() => {
    if (
      stepState !== step ||
      (currentStep?.step?.gameOrdinal &&
        currentStep?.step?.gameOrdinal !== gameOrdinal)
    ) {
      setStepState(step)
      setGameOrdinalInLocalStorage(currentStep?.step?.gameOrdinal ?? 1)
      setStepIndexInLocalStorage(0)
      if (step) {
        localStorage.setItem(stepNameSpace, step)
      }
      if (currentStep?.step?.gameOrdinal) {
        localStorage.setItem(
          gameOrdinalNameSpace,
          currentStep?.step?.gameOrdinal.toString()
        )
      }
    }
  }, [step, stepState, currentStep?.step?.gameOrdinal, gameOrdinal])

  function refetchData(): void {
    refetch?.()
    refetchMatch({
      matchId: matchId ?? '',
    })
  }

  const teamsById = indexBy(prop('id'), match?.teamContext?.teams ?? [])

  const data = {
    currentSeries,
    shouldRenderMatchAssistant,
    shouldRenderIntro,
    currentPlayers,
    currentStep,
    currentStepLoading,
    match,
    isMyTeamHome,
    esportSlug: esport,
    esportId,
    matchId: match?.id ?? '',
    userId,
    teamId,
    homeTeam: homeTeamWithRoster,
    awayTeam: awayTeamWithRoster,
    opposingTeam,
    myTeam: myTeamWithRoster,
    homeTeamId: homeTeam?.id,
    awayTeamId: awayTeam?.id,
    teamsById,
    opposingTeamId,
    stepIndex,
    setStepIndex: setStepIndexInLocalStorage,
    currentPlayerOptions,
    gameOrdinal,
    seriesOrdinal: currentStep?.step?.seriesOrdinal,
    stepOrdinal: currentStep?.step?.stepOrdinal,
    isInCheckpoint,
    step,
    selectionSide,
    currentTurn,
    isMyTurn,
    characterSelections: currentStep?.characterPicks ?? [],
    charactersByUserId,
    mapSelections: currentStep?.mapBans ?? [],
    homeCharacter,
    awayCharacter,
    selectedStage,
    lolGameCode: currentStep?.lolGameCode,
    gameMode: currentStep?.splatoonGameMode,
    refetchCurrentGameAssistantStepByMatchId,
    setActionsTakenInSteps,
    actionsTakenInSteps,
    showMatchAssistantIntro,
    setShowMatchAssistantIntro,
    submittedUserIdForNPS,
    matchSeasonId,
    setSubmittedUserIdForNPS,
    refetch: refetchData,
    showRulebookButton: matchAssistantActions?.showRulebookButton ?? false,
  }

  return (data as unknown) as UseMatchAssistantReturn
}

const context = React.createContext<UseMatchAssistantReturn>({
  refetch: noop,
  shouldRenderMatchAssistant: false,
  currentPlayers: [],
  currentStep: undefined,
  isCoachForTeamsOrSchools: false,
  subscriptionData: undefined,
  homeTeam: null,
  awayTeam: null,
  myTeam: null,
  teamId: '',
  opposingTeam: null,
  homeTeamId: '',
  awayTeamId: '',
  opposingTeamId: '',
  matchId: '',
  userId: '',
  currentPlayerOptions: [],
  gameOrdinal: 1,
  seriesOrdinal: 1,
  stepOrdinal: 1,
  characterSelections: [],
  mapSelections: [],
  currentStepLoading: false,
  step: StepType.CharacterSelect,
  selectionSide: PlayerSide.Home,
  charactersByUserId: {},
  currentTurn: undefined,
  isMyTurn: false,
  homeCharacter: undefined,
  awayCharacter: undefined,
  MatchAssistantActions: undefined,
  match: undefined,
  isMyTeamHome: false,
  esportSlug: undefined,
  esportId: undefined,
  isInCheckpoint: false,
  stepIndex: 0,
  showMatchAssistantIntro: false,
  setShowMatchAssistantIntro: noop,
  setStepIndex: noop,
  closePreview: noop,
  setShowPreview: noop,
  showPreview: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  refetchCurrentGameAssistantStepByMatchId: noop,
  submittedUserIdForNPS: '',
  setSubmittedUserIdForNPS: noop,
  matchSeasonId: '',
  gameMode: undefined,
  showRulebookButton: false,
})
const { Provider } = context

export const MatchAssistantProvider: React.FC<MatchAssistantProviderProps> = ({
  esport,
  children,
  myTeamId,
  skipMatchAssistant,
  refetch,
  matchId,
}) => {
  const values = useMatchAssistant({
    myTeamId,
    esport,
    skipMatchAssistant,
    refetch,
    matchId,
  })
  return <Provider value={values}>{children}</Provider>
}

export const useMatchAssistantContext = (): UseMatchAssistantReturn => {
  return useContext(context)
}
