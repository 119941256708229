import { makeStyles, Link } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import React from 'react'
import { animated } from 'react-spring'
import { EsportRating } from '@plvs/graphql'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { LogoMark } from '@plvs/rally/components/icon'
import { RouterLink } from '@plvs/respawn/features/route'
import { Colors } from '@plvs/rally/themes'

import {
  BracketResultStatus,
  bracketResultStatusMap,
  BracketTeam,
  POD_HEIGHT,
} from '@plvs/rally/features/standings/standingsHelpers'
import { NxTypography } from '@playvs-inc/nexus-components'

interface StyleProps {
  bottom?: boolean
  top?: boolean
  noOpponent?: boolean
  statusColor: string
  statusBackgroundColor: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%', // and no less than 280px, ideally
  },
  inner: ({ noOpponent, top }: StyleProps): CreateCSSProperties => ({
    backgroundColor: theme.palette.ColorBackgroundAlt,
    borderBottom: top ? `1px solid ${theme.palette.BorderLight}` : 'none',
    borderRadius: top ? '8px 8px 0 0' : '0 0 8px 8px',
    display: 'flex',
    height: POD_HEIGHT,
    overflow: 'hidden',
    ...(noOpponent && {
      border: `1px solid ${theme.palette.BorderLight}`,
      borderRadius: theme.shape.borderRadius * 2,
      boxShadow:
        '0px 2px 16px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)',
    }),
  }),
  imageWrapper: {
    alignItems: 'center',
    backgroundColor: Colors.Grey4,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    width: 32,
  },
  image: {
    height: '100%',
    width: '100%',
  },
  text: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    minWidth: 1, // prevents element from exceeding parent container's width
    paddingLeft: theme.spacing(2),
  },
  seedText: {
    color: theme.palette.ColorTextAlt2,
    paddingRight: theme.spacing(3),
  },
  byeText: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
    fontSize: 12,
  },
  byeTbd: { color: theme.palette.ColorTextAlt2 },
  teamName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  winLoss: ({
    statusColor,
    statusBackgroundColor,
  }: StyleProps): CreateCSSProperties => ({
    alignItems: 'center',
    backgroundColor: statusBackgroundColor,
    color: statusColor,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    width: 48,
  }),
  logo: {
    color: theme.palette.ColorBackgroundBase,
    height: '50%',
    width: '50%',
  },
}))

interface BracketResultPodProps {
  isUnfilledForBye?: boolean // used for double elimination matches
  esportRating: EsportRating
  matchId: string | null
  noOpponent?: boolean
  seed?: number | null
  status: BracketResultStatus
  team: BracketTeam
  toMatch?(matchId: string): string
  top?: boolean
  isWinningBracket?: boolean
}

export const BracketResultPod: React.FC<BracketResultPodProps> = ({
  isUnfilledForBye,
  matchId,
  noOpponent,
  seed,
  status,
  team,
  toMatch,
  top,
  isWinningBracket,
  esportRating = EsportRating.General,
}) => {
  const matchStatus = isWinningBracket ? 'Win' : status
  const statusBackgroundColor =
    bracketResultStatusMap[matchStatus].backgroundColor
  const statusColor = bracketResultStatusMap[matchStatus].color
  const classes = useStyles({
    noOpponent,
    statusBackgroundColor,
    statusColor,
    top,
  })

  const logoUrl = team?.school?.logoUrl
  const content = (
    <animated.div className={classes.root} data-seed={team ? team.seed : '?'}>
      <div className={classes.inner}>
        {noOpponent && status === BracketResultStatus.Tbd ? null : (
          <>
            <div className={classes.imageWrapper}>
              {logoUrl ? (
                <NxUserAvatar
                  avatarUrl={
                    esportRating === EsportRating.Restricted ? null : logoUrl
                  }
                  className={classes.image}
                  hashId={team?.id || ''}
                  size={NxAvatarSize.SM}
                />
              ) : (
                <LogoMark className={classes.logo} />
              )}
            </div>
            <div className={classes.text}>
              {seed && (
                <NxTypography
                  className={classes.seedText}
                  data-testid="seed"
                  variant="body1"
                >
                  {seed}
                </NxTypography>
              )}
              <NxTypography className={classes.byeTbd} variant="body1">
                {status === BracketResultStatus.Bye && 'Bye'}
                {!team?.id && status === BracketResultStatus.Tbd && 'TBD'}
              </NxTypography>
              <NxTypography className={classes.teamName} variant="body1">
                {team && team.name}
              </NxTypography>
              {isUnfilledForBye && (
                <NxTypography
                  className={classes.byeText}
                  data-testid="bye-match"
                  variant="body2"
                >
                  Bye
                </NxTypography>
              )}
            </div>
            <NxTypography className={classes.winLoss} variant="body2">
              {bracketResultStatusMap[matchStatus].shortText}
            </NxTypography>
          </>
        )}
      </div>
    </animated.div>
  )

  return toMatch && matchId ? (
    <Link
      color="inherit"
      component={RouterLink}
      data-testid="BracketResultPod_Link"
      to={toMatch(matchId)}
      underline="none"
    >
      {content}
    </Link>
  ) : (
    content
  )
}
