import React, { useContext } from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { CreateCSSProperties } from '@material-ui/styles'
import clsx from 'clsx'
import { Smallcheck } from '@playvs-inc/nexus-icons'
import { BracketTeam } from './Bracket'
import { handleLinkInNewTab } from './bracketHelpers'
import { matchContext } from './useMatchHighlightContext'

const useStyles = makeStyles((theme) => ({
  container: ({
    isWinner,
    isLoser,
    isMobile,
    esportColor,
    hovered,
  }: {
    isWinner: boolean
    isLoser: boolean
    isMobile: boolean
    esportColor: string
    hovered: boolean
  }): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: hovered ? esportColor : theme.palette.ColorBackgroundAlt,
    padding: isMobile ? theme.spacing(1, 2, 1, 2) : theme.spacing(1, 3, 1, 3),
    borderRadius: theme.shape.borderRadius,
    borderRight:
      isWinner || hovered
        ? `4px solid ${esportColor}`
        : `4px solid ${theme.palette.ColorBackgroundAlt}`,
    alignItems: 'center',
    minHeight: '54px',
    opacity: isLoser && (!hovered || !isMobile) ? 0.5 : 1,
    cursor: 'pointer',
  }),
  text: ({
    hovered,
    isMobile,
  }: {
    hovered: boolean
    isMobile: boolean
  }): CreateCSSProperties => ({
    color: hovered
      ? theme.palette.OverlayColorTextBase
      : theme.palette.ColorTextBase,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: isMobile ? '100px' : '200px',
  }),
  seed: {
    width: '25px',
    textAlign: 'center',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  avatar: {
    opacity: '1 !important',
  },
}))

export interface BracketTeamProps {
  party: BracketTeam
  hovered: boolean
  esportColor: string
}

export function NxBracketTeam({
  party,
  hovered,
  esportColor,
}: BracketTeamProps): React.ReactElement {
  const { dispatch } = useContext(matchContext)
  const isMobile = useBreakpointSm()
  const theme = useTheme()
  const classes = useStyles({
    isWinner: party.isWinner,
    isLoser: party.isLoser,
    isMobile,
    esportColor,
    hovered,
  })
  const checkmark = party.isWinner ? (
    <Smallcheck
      color={
        hovered
          ? theme.palette.OverlayColorIconBase
          : theme.palette.ColorIconSuccess
      }
      data-testid="BracketTeam_checkmark"
      height={24}
      width={24}
    />
  ) : (
    <></>
  )
  const onMouseEnter = (partyId): void => {
    if (dispatch) {
      dispatch({
        type: 'SET_HOVERED_PARTYID',
        payload: {
          partyId,
        },
      })
    }
  }
  const onMouseLeave = (): void => {
    if (dispatch) {
      dispatch({
        type: 'SET_HOVERED_PARTYID',
        payload: null,
      })
    }
  }

  return (
    <Box
      className={classes.container}
      onClick={(): void => {
        handleLinkInNewTab(party.matchLink)
      }}
      onMouseEnter={(): void => {
        if (party.id) {
          onMouseEnter(party.id)
        }
      }}
      onMouseLeave={onMouseLeave}
    >
      <Box mr={2}>
        <NxTypography
          className={clsx(classes.seed, classes.text)}
          variant="body1"
        >
          {party.seed}
        </NxTypography>
      </Box>
      <Box mr={1}>{party.picture}</Box>
      <Box className={classes.nameContainer} flex={1}>
        <NxTypography
          className={classes.text}
          onClick={(): void => {
            if (party.id && !party.isDeleted) {
              handleLinkInNewTab(party.teamLink)
            }
          }}
          variant="body2"
        >
          {party.displayName}
        </NxTypography>
        <NxTypography
          className={classes.text}
          onClick={(): void => {
            if (party.schoolId) {
              handleLinkInNewTab(party.schoolLink)
            }
          }}
          variant="body4"
        >
          {party.schoolName}
        </NxTypography>
      </Box>
      <Box alignItems="center" display="flex" flexDirection="row">
        {checkmark}
        <NxTypography className={classes.text} variant="body2">
          {party.score}
        </NxTypography>
      </Box>
    </Box>
  )
}
