import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'

import { NxTypography } from '@playvs-inc/nexus-components'

import { CssTriangle } from '@plvs/rally/components/triangle/CssTriangle'

export const useStyles = makeStyles((theme) => ({
  matchResultsText: {
    margin: theme.spacing(0, 1),
  },
}))

interface MatchResultsProps {
  leftTeam: {
    score: number | null
    placing: number | null
  }
  rightTeam: {
    score: number | null
    placing: number | null
  }
}

export const MatchResults: React.FC<MatchResultsProps> = ({
  leftTeam,
  rightTeam,
}) => {
  const theme = useTheme()

  const classes = useStyles()

  return (
    <Box
      alignItems="center"
      data-testid="score"
      display="flex"
      whiteSpace="nowrap"
    >
      {leftTeam.placing === 1 && (
        <CssTriangle color={theme.palette.ColorIconSuccess} direction="left" />
      )}
      <NxTypography className={classes.matchResultsText} variant="h4">
        {leftTeam.score} - {rightTeam.score}
      </NxTypography>
      {rightTeam.placing === 1 && (
        <CssTriangle color={theme.palette.ColorIconSuccess} direction="right" />
      )}
    </Box>
  )
}
