import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'

import { NxTypography, NxUserCluster } from '@playvs-inc/nexus-components'

import {
  PlayerReplayUserFragment,
  PlayerReplayStatFragment,
  Maybe,
} from '@plvs/graphql'
import { getSlideComponent, getHasTeam } from '@plvs/utils'
import { RewindAssets } from '../Rewind.helpers'
import { useStyles } from './RewindSlide.styles'
import * as SlideFormats from '../slideFormats'

interface RewindSlideProps {
  data?: PlayerReplayStatFragment
  user?: Maybe<PlayerReplayUserFragment>
}

export const RewindSlide: React.FC<RewindSlideProps> = ({ data, user }) => {
  const styles = useStyles()
  const theme = useTheme()

  const hasTeam = getHasTeam(data)

  const header = (
    <Box
      alignItems="flex-start"
      display="flex"
      justifyContent="space-between"
      p={2}
    >
      <Box>
        <img alt="Your PlayVS Rewind" src={RewindAssets.Logo} />
      </Box>

      <NxUserCluster
        avatarHashId={user?.id ?? ''}
        avatarUrl={user?.avatarUrl ?? ''}
        className={styles.avatar}
      />

      <Box>&nbsp;</Box>
    </Box>
  )

  const SlideComponent = SlideFormats[getSlideComponent(data?.statName)]

  const teamCluster = (
    <Box alignItems="center" display="flex" gridGap={theme.spacing(2)}>
      <NxUserCluster
        avatarHashId={data?.team?.id ?? ''}
        avatarUrl={data?.team?.school?.logoUrl ?? ''}
        className={styles.avatar}
      />

      <Box>
        <NxTypography colorToken="OverlayColorTextBase" variant="body2">
          {data?.team?.name}
        </NxTypography>

        <NxTypography colorToken="OverlayColorTextBase" variant="body2">
          {data?.team?.school?.name}
        </NxTypography>
      </Box>
    </Box>
  )

  const content = (
    <Box
      alignItems="center"
      bgcolor="#2A2525"
      className={styles.content}
      display="flex"
      gridGap={theme.spacing(5)}
      justifyContent="space-between"
      mb={3}
      pr={4.5}
    >
      <SlideComponent data={data} />
    </Box>
  )

  const footer = (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(2)}
    >
      {hasTeam && teamCluster}

      <img alt="Deocrative swish" src={RewindAssets.Swish} />
    </Box>
  )

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {header}

      <Box
        alignItems="center"
        className={styles.nameSpiral}
        display="flex"
        justifyContent="center"
      >
        <NxTypography colorToken="OverlayColorTextBase" variant="h1">
          {user?.name}
        </NxTypography>
      </Box>

      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
        mb={2}
        mt={3}
      >
        {content}

        {footer}
      </Box>
    </Box>
  )
}
