import {
  UserRole as UserRoleGQL,
  EsportPosition,
  TeamRosterQuery,
  Metaseason,
  UserSeasonPass,
  Season,
  Maybe,
  UserRoleStatusName,
} from '@plvs/graphql/generated'
import { ConnectedAccountProviders } from '@plvs/utils'

import { PlayerAccountStatus } from '@plvs/client-data/models/AllTeamsAccountStatus'
import { MinimalPhase, MinimalSeason } from '../league/types'
import {
  RosterCardMenuRowItems,
  RosterCardMenuRowSubItems,
} from '../manage-teams/team/type'
import { Rules } from '../manage-teams/team-management/rosterMenuRequirements'

export type GrantedRosterMenuPermissions = Array<Rules[0]['menuItemName']>

export enum ContentContextPermissions {
  ROSTER_MENU,
  ROSTER_ITEMS_MENU,
  ROSTER_ITEMS_MENU_DISABLED_REMOVAL,
  ROSTER_EMPTY_ITEMS_MENU,
  BENCH_ITEMS_MENU_DISABLED_REMOVAL,
  BENCH_ITEMS_MENU,
  COACH_ITEMS_MENU,
  MULTIPLE_COACH_ITEMS_MENU,
}

interface SchoolRoleStatus {
  schoolRoleStatus: { status?: UserRoleStatusName }[] | null
}

export type RosterUser = {
  id: string
  avatarUrl: string | null
  name: string | null
  lastSeen: string | null
} & {
  directRoles:
    | Pick<UserRole, 'userId' | 'resourceId' | 'resourceType' | 'roleName'>[]
    | null
} & SchoolRoleStatus &
  ConnectedAccountProviders & {
    seasonPasses?: Maybe<
      Array<
        Pick<UserSeasonPass, 'id' | 'metaseasonId' | 'consumedAt' | 'leagueId'>
      >
    >
  }

export type RosterPosition = Pick<
  EsportPosition,
  'id' | 'name' | 'index' | 'colloquial' | 'abbreviation'
>

export type ProviderRosterPlayer = Omit<RosterPlayer, 'userName'>
export type ProviderStarterRosterPlayer = ProviderRosterPlayer & {
  position: RosterPosition
  userName: string | undefined | null
}
export type ProviderBenchRosterPlayer = ProviderRosterPlayer & {
  position: null
  userName: string | undefined | null
}

export type RosterPlayer = {
  isCaptain: boolean
  user: RosterUser | undefined
  userName: string
  providerName: string | undefined
  accountStatus: PlayerAccountStatus | undefined
  providerOnboardUrl?: string
  directRoles: Pick<
    UserRole,
    'userId' | 'resourceId' | 'resourceType' | 'roleName'
  >[]
}

export type StarterRosterPlayer = RosterPlayer & {
  position: RosterPosition
  userName: string
}

export type BenchRosterPlayer = RosterPlayer & {
  position: null
  userName: string
}

export type RosterMember = Omit<RosterUser, 'userProviderAccounts'> & {
  isCaptain: boolean
}

export type TeamOwner = {
  id: string
  name: string | null
  avatarUrl: string | null
  lastSeen: string | null
}

export type TeamCoach = {
  isPhoneNumberVisible?: boolean | null
  id: string
  name: string | null
  firstName: string | null
  lastName: string | null
  lastSeen: string | null
  phone?: string | null
  phoneExt?: string | null
  avatarUrl: string | null
  email: string | null
  additionalInfo?: string | null
  directRoles:
    | Pick<UserRole, 'userId' | 'resourceId' | 'resourceType' | 'roleName'>[]
    | null
}

export type RosterPhase = Omit<
  MinimalPhase,
  | 'format'
  | 'survivalAdvancementPercentage'
  | 'survivalAdvancementCount'
  | 'name'
> & {
  name: string | null
}

export type UserRole = Pick<
  UserRoleGQL,
  'resourceType' | 'resourceId' | 'roleName' | 'userId'
>

export type TeamRoster = TeamRosterQuery['team'] | undefined

export type EnrolledSeason = MinimalSeason &
  Pick<
    Season,
    | 'startsAt'
    | 'endsAt'
    | 'suggestedRegistrationEndsAt'
    | 'playerDeregistrationEndsAt'
  > & {
    metaseason: Pick<
      Metaseason,
      'id' | 'name' | 'isPromoted' | 'startsAt' | 'endsAt'
    > | null
  }

// These are shared properties between two roster menu components.
// If a menu component needs a unique property, either call useRosterContext
// directly or query for the prop within the dialog associated to the menu action.

export type RowMenuProps = {
  member: StarterRosterPlayer | BenchRosterPlayer
  members: (StarterRosterPlayer | BenchRosterPlayer)[]
  getPermissions(
    memberRoles: UserRole[],
    contentContext: ContentContextPermissions,
    isSelf?: boolean
  ): GrantedRosterMenuPermissions
  onMutationSuccess?(): Promise<void>
  teamId: string
  metaseason?: Pick<Metaseason, 'id' | 'endsAt'>
  userId: string
  rosterCardStarterRowActions?: RosterCardMenuRowItems[]
  rosterCardSubRowActions?: RosterCardMenuRowSubItems[]
  hasUnlimitedPass?: boolean
  isAdmin?: boolean
}

export interface MinimalMultiLeague {
  id: string
  name: string
  seasons: { id: string; teamRegistrationEndsAt: string }[]
}

export enum RosterCardTabs {
  Roster = 'roster',
  Bench = 'bench',
  MatchHistory = 'matchHistory',
  Contacts = 'contacts',
  Rivals = 'rivals',
}

export type RosterTeamCoaches = Maybe<
  Array<
    Pick<
      TeamCoach,
      | 'id'
      | 'name'
      | 'firstName'
      | 'lastName'
      | 'lastSeen'
      | 'email'
      | 'isPhoneNumberVisible'
      | 'avatarUrl'
    > &
      Maybe<Pick<TeamCoach, 'phone' | 'phoneExt'>> & {
        directRoles: Maybe<
          Array<
            Pick<
              UserRole,
              'userId' | 'roleName' | 'resourceId' | 'resourceType'
            >
          >
        >
      }
  >
>
