import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { Location, ProductType } from '@plvs/client-data/models'
import React, { useEffect, useState } from 'react'
import {
  NxSelect,
  NxSelectOption,
  NxTextInput,
  NxButton,
} from '@playvs-inc/nexus-components'
import { rallyEnv } from '@plvs/rally/env'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { useAppLocationFn, useProductTypeFn } from '@plvs/client-data/hooks'
import { useApolloProviderWrapperContext } from '@plvs/rally/ApolloProviderWrapper'
import { LocalStorageKey } from '@plvs/const'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  select: {
    height: '54px',
    '& .MuiSelect-select, div': {
      display: 'flex',
      backgroundColor: theme.palette.ColorBackgroundBase,
      color: theme.palette.ColorTextAlt,
    },
    '& .MuiSelect-select:focus, div': {
      backgroundColor: `${theme.palette.ColorBackgroundBase} !important`,
    },
    marginTop: theme.spacing(3),
  },
  submitButton: {
    height: theme.spacing(5.5),
    opacity: 1,
  },
}))

export const EnvironmentFormSchema = yup.object().shape({
  environment: yup.string().url().nullable(),
})

interface EnvironmentForm {
  environment?: string
}

export const UpdateEnvironment: React.FC = () => {
  const productTypeVar = useProductTypeFn()
  const {
    changeProductAPI,
    defaultStagingDevelopURL,
  } = useApolloProviderWrapperContext()
  const [productType, setProductType] = useState<ProductType>(productTypeVar)

  const { enqueueSnackbar } = useSnackbar()

  const appLocation = useAppLocationFn()
  const isCheckpoint = appLocation === Location.Checkpoint
  const isProduction = rallyEnv.API_ENV === 'production'
  const isNotProduction = !isProduction
  const { errors, register, setValue, getValues, handleSubmit } = useForm<{
    environment?: string
  }>({
    resolver: yupResolver<EnvironmentForm>(EnvironmentFormSchema),
    defaultValues: {
      environment: defaultStagingDevelopURL,
    },
  })

  const classes = useStyles()

  const updateProductType = (
    e: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const newProductType = e.target.value as ProductType
    setProductType(newProductType)

    const currentEnv = getValues('environment') || defaultStagingDevelopURL

    if (newProductType === ProductType.Scholastic) {
      const replaceSapiWithApp = currentEnv.replace('sapi', 'app')
      setValue('environment', replaceSapiWithApp)
      changeProductAPI({
        productTypeChangeArg: newProductType,
        environment: replaceSapiWithApp,
      })
    } else {
      const replaceAppWithSapi = currentEnv.replace('app', 'sapi')
      setValue('environment', replaceAppWithSapi)
      changeProductAPI({
        productTypeChangeArg: newProductType,
        environment: replaceAppWithSapi,
      })
    }
  }

  const onSubmit = (data: EnvironmentForm): void => {
    try {
      const currentEnv = data.environment || defaultStagingDevelopURL

      changeProductAPI({
        productTypeChangeArg: productType,
        environment: currentEnv,
      })

      enqueueSnackbar('Environment updated successfully', {
        variant: 'success',
      })
    } catch (error) {
      // This swallows any error related to an invalid environment.
      // The error is thrown in errorLink as a network type error.
    }
  }

  useEffect(() => {
    if (isCheckpoint || !isProduction) {
      const currentEnv = getValues('environment') || defaultStagingDevelopURL

      localStorage.setItem(LocalStorageKey.ProductType, productType)
      localStorage.setItem(LocalStorageKey.Environment, currentEnv)
    }
  }, [])

  useEffect(() => {
    /**
     * product type will always be scholastic in checkpoint on first load
     * this fixes case where someone could have a different product type in local storage
     */
    if (productType !== productTypeVar) {
      setProductType(productTypeVar)
    }
  }, [productTypeVar, productType])

  return (
    <>
      {isCheckpoint && (
        <NxSelect
          className={classes.select}
          data-testid="productTypeSelect"
          defaultValue={productType}
          fullWidth
          onChange={updateProductType}
          value={productType}
        >
          <NxSelectOption value={ProductType.Scholastic}>
            Scholastic
          </NxSelectOption>
          <NxSelectOption value={ProductType.Stadium}>Stadium</NxSelectOption>
        </NxSelect>
      )}

      {isNotProduction && (
        <form name="environment" noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box mt={2}>
            <NxTextInput
              ref={register}
              data-testid="environment"
              defaultValue={defaultStagingDevelopURL}
              fullWidth
              helperText={errors.environment ? 'Please enter a url' : undefined}
              label="Environment"
              name="environment"
              // Setting style inline because className overrides base style
              style={{ height: '54px' }}
              type="url"
              variant={errors.environment ? 'error' : 'default'}
            />
          </Box>
          <Box mt={2}>
            <NxButton
              className={classes.submitButton}
              data-testid="updateEnvironmentButton"
              fullWidth
              label="Update Environment"
              name="environment"
              size="large"
              type="submit"
              variant="secondary"
            />
          </Box>
        </form>
      )}
    </>
  )
}
