import { ChildOnboardPath, Path } from '@plvs/const'
import { AuthorizedContainer } from '@plvs/respawn/features/route'
import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { UserRoleName } from '@plvs/graphql/generated'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { map, prop } from 'ramda'
import { checkIfAllowedByRoles } from '@plvs/utils'
import useBackButton from '@plvs/respawn/init/BrowserBackHook'
import { RequiredRoleForMatchWrapper } from '@plvs/respawn/features/match-lobby/RequiredRoleForMatchRoute'
import {
  OnboardChildDataUsage,
  OnboardChildEmail,
  OnboardChildInfo,
  OnboardChildOver13,
  OnboardChildReview,
} from '@plvs/respawn/features/childOnboard'
import { ParentHome } from '@plvs/respawn/features/parent/ParentHome'
import { FilterCacheProvider } from '@plvs/respawn/containers/filter/FilterCacheProvider'
import { Billing } from '@plvs/rally/features/account/Billing/Billing'
import { Box } from '@material-ui/core'
import { OnboardV2 } from '../containers/onboard/v2/OnboardV2'
import { MoreDetailsPrompt } from '../features/moreDetails/MoreDetailsPrompt'
import { AppRoute } from './app/AppRoute'
import { LeaguePage } from './app/LeaguePage'
import { SchoolPage } from './app/SchoolPage'
import { TeamPage } from './app/TeamPage'
import AuthorizedPage from './AuthorizedPage'
import { ForbiddenPage } from './ForbiddenPage'
import { EmailVerificationErrorPage } from './EmailVerificationErrorPage'
import { ForgotPasswordPage } from './ForgotPasswordPage'
import HealthCheckPage from './HealthCheckPage'
import { LoginPage } from './LoginPage'
import { PasswordResetPage } from './PasswordResetPage'
import { RegistrationPage } from './RegistrationPage'
import { ValuesPage } from './ValuesPage'
import { MatchQueuePage } from './app/match/MatchQueuePage'
import { PracticeDayQueuePage } from './app/match/PracticeDayQueuePage'
import { SlotQueuePage } from './app/match/SlotQueuePage'
import { LeagueDetailsPage } from './app/competitions/LeagueDetailsPage'
import { ProfilePage } from './app/profile/ProfilePage'
import { PreseasonQueuePage } from './app/match/PreseasonQueuePage'
import { ParentSignUpRequestPage } from './ParentSignupRequestPage'
import { ParentSignupPage } from './parentSignup/ParentSignupPage'
import { ParentOnboard } from './parentOnboard/ParentOnboard'
import { ChildDetailsPage } from './app/ChildDetailsPage'
import { LoggedOutWrapper } from '../features/login/LoggedOutRoute'
import { EmbedStandingsPage } from './app/embed/EmbedStandingsPage'
import { EmbedSchedulePage } from './app/embed/EmbedSchedulePage'
import { LogoutPage } from './page/LogoutPage'
import { CompetitionsPage } from './app/competitions/CompetitionsPage'
import { ExploreLeaguesTablePage } from './app/competitions/ExploreLeaguesTablePage'
import { MySchoolPage } from './app/MySchoolPage'
import { ResourcesPage } from './app/ResourcesPage'
import SchedulePage from './app/SchedulePage'
import AccountPage from './app/AccountPage'
import { ReferPage } from './app/ReferPage'
import { GlobalReferPage } from './app/GlobalReferPage'
import { CreateTeamPage } from './app/CreateTeamPage'
import { MatchCheckInsPage } from './app/match/MatchCheckInsPage'
import { QueueLobbyPage } from './app/QueueLobbyPage'
import StandingsPage from './app/StandingsPage'
import { ScrimmagePage } from './app/ScrimmagePage'
import { MatchDisputeViewPage } from './app/match/MatchDisputeViewPage'
import { MatchDisputePage } from './app/match/MatchDisputePage'
import { MatchLobbyPage } from './app/match/MatchLobbyPage'
import { NotFoundPage } from './NotFoundPage'
import { Overview } from '../features/explore/LeagueDetails/Overview'
import { LeagueDetailsStandings } from '../features/explore/LeagueDetails/LeagueDetailsStandings'
import { LeagueDetailsMatches } from '../features/explore/LeagueDetails/LeagueDetailsMatches'
import { LeagueDetailsTeams } from '../features/explore/LeagueDetails/LeagueDetailsTeams'
import { OnboardChildBirthday } from './app/childOnboard/OnboardChildBirthday'
import { OnboardChildEsports } from './app/childOnboard/OnboardChildEsports'
import { OnboardChildSchool } from './app/childOnboard/OnboardChildSchool'
import { AppPage } from './page/AppPage'
import { MySchoolLeagues } from '../containers/filter/league/MySchoolLeagues'
import { Home } from '../features/home/Home'
import { SponsorshipBanner } from '../features/home/SponsorshipBanner'
import { ChildOnboardLayout } from './app/childOnboard/ChildOnboardLayout'
import { ChildLoginEditPage } from './app/ChildLoginEditPage'
import { ChildEditInfoPage } from './app/ChildEditInfoPage'
import { ChildPlatformConnectionsPage } from './app/child/ChildPlatformConnectionsPage'
import { ManageTeamsWrapper } from './app/manage/ManageTeamsWrapper'
import { ManagePlayersPage } from './app/manage/ManagePlayersPage'
import { ManageRoleWrapper } from './app/manage/ManageRoleWrapper'
import { EnrollmentLayout } from './app/manage/EnrollmentLayout'
import { SuccessfulEnrollment } from '../features/enrollment/SuccessfulEnrollment'
import { EnrollmentRoutes } from './app/manage/EnrollmentRoutes'
import { EnrollmentBaseRedirect } from './app/manage/EnrollmentBaseRedirect'
import { EnrollmentWrapper } from './app/manage/EnrollmentWrapper'
import { ManagePlayers } from '../features/coach/players/ManagePlayers'
import { ManageCoaches } from '../features/coach/coaches/ManageCoaches'
import { Awards } from '../features/awards/Awards'
import { ITInfoFormWrapper } from '../features/account/ITInfoFormWrapper'
import SchoolInfoForm from '../features/account/SchoolInfoForm/SchoolInfoForm'
import { SelectedSchoolOverview } from './app/SelectedSchoolPage'
import AccountProfileSettings from '../features/account/AccountProfileSettings'
import { AccountProviders } from '../features/account/connections/AccountProviders'
import { NotificationSettings } from '../features/account/notifications/NotificationSettings'
import { PublicMatchPageLayout } from './app/match/PublicMatchPageLayout'

export const Pages: React.FC = () => {
  useBackButton()
  const { isParent, userRoles: roles } = useUserIdentityFn()
  const flags = useFlags()
  const isParentOn = flags?.coppaSupport && isParent
  const userRoleNames = map(prop('roleName'), roles)

  const canAccessPracticeQueues = checkIfAllowedByRoles({
    userRoleNames,
    requireAnyOf: [UserRoleName.Coach, UserRoleName.Captain],
  })

  return (
    <>
      <Routes>
        <Route
          element={
            <Navigate to={isParentOn ? Path.ParentDashboard : Path.Dashboard} />
          }
          path={Path.Root}
        />

        <Route
          element={<AuthorizedContainer component={<OnboardV2 />} />}
          path={`${Path.SpawnPoint}/*`}
        />
        <Route
          element={<AuthorizedContainer component={<ParentOnboard />} />}
          path={`${Path.ParentSpawnPoint}/*`}
        />
        <Route
          element={<AuthorizedContainer component={<MoreDetailsPrompt />} />}
          path={Path.MoreDetails}
        />
        <Route
          element={
            <AuthorizedContainer
              allowedRoles={[UserRoleName.Coach, UserRoleName.Captain]}
              component={<MatchQueuePage />}
            />
          }
          path={`${Path.MatchQueue}/*`}
        />
        {canAccessPracticeQueues && (
          <>
            <Route
              element={
                <AuthorizedContainer
                  allowedRoles={[UserRoleName.Coach, UserRoleName.Captain]}
                  component={<PracticeDayQueuePage />}
                />
              }
              path={`${Path.PracticeDayQueue}/*`}
            />
            <Route
              element={
                <AuthorizedContainer
                  allowedRoles={[UserRoleName.Coach, UserRoleName.Captain]}
                  component={<SlotQueuePage />}
                />
              }
              path={`${Path.SlotMatchQueue}/*`}
            />
            <Route
              element={
                <AuthorizedContainer
                  allowedRoles={[UserRoleName.Coach, UserRoleName.Captain]}
                  component={<PreseasonQueuePage />}
                />
              }
              path={`${Path.PreseasonMatchQueue}/*`}
            />
          </>
        )}
        {/* Error Pages */}
        <Route
          element={<EmailVerificationErrorPage />}
          path={Path.EmailVerificationError}
        />
      </Routes>

      <ApmRoutes>
        {/* Parent route with shared layout */}
        <Route
          element={<AuthorizedContainer component={<AppRoute />} />}
          path="app/"
        >
          <Route element={<Navigate to="dashboard" />} index />
          <Route
            element={
              <AppPage title="Dashboard">
                <MySchoolLeagues>
                  <SponsorshipBanner />
                  <Home />
                </MySchoolLeagues>
              </AppPage>
            }
            path="dashboard"
          />
          <Route
            element={
              <AuthorizedContainer
                allowedRoles={[UserRoleName.Parent]}
                component={
                  <AppPage title="Parent Dashboard">
                    <ParentHome />
                  </AppPage>
                }
              />
            }
            path="parent-dashboard"
          />
          <Route element={<CompetitionsPage />} path="explore" />
          <Route
            element={<ExploreLeaguesTablePage />}
            path="explore/leagues-table/:competitionGroup/:esportSlug"
          />
          <Route
            element={<LeagueDetailsPage />}
            path="explore/leagues/:publicSlug"
          >
            <Route element={<Overview />} path="overview" />
            <Route element={<LeagueDetailsStandings />} path="standings" />
            <Route element={<LeagueDetailsMatches />} path="matches" />
            <Route element={<LeagueDetailsTeams />} path="teams" />
            <Route element={<Navigate to="overview" />} path="" />
          </Route>
          {/* Child Onboard */}
          <Route
            element={
              <AuthorizedContainer
                allowedRoles={[UserRoleName.Parent]}
                component={<ChildOnboardLayout />}
              />
            }
            path="child/spawn-point"
          >
            <Route
              element={<Navigate to={`${ChildOnboardPath.ChildDataUsage}`} />}
              index
            />
            <Route
              element={<OnboardChildDataUsage />}
              path={`${ChildOnboardPath.ChildDataUsage}`}
            />
            <Route
              element={<OnboardChildBirthday />}
              path={`${ChildOnboardPath.ChildBirthday}`}
            />
            <Route
              element={<OnboardChildEmail />}
              path={`${ChildOnboardPath.ChildEmail}`}
            />
            <Route
              element={<OnboardChildInfo />}
              path={`${ChildOnboardPath.ChildInfo}/:childId?`}
            />
            <Route
              element={<OnboardChildEsports />}
              path={`${ChildOnboardPath.ChildEsportInterests}/:childId?`}
            />
            <Route
              element={<OnboardChildSchool />}
              path={`${ChildOnboardPath.ChildSchool}/:childId?`}
            />
            <Route
              element={<OnboardChildReview />}
              path={`${ChildOnboardPath.ChildReview}/:childId?`}
            />
            <Route
              element={<OnboardChildOver13 />}
              path={`${ChildOnboardPath.ChildOver13}`}
            />
          </Route>
          {/* Child Details */}
          <Route
            element={
              <AuthorizedContainer
                allowedRoles={[UserRoleName.Parent]}
                component={<Outlet />}
              />
            }
            path="child/:userId"
          >
            <Route element={<ChildDetailsPage />} index />
            <Route element={<ChildLoginEditPage />} path="login-settings" />
            <Route element={<ChildEditInfoPage />} path="edit-info" />
            <Route
              element={<ChildPlatformConnectionsPage />}
              path="platform-connections"
            />
          </Route>
          {/* Manage Route */}
          <Route
            element={
              <FilterCacheProvider id={Path.Manage}>
                <Outlet />
              </FilterCacheProvider>
            }
            path="manage"
          >
            <Route element={<Navigate to="teams" />} index />
            <Route element={<ManageTeamsWrapper />} path="teams" />
            <Route
              element={
                <ManageRoleWrapper>
                  <ManagePlayersPage />
                </ManageRoleWrapper>
              }
              path="players"
            />
            <Route
              element={
                <ManageRoleWrapper>
                  <EnrollmentWrapper>
                    <EnrollmentLayout />
                  </EnrollmentWrapper>
                </ManageRoleWrapper>
              }
              path="enrollment/:id"
            >
              <Route element={<EnrollmentBaseRedirect />} index />
              <Route element={<EnrollmentRoutes />} path="teams/*" />
              <Route element={<SuccessfulEnrollment />} path="youre-enrolled" />
              <Route
                element={<SuccessfulEnrollment />}
                path="enrollment-updated"
              />
            </Route>
          </Route>
          <Route element={<MySchoolPage />} path="my-school">
            <Route element={<Navigate to="activity" />} index />
            <Route element={<SelectedSchoolOverview />} path="activity" />
            <Route
              element={
                <AuthorizedContainer
                  allowedRoles={[UserRoleName.Coach]}
                  component={<ManagePlayers />}
                />
              }
              path="manage/players"
            />
            <Route
              element={
                <AuthorizedContainer
                  allowedRoles={[UserRoleName.Coach]}
                  component={<ManageCoaches />}
                />
              }
              path="manage/coaches"
            />
            <Route
              element={
                <AuthorizedContainer
                  allowedRoles={[UserRoleName.Coach]}
                  component={<Awards />}
                />
              }
              path="manage/awards"
            />
            <Route
              element={
                <AuthorizedContainer
                  allowedRoles={[UserRoleName.Coach]}
                  component={<Billing />}
                />
              }
              path="billing"
            />
            <Route
              element={
                <AuthorizedContainer
                  allowedRoles={[UserRoleName.Coach]}
                  component={
                    <Box>
                      <SchoolInfoForm />
                      <Box mt={4}>
                        <ITInfoFormWrapper />
                      </Box>
                    </Box>
                  }
                />
              }
              path="settings"
            />
          </Route>
          <Route element={<ProfilePage />} path="profile/:userId" />
          <Route element={<ResourcesPage />} path="resources" />
          <Route element={<SchedulePage />} path="schedule">
            <Route element={<SchedulePage />} path="upcoming" />
            <Route element={<SchedulePage />} path="past" />
          </Route>
          <Route element={<AccountPage />} path="account">
            <Route element={<AccountProfileSettings />} path="settings" />
            <Route element={<AccountProviders />} path="connections" />
            <Route element={<NotificationSettings />} path="notifications" />
          </Route>
          <Route element={<ReferPage />} path="refer/:invite" />
          <Route element={<GlobalReferPage />} path="refer-a-friend" />

          <Route element={<CreateTeamPage />} path="create-team" />
          <Route element={<MatchCheckInsPage />} path="check-in" />
          <Route element={<MatchCheckInsPage />} path="check-in/:matchId" />
          <Route element={<Outlet />} path="match/:matchId/">
            <Route element={<Navigate to="mission-control" />} index />
            <Route element={<MatchLobbyPage />} path="mission-control" />
            <Route element={<MatchLobbyPage />} path="teams" />
            <Route element={<MatchLobbyPage />} path="match-stats" />

            <Route
              element={
                <RequiredRoleForMatchWrapper
                  requireAnyOf={[UserRoleName.Coach]}
                >
                  <MatchDisputePage />
                </RequiredRoleForMatchWrapper>
              }
              path="dispute"
            />
            <Route
              element={
                <RequiredRoleForMatchWrapper
                  requireAnyOf={[UserRoleName.Coach]}
                >
                  <MatchDisputeViewPage />
                </RequiredRoleForMatchWrapper>
              }
              path="view-dispute"
            />
          </Route>
          <Route
            element={<QueueLobbyPage />}
            path="queue-lobby/:slotId/:teamId"
          />
          <Route element={<StandingsPage />} path="standings" />
          <Route element={<TeamPage />} path="team/:teamId" />
          <Route element={<SchoolPage />} path="school" />
          <Route element={<SchoolPage />} path="school/:slug" />
          <Route element={<LeaguePage />} path="league/:slug" />
          <Route element={<ScrimmagePage />} path="scrimmage">
            <Route element={<ScrimmagePage />} path="create" />
          </Route>
          <Route element={NotFoundPage} />
        </Route>

        {/* Non Nested Public Routes */}

        <Route element={<ValuesPage />} path="/values" />
        <Route element={<HealthCheckPage />} path="/healthcheck" />
        <Route
          element={
            <LoggedOutWrapper>
              <LoginPage />
            </LoggedOutWrapper>
          }
          path={Path.Login}
        />
        <Route element={<LogoutPage />} path="/logout" />
        <Route
          element={
            <LoggedOutWrapper>
              <RegistrationPage />
            </LoggedOutWrapper>
          }
          path={Path.Registration}
        />
        <Route
          element={
            <LoggedOutWrapper>
              <ParentSignUpRequestPage />
            </LoggedOutWrapper>
          }
          path={Path.ParentSignUpRequest}
        />
        <Route
          element={
            <LoggedOutWrapper>
              <ParentSignupPage />
            </LoggedOutWrapper>
          }
          path={Path.ParentSignUp}
        />
        <Route element={<SchoolPage />} path="/org/:slug" />
        <Route element={<TeamPage />} path="/team/:teamId" />
        <Route element={<LeaguePage />} path="/league/:slug" />
        <Route
          element={<LeagueDetailsPage />}
          path="explore/leagues/:publicSlug/"
        >
          <Route element={<Overview />} path="overview" />
          <Route element={<LeagueDetailsStandings />} path="standings" />
          <Route element={<LeagueDetailsMatches />} path="matches" />
          <Route element={<LeagueDetailsTeams />} path="teams" />
          <Route element={<Navigate to="overview" />} path="" />
        </Route>

        <Route element={<PublicMatchPageLayout />} path="match/:matchId/">
          <Route element={<Navigate to="mission-control" />} index />
          <Route element={<MatchLobbyPage />} path="mission-control" />
          <Route element={<MatchLobbyPage />} path="teams" />
          <Route element={<MatchLobbyPage />} path="match-stats" />

          <Route
            element={
              <RequiredRoleForMatchWrapper requireAnyOf={[UserRoleName.Coach]}>
                <MatchDisputePage />
              </RequiredRoleForMatchWrapper>
            }
            path="dispute"
          />
          <Route
            element={
              <RequiredRoleForMatchWrapper requireAnyOf={[UserRoleName.Coach]}>
                <MatchDisputeViewPage />
              </RequiredRoleForMatchWrapper>
            }
            path="view-dispute"
          />
        </Route>

        <Route element={<ProfilePage />} path={`${Path.Profile}/:userId`} />

        <Route element={<EmbedStandingsPage />} path={Path.EmbedStandings} />
        <Route element={<EmbedSchedulePage />} path={Path.EmbedSchedule} />

        <Route
          element={
            <LoggedOutWrapper>
              <ForgotPasswordPage />
            </LoggedOutWrapper>
          }
          path="/forgot-password"
        />
        <Route element={<PasswordResetPage />} path="/password-reset/:token" />
        {/* Require authorization */}
        <Route
          element={<AuthorizedContainer component={<AuthorizedPage />} />}
          path="/authorized"
        />
        {/* 403 Forbidden */}
        <Route element={<ForbiddenPage />} path={Path.Forbidden} />
        <Route element={<ForbiddenPage />} path={Path.Banned} />
      </ApmRoutes>
    </>
  )
}
