import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'

import { NxTypography } from '@playvs-inc/nexus-components'

import { EsportSlug } from '@plvs/graphql'
import { PlayerSelection } from '@plvs/utils'
import { SelectedCharactersSection } from '@plvs/rally/features/match/assistant/steps/smash/components/selectedCharacters/SelectedCharactersSection'
import { useStyles, useVsStyles } from './SelectedCharacters.styles'

interface SelectedCharactersProps {
  homePlayer?: PlayerSelection
  awayPlayer?: PlayerSelection
  hideBlindPick?: boolean
  esportSlug: EsportSlug | undefined
  customCenter?: React.ReactNode
  showOverlayImage?: boolean
}

export const SelectedCharacters: React.FC<SelectedCharactersProps> = ({
  homePlayer,
  awayPlayer,
  hideBlindPick = false,
  esportSlug = EsportSlug.SmashBrosUltimate,
  customCenter,
  showOverlayImage,
}) => {
  const styles = useStyles({ esportSlug })
  const vsStyles = useVsStyles()
  const theme = useTheme()

  const hasHomeDetails =
    homePlayer && Boolean(Object.values(homePlayer).join(''))
  const hasAwayDetails =
    awayPlayer && Boolean(Object.values(awayPlayer).join(''))
  const sharedProps = {
    esportSlug,
    hideBlindPick,
    showOverlayImage,
  }

  return (
    <Box
      alignSelf="center"
      display="flex"
      flex={1}
      gridGap={theme.spacing(1)}
      mb={3}
      width="100%"
    >
      <SelectedCharactersSection
        isHomeTeam
        player={homePlayer}
        {...sharedProps}
      />

      {hideBlindPick && (!hasAwayDetails || !hasHomeDetails) ? (
        <></>
      ) : (
        <Box alignSelf="center" className={styles.cutout}>
          {customCenter || (
            <NxTypography className={vsStyles.vs}>vs</NxTypography>
          )}
        </Box>
      )}

      <SelectedCharactersSection
        isHomeTeam={false}
        player={awayPlayer}
        {...sharedProps}
      />
    </Box>
  )
}
