import { Path } from '@plvs/const'
import { UserRoleName } from '@plvs/graphql/generated'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { checkIfAllowedByRoles } from '@plvs/utils'
import React from 'react'
import { Navigate } from 'react-router'

interface Props {
  children: React.ReactNode
}

export const ManageRoleWrapper: React.FC<Props> = ({ children }) => {
  const { roles, loading: profileLoading } = useProfileContext()

  const userRoleNames = roles.map(({ roleName }) => roleName)

  const isAllowed = checkIfAllowedByRoles({
    userRoleNames,
    requireAnyOf: [UserRoleName.Coach, UserRoleName.Admin],
  })

  return (
    <WaitTillLoaded loading={profileLoading}>
      {isAllowed ? <>{children}</> : <Navigate to={Path.Dashboard} />}
    </WaitTillLoaded>
  )
}
