// Note: Will will be moved to Nexus repo GP-2109
import React, { useMemo } from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { CreateCSSProperties } from '@material-ui/styles'
import { NxRandomAvatars } from '@playvs-inc/nexus-random-avatars'
import { Skeleton } from '@material-ui/lab'
import { useImageLoader } from './utils/useImageLoader'

type NxUserAvatarProps = {
  /**
   * Avatar size.  See Nexus Storybook or Nexus for sizing guide.
   * https://www.figma.com/file/VRRQJxR6xM2A8pAD2r1jfS/NEXUS---UI-Kit?node-id=98%3A94524
   */
  size: NxAvatarSize
  /**
   * A hash ID is the ID used to determine a unique color if no avatar URL is specified.
   * This value should be the ID of the entity in question, such as a UserId or a SchoolId.
   */
  hashId: string
  /**
   * URL of the avatar.
   */
  avatarUrl?: string | null
  /**
   * Name to provide this Avatar for accessibility props
   * like <img alt={altName}>
   */
  altName?: string

  /**
   * If true, it will display a ring color based on user's
   * ID hash color.
   */
  showRing?: boolean

  /**
   * Ring color override.  Used for situations like displaying a
   * user using a team color.
   */
  ringColor?: string | null

  /**
   * If true, it will display a ring when user hovers their
   * mouse over the Avatar.
   */
  showRingOnHover?: boolean

  /**
   * className applied on the root <Box />
   */
  className?: string
}

const ColorDefs = [
  '#FFB938',
  '#AC3EFF',
  '#FF7E70',
  '#109EF0',
  '#005A00',
  '#FF5509',
]

export enum NxAvatarSize {
  XXS = 24,
  XS = 32,
  SM = 40,
  MD = 56,
  LG = 80,
  XL = 128,
  XXL = 150,
}

export const sizeToNxAvatarSize: {
  [key: string]: NxAvatarSize
} = {
  small: NxAvatarSize.SM,
  medium: NxAvatarSize.MD,
  large: NxAvatarSize.LG,
  xlarge: NxAvatarSize.XL,
  xxlarge: NxAvatarSize.XXL,
}

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    borderRadius: '50%',
  },
  ringStyle: ({ ringColor }: { ringColor?: string }): CreateCSSProperties => ({
    borderColor: ringColor,
    borderWidth: '2px',
    borderStyle: 'solid',
    outlineColor: ringColor ?? 'transparent',
    outlineWidth: '2px',
    outlineStyle: 'solid',
  }),
  ringHover: ({ ringColor }: { ringColor?: string }): CreateCSSProperties => ({
    '&:hover': {
      borderColor: 'transparent',
      borderWidth: '2px',
      borderStyle: 'solid',
      outlineColor: ringColor ?? 'transparent',
      outlineWidth: '2px',
      outlineStyle: 'solid',
    },
  }),
  avatarImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  firebobPlaceholder: {
    height: '100%',
    width: '100%',
  },
  withBorder: {},
}))

const computeIdHash = (input: string): number => {
  return (
    input?.split('').reduce((acc, nextChar) => {
      return (acc + nextChar.charCodeAt(0)) % 256
    }, 0) ?? 0
  )
}

const randomAvatars = Object.values(NxRandomAvatars)

export const NxUserAvatar: React.FC<NxUserAvatarProps> = ({
  avatarUrl,
  hashId,
  size,
  altName,
  showRing,
  showRingOnHover,
  ringColor,
  className,
}) => {
  const theme = useTheme()
  const { isLoaded, isError } = useImageLoader(avatarUrl)

  const computedHash = useMemo(() => {
    return computeIdHash(hashId)
  }, [hashId])

  const htmlRingColor = useMemo((): string => {
    if (ringColor) {
      return ringColor
    }
    const colorIndex = computedHash % ColorDefs.length
    return ColorDefs[colorIndex]
  }, [ringColor, computedHash, theme])

  const RandomAvatar = useMemo(() => {
    if (!hashId) {
      return NxRandomAvatars.Firebob1
    }
    return randomAvatars[computedHash % randomAvatars.length]
  }, [computedHash])

  const classes = useStyles({ ringColor: htmlRingColor })

  const htmlSize = size ? size.valueOf() : NxAvatarSize.SM.valueOf()

  // return loading state if hashId not defined.

  if (
    (!avatarUrl && hashId === undefined) ||
    (!!avatarUrl && !isError && !isLoaded)
  ) {
    return <Skeleton height={htmlSize} variant="circle" width={htmlSize} />
  }

  return (
    <Box
      className={clsx(
        classes.container,
        showRing && classes.ringStyle,
        showRingOnHover && classes.ringHover,
        className
      )}
      height={htmlSize}
      width={htmlSize}
    >
      {avatarUrl && isLoaded ? (
        <img alt={altName} className={classes.avatarImage} src={avatarUrl} />
      ) : (
        <Box
          alignItems="center"
          data-testid="NxUserAvatar__NoAvatarContainer"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          position="relative"
        >
          <RandomAvatar
            height={htmlSize}
            viewBox="0 0 150 150"
            width={htmlSize}
          />
        </Box>
      )}
    </Box>
  )
}
