import { SchoolOverview } from '@plvs/rally/features/school/Overview'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import React from 'react'

export const SelectedSchoolOverview: React.FC = () => {
  const { selectedEntityId } = useProfileContext()
  return (
    <SchoolOverview hideHero isPublicPage={false} schoolId={selectedEntityId} />
  )
}
