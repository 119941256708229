import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { Game, GameResult, Maybe } from '@plvs/graphql'
import { Link } from 'react-router-dom'
import { Path } from '@plvs/const'
import { generatePath } from 'react-router'
import { NxTypography, OverlayPill } from '@playvs-inc/nexus-components'
import { GameResultDots } from './GameResultDots'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.common.white,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  checkInText: {
    letterSpacing: '0.06em',
    textTransform: 'capitalize',
    padding: '0px 5px',
  },
  teamPill: {
    backgroundColor: theme.palette.OverlayColorBackgroundInactive,
    textTransform: 'uppercase',
    borderRadius: '100px',
    height: '22px',
    width: '92px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  winnerPill: {
    backgroundColor: theme.palette.OverlayColorBackgroundSuccess,
    textTransform: 'uppercase',
    borderRadius: '100px',
    height: '22px',
    width: '92px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const MobileMatchFaceoffTeam: React.FC<{
  bestOf: number
  games: (Pick<Game, 'id'> & {
    gameResults: Maybe<Pick<GameResult, 'id' | 'placing' | 'teamId'>[]>
  })[]
  size: 'small' | 'medium' | 'large'
  team: {
    teamName: string
    avatarUrl: string
    schoolSlug?: string
    id: string
    schoolName?: string
  }
  disableGameDots?: boolean
  seriesPlacings: (Maybe<number> | undefined)[]
  isHomeTeam: boolean
  isWinningTeam: boolean
}> = ({
  bestOf,
  games,
  size,
  team,
  disableGameDots,
  seriesPlacings,
  isHomeTeam,
  isWinningTeam,
}) => {
  const classes = useStyles()

  const teamTitle = `${isHomeTeam ? 'Home' : 'Away'} Team`

  const teamSchoolSlugLink = team.schoolSlug ? (
    <Link
      className={classes.link}
      to={generatePath(Path.SchoolPage, { slug: team.schoolSlug })}
    >
      <NxTypography colorToken="OverlayColorTextBase" variant="overline">
        {team.schoolSlug}
      </NxTypography>
    </Link>
  ) : null

  return (
    <Box display="flex" flexDirection="row">
      <Box>
        <Box display="flex" flexDirection="row" mb={1}>
          <OverlayPill label={teamTitle} size="small" variant="disabled" />
          {isWinningTeam && (
            <Box ml={2}>
              <OverlayPill label="Winner" size="small" variant="success" />
            </Box>
          )}
        </Box>
        <Box pb={1}>
          <Link className={classes.link} to={`${Path.Team}/${team.id}`}>
            {' '}
            <NxTypography colorToken="OverlayColorTextBase" variant="h3">
              {team.teamName}
            </NxTypography>
          </Link>
        </Box>
        {teamSchoolSlugLink}
        <Box pt={1}>
          {!disableGameDots && (
            <GameResultDots
              bestOf={bestOf}
              games={games}
              seriesPlacings={seriesPlacings}
              size={size === 'large' ? 'medium' : 'small'}
              teamId={team.id}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
