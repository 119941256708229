import { useEffect, useState } from 'react'
import { SubscriptionResult } from '@apollo/client'

import {
  FetchMatchSettleRequestQuery,
  MatchSettleRequestFragment,
  MatchSettleRequestStatus,
  MatchSettleRequestUpdatedSubscription,
  useFetchMatchSettleRequestQuery,
  useMatchSettleRequestSubmittedSubscription,
  useMatchSettleRequestUpdatedSubscription,
} from '@plvs/graphql'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useMatchAssistantContext } from '../useMatchAssistant'

type MatchSettleRequestSubscriptionReturn = {
  matchSettleRequest: MatchSettleRequestFragment | null
  incomingMatchSettleRequest: FetchMatchSettleRequestQuery | undefined
  loadingMatchSettleRequest: boolean
  setIsLoadingMatchSettleRequest: (loading: boolean) => void
  matchSettleRequestUpdated: SubscriptionResult<
    MatchSettleRequestUpdatedSubscription,
    any
  >
}

export function useMatchSettleRequestSubscription(): MatchSettleRequestSubscriptionReturn {
  const { userId } = useUserIdentityFn()
  const { currentStep, match, stepIndex } = useMatchAssistantContext()

  const [
    matchSettleRequest,
    setMatchSettleRequest,
  ] = useState<MatchSettleRequestFragment | null>(null)
  const [loadingMatchSettleRequest, setIsLoadingMatchSettleRequest] = useState<
    boolean
  >(true)

  const { data: incomingMatchSettleRequest } = useFetchMatchSettleRequestQuery({
    variables: {
      input: {
        matchId: match?.id,
        status: MatchSettleRequestStatus.Incomplete,
      },
    },
    skip: !(match?.id || userId),
    fetchPolicy: 'network-only',
  })

  const matchSettleRequestUpdated = useMatchSettleRequestUpdatedSubscription({
    variables: {
      input: {
        matchId: match?.id ?? '',
      },
    },
    skip: !match?.id,
  })

  useMatchSettleRequestSubmittedSubscription({
    variables: {
      input: {
        matchSettleRequestId: matchSettleRequest?.id ?? '',
      },
    },
    skip: !matchSettleRequest?.id,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const returnedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      incomingMatchSettleRequest?.fetchMatchSettleRequest
        ?.matchSettleRequest?.[0]

    if (returnedMatchSettleRequest && currentStep) {
      setMatchSettleRequest(returnedMatchSettleRequest)
    } else {
      setIsLoadingMatchSettleRequest(false)
    }
  }, [incomingMatchSettleRequest?.fetchMatchSettleRequest?.success, stepIndex])

  useEffect(() => {
    const updatedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
        ?.matchSettleRequest

    if (updatedMatchSettleRequest && currentStep) {
      setMatchSettleRequest(updatedMatchSettleRequest ?? null)
    }
  }, [matchSettleRequestUpdated, stepIndex])

  return {
    matchSettleRequest,
    loadingMatchSettleRequest,
    incomingMatchSettleRequest,
    setIsLoadingMatchSettleRequest,
    matchSettleRequestUpdated,
  }
}
