import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { EsportSlug } from '@plvs/graphql/types'
import { CreateCSSProperties } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  text: ({
    shouldUseLightText,
  }: {
    shouldUseLightText: boolean
  }): CreateCSSProperties => ({
    color: shouldUseLightText
      ? theme.palette.OverlayColorTextBase
      : theme.palette.ColorTextAlt2,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  }),
}))

const SUPPORTED_SLUGS = [
  EsportSlug.MarioKart,
  EsportSlug.SmashBrosUltimate,
  EsportSlug.Splatoon,
]

const IMAGE_MAP: Record<string, string> = {
  [EsportSlug.MarioKart]:
    'https://s3.amazonaws.com/assets.playvs.com/esrb/MK_ESRB.svg',
  [EsportSlug.SmashBrosUltimate]:
    'https://s3.amazonaws.com/assets.playvs.com/esrb/Smash_ESRB.svg',
  [EsportSlug.Splatoon]:
    'https://s3.amazonaws.com/assets.playvs.com/esrb/Splatoon_ESRB.svg',
}

interface Props {
  esportSlug: EsportSlug
  mt?: number
  shouldUseLightText?: boolean
  position?: string
  bottom?: number
}

export const EsrbRating: React.FC<Props> = ({
  esportSlug,
  mt,
  shouldUseLightText = false,
  position = 'static',
  bottom,
}) => {
  const classes = useStyles({ shouldUseLightText })

  if (!SUPPORTED_SLUGS.includes(esportSlug)) {
    return null
  }

  return (
    <Box
      alignItems="center"
      bottom={bottom}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mt={mt}
      position={position}
    >
      <NxTypography className={classes.text} variant="body1">
        Nintendo trademarks and copyrights are properties of Nintendo. Licensed
        by Nintendo.
      </NxTypography>
      <img
        alt="esrbRating"
        height="68px"
        src={IMAGE_MAP[esportSlug]}
        width="144px"
      />
    </Box>
  )
}
