import React from 'react'
import clsx from 'clsx'
import { includes } from 'ramda'
import tz from 'dayjs/plugin/timezone'
import { NxTypography } from '@playvs-inc/nexus-components'
import { MatchStatus } from '@plvs/graphql/generated'
import { Box } from '@plvs/respawn/features/layout'
import {
  getInvalidMatchResults,
  INVALID_RESULTS_MATCH_STATUSES,
} from '@plvs/rally/features/explore/LeagueMatches/leagueMatchesHelpers'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { Grid, useTheme } from '@material-ui/core'
import dayjs from '@plvs/respawn/init/dayjs'
import { MappedMatch } from '@plvs/rally/features/match/schedule/schedule'
import { Path } from '@plvs/const'
import { useNavigate } from 'react-router'
import { useNxEsportsContext } from '@playvs-inc/nexus-theme'
import { useStyles } from './MatchRow.styles'

dayjs.extend(tz)

interface MatchRowMobileProps {
  children: React.ReactNode
  className?: string
  match: MappedMatch
  showEsportAccent?: boolean
  actions: React.ReactNode
  isQueueMatch?: boolean
  team1Id?: string
}

export const MatchRowMobile: React.FC<MatchRowMobileProps> = ({
  children,
  className,
  match,
  showEsportAccent = false,
  actions,
  isQueueMatch,
  team1Id,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const isMatchLive = match?.status === MatchStatus.Live

  const esportPalettes = useNxEsportsContext()
  const classes = useStyles({
    isMatchLive,
    showEsportAccent,
    esportSlug: match?.esport?.slug,
    esportPalettes,
  })

  const esportSlug = match?.esport?.slug
  const { name: esportName } = useGeneralEsportAdapter(esportSlug)

  const matchResults = match?.matchResults ?? []

  const hasValidResults =
    matchResults.length >= 2 &&
    !includes(match?.status, INVALID_RESULTS_MATCH_STATUSES)

  const matchTime = dayjs(match?.scheduledStartsAt).format('MMM D, ddd h:mm A')

  const navigateToMatch = (): void => {
    const lobbyUrl = isQueueMatch
      ? `/app/queue-lobby/${match?.slot?.id}/${team1Id}`
      : `${Path.Match}/${match?.id}`

    navigate(lobbyUrl)
  }

  return (
    <Box
      alignItems="center"
      className={clsx(classes.root, className)}
      data-cy="teamMatchRow"
      data-testid="match-row"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(0.5)}
      justifyContent="center"
      onClick={navigateToMatch}
      p={3}
      position="relative"
    >
      <Box position="absolute" right={theme.spacing(1)} top={theme.spacing(2)}>
        {actions}
      </Box>
      {!hasValidResults && getInvalidMatchResults(match?.status)}

      <NxTypography
        className={classes.matchDate}
        data-testid="matchDate"
        variant="body2"
      >
        {matchTime}
      </NxTypography>
      <NxTypography
        className={classes.matchDate}
        colorToken="ColorTextAlt"
        data-testid="esportName"
        variant="body3"
      >
        {esportName}
      </NxTypography>

      <Box mt={1.5}>
        <Grid alignItems="center" container justifyContent="center" spacing={2}>
          {children}
        </Grid>
      </Box>
    </Box>
  )
}
