import React from 'react'
import { LinearProgress, useTheme } from '@material-ui/core'

import { NxTypography } from '@playvs-inc/nexus-components'

import { Box } from '@plvs/respawn/features/layout/Box'
import { useStyles } from './RewindProgressBar.styles'

interface RewindProgressBarProps {
  hasNextPage: boolean
  isLoading: boolean
  slideIndex: number
  slideCount: number
  progress: number
}

export const RewindProgressBar: React.FC<RewindProgressBarProps> = ({
  slideIndex,
  slideCount,
  hasNextPage,
  progress,
  isLoading,
}) => {
  const styles = useStyles()
  const theme = useTheme()

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(2)}
      textAlign="center"
    >
      <NxTypography colorToken="OverlayColorTextBase" variant="overline">
        Rewind {slideIndex + 1} of {slideCount}
      </NxTypography>

      <LinearProgress
        classes={{
          colorPrimary: styles.colorPrimary,
          barColorPrimary: styles.barColorPrimary,
        }}
        color="primary"
        value={hasNextPage ? progress : 100}
        variant={isLoading ? 'indeterminate' : 'determinate'}
      />
    </Box>
  )
}
