import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import {
  TeamMember,
  useRemovePlayerFromTeamMutation,
  useLeaveTeamMutation,
} from '@plvs/graphql'
import { makeStyles } from '@material-ui/core/styles'
import { cleanGraphQLError } from '@plvs/utils'
import { NxButton, NxModal, NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { Banner, BannerType } from '@plvs/respawn/features/banner'

interface Props {
  member: Pick<TeamMember, 'id' | 'name'>
  teamId: string
  metaseasonId?: string
  onMutationSuccess?(): void
  action: 'remove' | 'leave'
  onClose(): void
}

const useStyle = makeStyles((theme) => ({
  subtitle: {
    padding: theme.spacing(2, 0),
  },
}))

export const RemovePlayerFromTeam: React.FC<Props> = ({
  member,
  teamId,
  metaseasonId,
  onMutationSuccess,
  action,
  onClose,
}) => {
  const classes = useStyle()
  const { enqueueSnackbar } = useSnackbar()
  const [errorString, setErrorString] = useState<string>()
  const [open, setOpen] = useState(true)

  const [leaveTeam, { loading: isLeaveTeamMutating }] = useLeaveTeamMutation({
    onCompleted: async () => {
      onMutationSuccess?.()
    },
  })

  const [removeFromTeam, { loading: isRemovePlayerMutating }] =
    useRemovePlayerFromTeamMutation({
      onCompleted: async () => {
        onMutationSuccess?.()
      },
    })

  const subTitle = (
    <NxTypography
      className={classes.subtitle}
      data-testid="DialogHeader_Subtitle"
      variant="body1"
    >
      Are you sure you want to {action === 'remove' ? 'remove' : ''}{' '}
      {action === 'remove' ? <strong>{member.name}?</strong> : 'leave?'}
    </NxTypography>
  )

  const onRemove = async (): Promise<void> => {
    try {
      const success =
        (
          await removeFromTeam({
            variables: {
              playerId: member.id,
              teamId,
              metaseasonId,
            },
          })
        )?.data?.removePlayerFromTeam ?? null

      if (success) {
        setOpen(false)
        onClose()
        enqueueSnackbar('Player removed from team.', {
          variant: 'warning',
        })
      } else {
        throw new Error()
      }
    } catch (err: any) {
      setErrorString(
        cleanGraphQLError(err.message ?? 'Cannot remove player from team.')
      )
    }
  }

  const onLeave = async (): Promise<void> => {
    try {
      const success =
        (
          await leaveTeam({
            variables: {
              teamId,
            },
          })
        )?.data?.leaveTeam?.success ?? false

      if (success) {
        setOpen(false)
        enqueueSnackbar('You  have left the team.', {
          variant: 'warning',
        })
      } else throw new Error()
    } catch (e: any) {
      setErrorString('Could not leave the team.')
    }
  }

  const submit = (): void => {
    if (action === 'remove') {
      onRemove()
    } else {
      onLeave()
    }
  }

  return (
    <NxModal
      actions={
        <>
          <NxButton
            label="Cancel"
            onClick={(): void => {
              setOpen(false)
              onClose()
            }}
            variant="text"
          />
          <NxButton
            disabled={isRemovePlayerMutating || isLeaveTeamMutating}
            label={action === 'remove' ? 'Yes, Remove' : 'Yes, Leave'}
            onClick={submit}
            variant="primary"
          />
        </>
      }
      onClose={(): void => {
        setOpen(false)
        onClose()
      }}
      open={open}
      size="small"
      subtitle={subTitle}
      title="Remove a Player Confirmation"
    >
      <>
        {errorString && (
          <Box py={2}>
            <Banner
              subtitle={errorString}
              title="Unable to change positions"
              type={BannerType.Error}
            />
          </Box>
        )}
      </>
    </NxModal>
  )
}
