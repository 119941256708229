import React, { useState } from 'react'
import { SelectedTeam } from '@plvs/rally/components/search/TeamSearch/types'
import { StringParam, useQueryParams } from 'use-query-params'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { useParams } from 'react-router'
import { PhaseFilterType, SlotFilterType } from '../LeagueDetails.types'

interface LeagueDetailsContext {
  phase: PhaseFilterType | undefined
  setPhase: React.Dispatch<React.SetStateAction<PhaseFilterType | undefined>>
  team: SelectedTeam | null
  setTeam: React.Dispatch<React.SetStateAction<SelectedTeam | null>>
  slotFilter: SlotFilterType | undefined
  setSlotFilter: React.Dispatch<
    React.SetStateAction<SlotFilterType | undefined>
  >
  metaseasonId: string
  publicSlug: string | undefined
}

export const leagueDetailsContext = React.createContext<LeagueDetailsContext>({
  phase: undefined,
  setPhase: () => {},
  team: null,
  setTeam: () => {},
  slotFilter: undefined,
  setSlotFilter: () => {},
  metaseasonId: '',
  publicSlug: undefined,
})

export function useLeagueDetails(): LeagueDetailsContext {
  return React.useContext(leagueDetailsContext)
}

export const LeagueDetailsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [phase, setPhase] = useState<PhaseFilterType | undefined>()
  const [team, setTeam] = useState<SelectedTeam | null>(null)
  const [slotFilter, setSlotFilter] = useState<SlotFilterType | undefined>()

  const [queryFilters] = useQueryParams({
    queryMetaseasonId: StringParam,
  })

  const { publicSlug } = useParams<{
    publicSlug: string
  }>()

  const { metaseason } = useSchoolLeagueInfoContext()

  const { queryMetaseasonId } = queryFilters
  const metaseasonId = queryMetaseasonId ?? metaseason?.id ?? ''

  return (
    <leagueDetailsContext.Provider
      value={{
        phase,
        setPhase,
        team,
        setTeam,
        slotFilter,
        setSlotFilter,
        metaseasonId,
        publicSlug,
      }}
    >
      {children}
    </leagueDetailsContext.Provider>
  )
}
