// This component is a wrapper around a component hosted by Plasmic.
// Cannot be effectively unit tested.
/* istanbul ignore file */
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import {
  Grid,
  GridSpacing,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core'
import * as analytics from '@plvs/respawn/features/analytics'
import {
  NxTypography,
  NxButton,
  NxTextInput,
} from '@playvs-inc/nexus-components'

import {
  yupEmailRequired,
  yupFirstNameRequired,
  yupLastNameRequired,
  yupPhoneNumber,
  yupString,
  userRoleNamesToUserType,
  formErrorToString,
} from '@plvs/utils'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import { highSchoolRolesOptions, stateNames } from '@plvs/const'
import {
  CompetitionGroup,
  refetchGetReferralSchoolsQuery,
  ResourceType,
  useCreateUserLeadMutation,
  useReferByEmailMutation,
  UserLeadInput,
  UserRoleName,
} from '@plvs/graphql/generated'
import { CreateCSSProperties } from '@material-ui/styles'

import clsx from 'clsx'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { CoachSchoolLookUp } from './CoachSchoolLookUp'
import { CoachLeadStateSelectorComponent } from './CoachLeadStateSelectorComponent'

interface StyleProps {
  isBorderless?: boolean
  isModalView?: boolean
  hideModalPadding?: boolean
  hasSideView?: boolean
  fullWidthSubmitButton?: boolean
}

const getSubmitButtonWidth = (
  isModalView?: boolean,
  fullWidthSubmitButton?: boolean
): string => {
  if (fullWidthSubmitButton) {
    return '100%'
  }

  return isModalView ? 'max-content' : '100%'
}

const useStyles = makeStyles((theme) => ({
  coachLeadContainer: ({
    isBorderless,
    isModalView,
  }: StyleProps): CreateCSSProperties => ({
    backgroundColor: isBorderless ? 'unset' : theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: isModalView ? 0 : theme.spacing(5),
    boxShadow: isBorderless ? 'unset' : theme.mixins.boxShadow.elevation2,
  }),
  inputContainer: ({ hasSideView }: StyleProps): CreateCSSProperties => ({
    [theme.breakpoints.up('lg')]: {
      padding: hasSideView
        ? `0 ${theme.spacing(3)}px 0 ${theme.spacing(12.5)}px`
        : `0 ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  }),
  inputModalContainer: ({
    hideModalPadding,
  }: StyleProps): CreateCSSProperties => ({
    padding: hideModalPadding ? 0 : '0 1rem 0 1rem',
  }),
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  roleSelector: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    border: `1px solid ${theme.palette.BorderLight}`,
    borderRadius: theme.shape.borderRadius,
  },
  helperText: {
    alignSelf: 'flex-start',
    color: theme.palette.ColorTextAlt2,
  },
  inputField: {
    height: '56px',
    width: '100%',
    fontSize: '1em',
    marginBottom: 'unset',
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: theme.spacing(2.5, 2, 2),
    backgroundColor: theme.palette.ColorBackgroundBase,
    color: theme.palette.ColorTextBase,
    borderColor: theme.palette.BorderMedium,
    borderRadius: theme.shape.borderRadius,
    outlineColor: theme.palette.BorderMedium,
    outlineWidth: '1px',
    // handles outline for hover and focused state.
    '&:active': {
      borderWidth: '1px',
      borderColor: theme.palette.ColorBackgroundInvert,
    },
    '&:hover': {
      borderWidth: '1px',
      borderColor: theme.palette.ColorBackgroundInvert,
    },
    '&:focus-visible': {
      outlineStyle: 'initial',
    },
    '&::placeholder': {
      color: theme.palette.ColorTextAlt2,
    },
    '&:-ms-input-placeholder': {
      color: theme.palette.ColorTextAlt2,
    },
    '&::-ms-input-placeholder': {
      color: theme.palette.ColorTextAlt2,
    },
    transition: 'all 0.3s ease-in-out',
    // Handles pushing input text down when text is entered.
    '&:placeholder-shown': {
      paddingTop: theme.spacing(2),
    },
  },
  errorInputItem: {
    paddingBottom: `${theme.spacing(1.5)}px !important`,
  },
  inputItem: {
    '& > div': {
      marginBottom: 'unset !important',
    },
  },
  buttonContainer: ({ isModalView }: StyleProps): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: isModalView ? 'row-reverse' : 'column',
    width: '100%',
  }),
  submitButton: ({
    isModalView,
    fullWidthSubmitButton,
  }: StyleProps): CreateCSSProperties => ({
    width: getSubmitButtonWidth(isModalView, fullWidthSubmitButton),
    alignSelf: isModalView ? 'flex-end' : 'unset',
  }),
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  menuItem: {
    color: theme.palette.ColorTextBase,
  },
}))

export type SchoolPropType = {
  id?: string
  name?: string
  state?: string | unknown
}

export type CoachLeadInputs = {
  email: string
  firstName: string
  lastName: string
  schoolRoleName: string
  schoolName: string
  phone: string
}

export interface SelectedSchoolType {
  [x: string]: any
  name?: string | null
  schoolId?: string | null
  city?: string | null
  state?: string | null
  logoUrl?: string | null
}

const CoachLeadForStudentsDetails = yup.object().shape({
  email: yupEmailRequired,
  firstName: yupFirstNameRequired,
  lastName: yupLastNameRequired,
  schoolName: yupString,
  schoolRoleName: yupString,
})

const CoachLeadForFacultyDetails = yup.object().shape({
  email: yupEmailRequired,
  firstName: yupFirstNameRequired,
  lastName: yupLastNameRequired,
  phone: yupPhoneNumber,
  schoolName: yupString,
  schoolRoleName: yupString,
})

export const CoachLeadComponent: React.FC<{
  school?: SchoolPropType | null
  sideView?: React.ReactElement
  title?: React.ReactElement
  isFaculty: boolean
  source: string
  isBorderless?: boolean
  formFooter?: React.ReactElement
  isModalView?: boolean
  hideModalPadding?: boolean
  setAvailableSchool?: boolean
  setAvailableState?: boolean
  showBackButton?: boolean
  onBackButtonClick?(): void
  gridSpacing?: GridSpacing
  className?: string
  fullWidthSubmitButton?: boolean
}> = ({
  school,
  sideView,
  title,
  isFaculty,
  source,
  isBorderless,
  formFooter,
  isModalView,
  hideModalPadding,
  setAvailableSchool,
  setAvailableState = true,
  showBackButton,
  onBackButtonClick,
  gridSpacing = 2,
  className,
  fullWidthSubmitButton = false,
}) => {
  const isMobile = useBreakpointSm()
  const flags = useFlags()
  const { id: orgId } = useSelectedOrganizationFn()

  const defaultSchoolState = Object.entries(stateNames).find(
    (state) => state[0] === school?.state
  )
  const coachLeadDetails = isFaculty
    ? CoachLeadForFacultyDetails
    : CoachLeadForStudentsDetails
  const { enqueueSnackbar } = useSnackbar()
  const [selectedSchool, setSelectedSchool] = useState<
    SelectedSchoolType | null | undefined
  >({ schoolId: school?.id, name: school?.name } || null)
  const [selectedAmericanState, setSelectedAmericanState] = useState<
    string | ''
  >((setAvailableState && defaultSchoolState && defaultSchoolState[0]) || '')
  const [hasFilledRequiredFields, setHasFilledRequiredFields] = useState<
    boolean
  >(false)
  const hasSideView = Boolean(sideView)

  const {
    handleSubmit,
    register,
    formState,
    getValues,
    setValue,
    reset,
    control,
    errors,
  } = useForm<CoachLeadInputs>({
    resolver: yupResolver<CoachLeadInputs>(coachLeadDetails),
    mode: 'onBlur',
  })

  const classes = useStyles({
    isBorderless,
    hasSideView,
    isModalView,
    hideModalPadding,
    fullWidthSubmitButton,
  })
  const [
    submitUserLead,
    { loading: isSubmitLoading },
  ] = useCreateUserLeadMutation()

  const [referByEmail] = useReferByEmailMutation()

  const handleChange = (): void => {
    let filledReqFields = false

    const values = getValues()

    filledReqFields =
      !!values.email &&
      !!values.firstName &&
      !!values.lastName &&
      !!selectedSchool?.schoolId

    setHasFilledRequiredFields(filledReqFields)
  }

  const onSendInvite = async (email: string): Promise<void> => {
    await referByEmail({
      variables: {
        input: {
          resourceId: selectedSchool?.schoolId ?? '',
          resourceType: ResourceType.System,
          email,
          roleName: UserRoleName.User,
          source,
          competitionGroup: CompetitionGroup.HighSchool,
        },
      },
    })

    analytics.inviteByEmail({
      inviteeEmails: [email],
      inviteeUserType: userRoleNamesToUserType([UserRoleName.User]),
      myUserRoles: isFaculty
        ? [{ roleName: UserRoleName.Fac }]
        : [{ roleName: UserRoleName.Student }],
    })
  }

  const handleCoachLeadSubmit = handleSubmit(
    async (input: CoachLeadInputs): Promise<void> => {
      const leadInput = {
        firstName: input.firstName,
        lastName: input.lastName,
        email: input.email,
        schoolRoleName: input.schoolRoleName ?? '',
        source,
        schoolId: selectedSchool?.schoolId ?? '',
      }

      const refetchQueries =
        flags.schoolBounties && !!orgId
          ? [refetchGetReferralSchoolsQuery({ limit: 12 })]
          : []

      try {
        await submitUserLead({
          variables: {
            input: leadInput as UserLeadInput,
          },
          awaitRefetchQueries: !!flags.schoolBounties,
          refetchQueries,
          errorPolicy: 'ignore',
        })
        const snackbarMessage = 'Referral info successfully sent!'
        enqueueSnackbar(snackbarMessage, {
          variant: 'success',
        })
        reset()

        if (!setAvailableSchool) {
          setSelectedSchool(null)
        }
        if (setAvailableState && defaultSchoolState && defaultSchoolState[0]) {
          setSelectedAmericanState(defaultSchoolState[0])
        }
        if (input.email) {
          onSendInvite(input.email)
        }

        if (isModalView && onBackButtonClick) {
          onBackButtonClick()
        }
      } catch (e: any) {
        const snackbarMessage = 'Something went wrong. Please try again'
        enqueueSnackbar(snackbarMessage, {
          variant: 'error',
        })
      }
    }
  )

  const handleStateSelected = (state: string): void => {
    if (state !== selectedAmericanState) {
      setSelectedAmericanState(state)
      setSelectedSchool(null)
    }
  }

  useEffect(() => {
    handleChange()
  }, [selectedSchool])

  useEffect(() => {
    if ((school && !isFaculty) || setAvailableSchool) {
      setSelectedSchool({
        name: school?.name,
        schoolId: school?.id,
      })
    }
  }, [school])

  return (
    <Grid className={clsx(classes.coachLeadContainer, className)} container>
      {sideView && (
        <Grid id="CoachLeadComponent" item md={isModalView ? 12 : 5} xs={12}>
          {sideView}
        </Grid>
      )}
      <Grid
        className={
          isModalView ? classes.inputModalContainer : classes.inputContainer
        }
        item
        md={isModalView ? 12 : 7}
        xs={12}
      >
        {!isMobile && title && (
          <Box mb={3}>
            <NxTypography variant="h3">{title}</NxTypography>
          </Box>
        )}
        <form
          className={classes.form}
          noValidate
          onChange={handleChange}
          onSubmit={handleCoachLeadSubmit}
        >
          <Grid container spacing={gridSpacing}>
            <Grid
              className={clsx(
                classes.inputItem,
                !!errors.firstName && classes.errorInputItem
              )}
              item
              md={6}
              xs={12}
            >
              <NxTextInput
                ref={register}
                className={classes.inputField}
                fullWidth
                helperText={formErrorToString(errors.firstName)}
                label="First Name*"
                name="firstName"
                variant={errors.firstName ? 'error' : 'default'}
              />
            </Grid>
            <Grid
              className={clsx(
                classes.inputItem,
                !!errors.lastName && classes.errorInputItem
              )}
              item
              md={6}
              xs={12}
            >
              <NxTextInput
                ref={register}
                className={classes.inputField}
                fullWidth
                helperText={formErrorToString(errors.lastName)}
                label="Last Name*"
                name="lastName"
                variant={errors.lastName ? 'error' : 'default'}
              />
            </Grid>
            <Grid
              className={clsx(
                classes.inputItem,
                !!errors.email && classes.errorInputItem
              )}
              item
              md={isModalView && isFaculty ? 12 : 6}
              xs={12}
            >
              <NxTextInput
                ref={register}
                className={classes.inputField}
                fullWidth
                helperText={formErrorToString(errors.email)}
                label="Email*"
                name="email"
                variant={errors.email ? 'error' : 'default'}
              />
            </Grid>
            {isFaculty && (
              <Grid
                className={clsx(
                  classes.inputItem,
                  !!errors.phone && classes.errorInputItem
                )}
                item
                md={6}
                xs={12}
              >
                <NxTextInput
                  ref={register}
                  className={classes.inputField}
                  fullWidth
                  helperText={formErrorToString(errors.phone)}
                  label="Phone Number"
                  name="phone"
                  variant={errors.phone ? 'error' : 'default'}
                />
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <CoachLeadStateSelectorComponent
                selectedAmericanState={selectedAmericanState}
                setAmericanState={handleStateSelected}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <CoachSchoolLookUp
                label="School Name*"
                register={register}
                selectedSchool={selectedSchool}
                setSelectedSchool={setSelectedSchool}
                state={selectedAmericanState}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name="schoolRoleName"
                render={(field): any => (
                  <TextField
                    defaultValue=""
                    error={!!errors?.schoolRoleName}
                    fullWidth
                    helperText={formErrorToString(errors?.schoolRoleName)}
                    inputProps={{ className: classes.roleSelector }}
                    inputRef={register}
                    label="Role/Job Title"
                    name={field.name}
                    onChange={(e): void => {
                      setValue('schoolRoleName', e.target.value)
                    }}
                    select
                    variant="filled"
                  >
                    {highSchoolRolesOptions.map((schoolRoleName) => {
                      return (
                        <MenuItem
                          key={schoolRoleName.value}
                          className={classes.menuItem}
                          value={schoolRoleName.value}
                        >
                          {schoolRoleName.label}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                )}
              />
              {/* 
              Will switch to this once autocomplete dropdown selection is create in nexus GP-3135
              <NxSelect
                style={{ height: '54px' }}
                // eslint-disable-next-line @typescript-eslint/ban-types
                {...((register('schoolRoleName') as unknown) as Object)}
                defaultValue={getValues().schoolRoleName ?? ''}
                fullWidth
                label="Role/Job Title"
                name="schoolRoleName"
                onChange={(e): void => {
                  setValue('schoolRoleName', e.currentTarget.value)
                }}
              >
                {highSchoolRolesOptions.map((schoolRoleName) => (
                  <NxSelectOption
                    key={schoolRoleName.value}
                    value={schoolRoleName.value}
                  >
                    {schoolRoleName.label}
                  </NxSelectOption>
                ))}
              </NxSelect> */}
            </Grid>
          </Grid>
          <NxTypography className={classes.helperText} variant="body5">
            *Indicates a required field
          </NxTypography>
          <Box className={classes.buttonContainer} mt={isModalView ? 2 : 3}>
            <NxButton
              className={classes.submitButton}
              disabled={
                !hasFilledRequiredFields ||
                formState.isSubmitting ||
                isSubmitLoading
              }
              label="Submit"
              onClick={handleCoachLeadSubmit}
              variant="primary"
            />
            {showBackButton && (
              <NxButton
                className={classes.cancelButton}
                label="Back"
                onClick={onBackButtonClick}
                variant="text"
              />
            )}
            {formFooter}
          </Box>
        </form>
      </Grid>
    </Grid>
  )
}
