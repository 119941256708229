import React from 'react'
import { FixedSizeList as VirtualList } from 'react-window'

import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { MappedMatch } from '../../match/schedule/schedule'
import { LeagueMatchRow } from './LeagueMatchRow'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  viewAll: {
    marginLeft: 'auto',
  },
  row: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  matches: MappedMatch[]
  title: string
}

export const LeagueMatchesSection: React.FC<Props> = ({ matches, title }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box alignItems="center" display="flex" mb={2}>
        <NxTypography variant="h4">{title}</NxTypography>
      </Box>

      <VirtualList height={600} itemCount={matches.length} itemSize={110}>
        {({ index, style }): React.ReactElement => {
          const match = matches[index]

          return (
            <div key={match.id} style={style}>
              <LeagueMatchRow match={match} />
            </div>
          )
        }}
      </VirtualList>
    </div>
  )
}
