import React from 'react'

import { NxButton } from '@playvs-inc/nexus-components'

interface SettleMatchButtonProps {
  disabled?: boolean
  onClickSettleMatch(): void
}

export const SettleMatchButton: React.FC<SettleMatchButtonProps> = ({
  disabled,
  onClickSettleMatch,
}) => {
  return (
    <NxButton
      data-cy="settleMatchButton"
      data-testid="settle-match-button"
      disabled={disabled}
      label="Settle Match"
      onClick={onClickSettleMatch}
      variant="primary"
    />
  )
}
