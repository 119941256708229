import { Path } from '@plvs/const'
import { Box } from '@plvs/respawn/features/layout'
import { TaskState } from '@plvs/graphql/generated'
import { NxTypography, NxTaskCard } from '@playvs-inc/nexus-components'
import React from 'react'

import { useNavigate } from 'react-router-dom'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { TaskCtaButton } from '../components/TaskCtaButton'

export const FillRosterTaskCard: React.FC<{
  task: { state: string; id: string; type: string }
  isMobile: boolean
  index: number
}> = ({ task, isMobile, index }) => {
  const navigate = useNavigate()
  const handleManageTeams = async (): Promise<void> => {
    navigate(Path.ManageTeams)
  }

  return (
    <NxTaskCard
      taskBody={
        <>
          <Box mb={1}>
            <NxTypography data-cy="download-coach-handbook" variant="h4">
              Fill Team Roster
            </NxTypography>
          </Box>
          <NxTypography variant={isMobile ? 'body3' : 'subtitle1'}>
            Build your team with verified players
          </NxTypography>
        </>
      }
      taskCta={
        <TaskCtaButton
          buttonText="Manage Teams"
          completed={task.state === TaskState.Completed}
          disabled={task.state === TaskState.Disabled}
          onClick={handleManageTeams}
          task={task}
          tooltipText={
            task.state === TaskState.Disabled
              ? 'You must be verified and your school must have a team created before you can fill the roster.'
              : undefined
          }
        />
      }
      taskHeader={
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <NxSpot
            domain="stadium"
            height={64}
            size="small"
            variant="primary"
            width={64}
          />
          <NxTypography variant="overline">TASK {index}</NxTypography>
        </Box>
      }
    />
  )
}
