import { Menu, MenuItem, makeStyles } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { Moremenu } from '@playvs-inc/nexus-icons'
import { NxIconButton, NxTypography } from '@playvs-inc/nexus-components'
import React from 'react'
import { useVerifyDeniedPlayerMutation } from '@plvs/graphql'
import { evictPlayersTableCache } from '@plvs/rally/features/coach/players/helpers'
import { Box } from '@plvs/respawn/features/layout/Box'

export interface UserDeniedPlayerMoreProps {
  id: string
  schoolId: string
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginLeft: theme.spacing(-1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 5,
  },
}))

export const UserDeniedPlayerMore = ({
  id,
  schoolId,
}: UserDeniedPlayerMoreProps): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  // mutation
  const [
    verifyDeniedPlayer,
    { loading: verifyingDeniedPlayer },
  ] = useVerifyDeniedPlayerMutation({
    update: evictPlayersTableCache,
  })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const classes = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const onVerify = async (): Promise<void> => {
    try {
      const success = (
        (await verifyDeniedPlayer({
          variables: {
            userId: id,
            resourceId: schoolId,
          },
        })) || {}
      )?.data?.verifyDeniedPlayer?.success

      if (success) {
        // TODO: Analytics.
        enqueueSnackbar('Success! Player verified.', {
          variant: 'success',
        })
      } else throw new Error()
    } catch (e: any) {
      handleClose()
      enqueueSnackbar('Could not verify player.', {
        variant: 'error',
      })
    }
  }

  return (
    <Box>
      <NxIconButton
        aria-label="verify-archived-player-button"
        className={classes.iconButton}
        data-cy="denied-player-more-button"
        icon={<Moremenu />}
        label=""
        onClick={handleClick}
        variant="text"
      />
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        <MenuItem
          data-cy="denied-player-more-verify-button"
          disabled={verifyingDeniedPlayer}
          onClick={onVerify}
        >
          <NxTypography variant="body1">Verify Player</NxTypography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
