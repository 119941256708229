import React, { useEffect } from 'react'

import { NxBreadcrumbs, NxTypography } from '@playvs-inc/nexus-components'
import { useNavigate, useParams } from 'react-router-dom'
import { Path } from '@plvs/const'
import {
  Provider,
  useGetChildPlatformConnectionsQuery,
} from '@plvs/graphql/generated'
import {
  gameConnectionComponentMap,
  platformConnectionComponentMap,
} from '@plvs/rally/features/account/connections/connections'
import { Divider, makeStyles } from '@material-ui/core'
import {
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import {
  useAccountRenderControllerContext,
  useAccountRenderControllerState,
} from '@plvs/respawn/renderController/account/AccountRenderControllerProvider'
import { useProductTypeFn } from '@plvs/client-data/hooks'

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius * 2.5,
  },
  divider: {
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
    boxShadow: theme.mixins.divider.bottom,
    backgroundColor: 'transparent',
  },
}))

type Connection = {
  name: string
  Component: typeof React.Component
}

const EXCLUDED_PROVIDERS = [Provider.Vesl]

export const ChildPlatformConnections: React.FC = () => {
  const navigate = useNavigate()

  const { userId } = useParams<{ userId: string }>()

  const classes = useStyles()

  const product = useProductTypeFn()

  const {
    providers: { getProvidersComponentsToRender },
  } = useAccountRenderControllerContext()
  const providersRenderController = getProvidersComponentsToRender({
    productType: product,
  })
  const {
    getAccountRenderControllerState,
    setAccountRenderControllerStateFn,
  } = useAccountRenderControllerState()

  useEffect(
    function updateRenderController() {
      const currentState = getAccountRenderControllerState()
      setAccountRenderControllerStateFn({
        providers: {
          ...currentState.providers,
          ...providersRenderController,
        },
      })
    },
    [...Object.values(providersRenderController.shouldRender)]
  )

  const {
    providers: { shouldRender },
  } = getAccountRenderControllerState()

  const { data, error, loading, refetch } = useGetChildPlatformConnectionsQuery(
    {
      variables: { id: userId },
      skip: !userId,
    }
  )

  const providerAccounts = data?.user?.userProviderAccounts ?? []

  const mapConnectionToComponent = (
    { name, Component }: Connection,
    index: number,
    arr: Connection[]
  ): React.ReactElement | null => {
    if (!shouldRender[name] || EXCLUDED_PROVIDERS.includes(name as Provider)) {
      return null
    }

    return (
      <div key={name} data-testid="provider">
        <Component
          key={name}
          apolloError={error}
          checkpoint={false}
          isParent
          name={name}
          providerDetails={providerAccounts.find(
            (account) => account.providerName === name
          )}
          refetchUserProviderAccounts={refetch}
          userId={userId}
        />
        {index < arr.length - 1 && (
          <Divider className={classes.divider} variant="fullWidth" />
        )}
      </div>
    )
  }

  return (
    <PageContentGutter>
      <NxBreadcrumbs
        breadcrumbs={[
          {
            label: 'My Family Group',
            onClick: (): void => navigate(Path.ParentDashboard),
          },
          {
            label: 'Settings for Family Group Member',
            onClick: (): void => navigate(`${Path.App}/child/${userId}`),
          },
          {
            label: 'Edit Platform Connections',
            onClick: (): void => {},
          },
        ]}
        className={classes.breadcrumbs}
      />
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        <div className={classes.cardContent}>
          <NxTypography variant="h2">Platform Connections</NxTypography>
          {gameConnectionComponentMap.map(mapConnectionToComponent)}
          <Divider className={classes.divider} variant="fullWidth" />
          {platformConnectionComponentMap.map(mapConnectionToComponent)}
        </div>
      </WaitTillLoaded>
    </PageContentGutter>
  )
}
