import { NoSsr } from '@material-ui/core'
import { Path } from '@plvs/const'
import {
  Box,
  FullHeightBox,
  Hidden,
  useBreakpointMd,
  useBreakpointSm,
} from '@plvs/respawn/features/layout'
import { InitAppState } from '@plvs/rally/containers/app'
import { useGetMissingFields } from '@plvs/rally/containers/app/fetchers/GetMissingFields'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { NotificationOverlay } from '@plvs/rally/containers/notificationOverlay'
import { RallyAppDrawer } from '@plvs/rally/features/app/drawer'
import React from 'react'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { matchPath, Outlet, useLocation } from 'react-router'
import { AppDrawerToggles } from '@plvs/rally/features/app/drawer/AppDrawerToggles'
import { GlobalChat } from '@plvs/rally/features/app/drawer/globalChat/GlobalChat'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Tour } from '@plvs/graphql/generated'
import { useProductTours } from '@plvs/respawn/features/shepherd/utils/useProductTours'
import { ShepherdStarter } from '@plvs/respawn/features/shepherd/ShepherdStarter'
import { MySchoolLeagues } from '../../containers/filter/league/MySchoolLeagues'
import { MissingFieldsRedirect } from './MissingFieldsRedirect'
import { SchoolSignupRedirect } from './SchoolSignupRedirect'

const ONBOARDING_TOUR_PATHS = [
  Path.App,
  `${Path.App}/`,
  Path.Dashboard,
  `${Path.Dashboard}/`,
] as string[]

export const AppRoute = (): React.ReactElement => {
  const { pathname } = useLocation()
  const isUserOnboarding = !!matchPath(
    {
      path: Path.SpawnPoint,
      end: false,
      caseSensitive: false,
    },
    pathname
  )
  const pathMatchesEnrollment = matchPath(
    {
      path: Path.Enrollment,
      end: false,
      caseSensitive: false,
    },
    pathname
  )
  const pathMatchesCheckIn = matchPath(
    {
      path: Path.MatchCheckIns,
      end: false,
      caseSensitive: false,
    },
    pathname
  )
  const removeFullScreenStyling = Boolean(
    pathMatchesEnrollment || pathMatchesCheckIn
  )

  const location = useLocation()

  // Data hooks
  // CORE-1440: Refactor this in a way that makes it reusable.

  const [missingFields, missingFieldsLoading] = useGetMissingFields()
  const {
    loading: identityLoading,
    orgId: schoolId,
    userName,
    isCoachAtOrg,
    isParent,
  } = useUserIdentityFn()

  const isTablet = useBreakpointMd()
  const isMobile = useBreakpointSm()
  const flags = useFlags()

  const maxScreenWidth =
    isTablet && !isMobile ? 'calc(100vw - 320px)' : '1042px'

  // derived props
  // Loading prop here is very important as we have a lot of conflicting rules for redirect or which
  // routes to display on this page.  It is very important this 'loading' var captures any pending
  // queries.
  const loading = identityLoading || missingFieldsLoading

  const { onboardingTour } = useProductTours()

  React.useEffect(() => {
    const handleEvent = (): void => {
      if (onboardingTour?.isActive()) {
        onboardingTour?.complete()
      }
    }
    window.addEventListener('popstate', handleEvent)
    return (): void => window.removeEventListener('popstate', handleEvent)
  }, [])

  if (loading) {
    return (
      <>
        <InitAppState />
      </>
    )
  }

  // Redirect users who require filling in additional details before using the app.
  if (!loading && !!missingFields && !isUserOnboarding && !identityLoading) {
    return (
      <MissingFieldsRedirect
        isParent={isParent}
        missingFields={missingFields}
      />
    )
  }

  // Redirect users to school signup if they do not belong to a school.
  const shouldRedirectToSchoolSignup =
    !loading && !schoolId && !isUserOnboarding && !identityLoading && !isParent
  if (shouldRedirectToSchoolSignup) {
    return <SchoolSignupRedirect userName={userName} />
  }

  const productTourEnabled =
    flags.productTour &&
    ONBOARDING_TOUR_PATHS.includes(location.pathname) &&
    isCoachAtOrg &&
    onboardingTour

  const isInChildOnboard = pathname.includes(Path.ChildSpawnPoint)

  return (
    <>
      <InitAppState />
      <NotificationOverlay />
      {productTourEnabled && (
        <ShepherdStarter tour={onboardingTour} tourType={Tour.Product} />
      )}
      <Esport data-testid="app-route">
        <MySchoolLeagues>
          <Box
            display="flex"
            justifyContent={removeFullScreenStyling ? undefined : 'center'}
          >
            {!isInChildOnboard && (
              <Hidden smDown>
                <RallyAppDrawer isPublic={false} />
              </Hidden>
            )}

            <FullHeightBox
              flexDirection={removeFullScreenStyling ? 'row' : 'row-reverse'}
              maxWidth={removeFullScreenStyling ? '100%' : maxScreenWidth}
            >
              <Hidden smDown>{!isParent && <AppDrawerToggles />}</Hidden>
              <NoSsr>
                <Outlet />
              </NoSsr>
            </FullHeightBox>
            <Box
              display="flex"
              flexDirection={removeFullScreenStyling ? 'row' : 'row-reverse'}
              maxWidth={removeFullScreenStyling ? '100%' : maxScreenWidth}
              zIndex={5}
            >
              <GlobalChat />
            </Box>
          </Box>
        </MySchoolLeagues>
      </Esport>
    </>
  )
}
