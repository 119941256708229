import React, { useState } from 'react'
import { useSlotExclusionsData } from '@plvs/respawn/containers/enrollment/useSlotExclusions'
import { groupBy } from 'ramda'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { NxButton, NxTypography, NxWidget } from '@playvs-inc/nexus-components'

import { ScheduleBreakWeeksModal } from './ScheduleBreakWeeksModal'

export type ScheduleBreakWeeksWidgetProps = {
  isCoach: boolean
  metaseasonId: string
  metaseasonName: string
  schoolId: string
}

const ScheduleBreakWeeksWidget = ({
  metaseasonId,
  metaseasonName,
  schoolId,
}: ScheduleBreakWeeksWidgetProps): React.ReactElement => {
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)
  const {
    slotExclusionRangeData,
    optionalWeeksCache,
    seasonSlotExclusionRangeData,
    optionalSeasonWeeksCache,
    setOptionalSeasonWeeksCache,
    setOptionalWeeksCache,
    loading,
    updateSlotExclusionSelection,
  } = useSlotExclusionsData({
    metaseasonId,
    schoolId,
  })

  const seasonSlotExclusionRangeDataMapping = groupBy(
    (range) => range.seasonId,
    seasonSlotExclusionRangeData
  )

  const optionalWeeks = Object.values(optionalWeeksCache).flatMap(
    (x) => x.selections
  )
  const canEditDefault =
    (optionalWeeks.some((x) => x.confirmedSelection?.isEditable) ||
      !optionalWeeks.some((x) => x.confirmedSelection)) &&
    slotExclusionRangeData.some((x) =>
      x.configuration.suggestions.some((y) => y.isAvailable)
    )

  const optionalSeasonWeeks = Object.values(optionalSeasonWeeksCache).flatMap(
    (x) => x.selections
  )
  const canEditSeason =
    (optionalSeasonWeeks.some((x) => x.confirmedSelection?.isEditable) ||
      !optionalSeasonWeeks.some((x) => x.confirmedSelection)) &&
    seasonSlotExclusionRangeData.some((x) =>
      x.configuration.suggestions.some((y) => y.isAvailable)
    )

  const canEdit = canEditDefault || canEditSeason

  return (
    <>
      <NxWidget
        actions={
          <NxButton
            disabled={loading || !canEdit}
            fullWidth
            label="Manage Break Week"
            mb={2}
            onClick={handleOpen}
            variant="primary"
          />
        }
        title={`${metaseasonName} Break Week`}
      >
        <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
          <Box>
            {optionalWeeks.some((x) => x.confirmedSelection) ? (
              <Box display="flex" flexDirection="column" mb={2}>
                <NxTypography variant="body2">School Break Week:</NxTypography>
                {Object.keys(optionalWeeksCache).map((key) => {
                  return optionalWeeksCache[key].selections.map(
                    ({ confirmedSelection }) => (
                      <Box key={confirmedSelection?.hash}>
                        <NxTypography variant="body1">
                          {confirmedSelection?.formattedSelection?.split(
                            ': '
                          )[1] ?? 'TBD'}
                        </NxTypography>
                      </Box>
                    )
                  )
                })}
              </Box>
            ) : (
              <></>
            )}
            {optionalSeasonWeeks.some((x) => x.confirmedSelection) &&
            !!Object.keys(optionalSeasonWeeksCache).length ? (
              <Box display="flex" flexDirection="column">
                <NxTypography variant="body2">Custom Break Weeks:</NxTypography>
                {Object.keys(optionalSeasonWeeksCache).map((key) => {
                  const rangeData =
                    seasonSlotExclusionRangeDataMapping[
                      optionalSeasonWeeksCache[key].seasonId || ''
                    ]

                  return optionalSeasonWeeksCache[key].selections.map(
                    ({ confirmedSelection }) => (
                      <Box
                        key={confirmedSelection?.hash}
                        display="flex"
                        flexDirection="column"
                      >
                        <NxTypography variant="body1">
                          {`${rangeData?.[0]?.leagueName}`}
                        </NxTypography>
                        <NxTypography variant="body1">
                          {`${
                            confirmedSelection?.formattedSelection?.split(
                              ': '
                            )[1] ?? 'TBD'
                          }`}
                        </NxTypography>
                      </Box>
                    )
                  )
                })}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </WaitTillLoaded>
      </NxWidget>
      <ScheduleBreakWeeksModal
        handleClose={handleClose}
        handleUpdate={updateSlotExclusionSelection}
        open={open}
        optionalSeasonWeeksCache={optionalSeasonWeeksCache}
        optionalWeeksCache={optionalWeeksCache}
        seasonSlotExclusionRangeData={seasonSlotExclusionRangeData}
        setOptionalSeasonWeeksCache={setOptionalSeasonWeeksCache}
        setOptionalWeeksCache={setOptionalWeeksCache}
        slotExclusionRangeData={slotExclusionRangeData}
      />
    </>
  )
}

export default ScheduleBreakWeeksWidget
