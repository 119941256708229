import React from 'react'

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Link,
  Button,
} from '@material-ui/core'
import { Platform } from '@plvs/graphql'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { RefreshIcon } from '@plvs/rally/components/icon/custom/RefreshIcon'
import { LoadingSpinner, NxTypography } from '@playvs-inc/nexus-components'
import {
  Epicgames,
  Nintendoswitch,
  Playstation,
  Steam,
  Xbox,
} from '@playvs-inc/nexus-icons'
import { Box } from '@plvs/respawn/features/layout/Box'

interface MinConnectedPlatform {
  platform: Platform
  username: string
}

const useStyles = makeStyles((theme) => ({
  platformOperations: {
    marginTop: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  platformSelect: {
    width: '400px',
    marginRight: '32px',
  },
  refreshButton: {
    height: '32px',
    width: '120px',
  },
  refreshIcon: {
    marginRight: '8px',
    display: 'flex',
  },
  banner: {
    marginTop: '40px',
  },
  gutters: {
    '&[role="option"]': {
      '& > div > div#hideActivationInicator': { display: 'flex' },
      '& > div > div > div#hideIcon': { display: 'flex' },
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    textTransform: 'none',
  },
  platformLabel: {
    fontSize: '18px',
  },
  activatedText: {
    marginRight: '12px',
    color: theme.palette.ColorTextBase || theme.palette.text.primary,
  },
  greenDot: {
    height: '6px',
    width: '6px',
    backgroundColor: theme.palette.ColorIconSuccess,
    borderRadius: '50%',
    display: 'inline-block',
  },
  redDot: {
    height: '6px',
    width: '6px',
    backgroundColor: theme.palette.ColorIconError,
    borderRadius: '50%',
    display: 'inline-block',
  },
}))

const ICON_PROPS = {
  width: 24,
  height: 24,
}
const platformOptions: {
  value: Platform
  label: string
  icon: React.ReactElement | React.FC<{ width: number; height: number }>
}[] = [
  {
    value: Platform.EpicGames,
    label: 'Epic Games Account',
    icon: <Epicgames {...ICON_PROPS} />,
  },
  {
    value: Platform.Steam,
    label: 'Steam',
    icon: <Steam {...ICON_PROPS} />,
  },
  {
    value: Platform.XboxOne,
    label: 'Xbox Live',
    icon: <Xbox {...ICON_PROPS} />,
  },
  {
    value: Platform.PlayStation4,
    label: 'PlayStation Network',
    icon: <Playstation {...ICON_PROPS} />,
  },
  {
    value: Platform.NintendoSwitch,
    label: 'Nintendo Switch',
    icon: <Nintendoswitch {...ICON_PROPS} />,
  },
]

interface EpicGamesEditComponentProps {
  userPlatform: Platform | ''
  connectedPlatforms: MinConnectedPlatform[]
  platformRefreshLoading: boolean
  onRefreshClick: () => void
  updatePlatform: (platform: Platform) => void
}

export const EpicGamesEditComponent: React.FC<EpicGamesEditComponentProps> = ({
  userPlatform,
  connectedPlatforms,
  platformRefreshLoading,
  onRefreshClick,
  updatePlatform,
}) => {
  const {
    platformOperations,
    platformSelect,
    refreshButton,
    refreshIcon,
    banner,
    greenDot,
    redDot,
    menuItem,
    platformLabel,
    activatedText,
    gutters,
  } = useStyles()

  return (
    <>
      {!userPlatform && (
        <Box className={banner}>
          <Banner
            subtitle={
              connectedPlatforms.length ? (
                <Box>
                  Rocket League players and spectators must select a platform to
                  receive match invites.
                  <br />
                  Choose one of your activated platforms and connect, or
                  activate another platform at{' '}
                  <Link
                    href="https://www.rocketleague.com/activate/"
                    target="_blank"
                  >
                    rocketleague.com/activate/
                  </Link>
                  .
                </Box>
              ) : (
                <Box>
                  Rocket League players and spectators must select a platform to
                  receive match invites.
                  <br />
                  Go to{' '}
                  <Link
                    href="https://www.rocketleague.com/activate/"
                    target="_blank"
                  >
                    rocketleague.com/activate/
                  </Link>{' '}
                  to activate a platform: Xbox Live, PlayStation Network,
                  Nintendo Switch, or Epic Games Account.
                </Box>
              )
            }
            title="Choose Platform"
            type={BannerType.Warning}
          />
        </Box>
      )}

      <Box className={platformOperations}>
        <Box className={platformSelect}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="select-platform-label">Platform</InputLabel>
            <Select
              data-testid="select-platform"
              fullWidth
              label="Platform"
              labelId="select-platform-label"
              onChange={(
                event: React.ChangeEvent<{
                  name?: string
                  value: unknown
                }>
              ): void => {
                updatePlatform(event.target.value as Platform)
              }}
              value={userPlatform || ''}
            >
              {platformOptions.map((platformOption) => {
                const isConnected = connectedPlatforms
                  .map((plat) => plat.platform)
                  .includes(platformOption.value)
                return (
                  <MenuItem
                    key={platformOption.value}
                    classes={{ gutters }}
                    disabled={!isConnected}
                    value={platformOption.value}
                  >
                    <Box
                      className={menuItem}
                      data-testid={platformOption.value}
                    >
                      <Box alignItems="center" display="flex">
                        <Box display="none" id="hideIcon" mr={1}>
                          {platformOption.icon}
                        </Box>
                        <NxTypography className={platformLabel}>
                          {platformOption.label}
                        </NxTypography>
                      </Box>

                      <Box display="none" id="hideActivationInicator">
                        <Box alignItems="center" display="flex">
                          <NxTypography className={activatedText}>
                            {isConnected ? 'Activated' : 'Not activated'}
                          </NxTypography>
                          <span className={isConnected ? greenDot : redDot} />
                        </Box>
                      </Box>
                    </Box>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        <Button
          className={refreshButton}
          color="secondary"
          disabled={platformRefreshLoading}
          onClick={onRefreshClick}
          variant="contained"
        >
          <span className={refreshIcon}>
            {platformRefreshLoading ? (
              <LoadingSpinner size="small" />
            ) : (
              RefreshIcon
            )}
          </span>
          Refresh
        </Button>
      </Box>
    </>
  )
}
