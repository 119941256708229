import React, { useState, useEffect } from 'react'
import { Button, makeStyles, useTheme } from '@material-ui/core'
import { Participant } from '@twilio/conversations'
import clsx from 'clsx'

import {
  NxTooltip,
  NxTypography,
  NxUserOnlineStatus,
  OnlineStatus as NxOnlineStatus,
  NxFirebobMessage,
  emotesMap,
} from '@playvs-inc/nexus-components'

import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
// eslint-disable-next-line no-restricted-imports
import { ChatRole } from '@plvs/graphql'
import { useGlobalUnreadMessagesVar } from '@plvs/client-data/hooks'
import { useUserOnlineStatusContext } from '@plvs/respawn/features/userOnlineStatus/UserOnlineStatusProvider'

import { LocalStorageKey } from '@plvs/const'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { ParticipantAttributesJSON, getDerivedChatUniqueName } from './utils'
import { AllChatWrapper } from './AllChatWrapper'
import { CoachChatWrapper } from './CoachChatWrapper'
import { useMatchChat } from './hooks/useMatchChat'
import { ChatTab } from './types'

const useStyles = makeStyles((theme) => ({
  chat: {
    flex: 1,
    maxWidth: '100%',

    [theme.breakpoints.up('sm')]: {
      minWidth: 414,
    },
  },
  tab: {
    '&:first-child': {
      borderLeftColor: 'transparent',
      borderTopLeftRadius: theme.mixins.cornerRadius['border-radius-10'],
      borderRightColor: theme.palette.BorderLight,
    },
    '&:hover': {
      backgroundColor: theme.palette.ColorBackgroundAlt2,
    },
    border: `0.5px solid transparent`,
    borderBottomColor: 'transparent',
    borderLeftColor: theme.palette.BorderLight,
    borderRadius: 0,

    color: theme.palette.ColorTextDisabled,
    background: theme.palette.ColorBackgroundAlt,
    padding: theme.spacing(4.4, 3),
    flex: 1,
    transition: 'border-color 0.15s ease-in, color 0.15s ease-in',

    '&:last-child': {
      borderLeftColor: theme.palette.BorderLight,
      borderTopRightRadius: theme.mixins.cornerRadius['border-radius-10'],
    },
  },
  activeTab: {
    borderBottomColor: 'transparent',
    color: theme.palette.ColorTextBase,
    background: theme.palette.ColorBackgroundBase,
    '&:hover': {
      backgroundColor: theme.palette.ColorBackgroundBase,
    },
  },
  playerTab: {
    borderTopLeftRadius: theme.mixins.cornerRadius['border-radius-10'],
    borderTopRightRadius: theme.mixins.cornerRadius['border-radius-10'],
  },
  unread: {
    height: theme.spacing(1),
    width: theme.spacing(1),
    borderRadius: '100%',
    background: theme.palette.OverlayColorBackgroundError,
  },
  tabContainer: {
    alignItems: 'center',
    display: 'flex',
    gridGap: theme.spacing(0.5),
  },
}))

interface MatchChatTabsProps {
  matchId?: string
  teamIds?: string[]
  isCheckpoint?: boolean
  chatRole: ChatRole
  allParticipants: Participant[]
  coachParticipants: Participant[]
}

export const MatchChatTabs: React.FC<MatchChatTabsProps> = ({
  matchId = '',
  teamIds,
  isCheckpoint,
  chatRole = ChatRole.Player,
  allParticipants,
  coachParticipants,
}) => {
  const theme = useTheme()
  const isMobile = useBreakpointSm()
  const styles = useStyles({ isMobile })
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem(`${matchId}-${LocalStorageKey.ActiveMatchChatTab}`) ??
      ChatTab.All
  )
  const {
    shouldRenderCoachChat,
    shouldRenderChat,
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState().chat
  const [firebobIndex, setFirebobIndex] = useState(0)
  const emotesLength = Object.entries(emotesMap).length

  const { unreadMessagesByConversation } = useGlobalUnreadMessagesVar()
  const derivedMatchId = getDerivedChatUniqueName({
    chatRole,
    matchId: matchId ?? '',
  })
  const allHasUnreadMessages = Boolean(
    matchId ? unreadMessagesByConversation[matchId] : 0
  )
  const coachHasUnreadMessages = Boolean(
    derivedMatchId ? unreadMessagesByConversation[derivedMatchId] : 0
  )

  const { scrollToBottom: allScrollToBottom } = useMatchChat({
    chatUniqueName: matchId,
    chatRole,
    shouldAddAdminToChat: isCheckpoint,
    matchId,
    active: false,
  })

  const { scrollToBottom: coachScrollToBottom } = useMatchChat({
    chatUniqueName: derivedMatchId,
    chatRole,
    matchId,
    shouldAddAdminToChat: isCheckpoint,
    active: false,
  })

  const { onlineStatusByUserId } = useUserOnlineStatusContext()

  useEffect(
    function scrollToBottomForActiveTab() {
      if (activeTab === ChatTab.Coach) {
        coachScrollToBottom()
      } else {
        allScrollToBottom()
      }
    },
    [activeTab]
  )

  // Set active tab to the tab whose chat has unread messages. If all or neither, default to all chat.
  // Only set active tab on first render to guard against a user looking at coach chat, receiving a message in all,
  // and the tabs being reset automatically.

  useEffect(() => {
    if (allHasUnreadMessages) {
      setActiveTab(ChatTab.All)
    } else if (coachHasUnreadMessages) {
      setActiveTab(ChatTab.Coach)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(
      `${matchId}-${LocalStorageKey.ActiveMatchChatTab}`,
      activeTab
    )
  }, [activeTab, matchId])

  const handleFirebobHover = (): void => {
    const indicesWithoutCurrent = new Array(emotesLength)
      .fill(null)
      .map((_, ndx) => ndx)
    indicesWithoutCurrent.splice(firebobIndex, 1)
    const randomIndex = Math.floor(Math.random() * indicesWithoutCurrent.length)
    setFirebobIndex(indicesWithoutCurrent[randomIndex])
  }

  const renderParticipantsCount = (
    tabTitle: string,
    hasUnreadMessages: boolean,
    participants?: Participant[]
  ): React.ReactElement =>
    participants && participants.length ? (
      <NxTooltip
        arrow
        placement="top-start"
        title={
          <Box>
            {participants.map((participant) => {
              const onlineStatus =
                ((onlineStatusByUserId && participant?.identity
                  ? onlineStatusByUserId[participant.identity]
                  : 'placeholder') as NxOnlineStatus) || 'placeholder'

              return (
                <Box key={participant.sid} alignItems="center" display="flex">
                  <NxUserOnlineStatus
                    height={8}
                    onlineStatus={onlineStatus}
                    position="static"
                    removeBorder
                    showTooltip={false}
                    width={8}
                  />

                  <Box pl={1}>
                    <NxTypography key={participant.sid} color="inherit">
                      {
                        (participant?.attributes as ParticipantAttributesJSON)
                          ?.friendlyName
                      }
                    </NxTypography>
                  </Box>
                </Box>
              )
            })}
          </Box>
        }
      >
        <Box className={styles.tabContainer}>
          {hasUnreadMessages && <Box className={styles.unread} />}
          <NxTypography color="inherit" variant="button">
            {tabTitle}
          </NxTypography>

          <NxTypography color="inherit" variant="button">
            ({participants.length})
          </NxTypography>
        </Box>
      </NxTooltip>
    ) : (
      <Box className={styles.tabContainer}>
        {hasUnreadMessages && <Box className={styles.unread} />}
        <NxTypography color="inherit" variant="button">
          {tabTitle}
        </NxTypography>
      </Box>
    )

  const renderTabs = (): React.ReactElement => (
    <Box display="flex" height="85px">
      <Button
        className={clsx(
          styles.tab,
          activeTab === ChatTab.All && styles.activeTab
        )}
        data-cy="allChatTab"
        onClick={(): void => setActiveTab(ChatTab.All)}
      >
        {renderParticipantsCount('All', allHasUnreadMessages, allParticipants)}
      </Button>

      <Button
        className={clsx(
          styles.tab,
          activeTab === ChatTab.Coach && styles.activeTab
        )}
        data-cy="coachChatTab"
        onClick={(): void => setActiveTab(ChatTab.Coach)}
      >
        {renderParticipantsCount(
          'Coach',
          coachHasUnreadMessages,
          coachParticipants
        )}
      </Button>
    </Box>
  )

  return (
    <Box className={styles.chat}>
      {shouldRenderCoachChat && renderTabs()}
      {shouldRenderChat && !shouldRenderCoachChat && (
        <Box
          bgcolor="ColorBackgroundAlt"
          className={styles.playerTab}
          color="ColorTextBase"
          display="flex"
          height="85px"
          justifyContent="space-between"
          padding={theme.spacing(3, 2)}
        >
          {renderParticipantsCount(
            'All Chat',
            allHasUnreadMessages,
            allParticipants
          )}

          <NxFirebobMessage
            emoteKey={Object.values(emotesMap)[firebobIndex].name}
            message="Say Hello!"
            onMouseEnter={handleFirebobHover}
          />
        </Box>
      )}

      <div className="shepherd-chat">
        {activeTab === ChatTab.All ? (
          <AllChatWrapper
            chatRole={chatRole}
            isCheckpoint={isCheckpoint}
            matchId={matchId}
            teamIds={teamIds}
          />
        ) : (
          <CoachChatWrapper
            chatRole={chatRole}
            isCheckpoint={isCheckpoint}
            matchId={matchId}
            teamIds={teamIds}
          />
        )}
      </div>
    </Box>
  )
}
