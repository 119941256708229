/* istanbul ignore file */

import React from 'react'
import {
  Provider,
  EsportSlug,
  refetchGetGeneralEsportAdapterDetailsQuery,
  EsportRating,
  Platform,
} from '@plvs/graphql'
import { MockedResponse } from '@apollo/client/testing'
import {
  GeneralEsportAdapter,
  VersusBar,
  MatchOverviewFormat,
} from '@plvs/respawn/features/esport/creator/types'
import { NxEsportIcon } from '@playvs-inc/nexus-components'
import {
  defaultStandingsColumns,
  esportPhaseStatColumns,
} from '@plvs/respawn/features/esport/creator/esportComponents'
import { Blue, esportObjectives, Red } from './esportStaticDetails'

export const MockGeneralAdapter: GeneralEsportAdapter = {
  id: '',
  seriesBestOf: 0,
  slug: EsportSlug.LeagueOfLegends,
  rating: EsportRating.General,
  watermarkUrl: '',
  abbreviation: 'LoL',
  name: 'League of Legends',
  accountProvider: Provider.Riot,
  accountProviderPrettyName: 'Riot',
  platforms: [],
  positions: [],
  bestOf: 3,
  rankingInfoCopy: 'Ranking is determined by X, Y, and Z.',
  competitionGroups: [],
  missingConnectionCopy: {
    match: { subtitle: '', title: '' },
    overview: { subtitle: '', title: '' },
  },
  matchInstructionsArticleId: '12345',
  isScrimmageEnabled: true,
  isEsportEnabled: true,
  isDiscordEnabled: true,
  isPlayerLedTeamEnabled: true,
  hasApiIntegration: false,
  hasSeries: false,
  matchAssistantEnabled: false,
  hasSubstitutesEnabled: true,
  gameType: 'MOBA',
  playerStatRankCategory: 'KDA',
  matchOverviewFormat: MatchOverviewFormat.HeadToHead,
  /**
   * Data below is pulled from static data in Forge
   */
  standardMapList: [],
  playoffMapList: [],
  avatarLogoUrl: '',
  disabledAvatarLogoUrl: '',
  largeEsportCardImage: '',
  smallEsportCardImage: '',
  colors: [Red, Blue],
  blackAndWhiteSvgIcon: { children: <path />, viewBox: '' },
  topPerformerCategories: [],
  phaseTopPerformerCategories: [],
  gamePlayerStatsColumns: [],
  matchPlayerStatsColumns: [],
  phaseStatColumns: esportPhaseStatColumns[EsportSlug.LeagueOfLegends],
  standingsColumns: defaultStandingsColumns,
  teamRecentGamesColumns: [],
  getPositionName: (): string => 'Mock',
  getPositionShortName: (): string => 'MCK',
  getPositionColloquial: (): string => 'MCK',
  getRandomImage: (): string => '',
  getUsername: (): string => 'username',
  getSideColor: (): string => Blue,
  getVersusBars: (): VersusBar[] => [
    { accessor: 'towersDestroyed', label: 'Towers Destroyed' },
  ],
  getObjectives: (key) =>
    key ? esportObjectives[EsportSlug.LeagueOfLegends][key] : [],
  getIsUserConnected: (): boolean => true,
  platformType: 'PC',
  platformIcon: null,
  isGamelessType: false,
  loading: false,
  headerImages: ['path/to/header/image'],
  hasArena: false,
  hasStatsEnabled: false,
  canSettleMatches: true,
  teamMinStarterSize: 3,
  isPlayerDesignatedMatchSetUp: false,
  matchInstructionArticleUrl: '',
  isFortnite: false,
  isAccountConnectionOptional: false,
  headerBackgroundUrl: '',
  themeGradient: '',
  NexusIcon: () => <NxEsportIcon esportSlug="" />,
  teamFormat: 3,
  teamMaxSize: 2,
  rulebookImage: '',
  rulebookUrl: '',
  rulebookArticleId: 'rulebookArticleId',
  scrimmageRatings: [],
  matchAssistantVideoSrc: '',
  isYouthProgram: false,
  hasMatchAssistantScore: false,
  scoreDisplayName: 'Score',
  theme: {
    color1: '',
    color2: '',
    color3: '',
    color4: '',
    color5: '',
    color6: '',
    mainColor: '',
    gradientStart: '',
    gradientEnd: '',
  },
}

export const leagueOfLegendsMock: MockedResponse[] = [
  {
    request: {
      ...refetchGetGeneralEsportAdapterDetailsQuery(),
    },
    result: {
      data: {
        esports: [
          {
            slug: 'league-of-legends',
            name: 'League of Legends',
            isEnabled: true,
            rating: 'General',
            details: {
              accountProviders: ['Riot'],
              features: {
                hasSeries: false,
                hasApiIntegration: true,
                hasSubstitutesEnabled: false,
                isDiscordEnabled: false,
                isScrimmageEnabled: true,
                isPlayerLedTeamEnabled: false,
                hasMatchAssistantScore: false,
              },
              competitionGroups: ['high_school', 'college'],
              rankingInfoCopy:
                'Rank is determined by Total Wins, followed by Tiebreaker Points',
              abbreviation: 'Lol',
              gameType: 'MOBA',
              defaultBestOf: 2,
              playerStatRankCategory: 'KDA',
              matchOverviewFormat: 'team-based',
              platforms: [Platform.PcMac],
              positions: [
                ['ADC', 'ADC', 'Attack-Damage-Carry', 'ADC', 'ADC'],
                ['Support', 'SPT', 'Support'],
                ['Jungle', 'JNG', 'Jungle'],
                ['Top', 'TOP', 'Top'],
                ['MID', 'Mid', 'Middle'],
              ],
              joinInstructions: [
                {
                  step: 0,
                  image: {
                    src: '',
                    alt: 'Sign In screen',
                  },
                  title: 'Sign into League of Legends',
                  description: 'Sign in using your PlayVS connected username.',
                  video: { src: null },
                },
                {
                  step: 1,
                  image: {
                    src: '',
                    alt: 'Click Play screen',
                  },
                  title: 'Click ‘Play’',
                  description:
                    'Navigate to the top-left corner of your screen and click the ‘Play’ button.',
                  video: { src: null },
                },
                {
                  step: 2,
                  image: {
                    src: '',
                    alt: 'Tournament screen',
                  },
                  title: 'Tournament Mode',
                  description:
                    'On the top-right corner of your screen, click ‘Tournament Mode’.',
                  video: { src: null },
                },
                {
                  step: 3,
                  image: {
                    src: '',
                    alt: 'Enter code screen',
                  },
                  title: 'Enter Your Tournament Code',
                  description:
                    'Enter the PlayVS provided game tournament code, then click ‘Join’.',
                  video: { src: null },
                },
              ],
              setupInstructions: [
                {
                  step: 0,
                  image: {
                    src: '',
                    alt: 'Sign In screen',
                  },
                  title: 'Sign into League of Legends',
                  description: 'Sign in using your PlayVS connected username.',
                  video: { src: null },
                },
                {
                  step: 1,
                  image: {
                    src: '',
                    alt: 'Click Play screen',
                  },
                  title: 'Click ‘Play’',
                  description:
                    'Navigate to the top-left corner of your screen and click the ‘Play’ button.',
                  video: { src: null },
                },
                {
                  step: 2,
                  image: {
                    src: '',
                    alt: 'Tournament screen',
                  },
                  title: 'Tournament Mode',
                  description:
                    'On the top-right corner of your screen, click ‘Tournament Mode’.',
                  video: { src: null },
                },
                {
                  step: 3,
                  image: {
                    src: '',
                    alt: 'Enter code screen',
                  },
                  title: 'Enter Your Tournament Code',
                  description:
                    'Enter the PlayVS provided game tournament code, then click ‘Join’.',
                  video: { src: null },
                },
              ],
              missingConnectionCopy: {
                match: {
                  subtitle:
                    'Connect your in-game name to access the game tournament code.',
                  title: 'Connect your Riot account!',
                },
                overview: {
                  subtitle:
                    'You must connect your in-game name to access game tournament codes during matches.',
                  title: 'Connect your Riot account!',
                },
              },
              assets: null,
              scrimmageRatings: [
                'Iron',
                'Bronze',
                'Silver',
                'Gold',
                'Platinum',
                'Diamond',
                'Master',
                'Grandmaster',
                'Challenger',
              ],
            },
          },
        ],
      },
    },
  },
]
