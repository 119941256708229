import { Hidden, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import React, { ChangeEvent } from 'react'
import { NxTypography, NxTextInput } from '@playvs-inc/nexus-components'

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 3, 0),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      textTransform: 'uppercase',
      marginBottom: theme.spacing(1),
    },
  },
}))

export interface ManagePlayerTableHeaderProps {
  totalCount: number
  countedUserText: string
  setNameFilter: (name: string) => void
}

export const ManagePlayersTableHeader: React.FC<ManagePlayerTableHeaderProps> = ({
  totalCount,
  countedUserText,
  setNameFilter,
}: {
  totalCount: number
  countedUserText: string
  setNameFilter: (name: string) => void
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.infoContainer}>
      <Box display="flex" flexDirection="column">
        <Hidden lgUp>
          <NxTypography
            className={classes.title}
            variant="subtitle2"
          >{`${totalCount} ${countedUserText} Player${
            totalCount > 1 ? 's' : ''
          }`}</NxTypography>
        </Hidden>
        <Hidden mdDown>
          <NxTypography className={classes.title} variant="h3">
            {`${totalCount} Player${totalCount > 1 ? 's' : ''}`}
          </NxTypography>
        </Hidden>
      </Box>

      {/* Filters Container */}
      <Box>
        <NxTextInput
          label="Search by Name"
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ): void => {
            setNameFilter(evt.currentTarget.value)
          }}
          style={{ width: '350px' }}
        />
      </Box>
    </Box>
  )
}
