/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Box } from '@plvs/respawn/features/layout'

import { MappedMatch } from '@plvs/rally/features/match/schedule/schedule'
import { ScheduleMatchRow } from '@plvs/rally/components/schedule/match/ScheduleMatchRow'
import { getContextualTeams } from '@plvs/utils'
import { useStyles } from './MatchList.styles'

type MatchListProps = {
  matches: MappedMatch[]
}

export const MatchList: React.FC<MatchListProps> = ({ matches }) => {
  const styles = useStyles()

  return (
    <Box display="flex" flexDirection="column">
      {matches.map((match, index) => {
        // The two arrays were previously combined for proper sorting.
        // Now, we need to separate them back into individual entries.
        // eslint-disable-next-line no-underscore-dangle
        const isRegularMatch = match.__typename === 'Match'
        const { team1 } = getContextualTeams(match?.teamContext?.teams)

        if (isRegularMatch) {
          return (
            <Box key={match.id} className={styles.matchRowContainer}>
              <ScheduleMatchRow match={match} showEsportAccent />
            </Box>
          )
        }

        return (
          <Box
            key={`${team1.id}-${index}`}
            className={styles.matchRowContainer}
          >
            <ScheduleMatchRow isQueueMatch match={match} showEsportAccent />
          </Box>
        )
      })}
    </Box>
  )
}
