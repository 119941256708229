import React from 'react'
import { StepType } from '@plvs/utils'
import { Box } from '@plvs/respawn/features/layout'
import { MatchAssistantAdapterProps } from '@plvs/rally/features/match/assistant/adapter/esports/MatchAssistantAdapter.types'
import { HeadToHeadGamePreview } from '@plvs/rally/features/match/assistant/steps/general/gamePreview/HeadToHeadGamePreview'
import { MatchAssistantActions } from '@plvs/rally/features/match/assistant/MatchAssistantActions'
import { MatchAssistantAdapterSteps } from '@plvs/rally/features/match/assistant/adapter/matchAssistantAdapterSteps'
import { TeamReporting } from '@plvs/rally/features/match/assistant/steps/reporting/TeamReporting'
import { MatchFinalScore } from '@plvs/rally/features/match/dynamic/matchFinalScore/MatchFinalScore'

export const headToHeadMatchAssistantAdapter = ({
  subtitle,
  generalSteps,
  match,
  setStepIndex,
  DisputeResultsButton,
}: Pick<
  MatchAssistantAdapterProps,
  | 'subtitle'
  | 'generalSteps'
  | 'isMatchComplete'
  | 'setStepIndex'
  | 'DisputeResultsButton'
  | 'match'
>): MatchAssistantAdapterSteps => {
  return {
    assistantSteps: {
      ...generalSteps,
      [StepType.Report]: [
        {
          step: <HeadToHeadGamePreview onNext={setStepIndex} />,
          title: 'Game Preview',
          actions: <MatchAssistantActions />,
        },
        {
          step: <TeamReporting />,
          title: 'Scoreboard',
          subtitle,
          actions: <MatchAssistantActions />,
        },
      ],
      [StepType.Final]: [
        {
          step: (
            <Box mb={3}>
              <MatchFinalScore match={match} />
            </Box>
          ),
          subtitle: '',
          title: 'Final Score',
          actions: DisputeResultsButton,
        },
      ],
    },
  }
}
