import React from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { UserNameInput } from '@plvs/rally/components/onboard/types'
import { Grid } from '@material-ui/core'
import { EthnicityAutocomplete } from '@plvs/respawn/features/account/ethnicity-autocomplete/EthnicityAutocomplete'
import { EthnicityValue } from '@plvs/respawn/features/account/ethnicity-autocomplete/types'
import { GenderSelect } from '@plvs/respawn/features/account/GenderSelect'
import { Gender } from '@plvs/graphql/generated'
import { useAuthRenderControllerContext } from '@plvs/respawn/renderController'
import { StateSelector } from '@plvs/respawn/features/form/StateSelector'
import { CountrySelector } from '@plvs/respawn/features/form/CountrySelector'
import { Box } from '@plvs/respawn/features/layout/Box'
import { OnboardTextField } from '../../../../components/form/OnboardTextfield'
import { DemographicCallout } from './DemographicCallout'

export interface OnboardNameFormProps {
  errors?: DeepMap<UserNameInput, FieldError>
  register: React.Ref<HTMLInputElement>
  values?: { firstName: string; lastName: string }
  state: string | undefined
  setAreaState: React.Dispatch<React.SetStateAction<string | undefined>>
  country: string | undefined
  setCountry: React.Dispatch<React.SetStateAction<string | undefined>>
  ethnicities: EthnicityValue[]
  setEthnicities: React.Dispatch<React.SetStateAction<EthnicityValue[]>>
  gender: Gender | undefined
  setGender: React.Dispatch<React.SetStateAction<Gender | undefined>>
  isUserDemographicsEnabled: boolean
}

export const OnboardUserNameForm: React.FC<OnboardNameFormProps> = ({
  errors,
  register,
  values,
  ethnicities,
  setEthnicities,
  setCountry,
  setAreaState,
  state,
  country,
  gender,
  setGender,
  isUserDemographicsEnabled,
}) => {
  const { auth } = useAuthRenderControllerContext()
  const { shouldShowFullDemographicForm } = auth.getAuthComponentsToRender({
    location: window.location.href,
  })

  return (
    <Box display="flex" flexDirection="column" mt={-4} pt={2} width="100%">
      <Box data-testid="OnboardNameForm_FirstName" mb={2} width="100%">
        <OnboardTextField
          defaultValue=""
          error={errors?.firstName}
          label="First Name"
          name="firstName"
          register={register}
          value={values?.firstName}
        />
      </Box>
      <Box
        data-testid="OnboardNameForm_LastName"
        mb={isUserDemographicsEnabled ? 2 : 0}
        width="100%"
      >
        <OnboardTextField
          defaultValue=""
          error={errors?.lastName}
          label="Last Name"
          name="lastName"
          register={register}
          value={values?.lastName}
        />
      </Box>
      {isUserDemographicsEnabled && (
        <>
          {shouldShowFullDemographicForm ? (
            <Grid container spacing={2}>
              <Grid data-testid="onboard-country" item sm={6} xs={12}>
                <CountrySelector onChange={setCountry} value={country ?? ''} />
              </Grid>
              <Grid data-testid="onboard-state" item sm={6} xs={12}>
                <StateSelector onChange={setAreaState} value={state ?? ''} />
              </Grid>

              <Grid data-testid="onboard-race" item sm={6} xs={12}>
                <EthnicityAutocomplete
                  onChange={setEthnicities}
                  values={ethnicities}
                />
              </Grid>
              <Grid data-testid="onboard-gender" item sm={6} xs={12}>
                <GenderSelect fullWidth onChange={setGender} value={gender} />
              </Grid>
            </Grid>
          ) : (
            <>
              <Box data-testid="onboard-race" mb={[2, 4]}>
                <EthnicityAutocomplete
                  onChange={setEthnicities}
                  values={ethnicities}
                />
              </Box>
              <Box data-testid="onboard-gender">
                <GenderSelect fullWidth onChange={setGender} value={gender} />
              </Box>
            </>
          )}
          <DemographicCallout />
        </>
      )}
    </Box>
  )
}
