import React from 'react'
import { FieldError } from 'react-hook-form'
import { NxTextInput } from '@playvs-inc/nexus-components'
import { formErrorToString } from '@plvs/utils'
import { Box } from '@plvs/respawn/features/layout'

export interface OnboardTextFieldProps {
  error?: FieldError
  register: React.Ref<HTMLInputElement>
  label: string
  name: string
  autoComplete?: string
  defaultValue?: string
  placeholder?: string
  value?: string
  type?: string
  ['data-testid']?: string
}
export const OnboardTextField: React.FC<OnboardTextFieldProps> = ({
  error,
  register,
  label,
  name,
  autoComplete,
  defaultValue,
  placeholder,
  value,
  type,
  'data-testid': dataTestId,
}) => {
  return (
    <Box data-testid={dataTestId}>
      <NxTextInput
        ref={register}
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        fullWidth
        helperText={formErrorToString(error)}
        label={label}
        name={name}
        placeholder={placeholder ?? label}
        type={type}
        value={value}
        variant={error ? 'error' : 'default'}
      />
    </Box>
  )
}
