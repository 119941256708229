import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'

import { NxTypography, NxUserCluster } from '@playvs-inc/nexus-components'

import { getEmote, getHasOpposingTeam } from '@plvs/utils'
import { useStyles } from './Slide.styles'
import type { SlideProps } from './Slide.props'
import { RewindAssets } from '../Rewind.helpers'

export const HorizontalSlide: React.FC<SlideProps> = ({ data }) => {
  const styles = useStyles({ valueLength: data?.value?.length ?? 0 })
  const theme = useTheme()

  const emote = getEmote(data?.value)
  const hasOpposingTeam = getHasOpposingTeam(data)

  const teamCluster = (
    <Box alignItems="center" display="flex" gridGap={theme.spacing(2)}>
      <NxUserCluster
        avatarHashId={data?.team?.id ?? ''}
        avatarUrl={data?.team?.school?.logoUrl ?? ''}
        className={styles.avatar}
      />

      <Box>
        <NxTypography colorToken="OverlayColorTextBase" variant="body2">
          {data?.team?.name}
        </NxTypography>

        <NxTypography colorToken="OverlayColorTextBase" variant="body2">
          {data?.team?.school?.name}
        </NxTypography>
      </Box>
    </Box>
  )

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(0.5)}
        minWidth="40%"
      >
        <NxTypography className={styles.accentColor} variant="display3">
          {data?.title}
        </NxTypography>

        <Box alignItems="center" display="flex" gridGap={theme.spacing(1)}>
          <NxTypography
            className={styles.value}
            colorToken="OverlayColorTextBase"
            variant="h1"
          >
            {data?.value}
          </NxTypography>

          <Box>{emote}</Box>
        </Box>

        {hasOpposingTeam && teamCluster}
      </Box>

      <Box my={-0.5}>
        <img alt="Decorative vertical scribble" src={RewindAssets.Scribble} />
      </Box>

      <Box alignItems="center" display="flex" justifyContent="center" py={6}>
        <NxTypography colorToken="OverlayColorTextBase" variant="body2">
          {data?.subtext}
        </NxTypography>
      </Box>
    </>
  )
}
