import React from 'react'

import { withTransaction } from '@elastic/apm-rum-react'
import { PrivatePage } from '@plvs/rally/pages/page'
import { ScrollToTop } from '@plvs/rally/pages/route/ScrollToTop'
import { MatchLobbyRenderControllerProvider } from '@plvs/respawn/renderController/matchLobby/MatchLobbyRenderControllerProvider'
import { Lobby } from '@plvs/rally/features/match/lobby/Lobby/Lobby'
import { useParams } from 'react-router-dom'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useGetMatchFormatByUuidQuery } from '@plvs/graphql/generated'
import { defaultToNull } from '@plvs/utils'

interface MatchLobbyPageInternalProps {
  breadcrumbHref?: string
}

const MatchLobbyPageInternal: React.FC<MatchLobbyPageInternalProps> = ({
  breadcrumbHref,
}) => {
  const { matchId } = useParams<{ matchId: string }>()
  const { data, loading } = useGetMatchFormatByUuidQuery({
    variables: { matchId: matchId ?? '' },
    skip: !matchId,
  })

  const format = defaultToNull(data?.match?.format)

  return (
    <PrivatePage title="Match Lobby">
      <ScrollToTop />
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        <MatchLobbyRenderControllerProvider matchFormat={format}>
          <Lobby
            breadcrumbHref={breadcrumbHref}
            matchFormat={format}
            matchId={matchId ?? ''}
          />
        </MatchLobbyRenderControllerProvider>
      </WaitTillLoaded>
    </PrivatePage>
  )
}

export const MatchLobbyPage = withTransaction(
  'MatchLobbyPage',
  'component'
)(MatchLobbyPageInternal)
