import React from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { NxTypography } from '@playvs-inc/nexus-components'
import { makeStyles, IconButton } from '@material-ui/core'
import { VariableSizeList as List } from 'react-window'
import { CaretLeft, CaretRight } from '@playvs-inc/nexus-icons'

import { CreateCSSProperties } from '@material-ui/styles'
import {
  BracketMatchCluster,
  NxBracketMatchCluster,
} from './BracketMatchCluster'
import { BracketMatch, getRoundName } from './Bracket'
import { getRightBottomMatch } from './bracketHelpers'

const useStyles = makeStyles((theme) => ({
  roundHeader: ({
    isMobile,
  }: {
    isMobile: boolean
    esportColor: string
  }): CreateCSSProperties => ({
    justifyItems: 'center',
    width: '100%',
    paddingRight: isMobile ? '0px' : '52px',
  }),
  paginationButtonBase: ({
    esportColor,
  }: {
    esportColor: string
  }): CreateCSSProperties => ({
    position: 'relative',
    border: '1px solid',
    borderRadius: '50%',
    backgroundColor: esportColor,
    borderColor: esportColor,
    color: esportColor,
    '& path': {
      fill: theme.palette.OverlayColorTextBase,
    },
    '&.active': {
      backgroundColor: esportColor,
      borderColor: 'rgba(0,0,0,0)',
      '& path': {
        fill: theme.palette.OverlayColorTextBase,
      },
      '&:disabled': {
        border: '1px solid',
        borderRadius: '50%',
        borderColor: theme.palette.BorderLight,
        backgroundColor: 'rgba(0,0,0,0)',
        '& path': {
          fill: theme.palette.BorderLight,
        },
      },
    },
    '&:hover:not(:disabled):not(.active)': {
      borderColor: esportColor,
      backgroundColor: esportColor,
      '& path': {
        fill: theme.palette.OverlayColorTextBase,
      },
    },
    '&:disabled': {
      border: '1px solid',
      borderRadius: '50%',
      borderColor: theme.palette.BorderLight,
      backgroundColor: 'rgba(0,0,0,0)',
      '& path': {
        fill: theme.palette.BorderLight,
      },
    },
  }),
}))

export interface BracketRoundProps {
  firstColumnOfMatches: BracketMatch[]
  secondColumnOfMatches?: BracketMatch[] | null
  startRoundIndex: number
  totalRounds: number
  overlayColor: string
  handleNext(): void
  disableNext: boolean
  handlePrev(): void
  disablePrev: boolean
}

export function NxBracketRoundCluster({
  firstColumnOfMatches,
  secondColumnOfMatches,
  startRoundIndex,
  totalRounds,
  overlayColor,
  handleNext,
  disableNext,
  handlePrev,
  disablePrev,
}: BracketRoundProps): React.ReactElement {
  const isMobile = useBreakpointSm()
  const classes = useStyles({ esportColor: overlayColor, isMobile })
  const firstRoundName = getRoundName(startRoundIndex + 1, totalRounds)
  const secondRoundName = getRoundName(startRoundIndex + 2, totalRounds)
  const maxMatchesOnLeft = firstColumnOfMatches.length
  const numClusters = maxMatchesOnLeft
  const matchClusters: BracketMatchCluster[] = []
  for (let i = 0; i < numClusters; i += 2) {
    const leftTopMatch = firstColumnOfMatches[i]
    const leftBottomMatch = firstColumnOfMatches[i + 1]
    const rightTopMatch = secondColumnOfMatches?.find(
      (x) => x.id === leftTopMatch.nextMatchId
    )
    const rightBottomMatch = getRightBottomMatch(
      secondColumnOfMatches,
      leftTopMatch?.nextMatchId,
      leftBottomMatch?.nextMatchId
    )
    matchClusters.push({
      leftTopMatch,
      leftBottomMatch,
      rightTopMatch,
      rightBottomMatch,
    })
  }

  const Row = ({ index, style }): JSX.Element => (
    <div style={style}>
      <NxBracketMatchCluster
        matchCluster={matchClusters[index]}
        overlayColor={overlayColor}
        roundIndex={startRoundIndex}
      />
    </div>
  )

  const rowHeights = matchClusters.map((x) => (x.leftBottomMatch ? 380 : 190))
  const getItemSize = (index): number => rowHeights[index]

  return (
    <Box pb={4} width="100%">
      <Box alignItems="center" display="flex" flexDirection="row" mb={2}>
        <IconButton
          className={classes.paginationButtonBase}
          disabled={disablePrev}
          onClick={handlePrev}
          size="small"
        >
          <CaretLeft />
        </IconButton>
        <Box className={classes.roundHeader}>
          <NxTypography variant="h4">{firstRoundName}</NxTypography>
        </Box>
        {secondColumnOfMatches && (
          <Box className={classes.roundHeader}>
            <NxTypography variant="h4">{secondRoundName}</NxTypography>
          </Box>
        )}
        <IconButton
          className={classes.paginationButtonBase}
          disabled={disableNext}
          onClick={handleNext}
          size="small"
        >
          <CaretRight />
        </IconButton>
      </Box>
      <Box pr="26px">
        <List
          height={760}
          itemCount={matchClusters.length}
          itemSize={getItemSize}
          width="100%"
        >
          {Row}
        </List>
      </Box>
    </Box>
  )
}
