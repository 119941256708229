import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'

import { ChatRole } from '@plvs/graphql'
import {
  useGlobalUnreadMessagesVar,
  useActiveGlobalChatConversationsVar,
  useActiveGlobalWindowsVar,
} from '@plvs/client-data/hooks'
import { updateGlobalUnreadMessages } from '@plvs/client-data/mutations'
import {
  getDerivedChatUniqueName,
  onOpenGlobalChat,
} from '@plvs/rally/features/chat/utils'
import { ChatUnreadMessage } from '@playvs-inc/nexus-icons'
import { useGetUnreadMessageCount } from '@plvs/rally/features/chat'
import * as analytics from '@plvs/respawn/features/analytics'
import { useChatConversationsContext } from '@plvs/rally/containers/chat/ChatConversationsProvider'

interface UnreadMessagesIndicatorProps {
  matchId: string
  chatRole?: ChatRole
}

const useStyles = makeStyles(() => ({
  chatUnreadMessagesIcon: {
    '& svg': {
      height: 20,
      width: 20,
    },
  },
  box: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export const UnreadMessagesIndicator: React.FC<UnreadMessagesIndicatorProps> = ({
  matchId,
  chatRole,
}) => {
  const styles = useStyles()
  const { usersActiveConversationsMap } = useChatConversationsContext()
  // useGlobalUnreadMessagesVar is only being imported because updating the var requires the current state.
  const { unreadMessagesByConversation } = useGlobalUnreadMessagesVar()
  const { activeConversations } = useActiveGlobalChatConversationsVar()
  const activeConversationsList = useActiveGlobalWindowsVar()
  const derivedMatchId = getDerivedChatUniqueName({ chatRole, matchId })

  const {
    unreadMessageCounts: unreadMessageCountObjAll,
    unreadMessageCount: unreadMessageCountAll,
  } = useGetUnreadMessageCount({
    chatUniqueName: matchId,
  })

  const {
    unreadMessageCounts: unreadMessageCountObjCoach,
    unreadMessageCount: unreadMessageCountCoach,
  } = useGetUnreadMessageCount({
    chatUniqueName: derivedMatchId,
  })

  const totalUnreadMessagesCount =
    (unreadMessageCountAll ?? 0) + (unreadMessageCountCoach ?? 0)
  const newUnreadMessagesState = {
    ...unreadMessageCountObjCoach,
    ...unreadMessageCountObjAll,
  }

  const shouldRenderUnreadNotifications = totalUnreadMessagesCount !== 0

  // This follows the same logic as the MatchChat tabs.
  const setConvoWithUnreadMessagesToActiveUniqueName =
    unreadMessageCountCoach > 0 && unreadMessageCountAll === 0
      ? derivedMatchId
      : matchId
  const subscribedConversation =
    usersActiveConversationsMap[setConvoWithUnreadMessagesToActiveUniqueName]

  // unreadMessagesByConversation is being updated here so that <AppDrawerChatMessagesToggle /> can determine
  // if any subscribed conversation has unread messages.
  useEffect(() => {
    updateGlobalUnreadMessages({
      ...unreadMessagesByConversation,
      ...newUnreadMessagesState,
    })
  }, [
    JSON.stringify(newUnreadMessagesState),
    JSON.stringify(unreadMessagesByConversation),
  ])

  const onClick = (): void => {
    onOpenGlobalChat({
      uniqueName: setConvoWithUnreadMessagesToActiveUniqueName,
      activeConversations,
      activeConversationsList,
    })
    analytics.leftNavUnreadNotificationIconClicked({
      chatOpened: true,
      chatUnqiueName: setConvoWithUnreadMessagesToActiveUniqueName,
    })

    analytics.userReadUnreadMessageViaGlobal({
      chatUniqueName: setConvoWithUnreadMessagesToActiveUniqueName,
      location: 'Left Nav Indicator',
      numberOfUnreadMessagesRead:
        setConvoWithUnreadMessagesToActiveUniqueName === matchId
          ? unreadMessageCountAll
          : unreadMessageCountCoach,
    })
    subscribedConversation?.conversation.setAllMessagesRead()
  }

  return !shouldRenderUnreadNotifications ? (
    <></>
  ) : (
    <Box className={styles.box} onClick={onClick}>
      <ChatUnreadMessage
        className={styles.chatUnreadMessagesIcon}
        count={totalUnreadMessagesCount}
      />
    </Box>
  )
}
