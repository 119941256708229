import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useState } from 'react'
import { ApolloError } from '@apollo/client'
import { withCookies } from 'react-cookie'
import { rallyEnv } from '@plvs/rally/env'
import { useQueryParam } from '@plvs/rally/components/filter'
import { Param, SpinLink } from '@plvs/const'

import { cleanGraphQLError, CookieProps } from '@plvs/utils'
import { Link } from '@material-ui/core'
import {
  Provider,
  refetchGetUserProviderAccountsQuery,
  useUnlinkUserProviderAccountMutation,
} from '@plvs/graphql'
import { NxTextLink, NxTypography } from '@playvs-inc/nexus-components'
import {
  RouteComponentProps,
  withRouter,
} from '@plvs/respawn/features/route/WithRouter'
import { providerTitleMap } from '@plvs/respawn/features/account/connections/ProviderConnection/utils'
import { Box } from '@plvs/respawn/features/layout/Box'
import ConnectionCard from '../ConnectionCard'
import { AccountProviderComponentProps, createConnectAccount } from '../utils'

interface ProviderConnectionProps
  extends AccountProviderComponentProps,
    RouteComponentProps {}

// Support new provider flow only for subset of providers
export const providerAuthLinkMap: Record<string, string> = {
  [Provider.BattleDotNet]: rallyEnv.BATTLEDOTNET_AUTH_URI,
  [Provider.Discord]: rallyEnv.DISCORD_AUTH_URI,
  [Provider.Twitch]: rallyEnv.TWITCH_AUTH_URI,
  [Provider.Riot]: rallyEnv.RIOT_AUTH_URI,
  [Provider.EpicGames]: rallyEnv.EPICGAMES_AUTH_URI,
  [Provider.XBoxLive]: rallyEnv.XBOXLIVE_AUTH_URI,
  [Provider.Youtube]: rallyEnv.YOUTUBE_AUTH_URI,
  [Provider.Spin]: rallyEnv.STAY_PLUGGED_IN_URI,
  [Provider.Vesl]: rallyEnv.VESL_AUTH_URI,
  unknown: 'unknown',
}

export const providerSubtitleMap: Record<string, string | React.ReactNode> = {
  [Provider.BattleDotNet]: 'Connect your Battle.net Account',
  [Provider.Discord]: 'Get access to our chat rooms',
  [Provider.Twitch]: 'Connect your Twitch account to submit a clip',
  [Provider.Riot]:
    'Connect your Riot Games account to participate in matches for League of Legends and VALORANT.',
  [Provider.EpicGames]: (
    <>
      Connect your Epic Games account to participate in Rocket League matches.
      Learn how to connect Epic Games for Rocket League{' '}
      <Link href="https://help.playvs.com/en/articles/4919180" target="_blank">
        here
      </Link>
      .
    </>
  ),
  [Provider.XBoxLive]: 'Connect your Xbox Live account',
  [Provider.Youtube]: 'Connect your YouTube account to submit a clip',
  [Provider.Spin]:
    'Connect your Stay Plugged In account to gain special membership access.',
  SpinFreeCompetition: 'Connect your Stay Plugged In account.',
  [Provider.Vesl]: 'Link your VESL and PlayVS accounts',
  [Provider.ChessDotCom]: 'Add your Chess.com username',
  [Provider.ChessKid]: 'Add your ChessKid username',
  [Provider.ElectronicArts]: 'Add your EA Account ID',
  [Provider.StreetFighter]: 'Add your Street Fighter 6 User Code',
  [Provider.Nba2K]: 'Add your 2K Account ID',
  [Provider.MarvelRivals]: 'Add your Marvel Rivals account name',
  [Provider.F1]: 'Add your F1 account name',
  [Provider.PokemonUnite]: 'Add your Pokémon UNITE account name',
}

const ProviderConnection: React.FC<ProviderConnectionProps & CookieProps> = ({
  name: providerName,
  providerDetails,
  apolloError,
  cookies,
  checkpoint,
  userId,
  isParent = false,
}) => {
  const flags = useFlags()
  // Data
  const providerId = providerDetails?.id ?? ''
  const providerDisplayName = providerDetails?.providerDisplayName ?? ''
  const lastUpdated =
    providerDetails?.updatedAt ?? providerDetails?.createdAt ?? ''
  const isEpicProvider = providerName === Provider.EpicGames
  const isSpinProvider = providerName === Provider.Spin

  // Hooks
  const [success] = useQueryParam(Param.Success)
  const [authError] = useQueryParam(Param.AuthError)
  const [provider] = useQueryParam(Param.Provider)
  const [name, setName] = useState<string>(providerDisplayName)
  const [error, setError] = useState<Error | ApolloError | undefined>()
  const [esportPreferenceError, setEsportPreferenceError] = useState<
    Error | ApolloError | undefined
  >()
  const [isConnected, setIsConnected] = useState<boolean>(!!name)

  // Mutations
  const [remove] = useUnlinkUserProviderAccountMutation({
    onError: (err): void => setError(err),
    refetchQueries: [refetchGetUserProviderAccountsQuery()],
  })

  useEffect(() => {
    if (providerDisplayName) {
      setName(providerDisplayName)
    }
  }, [providerDisplayName])

  useEffect(() => {
    if (lastUpdated) {
      setIsConnected(true)
    }
  }, [lastUpdated])

  useEffect(() => {
    if (apolloError && provider === providerName) {
      setError(apolloError)
    }
  }, [apolloError])

  useEffect(() => {
    if (provider === providerName) {
      if (success === 'false' && authError) {
        setError(new Error(authError))
      }
      if (success === 'false' && !authError) {
        setError(
          new Error(
            'Account could not be successfully connected, please try again or connect a different account.'
          )
        )
      }
    }
  }, [authError, success, provider])

  const disconnectAccount = async (): Promise<void> => {
    setIsConnected(false)
    await remove({
      variables: {
        providerId,
      },
    }).then(() => {
      setName('')
      setError(undefined)
      setEsportPreferenceError(undefined)
    })
  }

  const connectAccount = createConnectAccount(
    providerAuthLinkMap[providerName || 'unknown'],
    cookies,
    setError
  )

  const renderError = (): string => {
    if (error?.message) return cleanGraphQLError(error.message)
    if (isEpicProvider && esportPreferenceError?.message)
      return cleanGraphQLError(esportPreferenceError.message)
    return ''
  }

  const key =
    providerName === Provider.Spin && flags.freeCompetition
      ? 'SpinFreeCompetition'
      : providerName
  let providerSubtitle = providerSubtitleMap[key || '']

  if (providerName === Provider.Riot && !flags.valorantEsport) {
    providerSubtitle =
      'Connect your Riot Games account to participate in matches for League of Legends.'
  }
  if (
    isConnected &&
    !providerDisplayName &&
    providerDetails?.providerOnboardUrl &&
    providerName
  ) {
    providerSubtitle = (
      <NxTypography>
        Your {providerTitleMap[providerName]} account is not complete. Click{' '}
        <NxTextLink
          href={providerDetails.providerOnboardUrl}
          label="here"
          labelVariant="body1"
          style={{ textTransform: 'none' }}
          target="_blank"
        />{' '}
        to finish signing up.
      </NxTypography>
    )
  }

  const defaultConnectedText = (
    <>
      <NxTypography
        colorToken="ColorTextSuccess"
        variant="body2"
      >{`Connected to ${name}`}</NxTypography>
      {isSpinProvider && (
        <Box mt={0.5}>
          <NxTextLink
            href={`${
              SpinLink[(rallyEnv.API_ENV as string) ?? 'staging']
            }/dashboard/subscriptions?provider=playvs`}
            label="Find Esports Scholarships"
            labelVariant="body2"
            style={{ textTransform: 'none' }}
            target="_blank"
          />
        </Box>
      )}
    </>
  )

  return (
    <ConnectionCard
      checkpoint={checkpoint}
      connectedText={isConnected && name ? defaultConnectedText : ''}
      errorText={renderError()}
      isConnected={isConnected}
      isParent={isParent}
      lastUpdated={lastUpdated}
      onConnectClick={connectAccount}
      onUpdateClick={disconnectAccount}
      providerName={providerName}
      subtitle={providerSubtitle}
      title={providerTitleMap[providerName || '']}
      userId={userId}
    />
  )
}

export default withRouter(withCookies(ProviderConnection))
