import React from 'react'
import { SuccessOutlined, ErrorOutlined } from '@playvs-inc/nexus-icons'
import { NxTypography } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'

export const CheckRequirement: React.FC<{
  label: string
  errorLabel: string
  isCorrect: boolean
}> = ({ label, errorLabel, isCorrect }) => {
  const icon = isCorrect ? (
    <SuccessOutlined color="#008A00" />
  ) : (
    <ErrorOutlined color="#AB1100" />
  )

  return (
    <Box display="flex">
      {icon}
      <NxTypography
        colorToken={isCorrect ? 'ColorTextSuccess' : 'ColorTextError'}
        variant="body1"
      >
        {isCorrect ? label : errorLabel}
      </NxTypography>
    </Box>
  )
}
