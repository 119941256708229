import React from 'react'

import { StepType } from '@plvs/utils'
import { MatchAssistantAdapterStepValue } from '@plvs/rally/features/match/assistant/adapter/matchAssistantAdapterSteps'
import { MatchAssistantActions } from '@plvs/rally/features/match/assistant/MatchAssistantActions'
import { MatchFinalScore } from '@plvs/rally/features/match/dynamic/matchFinalScore/MatchFinalScore'
import {
  CharacterSelect,
  GameIntermission,
  MatchPreview,
  Reporting,
  StageSelect,
} from '@plvs/rally/features/match/assistant/steps'
import type { MatchAssistantAdapterProps } from './MatchAssistantAdapter.types'

export const smashBrosUltimateMatchAssistantSteps = ({
  subtitle,
  DisputeResultsButton,
  generalSteps,
  match,
  setStepIndex,
  playerSelectStep,
}: MatchAssistantAdapterProps): Record<
  StepType | string,
  MatchAssistantAdapterStepValue
> => {
  return {
    ...generalSteps,
    [StepType.PlayerSelect]: playerSelectStep,

    [StepType.CharacterSelect]: [
      {
        step: <GameIntermission onNext={setStepIndex} />,
        title: 'Intermission',
        subtitle,
        actions: <MatchAssistantActions />,
      },
      {
        step: <CharacterSelect />,
        subtitle,
        title: 'Characters',
        actions: <MatchAssistantActions />,
      },
    ],

    [StepType.StageSelect]: [
      {
        step: (
          <CharacterSelect
            buttonText="Continue"
            onNext={setStepIndex}
            readonly
          />
        ),
        subtitle,
        title: 'Characters',
        actions: <MatchAssistantActions />,
      },
      {
        step: <StageSelect />,
        title: 'Stage Striking',
        subtitle,
        actions: <MatchAssistantActions />,
      },
    ],

    [StepType.Report]: [
      {
        step: <MatchPreview onNext={setStepIndex} />,
        title: 'Match Preview',
        subtitle,
        actions: <MatchAssistantActions />,
      },
      {
        step: <Reporting />,
        title: 'Scoreboard',
        subtitle,
        actions: <MatchAssistantActions />,
      },
    ],

    [StepType.Final]: [
      {
        step: <MatchFinalScore match={match} />,
        title: 'Scoreboard',
        actions: DisputeResultsButton,
      },
    ],
  }
}
