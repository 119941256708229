import {
  defaultTo0,
  defaultToEmptyArray,
  defaultToEmptyString,
  MatchAssistantMatch,
  Placing,
  TeamRosterForSteps,
} from '@plvs/utils'
import { Box } from '@plvs/respawn/features/layout'
import {
  NxScoreboardRowsProps,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { MultiTeamCluster } from '@plvs/rally/features/explore/LeagueMatches/MatchRow/MultiTeamCluster'
import { MappedMatchTeam } from '@plvs/rally/features/match/schedule/schedule'
import React from 'react'

export function getAveragePlacing(
  match: MatchAssistantMatch,
  teamId?: string
): number {
  const myResults =
    match?.games
      ?.flatMap(({ gameResults }) => gameResults)
      .filter((result) => result?.teamId && result?.teamId === teamId) ?? []

  const sumOfPlacings = defaultTo0(
    myResults
      .map((result) => defaultTo0(result?.placing))
      .reduce((sum, placing) => sum + placing, 0)
  )

  // Don't divide by 0
  return sumOfPlacings ? sumOfPlacings / myResults?.length : sumOfPlacings
}

// Returns in format:
//  - Whole number: 1st, 2nd, 3rd, etc.
//  - Decimal: 1.00, 2.00, 3.00, etc.
export function getFormattedAveragePlacing(
  match: MatchAssistantMatch,
  teamId?: string
): string {
  const averagePlacing = getAveragePlacing(match, teamId)

  const isWholeNumber = averagePlacing % 1 === 0
  return isWholeNumber ? Placing(averagePlacing) : averagePlacing.toFixed(2)
}

export function mapGameResultsToScoreboardData({
  match,
  isFinal,
  myTeam,
  myTeamId,
}: {
  match: MatchAssistantMatch | null
  isFinal: boolean
  myTeam: TeamRosterForSteps | null
  myTeamId: string
}): NxScoreboardRowsProps['data'][0] {
  const myAveragePlacing = getFormattedAveragePlacing(match, myTeamId)

  return {
    title: isFinal ? 'Final' : 'Players',
    leftSide: {
      id: defaultToEmptyString(myTeamId),
      title: myTeam?.name,
      avatarUrl: myTeam?.avatarUrl,
      subtitles: [{ title: myTeam?.school?.name }],
    },

    customMiddle: (
      <Box mx={2}>
        <NxTypography variant="h4">
          {isFinal ? myAveragePlacing : 'vs'}
        </NxTypography>
      </Box>
    ),

    showScore: false,

    rightSide: {
      id: defaultToEmptyString('leaderboard'),
      title: 'Multiple Teams',
      Avatar: (
        <MultiTeamCluster
          subtitle={myTeam?.school?.id ? 'Multiple Schools' : ''}
          teams={
            defaultToEmptyArray(
              match?.teamContext?.teams.filter(({ id }) => id !== myTeam?.id)
            ) as MappedMatchTeam[]
          }
        />
      ),
      subtitles: [{ title: myTeam?.school?.id ? 'Multiple Schools' : '' }],
    },
  }
}
