import React from 'react'
import { Avatar, Box, makeStyles } from '@material-ui/core'

import {
  NxScoreboardRows,
  ScoreboardRow,
  NxTypography,
  NxTooltip,
} from '@playvs-inc/nexus-components'

import {
  Game,
  SmashUltimateGameStatsPlayerFragment,
  useGetSmashGameStatsQuery,
} from '@plvs/graphql/generated'
import { getContextualTeams, MatchAssistantMatch } from '@plvs/utils'
import { mapSeriesData } from './MatchFinalScore.helpers'

interface SmashFinalScoreProps {
  match?: MatchAssistantMatch
  homeTeamId: string
  awayTeamId: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(-2, -3),
  },
  rightTriangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '6px 0 6px 12px',
    borderColor: `transparent transparent transparent ${
      theme.palette.ColorIconSuccess || 'green'
    } `,
  },
  leftTriangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '6px 12px 6px 0',
    borderColor: `transparent ${
      theme.palette.ColorIconSuccess || 'green'
    } transparent`,
  },
  avatar: {
    height: 54,
    width: 54,
  },

  vs: {
    padding: `0 10px 0 10px`,
  },
  series: {
    '&:not(:last-child)': {
      borderBottom: `2px solid ${theme.palette.BorderLight}`,
    },
  },
}))

export const SmashFinalScore: React.FC<SmashFinalScoreProps> = ({
  match,
  homeTeamId,
  awayTeamId,
}) => {
  const styles = useStyles()

  const smashGames: Pick<Game, 'id' | 'seriesId'>[] = [...(match?.games ?? [])]
  const smashInput =
    smashGames.map((game) => {
      const series = match?.series?.find(({ id }) => id === game.seriesId)
      const { team1, team2 } = getContextualTeams(series?.teamContext?.teams)
      return {
        gameId: game.id,
        playerIds: [...(team1?.roster || []), ...(team2?.roster || [])].map(
          ({ id }) => id
        ),
      }
    }) || []

  const { data: statsData } = useGetSmashGameStatsQuery({
    variables: {
      input: smashInput,
      esportSlug: match?.esport?.slug,
    },
    skip: !smashInput.length || !match?.esport?.slug,
  })
  const gameStats = statsData?.getSmashUltimatePlayerStatsByGameIds?.results

  const seriesData = mapSeriesData(match, homeTeamId, awayTeamId)

  const smashAvatar = (
    { characterPlayedOption }: SmashUltimateGameStatsPlayerFragment,
    options: { isHome?: boolean; justifyContent?: string } = {}
  ): React.ReactElement => (
    <Box display="flex" justifyContent={options.justifyContent}>
      <NxTooltip
        arrow
        placement="top-end"
        title={characterPlayedOption.displayName}
      >
        <Avatar
          alt={characterPlayedOption.displayName}
          className={styles.avatar}
          src={characterPlayedOption.assetUri ?? undefined}
        />
      </NxTooltip>
    </Box>
  )

  return (
    <Box className={styles.container} data-testid="scoreboard-rows">
      {seriesData.map((series, ndx) => {
        const matchSeries = match?.series?.[ndx]
        const seriesGameIds = match?.games
          ?.filter(({ seriesId }) => seriesId === matchSeries?.id)
          .map(({ id }) => id)

        const gamesData = gameStats?.filter(({ gameId }) =>
          seriesGameIds?.includes(gameId)
        )

        const gameRowsData: ScoreboardRow[] =
          gamesData?.map(({ stagePlayed, winner, players }, gameNdx) => {
            const homePlayer =
              players[0].teamId === homeTeamId ? players[0] : players[1]
            const awayPlayer =
              players[0].teamId !== homeTeamId ? players[0] : players[1]

            return {
              title: `Game ${gameNdx + 1}`,
              subtitle: stagePlayed || '',
              showScore: false,
              customMiddle: (
                <>
                  <Box
                    className={styles.leftTriangle}
                    data-cy="leftTriangle"
                    visibility={
                      winner === homePlayer.playerId ? 'visible' : 'hidden'
                    }
                  />
                  <NxTypography className={styles.vs} variant="h4">
                    vs
                  </NxTypography>
                  <Box
                    className={styles.rightTriangle}
                    data-cy="rightTriangle"
                    visibility={
                      winner === awayPlayer.playerId ? 'visible' : 'hidden'
                    }
                  />
                </>
              ),
              leftSide: {
                id: homePlayer.playerId,
                Avatar: smashAvatar(homePlayer, {
                  isHome: true,
                  justifyContent: 'flex-end',
                }),
                score: winner === homePlayer.playerId ? 1 : 0,
                subtitles: [],
              },
              rightSide: {
                id: awayPlayer.playerId,
                Avatar: smashAvatar(awayPlayer),
                score: winner === awayPlayer.playerId ? 1 : 0,
                subtitles: [],
              },
            }
          }) || []

        return (
          <Box
            key={series.title}
            alignItems="center"
            className={styles.series}
            display="flex"
            flexDirection="column"
          >
            <Box bgcolor="ColorBackgroundBody" flex={1} py={3} width="100%">
              <NxScoreboardRows data={[series]} />
            </Box>

            {Boolean(gameRowsData.length) && (
              <Box bgcolor="ColorBackgroundBase" flex={1} py={3} width="100%">
                <NxScoreboardRows data={gameRowsData} />
              </Box>
            )}
          </Box>
        )
      })}
    </Box>
  )
}
