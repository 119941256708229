import { Grid, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import React from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import UnverifiedCoachCard from './UnverifiedCoachCard'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.ColorTextAlt2 || theme.palette.text.hint,
  },
}))

const UnverifiedCoachUsers: React.FC<{
  pendingCoaches: {
    avatarUrl: string
    email: string
    id: string
    name: string
    schoolId: string
  }[]
}> = ({ pendingCoaches }) => {
  const classes = useStyles()

  return (
    <Box overflow="auto" pb={3} pt={2}>
      {pendingCoaches.length ? (
        <Grid container spacing={2} style={{ width: '100%' }} wrap="nowrap">
          {pendingCoaches.map((user) => (
            <Grid key={user.id} item>
              <UnverifiedCoachCard
                avatarUrl={user.avatarUrl ?? ''}
                email={user.email}
                id={user.id}
                name={user.name ?? ''}
                schoolId={user.schoolId}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NxTypography className={classes.subtitle} variant="body1">
          You do not have any unverified coaches.
        </NxTypography>
      )}
    </Box>
  )
}

export default UnverifiedCoachUsers
