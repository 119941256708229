import numeral from 'numeral'
import {
  curry,
  init,
  join,
  last,
  map,
  zipObj,
  trim,
  split,
  identity,
  defaultTo,
} from 'ramda'

export const pluralize = (
  qty: number,
  singular: string,
  plural: string
): string => {
  return qty === 1 ? `${qty} ${singular}` : `${qty} ${plural}`
}
export const None = identity

/**
 * 1 => 1st
 * 2 => 2nd
 */
export const Placing = (x: any): string => numeral(x).format('0o')

/**
 * 1234 => 1,234
 * 1234.5 => 1,234
 */
export const Comma = (x: any): string => numeral(x).format('0,0')

/**
 * 1234 => 1k
 * 12345678 => 12m
 */
export const Km = (x: any): string => numeral(x).format('0a')

/**
 * 1 => 100%
 * .105 => 10%
 */
export const Percent = (x: any): string => numeral(x).format('0%')

/**
 * 1 => 100%
 * .105 => 10.50%
 */
export const PercentWithTwoDecimalPlaces = (x: any): string =>
  numeral(x).format('0.[00]%')

/**
 * 1 => 100%
 * .105 => 10.5%
 */
export const PercentWithOneDecimalPlace = (x: any): string =>
  numeral(x).format('0.[0]%')

/**
 * 1.444 => 1
 * 1.666 => 2
 */
export const Round = (x: any): string => numeral(x).format('0')

/**
 * 1.444 => 1.4
 * 1.666 => 1.7
 */
export const RoundToOneDecimalPlace = (x: any): string =>
  numeral(x).format('0.[0]')

/**
 * 1.444 => 1.44
 * 1.666 => 1.67
 */
export const RoundToTwoDecimalPlaces = (x: any): string =>
  numeral(x).format('0.[00]')

// (key => a, ['key_1', 'key_2']) => { 'key_1': a, 'key_2': a }
export const objFromKeys = curry(
  (fn, keys): Record<string, unknown> => zipObj(keys, map(fn, keys))
)

// ['one']                 => 'one'
// ['one', 'two']          => 'one and two'
// ['one', 'two', 'three'] => 'one, two, and three'
export const arrayToCommaString = (a: string[], conjuction = 'and'): string => {
  if (!a.length) return ''
  if (a.length === 1) return a[0]
  if (a.length === 2) return `${a[0]} ${conjuction} ${a[1]}`
  return `${join(', ', init(a))}, ${conjuction} ${last(a)}`
}

export const arrayToCommaOrString = (a: string[]): string =>
  arrayToCommaString(a, 'or')

export const convertUSD = (num: number): string => {
  const USD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return USD.format(num / 100)
}
// 'x, y, z ' => ['x', 'y', 'z']
export const splitByCommaAndTrim = (str: string): string[] | undefined => {
  if (!str) return undefined

  return map(trim, split(',', str))
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, '')
    const numbers = cleaned.split('')
    if (numbers.length === 7) {
      return `${numbers.slice(0, 3).join('')}.${numbers.slice(3).join('')}`
    }
    if (numbers.length === 10) {
      return `${numbers.slice(0, 3).join('')}.${numbers
        .slice(3, 6)
        .join('')}.${numbers.slice(6).join('')}`
    }
  }
  return phoneNumber
}

export const formatPhoneNumberWithExt = (
  phoneNumber: string,
  ext?: string | null
): string => {
  const baseNumber = formatPhoneNumber(phoneNumber)
  if (baseNumber && ext) {
    return `${baseNumber} ext. ${ext}`
  }
  return baseNumber
}

export const formatPhoneNumberFriendly = (phoneNumber: string): string => {
  const cleaned = phoneNumber.replace(/\D/g, '')
  const numbers = cleaned.split('')

  if (numbers.length === 0) {
    return ''
  }

  // Not US or CAD phone number, skip formatting
  if (numbers.length > 10) {
    return phoneNumber
  }

  let formattedNumber = `(ddd) ddd-dddd`
  numbers.forEach((digit) => {
    formattedNumber = formattedNumber.replace('d', digit)
  })

  const dIndex = formattedNumber.indexOf('d')
  return dIndex >= 0 ? formattedNumber.slice(0, dIndex) : formattedNumber
}

export const deslugify = (slug: string): string =>
  slug
    .split('-')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
    })
    .join(' ')

// getBaseLog(2, 8) => 3
// getBaseLog(2, 16) => 4
export const getBaseLog = (x: number, y: number): number =>
  Math.log(y) / Math.log(x)

export const defaultToEmptyString = defaultTo('')
export const defaultToQuestionMark = defaultTo('?')
export const defaultToNull = defaultTo(null)
export const defaultTo0 = defaultTo(0)
export const defaultToEmptyArray = defaultTo([])
