import React from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useStyles } from '../AppDrawerToggle.styles'

export const EmptyChats: React.FC = () => {
  const isMobile = useBreakpointSm()
  const classes = useStyles({ isMobile })

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <NxSpot
        domain="chat"
        height="100px"
        size="large"
        variant="secondary"
        width="100px"
      />
      <NxTypography className={classes.emptyTitle} variant="h3">
        No Messages Yet
      </NxTypography>
      <NxTypography className={classes.emptyBody} variant="body1">
        Check back for messages on gameday
      </NxTypography>
    </Box>
  )
}
