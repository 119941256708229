import { isObjLike, isString, isArray } from 'ramda-adjunct'
import * as yup from 'yup'
import { ObjectSchema } from 'yup'
import { FieldValues, Resolver } from 'react-hook-form'
import dayjs from 'dayjs'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { sanitizeString } from './utils'

const phoneUtil = PhoneNumberUtil.getInstance()

export const cleanGraphQLError = (error: string): string => {
  return error.replace('GraphQL error:', '').trim()
}

export const formErrorToString = (
  error?: string | { message?: string },
  defaultMessage?: string
): string | undefined => {
  if (isString(error)) return error
  let err = error
  if (isArray(error)) {
    ;[err] = error
  }
  if (isObjLike(err)) {
    const errorMessage = err?.message || defaultMessage || 'Unknown error'
    return cleanGraphQLError(errorMessage)
  }
  return undefined
}

export const yupNumber = yup
  .number()
  .transform(
    (value, originalValue): yup.NumberSchema =>
      originalValue === '' ? undefined : value
  )

export const yupString = yup.string()

export const yupPriceAmount = yup.string().matches(/\d+(\.\d{1,2})?/, {
  excludeEmptyString: true,
  message: 'You must only enter digits seperated by commas and/or periods',
})

export const yupEmail = yup
  .string()
  .email('Invalid email address')
  .test({
    name: 'Email',
    message: 'Invalid email address',
    test: (value) => {
      return /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+)?$/.test(
        value
      )
    },
  })

export const yupEmailRequired = yupEmail.required('Email address is required')

export const yupFirstName = yup
  .string()
  .trim()
  .max(35, 'First Name cannot be longer than 35 characters.')

export const yupFirstNameRequired = yupFirstName.required(
  'Please provide your first name'
)

export const yupGradYear = yup.string().matches(/^\d{4}$/, {
  excludeEmptyString: true,
  message: 'Must be exactly 4 digits',
})

export const yupGradYearRequired = yupGradYear.required(
  'Enter your graduation year'
)

export const yupLastName = yup
  .string()
  .trim()
  .max(35, 'Last Name cannot be longer than 35 characters.')

export const yupLastNameRequired = yupLastName.required(
  'Please provide your last name'
)

export const passwordRequirementsValidation = yup
  .string()
  .test(
    'passwordLength',
    'Password must be between 8-64 characters long.',
    (value): boolean => {
      return value?.length >= 8 && value?.length <= 64
    }
  )

export const yupMatchPassword = yup
  .string()
  .oneOf([yup.ref('password'), null], 'Passwords must match')

export const yupPhoneNumber = yup.string().matches(/^\d{10}$/, {
  excludeEmptyString: true,
  message: 'Must be exactly 10 digits',
})

export const yupPhoneNumberRequired = yupPhoneNumber.required(
  'Please provide your phone number'
)

export const yupPhoneExtension = yup.string()

export const yupWhoPays = yup
  .string()
  .matches(/(school|students)/, { excludeEmptyString: true })
  .required()

export const yupEsport = yup.string()

export const yupEsportRequired = yupEsport.required('Esport is required')

export const yupTeamId = yup.string()

export const yupTeamIdRequired = yupTeamId.required('Team ID is required')

export const yupRating = yup.string()

export const yupRatingRequired = yupTeamId.required(
  'Scrimmage Rating is required'
)

export const yupTime = yup.string()

export const yupTimeRequired = yupTime.required('Time is required')

export const yupDate = yup.string()

export const yupDateRequired = yupDate.required('Date is required')

export const yupNote = yup
  .string()
  .min(20, 'Please use more than 20 characters.')
  .max(2000, 'Please use less than 2000 characters')

export const yupTeamNameValidation = yup
  .string()
  .min(3, 'Team Name must be at least 3 characters.')
  .max(40, 'Team Name must be 40 characters or less.')
  .matches(
    /^[A-z0-9\s]+$/,
    'Team Name must contain alphanumeric characters only.'
  )
  .test(
    'appropriate',
    'Do not include profane or inappropriate language',
    (value): boolean => {
      return value ? !sanitizeString(value).isProfane : false
    }
  )

/**
 * A custom resolver to use with `react-hook-form`'s `useForm` function.
 * By default `react-hook-form` forms only return a single error at a time
 * for a given field, even if it has multiple validation checks. This
 * resolver aggreates all validation errors for a given field and returns
 * them all.
 *
 * This is useful in situations where we want to show users all
 * validation errors at once.
 *
 */
// I tried setting this to `Record<string, unknown>` instead of `object`, but react-hook-form expects an `object` type for the resolver property...
export function formMultiErrorResolver<
  T extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/ban-types
  V extends object = object
>(validationSchema: ObjectSchema): Resolver<T, V> {
  return async (data) => {
    try {
      const values = await validationSchema.validate(data, {
        abortEarly: false,
      })
      return {
        values,
        errors: {},
      }
    } catch (errors: any) {
      return {
        values: {},
        errors:
          errors?.inner?.reduce(
            (
              accum: { [key: string]: [] },
              {
                path,
                type,
                message,
              }: { path: string; type: string; message: string }
            ) => {
              const allErrors: {
                [key: string]: [{ [key: string]: string }?]
              } = { ...accum }
              if (!allErrors[path]) {
                allErrors[path] = []
              }
              allErrors[path].push({
                type,
                message,
              })
              return allErrors
            },
            {}
          ) ?? {},
      }
    }
  }
}

export const yupFacultyRoleRequired = yup
  .string()
  .required(
    'Please enter the role that best describes your job function at your school'
  )

export const yupDateOfBirthRequired = yup
  .string()
  .required('Please enter your Date of Birth')
  .test({
    name: 'DOB',
    message: 'You must enter a valid date.',
    test: (value) => {
      return (
        dayjs(value).isBefore(dayjs()) &&
        dayjs(value).isAfter(dayjs().subtract(100, 'years'))
      )
    },
  })

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  try {
    const cleanedNumber = phoneNumber.replace(/\D/g, '')
    const numberUs = phoneUtil.parseAndKeepRawInput(cleanedNumber, 'US')
    const numberCa = phoneUtil.parseAndKeepRawInput(cleanedNumber, 'CA')

    return (
      phoneUtil.isValidNumber(numberUs) || phoneUtil.isValidNumber(numberCa)
    )
  } catch (e) {
    // Non parseable phone numbers are invalid.
  }
  return false
}

export function isInputValid(input: object, schema: ObjectSchema): boolean {
  return schema.isValidSync(input)
}

export const yupPhoneNumberIsValid = yup.string().test({
  name: 'phone',
  message: 'Invalid phone number.',
  test: (value): boolean => {
    return isValidPhoneNumber(value)
  },
})
