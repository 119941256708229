import React from 'react'

import { User } from '@plvs/graphql'
import { NxTypography, NxUserCluster } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { IconButton, makeStyles } from '@material-ui/core'
import { Duplicate } from '@playvs-inc/nexus-icons'
import { truncate } from '@plvs/rally/features/explore/LeagueMatches/leagueMatchesHelpers'
import copy from 'clipboard-copy'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
}))

export type MobileUserClusterProps = Pick<
  User,
  'id' | 'avatarUrl' | 'name' | 'email'
>

export const MobileUserCluster: React.FC<MobileUserClusterProps> = ({
  avatarUrl,
  name,
  id,
  email,
}) => {
  const classes = useStyles()

  const handleCopy = (): void => {
    copy(email || '')
  }

  return (
    <Box pr={3}>
      <NxUserCluster
        avatarHashId={id ?? ''}
        avatarUrl={avatarUrl ?? undefined}
        subtitles={[
          {
            title: (
              <Box alignItems="center" display="flex">
                <NxTypography variant="body1">
                  {truncate(email || '', 20)}
                </NxTypography>
                <IconButton className={classes.icon} onClick={handleCopy}>
                  <Duplicate />
                </IconButton>
              </Box>
            ),
          },
        ]}
        title={name || 'No name'}
      />
    </Box>
  )
}
