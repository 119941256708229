import React from 'react'
import { NxTextInput } from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'

interface Props {
  dismissalTime: string
  register: any
  timezone: string | null
  variant: 'error' | 'default'
}

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    '& input::-webkit-date-and-time-value': {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 0, 0, 1),
        textAlign: 'left',
      },
    },
  },
}))

export const DismissalTimeControl: React.FC<Props> = ({
  dismissalTime,
  register,
  timezone,
  variant,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.inputContainer}>
      <NxTextInput
        ref={register}
        data-testid="dismissalTime-input"
        defaultValue={dismissalTime}
        fullWidth
        helperText="This is the time that classes end for most students at your school."
        label={`Dismissal Time${timezone ? ` (${timezone})` : ' (Local Time)'}`}
        max="23:59"
        min="12:00"
        name="dismissalTime"
        style={{
          width: '800px',
          maxWidth: '100%',
        }}
        type="time"
        variant={variant}
      />
    </Box>
  )
}
