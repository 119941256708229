import { FetchResult, MutationFunctionOptions } from '@apollo/client'
import {
  AdminSaveGameResultLiveMutation,
  AdminSaveGameResultLiveMutationVariables,
  SaveGameResultLiveMutation,
  SaveGameResultLiveMutationVariables,
  Asset,
  UploadMatchSettleRequestAssetMutationVariables,
  UploadMatchSettleRequestAssetMutation,
} from '@plvs/graphql/generated'

export enum ReportStepIndex {
  DefaultIndex,
  GameSelectionIndex,
  MatchScoreReviewIndex,
  MatchFinalScoreIndex,
}

export type MatchReportCardStep = {
  title?: string
  titleDetail?: string
  MainContent?: React.ReactNode
  SideContent?: React.ReactNode
  Footer?: React.ReactNode
  showCard: boolean
}

export type SaveGameResultLiveFunction = (
  options?:
    | MutationFunctionOptions<
        SaveGameResultLiveMutation,
        SaveGameResultLiveMutationVariables
      >
    | undefined
) => Promise<
  FetchResult<
    SaveGameResultLiveMutation,
    Record<string, any>,
    Record<string, any>
  >
>

export type UploadFileFunction = (
  options?:
    | MutationFunctionOptions<
        UploadMatchSettleRequestAssetMutation,
        UploadMatchSettleRequestAssetMutationVariables
      >
    | undefined
) => Promise<
  FetchResult<
    UploadMatchSettleRequestAssetMutation,
    Record<string, any>,
    Record<string, any>
  >
>

export type Attachment = Pick<Asset, 'id' | 'fileName' | 'signedUrl'>

export type AdminSaveGameResultLiveFunction = (
  options?:
    | MutationFunctionOptions<
        AdminSaveGameResultLiveMutation,
        AdminSaveGameResultLiveMutationVariables
      >
    | undefined
) => Promise<
  FetchResult<
    AdminSaveGameResultLiveMutation,
    Record<string, any>,
    Record<string, any>
  >
>
