import { yupResolver } from '@hookform/resolvers'
import { makeStyles } from '@material-ui/core'
import { Path, LocalStorageKey, NASEF_QUERY_PARAM } from '@plvs/const'
import {
  Gender,
  useAcceptTosMutation,
  useGetMissingFieldsAtAppLaunchQuery,
  useGetMyAccountDetailsQuery,
  useJoinTeamMutation,
  useSetMyAccountSettingsMutation,
  useSetMyUserDemographicsMutation,
} from '@plvs/graphql'
import * as analytics from '@plvs/respawn/features/analytics'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { Box } from '@plvs/respawn/features/layout'
import { UserNameInput } from '@plvs/rally/components/onboard/types'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import { OnboardUserNameForm } from '@plvs/rally/containers/onboard/v2/components/OnboardUserNameForm'
import {
  assert,
  cleanGraphQLError,
  yupFirstNameRequired,
  yupLastNameRequired,
} from '@plvs/utils'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useNavigate } from 'react-router-dom'
import { EthnicityValue } from '@plvs/respawn/features/account/ethnicity-autocomplete/types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAuthRenderControllerContext } from '@plvs/respawn/renderController'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'
import {
  getRaces,
  getUsernameNextPath,
  isUsernameStepDisabled,
} from './onboardHelpers'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
}))

const UserNameDetails = yup.object().shape({
  firstName: yupFirstNameRequired,
  lastName: yupLastNameRequired,
})

interface Props {
  isParentSignup?: boolean
}

export const OnboardUserName: React.FC<Props> = ({
  isParentSignup = false,
}) => {
  const { refetch } = useGetMissingFieldsAtAppLaunchQuery({
    nextFetchPolicy: 'cache-first',
  })
  const flags = useFlags()
  const { auth } = useAuthRenderControllerContext()
  const { shouldShowFullDemographicForm } = auth.getAuthComponentsToRender({
    location: window.location.href,
  })
  const isUserDemographicsEnabled = flags.userDemographicFields

  const { orgId, emails, phone } = useUserIdentityFn()

  // State Vars
  const [isNotFilled, setIsNotFilled] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)
  // Used to fix label overlapping
  const [nameValues, setNameValues] = useState({ firstName: '', lastName: '' })

  const [areaState, setAreaState] = useState<string | undefined>(undefined)
  const [country, setCountry] = useState<string | undefined>(undefined)
  const [ethnicities, setEthnicities] = useState<EthnicityValue[]>([])
  const [gender, setGender] = useState<Gender | undefined>(undefined)

  // Hooks
  const navigate = useNavigate()
  const classes = useStyles()
  const { assign, data: onboardingContext } = useOnboardingContext()

  // Queries and mutations
  const [
    setMyAccountMutation,
    { loading: isMutating },
  ] = useSetMyAccountSettingsMutation()
  const [
    acceptTosMutation,
    { loading: isAcceptingTos },
  ] = useAcceptTosMutation()
  const [
    associateTeamMutation,
    { loading: isJoiningTeam },
  ] = useJoinTeamMutation()
  const [
    setMyUserDemographics,
    { loading: isSettingDemographics },
  ] = useSetMyUserDemographicsMutation()

  const { userId } = useUserIdentityFn()
  const {
    data: accountData,
    loading: accountLoading,
  } = useGetMyAccountDetailsQuery({
    fetchPolicy: 'network-only',
  })

  // Computed Props
  const hasCompletedName =
    !!accountData?.me?.firstName && !!accountData.me.lastName

  const { firstName, lastName, state, country: userCountry, userDemographics } =
    accountData?.me ?? {}

  // Handlers
  const defaultValues = {
    firstName: accountData?.me?.firstName ?? '',
    lastName: accountData?.me?.lastName ?? '',
  }

  const isDemographicRequired =
    !flags.userDemographicFields ||
    (flags.userDemographicFields &&
      userDemographics?.race &&
      userDemographics?.gender)

  const hasCompletedOnboardingData =
    userId && firstName && lastName && state && userCountry

  const showNASEFUx = onboardingContext?.isNasefSignUp ? NASEF_QUERY_PARAM : ''
  const { errors, handleSubmit, register, getValues, reset } = useForm<
    UserNameInput
  >({
    mode: 'onBlur',
    resolver: yupResolver<UserNameInput>(UserNameDetails),
    defaultValues: useMemo(() => {
      return defaultValues
    }, [onboardingContext]),
  })

  // Side effects
  useEffect(() => {
    // Notify analytics upon user entering this page.
    analytics.userEnteredNameAndDateOfBirth({
      userId: userId ?? '',
    })

    // Only runs on component init.
  }, [hasCompletedName])

  useEffect(() => {
    reset(defaultValues)
    setNameValues({
      firstName: (onboardingContext?.firstName as string) ?? '',
      lastName: (onboardingContext?.lastName as string) ?? '',
    })
  }, [onboardingContext])

  useEffect(() => {
    if (
      hasCompletedOnboardingData &&
      isDemographicRequired &&
      !accountLoading
    ) {
      navigate(`/app/dashboard`, { replace: true })
    }
  }, [accountLoading, isDemographicRequired])

  // Form handlers
  const onFormUpdate = (): void => {
    const values = getValues()
    setNameValues({
      firstName: values.firstName,
      lastName: values.lastName,
    })
    const currentIsNotFilled = !values.firstName || !values.lastName

    if (currentIsNotFilled !== isNotFilled) {
      setIsNotFilled(currentIsNotFilled)
    }
  }

  const submitUserAccountDetails = handleSubmit(
    async (input: UserNameInput): Promise<void> => {
      try {
        let mutationError: Error | undefined
        try {
          await setMyAccountMutation({
            variables: {
              input: {
                firstName: input.firstName,
                lastName: input.lastName,
                state: areaState,
                country,
              },
            },
          })
        } catch (e: any) {
          if (
            e?.message !==
            "Unfortunately we're unable to complete your account update."
          ) {
            throw e
          }
          mutationError = new Error(e.message)
        }

        if (gender && ethnicities.length > 0 && isUserDemographicsEnabled) {
          await setMyUserDemographics({
            variables: {
              input: { gender, race: ethnicities.map(getRaces) },
            },
          })
        }

        await refetch()
        // Associate invited team if there are any.
        const teamInviteDataRaw = localStorage?.getItem(
          LocalStorageKey.InvitedTeamData
        )
        if (teamInviteDataRaw) {
          // Note: parsing should be reverse of how it was encoded in
          // packages/rally/src/containers/youthInvite/YouthInvite.tsx
          const teamInviteData = JSON.parse(teamInviteDataRaw)
          assert(teamInviteDataRaw)
          assign({ invitedTeamId: teamInviteData.resourceId })

          await associateTeamMutation({
            variables: {
              teamId: teamInviteData.resourceId,
              options: {
                roleInviteCode: teamInviteData.code,
              },
            },
          })

          localStorage.removeItem(LocalStorageKey.InvitedTeamData)
        }

        if (mutationError) {
          navigate(`${Path.Forbidden}${showNASEFUx}`, {
            state: {
              message:
                "Unfortunately we're unable to complete your account signup process at this time",
              subtext:
                'Please contact support at support@playvs.com if you have any questions.',
              title: 'PlayVS',
            },
          })
          return
        }

        await acceptTosMutation()

        assign({ ...input })

        // if the user is already verified, skip verification step
        const isVerified = Boolean(!!phone || emails?.[0]?.isVerified === true)

        navigate(
          getUsernameNextPath({
            isParentSignup,
            showNASEFUx,
            shouldGoToVerifyEmailStep:
              shouldShowFullDemographicForm && !isVerified,
            search: window.location.search,
            shouldGoToDashboard:
              // orgId check for rally, isVerified check for stadium
              Boolean(orgId) || (isVerified && shouldShowFullDemographicForm),
            isStillOnboarding: true,
            shouldGoToStadiumVerifySmsStep:
              flags.stadiumUserAccountVerification && !isVerified,
          })
        )
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(e)
        }
      }
    }
  )

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  const isLoading =
    isMutating || isAcceptingTos || isJoiningTeam || isSettingDemographics

  return (
    <form
      autoComplete="off"
      className={classes.form}
      noValidate
      onChange={onFormUpdate}
      onSubmit={submitUserAccountDetails}
    >
      <QuestionnaireCard
        buttonOpacity={isNotFilled}
        childWidth={416}
        disableContinue={isUsernameStepDisabled({
          isLoading,
          isUserDemographicsEnabled,
          gender,
          ethnicities,
          country,
          state: areaState,
          nameValues,
          shouldShowFullDemographicForm,
        })}
        isContinuing={isLoading}
        onContinue={submitUserAccountDetails}
        subtitle="Tell us a bit more about yourself to set up your account."
        title="Hi there!"
      >
        {errorMessage && (
          <Box maxWidth={400} pb={3} width="100%">
            <Banner
              subtitle={errorMessage}
              title="Unable to update your account"
              type={BannerType.Error}
            />
          </Box>
        )}

        <OnboardUserNameForm
          country={country}
          errors={errors}
          ethnicities={ethnicities}
          gender={gender}
          isUserDemographicsEnabled={isUserDemographicsEnabled}
          register={register}
          setAreaState={setAreaState}
          setCountry={setCountry}
          setEthnicities={setEthnicities}
          setGender={setGender}
          state={areaState}
          values={nameValues}
        />
      </QuestionnaireCard>
    </form>
  )
}
