import React from 'react'
import { EnrollmentProvider } from '@plvs/respawn/containers/enrollment/EnrollmentProvider'
import { useParams } from 'react-router'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { MySchoolLeagues } from '@plvs/rally/containers/filter/league/MySchoolLeagues'
import { SlotExclusionsProvider } from '@plvs/respawn/containers/enrollment/SlotExclusionsProvider'

interface Props {
  children: React.ReactNode
}

export const EnrollmentWrapper: React.FC<Props> = ({ children }) => {
  const params = useParams()

  const { id: orgId, loading } = useSelectedOrganizationFn()

  const { id: metaseasonId } = params

  if (loading) {
    return null
  }

  return (
    <MySchoolLeagues>
      <EnrollmentProvider
        includeOrganizationEnrollment
        isCoach
        metaseasonId={metaseasonId || ''}
        schoolId={orgId}
      >
        <SlotExclusionsProvider
          metaseasonId={metaseasonId || ''}
          schoolId={orgId}
        >
          {children}
        </SlotExclusionsProvider>
      </EnrollmentProvider>
    </MySchoolLeagues>
  )
}
