import {
  Season,
  PhaseFormat,
  TeamMember,
  UserRole,
  UserRoleName,
  ResourceType,
  League,
  EsportLeagueDefaults,
} from '@plvs/graphql'
import dayjs from 'dayjs'
import { filter, find, map, range } from 'ramda'
import { RelativeTiming } from '../timeUtils'

export type TeamMemberWithPosition = Pick<TeamMember, 'position'>

export const hasEnrolledTeams = ({
  teamEnrollments,
}: {
  teamEnrollments:
    | ({
        __typename?: 'TeamEnrollment'
      } & {
        enrollableSeasons: Pick<Season, 'id' | 'metaseasonId'>[]
        enrolledSeasons: Pick<
          Season,
          'id' | 'metaseasonId' | 'teamRegistrationEndsAt'
        >[]
      })[]
    | undefined
}): boolean => {
  if (!teamEnrollments) {
    return false
  }
  const today = dayjs()

  return (
    teamEnrollments?.some((teamE) =>
      teamE?.enrolledSeasons?.some((enrolledSeason) =>
        today.isBefore(dayjs(enrolledSeason?.teamRegistrationEndsAt))
      )
    ) ||
    teamEnrollments?.some((teamEnrollment) =>
      teamEnrollment.enrolledSeasons.some((enrolledSeason) =>
        teamEnrollment.enrollableSeasons.some(
          (enrollableSeason) =>
            enrollableSeason.metaseasonId === enrolledSeason.metaseasonId
        )
      )
    )
  )
}

export const teamFormatToString = ({
  teamFormat,
}: {
  teamFormat: number
}): string => {
  switch (teamFormat) {
    case 1:
      return 'solo'
    case 2:
      return 'duos'
    case 3:
      return 'trios'
    case 4:
      return 'quads'
    default:
      return `team of ${teamFormat}`
  }
}

// Gives either the top percentage of teams or top number of teams
export const calculateTeamSurvivalNumber = (
  totalAmountOfTeams: number,
  survivalAdvancementPercentage: number,
  survivalAdvancementCount: number
): number => {
  return (
    (survivalAdvancementPercentage > 0 &&
      Math.round(totalAmountOfTeams * survivalAdvancementPercentage * 0.01)) ||
    survivalAdvancementCount
  )
}

export const addFnSurvivalRankingAndFormat = (
  teams: any,
  survivalRankingNumber: number
): any => {
  return map(
    ({ team, stats }) => {
      return {
        team,
        stats: {
          ...stats,
        },
        format: PhaseFormat.Survival,
        survivalRankingNumber,
      }
    },
    [...teams]
  )
}

export const isDateLocked = (date: string | undefined | null): boolean => {
  return date ? dayjs(new Date(date)).isBefore(new Date()) : false
}

export const isSeasonRostersActivelyLocked = (
  metaseasonTiming: RelativeTiming | undefined,
  rostersLockAt: string | undefined | null
): boolean => {
  return (
    metaseasonTiming === RelativeTiming.Present && isDateLocked(rostersLockAt)
  )
}

export const isTeamOwnerOfLobbyTeam = (
  roles: Pick<
    UserRole,
    'resourceType' | 'resourceId' | 'roleName' | 'userId'
  >[],
  teamId: string
): boolean => {
  return roles?.some(
    (role) =>
      role.roleName === UserRoleName.Owner &&
      role.resourceId === teamId &&
      role.resourceType === ResourceType.Team
  )
}

const isStarter = (
  teamFormat: number
): (({ position }: TeamMemberWithPosition) => boolean) => ({
  position,
}: TeamMemberWithPosition): boolean => !!(position < teamFormat)

export function getStarters<T extends TeamMemberWithPosition>(
  teamMembers: T[],
  league: Pick<League, 'teamFormat'>
): T[] {
  if (!teamMembers || !league) return []
  return filter(isStarter(league.teamFormat), teamMembers)
}

export const getTeamFormat = ({
  teamFormat,
}: {
  teamFormat: number | null
}): number => teamFormat ?? 3

export function getPlayerList<T extends { position: number }>(
  members: T[],
  league: Pick<EsportLeagueDefaults, 'teamFormat' | 'teamMaxSize'>
): (T | { id: null; position: number })[] {
  return map(
    (i) =>
      find((member) => member?.position === i, members) || {
        id: null,
        position: i,
      },
    range(0, getTeamFormat(league))
  )
}

export const getTeamCaptainName = (team: {
  members: { isCaptain: boolean; name: string | null }[] | null
}): string => team?.members?.find(({ isCaptain }) => isCaptain)?.name ?? ''

export const canRemoveTeamMembers = (
  isRosterActivelyLocked: boolean,
  enrolledSeason?: Pick<Season, 'playerDeregistrationEndsAt'>,
  isAdmin?: boolean
): boolean => {
  if (isAdmin) {
    return true
  }

  const now = dayjs()
  return (
    (!enrolledSeason ||
      dayjs(enrolledSeason.playerDeregistrationEndsAt).isAfter(now)) &&
    !isRosterActivelyLocked
  )
}

export const canUnenrollTeam = (
  seasons: Pick<Season, 'teamDeregistrationEndsAt'>[]
): boolean => {
  const now = dayjs()
  return !seasons.some(({ teamDeregistrationEndsAt }) => {
    return dayjs(teamDeregistrationEndsAt).isBefore(now)
  })
}

/**
 * Restricts usage to features that support only head-to-head formats
 * or exactly two teams per match.
 *
 * Do not use for leaderboard features or cross-format functionalities.
 */
export function getContextualTeams(
  teams: any[] | null | undefined
): { team1: any; team2: any; opposingTeam: any; myTeam: any } {
  const team1 = teams?.[0]
  const team2 = teams?.[1]
  const myTeam = team1?.isMyTeam ? team1 : team2
  const opposingTeam = team1?.isMyTeam ? team2 : team1
  return {
    team1,
    team2,
    myTeam,
    opposingTeam,
  }
}

export function getContextualResourceIdsFromTeams(
  teams: {
    id: string
    schoolId: string | null | undefined
  }[]
): { ids: string[]; schoolIds: string[] } {
  return teams.reduce(
    (acc, team) => {
      acc.ids.push(team.id)
      if (team.schoolId) {
        acc.schoolIds.push(team.schoolId)
      }
      return acc
    },
    { ids: [], schoolIds: [] } as { ids: string[]; schoolIds: string[] }
  )
}
