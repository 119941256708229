import { ProductType } from '@plvs/client-data/models'
import {
  isCoachForScholasticRelatedTeam,
  isCaptainForResource,
  isTeamOwnerForResource,
} from '@plvs/utils'
import { PhaseType } from '@plvs/graphql/generated'
import {
  QueueComponentProps,
  QueueComponentReturn,
} from '../types/queueController.types'

export const determineQueueRender = ({
  productType,
  userRoles,
  team,
  phaseType,
}: QueueComponentProps): QueueComponentReturn => {
  const hideFAQs = phaseType === PhaseType.Qualifier
  return {
    canEnqueue:
      productType === ProductType.Scholastic
        ? isCoachForScholasticRelatedTeam(userRoles, team) ||
          isCaptainForResource(userRoles, [team.id])
        : isTeamOwnerForResource(userRoles, [team.id]),
    showFAQs: hideFAQs ? false : productType === ProductType.Scholastic,
  }
}
