import { Button, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import React from 'react'
import { Path } from '@plvs/const'
import { RouterLink } from '@plvs/respawn/features/route'
import { appendClasses } from '@plvs/utils'
import CreateTeam from '../../../../../assets/team-cards/create-team.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    scrollSnapAlign: 'start',
  },
  createTeamContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '33%',
    maxWidth: '20em',
    minWidth: '20em',
    height: '100%',
    minHeight: '14.6em',
    maxHeight: '20em',
    position: 'relative',
    boxSizing: 'border-box',
    border: '2px dashed',
    borderRadius: theme.spacing(1.25),
    borderColor: theme.palette.BorderLight,
  },
  ctaButton: {
    height: '2.6em',
    border: `1px solid ${theme.palette.BorderDark}`,
    marginTop: theme.spacing(2),
    textTransform: 'none',
  },
}))

export const EmptyCreateTeamCard: React.FC<{
  isMobile: boolean
}> = ({ isMobile }) => {
  const classes = useStyles({ isMobile })

  return (
    <Box
      className={appendClasses(
        classes.container,
        'TeamCard' /* This is used as a locator for '<TeamCardsDashboardContainer />' component. */
      )}
    >
      <Box className={classes.createTeamContainer}>
        <img alt="Icon" src={CreateTeam} />
        <Button
          className={classes.ctaButton}
          component={RouterLink}
          data-testid="create-team-dashboard"
          to={Path.CreateTeam}
          variant="outlined"
        >
          Create Team
        </Button>
      </Box>
    </Box>
  )
}
