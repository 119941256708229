import React, { useEffect } from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'

import { NxHeaderCard } from '@playvs-inc/nexus-components'

import { StepType, useTabNotification } from '@plvs/utils'
import { MatchFormat } from '@plvs/graphql/generated'
import { useMatchAssistantContext } from './useMatchAssistant'
import { MatchAssistantIntro } from './steps/general/intro/MatchAssistantIntro'
import { useMatchAssistantAdapterSteps } from './adapter/matchAssistantAdapterSteps'
import { useMatchAssistantStepAlertsContext } from './hooks/useMatchAssistantStepAlerts'
import { useMatchAssistantHelper } from './MatchAssistantHelper'

interface MatchAssistantProps {
  teamNameForSubtitle: string
  setIsMatchComplete: (isMatchComplete: boolean) => void
}
export const MatchAssistant: React.FC<MatchAssistantProps> = ({
  teamNameForSubtitle,
  setIsMatchComplete,
}) => {
  const {
    currentStepLoading,
    step,
    shouldRenderIntro,
    shouldRenderMatchAssistant,
    esportSlug,
    currentStep,
    stepIndex,
    isMyTurn,
    match,
  } = useMatchAssistantContext()

  const matchFormat = match?.format
  const showMatchAssistantActions = matchFormat === MatchFormat.HeadToHead

  const { assistantSteps } = useMatchAssistantAdapterSteps({
    esportSlug,
    setIsMatchComplete,
  })
  const {
    renderStepAlert,
    getStepAlerts,
  } = useMatchAssistantStepAlertsContext()
  const {
    openHelper,
    showHelper,
    alertData: helperAlertData,
  } = useMatchAssistantHelper()

  const [setTabNotification, clearTabNotification] = useTabNotification()

  useEffect(
    function addListeners() {
      const changeTabTitle = (): void => {
        // Flash tab title when the current step isn't submitted.
        // Ignore the Report step though because they'll be
        // playing their game.
        if (
          document.hidden &&
          isMyTurn &&
          currentStep?.step?.submitted === false &&
          step !== StepType.Report
        ) {
          setTabNotification('(Match) Your turn!')
        } else {
          clearTabNotification()
        }
      }

      window.addEventListener('visibilitychange', changeTabTitle)

      return (): void => {
        window.removeEventListener('visibilitychange', changeTabTitle)
      }
    },
    [step, currentStep?.step?.submitted === false, isMyTurn]
  )

  if (!shouldRenderMatchAssistant) {
    return <></>
  }

  const content = assistantSteps[step]
  const stepData = content?.[stepIndex]
  const stepAlertsData = getStepAlerts()
  const subtitle = `${stepData?.subtitle ?? ''} (${teamNameForSubtitle})`
  // Fallback for invalid/unsupported steps.
  if (shouldRenderIntro || (step && (!content || !stepData))) {
    return (
      <NxHeaderCard
        actions={stepData?.actions}
        alertData={showHelper ? helperAlertData : stepAlertsData}
        showAlert={showHelper || renderStepAlert}
        sideContentAction={openHelper}
        subtitle={subtitle}
        title={stepData?.title ?? ''}
      >
        <MatchAssistantIntro />
      </NxHeaderCard>
    )
  }

  // Example: A step would not be rendered for a match such as a bye match.
  const thereIsNoStepToRender = !currentStep && !currentStepLoading

  return (
    <Box className="shepherd-match-assistant" flex={1} minWidth="50%">
      <WaitTillLoaded
        loading={currentStepLoading}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        {thereIsNoStepToRender ? null : (
          <NxHeaderCard
            actions={showMatchAssistantActions ? stepData?.actions : undefined}
            alertData={showHelper ? helperAlertData : stepAlertsData}
            showAlert={showHelper || renderStepAlert}
            sideContentAction={openHelper}
            subtitle={subtitle}
            title={stepData?.title ?? ''}
          >
            <Box py={1}>{stepData?.step}</Box>
          </NxHeaderCard>
        )}
      </WaitTillLoaded>
    </Box>
  )
}
