import { ProductType } from '@plvs/client-data/models/ProductType'
import { Path } from '@plvs/const'
import {
  getContextualResourceIdsFromTeams,
  hasRoleForResource,
  ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS,
} from '@plvs/utils'
import { UserRoleName } from '@plvs/graphql/generated'
import {
  captainScheduleMenuItemIdsScholastic,
  coachScheduleMenuItemIds,
  MenuItemIdsForSchedulePageManageMatchDropdownProps,
  ownerScheduleMenuItemIds,
  playerScheduleMenuItemIdsNonScholastic,
  playerScheduleMenuItemIdsScholastic,
  ScheduleComponentDetailsProps,
  ScheduleComponentDetailsReturn,
  SchedulePageDropdownMenuItemsReturn,
} from '../types/scheduleController.types'
import { filterMenuItemIdsByMatchFormat } from '../../matchLobby/lobby/utils/lobbyRenderController.helpers'

export const determineScheduleRender = ({
  productType,
}: ScheduleComponentDetailsProps): ScheduleComponentDetailsReturn => {
  const isInStadium = productType === ProductType.Stadium

  if (isInStadium) {
    return {
      emptyMatchesButtonTitle: 'Explore Events',
      emptyMatchesButtonPath: Path.Dashboard,
      emptyMatchesSubtitleCopyPartial: 'You are not a part of any events',
    }
  }

  return {
    emptyMatchesButtonTitle: 'Explore Leagues',
    emptyMatchesButtonPath: Path.Explore,
    emptyMatchesSubtitleCopyPartial: 'You are not a part of any leagues',
  }
}

export const determineMenuItemIdsForScheduleManageMatchDropdown = ({
  competitionGroup,
  userRoles,
  teamsContext,
  matchFormat,
}: MenuItemIdsForSchedulePageManageMatchDropdownProps): SchedulePageDropdownMenuItemsReturn => {
  if (!competitionGroup) {
    return {
      items: [],
    }
  }
  // Early return if the user is not associated with the match.
  const isNotAssociatedWithAnyTeamsInMatch = !teamsContext.myTeams.length
  if (isNotAssociatedWithAnyTeamsInMatch) {
    return { items: [] }
  }

  const myTeamsResourceIds = getContextualResourceIdsFromTeams(
    teamsContext.myTeams
  )
  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    const isCoachForSomeResource = hasRoleForResource(
      userRoles,
      [...myTeamsResourceIds.ids, ...myTeamsResourceIds.schoolIds],
      UserRoleName.Coach
    )
    if (isCoachForSomeResource) {
      return {
        items: filterMenuItemIdsByMatchFormat({
          matchFormat,
          menuItemIds: coachScheduleMenuItemIds,
        }),
      }
    }
    const isCaptainForSomeResource = hasRoleForResource(
      userRoles,
      [...myTeamsResourceIds.ids, ...myTeamsResourceIds.schoolIds],
      UserRoleName.Captain
    )
    if (isCaptainForSomeResource) {
      return {
        items: filterMenuItemIdsByMatchFormat({
          matchFormat,
          menuItemIds: captainScheduleMenuItemIdsScholastic,
        }),
      }
    }
    return {
      items: filterMenuItemIdsByMatchFormat({
        matchFormat,
        menuItemIds: playerScheduleMenuItemIdsScholastic,
      }),
    }
  }
  const isTeamOwnerForResource = hasRoleForResource(
    userRoles,
    [...myTeamsResourceIds.ids],
    UserRoleName.Owner
  )
  const isCaptainForResource = hasRoleForResource(
    userRoles,
    [...myTeamsResourceIds.ids],
    UserRoleName.Captain
  )
  if (isTeamOwnerForResource || isCaptainForResource) {
    return {
      items: filterMenuItemIdsByMatchFormat({
        matchFormat,
        menuItemIds: ownerScheduleMenuItemIds,
      }),
    }
  }
  return {
    items: filterMenuItemIdsByMatchFormat({
      matchFormat,
      menuItemIds: playerScheduleMenuItemIdsNonScholastic,
    }),
  }
}
