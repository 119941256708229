import React from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import { SuccessOutlined } from '@playvs-inc/nexus-icons'
import { EsportSlug } from '@plvs/graphql/types'
import { NxTextLinkExternal, NxTooltip } from '@playvs-inc/nexus-components'
import { rocketLeaguePlatformMap } from '@plvs/respawn/features/esport/creator/esportStaticDetails'
import {
  PlayerAccountStatus,
  TeamStatus,
} from '@plvs/client-data/models/AllTeamsAccountStatus'

const getLink = (
  accountName: string,
  url: string,
  className: string
): React.ReactElement | null => {
  return (
    <NxTextLinkExternal
      className={className}
      data-testid="rosterCardLink"
      href={url}
      label={accountName}
    />
  )
}

export const getLinkedSubtitle = (
  accountName: string,
  esportSlug: EsportSlug | undefined,
  accountStatus: PlayerAccountStatus | undefined,
  className: string,
  isArchived: boolean
): React.ReactElement | string | null => {
  if (!accountName || isArchived) {
    return null
  }

  const accountNameEncoded = encodeURIComponent(accountName)

  if (esportSlug === EsportSlug.LeagueOfLegends) {
    // LoL op.gg links have a hashtag in them, which we don't want to encode. (link will break)
    // We want to replace the hashtag with a dash for op.gg links to work.
    const lolLink = `https://na.op.gg/summoners/na/${accountName.replace(
      '#',
      '-'
    )}`
    return getLink(accountName, lolLink, className)
  }
  if (esportSlug === EsportSlug.Valorant) {
    const valorantLink = `https://tracker.gg/valorant/profile/riot/${accountNameEncoded}`
    return getLink(accountName, valorantLink, className)
  }
  if (esportSlug === EsportSlug.RocketLeague && accountStatus?.platform) {
    const platform = rocketLeaguePlatformMap[accountStatus.platform]
    if (platform) {
      const rlLink = `https://rocketleague.tracker.network/rocket-league/profile/${platform}/${accountNameEncoded}/overview`
      return getLink(accountName, rlLink, className)
    }
  }
  if (esportSlug === EsportSlug.Overwatch) {
    const overWatchLink = `https://playoverwatch.com/en-us/search/?q=${accountNameEncoded}`
    return getLink(accountName, overWatchLink, className)
  }
  if (esportSlug === EsportSlug.MarvelRivals) {
    const marvelRivalsLink = `https://tracker.gg/marvel-rivals/profile/ign/${accountNameEncoded}/overview`
    return getLink(accountName, marvelRivalsLink, className)
  }
  return accountName
}

export const getStatusIconFromTeamStatus = (
  teamStatus: TeamStatus | undefined,
  isEnrolled: boolean,
  hasArchivedPlayer: boolean
): React.ReactElement | null => {
  if (!teamStatus) return null

  // NOTE: not using it from teamStatus b/c it doesn't account for the change season id yet.
  if (!isEnrolled) {
    return (
      <NxTooltip
        arrow
        data-testid="enrollStatusToolTip"
        placement="bottom-start"
        title="Not Enrolled"
      >
        <InfoIcon color="action" />
      </NxTooltip>
    )
  }

  if (!teamStatus?.areAllAccountsConnected) {
    const title = hasArchivedPlayer
      ? 'There are archived players or players with missing game accounts on the roster'
      : 'Players with missing game accounts on the roster'

    return (
      <NxTooltip
        arrow
        data-testid="accountConnectionStatusToolTip"
        maxWidth={1000}
        placement="bottom-start"
        title={title}
      >
        <ErrorIcon color="error" />
      </NxTooltip>
    )
  }

  if (teamStatus?.isMissingPlayers) {
    const title = hasArchivedPlayer
      ? 'There are archived players or missing players on the roster'
      : 'Missing players on the roster'

    return (
      <NxTooltip
        arrow
        data-testid="missingPlayersToolTip"
        maxWidth={1000}
        placement="bottom-start"
        title={title}
      >
        <InfoIcon color="action" />
      </NxTooltip>
    )
  }

  if (hasArchivedPlayer) {
    return (
      <NxTooltip
        arrow
        data-testid="archivedPlayersToolTip"
        placement="bottom-start"
        title="Archived players on the roster"
      >
        <ErrorIcon color="error" />
      </NxTooltip>
    )
  }

  return (
    <NxTooltip arrow data-testid="readyToolTip" title="Ready to Compete">
      <SuccessOutlined color="#008A00" />
    </NxTooltip>
  )
}
