import React from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import dayjs from '@plvs/respawn/init/dayjs'
import { BracketTeamMatchStatus } from '@plvs/graphql/generated'
import { CreateCSSProperties } from '@material-ui/styles'
import { NxBracketTeam } from './BracketTeam'
import { BracketMatch, BracketTeam } from './Bracket'
import {
  BRACKET_DATE_TIME_FORMAT,
  BRACKET_MATCH_HEIGHT,
  renderVerticalLine,
} from './bracketHelpers'
import useMatchHighlightContext from './useMatchHighlightContext'

const useStyles = makeStyles(() => ({
  match: ({ isMobile }: { isMobile: boolean }): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: '#000',
    width: isMobile ? '250px' : '363px',
    minHeight: '158px',
  }),
  matchHeader: { textAlign: 'end' },
  matchFooter: { textAlign: 'end' },
  horizontalLine: {
    width: '22px',
    height: '1px',
    marginLeft: '-2px',
  },
  verticalLine: {
    width: '1px',
  },
}))

export interface BracketMatchProps {
  match: BracketMatch
  matchIndex: number
  roundIndex: number
  topParty: BracketTeam
  bottomParty: BracketTeam
  esportColor: string
  numMatchesInNextRound: number
  numMatchesInRound: number
  hideVertLine?: boolean
}

export function NxBracketMatch({
  match,
  matchIndex,
  roundIndex,
  topParty,
  bottomParty,
  esportColor,
  numMatchesInNextRound,
  numMatchesInRound,
  hideVertLine,
}: BracketMatchProps): React.ReactElement {
  const isMobile = useBreakpointSm()
  const classes = useStyles({ isMobile })
  const { topHovered, bottomHovered } = useMatchHighlightContext({
    topPartyId: topParty.id,
    bottomPartyId: bottomParty.id,
  })
  const time = match.startTime
    ? dayjs(match.startTime).format(BRACKET_DATE_TIME_FORMAT)
    : 'TBD'
  const topHoveredAndWon =
    topHovered && topParty.status === BracketTeamMatchStatus.Win
  const bottomHoveredAndWon =
    bottomHovered && bottomParty.status === BracketTeamMatchStatus.Win
  const eitherHovered = topHovered || bottomHovered
  const backLineColor = eitherHovered
    ? `1px solid ${esportColor}`
    : '1px solid #ccc'
  const eitherHoveredAndWon = topHoveredAndWon || bottomHoveredAndWon
  const frontLineColor = eitherHoveredAndWon
    ? `1px solid ${esportColor}`
    : '1px solid #ccc'

  const isLastLowerBracketMatch =
    match.id.includes('lower') && match.nextMatchId?.includes('upper')

  const backwardLine =
    roundIndex === 0 ? (
      <></>
    ) : (
      <Box border={backLineColor} className={classes.horizontalLine} />
    )
  const forwardLine =
    !isLastLowerBracketMatch && match.nextMatchId ? (
      <Box border={frontLineColor} className={classes.horizontalLine} />
    ) : (
      <></>
    )

  const verticalLine = renderVerticalLine(
    BRACKET_MATCH_HEIGHT,
    frontLineColor,
    numMatchesInRound,
    numMatchesInNextRound,
    matchIndex,
    !!isLastLowerBracketMatch,
    !!hideVertLine,
    classes.verticalLine
  )

  return (
    <Box alignItems="center" display="flex" pb={4}>
      {!isMobile && backwardLine}
      <Box className={classes.match}>
        <Box className={classes.matchHeader}>
          <NxTypography variant="body4">{match.name}</NxTypography>
        </Box>
        <Box mb={1}>
          <NxBracketTeam
            esportColor={esportColor}
            hovered={topHovered}
            party={topParty}
          />
        </Box>
        <Box>
          <NxBracketTeam
            esportColor={esportColor}
            hovered={bottomHovered}
            party={bottomParty}
          />
        </Box>
        <Box className={classes.matchFooter}>
          <NxTypography variant="body4">{time}</NxTypography>
        </Box>
      </Box>
      {!isMobile && forwardLine}
      {!isMobile && verticalLine}
    </Box>
  )
}
