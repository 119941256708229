import React, { createContext, useContext } from 'react'

import { UseSlotExclusions } from '@plvs/respawn/containers/enrollment/types'
import { useSlotExclusionsData } from './useSlotExclusions'

export const SlotExclusionContext = createContext<UseSlotExclusions>({
  optionalSeasonWeeksCache: {},
  optionalWeeksCache: {},
  seasonSlotExclusionRangeData: [],
  addEnrollmentSeasonIds: () => {},
  removeEnrollmentSeasonId: () => {},
  setOptionalSeasonWeeksCache: () => {},
  setOptionalWeeksCache: () => {},
  slotExclusionRangeData: [],
  error: undefined,
  loading: false,
  updateSlotExclusionSelection: async () => false,
  numSchoolTeams: 0,
})

export const useSlotExclusions = (): UseSlotExclusions =>
  useContext(SlotExclusionContext)

interface Props {
  children: React.ReactNode
  metaseasonId: string
  schoolId: string
}

export const SlotExclusionsProvider: React.FC<Props> = ({
  children,
  metaseasonId,
  schoolId,
}) => {
  const slotExclusions = useSlotExclusionsData({ metaseasonId, schoolId })

  return (
    <SlotExclusionContext.Provider value={slotExclusions}>
      {children}
    </SlotExclusionContext.Provider>
  )
}
