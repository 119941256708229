import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { NxIconButton, NxTypography } from '@playvs-inc/nexus-components'
import { getLocationOrigin, subtractOriginFromUrl } from '@plvs/utils'
import TextField from '@material-ui/core/TextField'
import { Duplicate } from '@playvs-inc/nexus-icons'
import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(1, 0),
  },
  wrapper: {
    width: '100%',
  },
  textField: {
    width: '100%',
    marginRight: theme.spacing(1),
  },
  button: {
    width: 80,
    '& div': {
      alignItems: 'center',
    },
  },
}))

interface Props {
  overrideOrigin?: string
  link?: string
  onClickCopy(value: string): void
  label?: string
}

export const OnboardReferByLinkForm = ({
  overrideOrigin,
  link,
  onClickCopy,
  label,
  ...rest
}: Props): React.ReactElement => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const path = link ? subtractOriginFromUrl(link) : ''
  const origin = overrideOrigin ?? getLocationOrigin()
  const url = origin + path

  const handleCopy = async (): Promise<void> => {
    onClickCopy(url)
    copy(url)
    enqueueSnackbar('Copied!', { variant: 'success' })
  }

  return (
    <form
      className={classes.form}
      data-cy="refer-copy-link-form"
      noValidate
      {...rest}
    >
      <NxTypography data-testid="label" variant="body3">
        {label || 'Share your referral link'}
      </NxTypography>
      <Box alignItems="center" display="flex">
        <TextField
          className={classes.textField}
          data-cy="referral-text"
          data-testid="input"
          size="small"
          value={url}
          variant="outlined"
        />

        <NxIconButton
          className={classes.button}
          data-testid="copy"
          icon={<Duplicate />}
          label="Copy"
          onClick={handleCopy}
          variant="secondary"
        />
      </Box>
    </form>
  )
}
