import React, { useState } from 'react'

import { Box, Grid, useTheme } from '@material-ui/core'

import { useBreakpointXs } from '@plvs/respawn/features/layout'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { RosterContainer } from '@plvs/respawn/features/roster/RosterContainer'
import { EsportSlug } from '@plvs/graphql/types'
import {
  RosterCardMenuRowItems,
  RosterCardMenuRowSubItems,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import { useGetMatchRostersContainerDataQuery } from '@plvs/graphql/generated'
import { TeamStatsRosterHeader } from '@plvs/respawn/features/match-lobby/TeamStatsRosterHeader'
import { NxButton } from '@playvs-inc/nexus-components'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { defaultToEmptyString, MinimalMetaseason } from '@plvs/utils'

interface MatchRostersContainerProps {
  esportSlug: EsportSlug | null | undefined
  matchId: string
}

export const MatchRostersContainer: React.FC<MatchRostersContainerProps> = ({
  esportSlug,
  matchId,
}) => {
  const mobile = useBreakpointXs()
  const theme = useTheme()

  const [showAllTeams, setShowAllTeams] = useState(false)

  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()

  const {
    enableScoutingToTeamsPage,
  } = getMatchLobbyRenderControllerState().lobby

  const { data, loading } = useGetMatchRostersContainerDataQuery({
    variables: {
      matchId,
    },
    skip: !matchId,
  })

  const myTeams = data?.match?.teamContext?.myTeams ?? []
  const otherTeamsFromData = data?.match?.teamContext?.otherTeams ?? []

  const match = data?.match
  const teams = myTeams.length
    ? myTeams.concat(otherTeamsFromData)
    : data?.match?.teamContext?.teams ?? []
  const initialTeams = teams.slice(0, 2)
  const otherTeams = teams.slice(2)
  const canShowAllTeamsButton = Boolean(otherTeams.length) && !showAllTeams
  const metaseason = { id: defaultToEmptyString(match?.metaseasonId) }

  const rosterComponent = (team): React.ReactNode => (
    <Grid
      key={team.id}
      data-cy={team.name}
      data-testid={team.name}
      item
      xs={mobile ? 12 : 6}
    >
      <MatchCard px={0}>
        <RosterContainer
          CustomHeader={(): React.ReactElement => (
            <TeamStatsRosterHeader
              enableScoutingToTeamsPage={enableScoutingToTeamsPage}
              esportSlug={esportSlug}
              hasClinchedPlayoffs={
                !!team?.regularSeasonTeamPhaseStandings?.hasClinchedPlayoffs
              }
              isTeamDeleted={!!team?.archivedAt}
              phaseId={match?.slot?.phase?.id}
              ranking={team?.regularSeasonTeamPhaseStandings?.ranking}
              seasonId={match?.slot?.phase?.season?.id}
              teamId={team.id}
              teamName={team.name}
              winLossRecord={{
                matchesWon:
                  team?.regularSeasonTeamPhaseStandings?.winLossRecord
                    ?.matchesWon ?? 0,
                matchesLost:
                  team?.regularSeasonTeamPhaseStandings?.winLossRecord
                    ?.matchesLost ?? 0,
              }}
            />
          )}
          date={match?.scheduledStartsAt}
          isInMatchAssistantContainer
          metaseason={metaseason as MinimalMetaseason}
          overrideTeamRosterViewPermissions
          parentDataLoading={loading}
          rosterCardStarterRowActions={[
            RosterCardMenuRowItems.RepositionPlayer,
          ]}
          rosterCardSubRowActions={[RosterCardMenuRowSubItems.RepositionPlayer]}
          showAdditionalContainerActions={false}
          showCreateScrimmage={false}
          teamId={team.id}
        />
      </MatchCard>
    </Grid>
  )

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
      <Grid container spacing={3}>
        {initialTeams.map(rosterComponent)}
      </Grid>

      {canShowAllTeamsButton && (
        <NxButton
          fullWidth
          label="View All Teams"
          onClick={(): void => setShowAllTeams(true)}
          variant="secondary"
        />
      )}

      <Grid container spacing={3}>
        {showAllTeams && otherTeams.map(rosterComponent)}
      </Grid>
    </Box>
  )
}
