import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router'
import {
  PhaseType,
  useGetLeagueByPublicSlugQuery,
  useGetLeagueSeasonDataQuery,
} from '@plvs/graphql/generated'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import dayjs from 'dayjs'
import {
  DateFormats,
  findNextCompSlot,
  formatInTz,
  sortByStartsAt,
} from '@plvs/utils'
import { last } from 'ramda'
import { LeagueOverviewCard } from '../LeagueOverviewCard'
import { KeyDatesCard } from '../KeyDatesCard'
import { SeasonSchedule } from '../SeasonSchedule/SeasonSchedule'
import { ResourcesCard } from '../ResourcesCard/ResourcesCard'
import { ReferCard } from '../refer/ReferCard'
import { CreateTeamCard } from '../CreateTeamCard'
import { LeagueTeams } from '../LeagueTeams/LeagueTeams'
import { useLeagueDetails } from './LeagueDetailsProvider'

export const useStyles = makeStyles((theme) => ({
  exploreContainer: {
    cursor: 'pointer',
  },
  leagueSideInfoContainer: {
    padding: theme.spacing(3),
  },
  cta: {
    marginRight: theme.spacing(2),
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  iconWrapper: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  createButton: {
    width: '100%',
    padding: theme.spacing(1.75, 8.75),
    marginBottom: theme.spacing(4),
  },
  seasonSchedule: {
    marginTop: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(4),
  },
  privateResourceCard: {
    marginTop: theme.spacing(3),
  },
}))

export const Overview: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const isPublic = !location.pathname.includes('/app')

  const { metaseasonId, publicSlug } = useLeagueDetails()

  const {
    metaseason,
    loading: schoolLeagueInfoContextLoading,
  } = useSchoolLeagueInfoContext()

  const metaseasonName = metaseason?.name ?? ''

  const { isCoachAtOrg, orgId } = useUserIdentityFn()

  const { data } = useGetLeagueByPublicSlugQuery({
    skip: !publicSlug,
    variables: { publicSlug },
  })

  // Derived data
  const selectedLeague = data?.getLeagueByPublicSlug
  const leagueId = data?.getLeagueByPublicSlug?.id ?? ''
  const esportId = selectedLeague?.esport?.id || ''
  const esportSlug = selectedLeague?.esport?.slug
  const esportName = selectedLeague?.esport.name || ''
  const competitionGroup = selectedLeague?.competitionGroup

  const { data: leagueSeasonData } = useGetLeagueSeasonDataQuery({
    skip: !leagueId || !metaseasonId || schoolLeagueInfoContextLoading,
    variables: { leagueId, metaseasonId },
  })

  const season = leagueSeasonData?.league?.seasonByMetaseasonId
  const seasonStartsAt = season?.startsAt
  const seasonEndsAt = season?.endsAt
  const teamRegistrationEndsAt = season?.teamRegistrationEndsAt
  const suggestedRegistrationEndsAt = season?.suggestedRegistrationEndsAt

  const seasonPhases = sortByStartsAt(season?.activePhases ?? [])
  const lastPhase = last(seasonPhases)

  const seasonPhase =
    seasonPhases.find((p) => p.type === PhaseType.RegularSeason) ||
    lastPhase ||
    null

  const nextCompSlot = findNextCompSlot(seasonPhase)
  const competitionTime = nextCompSlot?.startsAt
  const formattedTime = competitionTime
    ? formatInTz({
        at: competitionTime,
        format: DateFormats.LeagueCompetitionTimeFormat,
        timezone: dayjs.tz.guess(),
      })
    : 'TBD'

  return (
    <Grid container spacing={2}>
      <Grid item md={isPublic ? 12 : 8} sm={12} xs={12}>
        {!isPublic && (
          <LeagueOverviewCard
            className={classes.card}
            formattedTime={formattedTime}
            teamFormat={selectedLeague?.teamFormat ?? 0}
            teamMaxSize={selectedLeague?.teamMaxSize ?? 0}
          />
        )}
        <KeyDatesCard
          className={classes.card}
          esportName={esportName}
          esportSlug={esportSlug}
          isPublic={isPublic}
          metaseasonName={metaseasonName}
          rostersLockAt={season?.rostersLockAt}
          seasonEndsAt={seasonEndsAt}
          seasonStartsAt={seasonStartsAt}
          suggestedRegistrationEndsAt={suggestedRegistrationEndsAt}
          teamRegistrationEndsAt={teamRegistrationEndsAt}
        />
        <Grid
          className={classes.seasonSchedule}
          container
          spacing={isPublic ? 2 : 0}
        >
          <Grid item sm={isPublic ? 7 : 12} xs={12}>
            {seasonPhases.length > 0 && (
              <SeasonSchedule phases={seasonPhases} />
            )}
          </Grid>
          {isPublic && esportSlug && (
            <Grid item sm={5} xs={12}>
              <ResourcesCard
                esportSlug={esportSlug}
                isPublic={isPublic}
                rulebookUrl={selectedLeague?.esport?.details?.rulebookUrl}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isPublic && (
        <Grid item md={4} sm={12} xs={12}>
          <LeagueTeams
            className={classes.leagueSideInfoContainer}
            competitionGroup={competitionGroup}
            esportId={esportId}
            esportSlug={esportSlug}
            isPastRegDate={dayjs().isSameOrAfter(teamRegistrationEndsAt)}
            leagueId={leagueId}
            metaseasonId={metaseasonId}
          />
          <CreateTeamCard esportSlug={esportSlug} isCoachAtOrg={isCoachAtOrg} />
          {esportSlug && (
            <ResourcesCard
              className={classes.privateResourceCard}
              esportSlug={esportSlug}
              isPublic={isPublic}
              rulebookUrl={selectedLeague?.esport?.details?.rulebookUrl}
            />
          )}
          {isCoachAtOrg && <ReferCard schoolId={orgId || ''} />}
        </Grid>
      )}
    </Grid>
  )
}
