import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import React, { useState } from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import '@playvs-inc/nexus-theme'
import { appendClasses, convertSecondsToMinutes } from '@plvs/utils'
import { useGetWalkthroughPlaylistQuery } from '@plvs/graphql/generated'
import { PlayArrow } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  tutorialContainer: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  subtitle: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  playlistContainer: {
    border: `1px solid ${theme.palette.BorderMedium}`,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  videoNumber: {
    marginRight: theme.spacing(2),
    alignSelf: 'center',
  },
  thumbnail: {
    maxWidth: '100px',
    borderRadius: theme.shape.borderRadius,
    objectFit: 'contain',
  },
  baseVideo: {
    cursor: 'pointer',
  },
  selectedVideo: {
    backgroundColor: theme.palette.ColorBackgroundAlt2,
  },
  youtube: {
    width: '100%',
    height: 'auto',
    aspectRatio: '10 / 6',
    maxWidth: '760px',
    maxHeight: '425px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      maxHeight: 'auto',
    },
  },
  playIcon: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
  },
  durationText: {
    position: 'absolute',
    top: theme.spacing(6.5),
    right: theme.spacing(0.6),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.ColorBackgroundInvert,
    color: theme.palette.ColorTextInvert,
    fontWeight: 'bold',
    fontSize: theme.typography.body4.fontSize,
    padding: theme.spacing(0, 0.5),
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(8.75),
      right: theme.spacing(3),
    },
  },
  mobilePlaylistContainer: {
    border: `1px solid ${theme.palette.BorderMedium}`,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  scrollStyle: {
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.ColorBackgroundAlt2,
    },
  },
}))

export const VideoTutorialsContainer: React.FC = () => {
  const classes = useStyles()
  const [currentVideo, setCurrentVideo] = useState(0)
  const { data, loading } = useGetWalkthroughPlaylistQuery()
  const playlist = [...(data?.getWalkthroughPlaylist?.playlist ?? [])]
  const embedVideoLink =
    playlist[currentVideo]?.embedHtml
      ?.split('www.youtube.com/embed/')[1]
      ?.split('"')[0] ?? null

  const selectVideo = (videoNumber: number): void => {
    setCurrentVideo(videoNumber)
  }

  return (
    <Box className={classes.tutorialContainer}>
      <NxTypography variant="h2">
        PlayVS Experience Video Tutorials
      </NxTypography>
      <NxTypography className={classes.subtitle} variant="subtitle1">
        Check out the latest PlayVS usage tutorials
      </NxTypography>
      <Box display="flex" flexDirection="row" width="100%">
        <Box display="flex" width="100%">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className={classes.youtube}
            height="55%"
            src={
              embedVideoLink
                ? `https://www.youtube.com/embed/${embedVideoLink}?rel=0`
                : 'https://www.youtube.com/embed/vVgE3DvbVCY?rel=0'
            }
            title="YouTube video player"
            width="100%"
          />
        </Box>

        <Box
          className={classes.playlistContainer}
          display="flex"
          flexDirection="column"
          ml={2}
          pt={2}
        >
          <Box mb={2} px={2}>
            <NxTypography variant="h3">
              PlayVS Experience Walkthrough
            </NxTypography>
            <Box display="flex" flexDirection="row">
              <NxTypography variant="subtitle2">PlayVS</NxTypography>
              <NxTypography variant="subtitle2">
                &nbsp;{`- ${currentVideo + 1} / ${playlist.length}`}
              </NxTypography>
            </Box>
          </Box>
          <Box
            className={classes.scrollStyle}
            display="flex"
            flexDirection="column"
            maxHeight={325}
            maxWidth={285}
            overflow="auto"
          >
            {!loading &&
              playlist.map((playlistVideo, index) => {
                const isSelectedVideo = index === currentVideo
                const videoDuration = convertSecondsToMinutes(
                  playlistVideo.durationSeconds
                )
                return (
                  <Box
                    key={playlistVideo.title}
                    alignItems="center"
                    className={appendClasses(
                      classes.baseVideo,
                      isSelectedVideo ? classes.selectedVideo : ''
                    )}
                    display="flex"
                    flexDirection="row"
                    onClick={(): void => selectVideo(index)}
                    p={2}
                  >
                    {isSelectedVideo ? (
                      <PlayArrow className={classes.playIcon} />
                    ) : (
                      <NxTypography
                        className={classes.videoNumber}
                        variant="subtitle2"
                      >
                        {index + 1}
                      </NxTypography>
                    )}
                    <Box position="relative">
                      <img
                        alt="video"
                        className={classes.thumbnail}
                        src={playlistVideo.thumbnail}
                      />
                      <NxTypography className={classes.durationText}>
                        {videoDuration}
                      </NxTypography>
                    </Box>
                    <Box display="flex" flexDirection="column" ml={1}>
                      <NxTypography variant="body3">
                        {playlistVideo.title}
                      </NxTypography>
                      <NxTypography variant="body4">PlayVS</NxTypography>
                    </Box>
                  </Box>
                )
              })}
          </Box>
        </Box>
      </Box>
      <Box>
        <Box className={classes.mobilePlaylistContainer} mt={2} px={2} py={2}>
          <Box mb={2}>
            <NxTypography variant="h3">
              PlayVS Experience Walkthrough
            </NxTypography>
            <Box display="flex" flexDirection="row">
              <NxTypography variant="subtitle2">PlayVS</NxTypography>
              <NxTypography variant="subtitle2">
                &nbsp;{`- ${currentVideo + 1} / ${playlist.length}`}
              </NxTypography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" overflow="auto">
            {playlist.map((playlistVideo, index) => {
              const isSelectedVideo = index === currentVideo
              const videoDuration = convertSecondsToMinutes(
                playlistVideo.durationSeconds
              )
              return (
                <Box
                  key={playlistVideo.title}
                  className={appendClasses(
                    classes.baseVideo,
                    isSelectedVideo ? classes.selectedVideo : ''
                  )}
                  display="flex"
                  flexDirection="row"
                  onClick={(): void => selectVideo(index)}
                  p={2}
                  position="relative"
                >
                  <img
                    alt="video"
                    className={classes.thumbnail}
                    src={playlistVideo.thumbnail}
                  />
                  <NxTypography className={classes.durationText}>
                    {videoDuration}
                  </NxTypography>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
