import React from 'react'
import { competitionModelToDisplay } from '@plvs/utils'
import { Navigate } from 'react-router'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { CompetitionModel } from '@plvs/graphql/generated'
import { EnrollmentStatus } from '@plvs/respawn/containers/enrollment/types'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { useEnrollmentProvider } from '@plvs/respawn/containers/enrollment/EnrollmentProvider'

export const EnrollmentBaseRedirect: React.FC = () => {
  const flags = useFlags()

  const {
    competitionGroup: orgCompetitionGroup,
    loading: userSelectedOrganizationLoading,
  } = useSelectedOrganizationFn()

  const {
    loading,
    status,
    hasAnnualPassInstance,
    competitionModel,
  } = useEnrollmentProvider()

  const shouldRedirectToPlanSelection =
    !flags.freeCompetition &&
    !loading &&
    status === EnrollmentStatus.NotEnrolled &&
    !hasAnnualPassInstance

  if (loading || userSelectedOrganizationLoading) {
    return null
  }

  // Redirect to plan selection if user enters team enrollment page without a plan.
  return (
    <Navigate
      to={
        hasAnnualPassInstance ||
        flags.freeCompetition ||
        shouldRedirectToPlanSelection
          ? `teams/${competitionModelToDisplay({
              competitionModel: competitionModel ?? CompetitionModel.Rec,
              competitionGroup: orgCompetitionGroup,
            }).toLowerCase()}`
          : `select`
      }
    />
  )
}
