import React from 'react'
import { makeStyles, useTheme, capitalize } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'

import { Path } from '@plvs/const'
import { Ellipsis } from '@plvs/rally/components/typography'
import {
  GetSchoolAvailableScrimmagesQuery,
  GetSchoolScrimmagesQuery,
  FindRecommendedTeamsForScrimmageQuery,
  GetScrimmageRequestsQuery,
  ScrimmageRequestStatus,
  useMySchoolInfoQuery,
} from '@plvs/graphql'
import { Colors } from '@plvs/rally/themes'
import { GeneralEsportAdapter } from '@plvs/respawn/features/esport/creator/types'
import { StatusBadge } from '@plvs/rally/components/badge'

import {
  NxTypography,
  NxButton,
  NxUserCluster,
} from '@playvs-inc/nexus-components'

// eslint-disable-next-line import/no-restricted-paths
import { TableColumn } from '../Table'
import { ScrimmageTeam, TeamAndSchoolInfo } from './TeamAndSchoolInfo'

dayjs.extend(tz)

const useStyles = makeStyles((theme) => ({
  platformIconStyling: {
    paddingRight: theme.spacing(1),
    height: 32,
    width: 32,
    color: theme.palette.ColorIconAlt,
  },
}))

/* Column Components */
export type MyScrimmageRow = NonNullable<
  NonNullable<
    NonNullable<GetSchoolScrimmagesQuery['school']>['myScrimmageRequests']
  >['result']
>[0]

export type AvailableScrimmageRow = NonNullable<
  NonNullable<
    NonNullable<
      GetSchoolAvailableScrimmagesQuery['school']
    >['availableScrimmageRequests']
  >['result']
>[0]

export type AvailableScrimmageRowWithDetails = AvailableScrimmageRow & {
  platformType?: GeneralEsportAdapter['platformType']
  platformIcon?: GeneralEsportAdapter['platformIcon']
  teamMinStarterSize?: GeneralEsportAdapter['teamMinStarterSize']
  hasSeries?: GeneralEsportAdapter['hasSeries']
}

export type AllScrimmagesRow = NonNullable<
  NonNullable<GetScrimmageRequestsQuery['scrimmageRequests']>['result']
>[0]

export type ScrimmageRow =
  | MyScrimmageRow
  | AvailableScrimmageRow
  | AvailableScrimmageRowWithDetails

export const ScrimmageTeams: TableColumn<MyScrimmageRow> = {
  id: 'scrimmageTeams',
  Cell: ({ requestingTeam, opposingTeam, esport }) => {
    return (
      <div
        data-cy="myScrimmages"
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {requestingTeam && (
          <TeamAndSchoolInfo
            esportRating={esport?.rating}
            team={requestingTeam}
          />
        )}
        {opposingTeam && (
          <>
            <TeamAndSchoolInfo
              esportRating={esport?.rating}
              team={opposingTeam}
            />
          </>
        )}
      </div>
    )
  },
  Header: 'Team',
  minWidth: 200,
  sortable: false,
}

export const ScrimmageTeamA: TableColumn<AllScrimmagesRow> = {
  id: 'scrimmageTeamA',
  Cell: ({ requestingTeam }) => {
    return requestingTeam ? (
      <TeamAndSchoolInfo team={requestingTeam} useCheckpointUrl />
    ) : (
      <></>
    )
  },
  Header: 'Team A',
  minWidth: 330,
  sortable: false,
}

export const ScrimmageTeamB: TableColumn<AllScrimmagesRow> = {
  id: 'scrimmageTeamB',
  Cell: ({ opposingTeam }) => {
    return opposingTeam ? (
      <TeamAndSchoolInfo team={opposingTeam} useCheckpointUrl />
    ) : (
      <></>
    )
  },
  Header: 'Team B',
  minWidth: 330,
  sortable: false,
}

export const ScrimmageRequestingTeam: TableColumn<ScrimmageRow> = {
  accessor: 'requestingTeam.name',
  Cell: ({ requestingTeam }) => {
    return requestingTeam ? (
      <NxUserCluster
        avatarHashId={requestingTeam.id}
        avatarUrl={requestingTeam.school?.logoUrl ?? ''}
        subtitles={[{ title: requestingTeam.school?.name ?? '' }]}
        title={requestingTeam.name ?? ''}
      />
    ) : (
      <></>
    )
  },
  Header: 'Team',
  minWidth: 200,
  sortable: false,
}

export const ScrimmageStatus: TableColumn<MyScrimmageRow> = {
  Cell: ({ status, startsAt, opposingTeam, createdAt }) => {
    const timezone = dayjs.tz.guess()
    const startTime = dayjs(startsAt).tz(timezone)
    const createTime = dayjs(createdAt).tz(timezone)

    const formattedTime =
      startTime.diff(createTime, 'hours') > 24
        ? createTime.add(24, 'hours').format('ddd h:mma')
        : startTime.subtract(1, 'hour').format('ddd h:mma')

    const { data: schoolData } = useMySchoolInfoQuery()
    const schoolId = schoolData?.me?.school?.id ?? ''
    const isOpposingTeam = schoolId === opposingTeam?.school?.id

    const text =
      status === ScrimmageRequestStatus.Requested && isOpposingTeam
        ? `Respond by\n${formattedTime}`
        : ''
    return <StatusBadge status={status} text={text} />
  },
  Header: 'Status',
  sortable: false,
  width: 177,
}

export const ScrimmageFormattedDate: TableColumn<ScrimmageRow> = {
  id: 'formattedDate',
  Cell: ({ startsAt }) => {
    const formattedDate = dayjs(startsAt).format('ddd MMM D[,] YYYY')
    return <NxTypography>{formattedDate}</NxTypography>
  },
  Header: 'Date',
  width: 168,
  sortable: false,
}

export const ScrimmageFormattedTime: TableColumn<ScrimmageRow> = {
  id: 'formattedTime',
  Cell: ({ startsAt }) => {
    const timezone = dayjs.tz.guess() ?? null
    const formattedTime = timezone
      ? dayjs(startsAt).tz(timezone).format('h:mma z')
      : dayjs(startsAt).format('h:mma')

    return <NxTypography>{formattedTime}</NxTypography>
  },
  Header: 'Time',
  sortable: false,
  width: 140,
}

export const ScrimmageFormattedDateAndTime: TableColumn<ScrimmageRow> = {
  id: 'formattedDateAndTime',
  Cell: ({ startsAt }) => {
    const timezone = dayjs.tz.guess() ?? null
    const formattedTime = timezone
      ? dayjs(startsAt).tz(timezone).format('h:mma z')
      : dayjs(startsAt).format('h:mma')
    const formattedDate = dayjs(startsAt).format('ddd MMM D[,] YYYY')

    return (
      <div>
        <div>
          <NxTypography>{formattedDate}</NxTypography>
        </div>
        <div>
          <NxTypography>{formattedTime}</NxTypography>
        </div>
      </div>
    )
  },
  Header: 'Date & Time',
  sortable: false,
  width: 200,
}

export const ScrimmageLeague: TableColumn<AvailableScrimmageRow> = {
  id: 'league',
  Cell: ({ league }) => {
    const theme = useTheme()

    return (
      <div style={{ width: '100%' }}>
        <Ellipsis variant="body1">{league?.name}</Ellipsis>
        <NxTypography
          style={{ color: theme.palette.ColorTextAlt2 }}
          variant="body2"
        >
          {league?.state}
        </NxTypography>
      </div>
    )
  },
  Header: 'League',
  sortable: false,
  minWidth: 180,
}

export const ScrimmageNumberOfGames: TableColumn<ScrimmageRow> = {
  accessor: 'bestOf',
  Cell: ({ bestOf }) => {
    return <NxTypography>{bestOf}</NxTypography>
  },
  Header: 'Best Of',
  sortable: false,
  width: 100,
}

export const ScrimmageRating: TableColumn<AvailableScrimmageRowWithDetails> = {
  accessor: 'rating',
  Cell: ({ rating }) => {
    return rating ? (
      <NxTypography data-cy="EsportRating">{capitalize(rating)}</NxTypography>
    ) : (
      <></>
    )
  },
  Header: 'Rating',
  sortable: false,
  width: 150,
}

export const ScrimmagePlatform: TableColumn<AvailableScrimmageRowWithDetails> = {
  accessor: 'platformType',
  Cell: ({ platformType, platformIcon }) => {
    const { platformIconStyling } = useStyles()
    const PlatformIcon = platformIcon as React.FC<SvgIconProps>
    return (
      <>
        {platformIcon ? (
          <PlatformIcon className={platformIconStyling} />
        ) : (
          <></>
        )}
        <NxTypography>{platformType}</NxTypography>
      </>
    )
  },
  Header: 'Platform',
  sortable: false,
  width: 160,
}

export const ScrimmageFormat: TableColumn<AvailableScrimmageRowWithDetails> = {
  accessor: 'bestOf',
  Cell: ({ bestOf, seriesBestOf, hasSeries }) => {
    return (
      <NxTypography>{`Best of ${
        hasSeries ? seriesBestOf : bestOf
      }`}</NxTypography>
    )
  },
  Header: 'Format',
  sortable: false,
  width: 150,
}

export const ScrimmageJoin: TableColumn<ScrimmageRow> = {
  accessor: 'status',
  id: 'joinLink',
  Cell: ({ id }) => (
    <NxButton
      data-cy="joinScrimmageButton"
      href={`${Path.Scrimmage}/?join=${id}`}
      label="Join"
      variant="primary"
    />
  ),
  Header: '',
  sortable: false,
  width: 177,
}

export type ScrimmageOpponent = NonNullable<
  NonNullable<
    FindRecommendedTeamsForScrimmageQuery['findRecommendedTeamsForScrimmage']
  >
>[0]

export const ScrimmageOpponentTeam: (
  selectedRowIndex: number
) => TableColumn<ScrimmageOpponent> = (selectedRowIndex: number) => ({
  accessor: 'team.name',
  Cell: (recommendedTeam): React.ReactElement | null => {
    if (recommendedTeam) {
      return TeamAndSchoolInfo({
        addPadding: true,
        team: recommendedTeam as ScrimmageTeam,
        esportRating: recommendedTeam?.esport?.rating,
        selectable: true,
        // eslint-disable-next-line no-underscore-dangle
        selected: recommendedTeam?.row._index === selectedRowIndex,
      })
    }
    return <></>
  },
  Header: 'Team',
  minWidth: 360,
})

export const ScrimmageOpponentRank: TableColumn<ScrimmageOpponent> = {
  Cell: (recommendedTeam) => {
    const rank = recommendedTeam?.phaseResult?.rank ?? ''
    return (
      <NxTypography style={{ color: rank ? Colors.Black : Colors.Grey3 }}>
        {rank ? `#${rank}` : 'Unranked'}
      </NxTypography>
    )
  },
  Header: 'Standings',
  width: 130,
}
