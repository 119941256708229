import { sort, ascend, prop, groupBy, head } from 'ramda'
import { ApolloQueryResult } from '@apollo/client'

import {
  GetCurrentGameAssistantStepByMatchIdQuery,
  GetCurrentGameAssistantStepByMatchIdQueryVariables,
  SeriesPlayer,
  GameAssistantStepWithSelectionsFragment,
  GameAssistantStateFragment,
  GameAssistantSelectionOptionType,
  GameAssistantStepType,
  GameAssistantSelection,
  GameAssistantSelectionOptionFragment,
  GameAssistantGameDetails,
  Provider,
  GetCurrentGameAssistantStepByMatchIdQueryResult,
  MatchTeamFragment,
  GetMatchForMatchAssistantQuery,
  EsportSlug,
  EsportRating,
  MatchAssistantTeamFragment,
} from '@plvs/graphql'
import { ConnectedAccountProviders, getAccountProviderUsername } from '../user'

export type MatchAssistantMatch =
  | (NonNullable<GetMatchForMatchAssistantQuery>['match'] & {
      esport: {
        id: string
        slug: EsportSlug | undefined | null
        name: string
        rating: EsportRating
      }
    })
  | null
  | undefined

export type MatchAssistantTeam = MatchAssistantTeamFragment | null | undefined

export type TeamRosterForSteps = {
  id: string
  name: string
  avatarUrl: string
  regularSeasonTeamPhaseStandings: {
    ranking: number
    hasClinchedPlayoffs: boolean
    winLossRecord: {
      matchesWon: number
      matchesLost: number
    }
  }
  school: {
    id: string
    name: string
  }
  roster: {
    id: string
    name: string
    avatarUrl: string
    publisherAccountName: string
  }[]
  rosterFormat: {
    teamSize: number
  }
  starters: {
    id: string
    name: string
    avatarUrl: string
    publisherAccountName: string
  }[]
  archivedAt: string
}
export type SelectionOption = GameAssistantSelectionOptionFragment
export type Selection = GameAssistantSelection
export type Step = GameAssistantStepWithSelectionsFragment

export type TeamRosterType =
  | NonNullable<
      GetCurrentGameAssistantStepByMatchIdQueryResult['data']
    >['currentGameAssistantStateByMatchId']['roster']
  | NonNullable<
      GetCurrentGameAssistantStepByMatchIdQueryResult['data']
    >['currentGameAssistantStateByMatchId']['opponentRoster']
  | undefined
export type MatchAssistantTeamScoped = Pick<
  MatchTeamFragment,
  'id' | 'name' | 'avatarUrl' | 'regularSeasonTeamPhaseStandings'
> & {
  school: {
    id: string | null | undefined
    name: string | null | undefined
  } | null
}
export type MatchAssistantPlayer = {
  __typename?: 'SeriesPlayer'
} & Pick<SeriesPlayer, 'id' | 'name' | 'avatarUrl' | 'publisherAccountName'>

export type MatchAssistantStep = GameAssistantStateFragment
export type refetchCurrentGameAssistantStepByMatchIdFunction = (
  variables?: Partial<GetCurrentGameAssistantStepByMatchIdQueryVariables>
) => Promise<ApolloQueryResult<GetCurrentGameAssistantStepByMatchIdQuery>>

export enum PlayerSide {
  Home = 0,
  Away = 1,
}

export enum MatchAssistantAlertType {
  Skip = 'skip',
  Reset = 'reset',
}

export enum StepType {
  Initial = 'Initial',

  // General (all esports)
  ReadyUp = 'ReadyUp',
  RosterSelect = 'RosterSelect',
  OpponentPreview = 'OpponentPreview',

  // Smash
  PlayerSelect = 'PlayerSelect',
  CharacterSelect = 'CharacterSelect',
  StageSelect = 'StageSelect',
  Report = 'Report',

  // Rocket League
  Spectator = 'Spectator',
  PlatformCheck = 'PlatformCheck',
  PrepareForInvite = 'PrepareForInvite',
  Scoreboard = 'Scoreboard',

  // Splatoon
  BetaOptIn = 'BetaOptIn',

  Final = 'Final',
}

export enum FirebobMessage {
  Spectator = 'You are currently spectating.',
  StageStriking = 'Get ready for Stage Striking',
  PlaybookSelect = 'Select your team & playbooks',
  TeamSelect = 'Select an NBA Team for Game',
}

export function sortByDisplayName(
  selectionOptions: SelectionOption[] = []
): SelectionOption[] {
  const byDisplayName = ascend(prop('displayName'))

  return sort(byDisplayName, selectionOptions)
}

export const groupByDisplayName = groupBy(
  (selectionOption: SelectionOption) => {
    return selectionOption.displayCategory ?? ''
  }
)

export const groupOptionsByDisplayName = groupBy(
  (selectionOption: GameAssistantSelection) => {
    return selectionOption.selectionOption?.displayCategory ?? ''
  }
)
export interface PlayerSelection {
  id: string
  name: string
  avatarUrl: string
  selection: SelectionOption | undefined | null
  useAvatar: boolean
}

export const createPlayerObject = ({
  team,
  selectionOption,
}: {
  team?: {
    id?: string | null
    name?: string | null
    logoUrl?: string | null
  } | null
  selectionOption?: SelectionOption | null
}): PlayerSelection => {
  return {
    id: team?.id ?? '',
    name: selectionOption?.displayName || team?.name || '',
    avatarUrl: selectionOption?.assetUri || team?.logoUrl || '',
    // @ts-ignore
    selection: selectionOption || {
      id: team?.id,
      displayName: team?.name,
      assetUri: team?.logoUrl,
    },
    useAvatar: !selectionOption?.assetUri,
  }
}

export function getMaddenPicks({
  teamId,
  options,
  gameOrdinal,
}: {
  teamId?: string | null
  options?: GameAssistantSelection[] | null
  gameOrdinal?: number | null
}): {
  team?: GameAssistantSelection
  offense?: GameAssistantSelection
  defense?: GameAssistantSelection
} {
  const myOptions =
    options?.filter(
      (pick) =>
        pick?.gameAssistantStep?.teamId === teamId &&
        pick?.gameAssistantStep?.gameOrdinal === gameOrdinal
    ) ?? []
  const myPicks = groupOptionsByDisplayName(myOptions)
  const team = myPicks.Team?.[0]
  const offense = myPicks['Offense Playbook']?.[0]
  const defense = myPicks['Defense Playbook']?.[0]

  return { team, offense, defense }
}

export function getPickForGameOrdinal({
  teamId,
  options,
  gameOrdinal,
  seriesOrdinal,
}: {
  teamId?: string | null
  options?: GameAssistantSelection[] | null
  gameOrdinal?: number | null
  seriesOrdinal?: number | null
}): GameAssistantSelection | undefined {
  return options?.find(
    (pick) =>
      pick?.gameAssistantStep?.teamId === teamId &&
      // Ignore gameOrdinal if not provided.
      (typeof gameOrdinal !== 'number' ||
        pick?.gameAssistantStep?.gameOrdinal === gameOrdinal) &&
      // Ignore seriesOrdinal if not provided.
      (typeof seriesOrdinal !== 'number' ||
        pick?.gameAssistantStep?.seriesOrdinal === seriesOrdinal)
  )
}

export function stepToStepType(
  step:
    | {
        selectionOptionType: GameAssistantSelectionOptionType | undefined | null
        stepType: string
        isComplete: boolean
      }
    | undefined
): StepType | null {
  if (step?.isComplete) {
    return StepType.Final
  }

  switch (step?.stepType) {
    case GameAssistantStepType.BetaOptIn: {
      return StepType.BetaOptIn
    }
    case GameAssistantStepType.Scoreboard:
      return StepType.Scoreboard

    case GameAssistantStepType.ReadyUp:
      return StepType.ReadyUp

    case GameAssistantStepType.OpponentRoster:
      return StepType.OpponentPreview

    case GameAssistantStepType.Roster:
      return StepType.RosterSelect

    default:
      break
  }

  switch (step?.selectionOptionType) {
    case GameAssistantSelectionOptionType.Character:
      return StepType.CharacterSelect

    case GameAssistantSelectionOptionType.Map:
      return StepType.StageSelect

    case GameAssistantSelectionOptionType.Team:
      return StepType.Report

    case GameAssistantSelectionOptionType.Platform:
      return StepType.PlatformCheck

    case GameAssistantSelectionOptionType.Confirm:
      return StepType.PrepareForInvite

    case GameAssistantSelectionOptionType.Player:
      return StepType.PlayerSelect

    case GameAssistantSelectionOptionType.Spectator:
      return StepType.Spectator

    default:
      return null
  }
}

export function sortByGameOrdinal(
  games: GameAssistantGameDetails[]
): GameAssistantGameDetails[] {
  return [...games].sort((a, b) => b.gameOrdinal - a.gameOrdinal)
}

export function getTeamAndRosterForSteps(
  roster: TeamRosterType,
  team: MatchAssistantTeam | null | undefined,
  providerName: Provider
): TeamRosterForSteps {
  const players = roster?.players ?? []
  const format = head(roster?.formats ?? [])
  const rosterPlayers = players.map((player) => ({
    id: player.user.id ?? '',
    name: player.user.name ?? '',
    avatarUrl: player.user.avatarUrl ?? '',
    publisherAccountName: getAccountProviderUsername(
      (player.user ?? null) as ConnectedAccountProviders | null,
      providerName
    ),
  }))
  const rosterStarters = (format?.starters ?? []).map((p) => ({
    id: p?.player?.user.id ?? '',
    name: p?.player?.user.name ?? '',
    avatarUrl: p?.player?.user?.avatarUrl ?? '',
    publisherAccountName: getAccountProviderUsername(
      (p?.player?.user ?? null) as ConnectedAccountProviders | null,
      providerName
    ),
  }))
  const regularSeasonTeamPhaseStandings = {
    ranking: team?.regularSeasonTeamPhaseStandings?.ranking ?? 0,
    hasClinchedPlayoffs:
      team?.regularSeasonTeamPhaseStandings?.hasClinchedPlayoffs ?? false,
    winLossRecord: {
      matchesWon:
        team?.regularSeasonTeamPhaseStandings?.winLossRecord?.matchesWon ?? 0,
      matchesLost:
        team?.regularSeasonTeamPhaseStandings?.winLossRecord?.matchesLost ?? 0,
    },
  }

  return {
    id: team?.id ?? '',
    name: team?.name ?? '',
    avatarUrl: team?.avatarUrl ?? '',
    school: {
      id: team?.school?.id ?? '',
      name: team?.school?.name ?? '',
    },
    roster: rosterPlayers,
    rosterFormat: {
      teamSize: format?.teamSize ?? 0,
    },
    starters: rosterStarters,
    regularSeasonTeamPhaseStandings,
    archivedAt: team?.archivedAt ?? '',
  }
}
