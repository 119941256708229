import { Hidden, NoSsr } from '@material-ui/core'
import { RallyAppDrawer } from '@plvs/rally/features/app/drawer'
import {
  Box,
  FullHeightBox,
  useBreakpointMd,
  useBreakpointSm,
} from '@plvs/respawn/features/layout'
import React from 'react'
import { Outlet } from 'react-router'

export const PublicMatchPageLayout: React.FC = () => {
  const isTablet = useBreakpointMd()
  const isMobile = useBreakpointSm()

  const maxScreenWidth =
    isTablet && !isMobile ? 'calc(100vw - 320px)' : '1042px'

  return (
    <Box display="flex" justifyContent="center">
      <Hidden smDown>
        <RallyAppDrawer isPublic />
      </Hidden>
      <FullHeightBox flexDirection="row-reverse" maxWidth={maxScreenWidth}>
        <NoSsr>
          <Outlet />
        </NoSsr>
      </FullHeightBox>
    </Box>
  )
}
