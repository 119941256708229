/* eslint-disable no-restricted-imports */
import { useState, useRef, useEffect, useCallback } from 'react'
import { head, uniqBy, prop } from 'ramda'
import { Conversation, Message, Paginator } from '@twilio/conversations'
import * as Sentry from '@sentry/browser'
import type { NxChatMessageProps } from '@playvs-inc/nexus-chat'

import {
  ChatRole,
  useSendFirstMessageNotificationIfNecessaryMutation,
  useAddUserToChatMutation,
} from '@plvs/graphql'
import { useUserOnlineStatusContext } from '@plvs/respawn/features/userOnlineStatus/UserOnlineStatusProvider'
import { logger } from '@plvs/rally/logging'
import { useChatConversationsContext } from '@plvs/rally/containers/chat/ChatConversationsProvider'
import { useChatClientContext } from '@plvs/rally/containers/chat/ChatClientProvider'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { ConversationsProviderMetadata } from '@plvs/rally/containers/chat/Providers.types'
import { useSnackbar } from 'notistack'
import {
  mapMessagesToNxMessage,
  getMessageError,
  ParticipantAttributesJSON,
  addAdminUserToChat,
} from '../utils/chatUtils'
import { executeCommands } from '../utils'
import { UseMatchChatProps, UseMatchChatReturn } from './hooks.types'

export const useMatchChat = ({
  chatUniqueName = '',
  chatRole = ChatRole.Player,
  matchId,
  shouldAddAdminToChat = false,
  active,
  conversationSid,
  isStateClosed = false,
  isGlobal,
}: UseMatchChatProps): UseMatchChatReturn => {
  const { client } = useChatClientContext()

  const {
    usersActiveConversationsMap,
    loading,
    setUsersActiveConversationsMap,
  } = useChatConversationsContext()
  const {
    onlineStatusByUserId,
    setCurrentUserIdsMap,
    currentUserIdsMap,
  } = useUserOnlineStatusContext()
  const { userId, userName, avatarUrl } = useUserIdentityFn()

  const [
    firstMessageMutation,
  ] = useSendFirstMessageNotificationIfNecessaryMutation()
  const [addUserToChatMutation] = useAddUserToChatMutation()
  const [loadingConversationState, setLoadingConversationState] = useState(true)
  const [usersConversation, setUsersConversation] = useState<
    ConversationsProviderMetadata | undefined
  >(usersActiveConversationsMap[chatUniqueName])
  const [paginator, setPaginator] = useState<Paginator<Message>>()
  const [messages, setMessages] = useState<NxChatMessageProps[]>([])
  const [draft, setDraft] = useState<string>('')
  const [media, setMedia] = useState<File[]>([])
  const [errorCode, setErrorCode] = useState<number>()
  const [isSending, setIsSending] = useState<boolean>(false)

  const scrollRef = useRef<HTMLElement | undefined>()

  const { enqueueSnackbar } = useSnackbar()

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [scrollRef.current])

  const loadMessages = useCallback(async (): Promise<void> => {
    if (paginator?.hasPrevPage) {
      const newMsgs = await paginator.prevPage()

      const msgsMapped = await mapMessagesToNxMessage({
        messages: newMsgs?.items,
        onlineStatusByUserId,
        isGlobal,
      })
      setMessages([...msgsMapped, ...messages])
      setPaginator(newMsgs)
    }
  }, [paginator, messages, isGlobal, onlineStatusByUserId])

  const notifyAPIOfFirstMessage = async ({
    matchId,
    message,
    isCoachChat,
  }: {
    matchId: string | undefined
    message: string
    isCoachChat: boolean
  }): Promise<void> => {
    if (matchId) {
      try {
        await firstMessageMutation({
          variables: {
            input: {
              matchId,
              message,
              isCoachChat,
            },
          },
        })
      } catch (err) {
        // Swallowing the error in the UI. If this call fails it should not trigger
        // any sort of fail state or block the sending of the actual message.
        logger.error(
          '[useMatchChat.firstMessageMutation - error]',
          'error:',
          err
        )
      }
    }
  }

  const sendMessage = useCallback(
    async (val: string): Promise<void> => {
      setIsSending(true)
      const refetchConversation = !usersConversation?.conversation
      if (refetchConversation) {
        Sentry.captureMessage(
          `No conversation found for matchId: ${matchId}, had to refetch from twilio; subscribedConversations: ${Object.keys(
            usersActiveConversationsMap
          )}`
        )
        logger.log(
          `No conversation found for matchId: ${matchId}, had to refetch from twilio; subscribedConversations: ${Object.keys(
            usersActiveConversationsMap
          )}`
        )
      }
      const conversation = refetchConversation
        ? await client?.getConversationByUniqueName(chatUniqueName)
        : usersConversation?.conversation

      const error = getMessageError(val, media, chatRole)

      if (error) {
        setIsSending(false)
        enqueueSnackbar(error, { variant: 'error' })
        return Promise.reject().catch(() =>
          logger.error('[useMatchChat.sendMessage - error]', 'error:', error)
        )
      }

      if (!conversation) {
        setIsSending(false)
        Sentry.captureMessage(`No conversation found for matchId: ${matchId}`)
        return Promise.reject().catch(() =>
          logger.error(
            '[useMatchChat.sendMessage - error]',
            'No conversation found:',
            matchId
          )
        )
      }

      const messageBuilder = conversation.prepareMessage().setBody(val)

      media.forEach((img) => {
        const formData = new FormData()
        formData.append('File', img)
        messageBuilder?.addMedia(formData)
      })

      const promise = messageBuilder
        .buildAndSend()
        .then(async (messageIndex) => {
          conversation.updateLastReadMessageIndex(messageIndex)
          setIsSending(false)
          // Moving setMedia to the .then so that if sending a message fails, the media is not cleared.
          setMedia([])
          const isFirstMessageInTheConversation = messageIndex === 0
          // The matchId must be sent as the chatUniqueName to the API. For a coach chat, the chatUniqueName is the matchId + '-coach'.

          const isCoachChat = chatUniqueName.includes('-coach')

          if (isFirstMessageInTheConversation) {
            await notifyAPIOfFirstMessage({
              matchId,
              message: val,
              isCoachChat,
            })
          }
        })
        .catch((reason) => {
          setIsSending(false)
          if (reason?.body?.status === 403) {
            setMessages([
              ...messages,
              {
                message: val,
                messageId: 'message-unsent',
                timestamp: new Date().toISOString(),
                author: {
                  id: userId,
                  name: userName,
                },
                avatarUrl,
                error: 'Not Delivered. This message is inappropriate.',
                isCoach: chatRole === ChatRole.Coach,
                isAdmin: chatRole === ChatRole.Admin,
                isCaptain: chatRole === ChatRole.Captain,
              },
            ])
          }
        })

      return (
        promise ||
        Promise.reject().finally(() => {
          setIsSending(false)
        })
      )
    },
    [
      isSending,
      usersConversation?.conversation?.uniqueName,
      isStateClosed,
      Boolean(client),
      media,
      chatRole,
      userId,
      userName,
      avatarUrl,
      chatUniqueName,
    ]
  )
  // Initial load
  useEffect(
    function loadInitialConversation() {
      async function loadInitialConversationAsync(): Promise<void> {
        if (
          (conversationSid && isStateClosed) ||
          !usersConversation?.conversation ||
          loading
        ) {
          return
        }

        if (usersConversation?.conversation) {
          // This initial load should be instant, but there is a wait time when the messages include media.
          // This can cause a 'no message' flash before the messages are renderd. Setting a loading state here so that we avoid a flash,
          // but this is still instant for conversations that don't include a lot of media in their first 10 messages.
          // In the future, we can render a loading skeleton for the media.

          if (
            active &&
            usersConversation.conversation.state?.current !== 'closed'
          ) {
            usersConversation.conversation.setAllMessagesRead()
          }

          const mappedMessages = await mapMessagesToNxMessage({
            messages: uniqBy(prop('sid'), usersConversation.messages.items),
            onlineStatusByUserId,
            isGlobal,
          })
          setMessages(mappedMessages)

          setPaginator(usersConversation.messages)
        }

        scrollToBottom()
        setLoadingConversationState(false)
      }
      loadInitialConversationAsync()
    },
    [
      usersConversation?.conversation?.uniqueName,
      conversationSid,
      isStateClosed,
      loading,
      active,
    ]
  )

  // Update the conversation with closed conversations as closed conversations are not apart of subscribed conversations, or check to see there is an error to add admin to the chat.
  // Note that to increase the speed of loading of chat messages, we should save the closed conversation in the user's state. However, the console will throw an error if we call setAllMessagesRead() on a closed conversation.
  useEffect(
    function getConversation() {
      async function getConversationAsync(): Promise<void> {
        if (!usersConversation?.conversation && client && !loading) {
          let conversation: Conversation | undefined

          if (shouldAddAdminToChat) {
            await addAdminUserToChat({
              addUserToChatMutation,
              chatUniqueName,
              userId,
              setErrorCode,
            })
          }

          try {
            conversation =
              isStateClosed && conversationSid
                ? await client.getConversationBySid(conversationSid)
                : await client.getConversationByUniqueName(chatUniqueName)
          } catch (err) {
            const error = err as Record<string, unknown>

            if (error?.status) {
              setErrorCode(error.status as number)
            }
          }

          if (conversation) {
            // Potentially pull this out into a helper function of mapping conversation data to ConversationsProviderMetadata
            const convoMessages = await conversation.getMessages(10)
            const lastMessageContent = head(convoMessages.items)
            const chatLastUpdated = lastMessageContent?.dateUpdated
            const mappedMessages = await mapMessagesToNxMessage({
              messages: convoMessages.items,
              isGlobal,
            })
            setMessages(mappedMessages)

            const convoParticipants = await conversation.getParticipants()

            const participantsWithoutAdmins = convoParticipants.filter(
              (participant) => {
                const {
                  chatRole: participantChatRole,
                } = participant.attributes as ParticipantAttributesJSON
                return participantChatRole !== ChatRole.Admin
              }
            )

            // Set participants in online status map

            const participantsIdentity = participantsWithoutAdmins
              .filter((user) => user.identity)
              .map((user) => user.identity) as Array<string>

            setCurrentUserIdsMap?.({
              ...currentUserIdsMap,
              [conversation.uniqueName as string]: participantsIdentity,
            })

            const metadata = {
              messages: convoMessages,
              chatLastUpdated,
              participants: participantsWithoutAdmins,
              participantsCount: participantsWithoutAdmins.length,
              conversation,
              mappedMessages,
            }

            const newUsersActiveConversations = {
              ...usersActiveConversationsMap,
              [conversation.uniqueName as string]: metadata,
            }

            setUsersConversation(metadata)
            setUsersActiveConversationsMap({ ...newUsersActiveConversations })
            setPaginator(metadata.messages)

            scrollToBottom()
            setLoadingConversationState(false)
          }
        }
      }
      getConversationAsync()
    },
    [
      conversationSid,
      isStateClosed,
      Boolean(client),
      chatUniqueName,
      usersConversation?.conversation?.uniqueName,
      loading,
      shouldAddAdminToChat,
      userId,
    ]
  )

  // Update
  useEffect(
    function updateConversation() {
      async function updateConversationAsync(): Promise<void> {
        // A listener shouldn't be added to conversations that are closed.
        if (usersConversation?.conversation && !isStateClosed) {
          let updatedMessages = await mapMessagesToNxMessage({
            messages: uniqBy(prop('sid'), usersConversation.messages.items),
            onlineStatusByUserId,
            isGlobal,
          })
          const onlineStatusByUserIdMap = onlineStatusByUserId

          usersConversation.conversation.on(
            'messageAdded',
            async (newMsg: Message) => {
              executeCommands(newMsg?.body ?? '', {
                execute: true,
                replace: false,
              })

              updatedMessages = updatedMessages.concat(
                await mapMessagesToNxMessage({
                  messages: [newMsg],
                  onlineStatusByUserId: onlineStatusByUserIdMap,
                  isGlobal,
                })
              )

              // This ensures the messages rendered on the initial load (think clicking between the coach tab and all tab) are up to date
              // with the latest messages.
              const currentStateConvo =
                usersActiveConversationsMap[chatUniqueName]
              if (currentStateConvo?.messages) {
                currentStateConvo.messages.items = usersConversation?.messages.items.concat(
                  newMsg
                )
              }
              setMessages(updatedMessages)
              // Reset the paginator when a message is sent.
              setPaginator(usersConversation.messages)

              try {
                newMsg.getParticipant().then((participant) => {
                  if (participant.identity === userId) {
                    usersConversation.conversation?.updateLastReadMessageIndex(
                      newMsg.index
                    )
                  }
                })
              } catch (err) {
                // swallow error
              }
            }
          )
        }
      }
      updateConversationAsync()
    },
    [usersConversation?.conversation?.uniqueName, isStateClosed, userId]
  )

  return {
    conversation: usersConversation?.conversation,
    messages,
    loadMessages,
    hasOlderMessages: paginator?.hasPrevPage,
    draft,
    setDraft,
    media,
    setMedia,
    isLoading: loading || loadingConversationState,
    isSending,
    sendMessage,
    errorCode,
    setErrorCode,
    scrollRef,
    scrollToBottom,
  }
}
