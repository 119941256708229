import React from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { SvgIcon } from '@material-ui/core'

import { WarningFilled } from '@playvs-inc/nexus-icons'
import { At } from './box-icons/At'
import { CalendarX } from './box-icons/CalendarX'
import { Discord } from './box-icons/Discord'
import { Mobile } from './box-icons/Mobile'
import { Megaphone } from './custom/Megaphone'
import { Time } from './box-icons/Time'
import { PaymentBadge } from './custom/PaymentBadge'
import { Eye } from './custom/Eye'
import { ClosedEye } from './custom/ClosedEye'
import { StateConference } from './logos/StateConference'
import { RegionalNational } from './logos/RegionalNational'
import { Lock } from './custom/Lock'
import { IgniteAnnualPass } from './logos/IgniteAnnualPass'

export enum Icons {
  alert = 'alert',
  at = 'at',
  discord = 'discord',
  mobile = 'mobile',
  megaphone = 'megaphone',
  calendarX = 'calendarX',
  time = 'time',
  rocketLeague = 'rocketLeague',
  fortnite = 'fortnite',
  smite = 'smite',
  leagueOfLegends = 'leagueOfLegends',
  emptyAvatar = 'emptyAvatar',
  paymentBadge = 'paymentBadge',
  eye = 'eye',
  closedEye = 'closedEye',
  stateLogo = 'stateLogo',
  regionalLogo = 'regionalLogo',
  lock = 'lock',
  igniteAnnualPass = 'igniteAnnualPass',
}

const iconsMap = {
  [Icons.alert]: WarningFilled,
  [Icons.at]: At,
  [Icons.discord]: Discord,
  [Icons.mobile]: Mobile,
  [Icons.megaphone]: Megaphone,
  [Icons.calendarX]: CalendarX,
  [Icons.time]: Time,
  [Icons.paymentBadge]: PaymentBadge,
  [Icons.eye]: Eye,
  [Icons.closedEye]: ClosedEye,
  [Icons.stateLogo]: StateConference,
  [Icons.regionalLogo]: RegionalNational,
  [Icons.lock]: Lock,
  [Icons.igniteAnnualPass]: IgniteAnnualPass,
}

interface IconProps extends SvgIconProps {
  icon: Icons
}

export const Icon: React.FC<IconProps> = ({ icon, ...otherProps }) => {
  const MappedIcon = iconsMap[icon]
  return (
    <SvgIcon {...otherProps}>
      <MappedIcon />
    </SvgIcon>
  )
}

export * from './custom'
export * from './logos'
export * from './createSvgIcon'
