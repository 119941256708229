import { Provider } from '@plvs/graphql/generated'

export const providerTitleMap: Record<string, string> = {
  [Provider.BattleDotNet]: 'Battle.net',
  [Provider.Discord]: Provider.Discord,
  [Provider.Twitch]: Provider.Twitch,
  [Provider.Riot]: 'Riot Games',
  [Provider.EpicGames]: 'Epic Games',
  [Provider.XBoxLive]: 'Xbox Live',
  [Provider.Youtube]: 'YouTube',
  [Provider.Spin]: 'Stay Plugged In (SPIN)',
  [Provider.Vesl]: 'VESL',
  [Provider.ChessDotCom]: 'Chess.com',
  [Provider.ChessKid]: 'ChessKid',
  [Provider.ElectronicArts]: 'EA',
  [Provider.StreetFighter]: 'Street Fighter 6',
  [Provider.Nba2K]: 'NBA2K',
  [Provider.Nintendo]: 'Nintendo',
  [Provider.MarvelRivals]: 'Marvel Rivals',
  [Provider.F1]: 'F1',
  [Provider.PokemonUnite]: 'Pokémon UNITE',
}
