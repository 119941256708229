import React, { useState } from 'react'
import {
  StarterPosition,
  TeamMember,
  useRepositionPlayersInTeamMutation,
  useTeamPlayersOnRosterQuery,
  TeamPlayer,
} from '@plvs/graphql'
import { head } from 'ramda'
import { useSnackbar } from 'notistack'
import { ConnectedAccountProviders, useAutoskipQuery } from '@plvs/utils'
import { RepositionPlayersInTeamModal } from '@plvs/respawn/features/manage-teams/RepositionPlayersInTeamModal'
import { Position } from '@plvs/respawn/features/manage-teams/types'
import {
  createRosterRowDataForBench,
  createRosterRowDataForStarters,
  repositionPlayer,
} from './helpers'

export interface RepositionPlayersInTeamProps {
  teamId: string
  player: Pick<TeamMember, 'id'>
  onMutationSuccess?(): void
  leagueId?: string
  metaseasonId?: string
  onClose(): void
  isBench?: boolean
  getUsername: (user: ConnectedAccountProviders) => string
}

export const RepositionPlayersInTeam: React.FC<
  RepositionPlayersInTeamProps
> = ({
  isBench,
  metaseasonId,
  leagueId,
  onClose,
  onMutationSuccess,
  player,
  teamId,
  getUsername,
}) => {
  // state
  const [open, setOpen] = useState(true)
  const [selectedPosition, setSelectedPosition] = useState<Position>({
    id: '',
    positionId: null,
    positionIndex: null,
    currentUser: {
      isPlayer: false,
      hasConsumedPass: false,
    },
  })
  const [error, setError] = useState<Error>()
  const { enqueueSnackbar } = useSnackbar()

  const { data, loading: teamRosterLoading } = useAutoskipQuery(
    useTeamPlayersOnRosterQuery,
    {
      variables: {
        id: teamId,
      },
    }
  )
  const [repositionPlayers, { loading }] = useRepositionPlayersInTeamMutation()
  const teamRoster = data?.team?.roster
  const teamFormatRoster = head(teamRoster?.formats ?? [])
  const teamFormatStarters = teamFormatRoster?.starters ?? []
  const teamMaxSize = teamRoster?.maxNumPlayers ?? 0
  const teamFormat = teamFormatRoster?.teamSize ?? 0
  const maxNumberOfSubs = teamMaxSize - teamFormat

  const teamFormatSubstitutes = teamFormatRoster?.substitutes ?? []
  const rosterRowStarters = createRosterRowDataForStarters({
    list: teamFormatStarters as StarterPosition[],
    getUsername,
    player,
  })
  const rosterRowBench = createRosterRowDataForBench({
    list: teamFormatSubstitutes as TeamPlayer[],
    getUsername,
    player,
    maxNumberOfSubs,
    metaseasonId,
    leagueId,
  })

  const allRosterRowMembers = [
    { label: 'Roster', list: rosterRowStarters },
    { label: 'Bench', list: rosterRowBench },
  ]

  const playerStarter = teamFormatStarters.find(
    (starter) => starter?.player?.user.id === player.id
  )

  const closeDialog = (): void => {
    setOpen(false)
    onClose()
  }
  const onChange = (input: string): void => {
    const allRosterRowEntries = [...rosterRowStarters, ...rosterRowBench]
    const selectedRow = allRosterRowEntries.find((entry) => entry.id === input)
    const position = {
      id: selectedRow?.id ?? '',
      positionId: selectedRow?.positionId ?? null,
      positionIndex: selectedRow?.positionIndex ?? null,
      currentUser: {
        hasConsumedPass: selectedRow?.hasConsumedPass ?? false,
        isPlayer: selectedRow?.title !== 'Position Available',
      },
    }
    setSelectedPosition(position)
  }

  const onClick = async (): Promise<void> => {
    const finalPlayerPositions = repositionPlayer({
      selectedPosition,
      player: playerStarter
        ? { ...player, position: playerStarter.position.index }
        : { ...player, position: null },
      playerToBeRepositionedIsSub: !playerStarter,
    })

    try {
      await repositionPlayers({
        variables: {
          ...(metaseasonId && { metaseasonId }),
          teamId: teamRoster?.teamId ?? '',
          playerPositions: finalPlayerPositions,
        },
      })
      onMutationSuccess?.()
      onClose()
      enqueueSnackbar('Positions changed', {
        variant: 'success',
      })
    } catch (e: any) {
      setError(e)
    }
  }
  return (
    <RepositionPlayersInTeamModal
      allRosterRowMembers={allRosterRowMembers.filter(({ list }) =>
        list.filter((member) => member.id === player.id)
      )}
      error={error}
      isBench={isBench}
      isSubstitute={!playerStarter}
      loading={loading || teamRosterLoading}
      onChange={onChange}
      onClick={onClick}
      onClose={closeDialog}
      open={open}
      rosterRowStarters={rosterRowStarters}
      selectedPosition={selectedPosition}
    />
  )
}
