import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Skeleton } from '@material-ui/lab'

type Props = {
  numRows: number
  rowClassname?: string
}

const useStyle = makeStyles((theme) => ({
  skeleton: {
    marginBottom: theme.spacing(1),
  },
}))

export const BasicRowsSkeleton: React.FC<Props> = ({
  numRows,
  rowClassname,
}) => {
  const styles = useStyle()
  return (
    <Box data-testid="BasicRowsSkeleton_Skeleton">
      {new Array(numRows).fill(0).map((_) => {
        return (
          <Skeleton
            key={`skeleton_row_${Math.round(Date.now() * Math.random())}`}
            animation="pulse"
            className={rowClassname || styles.skeleton}
            variant="rect"
          />
        )
      })}
    </Box>
  )
}
