import React from 'react'
import { useGetLeagueByPublicSlugQuery } from '@plvs/graphql/generated'
import { LeagueMatches } from '../LeagueMatches/LeagueMatches'
import { useLeagueDetails } from './LeagueDetailsProvider'

export const LeagueDetailsMatches: React.FC = () => {
  const {
    phase,
    team,
    setTeam,
    slotFilter,
    metaseasonId,
    publicSlug,
  } = useLeagueDetails()

  const { data } = useGetLeagueByPublicSlugQuery({
    skip: !publicSlug,
    variables: { publicSlug },
  })

  const leagueId = data?.getLeagueByPublicSlug?.id ?? ''

  return (
    <LeagueMatches
      leagueId={leagueId}
      metaseasonId={metaseasonId}
      phase={phase}
      seasonIds={undefined}
      setTeam={setTeam}
      slotFilter={slotFilter}
      team={team}
    />
  )
}
