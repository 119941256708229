import React, { useState } from 'react'
import {
  NxTypography,
  NxButton,
  NxRating,
  NxTextArea,
  FB,
} from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { SurveyType, useSaveSurveyMutation } from '@plvs/graphql/generated'
import { useSnackbar } from 'notistack'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  form: {
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  optionalText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
    },
  },
  ratingWrapper: {
    marginLeft: -theme.spacing(1),
  },
  textArea: {
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

interface Props {
  isEnrollmentUpdated: boolean
  setOnboardingExpanded: React.Dispatch<React.SetStateAction<boolean>>
  setOnboardingSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

export const OnboardSurvey: React.FC<Props> = ({
  isEnrollmentUpdated,
  setOnboardingExpanded,
  setOnboardingSubmitted,
}) => {
  const classes = useStyles()

  const [funRating, setFunRating] = useState<undefined | number>(undefined)
  const [message, setMessage] = useState<string>('')

  const { userId } = useUserIdentityFn()

  const [saveSurvey, { loading }] = useSaveSurveyMutation()

  const { enqueueSnackbar } = useSnackbar()

  const handleChange = (evnt: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setMessage(evnt.target.value)
  }

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    try {
      const result = await saveSurvey({
        variables: {
          input: {
            userId,
            surveyType: isEnrollmentUpdated
              ? SurveyType.ReturningEnrollment
              : SurveyType.NewEnrollment,
            questions: [
              {
                questionText:
                  'How has your journey been leading up to enrollment with PlayVS?',
                response: `${funRating}`,
              },
              {
                questionText:
                  'Anything we can do to make your experience better? (Optional)',
                response: message,
              },
            ],
          },
        },
      })

      if (result?.data?.saveSurvey?.id) {
        setOnboardingExpanded(false)
        setOnboardingSubmitted(true)

        enqueueSnackbar('Saved survey successfully!', { variant: 'success' })
      }
    } catch (error) {
      enqueueSnackbar('Failed to send survey', { variant: 'error' })
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <NxTypography className={classes.title} variant="subtitle1">
        How has your journey been leading up to enrollment with PlayVS?
      </NxTypography>
      <div className={classes.ratingWrapper}>
        <NxRating
          onClick={setFunRating}
          options={[
            { ratingValue: 1, emoteKey: FB.Rage, title: 'Very Hard' },
            { ratingValue: 2, emoteKey: FB.Facepalm, title: 'Hard' },
            { ratingValue: 3, emoteKey: FB.Lurk, title: 'Neutral' },
            { ratingValue: 4, emoteKey: FB.Cool, title: 'Easy' },
            { ratingValue: 5, emoteKey: FB.Gg, title: 'Very Easy' },
          ]}
          value={funRating}
        />
      </div>
      <NxTypography className={classes.optionalText} variant="subtitle1">
        Anything we can do to make your experience better? (Optional)
      </NxTypography>
      <div className={classes.textArea}>
        <NxTextArea
          fullWidth
          onChange={handleChange}
          placeholder="Type here..."
          rows={6}
          value={message}
        />
      </div>
      <Box display="flex" justifyContent="flex-end" mt={3} width="100%">
        <NxButton
          className={classes.button}
          disabled={!funRating || loading}
          label="Submit"
          type="submit"
          variant="primary"
        />
      </Box>
    </form>
  )
}
