import React from 'react'

import { NxButton } from '@playvs-inc/nexus-components'

interface TriggerMatchAssistantAlertsButtonProps {
  label: string
  onClick(): void
  disabled?: boolean
}

export const TriggerMatchAssistantAlertsButton: React.FC<TriggerMatchAssistantAlertsButtonProps> = ({
  label,
  onClick,
  disabled,
}) => {
  return (
    <NxButton
      disabled={disabled}
      fullWidth
      label={label}
      onClick={onClick}
      variant="secondary"
    />
  )
}
