import React, { useContext, createContext, useReducer } from 'react'

type BracketContext = {
  hoveredPartyId: string | null
  dispatch: React.Dispatch<any> | null
}
const initialState = {
  hoveredPartyId: null,
  dispatch: null,
}
const store = createContext<BracketContext>(initialState)
const { Provider } = store

const MatchContextProvider = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer((previousState, action) => {
    switch (action.type) {
      case 'SET_HOVERED_PARTYID': {
        const { partyId } = action.payload ?? {}
        return {
          ...previousState,
          hoveredPartyId: partyId,
        }
      }
      default:
        throw new Error()
    }
  }, initialState)

  return <Provider value={{ ...state, dispatch }}>{children}</Provider>
}

const useMatchHighlightContext = ({
  topPartyId,
  bottomPartyId,
}: {
  topPartyId: string
  bottomPartyId: string
}): { topHovered: boolean; bottomHovered: boolean } => {
  const { hoveredPartyId } = useContext(store)
  const topHovered = topPartyId === hoveredPartyId
  const bottomHovered = bottomPartyId === hoveredPartyId
  return { topHovered, bottomHovered }
}

export { store as matchContext, MatchContextProvider }
export default useMatchHighlightContext
