import { MatchRescheduleRequestStatus } from '@plvs/graphql/generated'
import {
  RawActionAndInfoMatch,
  ActionAndInfoMatch,
} from './ActionAndInfoSection.types'

export function normalizeActionAndInfoMatch(
  match: RawActionAndInfoMatch
): ActionAndInfoMatch {
  const teams = match?.teamContext?.teams ?? []
  return {
    id: match?.id ?? '',
    status: match?.status ?? null,
    esportSlug: match?.esport?.slug ?? null,
    matchRescheduleRequests: (match?.matchRescheduleRequests ?? []).map(
      (request) => ({
        id: request.id,
        status: request.status,
        teamId: request.teamId,
        proposedTimes: request.proposedTimes ?? [],
        matchId: request.matchId,
      })
    ),
    phaseType: match?.slot?.phase?.type ?? null,
    scheduledStartsAt: match?.scheduledStartsAt ?? '',
    isScrimmage: match?.isScrimmage || false,
    teamContext: {
      teams,
    },
    bestOf: match?.bestOf ?? 0,
  }
}

export const determineRescheduleStepOnClick = ({
  matchRescheduleRequests,
  matchId,
  isMemberOfBothTeams,
  myTeamId,
  opposingTeamId,
  setStep,
  setIsRescheduleDialogOpen,
}: {
  matchRescheduleRequests: Array<
    Omit<ActionAndInfoMatch['matchRescheduleRequests'][0], 'proposedTimes'>
  >
  matchId: string
  isMemberOfBothTeams: boolean
  myTeamId: string
  opposingTeamId?: string | null
  setStep: (step: string) => void
  setIsRescheduleDialogOpen: (isOpen: boolean) => void
}): void => {
  const matchRescheduleRequest = matchRescheduleRequests.find((request) =>
    request.matchId === matchId &&
    request.status === MatchRescheduleRequestStatus.Pending &&
    isMemberOfBothTeams
      ? request.teamId === myTeamId || request.teamId === opposingTeamId
      : request.teamId === myTeamId
  )
  if (matchRescheduleRequest) {
    setStep('read-request')
  } else {
    setStep('update-request')
  }
  setIsRescheduleDialogOpen(true)
}
