import React from 'react'

import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { MatchAssistant } from '@plvs/rally/features/match/assistant/MatchAssistant'
import { MatchAssistantStepAlertsProvider } from '@plvs/rally/features/match/assistant/hooks'
import { MatchAssistantProvider } from '@plvs/rally/features/match/assistant/useMatchAssistant'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'
import { EsportSlug } from '@plvs/graphql/types'

interface MatchAssistantWrapperProps {
  matchId: string
  skipMatchAssistant(): Promise<void>
  esportSlug: EsportSlug
}

export const MatchAssistantWrapper: React.FC<MatchAssistantWrapperProps> = ({
  matchId,
  skipMatchAssistant,
  esportSlug,
}) => {
  const theme = useTheme()
  const [isMatchComplete, setIsMatchComplete] = React.useState(false)

  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()

  const {
    shouldRenderMatchAssistant,
    teamsToRenderMatchAssistantFor,
  } = getMatchLobbyRenderControllerState().match

  if (!shouldRenderMatchAssistant) return null

  // When isMatchComplete is true, the Match Assistant is displaying the final score step.
  // This step shows the scores for all teams and is only displayed once,
  // as users only need to view the final summary a single time.

  if (!isMatchComplete) {
    return (
      <Box
        data-testid="match-assistant-container"
        display="flex"
        flexWrap="wrap"
        gridGap={theme.spacing(3)}
        justifyContent="space-between"
        width="100%"
      >
        {teamsToRenderMatchAssistantFor.map((team) => (
          <MatchAssistantProvider
            key={team.id}
            esport={esportSlug}
            matchId={matchId}
            myTeamId={team.id}
            skipMatchAssistant={skipMatchAssistant}
          >
            <MatchAssistantStepAlertsProvider
              matchId={matchId}
              teamId={team.id}
            >
              <MatchAssistant
                setIsMatchComplete={setIsMatchComplete}
                teamNameForSubtitle={team.name}
              />
            </MatchAssistantStepAlertsProvider>
          </MatchAssistantProvider>
        ))}
      </Box>
    )
  }

  const firstTeamInList = teamsToRenderMatchAssistantFor[0]
  return (
    <MatchAssistantProvider
      esport={esportSlug}
      matchId={matchId}
      myTeamId={firstTeamInList.id}
      skipMatchAssistant={skipMatchAssistant}
    >
      <MatchAssistantStepAlertsProvider
        matchId={matchId}
        teamId={firstTeamInList.id}
      >
        <MatchAssistant
          setIsMatchComplete={setIsMatchComplete}
          teamNameForSubtitle={firstTeamInList.name}
        />
      </MatchAssistantStepAlertsProvider>
    </MatchAssistantProvider>
  )
}
