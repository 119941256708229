import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'
import {
  NxButton,
  NxFirebobMessage,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import React from 'react'
import { useSubmitGameAssistantStepSelectionsMutation } from '@plvs/graphql'
import { logger } from '@plvs/rally/logging'
import { MatchAssistantAlertType } from '@plvs/utils'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { PlayersMatchUp } from '../../../PlayersMatchUp'
import { useStyles } from '../../../MatchAssistant.styles'
import {
  mapTeamRoster,
  useMatchAssistantContext,
} from '../../../useMatchAssistant'
import { useMatchAssistantStepAlertsContext } from '../../../hooks/useMatchAssistantStepAlerts'

export const PrepareForInvite: React.FC = () => {
  const styles = useStyles()
  const theme = useTheme()
  const {
    homeTeam,
    awayTeam,
    gameOrdinal,
    currentStep,
  } = useMatchAssistantContext()

  const [
    submitGameAssistantStepSelections,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const isWaitingForOpponent = currentStep?.step?.submitted

  const handleNext = async (): Promise<void> => {
    try {
      await submitGameAssistantStepSelections({
        variables: {
          input: {
            stepId: currentStep?.step?.id ?? '',
          },
        },
      })
    } catch (err) {
      logger.error(err)
    }
  }

  const { openStepAlert, getStepAlerts } = useMatchAssistantStepAlertsContext()
  const stepAlertsData = getStepAlerts()

  const spectatorPicks = currentStep?.spectatorPicks ?? []
  const homeTeamSpectator = spectatorPicks.find(
    (pick) => pick.gameAssistantStep?.teamId === homeTeam?.id
  )
  const awayTeamSpectator = spectatorPicks.find(
    (pick) => pick.gameAssistantStep?.teamId === awayTeam?.id
  )
  const showSpectators =
    Boolean(homeTeamSpectator?.userId) || Boolean(awayTeamSpectator?.userId)
  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="column">
        <NxTypography variant="h1">Prepare for Game {gameOrdinal}</NxTypography>

        <Box pt={3}>
          <PlayersMatchUp
            currentPlayersAwayTeam={mapTeamRoster({
              teamRoster: awayTeam?.starters ?? [],
            })}
            currentPlayersHomeTeam={mapTeamRoster({
              teamRoster: homeTeam?.starters ?? [],
            })}
          />
        </Box>

        {showSpectators && (
          <Box pb={3}>
            <Box display="flex" justifyContent="center" pb={1}>
              <NxTypography variant="h4">Spectators</NxTypography>
            </Box>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gridGap={theme.spacing(2)}
              justifyContent="center"
              marginLeft={-2}
            >
              <NxUserCluster
                alignment="right"
                avatarHashId={homeTeamSpectator?.userId ?? ''}
                avatarUrl={homeTeamSpectator?.user?.avatarUrl ?? ''}
                subtitles={[
                  {
                    title:
                      homeTeamSpectator?.selectionOption?.displayName ?? '',
                  },
                ]}
                title={
                  homeTeamSpectator?.userId
                    ? homeTeamSpectator?.user?.name ?? ''
                    : 'No Spectator'
                }
              />
              <Box
                display="flex"
                flexDirection="column"
                height="18.6px"
                justifyContent="center"
                pl={-1}
                pr={1}
                width="26px"
              />
              <Box ml={-1}>
                <NxUserCluster
                  avatarHashId={awayTeamSpectator?.userId ?? ''}
                  avatarUrl={awayTeamSpectator?.user?.avatarUrl ?? ''}
                  subtitles={[
                    {
                      title:
                        awayTeamSpectator?.selectionOption?.displayName ?? '',
                    },
                  ]}
                  title={
                    awayTeamSpectator?.userId
                      ? awayTeamSpectator?.user?.name ?? ''
                      : 'No Spectator'
                  }
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage message="Once all players are in-game and ready, PlayVS will send invites." />

        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        <Box
          className={styles.ctaContainer}
          display="flex"
          gridGap={theme.spacing(1)}
          width="100%"
        >
          <NxButton
            className={styles.cta}
            disabled={
              isWaitingForOpponent ||
              updateLoading ||
              stepAlertsData.actionLoading
            }
            fullWidth
            label="Change Roster"
            loading={stepAlertsData.actionLoading}
            onClick={(): void => openStepAlert(MatchAssistantAlertType.Reset)}
            variant="secondary"
          />
          <NxButton
            className={styles.cta}
            disabled={isWaitingForOpponent || updateLoading}
            fullWidth
            label="Ready for Invites"
            loading={isWaitingForOpponent}
            onClick={handleNext}
            variant="primary"
          />
        </Box>
      </MatchAssistantFooter>
    </>
  )
}
