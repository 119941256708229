import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  CompetitionGroup,
  Metaseason,
  UserRoleStatusName,
} from '@plvs/graphql/generated'
import {
  NxTextLink,
  NxTooltip,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import { CaptainNew } from '@playvs-inc/nexus-icons'

import { useAppLocationFn } from '@plvs/client-data/hooks'
import { Location } from '@plvs/client-data/models'
import { EsportSlug } from '@plvs/graphql/types'
import { IntercomArticleMappings, Path } from '@plvs/const'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import {
  ContentContextPermissions,
  GrantedRosterMenuPermissions,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuHelpersV2'
import {
  RosterCardMenuRowItems,
  RosterCardMenuRowSubItems,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import { useRosterCardRenderControllerContext } from '@plvs/respawn/renderController/rosterCard/RosterCardRenderControllerProvider'
import { RosterRowItem } from './RosterRowItem'
import {
  BenchRosterPlayer,
  RowMenuProps,
  StarterRosterPlayer,
  UserRole,
} from '../RosterProvider.types'
import {
  ErrorSubtitle,
  playerAccountStatusDetails,
} from './PlayerAccountStatusDetails.helpers'

import {
  RosterRowFilledStatsMadden,
  RosterRowFilledStatsNBA,
  RosterRowFilledStatsRocketLeague,
  RosterRowFilledStatsSmash,
  RosterRowFilledStatsSplatoon,
} from './stats'
import { getLinkedSubtitle } from '../RosterCard.helpers'

const useStyles = makeStyles((theme) => ({
  linkColor: {
    color: theme.palette.ColorTextAlt2,
    '&:hover': {
      color: theme.palette.ColorTextLink,
    },
  },
}))

export interface RosterRowFilledProps {
  member: StarterRosterPlayer | BenchRosterPlayer
  isLastNode: boolean
  disableAction: boolean
  teamId: string
  RowMenu: React.FC<RowMenuProps>

  getPermissions(
    memberRoles: UserRole[],
    contentContext: ContentContextPermissions,
    isSelf?: boolean
  ): GrantedRosterMenuPermissions

  members: (StarterRosterPlayer | BenchRosterPlayer)[]

  onMutationSuccess?(): Promise<void>

  metaseason?: Pick<Metaseason, 'id' | 'endsAt'>
  userId: string
  rosterCardStarterRowActions?: RosterCardMenuRowItems[]
  rosterCardSubRowActions?: RosterCardMenuRowSubItems[]
  esportSlug: EsportSlug | undefined
  publicView?: boolean
  isAdmin?: boolean
  competitionGroup: CompetitionGroup | undefined
}

export const getRosterRowFilledStatsForEsport = ({
  esportSlug,
  memberId,
}: {
  esportSlug: EsportSlug | undefined
  memberId: string
}): React.ReactElement | null => {
  if (esportSlug?.includes('super-smash-bros-ultimate')) {
    return <RosterRowFilledStatsSmash memberId={memberId} />
  }

  if (esportSlug?.includes('madden')) {
    return <RosterRowFilledStatsMadden memberId={memberId} />
  }

  switch (esportSlug) {
    case EsportSlug.SmashBrosUltimate:
    case EsportSlug.StreetFighter:
      return <RosterRowFilledStatsSmash memberId={memberId} />
    case EsportSlug.RocketLeague:
      return <RosterRowFilledStatsRocketLeague memberId={memberId} />
    case EsportSlug.Splatoon:
      return <RosterRowFilledStatsSplatoon memberId={memberId} />
    case EsportSlug.Madden:
    case EsportSlug.Madden21PS4:
    case EsportSlug.Madden21XboxOne:
    case EsportSlug.Madden21SolosXboxOne:
      return <RosterRowFilledStatsMadden memberId={memberId} />
    case EsportSlug.NBA2K:
      return <RosterRowFilledStatsNBA memberId={memberId} />
    default:
      return null
  }
}

export const RosterRowFilled: React.FC<RosterRowFilledProps> = ({
  member,
  isLastNode,
  disableAction,
  RowMenu,
  members,
  getPermissions,
  onMutationSuccess,
  teamId,
  metaseason,
  userId,
  rosterCardStarterRowActions = [],
  rosterCardSubRowActions = [],
  esportSlug,
  isAdmin = false,
  competitionGroup,
}) => {
  const { rosterCard, getRenderControllerState, setRenderControllerStateFn } =
    useRosterCardRenderControllerContext()
  const { getRosterRowItemRender } = rosterCard
  const theme = useTheme()
  const location = useAppLocationFn()
  const { linkColor } = useStyles()
  const navigate = useNavigate()
  const flags = useFlags()

  const isPlayerProfileEnabled = flags.playerProfile
  const memberId = member?.user?.id ?? ''
  const avatarUrl = member?.user?.avatarUrl ?? undefined
  const name = member?.user?.name ?? ''
  const userName = member?.userName
  const { accountStatus } = member
  const isInCheckpoint = location === Location.Checkpoint

  const schoolRoles = member?.user?.schoolRoleStatus ?? []
  const isGraduated = schoolRoles.some(
    (schoolRole) => schoolRole?.status === UserRoleStatusName.Graduated
  )
  const hasTransferred = schoolRoles.some(
    (schoolRole) => schoolRole?.status === UserRoleStatusName.Transferred
  )
  const isArchived = Boolean(isGraduated || hasTransferred)

  const handleAvatarClick = (): void => {
    if (location === Location.Stadium) {
      return
    }
    if (memberId) {
      if (isInCheckpoint) {
        window.open(`/user/${memberId}`, '_blank', 'noopener')
      } else if (isPlayerProfileEnabled) {
        navigate(`${Path.App}${Path.Profile}/${memberId}`)
      }
    }
  }

  const subtitles = [
    ...(userName
      ? [
          {
            title: getLinkedSubtitle(
              userName,
              esportSlug,
              accountStatus,
              linkColor,
              isArchived
            ),
          },
        ]
      : []),
    isArchived
      ? {
          title: (
            <ErrorSubtitle
              title={
                isGraduated
                  ? 'This player has graduated'
                  : 'This player has transferred schools'
              }
              tooltip="Please remove this player and add an active verified player"
            />
          ),
        }
      : {
          title: playerAccountStatusDetails(accountStatus),
        },
  ].filter(({ title }) => Boolean(title))

  const { shouldRenderCaptainIcon } = getRosterRowItemRender({
    userRoles: member?.user?.directRoles ?? [],
    competitionGroup,
    teamId,
  })

  useEffect(() => {
    const currentState = getRenderControllerState()
    setRenderControllerStateFn({
      ...currentState,
      rosterCard: {
        ...currentState.rosterCard,
        shouldRenderCaptainIcon,
      },
    })
  }, [shouldRenderCaptainIcon])

  return (
    <RosterRowItem
      content={
        <Box
          display="flex"
          flexDirection={['column', 'row']}
          gridGap={theme.spacing(2)}
        >
          <Box width={280}>
            <NxUserCluster
              avatarHashId={memberId}
              avatarUrl={avatarUrl}
              onClickAvatar={handleAvatarClick}
              subtitles={subtitles}
              title={
                shouldRenderCaptainIcon ? (
                  <NxTooltip
                    arrow
                    data-testid="captain-tooltip"
                    interactive
                    leaveDelay={2000}
                    placement="top-end"
                    title={
                      <Box
                        alignItems="center"
                        display="flex"
                        gridGap={theme.spacing(0.5)}
                      >
                        Team Captain.
                        <NxTextLink
                          label="Learn more"
                          labelVariant="body4"
                          onClick={(e: React.SyntheticEvent): void => {
                            e.stopPropagation()
                            showIntercomArticle(
                              IntercomArticleMappings.captains
                            )
                          }}
                        />
                      </Box>
                    }
                  >
                    <Box
                      alignItems="center"
                      display="flex"
                      gridGap={theme.spacing(1)}
                    >
                      <NxTypography variant="body2">{name}</NxTypography>

                      <CaptainNew
                        data-testid="captain-icon"
                        height={16}
                        width={16}
                      />
                    </Box>
                  </NxTooltip>
                ) : (
                  name
                )
              }
            />
          </Box>

          <Box
            alignItems="center"
            display="flex"
            ml={[9.5, 0]}
            mr={1}
            mt={[-2.5, 0]}
          >
            {getRosterRowFilledStatsForEsport({ esportSlug, memberId })}
          </Box>
        </Box>
      }
      end={
        !disableAction ? (
          <RowMenu
            getPermissions={getPermissions}
            isAdmin={isAdmin}
            member={member}
            members={members}
            metaseason={metaseason}
            onMutationSuccess={onMutationSuccess}
            rosterCardStarterRowActions={rosterCardStarterRowActions}
            rosterCardSubRowActions={rosterCardSubRowActions}
            teamId={teamId}
            userId={userId}
          />
        ) : null
      }
      isLastNode={isLastNode}
    />
  )
}
