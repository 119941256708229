import React from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { NxTypography } from '@playvs-inc/nexus-components'
import { useStyles } from '../AppDrawerToggle.styles'

export const EmptyNotifications: React.FC<{ onClose?: () => void }> = ({
  onClose,
}) => {
  const isMobile = useBreakpointSm()
  const classes = useStyles({ isMobile })

  return (
    <Box
      alignItems="center"
      data-testid="EmptyNotifications_EmptyNotificationsContainer"
      display="flex"
      flexDirection="column"
      onClick={onClose}
    >
      <NxSpot
        domain="notification"
        height={100}
        size="small"
        variant="secondary"
        width={100}
      />
      <NxTypography className={classes.emptyTitle} variant="h3">
        No Notifications Yet
      </NxTypography>
      <NxTypography className={classes.emptyBody} variant="body1">
        Check back for updates on upcoming matches and tasks that need to be
        completed
      </NxTypography>
    </Box>
  )
}
