import React from 'react'
import { EsportSlug } from '@plvs/graphql/types'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { NxEmptyState } from '@playvs-inc/nexus-components'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { StandingsByEsport } from '../../standings/StandingsByEsport'
import { PhaseFilterType } from '../LeagueDetails.types'

interface Props {
  esportSlug: EsportSlug | undefined
  phase: PhaseFilterType | undefined
  seasonId: string
}

export const LeagueStandings: React.FC<Props> = ({
  esportSlug,
  phase,
  seasonId,
}) => {
  if (!phase) {
    return (
      <NxEmptyState
        spot={
          <NxSpot
            domain="standing"
            height={200}
            size="large"
            variant="secondary"
            width={200}
          />
        }
        subtitle="Standings will be available once results have been submitted."
        title="Standings not available yet"
      />
    )
  }

  return (
    <Esport slug={esportSlug}>
      <StandingsByEsport phase={phase} seasonId={seasonId} />
    </Esport>
  )
}
