import React from 'react'
import { Grid, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import tz from 'dayjs/plugin/timezone'
import { NxEsportIcon, NxTypography } from '@playvs-inc/nexus-components'
import dayjs from '@plvs/respawn/init/dayjs'
import { Box } from '@plvs/respawn/features/layout'
import { MatchStatus } from '@plvs/graphql/generated'
import { Path, SHORT_MONTH_AND_DAY_AND_DOW } from '@plvs/const'
import { LiveIndicator } from '@plvs/rally/features/app/drawer/matches/LiveIndicator'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { OverflowTooltip } from '@plvs/respawn/components/OverflowTooltip'
import { MappedMatch } from '@plvs/rally/features/match/schedule/schedule'
import { useNxEsportsContext } from '@playvs-inc/nexus-theme'
import { useStyles } from './MatchRow.styles'

dayjs.extend(tz)

interface MatchRowProps {
  className?: string
  match: MappedMatch
  showEsportAccent?: boolean
  actions?: React.ReactNode
  showFullDate?: boolean
  children?: React.ReactNode
}

export const MatchRow: React.FC<MatchRowProps> = ({
  className,
  match,
  showEsportAccent = false,
  showFullDate = false,
  actions,
  children,
}) => {
  const theme = useTheme()
  const esportSlug = match?.esport?.slug
  const { name: esportName } = useGeneralEsportAdapter(esportSlug)

  const isMatchLive =
    match?.status === MatchStatus.Live || match?.status === MatchStatus.Open

  const esportPalettes = useNxEsportsContext()
  const classes = useStyles({
    isMatchLive,
    showEsportAccent,
    esportSlug,
    esportPalettes,
  })

  const matchDate = dayjs(match?.scheduledStartsAt).format(
    SHORT_MONTH_AND_DAY_AND_DOW
  )
  const matchTime = dayjs(match?.scheduledStartsAt).format('h:mm A')

  const matchDateTime = (
    <Box mb={0.5}>
      {isMatchLive ? (
        <LiveIndicator
          isLive={isMatchLive}
          typographyClassName={classes.liveText}
          typographyVariant="body2"
        />
      ) : (
        <>
          {showFullDate && (
            <NxTypography
              className={clsx(classes.matchDate, classes.matchDateSection)}
              data-testid="matchDate"
              variant="body2"
            >
              {matchDate}
            </NxTypography>
          )}
          <NxTypography className={classes.matchDateSection} variant="body2">
            {matchTime}
          </NxTypography>
        </>
      )}
    </Box>
  )

  const matchEsport = (
    <Box alignItems="center" display="flex" gridGap={4}>
      <NxEsportIcon
        esportSlug={esportSlug ?? ''}
        height="20"
        mode={theme.palette.type}
        width="20"
      />
      <NxTypography colorToken="ColorTextAlt" noWrap variant="body3">
        <OverflowTooltip>{esportName}</OverflowTooltip>
      </NxTypography>
    </Box>
  )

  return (
    <Box
      className={clsx(classes.root, className)}
      data-cy="teamMatchRow"
      data-testid="match-row"
      px={3}
      py={2}
    >
      <Grid
        alignItems="center"
        container
        data-testid="team-match-row-grid"
        direction="row"
        onClick={(): void => {
          if (actions) {
            return
          }

          window.open(`${Path.Match}/${match.id}`, '_blank')
        }}
      >
        <Grid item xs={2}>
          {matchDateTime}
          {matchEsport}
        </Grid>

        {children}

        {actions && (
          <Grid data-testid="actions" item xs={2}>
            {actions}
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
