import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import {
  NxButton,
  NxTypography,
  NxEmote,
  FB,
} from '@playvs-inc/nexus-components'
import { SessionStorageKey } from '@plvs/const'

import { useSendParentVerificationEmailMutation } from '@plvs/graphql/generated'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  text: {
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  textbox: {
    backgroundColor: theme.palette.ColorBackgroundTooltip,
    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderRight: `8px solid ${theme.palette.ColorBackgroundTooltip}`,
      position: 'absolute',
      left: -4,
    },
  },
}))

interface Props {
  email: string
}

export const ParentOnboardVerification: React.FC<Props> = ({ email }) => {
  const classes = useStyles()

  const [sendParentVerificationEmail] = useSendParentVerificationEmailMutation()

  useEffect(() => {
    sessionStorage.removeItem(SessionStorageKey.OnboardingContext)
    sendParentVerificationEmail()
  }, [])

  const handleContinue = (): void => {
    window.location.replace('/app/parent-dashboard')
  }

  return (
    <div className={classes.root}>
      <QuestionnaireCard childWidth={420} title="">
        <NxTypography className={classes.text} variant="display3">
          Almost there!
        </NxTypography>
        <NxTypography className={classes.text} variant="display3">
          We need to verify your identity.
        </NxTypography>
        <NxTypography
          className={classes.subtitle}
          colorToken="ColorTextAlt"
          variant="body1"
        >
          {`To comply with child data privacy laws, we need to verify you as an adult before you can create your child’s account. We just sent an email to ${email}. Please follow the instructions in the email to get verified.`}
        </NxTypography>
        <Box alignItems="center" display="flex" justifyContent="center" my={3}>
          <NxEmote emoteKey={FB.Wave} height={40} width={40} />
          <Box
            alignItems="center"
            borderRadius="4px"
            className={classes.textbox}
            display="flex"
            justifyContent="center"
            maxWidth={280}
            ml={1}
            padding="4px 10px"
            position="relative"
            width="100%"
          >
            <NxTypography colorToken="OverlayColorTextBase" variant="body4">
              Any personal information gathered for verification purposes,
              including social security number and credit card details, will not
              be not stored. This information will be deleted after you are
              verified.
            </NxTypography>
          </Box>
        </Box>

        <NxButton
          fullWidth
          label="continue to Parent dashboard"
          onClick={handleContinue}
          variant="primary"
        />
      </QuestionnaireCard>
    </div>
  )
}
