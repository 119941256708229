import React from 'react'
import { EsportSlug } from '@plvs/graphql'
import {
  getEsportBanner,
  PersonaImageVariant,
  personaImagesMap,
  PersonaImages,
} from '@playvs-inc/nexus-components'
import { UseImageLoaderProps, UseImageLoaderReturn } from './types'
import { esportImagesMap } from './esportDesignAssets'
import { useImageLoader } from '../../avatar'

const randomN = (n: number): number => Math.floor(Math.random() * (n - 1))

export const randomImage = (slug: EsportSlug | null): string => {
  if (!slug) return ''
  const images = esportImagesMap[slug] ?? []
  const index = randomN(images.length)
  return images?.[index] ?? ''
}

function getPersonaImages(
  variant: PersonaImageVariant,
  esportSlug?: EsportSlug | null
): string {
  if (esportSlug) {
    return getEsportBanner(esportSlug, variant)
  }

  return personaImagesMap.PlayVs[variant] as string
}

export const useMatchImageLoader = ({
  src,
  esportSlug,
  variant,
  disableFallback,
}: UseImageLoaderProps): UseImageLoaderReturn => {
  let img: string | PersonaImages = React.useMemo(
    () => randomImage(esportSlug),
    [esportSlug]
  )

  if (variant) {
    img = getPersonaImages(variant, esportSlug)
  }

  const loaderOption = disableFallback
    ? {
        src: src || img,
        fallbackSrc: '',
      }
    : {
        src: src || img,
      }

  return useImageLoader(loaderOption)
}
