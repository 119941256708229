import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { SponsorshipImageName } from '@plvs/const'

import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { Linkout } from '@playvs-inc/nexus-icons'
import { CreateCSSProperties } from '@material-ui/styles'
import { SponsorshipComponent as SponsorshipComponentType } from '@plvs/graphql'
import { SponsorshipComponentConfigs } from './SponsorshipComponentConfigs'

interface StyleProps {
  showLinkInTitle: boolean
  isMobile: boolean
  fullWidth: boolean
  pointerCursorForContainer: boolean
}

export const useStyles = makeStyles((theme) => ({
  backgroundImage: ({ fullWidth }: StyleProps): CreateCSSProperties => ({
    position: fullWidth ? 'absolute' : 'relative',
    top: 0,
    left: fullWidth ? 0 : 'unset',
    zIndex: -1,
    objectFit: 'fill',
    height: fullWidth ? '100%' : 'unset',
    width: fullWidth ? '100%' : 'unset',
    borderRadius: '10px',
  }),
  container: ({
    isMobile,
    fullWidth,
    pointerCursorForContainer,
  }: StyleProps): CreateCSSProperties => ({
    position: 'relative',
    cursor: isMobile || pointerCursorForContainer ? 'pointer' : 'initial',
    zIndex: 1,
    justifyContent: fullWidth ? 'none' : 'center',
  }),
  buttonContainer: {
    minWidth: '120px',
  },
  titleText: ({ showLinkInTitle }: StyleProps): CreateCSSProperties => ({
    cursor: showLinkInTitle ? 'pointer' : 'initial',
  }),
  linkout: {
    color: theme.palette.OverlayColorTextBase,
  },
}))

type SponsorshipComponentProps = {
  component: SponsorshipComponentType
}

export const SponsorshipComponent = ({
  component,
}: SponsorshipComponentProps): React.ReactElement => {
  const isMobile = useBreakpointSm()

  const logoImage = component?.sponsorshipImages?.find(
    (i) => i.name === SponsorshipImageName.BannerLogo
  )
  const mobileLogoImage = component?.sponsorshipImages?.find(
    (i) => i.name === SponsorshipImageName.MobileBannerLogo
  )
  const bannerImage = component?.sponsorshipImages?.find(
    (i) => i.name === SponsorshipImageName.Banner
  )
  const mobileBannerImage = component?.sponsorshipImages?.find(
    (i) => i.name === SponsorshipImageName.MobileBanner
  )

  const backgroundImage = isMobile ? mobileBannerImage?.url : bannerImage?.url
  const logo = isMobile ? mobileLogoImage?.url : logoImage?.url

  const showLinkInTitle =
    !component?.callToAction && component?.url && !isMobile && component.title

  const config = SponsorshipComponentConfigs[component.name]
  const classes = useStyles({
    showLinkInTitle: !!showLinkInTitle,
    isMobile,
    fullWidth: config.fullWidth,
    pointerCursorForContainer: !component?.callToAction && !component?.title,
  })

  if ((isMobile && !mobileBannerImage) || (!isMobile && !bannerImage)) {
    return <></>
  }

  return (
    <Box
      alignItems="center"
      className={classes.container}
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      onClick={(): void => {
        if (isMobile || (!component.callToAction && !component.title)) {
          window.open(component.url ?? '', '_blank', 'noreferrer')
          window?.focus()
        }
      }}
      px={3}
      py={2}
    >
      <img
        alt="sponsorship-background"
        className={classes.backgroundImage}
        src={backgroundImage}
      />
      {logo ? (
        <Box display="flex" mb={isMobile ? 2 : 0} mr={isMobile ? 0 : 3}>
          <img alt="sponsorship-logo" src={logo} />
        </Box>
      ) : (
        <></>
      )}
      <Box display="flex" flexDirection="column">
        <Box
          alignItems="center"
          className={classes.titleText}
          display="flex"
          flexDirection="row"
          mb={1}
          onClick={(): void => {
            if (showLinkInTitle && !isMobile) {
              window.open(component.url ?? '', '_blank', 'noreferrer')
              window?.focus()
            }
          }}
        >
          <NxTypography colorToken="OverlayColorTextBase" variant="h4">
            {component.title}
          </NxTypography>
          {showLinkInTitle ? (
            <>
              <Linkout className={classes.linkout} height={20} />
            </>
          ) : (
            <></>
          )}
        </Box>
        <NxTypography colorToken="OverlayColorTextBase" variant="body1">
          {component.subtitle}
        </NxTypography>
      </Box>
      {component.callToAction && component.url && !isMobile ? (
        <Box
          alignSelf={isMobile ? 'flex-end' : ''}
          className={classes.buttonContainer}
          ml={3}
          mt={isMobile ? 2 : 0}
        >
          <NxButton
            label={component.callToAction}
            onClick={(): void => {
              window.open(component.url ?? '', '_blank', 'noreferrer')
              window?.focus()
            }}
            shouldUseOverlayColors
            variant="secondary"
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}
