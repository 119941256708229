import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'
import {
  NxButton,
  NxFirebobMessage,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { MatchAssistantAlertType } from '@plvs/utils'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { noop } from 'ramda-adjunct'
import { useStyles } from '../../../MatchAssistant.styles'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantStepAlertsContext } from '../../../hooks/useMatchAssistantStepAlerts'

interface MatchPreviewProps {
  onNext?(): void

  children?: React.ReactNode
}

export const MatchPreview: React.FC<MatchPreviewProps> = ({
  onNext = noop,
  children,
}) => {
  const styles = useStyles()
  const theme = useTheme()
  const { gameOrdinal } = useMatchAssistantContext()

  const {
    match: { canSpectate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const isNextDisabled = canSpectate
  const { openStepAlert, getStepAlerts } = useMatchAssistantStepAlertsContext()
  const stepAlertsData = getStepAlerts()

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
        justifyContent="center"
        width="100%"
      >
        <NxTypography variant="h1">Match Preview</NxTypography>

        <NxTypography variant="overline">Teams</NxTypography>

        {children}
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage
          message={`Go play game ${gameOrdinal} and come back to report the results.`}
        />

        <Box
          className={styles.ctaContainer}
          display="flex"
          gridGap={theme.spacing(1)}
          width="100%"
        >
          <NxButton
            className={styles.cta}
            disabled={stepAlertsData.actionLoading}
            fullWidth
            label="Change Roster"
            loading={stepAlertsData.actionLoading}
            onClick={(): void => openStepAlert(MatchAssistantAlertType.Reset)}
            variant="secondary"
          />

          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label="Report Results"
            onClick={onNext}
            variant="primary"
          />
        </Box>
      </MatchAssistantFooter>
    </>
  )
}
