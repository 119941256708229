import React, { useState } from 'react'
import { useSnackbar } from 'notistack'

import { NxButton, NxModal } from '@playvs-inc/nexus-components'

import {
  useResetGameAssistantStepsForGameMutation,
  useResetGameAssistantStepsForMatchMutation,
  useResetGameAssistantStepsForSeriesMutation,
} from '@plvs/graphql'
import { logger } from '@plvs/rally/logging'
import { refetchCurrentGameAssistantStepByMatchIdFunction } from '@plvs/utils'

export enum ResetOption {
  Match = 'match',
  Series = 'series',
  Game = 'game',
}

export const resetSubtitles = {
  [ResetOption.Match]: 'You are about to reset the entire match.',
  [ResetOption.Series]: 'You are about to reset the series.',
  [ResetOption.Game]: 'You are about to reset the game.',
  Default: 'Whoops!',
}

export function getSubtitle(value?: ResetOption): string {
  return (value && resetSubtitles[value]) ?? resetSubtitles.Default
}

interface ResetMatchAssistantProps {
  matchId?: string
  gameOrdinal?: number
  seriesOrdinal?: number
  refetchCurrentGameAssistantStepByMatchId: refetchCurrentGameAssistantStepByMatchIdFunction
  teamId?: string
}

interface ResetMatchAssistant {
  resetOption?: ResetOption
  setResetOption: React.Dispatch<React.SetStateAction<ResetOption | undefined>>

  resetMatchAssistant(): Promise<void>

  ResetMatchAssistantModal?: React.ReactElement
}

export const useResetMatchAssistant = ({
  matchId = '',
  gameOrdinal = 1,
  seriesOrdinal = 1,
  refetchCurrentGameAssistantStepByMatchId,
}: ResetMatchAssistantProps): ResetMatchAssistant => {
  const [resetOption, setResetOption] = useState<ResetOption>()

  const { enqueueSnackbar } = useSnackbar()

  const [
    resetGameAssistantStepsForGame,
  ] = useResetGameAssistantStepsForGameMutation()
  const [
    resetGameAssistantStepsForSeries,
  ] = useResetGameAssistantStepsForSeriesMutation()
  const [
    resetGameAssistantStepsForMatch,
  ] = useResetGameAssistantStepsForMatchMutation()

  const resetMatchAssistant = async (): Promise<void> => {
    try {
      switch (resetOption) {
        case ResetOption.Match:
          await resetGameAssistantStepsForMatch({
            variables: {
              matchId,
            },
          })
          break
        case ResetOption.Series:
          await resetGameAssistantStepsForSeries({
            variables: {
              matchId,
              seriesOrdinal,
            },
          })
          break
        case ResetOption.Game:
          await resetGameAssistantStepsForGame({
            variables: {
              matchId,
              seriesOrdinal,
              gameOrdinal,
            },
          })
          break
        default:
          break
      }

      refetchCurrentGameAssistantStepByMatchId()
      setResetOption(undefined)

      enqueueSnackbar(`Successfully reset ${resetOption}`, {
        variant: 'success',
      })
    } catch (err) {
      logger.error('[resetMatchAssistant]', err)

      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
      })
    }
  }

  const subtitle = getSubtitle(resetOption)

  return {
    resetOption,
    setResetOption,
    resetMatchAssistant,
    ResetMatchAssistantModal: (
      <NxModal
        actions={
          <>
            <NxButton
              label="Cancel"
              onClick={(): void => {
                setResetOption(undefined)
              }}
              variant="secondary"
            />

            <NxButton
              label="Confirm"
              onClick={(): void => {
                resetMatchAssistant()
              }}
              variant="primary"
            />
          </>
        }
        onClose={(): void => setResetOption(undefined)}
        open={Boolean(resetOption)}
        showTopRightClose
        size="small"
        subtitle={subtitle}
        title="Are you sure?"
      />
    ),
  }
}
