import { Hidden, makeStyles } from '@material-ui/core'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'
import { NxTextLink, NxTypography } from '@playvs-inc/nexus-components'
import { Path } from '@plvs/const'
import { useGetReferralSchoolsQuery } from '@plvs/graphql/generated'
import React from 'react'

import Coach from '@plvs/rally/assets/schoolBounty/Coach.png'
import { useNavigate } from 'react-router-dom'
import { DashboardInviteSchoolItem } from './inviteSchoolItem/DashboardInviteSchoolItem'

export const useStyles = makeStyles((theme) => ({
  container: (): CreateCSSProperties => ({
    backgroundColor: '#074646ff',
    borderRadius: theme.mixins.cornerRadius['border-radius-10'],
  }),
  textColor: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  coachSpot: {
    position: 'absolute',
    left: theme.spacing(-4),
    bottom: -24,
    height: 370,
    width: 370,
    [theme.breakpoints.down('md')]: {
      left: -55,
      height: 360,
      width: 360,
    },
  },
}))

export const DashboardSchoolBountyComponent: React.FC<{
  isFacultyAtOrg: boolean
}> = ({ isFacultyAtOrg }) => {
  const navigate = useNavigate()
  const isMobileXs = useBreakpointXs()
  const classes = useStyles()
  const { data } = useGetReferralSchoolsQuery({
    variables: {
      limit: 2,
    },
  })

  const schoolReferrals = data?.getReferralSchools?.referralSchools
  const hasSchoolReferrals = schoolReferrals?.some(
    (referral) => referral?.school?.name
  )
  const incentiveCallout = 'Refer a School Near You!'

  const navigateToReferPage = (): void => {
    navigate(Path.GlobalRefer)
  }

  if (!hasSchoolReferrals) {
    return <></>
  }

  return (
    <Box
      alignItems="center"
      className={classes.container}
      display="flex"
      flexDirection="row"
      mb={3}
      mt={3}
      p={3}
      width="100%"
    >
      <Hidden smDown>
        <Box height={252} position="relative" width={471}>
          <img alt="coach" className={classes.coachSpot} src={Coach} />
        </Box>
      </Hidden>
      <Box display="flex" flexDirection="column" width="100%">
        <Box mb={2}>
          <Hidden smUp>
            <NxTypography className={classes.textColor} variant="h3">
              {incentiveCallout}
            </NxTypography>
          </Hidden>
          <Hidden xsDown>
            <NxTypography className={classes.textColor} variant="display3">
              {incentiveCallout}
            </NxTypography>
          </Hidden>
        </Box>
        {schoolReferrals?.map((schoolReferral) => {
          return (
            <DashboardInviteSchoolItem
              key={schoolReferral?.school?.name ?? undefined}
              city={schoolReferral?.school?.city ?? ''}
              competitionGroup={schoolReferral?.school?.competitionGroup}
              id={schoolReferral?.schoolId ?? ''}
              isFacultyAtOrg={isFacultyAtOrg}
              isMobile={isMobileXs}
              name={schoolReferral?.school?.name ?? ''}
              state={schoolReferral?.school?.state ?? ''}
            />
          )
        })}
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <NxTextLink
            className={classes.textColor}
            label="View All Schools"
            labelVariant="button"
            onClick={navigateToReferPage}
          />
        </Box>
      </Box>
    </Box>
  )
}
