import React from 'react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { capitalize } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import { NxCheckbox, NxUserCluster, Pill } from '@playvs-inc/nexus-components'

import {
  EsportRating,
  GetSchoolRecommendedScrimmagesQuery,
} from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout/Box'

dayjs.extend(tz)

export type RecommendedScrimmage = NonNullable<
  NonNullable<
    NonNullable<
      GetSchoolRecommendedScrimmagesQuery['school']
    >['availableScrimmageRequests']
  >['result']
>[0]

type TopRecommendedScrimmagesProps = {
  availableScrimmages: RecommendedScrimmage[]
  selectedScrimmageId: string
  setSelectedScrimmageId(id: string): void
}

export const TopRecommendedScrimmages = ({
  availableScrimmages,
  selectedScrimmageId,
  setSelectedScrimmageId,
}: TopRecommendedScrimmagesProps): React.ReactElement => {
  // Supports reporting selected ID as form input OR
  // Direct access to selected scrimmage ID via state props

  return (
    <>
      {availableScrimmages.map((scrimmage) => {
        const formattedDatetime = dayjs(scrimmage.startsAt)
          .tz(dayjs.tz.guess())
          .format('h:mma z MMM D, YYYY')
        const scrimmageId = scrimmage.id

        const isYouthProgram =
          scrimmage?.esport?.rating === EsportRating.Restricted

        return (
          <Box key={scrimmageId} alignItems="center" display="flex">
            <NxCheckbox
              checked={selectedScrimmageId === scrimmageId}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              name="scrimmageId"
              onChange={(): void =>
                setSelectedScrimmageId(
                  selectedScrimmageId === scrimmageId ? '' : scrimmageId
                )
              }
              value={scrimmageId}
            />
            <div
              style={{
                paddingTop: 16,
                paddingLeft: 8,
                width: '300px',
              }}
            >
              <NxUserCluster
                avatarHashId={scrimmage.requestingTeam?.id ?? ''}
                avatarUrl={scrimmage.requestingTeam?.school?.logoUrl ?? ''}
                subtitles={
                  isYouthProgram
                    ? [
                        {
                          title: formattedDatetime,
                        },
                      ]
                    : [
                        {
                          title:
                            scrimmage.requestingTeam?.school?.name ?? 'Unknown',
                        },
                        {
                          title: formattedDatetime,
                        },
                      ]
                }
                title={scrimmage.requestingTeam?.name ?? 'Unknown'}
              />
            </div>

            {scrimmage.rating && (
              <div style={{ paddingLeft: 32 }}>
                <Pill
                  label={`${capitalize(scrimmage.rating)}`}
                  size="large"
                  variant="brand"
                />
              </div>
            )}
          </Box>
        )
      })}
    </>
  )
}
