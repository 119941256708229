import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { formErrorToString } from '@plvs/utils'
import { CreateCSSProperties } from '@material-ui/styles'
import {
  useRequestPasswordResetMutation,
  RegisterUserInput,
} from '@plvs/graphql'
import { Path } from '@plvs/const'
import {
  NxTypography,
  NxTextInput,
  NxButton,
  NxTextLink,
} from '@playvs-inc/nexus-components'

import { noop } from 'ramda-adjunct'
import { ForgotPasswordFormSchema } from '@plvs/rally/features/auth/utils/helpers'
import { useNavigate } from 'react-router-dom'
import { getEmailHelperText } from './forgotPasswordHelpers'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
  submitButton: ({
    hasEmailError,
  }: {
    hasEmailError: boolean
  }): CreateCSSProperties => ({
    marginTop: hasEmailError ? theme.spacing(3.5) : theme.spacing(0.5),
    height: theme.spacing(5.5),
  }),
}))

interface ForgotPasswordFormProps {
  wasEmailSent: boolean
  setWasEmailSent: (value: boolean) => void
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  wasEmailSent,
  setWasEmailSent,
}) => {
  const query = new URLSearchParams(window.location.search)
  const errorQueryParam = query.get('error')

  const [submittedEmail, setSubmittedEmail] = React.useState('')
  const { errors, handleSubmit, register } = useForm<RegisterUserInput>({
    resolver: yupResolver<RegisterUserInput>(ForgotPasswordFormSchema),
  })
  const navigate = useNavigate()

  const [mutate] = useRequestPasswordResetMutation()

  const onSubmit = handleSubmit(
    async (input: RegisterUserInput): Promise<void> => {
      setWasEmailSent(true)
      try {
        if (!input?.email) {
          throw new Error('Unable to submit email.')
        }
        setSubmittedEmail(input?.email)

        await mutate({
          variables: { email: input.email },
        })
      } catch (e: any) {
        noop(e)
      }
    }
  )

  const hasEmailError = Boolean(errors.email || errorQueryParam)

  const classes = useStyles({ hasEmailError })

  return (
    <form className={classes.form} method="post" noValidate>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={391}
        mt={3}
        textAlign="center"
        width="100%"
      >
        <Box textAlign="center">
          {wasEmailSent ? (
            <>
              <Box mb={1.5} textAlign="left">
                <NxTypography colorToken="ColorTextBase" variant="body1">
                  {`If ${submittedEmail} is associated with an account, we’ll email you instructions to reset your password.`}
                </NxTypography>
                <NxTypography colorToken="ColorTextBase" variant="body1">
                  <br /> Please check both your inbox and spam folder
                </NxTypography>
              </Box>
              <NxButton
                className={classes.submitButton}
                data-cy="back-to-login"
                fullWidth
                label="Back to Login"
                onClick={(): void => navigate(Path.Login)}
                variant="primary"
              />
            </>
          ) : (
            <Box>
              <Box display="flex" textAlign="left">
                <NxTextInput
                  ref={register}
                  data-testid="email"
                  fullWidth
                  helperText={getEmailHelperText(
                    formErrorToString(errors.email),
                    errorQueryParam
                  )}
                  // Setting style inline because className overrides base style
                  label="Email address"
                  name="email"
                  style={{ height: '54px' }}
                  type="email"
                  variant={hasEmailError ? 'error' : 'default'}
                />
              </Box>

              <NxButton
                className={classes.submitButton}
                fullWidth
                label="Send Recovery Email"
                onClick={onSubmit}
                type="submit"
                variant="primary"
              />

              <Box mt={2}>
                <NxTextLink
                  label="Back to Login"
                  onClick={(): void => navigate(Path.Login)}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </form>
  )
}
