/* istanbul ignore file */
import React, { useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { Enrollment } from '@plvs/rally/containers/enrollment/Enrollment'
import { competitionModelToDisplay, isAdminForSystem } from '@plvs/utils'
import {
  useUserIdentityFn,
  useSelectedOrganizationFn,
} from '@plvs/client-data/hooks'
import { useEnrollmentProvider } from '@plvs/respawn/containers/enrollment/EnrollmentProvider'
import { useSlotExclusions } from '@plvs/respawn/containers/enrollment/SlotExclusionsProvider'

export const EnrollmentRoutes: React.FC = () => {
  const params = useParams()

  const { id: metaseasonId } = params
  const { userRoles, loading: userIdentityLoading } = useUserIdentityFn()
  const {
    id: orgId,
    competitionGroup: orgCompetitionGroup,
    loading: userSelectedOrganizationLoading,
  } = useSelectedOrganizationFn()
  const isAdmin = isAdminForSystem(userRoles ?? [])

  const {
    enrolledPlayersOnTeam,
    onPlayersSelected,
    leagues,
    season,
    loading,
    competitionModels,
    leagueEsports,
  } = useEnrollmentProvider()

  const {
    setOptionalWeeksCache,
    optionalWeeksCache,
    slotExclusionRangeData,
    seasonSlotExclusionRangeData,
    addEnrollmentSeasonIds,
    removeEnrollmentSeasonId,
    optionalSeasonWeeksCache,
    setOptionalSeasonWeeksCache,
    numSchoolTeams,
  } = useSlotExclusions()

  useEffect(() => {
    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          position: { horizontal: 'left', vertical: 'bottom' },
        },
      })
    }
  }, [])

  if (loading || userIdentityLoading || userSelectedOrganizationLoading) {
    return null
  }

  return (
    <Routes>
      {(competitionModels ?? []).map((model) => (
        <Route
          key={model}
          element={
            <Enrollment
              addEnrollmentSeasonIds={addEnrollmentSeasonIds}
              competitionModel={model}
              enrolledPlayersOnTeam={enrolledPlayersOnTeam}
              isAdmin={isAdmin}
              leagueEsports={leagueEsports}
              leagues={leagues ?? []}
              metaseasonId={metaseasonId ?? ''}
              numSchoolTeams={numSchoolTeams}
              onPlayersSelected={onPlayersSelected}
              optionalSeasonWeeksCache={optionalSeasonWeeksCache}
              optionalWeeksCache={optionalWeeksCache}
              removeEnrollmentSeasonId={removeEnrollmentSeasonId}
              schoolId={orgId}
              seasonName={season?.name}
              seasonSlotExclusionRangeData={seasonSlotExclusionRangeData}
              setOptionalSeasonWeeksCache={setOptionalSeasonWeeksCache}
              setOptionalWeeksCache={setOptionalWeeksCache}
              slotExclusionRangeData={slotExclusionRangeData}
            />
          }
          path={`${competitionModelToDisplay({
            competitionModel: model,
            competitionGroup: orgCompetitionGroup,
          }).toLowerCase()}`}
        />
      ))}
    </Routes>
  )
}
