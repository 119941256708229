import React from 'react'

import { MatchStatus } from '@plvs/graphql/generated'
import { Box } from '@plvs/respawn/features/layout'

import { MatchHeroCountdownDetails } from './MatchHeroCountdownDetails'

export const MatchHeroCountdownContainer: React.FC<{
  countdown?: string
  liveLabel: string
  mobile: boolean
  size: 'small' | 'medium' | 'large'
  bestOf: number
  seriesBestOf: number | null
  status: MatchStatus | null | undefined
  matchId: string
  refetchMatchStatus(): Promise<void>
  breadcrumbHref?: string
}> = ({
  children,
  countdown,
  liveLabel,
  mobile,
  size,
  bestOf,
  seriesBestOf,
  refetchMatchStatus,
  status,
  matchId,
  breadcrumbHref,
}) => (
  <Box pb={mobile ? 3 : 5} pt={mobile ? 0 : 3}>
    {size !== 'small' && (
      <MatchHeroCountdownDetails
        bestOf={bestOf}
        breadcrumbHref={breadcrumbHref}
        countdown={countdown}
        liveLabel={liveLabel}
        matchId={matchId}
        mobile={mobile}
        refetchMatchStatus={refetchMatchStatus}
        seriesBestOf={seriesBestOf}
        status={status}
      />
    )}
    {children}
  </Box>
)
