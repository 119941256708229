import { head } from 'ramda'

import { MatchTeam } from '@plvs/graphql/generated'
import {
  DisputeMatch,
  getContextualTeams,
  LobbyTeam,
  MatchAssistantTeam,
} from '@plvs/utils'
import { MatchTeamForRenderController } from '@plvs/respawn/renderController/matchLobby/match/types/matchRenderController.types'
import { DisputeResultsButton } from '@plvs/rally/features/match/dynamic/buttons/DisputeResultsButton'
import React from 'react'
import { CurrentPlayersForMatchAssistant } from './MatchAssistant.types'
import { LobbySeries } from './useMatchAssistant.types'

interface MapSeriesToScoreboardRowArrayProps {
  homeTeamId?: string
  series: LobbySeries[]
}

export const mapSeriesToScoreboardRowArray = ({
  homeTeamId,
  series,
}: MapSeriesToScoreboardRowArrayProps): CurrentPlayersForMatchAssistant[] => {
  return (
    head(
      series?.map((currSeries) => {
        const { team1, team2 } = getContextualTeams(
          currSeries?.teamContext?.teams
        )
        const seriesHomeTeam:
          | LobbyTeam
          | null
          | Partial<MatchTeam>
          | undefined = team1?.id === homeTeamId ? team1 : team2

        const seriesAwayTeam:
          | LobbyTeam
          | null
          | Partial<MatchTeam>
          | undefined = team1?.id === homeTeamId ? team2 : team1

        const homePlayer = seriesHomeTeam?.roster?.[0]
        const awayPlayer = seriesAwayTeam?.roster?.[0]

        return [
          {
            title: homePlayer?.name ?? '',
            subtitles: [{ title: seriesHomeTeam?.name ?? '' }],
            avatarUrl: homePlayer?.avatarUrl ?? undefined,
            id: homePlayer?.id ?? '',
            userProviderName: homePlayer?.publisherAccountName ?? '',
          },
          {
            title: awayPlayer?.name ?? '',
            subtitles: [{ title: seriesAwayTeam?.name ?? '' }],
            avatarUrl: awayPlayer?.avatarUrl ?? undefined,
            id: awayPlayer?.id ?? '',
            userProviderName: awayPlayer?.publisherAccountName ?? '',
          },
        ]
      })
    ) ?? []
  )
}

export function normalizeMatchAssistantTeam(
  team: MatchAssistantTeam | null | undefined
): MatchTeamForRenderController {
  return {
    id: team?.id ?? '',
    schoolId: team?.schoolId ?? '',
    name: team?.name ?? '',
  }
}

export function getDisputeResultsButton({
  showDisputeButton,
  canManageMatch,
  match,
  fallback,
}: {
  showDisputeButton: boolean
  canManageMatch: boolean
  match: DisputeMatch
  fallback?: React.ReactElement
}): React.ReactElement {
  if (showDisputeButton) {
    return (
      <DisputeResultsButton canManageMatch={canManageMatch} match={match} />
    )
  }

  if (fallback) {
    return fallback
  }

  return <></>
}
