import React from 'react'
import { NxTypography, Pill } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import {
  getInvalidMatchResults,
  INVALID_RESULTS_MATCH_STATUSES,
} from '@plvs/rally/features/explore/LeagueMatches/leagueMatchesHelpers'
import {
  MappedMatch,
  MappedMatchResult,
} from '@plvs/rally/features/match/schedule/schedule'
import { makeStyles } from '@material-ui/core'
import { ScrimmageBadge } from '@plvs/rally/components/badge/ScrimmageBadge'
import { isMatchPreseason } from '@plvs/utils'
import { PreseasonBadge } from '@plvs/rally/components/badge'
import { includes } from 'ramda'
import { MatchStatus } from '@plvs/graphql/generated'
import { MatchRowResults } from './MatchRowResults'
import { getIsPastMatch } from './matchRow.helpers'

export const useStyles = makeStyles({
  matchResults: {
    textAlign: 'center',
  },
})

interface Props {
  isLeaderboard: boolean
  match: MappedMatch
  myMatchResults: MappedMatchResult | undefined
  oppMatchResults: MappedMatchResult | undefined
}

export const getUpcomingBadge = (match: MappedMatch): React.ReactElement => {
  let badge = <NxTypography variant="subtitle2">VS</NxTypography>

  if (match.isScrimmage) {
    badge = <ScrimmageBadge size="medium" />
  }

  if (isMatchPreseason(match)) {
    badge = <PreseasonBadge />
  }

  if (match?.status === MatchStatus.Bye) {
    badge = <Pill label="Bye" size="small" variant="misc" />
  }

  return badge
}

export const MatchRowMiddle: React.FC<Props> = ({
  isLeaderboard,
  match,
  myMatchResults,
  oppMatchResults,
}) => {
  const classes = useStyles()

  const isPastMatch = getIsPastMatch(match)

  const hasValidResults = !includes(
    match?.status,
    INVALID_RESULTS_MATCH_STATUSES
  )

  return (
    <Box
      alignItems="center"
      className={classes.matchResults}
      display="flex"
      justifyContent="center"
    >
      {isPastMatch ? (
        <>
          {hasValidResults ? (
            <MatchRowResults
              isLeaderboard={isLeaderboard}
              myPlacing={match?.teamContext?.myTeamMatchResults?.[0]?.placing}
              team1Result={myMatchResults}
              team2Result={oppMatchResults}
            />
          ) : (
            getInvalidMatchResults(match?.status)
          )}
        </>
      ) : (
        getUpcomingBadge(match)
      )}
    </Box>
  )
}
