import React from 'react'
import { ParentBanner } from '@plvs/respawn/features/parent/components/ParentBanner'
import { AccountRenderControllerProvider } from '@plvs/respawn/renderController/account/AccountRenderControllerProvider'
import { AppPage } from '../../page'
import { ChildPlatformConnections } from './ChildPlatformConnections'

export const ChildPlatformConnectionsPage: React.FC = () => {
  return (
    <AppPage title="Platform Connections">
      <ParentBanner />
      <AccountRenderControllerProvider>
        <ChildPlatformConnections />
      </AccountRenderControllerProvider>
    </AppPage>
  )
}
