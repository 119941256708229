import React from 'react'
import { Box } from '@plvs/respawn/features/layout'

import { NxTypography, NxTextLink } from '@playvs-inc/nexus-components'

export const PrivacyPolicy: React.FC = () => {
  return (
    <Box mb={6} mt={4}>
      <NxTypography colorToken="ColorTextAlt2" variant="body3">
        By signing up, you agree to our&nbsp;
        <NxTextLink
          href="https://www.playvs.com/terms"
          label="Terms of Use"
          labelVariant="body3"
          rel="noreferrer"
          target="_blank"
        />
        &nbsp;and&nbsp;
        <NxTextLink
          href="https://www.playvs.com/privacy"
          label="Privacy Policy"
          labelVariant="body3"
          rel="noreferrer"
          target="_blank"
        />
      </NxTypography>
    </Box>
  )
}
