import { CoachDeniedAdapter } from './CoachDeniedAdapter'
import { ConnectPublisherAccountAdapter } from './ConnectPublisherAccountAdapter'
import { ConnectVeslAccountAdapter } from './ConnectVeslAccountAdapter'
import { DefaultNotificationAdapter } from './DefaultNotificationAdapter'
import { MatchCompletedAdapter } from './MatchCompletedAdapter'
import { MatchForfeitedAdapter } from './MatchForfeitedAdapter'
import { MatchRescheduleCancelledAdapter } from './MatchRescheduleCancelledAdapter'
import { MatchRescheduledDeniedAdapter } from './MatchRescheduledDeniedAdapter'
import { MatchRescheduleRequestAdapter } from './MatchRescheduleRequestAdapter'
import { MatchRescheduleSuccessAdapter } from './MatchRescheduleSuccessAdapter'
import { MatchUnderReviewAdapter } from './MatchUnderReviewAdapter'
import { MissingMatchResultsAdapter } from './MissingMatchResultsAdapter'
import { PlayerDeniedAdapter } from './PlayerDeniedAdapter'
import { ReviewUnverifiedCoachesAdapter } from './ReviewUnverifiedCoachesAdapter'
import { ReviewUnverifiedPlayersAdapter } from './ReviewUnverifiedPlayersAdapter'
import { ReviewVerifiedCoachesAdapter } from './ReviewVerifiedCoachesAdapter'
import { MatchCanceledAdapter } from './MatchCanceledAdapter'
import { SchoolMessagePostedAdapter } from './SchoolMessagePostedAdapter'
import { SelectEpicGamesPlatformAdapter } from './SelectEpicGamesPlatformAdapter'
import { FillTeamRosterAdapter } from './FillTeamRosterAdapter'
import { UpdatePersonalPhoneNumberAdapter } from './UpdatePersonalPhoneNumberAdapter'
import { UpdateSchoolITPhoneNumberAdapter } from './UpdateSchoolITPhoneNumberAdapter'
import { SchoolDistrictClassificationAdapter } from './SchoolDistrictClassificationAdapter'
import { SchoolDismissalTimeAdapter } from './SchoolDismissalTimeAdapter'

const adapterRegistry = [
  // Notification Adapters
  MatchRescheduledDeniedAdapter,
  CoachDeniedAdapter,
  MatchRescheduleCancelledAdapter,
  MatchRescheduleSuccessAdapter,
  MatchForfeitedAdapter,
  MatchCompletedAdapter,
  MatchUnderReviewAdapter,
  PlayerDeniedAdapter,
  MatchCanceledAdapter,
  SchoolMessagePostedAdapter,

  // Task Adapters
  MissingMatchResultsAdapter,
  MatchRescheduleRequestAdapter,
  ReviewUnverifiedCoachesAdapter,
  ReviewUnverifiedPlayersAdapter,
  ReviewVerifiedCoachesAdapter,
  ConnectPublisherAccountAdapter,
  ConnectVeslAccountAdapter,
  DefaultNotificationAdapter,
  SelectEpicGamesPlatformAdapter,
  FillTeamRosterAdapter,
  UpdatePersonalPhoneNumberAdapter,
  UpdateSchoolITPhoneNumberAdapter,
  SchoolDismissalTimeAdapter,
  SchoolDistrictClassificationAdapter,
]

export const adapters = Object.fromEntries(
  adapterRegistry.map((adapter) => [
    adapter.notificationSource,
    adapter.createNotification,
  ])
)
