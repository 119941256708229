import React from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { IconButton, makeStyles } from '@material-ui/core'
import { Dialog } from '@plvs/respawn/features/dialog'
import { NxTypography } from '@playvs-inc/nexus-components'
import { CreateCSSProperties } from '@material-ui/styles'
import { Close } from '@playvs-inc/nexus-icons'

interface StyleProps {
  buttonOpacity?: boolean
  isMobile?: boolean
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      paddingTop: '1.5rem',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: (props?: StyleProps): CreateCSSProperties => ({
    fontSize: props?.isMobile ? '1.5rem' : '1.75rem',
    fontWeight: 600,
  }),
  continueButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: '400px',
  },
  iframe: {
    width: '100%',
    height: '762px',
    border: 'none',
  },
}))

interface ScheduleConsultationModal {
  handleClose: () => void
  open: boolean
  link?: string
}

export const ScheduleConsultationModal: React.FC<ScheduleConsultationModal> = ({
  handleClose,
  open,
  link,
}) => {
  const classes = useStyles()

  return (
    <Dialog className={classes.dialog} onClose={handleClose} open={open}>
      <Box
        className={classes.container}
        data-cy="scheduleConsultationDialog"
        px={2.5}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          minWidth={518}
          width="100%"
        >
          <NxTypography
            className={classes.title}
            data-cy="scheduleConsultationHeader"
            variant="h2"
          >
            Let&apos;s Get in Touch!
          </NxTypography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <iframe
          className={classes.iframe}
          src={link}
          title="Schedule a consultation"
        />
      </Box>
    </Dialog>
  )
}
