import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  useGetMissingFieldsAtAppLaunchQuery,
  useGetSchoolEligibleMetaseasonsQuery,
  UserRequiredFieldsAtAppLaunch,
} from '@plvs/graphql/generated/graphql'

type GetMissingFieldsReturn = [
  /**
   * List of missing fields if exists, null if none found.
   */
  missingFields: string[] | null,
  /**
   * Loading in progress
   */
  loading: boolean,
  /**
   * Trigger this hook to refetch results.
   */
  refetch: () => void
]

/**
 * Gets a list of missing profile data requried before we can use the app.
 *
 * @returns [
 *  missingFields: string[] | null, : List of missing fields
 *  loading: boolean, : loading state of this hook
 *  refetch: () => void : triggers a refetch so we can update missingFields.
 * ]
 */
export const useGetMissingFields = (): GetMissingFieldsReturn => {
  // Check if the user selected "I don't know" on Billing contact.
  // This is a locally stored preference in local storage.
  // We store the metaseason ID so we'll stop prompting
  // for the remainer of the promoted metaseason.
  const userId = useUserIdentityFn()?.userId
  let billingContactSkippedMetaseason
  try {
    billingContactSkippedMetaseason = localStorage?.getItem(
      `skipBillingContactVar-${userId}`
    )
  } catch (e) {
    // Swallow exception in event localStorage API is denied.
  }

  const { data, loading, refetch } = useGetMissingFieldsAtAppLaunchQuery({
    nextFetchPolicy: 'cache-first',
  })

  const orgId = data?.me?.school?.id

  const {
    data: metaseasonData,
    loading: metaseasonLoading,
  } = useGetSchoolEligibleMetaseasonsQuery({
    variables: {
      schoolId: orgId ?? '',
    },
    skip: !orgId,
  })
  const promotedMetaseason = metaseasonData?.eligibleMetaseasons?.find(
    (ms) => ms.isPromoted
  )

  let missingFields = data?.userAttributes?.missingFieldsAtAppLaunch ?? []

  if (loading || metaseasonLoading) {
    return [null, true, refetch]
  }

  if (
    !promotedMetaseason ||
    billingContactSkippedMetaseason === promotedMetaseason?.id
  ) {
    missingFields = missingFields.filter(
      (field) => field !== UserRequiredFieldsAtAppLaunch.BillingContact
    )
  }

  return [
    missingFields.length > 0 ? missingFields : null,
    loading || metaseasonLoading,
    refetch,
  ]
}
