import React, { useState } from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'

import {
  MyScrimmagesEmptySubtitle,
  ScrimmagesCTA,
} from '@plvs/rally/components/scrimmage'
import { ScrimmageTableLimits } from '@plvs/const'
import {
  Column,
  Table,
  TablePagination,
  usePagination,
} from '@plvs/rally/components/table'
import { ContainerColumn } from '@plvs/rally/containers/table'
import {
  useGetSchoolScrimmagesQuery,
  EsportSlug,
  ScrimmageRequestTimeFilter,
  ScrimmageRequestStatus,
} from '@plvs/graphql'
import { getNow } from '@plvs/utils'
import { getScrimmageAcceptColumn } from './AcceptScrimmageButton'
import {
  ScrimmagePageRefreshContext,
  sortScrimmagesByDescendingTime,
} from '../../components/scrimmage/scrimmageHelpers'
import { ScrimmagesEmpty } from './ScrimmagesEmpty'

export const UpcomingScrimmages: React.FC<{
  schoolId: string
  selectedEsportSlug?: EsportSlug
}> = ({ schoolId, selectedEsportSlug }) => {
  const { page, setPage, getNumPages, offset } = usePagination({
    itemsPerPage: ScrimmageTableLimits.MyScrimmages,
  })
  const { isRefreshed } = React.useContext(ScrimmagePageRefreshContext)

  const [now] = useState(getNow())
  const [twoHoursAgo] = useState(getNow({ minuteDifference: -120 }))

  const {
    data: scrimmageData,
    loading: loadingScrimmages,
  } = useGetSchoolScrimmagesQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: schoolId,
      filter: {
        esportSlugs: selectedEsportSlug ? [selectedEsportSlug] : null,
        statuses: [
          ScrimmageRequestStatus.NeedsOpponent,
          ScrimmageRequestStatus.Processed,
          ScrimmageRequestStatus.Requested,
          ScrimmageRequestStatus.Completed,
          ScrimmageRequestStatus.Scheduled,
        ],
        time: ScrimmageRequestTimeFilter.All,
        startsAt: {
          after: twoHoursAgo,
        },
      },
      offset,
      limit: ScrimmageTableLimits.MyScrimmages,
      // @ts-ignore cache bust
      isRefreshed,
    },
    skip: !schoolId,
  })

  const scrimmages = [
    ...(scrimmageData?.school?.myScrimmageRequests?.result ?? []),
  ]
    .sort(sortScrimmagesByDescendingTime)
    .filter(
      (scrimmage) =>
        scrimmage.status !== ScrimmageRequestStatus.NeedsOpponent ||
        scrimmage.startsAt > now
    )

  const numPages = getNumPages(scrimmages.length)

  return (
    <WaitTillLoaded
      loading={loadingScrimmages}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      {scrimmages.length ? (
        <>
          <Table
            columns={[
              ContainerColumn.ScrimmageEsportIcon,
              Column.ScrimmageTeams,
              Column.ScrimmageStatus,
              Column.ScrimmageFormattedDateAndTime,
              getScrimmageAcceptColumn({ schoolId }),
              Column.EndCap,
            ]}
            data={scrimmages}
          />
          <Box mt={2} />
          {numPages > 1 && (
            <TablePagination
              numPages={numPages}
              page={page}
              setPage={setPage}
            />
          )}
        </>
      ) : (
        <ScrimmagesEmpty
          subtitle={`${MyScrimmagesEmptySubtitle} ${ScrimmagesCTA}`}
        />
      )}
    </WaitTillLoaded>
  )
}
