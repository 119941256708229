import React, { useMemo } from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'

import { NxTypography, NxEmote } from '@playvs-inc/nexus-components'

import { PlayerReplayStatName } from '@plvs/graphql'
import { RewindAssets } from '../Rewind.helpers'
import { useStyles } from './Slide.styles'
import type { SlideProps } from './Slide.props'

export const VerticalSlide: React.FC<SlideProps> = ({ data }) => {
  const styles = useStyles({ valueLength: data?.value?.length ?? 0 })
  const theme = useTheme()

  const isPlayVsCup =
    data?.statName?.toLowerCase() ===
    PlayerReplayStatName.PlayVsCupChampion.toLowerCase()

  const emotes = ['fbcelebrate', 'fbgg', 'fbhappy', 'fbcool', 'fbtrophy']
  const randomEmote = useMemo(
    () => emotes[Math.floor(Math.random() * emotes.length)],
    [data?.statName]
  )

  return (
    <>
      {isPlayVsCup ? (
        <Box className={styles.playvsCupContainer} position="absolute">
          <img
            alt="PlayVS Cup"
            className={styles.playvsCup}
            src={RewindAssets.PlayVSCup}
          />
        </Box>
      ) : (
        <Box margin={1} position="absolute" right={0} top={0}>
          <NxEmote emoteKey={randomEmote} height={45} width={45} />
        </Box>
      )}

      <Box
        alignItems="center"
        display="flex"
        flex={1}
        flexDirection="column"
        gridGap={theme.spacing(0.5)}
        position="relative"
        textAlign="center"
      >
        <NxTypography className={styles.accentColor} variant="display3">
          {data?.title}
        </NxTypography>

        <NxTypography
          className={styles.verticalValue}
          colorToken="OverlayColorTextBase"
          variant="display3"
        >
          {data?.value}
        </NxTypography>

        <NxTypography colorToken="OverlayColorTextBase" variant="body2">
          {data?.subtext}
        </NxTypography>
      </Box>
    </>
  )
}
