import React from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'

import {
  ScrimmagesCTA,
  UnplayedScrimmagesEmptySubtitle,
} from '@plvs/rally/components/scrimmage'
import {
  Column,
  Table,
  TablePagination,
  usePagination,
} from '@plvs/rally/components/table'
import {
  useGetSchoolScrimmagesQuery,
  EsportSlug,
  ScrimmageRequestStatus,
  ScrimmageRequestTimeFilter,
} from '@plvs/graphql'
import { ScrimmageTableLimits } from '@plvs/const'
import { sortScrimmagesByAscendingTime } from '../../components/scrimmage/scrimmageHelpers'
import { ScrimmagesEmpty } from './ScrimmagesEmpty'

// Max number of days to display unplayed scrimmages
const MAX_DISPLAY_DAYS = 90

export const UnplayedScrimmages: React.FC<{
  schoolId: string
  selectedEsportSlug?: EsportSlug
}> = ({ schoolId, selectedEsportSlug }) => {
  const { page, setPage, getNumPages, offset } = usePagination({
    itemsPerPage: ScrimmageTableLimits.MyScrimmages,
  })

  const {
    data: pastScrimmageData,
    loading: loadingUnplayedScrimmages,
  } = useGetSchoolScrimmagesQuery({
    variables: {
      id: schoolId,
      filter: {
        esportSlugs: selectedEsportSlug ? [selectedEsportSlug] : null,
        statuses: [
          ScrimmageRequestStatus.Canceled,
          ScrimmageRequestStatus.Declined,
          ScrimmageRequestStatus.NoResponse,
        ],
        time: ScrimmageRequestTimeFilter.All,
        maxDisplayDays: MAX_DISPLAY_DAYS,
      },
      limit: ScrimmageTableLimits.MyScrimmages,
      offset,
    },
    skip: !schoolId,
  })

  const pastScrimmages = [
    ...(pastScrimmageData?.school?.myScrimmageRequests?.result ?? []),
  ].sort(sortScrimmagesByAscendingTime)
  const totalUnplayedScrimmages =
    pastScrimmageData?.school?.myScrimmageRequests?.total ?? 0
  const numPages = getNumPages(totalUnplayedScrimmages)

  return (
    <>
      <WaitTillLoaded
        loading={loadingUnplayedScrimmages}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        {pastScrimmages.length ? (
          <>
            <Table
              columns={[
                // ContainerColumn.ScrimmageEsportIcon,
                Column.ScrimmageTeams,
                Column.ScrimmageStatus,
                Column.ScrimmageFormattedDateAndTime,
                Column.EndCap,
              ]}
              data={pastScrimmages}
            />
            <Box mt={2} />
            {numPages > 1 && (
              <TablePagination
                numPages={numPages}
                page={page}
                setPage={setPage}
              />
            )}
          </>
        ) : (
          <ScrimmagesEmpty
            subtitle={`${UnplayedScrimmagesEmptySubtitle} ${ScrimmagesCTA}`}
          />
        )}
      </WaitTillLoaded>
    </>
  )
}
