import React, { useState } from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'

import { Unhide, Hide } from '@playvs-inc/nexus-icons'
import { NxTextInput } from '@playvs-inc/nexus-components'

import { formErrorToString } from '@plvs/utils'
import type { InputProps } from './InputProps.type'
import { useStyles } from './Input.styles'

export const PasswordInput: React.FC<InputProps> = React.forwardRef(
  ({ error, message }, ref) => {
    useStyles()
    const theme = useTheme()
    const [showPassword, setShowPassword] = useState(false)

    const ShowPasswordIcon = showPassword ? Unhide : Hide

    return (
      <NxTextInput
        ref={ref}
        data-testid="password"
        endAdornment={
          <Box mr={1.375}>
            <ShowPasswordIcon
              color={theme.palette.ColorIconBase}
              data-cy="showPasswordIcon"
              data-testid="showPasswordIcon"
              onClick={(): void => setShowPassword(!showPassword)}
            />
          </Box>
        }
        fullWidth
        helperText={message || formErrorToString(error)}
        label="Password"
        name="password"
        // Setting style inline because className overrides base style
        style={{ height: '54px' }}
        type={showPassword ? 'text' : 'password'}
        variant={error ? 'error' : 'default'}
      />
    )
  }
)
