import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography, NxEsportAvatar } from '@playvs-inc/nexus-components'

import { UpcomingSeasonLeague } from './enrollmentModalHelpers'

const useStyles = makeStyles((theme) => ({
  leagueName: {
    color: theme.palette.ColorTextBase,
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  league: UpcomingSeasonLeague
}

export const LeagueRow: React.FC<Props> = ({ league }) => {
  const classes = useStyles()

  return (
    <Box key={league?.id ?? ''} alignItems="center" display="flex" mb={1}>
      {league?.esport?.slug && <NxEsportAvatar esport={league?.esport?.slug} />}
      <NxTypography className={classes.leagueName}>
        {league?.displayName || league?.name || ''}
      </NxTypography>
    </Box>
  )
}
