import React, { useContext, useEffect } from 'react'
import { LocalStorageKey } from '@plvs/const'
import { useLocation, useNavigate } from 'react-router'
import { useProductTypeFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import { ProductType } from '@plvs/client-data/models'
import * as analytics from './master'
import { sentryContext } from './sentry/initSentry'
import * as segment from './segment/segment'

// 1. create a listener for page views (note: this doesn't cover the initial page view)
// 2. identify user
// 3. group user (by school)
// 4. initialize intercom
// 5. initialize intercom for faculty
// 6. identify user in sentry
// 7. set isUnderage if not already set

const Analytics: React.FC = () => {
  // TODO - GP-4086 - on or after 7/8/2024, remove the cookie fallback
  const location = useLocation()
  const navigate = useNavigate()
  const { client: Sentry } = useContext(sentryContext)

  const productType = useProductTypeFn()

  const {
    userId,
    loading,
    orgId,
    orgCity,
    orgName,
    orgProvince,
    orgDeletedAt,
    firstName,
    lastName,
    promotionalEmail,
    emails,
    userRoles,
    isUnderage,
    hmac,
    userRoleNames,
    isFacultyAtOrg,
    orgSchoolType,
    state,
    gradYears,
    teams,
    managedTeams,
  } = useUserIdentityFn()

  // Redirect users in stadium if they are not authenticated for joining team
  if (
    !loading &&
    !userId &&
    location.search.includes('utm_source=event_team_creation') &&
    location.pathname.includes('/app/event')
  ) {
    navigate(`/signup${location.search}&next=${location.pathname}`)
  }

  // https://reactrouter.com/en/main/hooks/use-location
  useEffect(() => {
    // 1. create a listener for page views
    segment.page()
  }, [location])

  useEffect(() => {
    if (loading) {
      return
    }

    // 2. identify user
    analytics.identify({
      id: userId,
      firstName,
      lastName,
      hmac,
      schoolId: orgId,
      promotionalEmail,
      emails,
      roles: userRoles,
      isUnderage,
      app: productType,
      schoolType: productType === ProductType.Scholastic ? orgSchoolType : null,
      state: productType === ProductType.Scholastic ? orgProvince : state,
      gradYears,
      teams: [...teams, ...managedTeams],
    })

    // 4. initialize intercom
    if (orgId) {
      analytics.group({
        id: orgId,
        city: orgCity || null,
        name: orgName || null,
        state: orgProvince || null,
        deletedAt: orgDeletedAt || null,
      })
    }

    // 5. initialize intercom for faculty
    if (isFacultyAtOrg) {
      analytics.bootIntercom({ id: userId, emails, hmac })
    }

    if (userId) {
      // 6. identify user in sentry
      Sentry.setUser({ id: userId, userRoles: userRoleNames })
    }

    // 7. set isUnderage if not already set
    localStorage.setItem(LocalStorageKey.IsUnderage, JSON.stringify(isUnderage))
  }, [userId, productType, loading])

  return null
}

export default Analytics
