/* istanbul ignore file */
// Helper functions are tested instead of actual component
import React from 'react'
import { ListItem, makeStyles } from '@material-ui/core'
import dayjs from 'dayjs'
import {
  ChatRole,
  MatchStatus,
  useGetLowLevelMatchesForDrawerListQuery,
  useGetTeamsForScheduleMatchesWithSlotsQuery,
  useGetTeamSlotsWithQueueMatchesQuery,
} from '@plvs/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useTeamsContext } from '@plvs/rally/containers/team/TeamsProvider'
import {
  buildAllPossibleQueueMatches,
  filterQueueMatches,
} from '@plvs/rally/features/match/schedule/matchesHelper'
import { SchedulePageQueueMatch } from '@plvs/rally/features/match/schedule/schedule'
import { getContextualTeams } from '@plvs/utils'
import { DrawerMatchCard } from './DrawerMatchCard'
import { sortMatches } from './drawerMatchListHelpers'

const MATCHES_RECORD_LIMIT = 100
const START_OF_TODAY = dayjs().startOf('day').toISOString()
const END_OF_TODAY = dayjs().endOf('day').toISOString()

const useStyles = makeStyles((theme) => ({
  matchListItem: {
    paddingBottom: theme.spacing(1),
  },
}))

interface DrawerMatchListProps {
  setHasAnnouncements(input: boolean): void
  setLoadingMatchAnnouncements(input: boolean): void
  isCoach?: boolean
}

export const DrawerMatchList: React.FC<DrawerMatchListProps> = ({
  setHasAnnouncements,
  setLoadingMatchAnnouncements,
  isCoach,
}) => {
  const { loadingTeams, schoolTeamIds } = useTeamsContext()

  const {
    data,
    loading: loadingMatches,
  } = useGetLowLevelMatchesForDrawerListQuery({
    variables: {
      limit: MATCHES_RECORD_LIMIT,
      filters: {
        teamIds: schoolTeamIds,
        startsAt: {
          after: START_OF_TODAY,
          before: END_OF_TODAY,
        },
        status: [MatchStatus.Open, MatchStatus.Scheduled, MatchStatus.Live],
      },
    },
    skip: loadingTeams || !schoolTeamIds.length,
  })

  const {
    data: teamsData,
    loading: loadingTeamSchedules,
  } = useGetTeamsForScheduleMatchesWithSlotsQuery({
    variables: {
      teamIds: schoolTeamIds,
      minStartsAt: START_OF_TODAY,
      maxStartsAt: END_OF_TODAY,
    },
    skip: loadingTeams || schoolTeamIds.length === 0,
  })

  const allPossibleQueueMatchesAndSlots = teamsData
    ? buildAllPossibleQueueMatches(teamsData)
    : undefined
  const slotIds = allPossibleQueueMatchesAndSlots?.slotIds ?? []

  const {
    data: slotTeams,
    loading: queueMatchesLoading,
  } = useGetTeamSlotsWithQueueMatchesQuery({
    variables: {
      teamIds: schoolTeamIds,
      slotIds: Array.from(slotIds),
    },
    skip: loadingTeams || schoolTeamIds.length === 0,
  })

  const queueMatches: SchedulePageQueueMatch[] =
    allPossibleQueueMatchesAndSlots && slotTeams
      ? filterQueueMatches(allPossibleQueueMatchesAndSlots, slotTeams)
      : []

  const rawMatches = data?.getMatches?.matches ?? []

  const myMatchesListingMyTeamAsTeamName = rawMatches.map((match) => {
    const { team1, team2 } = getContextualTeams(match?.teamContext?.teams)
    return {
      ...match,
      scheduledStartsAt: match?.scheduledStartsAt ?? '',
      teamName: team1?.isMyTeam ? team1?.name ?? '' : team2?.name ?? '',
    }
  })

  const sortedMatches = sortMatches([
    ...myMatchesListingMyTeamAsTeamName,
    ...queueMatches,
  ])

  const styles = useStyles()
  const isLoading =
    loadingMatches ||
    loadingTeamSchedules ||
    queueMatchesLoading ||
    loadingTeams
  const hasMatches = !isLoading && sortedMatches.length

  const chatRole = isCoach ? ChatRole.Coach : ChatRole.Player

  React.useEffect(() => {
    setHasAnnouncements(!!hasMatches)
  }, [hasMatches])

  React.useEffect(() => {
    setLoadingMatchAnnouncements(isLoading)
  }, [isLoading])

  return (
    <WaitTillLoaded loading={isLoading}>
      {hasMatches
        ? sortedMatches.map((match, index) => (
            <ListItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${match.id}-${match.slotId}-${match.teamId}-${index}`}
              className={styles.matchListItem}
            >
              <DrawerMatchCard chatRole={chatRole} data={match} />
            </ListItem>
          ))
        : null}
    </WaitTillLoaded>
  )
}
