import {
  CompetitionGroup,
  ResourceType,
  EsportRating,
  useGetExploreLeaguesQuery,
} from '@plvs/graphql'
import {
  caseInsensitiveSort,
  isCoachAtOrganization,
  isPlayerForOrganization,
  useAutoskipQuery,
} from '@plvs/utils'
import { HeroGutter } from '@plvs/rally/components/hero'
import {
  Box,
  PageContentGutter,
  useBreakpointSm,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import {
  useUserIdentityFn,
  useSelectedOrganizationFn,
} from '@plvs/client-data/hooks'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'

import { uniqBy } from 'ramda'
import React from 'react'
import { EmptyPageWithSpot } from '@plvs/respawn/components/empty/EmptyPageWithSpot'
import StadiumSecondaryLargeDark from '@plvs/rally/assets/icons/StadiumSecondaryLargeDark.svg'
import StadiumSecondaryLarge from '@plvs/rally/assets/icons/StadiumSecondaryLarge.svg'
import {
  NxTypography,
  NxPlayVsBanner,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'

import { MySchoolLeagues } from '@plvs/rally/containers/filter/league/MySchoolLeagues'
import { getCompetitionGroupDetails } from './ExploreHelpers'
import { emptyAnnouncement } from './exploreHeroAnnouncements'
import { ExploreLeaguesSection } from './ExploreLeagueSection'
import { MyEnrolledLeagues } from './MyEnrolledLeagues/MyEnrolledLeagues'
import { useExploreLeagueStyles } from './exploreLeagues.styles'

export const ExploreLeagues: React.FC = () => {
  const isMobile = useBreakpointSm()

  const classes = useExploreLeagueStyles()

  const { userRoles, loading: identityLoading } = useUserIdentityFn()

  const {
    id: orgId,
    competitionGroup: orgCompetitionGroup,
    loading: selectedOrgLoading,
  } = useSelectedOrganizationFn()

  const isCoachForOrg = isCoachAtOrganization(userRoles, [orgId])
  const isPlayerForOrg = isPlayerForOrganization(userRoles, [
    userRoles?.find((r) => r.resourceType === ResourceType.Organization)
      ?.resourceId ?? '',
  ])
  const isVerifiedByOrg = isCoachForOrg || isPlayerForOrg

  const { data, loading } = useAutoskipQuery(useGetExploreLeaguesQuery, {
    variables: {
      schoolId: orgId,
    },
    skip: !isVerifiedByOrg || selectedOrgLoading,
  })

  // NOTE: competitionGroup can be null or undefined since due to selectedEntityId
  // in that case heroDetails should default to youth
  const heroDetails = getCompetitionGroupDetails({
    competitionGroup: orgCompetitionGroup ?? CompetitionGroup.Youth,
    overwrite: true,
    heroAnnouncement: emptyAnnouncement,
  })

  const schoolLeagues = uniqBy(
    (l) => l.esport.slug,
    data?.getLeaguesBySchoolId ?? []
  ).sort((a, b) =>
    caseInsensitiveSort(a?.esport?.name ?? '', b?.esport?.name ?? '')
  )

  const highSchoolLeagues =
    schoolLeagues.filter(
      (league) => league?.competitionGroup === CompetitionGroup.HighSchool
    ) ?? []

  const middleSchoolLeagues =
    schoolLeagues.filter(
      (league) =>
        league?.esport?.rating === EsportRating.General &&
        league?.competitionGroup === CompetitionGroup.MiddleSchool
    ) ?? []

  const emptyState = (
    <Box mb={5}>
      <MatchCard>
        <EmptyPageWithSpot
          spot={StadiumSecondaryLarge}
          spotCaption="No esports available"
          spotDark={StadiumSecondaryLargeDark}
          subtitle="There are no available competitions at this time."
          title="No Esports Available"
        />
      </MatchCard>
    </Box>
  )

  const showMiddleSchoolLeagues =
    middleSchoolLeagues.length > 0 && isVerifiedByOrg

  return (
    <>
      <HeroGutter mb={4}>
        {isMobile ? (
          <>
            <NxTypography className={classes.title} variant="display2">
              {heroDetails.heroTitle}
            </NxTypography>
            <NxTypography colorToken="ColorTextAlt2" variant="body2">
              {heroDetails.heroSubtitle}
            </NxTypography>
          </>
        ) : (
          <>
            <NxPlayVsBanner
              size="small"
              subtitle={heroDetails.heroSubtitle}
              title={heroDetails.heroTitle}
              variant={PersonaImageVariant.Explore}
            />
          </>
        )}
      </HeroGutter>
      <WaitTillLoaded
        loading={loading || identityLoading || selectedOrgLoading}
        showSpinnerWhileLoading
      >
        <PageContentGutter>
          {isCoachForOrg && (
            <MySchoolLeagues>
              <MyEnrolledLeagues />
            </MySchoolLeagues>
          )}

          {orgCompetitionGroup === CompetitionGroup.MiddleSchool && (
            <>
              {showMiddleSchoolLeagues ? (
                <ExploreLeaguesSection
                  competitionGroup={CompetitionGroup.MiddleSchool}
                  leagues={middleSchoolLeagues}
                  subtitle="Coach required"
                  title="Available Leagues"
                />
              ) : (
                <>{emptyState}</>
              )}
            </>
          )}

          {orgCompetitionGroup === CompetitionGroup.HighSchool && (
            <>
              {!highSchoolLeagues.length && emptyState}

              {/**  Only show school leagues if verified. */}
              {isVerifiedByOrg && (
                <>
                  {highSchoolLeagues.length > 0 && (
                    <ExploreLeaguesSection
                      competitionGroup={CompetitionGroup.HighSchool}
                      leagues={highSchoolLeagues}
                      subtitle={
                        getCompetitionGroupDetails({
                          competitionGroup: CompetitionGroup.HighSchool,
                        }).subText
                      }
                      title="Available Leagues"
                    />
                  )}
                </>
              )}
            </>
          )}
        </PageContentGutter>
      </WaitTillLoaded>
    </>
  )
}
