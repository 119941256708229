import { env } from '@plvs/env'

type SegmentSource = 'checkpoint' | 'rally' | 'stadium'

const defaultCdnUrl = env.DEFAULT_SEGMENT_CDN_URL
const cdnMap: Record<SegmentSource, string | undefined> = {
  checkpoint: defaultCdnUrl,
  rally: env.RALLY_PROD_SEGMENT_CDN_URL,
  stadium: env.STADIUM_PROD_SEGMENT_CDN_URL,
}

export default (
  key: string,
  source: SegmentSource,
  isProduction: boolean
): void => {
  const cdnUrl = (isProduction ? cdnMap[source] : defaultCdnUrl) ?? ''
  /* eslint-disable */
  // @ts-ignore
  !(function () {
    // @ts-ignore
    const analytics = (window.analytics = window.analytics || [])
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.')
      else {
        analytics.invoked = !0
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
        ]
        // @ts-ignore
        analytics.factory = function (t) {
          return function () {
            const e = Array.prototype.slice.call(arguments)
            e.unshift(t)
            analytics.push(e)
            return analytics
          }
        }
        analytics.methods.forEach((method) => {
          analytics[method] = analytics.factory(method)
        })
        // @ts-ignore
        analytics.load = function (writeKey,  options) {
          const n = document.createElement('script')
          n.type = 'text/javascript'
          n.async = !0
          n.src = source === 'checkpoint' || !isProduction ? `${cdnUrl}/${writeKey}/analytics.min.js` : cdnUrl
          const a = document.getElementsByTagName('script')[0]
          // @ts-ignore
          a.parentNode.insertBefore(n, a)
          analytics._loadOptions = options
          analytics._writeKey = writeKey
        }
        analytics._cdn = (!isProduction || source === 'checkpoint') ? undefined : "https://evs.schsegment.playvs.com"
        analytics.SNIPPET_VERSION = '5.2.0'
        analytics.load(key)
      }
  })()
  /* eslint-enable */
}
