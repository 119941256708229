import React, { useState } from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { useTheme } from '@material-ui/core'

import {
  NxButton,
  NxTextLink,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { logger } from '@plvs/rally/logging'
import { useSubmitGameAssistantStepSelectionsMutation } from '@plvs/graphql/generated'
import { EsportSlug } from '@plvs/graphql'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { IntercomArticleMappings } from '@plvs/const'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { useStyles } from '../../../MatchAssistant.styles'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useMatchAssistantContext } from '../../../useMatchAssistant'

const esportCopyMap = {
  [EsportSlug.Splatoon]: (
    <>
      Want to try our new <b>Stage Select</b> &amp; <b>Loadout Selection</b>{' '}
      steps?
    </>
  ),
  [EsportSlug.Madden]: (
    <>
      Want to try our new <b>Team</b> &amp; <b>Playbook Selections</b>?
    </>
  ),
}

export const Beta: React.FC = () => {
  const theme = useTheme()
  const styles = useStyles()
  const { currentStep, esportSlug } = useMatchAssistantContext()
  const { name: esportName } = useGeneralEsportAdapter(esportSlug)
  const [tryingBeta, setTryingBeta] = useState(false)
  const [skippingBeta, setSkippingBeta] = useState(false)

  const [
    submitGameAssistantStepSelections,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const isWaitingForOpponent = currentStep?.step?.submitted

  const onChangeToBeta = async ({
    changeToBeta,
  }: {
    changeToBeta: boolean
  }): Promise<void> => {
    try {
      await submitGameAssistantStepSelections({
        variables: {
          input: {
            stepId: currentStep?.step?.id ?? '',
            selectionOptions: [{ yesNoResponse: changeToBeta }],
          },
        },
      })
    } catch (err) {
      logger.error('[Beta | handleNext]', err)
    }
  }

  const handleLearnMore = (): void => {
    showIntercomArticle(IntercomArticleMappings.matchAssistantBeta)
  }

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(4)}
        justifyContent="center"
        mb={3}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(2)}
          textAlign="center"
        >
          <NxSpot
            domain="construction"
            height={100}
            size="medium"
            variant="secondary"
            width={100}
          />
          <NxTypography variant="h4">
            {esportName} Match Assistant Beta
          </NxTypography>
          <NxTypography variant="subtitle1">
            {esportSlug && esportCopyMap[esportSlug]} If both teams select “Try
            The Beta” we’ll enable these for your match. All rules will stay the
            same, and you can still skip if you encounter issues. Make sure to
            leave us feedback on your experience!
          </NxTypography>
        </Box>
      </Box>

      <MatchAssistantFooter>
        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}

        <Box
          className={styles.ctaContainer}
          display="flex"
          gridGap={theme.spacing(1)}
          width="100%"
        >
          <NxButton
            disabled={updateLoading || isWaitingForOpponent}
            fullWidth
            label="Try the Beta"
            loading={tryingBeta}
            onClick={(): void => {
              onChangeToBeta({ changeToBeta: true })
              setTryingBeta(true)
            }}
            variant="secondary"
          />
          <NxButton
            disabled={updateLoading || isWaitingForOpponent}
            fullWidth
            label="Skip"
            loading={skippingBeta}
            onClick={(): void => {
              onChangeToBeta({ changeToBeta: false })
              setSkippingBeta(true)
            }}
            variant="primary"
          />
        </Box>
      </MatchAssistantFooter>

      <Box mt={3} textAlign="center">
        <NxTextLink
          label="Learn about Beta features"
          onClick={handleLearnMore}
        />
      </Box>
    </>
  )
}
