import {
  Container,
  CardContent,
  CardMedia,
  Paper,
  makeStyles,
} from '@material-ui/core'
import React from 'react'
import { r } from '@plvs/utils'
import { NxTypography } from '@playvs-inc/nexus-components'
import { NxTypographyVariant } from '@playvs-inc/nexus-theme'
import { Box } from '@plvs/respawn/features/layout/Box'

interface Props {
  children: React.ReactNode
  containerless?: boolean
  image?: string
  subtitle?: string
  title: string
  headerVariant?: NxTypographyVariant
  subTitleAlign?: React.CSSProperties['textAlign']
  headerAlign?: React.CSSProperties['textAlign']
  headerComponent?: React.ReactElement
}

const useStyles = makeStyles((theme) => ({
  mediaCard: { backgroundSize: 'contain', height: '100%' },
  subtitle: {
    color: theme.palette.ColorTextAlt2,
  },
}))

export const OnboardCard = ({
  children,
  containerless,
  image,
  subtitle,
  subTitleAlign = 'center',
  title,
  headerVariant = 'h3',
  headerAlign = 'center',
  headerComponent,
}: Props): React.ReactElement => {
  const classes = useStyles()
  const content = (
    <>
      {image && (
        <Box height={r([100, 140])} mb={2}>
          <CardMedia className={classes.mediaCard} image={image} />
        </Box>
      )}
      {!image && headerComponent && (
        <Box alignItems="center" display="flex" justifyContent="center" mb={2}>
          {headerComponent}
        </Box>
      )}

      <Box my={2}>
        <NxTypography
          style={{ textAlign: headerAlign }}
          variant={headerVariant}
        >
          {title}
        </NxTypography>
      </Box>
      {subtitle && (
        <NxTypography
          className={classes.subtitle}
          style={{ textAlign: subTitleAlign }}
          variant="body1"
        >
          {subtitle}
        </NxTypography>
      )}
      {children}
    </>
  )

  return containerless ? (
    content
  ) : (
    <Container maxWidth="md">
      <Box margin="auto" maxWidth={420} my={2}>
        <Paper>
          <Box px={r([1, 2])} py={r([2, 4])} textAlign="center">
            <CardContent>{content}</CardContent>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}
