import React, { useState } from 'react'
import { NxButton, NxModal, NxTypography } from '@playvs-inc/nexus-components'

import { Box } from '@plvs/respawn/features/layout'
import {
  EnrollmentRangeData,
  OptionalWeeksCache,
  SeasonEnrollmentRangeData,
} from '@plvs/respawn/containers/enrollment/types'
import { OptionalWeekContent } from '@plvs/rally/features/enrollment/optionalWeeks/OptionalWeeksContent'

interface Props {
  handleClose(): void
  handleUpdate(): Promise<boolean>
  open: boolean
  slotExclusionRangeData: EnrollmentRangeData
  optionalWeeksCache: OptionalWeeksCache
  seasonSlotExclusionRangeData: SeasonEnrollmentRangeData
  optionalSeasonWeeksCache: OptionalWeeksCache
  setOptionalWeeksCache(input: OptionalWeeksCache): void
  setOptionalSeasonWeeksCache(input: OptionalWeeksCache): void
}

export const ScheduleBreakWeeksModal: React.FC<Props> = ({
  handleClose,
  handleUpdate,
  open,
  slotExclusionRangeData,
  optionalWeeksCache,
  seasonSlotExclusionRangeData,
  optionalSeasonWeeksCache,
  setOptionalWeeksCache,
  setOptionalSeasonWeeksCache,
}) => {
  const [updating, setUpdating] = useState<boolean>(false)
  const onUpdate = async (): Promise<void> => {
    setUpdating(true)
    const success = await handleUpdate()
    if (success) {
      handleClose()
    }
    setUpdating(false)
  }

  return (
    <NxModal
      actions={
        <>
          <NxButton label="Cancel" onClick={handleClose} variant="text" />
          <NxButton
            disabled={updating}
            label="Update"
            onClick={onUpdate}
            variant="primary"
          />
        </>
      }
      onClose={handleClose}
      open={open}
      showTopRightClose
      size="small"
      subtitle="Easily modify your school break week or league custom break weeks before they have passed."
      title="Update Break Week"
    >
      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <NxTypography variant="body1">Your School’s Break Week</NxTypography>
        {slotExclusionRangeData.map((data) => {
          const { configuration, range, resourceType, resourceId } = data
          return (
            <OptionalWeekContent
              key={range}
              configuration={configuration}
              menuOpened
              menuWidth="100%"
              optionalWeeksCache={optionalWeeksCache}
              range={range}
              resourceId={resourceId}
              resourceType={resourceType}
              setOptionalWeeksCache={setOptionalWeeksCache}
            />
          )
        })}
        {seasonSlotExclusionRangeData?.length ? (
          <Box>
            <NxTypography variant="body1">
              League with Custom Break Weeks
            </NxTypography>
            {seasonSlotExclusionRangeData.map((data, idx) => {
              const {
                configuration,
                seasonId,
                resourceId,
                resourceType,
                leagueName,
              } = data
              const key = `${seasonId}-${idx}`
              return (
                <OptionalWeekContent
                  key={key}
                  configuration={configuration}
                  menuOpened
                  menuWidth="100%"
                  optionalWeeksCache={optionalSeasonWeeksCache}
                  range={seasonId}
                  resourceId={resourceId}
                  resourceType={resourceType}
                  seasonId={seasonId}
                  selectLabel={leagueName}
                  setOptionalWeeksCache={setOptionalSeasonWeeksCache}
                />
              )
            })}
          </Box>
        ) : null}
      </Box>
    </NxModal>
  )
}
