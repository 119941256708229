import React, { useEffect, useState } from 'react'
import { isNil } from 'ramda'
import { TextField, useTheme } from '@material-ui/core'

import {
  NxButton,
  NxFirebobMessage,
  NxSelectableSingle,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'

import {
  MatchSettleRequestFragment,
  MatchSettleRequestStatus,
  useAdminSaveGameResultLiveMutation,
  useFetchMatchSettleRequestQuery,
  useMatchSettleRequestSubmittedSubscription,
  useMatchSettleRequestUpdatedSubscription,
  useSaveGameResultLiveMutation,
  useUploadMatchSettleRequestAssetMutation,
} from '@plvs/graphql'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useChangeRoster } from '@plvs/rally/features/match/assistant/hooks/useChangeRoster'
import { logger } from '@plvs/rally/logging'
import { Box } from '@plvs/respawn/features/layout/Box'
import { useMatchAssistantContext } from '../../useMatchAssistant'
import { useStyles } from './Reporting.styles'
import {
  FirebobMessage,
  getInvalidScores,
  submitGameResults,
  TeamSide,
  updateStateOnMatchSettleRequestChange,
} from './Reporting.helpers'

import { MatchAssistantFooter } from '../../MatchAssistantFooter'
import { AttachScreenshotsForGame } from './AttachScreenshotsForGame'

export const TeamReporting: React.FC = () => {
  const theme = useTheme()
  const { userId } = useUserIdentityFn()

  const {
    currentStep,
    refetchCurrentGameAssistantStepByMatchId,
    homeTeam,
    awayTeam,
    homeTeamId,
    awayTeamId,
    match,
    teamId: myTeamId = '',
    setStepIndex,
    setSubmittedUserIdForNPS,
    stepIndex,
    gameMode,
    selectedStage,
    isInCheckpoint,
    esportSlug,
  } = useMatchAssistantContext()
  const { hasMatchAssistantScore, scoreDisplayName } = useGeneralEsportAdapter(
    esportSlug
  )

  const classes = useStyles({ hasCharacterAsset: false })
  /// ///////
  // State //
  /// ///////
  const [selectedTeamSide, setSelectedTeamSide] = useState<TeamSide | -1>(-1)
  const [gameOrdinalState] = useState(currentStep?.step?.gameOrdinal)
  const [loadingMatchSettleRequest, setIsLoadingMatchSettleRequest] = useState<
    boolean
  >(true)
  const [firebobMessage, setFirebobMessage] = useState<FirebobMessage>(
    FirebobMessage.Hmm
  )
  const [
    matchSettleRequest,
    setMatchSettleRequest,
  ] = useState<MatchSettleRequestFragment | null>(null)
  const [homeScore, setHomeScore] = useState<string>()
  const [awayScore, setAwayScore] = useState<string>()
  const [screenshotFile, setScreenshotFile] = useState<File>()

  /// /////////
  // QUERIES //
  /// /////////
  const { data: incomingMatchSettleRequest } = useFetchMatchSettleRequestQuery({
    variables: {
      input: {
        matchId: match?.id,
        status: MatchSettleRequestStatus.Incomplete,
      },
    },
    skip: !(match?.id || userId),
    fetchPolicy: 'network-only',
  })

  /// ///////////
  // MUTATIONS //
  /// ///////////
  const [
    saveGameResultLive,
    { loading: loadingUserMutation },
  ] = useSaveGameResultLiveMutation()
  const [
    adminSaveGameResultLive,
    { loading: loadingAdminMutation },
  ] = useAdminSaveGameResultLiveMutation()
  const loading = loadingUserMutation || loadingAdminMutation

  const saveGameResult = isInCheckpoint
    ? adminSaveGameResultLive
    : saveGameResultLive

  const nextGameRequested = Boolean(currentStep?.nextGameRosterChangeRequested)
  const { ChangeRoster, isChangeRosterChecked } = useChangeRoster({
    disabled:
      firebobMessage === FirebobMessage.WaitingOnOpp ||
      loading ||
      nextGameRequested,
    initChecked: nextGameRequested,
  })
  const [uploadFile] = useUploadMatchSettleRequestAssetMutation()

  /// ///////////////
  // SUBSCRIPTIONS //
  /// ///////////////
  const matchSettleRequestUpdated = useMatchSettleRequestUpdatedSubscription({
    variables: {
      input: {
        matchId: match?.id ?? '',
      },
    },
    skip: !match?.id,
  })

  useMatchSettleRequestSubmittedSubscription({
    variables: {
      input: {
        matchSettleRequestId: matchSettleRequest?.id ?? '',
      },
    },
    skip: !matchSettleRequest?.id,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const returnedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      incomingMatchSettleRequest?.fetchMatchSettleRequest
        ?.matchSettleRequest?.[0]

    if (returnedMatchSettleRequest && currentStep) {
      updateStateOnMatchSettleRequestChange({
        matchSettleRequest: returnedMatchSettleRequest,
        seriesId: currentStep?.step?.seriesId || undefined,
        gameOrdinal: currentStep?.step?.gameOrdinal ?? 0,
        myTeamId,
        homeTeamId,
        matchHasBeenAutoSettled: false,
        gameAssistantStepHasBeenUpdated: false,
        setIsLoadingMatchSettleRequest,
        setSelectedTeamSide,
        setFirebobMessage,
        refetchCurrentGameAssistantStepByMatchId,
      })
      setMatchSettleRequest(returnedMatchSettleRequest)
    } else {
      setIsLoadingMatchSettleRequest(false)
    }
  }, [incomingMatchSettleRequest, stepIndex])

  useEffect(() => {
    const updatedMatchSettleRequest:
      | MatchSettleRequestFragment
      | null
      | undefined =
      matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
        ?.matchSettleRequest

    if (updatedMatchSettleRequest && currentStep) {
      updateStateOnMatchSettleRequestChange({
        matchSettleRequest: updatedMatchSettleRequest,
        seriesId: currentStep?.step?.seriesId || undefined,
        gameOrdinal: currentStep?.step?.gameOrdinal ?? 0,
        myTeamId,
        homeTeamId,
        matchHasBeenAutoSettled:
          matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
            ?.matchHasBeenAutoSettled ?? false,
        gameAssistantStepHasBeenUpdated:
          matchSettleRequestUpdated?.data?.matchSettleRequestUpdated
            ?.gameAssistantStepHasBeenUpdated ?? false,
        setIsLoadingMatchSettleRequest,
        setSelectedTeamSide,
        setFirebobMessage,
        refetchCurrentGameAssistantStepByMatchId,
      })
      setMatchSettleRequest(updatedMatchSettleRequest ?? null)
    }
  }, [matchSettleRequestUpdated, stepIndex])

  useEffect(() => {
    if (
      gameOrdinalState &&
      currentStep?.step?.gameOrdinal !== gameOrdinalState
    ) {
      setStepIndex(0)
    }
  }, [gameOrdinalState, currentStep?.step?.gameOrdinal])

  const { invalidScores, errorMessage } = getInvalidScores(
    hasMatchAssistantScore,
    homeScore !== undefined ? Number(homeScore) : undefined,
    awayScore !== undefined ? Number(awayScore) : undefined
  )
  const derivedFirebobMessage =
    (invalidScores && errorMessage) || firebobMessage
  const isNextDisabled = selectedTeamSide === -1 || invalidScores

  useEffect(
    function updateSelectionOnScoreChange(): void {
      if (!hasMatchAssistantScore) {
        return
      }

      if (!invalidScores) {
        setSelectedTeamSide(
          Number(homeScore) > Number(awayScore)
            ? TeamSide.homeTeam
            : TeamSide.awayTeam
        )
      } else {
        setSelectedTeamSide(-1)
      }
    },
    [hasMatchAssistantScore, homeScore, awayScore]
  )

  const shouldShowChangeRoster = homeTeam?.rosterFormat?.teamSize !== 1

  return (
    <Box className={classes.container}>
      <NxTypography variant="h1">
        Who won Game {currentStep?.step?.gameOrdinal}?
      </NxTypography>

      {gameMode && (
        <Box textAlign="center">
          <NxTypography colorToken="ColorTextAlt2" variant="overline">
            {gameMode}
          </NxTypography>
          <NxTypography variant="h4">
            {selectedStage?.selectionOption?.displayName}
          </NxTypography>
        </Box>
      )}

      <Box display="flex" flexDirection="column" gridGap={theme.spacing(3)}>
        <NxSelectableSingle
          height={`${theme.spacing(11)}px`}
          hideCheckmark
          onChange={(_, newValue): void => {
            if (hasMatchAssistantScore) {
              return
            }
            setSelectedTeamSide(newValue)
          }}
          outerContainerClassName={classes.outerContainer}
          selectableContainerClassName={classes.selectableContainer}
          value={selectedTeamSide}
          width="100%"
        >
          <Box key="home-team" className={classes.selectable}>
            <Box className={classes.playerGradient} />
            <NxUserCluster
              avatarHashId={homeTeam?.id ?? ''}
              avatarUrl={homeTeam?.avatarUrl ?? undefined}
              className={classes.userCluster}
              compact
              subtitles={[{ title: homeTeam?.school?.name }]}
              title={homeTeam?.name ?? '??'}
            />

            {hasMatchAssistantScore && (
              <Box display="flex" flex={1} justifyContent="flex-end">
                <TextField
                  className={classes.scoreInput}
                  inputProps={{ min: 0 }}
                  label={scoreDisplayName}
                  name="homeScore"
                  onChange={(evnt): void => {
                    setHomeScore(evnt.target.value)
                  }}
                  type="number"
                  value={homeScore}
                  variant="outlined"
                />
              </Box>
            )}
          </Box>

          <Box key="away-team" className={classes.selectable}>
            <Box className={classes.opponentGradient} />
            <NxUserCluster
              avatarHashId={awayTeam?.id ?? ''}
              avatarUrl={awayTeam?.avatarUrl ?? undefined}
              className={classes.userCluster}
              compact
              subtitles={[{ title: awayTeam?.school?.name }]}
              title={awayTeam?.name ?? '??'}
            />

            {hasMatchAssistantScore && (
              <Box display="flex" flex={1} justifyContent="flex-end">
                <TextField
                  className={classes.scoreInput}
                  inputProps={{ min: 0 }}
                  label={scoreDisplayName}
                  name="awayScore"
                  onChange={(evnt): void => {
                    setAwayScore(evnt.target.value)
                  }}
                  type="number"
                  value={awayScore}
                  variant="outlined"
                />
              </Box>
            )}
          </Box>
        </NxSelectableSingle>

        {shouldShowChangeRoster && ChangeRoster}
      </Box>

      <MatchAssistantFooter>
        <NxFirebobMessage message={derivedFirebobMessage} />

        <Box
          display="flex"
          gridGap={theme.spacing(2)}
          justifyContent="space-between"
          width="100%"
        >
          <AttachScreenshotsForGame
            file={screenshotFile}
            setScreenshotFile={setScreenshotFile}
          />

          <NxButton
            disabled={isNextDisabled}
            fullWidth
            label={
              firebobMessage === FirebobMessage.RejectedSubmission
                ? 'Resubmit'
                : 'Submit'
            }
            loading={
              loadingMatchSettleRequest ||
              firebobMessage === FirebobMessage.WaitingOnOpp
            }
            onClick={async (): Promise<void> => {
              setSubmittedUserIdForNPS(userId)
              if (!isNil(selectedTeamSide) && currentStep && match) {
                try {
                  await submitGameResults({
                    selectedTeamSide,
                    homeTeamId,
                    awayTeamId,
                    userId,
                    matchId: match?.id ?? '',
                    seriesId: currentStep?.step?.seriesId || undefined,
                    seriesOrdinal:
                      currentStep?.step?.seriesOrdinal || undefined,
                    gameOrdinal: currentStep?.step?.gameOrdinal ?? 0,
                    myTeamId,
                    saveGameResultLive: saveGameResult,
                    setIsLoadingMatchSettleRequest,
                    homeTeamIds: homeTeam?.starters?.map(({ id }) => id) || [],
                    awayTeamIds: awayTeam?.starters?.map(({ id }) => id) || [],
                    isAdmin: isInCheckpoint,
                    homeScore: Number(homeScore),
                    awayScore: Number(awayScore),
                    requestRosterChangeForNextGame: isChangeRosterChecked,
                    screenShot: screenshotFile,
                    uploadFile,
                  })
                } catch (err) {
                  logger.error(err)
                }
              }
            }}
            variant="primary"
          />
        </Box>
      </MatchAssistantFooter>
    </Box>
  )
}
