import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import {
  NxButton,
  NxTooltip,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import { Path } from '@plvs/const'
import { School } from '@plvs/graphql/generated'
import { WarningOutlined } from '@playvs-inc/nexus-icons'
import { CopyIconButton } from '@plvs/respawn/features/button'
import { useNavigate } from 'react-router-dom'
// <AccountSettingsPromptEditPasswordDialog /> relies on an environment variable from rallyEnv, which we can't use in respawn
// eslint-disable-next-line no-restricted-imports
import { AccountSettingsPromptEditPasswordDialog } from '@plvs/rally/features/account/AccountSettingsPromptEditPasswordDialog'
import { useAuthRenderControllerContext } from '@plvs/respawn/renderController'
import { ChildSettingsCard } from './ChildSettingsCard'

type ChildSchoolLoginProps = {
  userId: string
  childSchool: Pick<School, 'id' | 'name' | 'logoUrl' | 'city' | 'state'>
  childEmail?: string
}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    minWidth: '81px',
    marginRight: theme.spacing(3),
  },
}))

export const ChildSchoolLoginSettings: React.FC<ChildSchoolLoginProps> = ({
  userId,
  childEmail,
  childSchool,
}) => {
  const { auth } = useAuthRenderControllerContext()
  const { shouldRenderUsernameField } = auth.getAuthComponentsToRender({
    location: window.location.href,
  })
  const navigate = useNavigate()
  const classes = useStyles()
  const [showEnterPasswordPrompt, setShowEnterPasswordPrompt] =
    useState<boolean>()

  const childSchoolCluster = (
    <Box data-testid="child-school" display="flex" flexDirection="row">
      <NxUserCluster
        avatarHashId={childSchool?.id}
        avatarUrl={childSchool?.logoUrl ?? ''}
        subtitles={[{ title: `${childSchool?.city}, ${childSchool?.state}` }]}
        title={childSchool?.name ?? ''}
      />

      <NxTooltip
        arrow
        placement="right"
        title="Contact support to link a different school to this account"
      >
        <Box ml={1} mt={1}>
          <WarningOutlined />
        </Box>
      </NxTooltip>
    </Box>
  )

  const emailCluster = (
    <Box
      alignItems="center"
      data-testid="child-email"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      <Box display="flex" flexDirection="row">
        <NxTypography className={classes.textTitle} variant="body1">
          {shouldRenderUsernameField ? `Child's Email` : `School Email`}
        </NxTypography>
        <NxTypography variant="body1">{childEmail}</NxTypography>
      </Box>
      <CopyIconButton
        textToCopy={childEmail ?? ''}
        tooltipTextBeforeCopying="Copy Child Email"
      />
    </Box>
  )

  const PasswordCluster = (
    <Box data-testid="child-password" display="flex" flexDirection="row">
      <NxTypography className={classes.textTitle} variant="body1">
        Password
      </NxTypography>
      <NxTypography variant="body1">*************</NxTypography>
    </Box>
  )

  const showEditPasswordPrompt = (): void => {
    setShowEnterPasswordPrompt(true)
  }

  const titleCta = (
    <NxButton
      data-testid="edit-details-button"
      label="Edit"
      onClick={showEditPasswordPrompt}
      variant="secondary"
    />
  )

  const handleRequestAccountEditSuccess = (): void => {
    setShowEnterPasswordPrompt(false)

    navigate(`${Path.App}/child/${userId}/login-settings`)
  }
  let baseContent = [
    { id: 'school', content: childSchoolCluster },
    { id: 'email', content: emailCluster },
    { id: 'password', content: PasswordCluster },
  ]

  // Remove school user cluster on stadium child
  if (shouldRenderUsernameField) {
    baseContent = baseContent.filter((content) => content.id !== 'school')
  }

  return (
    <Box mt={3}>
      <ChildSettingsCard
        customContent={baseContent}
        title={`${
          shouldRenderUsernameField ? '' : 'School & '
        }Login Information`}
        titleCta={titleCta}
      />
      {showEnterPasswordPrompt && (
        <Box>
          <AccountSettingsPromptEditPasswordDialog
            onClose={(): void => {
              setShowEnterPasswordPrompt(false)
            }}
            onSuccess={handleRequestAccountEditSuccess}
          />
        </Box>
      )}
    </Box>
  )
}
