import { Box } from '@plvs/respawn/features/layout'
import { makeStyles, useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import { Param, Path } from '@plvs/const'
import { EsportSlug } from '@plvs/graphql'
import { EsportRating } from '@plvs/graphql/generated'
import {
  CompetitionGroupMap,
  competitionModelToDisplay,
  appendClasses,
} from '@plvs/utils'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { restrictedEsportLabel } from '@plvs/rally/libs/data-utils/esport-utils'
import { BoxShadow, Shape } from '@plvs/rally/themes'

import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { MinimalLeague, MinimalTeam } from './TeamCard.types'

// Used for CSS anitmation on loading state
import './TeamCard.styles.css'

const useStyles = makeStyles((theme) => ({
  container: {
    scrollSnapAlign: 'start',
    minWidth: 'min-content',
    cursor: 'pointer',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadius,
    flexBasis: '33%',
    maxWidth: '20em',
    minWidth: '20em',
    height: '100%',
    minHeight: '14.6em',
    maxHeight: '20em',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    boxSizing: 'border-box',
    boxShadow: BoxShadow.elevation1,
  },
  cardContent: {
    maxWidth: '100%',
    width: '100%',
  },
  cardContentHeader: {
    borderRadius: `${Shape.borderRadius.card} ${Shape.borderRadius.card} 0 0`,
    width: '100%',
    position: 'relative',
    minHeight: '4rem',
    backgroundColor: theme.palette.ColorBackgroundInactive,
    maxWidth: '100%',
  },
  cardContentBody: {
    width: '100%',
    // Added extra padding on top to account for team logo.
    padding: '2rem 1rem 1rem 1rem',
    minHeight: 'max-content',
    gap: '1rem',
    maxWidth: '100%',
    color: theme.palette.ColorTextAlt,
  },
  imageClip: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'hidden',
  },
  avatar: {
    bottom: 0,
    position: 'absolute',
    transform: 'translate(-50%, 50%)',
    left: '50%',
    borderRadius: '50%',
    boxShadow: BoxShadow.elevation1,
  },
  teamName: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: '1.4rem',
    fontFamily: 'Whitney, sans-serif',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  rankDisplay: {
    gap: '0.5rem',
    marginBottom: '1rem',
  },
  greyedOut: {
    color: theme.palette.ColorTextDisabled,
  },
  rankChangeLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(0, -50%)',
    display: 'flex',
    alignItems: 'center',
    borderRadius: Shape.borderRadius.pill,
    padding: '0.0125rem .5rem',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontWeight: 'bold',
  },
  rankChangeLabelUp: {
    color: theme.palette.ColorTextSuccess,
    backgroundColor: theme.palette.ColorBackgroundSuccess,
  },
  rankChangeLabelDown: {
    color: theme.palette.ColorTextError,
    backgroundColor: theme.palette.ColorBackgroundError,
  },
  rankChangeIcon: {
    marginRight: '0.2rem',
  },
  leagueBlock: {
    maxWidth: '100%',
  },
  regionalityLabel: {
    fontWeight: 'bold',
    color: theme.palette.ColorTextAlt,
    fontSize: '0.75rem',
    lineHeight: '125%',
    margin: 0,
    textAlign: 'center',
    letterSpacing: '0.12rem',
    textTransform: 'uppercase',
    fontFamily: 'Whitney, sans-serif',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  leagueNameLabel: {
    color: theme.palette.ColorTextAlt,
    textAlign: 'center',
    fontFamily: 'Whitney, sans-serif',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  enrollmentPill: {
    borderRadius: Shape.borderRadius.pill,
    backgroundColor: theme.palette.ColorBackgroundWarn,
    color: theme.palette.ColorTextWarn,
    padding: '0.25rem 1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    width: 'fit-content',
    overflow: 'hidden',
    margin: '0 auto',
  },
  ctaButton: {
    marginTop: 'auto',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& span': {
      whiteSpace: 'nowrap',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  inelligibleText: {
    color: theme.palette.ColorTextAlt2,
  },
}))

export interface TeamCardProps {
  team: MinimalTeam

  esportSlug?: EsportSlug
  rating?: EsportRating
  teamLogoUrl?: string

  /**
   * current rank as a string
   */
  rankLabel?: string

  // Rank change removed from requirements for now but may come up again
  rankChange?: number
  isEnrolled?: boolean
  daysLeftToEnroll?: number

  ctaEnabled?: boolean
  ctaLabel?: string
  ctaOnClick?: () => void

  league?: MinimalLeague
  isCoach?: boolean
}

export const TeamCard: React.FC<TeamCardProps> = ({
  team,
  esportSlug,
  rating = EsportRating.General,
  teamLogoUrl,
  isEnrolled,
  rankLabel,
  rankChange,
  daysLeftToEnroll,
  league,
  isCoach,
  ctaEnabled,
  ctaLabel,
  ctaOnClick,
}) => {
  const isTbd = rankLabel?.includes('TBD')
  const navigate = useNavigate()
  const classes = useStyles()
  const theme = useTheme()

  const { headerBackgroundUrl, themeGradient } = useGeneralEsportAdapter(
    esportSlug
  )

  const isYouthProgram = rating === EsportRating.Restricted

  const competitionGroupDisplayName = league?.competitionGroup
    ? CompetitionGroupMap[league?.competitionGroup]
    : null

  const programDisplay = isYouthProgram
    ? restrictedEsportLabel
    : competitionGroupDisplayName

  const scholasticRegionality = isYouthProgram
    ? null
    : competitionModelToDisplay({
        competitionGroup: league?.competitionGroup,
        competitionModel: league?.competitionModel,
      })

  const regionalityDisplayLabel: string = [
    !!scholasticRegionality && scholasticRegionality,
    !!programDisplay && programDisplay,
  ]
    .filter((x) => !!x)
    .join(' • ')

  const onNavigateToTeam = (): void => {
    if (isCoach) {
      navigate({
        pathname: Path.ManageTeams,
        search: `?${Param.Esport}=${esportSlug}`,
      })
    } else {
      const teamPath = generatePath(`${Path.Team}/:teamId`, {
        teamId: team?.id,
      })
      navigate(teamPath)
    }
  }

  return (
    <Box
      className={appendClasses(
        classes.container,
        'TeamCard' /* This is used as a locator for '<TeamCard />' component. */
      )}
      data-testid="TeamCard_Container"
      mr={2.25}
      onClick={onNavigateToTeam}
    >
      <Box
        className={classes.card}
        data-testid="DashboardTeamCard_OuterCard"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {/* Card forground */}
        <Box
          alignItems="center"
          className={classes.cardContent}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          justifyContent="space-around"
        >
          {/* Display Area for logo */}
          <Box
            className={classes.cardContentHeader}
            data-testid="DashboardTeamCard_CardContent_Header"
            display="flex"
            flexBasis={1}
            flexGrow={1}
            flexShrink={1}
            style={{
              background: `${
                headerBackgroundUrl ? `url('${headerBackgroundUrl}'), ` : ''
              }${themeGradient || theme.palette.ColorBackgroundInactive}`,
              backgroundSize: 'auto 100%',
              backgroundPositionX: '50%',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Box className={classes.avatar}>
              <NxUserAvatar
                avatarUrl={
                  rating === EsportRating.Restricted ? null : teamLogoUrl
                }
                hashId={team?.id ?? ''}
                size={NxAvatarSize.MD}
              />
            </Box>
          </Box>

          {/* Content Body */}
          <Box
            alignItems="center"
            className={classes.cardContentBody}
            data-testid="DashboardTeamCard_CardContent_Body"
            display="flex"
            flexBasis={1}
            flexDirection="column"
            flexGrow={3.4}
            flexShrink={3.4}
            justifyContent="space-around"
            p={1}
          >
            {/* Name and Rank */}
            <Box width="100%">
              <NxTypography
                align="center"
                className={classes.teamName}
                color="inherit"
                data-testid="DashboardTeamCard_TeamName"
                variant="h3"
              >
                {team.name}
              </NxTypography>

              <Box
                alignItems="center"
                className={classes.rankDisplay}
                data-testid="DashboardTeamCard_Rank"
                display="flex"
                flexDirection="row"
                justifyContent="center"
              >
                <NxTypography
                  className={isTbd ? classes.greyedOut : ''}
                  colorToken="ColorTextBase"
                  data-testid="DashboardTeamCard_CurrentRank"
                  variant="subtitle1"
                >
                  #{rankLabel}
                </NxTypography>
                {rankLabel && rankChange && (
                  <Box
                    data-testid="DashboardTeamCard_RankChange"
                    height="100%"
                    position="relative"
                  >
                    <div
                      className={appendClasses(
                        classes.rankChangeLabel,
                        rankChange > 0
                          ? classes.rankChangeLabelUp
                          : classes.rankChangeLabelDown
                      )}
                    >
                      {rankChange > 0 ? (
                        <span
                          className={classes.rankChangeIcon}
                          data-testid="DashboardTeamCard_RankChange_Up"
                        >
                          &#x25B2;
                        </span>
                      ) : (
                        <span
                          className={classes.rankChangeIcon}
                          data-testid="DashboardTeamCard_RankChange_Down"
                        >
                          &#x25BC;
                        </span>
                      )}
                      {rankChange}
                    </div>
                  </Box>
                )}
              </Box>
              {!Number.isNaN(Number(daysLeftToEnroll)) && (
                <Box
                  className={classes.enrollmentPill}
                  data-testid="DashboardTeamCard_DaysLeftToEnroll"
                >
                  {daysLeftToEnroll} day(s) left to enroll
                </Box>
              )}
            </Box>

            {!!league && (
              <Box
                alignItems="center"
                className={classes.leagueBlock}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <NxTypography
                  className={classes.regionalityLabel}
                  data-testid="DashboardTeamCard_RegionalityLabel"
                >
                  {regionalityDisplayLabel}
                </NxTypography>
                {!daysLeftToEnroll && isEnrolled && (
                  <NxTypography
                    className={classes.leagueNameLabel}
                    data-testid="DashboardTeamCard_LeagueNameLabel"
                    variant="subtitle1"
                  >
                    {league.displayName ?? league.name}
                  </NxTypography>
                )}
                {!daysLeftToEnroll &&
                  !isEnrolled &&
                  ctaLabel !== 'Fill Roster' && (
                    <NxTypography
                      className={classes.inelligibleText}
                      variant="body4"
                    >
                      No eligible leagues this season
                    </NxTypography>
                  )}
              </Box>
            )}

            {!!ctaLabel && (daysLeftToEnroll || ctaLabel === 'Fill Roster') && (
              <NxButton
                className={classes.ctaButton}
                data-testid="DashboardTeamCard_CtaButton"
                disabled={!ctaEnabled}
                fullWidth
                label={ctaLabel}
                onClick={(e): void => {
                  ctaOnClick?.()
                  e.stopPropagation()
                }}
                variant="primary"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export const TeamCardLoading: React.FC = () => {
  const classes = useStyles()

  return (
    <Box
      className={appendClasses(
        classes.container,
        'TeamCard' /* This is used as a locator for '<TeamCard />' component. */
      )}
      mr={2.25}
    >
      <Box
        className={classes.card}
        data-testid="DashboardTeamCard_OuterCard"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {/* Card forground */}
        <Box
          alignItems="center"
          className={classes.cardContent}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          justifyContent="space-around"
        >
          {/* Display Area for logo */}
          <Box
            className={appendClasses(
              classes.cardContentHeader,
              'DashboardTeamCard--loading'
            )}
            display="flex"
            flexBasis={1}
            flexGrow={1}
            flexShrink={1}
          >
            <Box className={classes.avatar}>
              <Skeleton
                animation={false}
                className="DashboardTeamCard--loading"
                height="4em"
                variant="circle"
                width="4em"
              />
            </Box>
          </Box>

          {/* Content Body */}
          <Box
            alignItems="center"
            className={classes.cardContentBody}
            data-testid="DashboardTeamCard--loading"
            display="flex"
            flexBasis={1}
            flexDirection="column"
            flexGrow={3.4}
            flexShrink={3.4}
            justifyContent="space-around"
            p={1}
          >
            {/* Name and Rank */}
            <Box>&nbsp;</Box>

            {/* button placeholder */}
            <Skeleton
              animation={false}
              className="DashboardTeamCard--loading"
              height="3em"
              variant="rect"
              width="100%"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
