import dayjs from 'dayjs'
import { head, reject, uniqBy } from 'ramda'
import {
  GetTeamsForScheduleMatchesWithSlotsQuery,
  GetTeamSlotsWithQueueMatchesQuery,
  PhaseFormat,
} from '@plvs/graphql'
import { isMatchQuoteUnquoteCancelled } from '@plvs/utils'
import {
  TeamSlot,
  SchedulePageMatch,
  SchedulePhase,
  SchedulePageQueueMatch,
  TeamMatch,
  MappedMatch,
  MappedMatchTeam,
} from './schedule'

export const makeOwnersTeamsFirst = (
  matches: (SchedulePageMatch | TeamMatch)[],
  teamIds: string[]
): (SchedulePageMatch | TeamMatch)[] => {
  const teamIdsSet = new Set(teamIds)
  const usedKeys = new Set()
  const makeKey = (slotId: string, teamId: string): string => slotId + teamId
  return matches.map((match) => {
    if (
      match.matchResults &&
      match.matchResults.length === 2 &&
      match.matchResults[1].team &&
      teamIdsSet.has(match.matchResults[1].team.id)
    ) {
      return {
        ...match,
        matchResults: [match.matchResults[1], match.matchResults[0]],
      }
    }
    if (match.matchResults && match.matchResults.length > 2) {
      const firstTeamId = match.matchResults[0].team?.id ?? ''
      const key = makeKey(match.slotId ?? '', firstTeamId)
      if (teamIdsSet.has(firstTeamId) && !usedKeys.has(key)) {
        // if it's already the first team, add it to usedTeamIds and do nothing
        usedKeys.add(key)
      } else {
        // if not first team, look for team id not already used and make it first
        const teamIndex = match.matchResults.findIndex((matchResult) => {
          const teamId = matchResult?.team?.id ?? ''
          const newKey = makeKey(match.slotId ?? '', teamId)
          if (teamId && teamIdsSet.has(teamId) && !usedKeys.has(newKey)) {
            usedKeys.add(newKey)
            return true
          }
          return false
        })
        if (teamIndex > 0) {
          const matchResult = match.matchResults[teamIndex]
          return {
            ...match,
            matchResults: [
              matchResult,
              ...match.matchResults.slice(0, teamIndex),
              ...match.matchResults.slice(teamIndex + 1),
            ],
          }
        }
      }
    }
    return match
  })
}

const slotTimeKey = (timeObj: { startsAt: string; endsAt: string }): string => {
  return `${dayjs(timeObj.startsAt).valueOf()}-${dayjs(
    timeObj.endsAt
  ).valueOf()}`
}

const teamSlotKey = (teamId: string, slotId: string): string =>
  `${teamId}-${slotId}`

export const filterOutExcludedSlots = (
  matches: TeamMatch[],
  teamSlots: TeamSlot[]
): TeamMatch[] => {
  const result: TeamMatch[] = []
  let excludedTeamSlotIds: string[] = []
  teamSlots.forEach((team) => {
    const exclusionWindows = team.slotExclusionWindows
    let teamPhases: SchedulePhase[] = []
    team.seasonTeams?.forEach((seasonTeam) => {
      seasonTeam.season?.activePhases?.forEach((phase) => {
        teamPhases = teamPhases.concat(phase)
      })
    })
    const slotExclusionTimes = new Set(
      exclusionWindows?.map((window) => slotTimeKey(window))
    )
    teamPhases.forEach((phase) => {
      if (slotExclusionTimes.has(slotTimeKey(phase))) {
        const excludedSlotTeamIds =
          phase?.defaultSlots?.map(({ id }) => teamSlotKey(team.id, id)) || []
        excludedTeamSlotIds = excludedTeamSlotIds.concat(excludedSlotTeamIds)
      }
    })
  })
  const excludedTeamSlotIdsSet = new Set(excludedTeamSlotIds)
  matches.forEach((match) => {
    const teamId = head(match.matchResults || [])?.team?.id
    const { slotId, isScrimmage } = match
    const isNotExcluded =
      teamId &&
      slotId &&
      !excludedTeamSlotIdsSet.has(teamSlotKey(teamId, slotId))

    if (isScrimmage || isNotExcluded) {
      result.push(match)
    }
  })
  return result
}

export const filterOutExcludedMatches = (
  matches: TeamMatch[],
  teamSlots: TeamSlot[]
): TeamMatch[] => {
  const teamWindows = teamSlots.reduce<
    Record<string, { startsAt?: string; endsAt?: string }[]>
  >((accum, team) => {
    return { [team.id]: team.slotExclusionWindows || [], ...accum }
  }, {})
  return matches.filter((match) => {
    // We only want to filter out matches that are TBD. If a match
    // exists and a window for that match also exsits, that's a bug
    // in our match scheduler and should be fixed there. We don't want
    // to mask the system error.
    if ((match.matchResults || []).length > 1) return true
    const matchDate = dayjs(match.scheduledStartsAt)
    const teamId = head(match.matchResults || [])?.team?.id || ''
    const windows = teamWindows[teamId]
    if (windows) {
      for (let i = 0; i < windows.length; i += 1) {
        const window = windows[i]
        if (
          matchDate.isAfter(dayjs(window.startsAt).subtract(1, 'second')) &&
          matchDate.isBefore(dayjs(window.endsAt).add(1, 'second'))
        ) {
          return false
        }
      }
    }
    return true
  })
}

/**
 * Because matches need several preprocessing steps, it has been abstracted
 * out into this fn which execute all necessary processes.
 *
 * @param matches
 * @param teamSlots
 * @param teamIds
 */
export const getMatchesPrepped = (
  matches: TeamMatch[],
  teamSlots: TeamSlot[],
  teamIds: string[]
): TeamMatch[] => {
  let result = reject(isMatchQuoteUnquoteCancelled, matches)
  result = makeOwnersTeamsFirst(result, teamIds)
  result = uniqBy(({ id }) => id, result)
  result = filterOutExcludedSlots(result, teamSlots)
  result = filterOutExcludedMatches(result, teamSlots)
  result = result.map((match) => {
    return {
      ...match,
      metaseasonId: match.slot?.phase?.season?.metaseasonId ?? '',
    }
  })
  return result as TeamMatch[]
}

type QueueMatchesAndSlots = {
  queueMatches: any
  slotIds: string[]
}

export function buildAllPossibleQueueMatches(
  teamsWithSlots: GetTeamsForScheduleMatchesWithSlotsQuery
): QueueMatchesAndSlots {
  const slotIds = new Set<string>()
  const allPossibleQueueMatchesBySlot: {
    [k: string]: any
  } = {}

  // Build queue match details for each slot
  teamsWithSlots.teamsByIds?.forEach((team) => {
    team.seasonTeams?.forEach((seasonTeam) => {
      seasonTeam.season?.activePhases?.forEach((phase) => {
        // check for smart schedule format
        if (phase.format === PhaseFormat.SmartSchedule) {
          phase.defaultSlots?.forEach((slot) => {
            slotIds.add(slot.id)
            if (!allPossibleQueueMatchesBySlot[slot.id]) {
              allPossibleQueueMatchesBySlot[slot.id] = {}
            }

            allPossibleQueueMatchesBySlot[slot.id][team.id] = {
              slotId: slot.id,
              esport: team.esport,
              bestOf: phase.bestOf ?? 0,
              scheduledStartsAt: slot.startsAt ?? '',
              metaseasonId: seasonTeam.season?.metaseasonId,
              teamId: team.id,
              teamName: team.name ?? '',
              schoolLogoUrl: team.school?.logoUrl ?? '',
              schoolName: team.school?.name ?? '',
              schoolId: team.school?.id ?? '',
              competitionGroup: seasonTeam.season?.league?.competitionGroup,
              isScrimmage: false,
            }
          })
        }
      })
    })
  })

  return {
    queueMatches: allPossibleQueueMatchesBySlot,
    slotIds: Array.from(slotIds),
  }
}

export function filterQueueMatches(
  allPossibleQueueMatchesBySlot: QueueMatchesAndSlots,
  slots: GetTeamSlotsWithQueueMatchesQuery
): SchedulePageQueueMatch[] {
  const queueMatches: SchedulePageQueueMatch[] = []
  slots.slots?.forEach((slot) => {
    slot.teamIdsWithSmartQueueMatches?.forEach((teamId) => {
      const queueMatch =
        allPossibleQueueMatchesBySlot.queueMatches[slot.id][teamId]
      if (queueMatch) {
        queueMatches.push(queueMatch)
      }
    })
  })

  return queueMatches
}

// Queue matches don't have a second team, so we need to fake it
// for ease of typing.
export const DEFAULT_QUEUE_MATCH_TEAM = {
  id: 'queue-team',
  name: 'Join Queue',
  isMyTeam: false,
  schoolId: null,
  school: null,
}

export const mapQueueMatches = (queueMatches): MappedMatch[] => {
  return queueMatches.map((qm) => {
    return {
      slotId: qm.slotId ?? '',
      esport: {
        id: qm.esport?.id ?? '',
        name: qm.esport?.name ?? '',
        rating: qm.esport?.rating,
        slug: qm.esport?.slug,
      },
      bestOf: qm.bestOf ?? 0,
      scheduledStartsAt: qm.scheduledStartsAt ?? '',
      metaseasonId: qm?.slot?.phase?.season?.metaseasonId ?? '',
      id: '',
      league: {
        id: qm.slot?.phase?.season?.league?.id || '',
      },
      matchResults: [],
      scheduleContext: qm.scheduleContext,
      isScrimmage: qm.isScrimmage,
      slot: {
        id: qm.slot?.id || '',
        phase: {
          id: qm.slot?.phase?.id || '',
          type: qm.slot?.phase?.type || null,
          season: {
            id: qm.slot?.phase?.season?.id || '',
            metaseasonId: qm.slot?.phase?.season?.metaseasonId || '',
          },
        },
      },
      status: null,
      // We are creating a team1 based on pulling the data
      // from the team object in the queue match. This
      // is again for ease of typing.
      teamContext: {
        myTeams: [
          {
            id: qm.team?.id || '',
            schoolId: qm.team?.school?.id || '',
            name: qm.team?.name || '',
            avatarUrl: qm.team?.avatarUrl || '',
            school: {
              id: qm.team?.school?.id || '',
              logoUrl: qm.team?.school?.logoUrl || '',
              name: qm.team?.school?.name || '',
            },
          },
        ],
        otherTeams: [DEFAULT_QUEUE_MATCH_TEAM],
        teams: [
          {
            id: qm.team?.id || '',
            name: qm.team?.name || '',
            isMyTeam: true,
            avatarUrl: qm.team?.avatarUrl || '',
            schoolId: qm.team?.school?.id || '',
            school: {
              id: qm.team?.school?.id || '',
              logoUrl: qm.team?.school?.logoUrl || '',
              name: qm.team?.school?.name || '',
            },
          },
          DEFAULT_QUEUE_MATCH_TEAM,
        ],
        myTeamMatchResults: qm?.teamContext?.myTeamMatchResults ?? [],
      },
      matchFormat: null,
      competitionGroup:
        qm?.slot?.phase?.season?.league?.competitionGroup ||
        qm.team?.competitionGroup,
      // eslint-disable-next-line no-underscore-dangle
      __typename: qm.__typename,
      matchRescheduleRequests: [],
    }
  })
}

export const withSchoolId = (t): MappedMatchTeam => ({
  ...t,
  schoolId: t?.school?.id,
})

export const mapMatches = (matches): MappedMatch[] => {
  return matches.map((match) => {
    return {
      slotId: match.slotId ?? '',
      esport: {
        id: match.esport?.id ?? '',
        name: match.esport?.name ?? '',
        rating: match.esport?.rating,
        slug: match.esport?.slug,
      },
      bestOf: match.bestOf ?? 0,
      scheduledStartsAt: match.scheduledStartsAt ?? '',
      metaseasonId: match.metaseasonId,
      id: match.id,
      league: {
        id: match?.league?.id || '',
      },
      competitionGroup:
        match?.league?.competitionGroup ||
        match?.teamContext?.teams?.[0]?.competitionGroup,
      matchResults: match.matchResults ?? [],
      scheduleContext: match.scheduleContext,
      isScrimmage: match.isScrimmage,
      slot: {
        id: match.slot?.id || '',
        phase: {
          id: match.slot?.phase?.id || '',
          type: match.slot?.phase?.type || null,
          season: {
            id: match.slot?.phase?.season?.id || '',
            metaseasonId: match.slot?.phase?.season?.metaseasonId || '',
          },
        },
      },
      status: match.status,
      matchFormat: match.esport?.details?.matchFormat,
      teamContext: {
        myTeams: (match.teamContext?.myTeams ?? []).map(withSchoolId),
        otherTeams: (match.teamContext?.otherTeams ?? []).map(withSchoolId),
        teams: (match?.teamContext?.teams ?? []).map(withSchoolId),
        myTeamMatchResults: match?.teamContext?.myTeamMatchResults ?? [],
      },
      // eslint-disable-next-line no-underscore-dangle
      __typename: match.__typename,
      matchRescheduleRequests: match.matchRescheduleRequests ?? [],
    }
  })
}
