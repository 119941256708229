import {
  CompetitionGroup,
  EsportRating,
  MatchFormat,
  MatchStatus,
} from '@plvs/graphql/generated'
import { UserRolesForMatchLobbyRenderController } from '../../MatchLobbyRenderController.types'
import { MatchTeamsContext } from '../../match/types/matchRenderController.types'

export const genericQueueBannerActionsCopy =
  'After finishing your match or if your opponent forfeits, you can play against other teams without worrying about your standings. Have fun and take advantage of the extra practice!'

export enum ManageMatchDropdownMenuItems {
  Reschedule = 'manage-match-reschedule',
  ViewReschedule = 'manage-match-view-reschedule',
  Forfeit = 'manage-match-forfeit',
  Dispute = 'manage-match-dispute',
  Rulebook = 'manage-match-rulebook',
  LeagueRules = 'manage-match-league-rules',
  Scout = 'manage-match-scout',
  MatchInstructions = 'manage-match-match-instructions',
  LobbyTour = 'manage-match-lobby-tour',
  CopyGameCode = 'manage-match-copy-game-code',
  Open = 'manage-match-open-match',
  Chat = 'manage-match-open-chat',
  JoinQueue = 'manage-match-join-queue',
  CodeOfConduct = 'manage-match-code-of-conduct',
}

export const coachMenuItemIds = [
  ManageMatchDropdownMenuItems.Reschedule,
  ManageMatchDropdownMenuItems.ViewReschedule,
  ManageMatchDropdownMenuItems.Forfeit,
  ManageMatchDropdownMenuItems.Dispute,
]

export const ownerMenuItemIds = [
  ManageMatchDropdownMenuItems.Forfeit,
  ManageMatchDropdownMenuItems.Dispute,
]
export const scholasticMenuItemIdsForAllRoles = [
  ManageMatchDropdownMenuItems.Rulebook,
  ManageMatchDropdownMenuItems.LeagueRules,
  ManageMatchDropdownMenuItems.Scout,
  ManageMatchDropdownMenuItems.LobbyTour,
  ManageMatchDropdownMenuItems.CopyGameCode,
  ManageMatchDropdownMenuItems.MatchInstructions,

  ManageMatchDropdownMenuItems.CodeOfConduct,
]

export const nonScholasticMenuItemIdsForAllRoles = [
  ManageMatchDropdownMenuItems.LobbyTour,
  ManageMatchDropdownMenuItems.CopyGameCode,
  ManageMatchDropdownMenuItems.MatchInstructions,

  ManageMatchDropdownMenuItems.CodeOfConduct,
]

export const excludedMenuItemIdsLeaderboard = [
  ManageMatchDropdownMenuItems.Scout,
  ManageMatchDropdownMenuItems.Forfeit,
  ManageMatchDropdownMenuItems.Reschedule,
  ManageMatchDropdownMenuItems.ViewReschedule,
]

export type LobbyTeamForRenderController = {
  name?: string | null
  schoolName?: string | null
  avatarUrl: string | null
  schoolSlug?: string | null
  id: string
}
export type LobbyComponentProps = {
  status: MatchStatus | undefined
  isMatchAssistantEnabled: boolean
  teamsContext: MatchTeamsContext
  userRoles: UserRolesForMatchLobbyRenderController
  // Note, if we ever had a match or an event with two teams from different competition groups, this would need to be updated.
  competitionGroup: CompetitionGroup | null | undefined
}

export type LobbyComponentReturn = {
  showManageMatchSection: boolean
  showMatchLobbyTour: boolean
  myTeamIds: string[]
  opposingTeamId: string
  opposingTeamIds: string[]
  canManageQueueBannerActions: boolean
  canManageQueueBannerActionsCopy: string
  isMemberOfMoreThanOneTeam: boolean
  showActionAndInfoSection: boolean
  showRescheduleMatchAlert: boolean
  enableScoutingToTeamsPage: boolean
}

export type MenuItemIdsForManageMatchDropdownProps = {
  competitionGroup: CompetitionGroup | null | undefined
  userRoles: UserRolesForMatchLobbyRenderController
  teamsContext: MatchTeamsContext
  matchFormat: MatchFormat | null | undefined
}

export type MatchTeamsDisplayDetailsProps = {
  competitionGroup: CompetitionGroup | null | undefined
  teams: LobbyTeamForRenderController[]
  esportRating: EsportRating | null | undefined
}

export type MatchTeamsDisplayDetails = {
  teamDisplayDetails: Array<{
    id: string
    teamName: string
    avatarUrl: string
    schoolSlug?: string
    schoolName?: string
  }>
  numberOfOtherTeamsInMatch?: number
}
export type ManageMatchDropdownMenuItemsReturn = {
  items: ManageMatchDropdownMenuItems[]
}

export type LobbyRenderController = {
  getLobbyComponentsToRender: ({
    status,
    userRoles,
    competitionGroup,
    teamsContext,
    isMatchAssistantEnabled,
  }: LobbyComponentProps) => LobbyComponentReturn
  getMatchTeamsDisplayDetails: ({
    competitionGroup,
    teams,
    esportRating,
  }: MatchTeamsDisplayDetailsProps) => MatchTeamsDisplayDetails
  getMenuItemIdsForManageMatchDropdown: ({
    competitionGroup,
    userRoles,
    teamsContext,
    matchFormat,
  }: MenuItemIdsForManageMatchDropdownProps) => ManageMatchDropdownMenuItemsReturn
}
