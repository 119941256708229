/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react'

import { EsportSlug, MatchFormat, MatchStatus } from '@plvs/graphql'
import { DisputeMatch, StepType } from '@plvs/utils'

import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { nba2kMatchAssistantSteps } from '@plvs/rally/features/match/assistant/adapter/esports/nba2kMatchAssistantSteps'
import { maddenMatchAssistantSteps } from '@plvs/rally/features/match/assistant/adapter/esports/maddenMatchAssistantSteps'
import { splatoonMatchAssistantSteps } from '@plvs/rally/features/match/assistant/adapter/esports/splatoonMatchAssistantSteps'
import { rocketLeagueMatchAssistantSteps } from '@plvs/rally/features/match/assistant/adapter/esports/rocketLeagueMatchAssistantSteps'
import { smashBrosUltimateMatchAssistantSteps } from '@plvs/rally/features/match/assistant/adapter/esports/smashBrosUltimateMatchAssistantSteps'
import { leagueOfLegendsMatchAssistantSteps } from '@plvs/rally/features/match/assistant/adapter/esports/leagueOfLegendsMatchAssistantSteps'
import { getDisputeResultsButton } from '@plvs/rally/features/match/assistant/MatchAssistant.helpers'
import { GameIntermission } from '../steps'
import { useMatchAssistantContext } from '../useMatchAssistant'
import { RosterSelect } from '../steps/general/rosterSelect'
import { MatchAssistantActions } from '../MatchAssistantActions'
import { MatchAssistantIntro } from '../steps/general/intro'
import { PlayerSelect } from '../steps/smash/playerSelect/PlayerSelect'
import { ReadyUp } from '../steps/general/readyUp'
import { Beta } from '../steps/general/beta/Beta'
import { generalMatchAssistantAdapter } from './general/generalMatchAssistantAdapter'

export type MatchAssistantAdapterStepValue = Array<{
  step: React.ReactNode
  title: string | React.ReactNode
  actions?: React.ReactElement
  subtitle?: string
}>

export interface MatchAssistantAdapterSteps {
  assistantSteps: Record<StepType | string, MatchAssistantAdapterStepValue>
}

interface MatchAssistantAdapterStepsProps {
  esportSlug?: EsportSlug
  setIsMatchComplete: (isMatchComplete: boolean) => void
}

export const useMatchAssistantAdapterSteps = ({
  esportSlug,
  setIsMatchComplete,
}: MatchAssistantAdapterStepsProps): MatchAssistantAdapterSteps => {
  const {
    match,
    teamId,
    opposingTeamId,
    currentStep,
    stepIndex,
    setStepIndex,
    currentSeries,
    seriesOrdinal = 1,
    gameOrdinal = 1,
    myTeam,
  } = useMatchAssistantContext()

  const {
    match: { canManageMatch },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const incrementStepIndex = (): void => setStepIndex(stepIndex + 1)

  const isMatchComplete = currentStep?.matchDetails?.isComplete ?? false

  // This useEffect notifies the parent when the match is complete,
  // ensuring that only one final score step is rendered per user
  // This prevents duplicate final score steps for users overseeing multiple teams in the match.
  useEffect(() => {
    if (isMatchComplete) {
      setIsMatchComplete(true)
    }
  }, [isMatchComplete])

  const isSeriesTitle = Boolean(currentSeries && seriesOrdinal)
  const subtitle = isSeriesTitle
    ? `Series ${seriesOrdinal} - Game ${gameOrdinal}`
    : `Game ${gameOrdinal}`

  const showDisputeButton = Boolean(
    (isMatchComplete || match?.status === MatchStatus.Completed) &&
      canManageMatch &&
      match?.id
  )

  const rosterSelectSteps: MatchAssistantAdapterStepValue = []
  if (gameOrdinal === 1) {
    rosterSelectSteps.push({
      step: <MatchAssistantIntro onNext={incrementStepIndex} />,
      title: 'Guide',
      actions: <MatchAssistantActions />,
    })
  }
  rosterSelectSteps.push({
    step: <RosterSelect teamId={teamId} />,
    title: 'My Roster',
    subtitle,
    actions: <MatchAssistantActions />,
  })

  const generalSteps: Record<
    StepType | string,
    MatchAssistantAdapterStepValue
  > = {
    [StepType.ReadyUp]: [
      {
        step: <ReadyUp />,
        title: 'Ready Up',
        actions: <MatchAssistantActions />,
      },
    ],
    // Only beta-enabled esports will see this step
    [StepType.BetaOptIn]: [
      {
        step: <Beta />,
        title: 'New Feature Alert',
        actions: <MatchAssistantActions />,
      },
    ],
    [StepType.RosterSelect]: rosterSelectSteps,
    [StepType.OpponentPreview]: [
      {
        step: <RosterSelect teamId={opposingTeamId} />,
        subtitle,
        title: 'Opponent',
        actions: <MatchAssistantActions />,
      },
    ],
  }

  const playerSelectStep: MatchAssistantAdapterStepValue = []
  if (seriesOrdinal > 1) {
    playerSelectStep.push({
      step: (
        <GameIntermission
          buttonText="Choose Your Player"
          firebobMessage="Get ready to select your players!"
          isSeriesIntermission
          onNext={incrementStepIndex}
        />
      ),
      title: 'Intermission',
      subtitle,
      actions: <MatchAssistantActions />,
    })
  }
  playerSelectStep.push({
    step: <PlayerSelect />,
    subtitle,
    title: 'Player Select',
    actions: <MatchAssistantActions />,
  })

  const props = {
    setStepIndex: () => setStepIndex(stepIndex + 1),
    stepIndex,
    subtitle,
    isMatchComplete,
    myTeam,
    showDisputeButton,
    generalSteps,
    match,
    gameOrdinal,
    playerSelectStep,
    DisputeResultsButton: getDisputeResultsButton({
      showDisputeButton,
      canManageMatch: !!myTeam,
      match: match as DisputeMatch,
    }),
    Actions: getDisputeResultsButton({
      showDisputeButton,
      canManageMatch: !!myTeam,
      match: match as DisputeMatch,
      fallback: <MatchAssistantActions />,
    }),
  }

  const smashBrosSteps = smashBrosUltimateMatchAssistantSteps(props)
  const rocketLeagueSteps = rocketLeagueMatchAssistantSteps(props)
  const splatoonSteps = splatoonMatchAssistantSteps(props)
  const maddenSteps = maddenMatchAssistantSteps(props)
  const nba2kSteps = nba2kMatchAssistantSteps(props)
  const leagueOfLegendsSteps = leagueOfLegendsMatchAssistantSteps(props)

  const adapter: Record<string | EsportSlug, MatchAssistantAdapterSteps> = {
    // All Smash Bros
    [EsportSlug.SmashBrosUltimate]: {
      assistantSteps: smashBrosSteps,
    },
    [EsportSlug.SuperSmashBrosUltimateSolos]: {
      assistantSteps: smashBrosSteps,
    },
    [EsportSlug.SuperSmashBrosUltimateCrew]: {
      assistantSteps: smashBrosSteps,
    },

    // Street Fighter uses Smash's steps
    [EsportSlug.StreetFighter]: {
      assistantSteps: smashBrosSteps,
    },

    [EsportSlug.RocketLeague]: {
      assistantSteps: rocketLeagueSteps,
    },
    [EsportSlug.Splatoon]: {
      assistantSteps: splatoonSteps,
    },

    // All Maddens
    [EsportSlug.Madden]: {
      assistantSteps: maddenSteps,
    },
    [EsportSlug.Madden21PS4]: {
      assistantSteps: maddenSteps,
    },
    [EsportSlug.Madden21SolosXboxOne]: {
      assistantSteps: maddenSteps,
    },
    [EsportSlug.Madden21XboxOne]: {
      assistantSteps: maddenSteps,
    },

    [EsportSlug.NBA2K]: {
      assistantSteps: nba2kSteps,
    },
    [EsportSlug.LeagueOfLegends]: {
      assistantSteps: leagueOfLegendsSteps,
    },
  }

  const generalAdapters = generalMatchAssistantAdapter(props)

  const defaultMatchAssistantAdapterSteps =
    generalAdapters[MatchFormat.HeadToHead]
  const esportSpecificMatchAssistantAdapterSteps =
    esportSlug && adapter[esportSlug]
  const matchFormatSpecificMatchAssistantAdapterSteps =
    match?.format && generalAdapters[match?.format]

  return (
    esportSpecificMatchAssistantAdapterSteps ||
    matchFormatSpecificMatchAssistantAdapterSteps ||
    defaultMatchAssistantAdapterSteps
  )
}
