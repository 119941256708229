import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import { QuestionOutlined } from '@playvs-inc/nexus-icons'
import { NxTooltip, NxTypography } from '@playvs-inc/nexus-components'

import { IntercomArticleMappings } from '@plvs/const'
import { Box } from '@plvs/respawn/features/layout'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    padding: theme.spacing(0, 0, 0, 0.5),
  },
  learnMore: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

const PLAY_VS_AVG_TOOLTIP = 'Learn more about'

export const PlayVsAvgPlacementHeader: React.FC = () => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <NxTypography variant="subtitle1">Average Placement</NxTypography>
      <NxTooltip
        arrow
        interactive
        leaveDelay={2000}
        placement="top-start"
        title={
          <>
            {PLAY_VS_AVG_TOOLTIP}&nbsp;
            <NxTypography
              className={classes.learnMore}
              component="span"
              onClick={(): void =>
                showIntercomArticle(IntercomArticleMappings.playVsScore)
              }
              variant="body5"
            >
              how leaderboard ranking works
            </NxTypography>
          </>
        }
      >
        <IconButton className={classes.iconButton}>
          <QuestionOutlined />
        </IconButton>
      </NxTooltip>
    </Box>
  )
}
