import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Path } from '@plvs/const'
import { useGetMissingFields } from '@plvs/rally/containers/app/fetchers/GetMissingFields'
import React, { FunctionComponent } from 'react'
import { Navigate } from 'react-router'
import { UserRequiredFieldsAtAppLaunch } from '@plvs/graphql/generated'
import { LoadingSpinner } from '@playvs-inc/nexus-components'

import { FilterCacheProvider } from '@plvs/respawn/containers/filter/FilterCacheProvider'
import { MoreDetailsPromptPage } from './types'

/**
 * Map of which MoreDetails pages gathers which missing fields.
 */
const PAGE_MAP: { [key: string]: MoreDetailsPromptPage } = {
  [UserRequiredFieldsAtAppLaunch.Name]: React.lazy(
    () => import('./MoreDetailsName')
  ),
  [UserRequiredFieldsAtAppLaunch.DateOfBirth]: React.lazy(
    () => import('./MoreDetailsName')
  ),
  [UserRequiredFieldsAtAppLaunch.SchoolEmail]: React.lazy(
    () => import('./MoreDetailsSchoolEmail')
  ),
}

export const MoreDetailsPrompt: FunctionComponent = () => {
  const [missingFields, loading, refetch] = useGetMissingFields()

  // When we have no more missing fields, we redirect back to the main app.
  if (!missingFields || missingFields.length === 0) {
    return <Navigate to={Path.Dashboard} />
  }

  // Serve up the page to prompt for the missing fields.
  const MoreDetailsItem = PAGE_MAP[missingFields[0]]
  return (
    <WaitTillLoaded
      loading={loading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <FilterCacheProvider id={Path.Dashboard}>
        <React.Suspense fallback={<LoadingSpinner size="medium" />}>
          <Box mt={8}>
            <MoreDetailsItem onComplete={refetch} />
          </Box>
        </React.Suspense>
      </FilterCacheProvider>
    </WaitTillLoaded>
  )
}

// Note: default export is needed for lazy loading.
export default MoreDetailsPrompt
