import {
  CompetitionGroup,
  EsportSlug,
  PhaseFormat,
  useFnTeamPhaseStandingsQuery,
} from '@plvs/graphql'
import { EmptyPage } from '@plvs/respawn/components/empty'
import { getTeamsByFilter } from '@plvs/rally/components/filter'
import { Box } from '@plvs/respawn/features/layout'
import { MatchLeaderboard } from '@plvs/rally/components/match'
import { useGetAdapter } from '@plvs/respawn/features/esport/adapters'
import { useMyLeaderboardFilter } from '@plvs/rally/containers/filter'
import { getTableColumns } from '@plvs/rally/containers/table'
import React from 'react'
import {
  addFnSurvivalRankingAndFormat,
  calculateTeamSurvivalNumber,
} from '@plvs/utils'

const PAGE_SIZE = 15

export const FnRegularStandings: React.FC<{
  phaseId: string
  schoolId?: string
  esportSlug: EsportSlug | null
}> = ({ phaseId, schoolId, esportSlug }) => {
  const [page, setPage] = React.useState(1)
  const { standingsColumns, rankingInfoCopy } = useGetAdapter(esportSlug)
  const columns = getTableColumns(standingsColumns ?? [], rankingInfoCopy)
  const { data } = useFnTeamPhaseStandingsQuery({
    variables: {
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      phaseId,
      schoolId: schoolId ?? null,
    },
  })

  const {
    leaderboardFilter,
    selectedLeaderboardFilter,
  } = useMyLeaderboardFilter()

  React.useEffect(() => {
    setPage(1)
  }, [selectedLeaderboardFilter])

  const fortniteTeamPhaseStats = data?.phase?.fortniteTeamPhaseStats ?? null
  const allPhaseTeams = fortniteTeamPhaseStats?.leaderboard ?? []
  const myPhaseTeams = fortniteTeamPhaseStats?.myTeams ?? []
  const schoolPhaseTeams = fortniteTeamPhaseStats?.schoolTeams ?? []
  const total = data?.phase?.fortniteTeamPhaseStats?.total ?? 0
  const isSurvivalFormat = data?.phase?.format === PhaseFormat.Survival
  // One or the other will be given survivalAdvancementPercentage/survivalAdvancementCount
  let survivalRankingNumber = 0
  if (isSurvivalFormat) {
    survivalRankingNumber = calculateTeamSurvivalNumber(
      total,
      data?.phase?.survivalAdvancementPercentage ?? 0,
      data?.phase?.survivalAdvancementCount ?? 0
    )
  }

  // We exclude the filter for non scholastic
  const shouldShowTeamFilter = [
    CompetitionGroup.College,
    CompetitionGroup.HighSchool,
  ].some((x) => x === data?.phase?.season?.league?.competitionGroup)

  let finalTeams = getTeamsByFilter({
    filter: selectedLeaderboardFilter,
    allTeams: allPhaseTeams,
    myTeams: myPhaseTeams,
    schoolTeams: schoolPhaseTeams,
  })
  // If PlayVS cup add format and survival ranking number to show correct badge
  if (isSurvivalFormat && survivalRankingNumber > 0) {
    finalTeams = addFnSurvivalRankingAndFormat(
      finalTeams,
      survivalRankingNumber
    )
  }

  const finalTotal =
    selectedLeaderboardFilter === 'default' ? total : finalTeams.length
  const numPages = Math.ceil(finalTotal / PAGE_SIZE)

  const titleDetails = isSurvivalFormat
    ? `The top ${survivalRankingNumber} teams will advance to the next round.`
    : 'Top 33 teams in the Semifinals round will advance to Finals.'

  return allPhaseTeams.length ? (
    <MatchLeaderboard
      buttons={
        <>
          {shouldShowTeamFilter ? (
            <Box display="flex">{leaderboardFilter}</Box>
          ) : (
            <></>
          )}
        </>
      }
      columns={columns}
      data={finalTeams}
      numPages={numPages}
      page={page}
      setPage={setPage}
      title="Season Standings"
      titleDetail={titleDetails}
    />
  ) : (
    <EmptyPage showIcon subtitle="No data yet - check back later" />
  )
}
