import { Box } from '@plvs/respawn/features/layout'
import { IconButton, makeStyles } from '@material-ui/core'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import React, { useRef } from 'react'
import { colors } from '@playvs-inc/nexus-core'
import {
  NxActionSpotModal,
  NxButton,
  NxTypography,
} from '@playvs-inc/nexus-components'
import {
  refetchTeamRosterQuery,
  useUploadTeamAvatarMutation,
} from '@plvs/graphql/generated'
import { useSnackbar } from 'notistack'
import { useResourceImageProvider } from '../../resources/ResourceImageProvider'

const MAX_FILE_SIZE_IN_BYTES = 1000000 // 1MB

const useStyles = makeStyles((theme) => ({
  container: {
    height: 125,
    width: 125,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px ${colors.OpWhite[10]} dashed`,
    borderRadius: 65,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hiddenInput: {
    display: 'none',
  },
  editIcon: {
    marginRight: theme.spacing(1),
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

interface Props {
  onClose(): void
  teamId: string
}

export const UpdateTeamAvatarModal: React.FC<Props> = ({ onClose, teamId }) => {
  const [uploadTeamAvatar, { loading }] = useUploadTeamAvatarMutation()
  const { updateResourceImages } = useResourceImageProvider()
  const { enqueueSnackbar } = useSnackbar()

  const classes = useStyles()
  const [isBusy, setIsBusy] = React.useState(false)
  const [error, setError] = React.useState<Error>()
  const [file, setFile] = React.useState<File | undefined>(undefined)

  const inputRef = useRef<HTMLInputElement>(null)

  const handleOnClick = (): void => {
    inputRef.current?.click()
  }

  const handleOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setError(undefined)
    try {
      const theFile = event?.target?.files?.[0]
      if (theFile) {
        const { type } = theFile
        if (theFile.size > MAX_FILE_SIZE_IN_BYTES) {
          throw new Error('File size must be less than 1MB')
        }
        if (type === 'image/jpeg' || type === 'image/png') {
          setFile(theFile)
        } else {
          throw new Error('Unsupported format or size, please try again.')
        }
      }
    } catch (err: unknown) {
      setError(err as Error)
    }
  }

  const handleUpdate = async (): Promise<void> => {
    try {
      setIsBusy(true)
      await uploadTeamAvatar({
        variables: { input: { teamId, avatar: file } },
        awaitRefetchQueries: true,
        refetchQueries: [
          refetchTeamRosterQuery({
            id: teamId,
            metaseasonId: null,
          }),
        ],
      })
      updateResourceImages()
      onClose()
      enqueueSnackbar('Avatar updated successfully', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Failed to update avatar', { variant: 'error' })
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <NxActionSpotModal
      actions={
        <>
          <NxButton label="Cancel" onClick={onClose} variant="text" />
          <NxButton
            disabled={loading}
            label="Update Avatar"
            onClick={handleUpdate}
            variant="primary"
          />
        </>
      }
      content={
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          {file ? (
            <img alt="preview" src={URL.createObjectURL(file)} />
          ) : (
            <Box className={classes.container} onClick={handleOnClick}>
              <IconButton>
                {isBusy ? (
                  <NxTypography>Uploading...</NxTypography>
                ) : (
                  <Box onClick={handleOnClick}>
                    <NxSpot
                      data-testid="upload-avatar-button"
                      domain="uploadDoc"
                      height={60}
                      size="small"
                      variant="secondary"
                      width="60"
                    />
                  </Box>
                )}
              </IconButton>
            </Box>
          )}
          <label htmlFor="contained-button-file">
            <input
              ref={inputRef}
              accept="image/*"
              className={classes.hiddenInput}
              data-testid="upload-avatar-input"
              disabled={isBusy}
              id="contained-button-file"
              name="uploadAvatarInput"
              onChange={handleOnChange}
              type="file"
            />
          </label>
          {error && (
            <Box
              className={classes.errorContainer}
              data-testid="upload-avatar-error"
              mt={3.5}
            >
              <NxTypography colorToken="ColorTextError" variant="body1">
                {error.message}
              </NxTypography>
            </Box>
          )}
        </Box>
      }
      headerNode={
        <Box display="flex" flexDirection="column" textAlign="center">
          {error && (
            <Box mb={1}>
              <NxTypography color="error" variant="body1">
                {error.message}
              </NxTypography>
            </Box>
          )}
          <Box mb={1}>
            <NxTypography variant="h4">Team Avatar</NxTypography>
          </Box>
          <NxTypography variant="body1">JPG, PNG Max 1500 px</NxTypography>
        </Box>
      }
      onClose={onClose}
      open
      showTopRightClose
      size="small"
      spot={<></>}
      subtitle="Choose a new avatar image."
      title="Update Team Avatar"
    />
  )
}
