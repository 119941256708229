import React, { useEffect, useState } from 'react'
import {
  MatchCheckInStatus,
  MatchRescheduleRequestStatus,
  MatchRescheduleResponse,
  MatchStatus,
  UpsertMatchCheckInDocument,
  UpsertMatchCheckInMutation,
  UpsertMatchCheckInMutationVariables,
  useGetCheckInMatchesQuery,
  useGetMetaseasonByIdQuery,
  useTeamQuery,
} from '@plvs/graphql'
import { isNil } from 'ramda'
import {
  Box,
  FullHeightBox,
  Hidden,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { AppPage } from '@plvs/rally/pages/page'
import { MatchCheckInSidebar } from '@plvs/rally/features/match/checkIns/MatchCheckInSidebar'
import { MatchCheckInHeader } from '@plvs/rally/features/match/checkIns/MatchCheckInHeader'
import { MatchCheckInAcknowledgementsStep } from '@plvs/rally/features/match/checkIns/MatchCheckInAcknowledgementsStep'
import { MatchCheckInCheckedInStep } from '@plvs/rally/features/match/checkIns/MatchCheckInCheckedInStep'
import { EmptyPage } from '@plvs/respawn/components/empty'
import { Button, makeStyles, Slide } from '@material-ui/core'
import { CaretLeft } from '@playvs-inc/nexus-icons'
import {
  MatchCheckInMatch,
  MatchCheckInMatchStatus,
  MatchCheckInStatusMap,
} from '@plvs/rally/features/match/checkIns/MatchCheckInTypes'
import { MatchCheckInCheckedInContent } from '@plvs/rally/features/match/checkIns/MatchCheckInCheckedInContent'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { Link } from 'react-router-dom'
import { Path } from '@plvs/const'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { getNow, sortByMaybeScheduledStartsAt } from '@plvs/utils'
import dayjs from 'dayjs'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

export interface MatchCheckInProps {
  matchId?: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: '100vh',
    overflow: 'auto',
    position: 'sticky',
    top: 0,
    paddingRight: theme.spacing(1.5),
    width: '18em',
  },
  gutter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  heroGutter: {
    display: 'flex',
    margin: theme.spacing(2, 2, 5),
    flex: 'auto',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      margin: '0',
    },
  },
  contentBox: {
    background: theme.palette.ColorBackgroundBase,
    padding: theme.spacing(3, 4),
    borderRadius: '24px',
    flex: 'auto',
    width: '100%',
    height: '100%',
  },
}))

enum CheckInStep {
  Acknowledgements = 0,
  CheckedIn = 1,
}

export const MatchCheckIns = ({
  matchId,
}: MatchCheckInProps): React.ReactElement => {
  const [loaded, setLoaded] = useState(false)
  const [selectedMatchId, setSelectedMatchId] = useState(matchId)
  const [matches, setMatches] = useState<MatchCheckInMatch[]>([])
  const [matchStatuses, setMatchStatuses] = useState<MatchCheckInStatusMap>({})
  const [showingMatchSelect, setShowingMatchSelect] = useState(true)
  const [step, setStep] = useState(CheckInStep.Acknowledgements)
  const [now] = useState(getNow({ minuteDifference: -120 }))

  const { contentBox, heroGutter } = useStyles({
    isPlayer: false,
  })

  const { enqueueSnackbar } = useSnackbar()

  const { userId, teamIds, loading: userDataLoading } = useUserIdentityFn()

  const { data, error, loading } = useGetCheckInMatchesQuery({
    variables: {
      isFortnite: false,
      limit: 1000,
      filters: {
        startsAt: {
          after: now,
        },
        teamIds,
        status: [
          MatchStatus.Open,
          MatchStatus.Forfeited,
          MatchStatus.Scheduled,
        ],
      },
    },
    skip: userDataLoading || !teamIds.length,
  })

  const [
    upsertMatchCheckInMutation,
    { loading: isUpsertingMatchCheckIn },
  ] = useMutation<
    UpsertMatchCheckInMutation,
    UpsertMatchCheckInMutationVariables
  >(UpsertMatchCheckInDocument)

  const setStepForCheckInStatus = (status: string | undefined | null): void => {
    if (status === MatchCheckInStatus.Completed) {
      setStep(CheckInStep.CheckedIn)
    } else {
      setStep(CheckInStep.Acknowledgements)
    }
  }

  const setStepForMatchCheckInMatchStatus = (
    status: MatchCheckInMatchStatus | undefined | null
  ): boolean => {
    switch (status) {
      case MatchCheckInMatchStatus.CheckedIn:
      case MatchCheckInMatchStatus.Forfeited:
      case MatchCheckInMatchStatus.OpponentForfeited:
      case MatchCheckInMatchStatus.RescheduleRequested:
      case MatchCheckInMatchStatus.Closed:
        setStep(CheckInStep.CheckedIn)
        return true
      case MatchCheckInMatchStatus.Available:
      default:
        return false
    }
  }

  useEffect(() => {
    if (!loading && data && !loaded) {
      setLoaded(true)

      const filteredMatches = (data?.getMatches?.matches ?? []).filter(
        (match) => {
          return (
            match?.checkInOpensAt &&
            match?.checkInClosesAt &&
            dayjs(match?.checkInOpensAt).isBefore(dayjs()) &&
            dayjs(match?.scheduledStartsAt).isAfter(dayjs())
          )
        }
      )

      const fetchedMatches = sortByMaybeScheduledStartsAt(filteredMatches)

      if (
        !selectedMatchId ||
        isNil(fetchedMatches.find(({ id }) => id === selectedMatchId))
      ) {
        setSelectedMatchId(fetchedMatches[0]?.id ?? '')
      }

      const fetchedMatchStatuses: MatchCheckInStatusMap = {}
      fetchedMatches.forEach((match) => {
        if (match.status === MatchStatus.Forfeited) {
          const currentTeamMatchResult = match?.matchResults?.find(({ team }) =>
            teamIds.includes(team?.id ?? '')
          )
          if (
            (currentTeamMatchResult?.gamesWon ?? 0) >=
            (currentTeamMatchResult?.gamesLost ?? 0)
          ) {
            fetchedMatchStatuses[match.id] =
              MatchCheckInMatchStatus.OpponentForfeited
          } else {
            fetchedMatchStatuses[match.id] = MatchCheckInMatchStatus.Forfeited
          }

          return
        }

        if (
          (match?.matchRescheduleRequests ?? []).filter(
            (request) =>
              request.status === MatchRescheduleRequestStatus.Pending &&
              teamIds.includes(request.teamId)
          ).length > 0
        ) {
          fetchedMatchStatuses[match.id] =
            MatchCheckInMatchStatus.RescheduleRequested
          return
        }

        const isCompleted = match.matchCheckIns.find(
          (checkIn) =>
            teamIds.includes(checkIn.teamId) &&
            checkIn.status === MatchCheckInStatus.Completed
        )
        fetchedMatchStatuses[match.id] = isCompleted
          ? MatchCheckInMatchStatus.CheckedIn
          : MatchCheckInMatchStatus.Available

        if (isCompleted) {
          fetchedMatchStatuses[match.id] = MatchCheckInMatchStatus.CheckedIn
        } else {
          fetchedMatchStatuses[match.id] = MatchCheckInMatchStatus.Available
        }
      })
      setMatchStatuses(fetchedMatchStatuses)

      setMatches(fetchedMatches)
    }
  }, [loading, data])

  const selectedMatch = matches.find((match) => match.id === selectedMatchId)
  const nextMatch = matches.find(
    (match) =>
      match.id !== selectedMatchId &&
      matchStatuses[match.id] === MatchCheckInMatchStatus.Available
  )

  useEffect(() => {
    if (
      !setStepForMatchCheckInMatchStatus(matchStatuses[selectedMatch?.id ?? ''])
    ) {
      setStepForCheckInStatus(
        selectedMatch?.matchCheckIns?.find(({ user }) => user?.id === userId)
          ?.status
      )
    }
  }, [selectedMatch])

  const selectedTeamId =
    selectedMatch?.matchResults?.find(({ team }) => {
      return team && teamIds.includes(team.id)
    })?.team?.id ?? ''

  const metaseasonId = selectedMatch?.slot?.phase?.season?.metaseasonId ?? ''
  const {
    data: metaseasonData,
    loading: metaseasonLoading,
  } = useGetMetaseasonByIdQuery({
    variables: { id: metaseasonId },
    skip: !metaseasonId,
  })

  const metaseasonEndsDate = metaseasonData?.metaseason?.endsAt ?? ''
  const { data: teamData, loading: teamLoading } = useTeamQuery({
    variables: { id: selectedTeamId, date: metaseasonEndsDate },
    skip: !metaseasonEndsDate || !selectedTeamId,
  })

  const opponentId =
    selectedMatch?.matchResults?.find(({ team }) => team?.id !== selectedTeamId)
      ?.team?.id ?? ''
  const { data: opponentData, loading: opponentLoading } = useTeamQuery({
    variables: { id: opponentId, date: metaseasonEndsDate },
    skip: !metaseasonEndsDate || !opponentId,
  })

  const opponentIsCheckedIn =
    selectedMatch?.matchCheckIns?.some(
      ({ teamId, status }) =>
        teamId === opponentId && status === MatchCheckInStatus.Completed
    ) ?? false

  const isCheckedIn =
    matchStatuses[selectedMatch?.id ?? ''] === MatchCheckInMatchStatus.CheckedIn

  const currentMatchCheckInMatchStatus = matchStatuses[selectedMatch?.id ?? '']

  const onSelectMatch = (id: string): void => {
    setShowingMatchSelect(false)
    setSelectedMatchId(id)
  }

  const onMatchForfeit = (): void => {
    if (selectedMatchId) {
      matchStatuses[selectedMatchId] = MatchCheckInMatchStatus.Forfeited
      enqueueSnackbar('Match forfeited.')
      setStep(CheckInStep.CheckedIn)
    }
  }

  const onMatchReschedule = (): void => {
    if (selectedMatchId) {
      matchStatuses[selectedMatchId] =
        MatchCheckInMatchStatus.RescheduleRequested
      enqueueSnackbar('Reschedule requested.')
      setStep(CheckInStep.CheckedIn)
    }
  }

  const onMatchRescheduleRequestRespond = (
    reason: MatchRescheduleResponse
  ): void => {
    if (selectedMatchId) {
      switch (reason) {
        case MatchRescheduleResponse.Approved:
          matchStatuses[selectedMatchId] = MatchCheckInMatchStatus.Rescheduled
          enqueueSnackbar('Match reschedule accepted', { variant: 'success' })
          setStep(CheckInStep.CheckedIn)
          break
        case MatchRescheduleResponse.Denied:
          enqueueSnackbar('Match reschedule denied')
          break
        case MatchRescheduleResponse.Resubmitted:
          matchStatuses[selectedMatchId] =
            MatchCheckInMatchStatus.RescheduleRequested
          enqueueSnackbar('Match reschedule requested')
          setStep(CheckInStep.CheckedIn)
          break
        default:
          break
      }
    }
  }

  const upsertMatchCheckIn = (status: MatchCheckInStatus): Promise<unknown> => {
    if (selectedMatchId && selectedTeamId) {
      return upsertMatchCheckInMutation({
        variables: {
          params: {
            matchId: selectedMatchId,
            teamId: selectedTeamId,
            userId,
            status,
          },
        },
      })
    }
    return Promise.reject()
  }

  const onNext = async (): Promise<void> => {
    switch (step) {
      case CheckInStep.Acknowledgements:
        if (await upsertMatchCheckIn(MatchCheckInStatus.Completed)) {
          setMatchStatuses({
            ...matchStatuses,
            [selectedMatchId ?? '']: MatchCheckInMatchStatus.CheckedIn,
          })
          enqueueSnackbar('Checked in successfully!', { variant: 'success' })
          setStep(CheckInStep.CheckedIn)
        } else {
          enqueueSnackbar(
            'Could not set check-in status. Please try again later.',
            { variant: 'error' }
          )
        }
        break
      case CheckInStep.CheckedIn:
      default:
        break
    }
  }

  const selectNextMatch = (): void => {
    if (nextMatch?.id) {
      onSelectMatch(nextMatch?.id)
      setStep(CheckInStep.Acknowledgements)
    }
  }

  // Handle match expiring while in check-in flow

  // set to any value > 0 when we don't have a match selected
  const getMillisecondsUntilCheckInCloses = (): number =>
    selectedMatch?.checkInClosesAt
      ? new Date(selectedMatch?.checkInClosesAt).getTime() -
        new Date().getTime()
      : 1000

  const [
    millisecondsUntilCheckInCloses,
    setMillisecondsUntilCheckInCloses,
  ] = useState(getMillisecondsUntilCheckInCloses())

  useEffect(() => {
    if (
      getMillisecondsUntilCheckInCloses() < 0 &&
      selectedMatchId &&
      matchStatuses[selectedMatchId] === MatchCheckInMatchStatus.Available
    ) {
      matchStatuses[selectedMatchId] = MatchCheckInMatchStatus.Closed
      setStepForMatchCheckInMatchStatus(MatchCheckInMatchStatus.Closed)
    }
    setMillisecondsUntilCheckInCloses(getMillisecondsUntilCheckInCloses())
  }, [selectedMatch?.id])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMillisecondsUntilCheckInCloses(getMillisecondsUntilCheckInCloses())
      if (
        millisecondsUntilCheckInCloses < 0 &&
        selectedMatchId &&
        matchStatuses[selectedMatchId] === MatchCheckInMatchStatus.Available
      ) {
        matchStatuses[selectedMatchId] = MatchCheckInMatchStatus.Closed
        setStepForMatchCheckInMatchStatus(MatchCheckInMatchStatus.Closed)
      }
    }, 1000)

    return (): void => clearInterval(intervalId)
  }, [millisecondsUntilCheckInCloses])

  const hasMatches = (
    <>
      <MatchCheckInHeader
        isCheckedIn={isCheckedIn}
        isCheckInOpen={millisecondsUntilCheckInCloses > 0}
        match={selectedMatch}
        status={currentMatchCheckInMatchStatus}
      />

      {selectedMatch && !!selectedTeamId && (
        <>
          {step === CheckInStep.Acknowledgements && (
            <MatchCheckInAcknowledgementsStep
              match={selectedMatch}
              onMatchForfeit={onMatchForfeit}
              onMatchReschedule={onMatchReschedule}
              onMatchRescheduleRequestRespond={onMatchRescheduleRequestRespond}
              onNext={onNext}
              teamId={selectedTeamId}
              upsertingMatchCheckIn={isUpsertingMatchCheckIn}
            />
          )}

          {step === CheckInStep.CheckedIn && (
            <MatchCheckInCheckedInStep
              hasNextMatch={!!nextMatch}
              loading={opponentLoading}
              match={selectedMatch}
              onNext={selectNextMatch}
              opponent={opponentData?.team}
              opponentIsCheckedIn={opponentIsCheckedIn}
              status={currentMatchCheckInMatchStatus}
            />
          )}
        </>
      )}
    </>
  )

  const noMatches = <EmptyPage showIcon title="No matches found." />

  const content = matches.length ? hasMatches : noMatches

  const contentWithWrapper = (
    <Box className={heroGutter} position="relative">
      <Hidden mdUp>
        <Box mb={1} mt="16px">
          <Button onClick={(): void => setShowingMatchSelect(true)}>
            <CaretLeft />
            Back to Matches
          </Button>
        </Box>
      </Hidden>
      <Box className={contentBox}>
        <WaitTillLoaded
          loading={
            (!data && !error) || loading || userDataLoading || metaseasonLoading
          }
          showSpinnerWhileLoading
        >
          {matches.length > 0 ? (
            content
          ) : (
            <EmptyPage subtitle="" title="No matches need to be checked into">
              <Button
                color="primary"
                component={Link}
                to={Path.ScheduleUpcoming}
                variant="contained"
              >
                Return to schedule
              </Button>
            </EmptyPage>
          )}
        </WaitTillLoaded>
      </Box>
    </Box>
  )

  const sidebar = (isMobile: boolean): React.ReactElement => (
    <Box position={isMobile && 'absolute'} width={isMobile && '100%'}>
      <MatchCheckInSidebar
        loading={userDataLoading || loading || metaseasonLoading}
        matches={matches}
        matchStatuses={matchStatuses}
        onSelectMatch={onSelectMatch}
        selectedMatchId={selectedMatchId}
      />
    </Box>
  )

  return (
    <Esport slug={selectedMatch?.esport?.slug ?? null}>
      <AppPage title="Check-In">
        <FullHeightBox flexDirection="row">
          <Hidden mdUp>
            <Slide direction="right" in={showingMatchSelect}>
              {sidebar(true)}
            </Slide>
            <Slide direction="left" in={!showingMatchSelect}>
              {contentWithWrapper}
            </Slide>
          </Hidden>
          <Hidden smDown>
            {sidebar(false)}
            <Box width="100%">
              {contentWithWrapper}
              {step === CheckInStep.CheckedIn &&
                selectedMatch &&
                currentMatchCheckInMatchStatus ===
                  MatchCheckInMatchStatus.CheckedIn && (
                  <Box className={heroGutter}>
                    <MatchCheckInCheckedInContent
                      loading={metaseasonLoading || teamLoading}
                      metaseason={metaseasonData?.metaseason}
                      team={teamData?.team}
                    />
                  </Box>
                )}
            </Box>
          </Hidden>
        </FullHeightBox>
      </AppPage>
    </Esport>
  )
}
