import {
  Avatar as MuiAvatar,
  Divider,
  TextField,
  useTheme,
} from '@material-ui/core'
import { SUPPORT_EMAIL, Path, SessionStorageKey } from '@plvs/const'
import {
  GetMyAccountDetailsQuery,
  ResourceType,
  useCanEditAccountQuery,
  useRequestEmailUpdateMutation,
  useRequestEmailVerificationMutation,
  useSetMyAccountSettingsMutation,
} from '@plvs/graphql'
import {
  NxTypography,
  NxButton,
  NxUserCluster,
  NxToggle,
  NxTooltip,
  Pill,
} from '@playvs-inc/nexus-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SchoolOutlined } from '@playvs-inc/nexus-icons'
import { addSchoolButtonClicked } from '@plvs/respawn/features/analytics'
import { Avatar } from '@plvs/rally/components/avatar'
import { isBefore } from 'date-fns'
import { Colors } from '@plvs/rally/themes'
import { useSnackbar } from 'notistack'
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'

import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { assert } from '@plvs/utils'
import { useAccountRenderControllerState } from '@plvs/respawn/renderController/account/AccountRenderControllerProvider'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { ApolloError } from '@apollo/client'
import { Box } from '@plvs/respawn/features/layout/Box'
import { useStyles } from './AccountSettings.styles'
import { AccountSettingsPromptEditPasswordDialog } from './AccountSettingsPromptEditPasswordDialog'
import { ChangePasswordForm } from './ChangePasswordForm'
import { useResourceImageProvider } from '../../../../respawn/src/features/resources/ResourceImageProvider'

interface MinimalSchool {
  id: string | null
  city: string | null
  name: string | null
  state: string | null
  logoUrl: string | null
}

interface Props {
  accountDetailsData: GetMyAccountDetailsQuery | undefined
  accountDetailsError?: ApolloError | undefined
}

export const AccountSettingsAccountInfo: React.FC<Props> = ({
  accountDetailsData,
  accountDetailsError = undefined,
}) => {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const [searchParams] = useSearchParams()

  const isOauthSuccess = searchParams.get('oauthSuccessful')

  const { data: canEditAccountData } = useCanEditAccountQuery()

  const canEdit = canEditAccountData?.canEditAccount?.success ?? false

  const { isFacultyAtOrg, isUnderage } = useUserIdentityFn()

  const [
    requestEmailUpdate,
    { loading: requestEmailUpdateLoading, error: requestEmailUpdateError },
  ] = useRequestEmailUpdateMutation()

  const [setMyAccountMutation] = useSetMyAccountSettingsMutation()
  const [
    requestVerificationEmailMutation,
  ] = useRequestEmailVerificationMutation()

  const [focusedEmailField, setFocusedEmailField] = useState<
    undefined | 'personal' | 'school'
  >()
  const [newEmail, setNewEmail] = useState<string>()
  const [editExpirationDate, setEditExpirationDate] = useState<Date>()
  const [showEnterPasswordPrompt, setShowEnterPasswordPrompt] = useState<
    boolean
  >()
  const [isSendingVerificationEmail, setIsSendingVerificationEmail] = useState(
    false
  )

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)

  useEffect(() => {
    if (canEdit || isOauthSuccess) {
      setIsEditOpen(true)
    }
  }, [canEdit, isOauthSuccess])

  const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState<
    boolean | undefined
  >(accountDetailsData?.me?.isPhoneNumberVisible ?? false)
  const [pendingAddPersonalEmail, setPendingAddPersonalEmail] = useState<
    string | undefined
  >()

  const { getAccountRenderControllerState } = useAccountRenderControllerState()
  const {
    settings: { shouldRenderSchool, shouldRenderUsername },
  } = getAccountRenderControllerState()

  const school: MinimalSchool | undefined | null =
    accountDetailsData?.me?.school
  const { getResourceImageOfSize, updateResources } = useResourceImageProvider()
  updateResources({
    resources: [{ id: school?.id, type: ResourceType.Organization }],
  })
  const schoolLogoUrl = getResourceImageOfSize({
    resource: { id: school?.id, type: ResourceType.Organization },
    size: NxAvatarSize.MD,
  })?.url

  const emails = accountDetailsData?.me?.emails ?? []

  const currentUser = accountDetailsData?.me
  const userAvatarUrl = getResourceImageOfSize({
    resource: {
      id: currentUser?.id,
      type: ResourceType.User,
    },
    size: NxAvatarSize.MD,
  })?.url
  const personalEmail = emails.filter((email) => !email?.isSchoolEmail)[0]
  const schoolEmail = emails.filter((email) => email?.isSchoolEmail)[0]

  const classes = useStyles()

  const navigate = useNavigate()

  const handleOnAddSchoolButtonClick = (): void => {
    addSchoolButtonClicked({
      sourcePage: Path.Settings,
      timeStamp: new Date().toString(),
      userId: accountDetailsData?.me?.id || '',
    })
    sessionStorage.removeItem(SessionStorageKey.OnboardingContext)
    navigate(Path.JoinSchool)
  }

  const handleRequestAccountEditSuccess = (editExpiration?: Date): void => {
    setIsEditOpen(true)
    if (editExpiration) {
      setEditExpirationDate(editExpiration)
    }
    setShowEnterPasswordPrompt(false)
  }

  const onCancelEditEmail = (): void => {
    setFocusedEmailField(undefined)
    setIsEditOpen(false)
  }

  const handleSubmitEmailUpdate = async (): Promise<void> => {
    try {
      assert(focusedEmailField)
      assert(newEmail)
      if (!editExpirationDate || isBefore(editExpirationDate, new Date())) {
        enqueueSnackbar('Please re-enter your password to continue.', {
          variant: 'info',
        })
        setShowEnterPasswordPrompt(true)
        return
      }
      await requestEmailUpdate({
        variables: {
          oldEmail:
            focusedEmailField === 'school'
              ? schoolEmail?.email ?? ''
              : personalEmail?.email ?? '',
          newEmail,
        },
      })

      setFocusedEmailField(undefined)
      setNewEmail(undefined)
      setIsEditOpen(false)
      setPendingAddPersonalEmail(newEmail)

      enqueueSnackbar(
        'An email verification has been sent please verify your email change.',
        {
          variant: 'success',
        }
      )
    } catch (err) {
      enqueueSnackbar('An error has occurred while updating email.', {
        variant: 'error',
      })
    }
  }

  const handleShowPhoneNumberChange = async (): Promise<void> => {
    try {
      await setMyAccountMutation({
        variables: {
          input: {
            isPhoneNumberVisible: !isPhoneNumberVisible,
          },
        },
      })
    } catch (e: any) {
      enqueueSnackbar(
        'An error has occurred while updating your phone settings.',
        {
          variant: 'error',
        }
      )
    }

    setIsPhoneNumberVisible((currentValue) => !currentValue)
  }

  const handleRequestVerificationEmail = async (): Promise<void> => {
    if (personalEmail?.email && accountDetailsData?.me?.id) {
      try {
        const response = await requestVerificationEmailMutation({
          variables: {
            input: {
              email: personalEmail?.email,
              userId: accountDetailsData?.me?.id,
            },
          },
        })

        if (response?.data) {
          enqueueSnackbar('Email sent. Please check your inbox.', {
            variant: 'success',
          })
          setIsSendingVerificationEmail(true)
        }
      } catch (e: any) {
        setIsSendingVerificationEmail(false)
        enqueueSnackbar(
          'An error has occurred while sending verification email.',
          {
            variant: 'error',
          }
        )
      }
    }
  }

  if (accountDetailsError) {
    throw accountDetailsError
  }

  // This should not happen since parent component overqueries,
  // but we'll handle this just in case for unit tests.
  if (!accountDetailsData) {
    return <></>
  }

  const emailErrors = requestEmailUpdateError?.graphQLErrors?.filter(
    (apolloError) => {
      return !!apolloError.extensions?.validationErrors?.newEmail
    }
  )
  const emailErrorMsg = emailErrors?.[0]?.extensions?.validationErrors?.newEmail

  return (
    <>
      <Box data-testid="container" mb={2}>
        {(!isUnderage || shouldRenderUsername) && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              mb={2}
            >
              <Box display="flex" flexDirection="row">
                <Box mr={2}>
                  <NxUserCluster
                    avatarHashId={currentUser?.id ?? ''}
                    avatarUrl={userAvatarUrl ?? ''}
                    title=""
                  />
                </Box>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  flexGrow={1}
                  flexWrap="wrap"
                  gridGap="1em 2em"
                >
                  <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    flexGrow={1}
                    flexShrink={1}
                  >
                    <NxTypography
                      className={classes.emailLabel}
                      variant="subtitle2"
                    >
                      Personal Email
                    </NxTypography>
                    {!isEditOpen && (
                      <Box alignItems="center" display="flex">
                        <NxTypography
                          className={classes.email}
                          data-testid="AccountSettingsAccountInfo_PersonalEmailLabel"
                          variant="body1"
                        >
                          {personalEmail?.activeUpdateRequest?.newEmail ??
                            personalEmail?.email ??
                            pendingAddPersonalEmail ??
                            'No Email Added'}
                        </NxTypography>
                        {shouldRenderUsername && (
                          <>
                            {personalEmail?.isVerified ? null : (
                              <Box ml={1}>
                                <Pill
                                  label="Unverified"
                                  size="small"
                                  variant="warn"
                                />
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    )}
                    {isEditOpen && (
                      <>
                        <TextField
                          className={classes.accountEditInput}
                          data-testid="AccountSettingsAccountInfo_NewPersonalEmailTextField"
                          defaultValue=""
                          disabled={requestEmailUpdateLoading}
                          fullWidth
                          name="email"
                          onChange={(
                            evt: ChangeEvent<HTMLInputElement>
                          ): void => {
                            setNewEmail(evt.currentTarget.value)
                          }}
                          onFocus={(): void => {
                            setFocusedEmailField('personal')
                          }}
                          onKeyPress={(evt: KeyboardEvent): void => {
                            if (evt.key === 'Enter') {
                              handleSubmitEmailUpdate()
                            }
                          }}
                          placeholder={personalEmail?.email}
                          type="text"
                          variant="outlined"
                        />
                        {emailErrorMsg && (
                          <NxTypography
                            colorToken="ColorTextError"
                            variant="body2"
                          >
                            {emailErrorMsg}
                          </NxTypography>
                        )}
                      </>
                    )}
                  </Box>

                  <Box
                    alignItems="center"
                    display="flex"
                    flexBasis="20em"
                    flexShrink={1}
                    justifyContent="flex-end"
                  >
                    {!isEditOpen && (
                      <Box display="flex" flexDirection="row" gridGap="1em">
                        {shouldRenderUsername && (
                          <>
                            {personalEmail?.isVerified ? null : (
                              <NxTooltip
                                arrow
                                placement="bottom"
                                title={
                                  isSendingVerificationEmail
                                    ? 'This email was already sent. Please contact support if you don’t receive it within 15 minutes.'
                                    : ''
                                }
                              >
                                <Box width="100%">
                                  <NxButton
                                    data-testid="AccountSettingsAccountInfo_ResendEmailButton"
                                    disabled={isSendingVerificationEmail}
                                    label="Resend Email"
                                    onClick={handleRequestVerificationEmail}
                                    variant="text"
                                  />
                                </Box>
                              </NxTooltip>
                            )}
                          </>
                        )}
                        <NxTooltip
                          arrow
                          placement="top"
                          title={
                            isUnderage
                              ? 'Only your parent can edit your email or password'
                              : ''
                          }
                        >
                          <div>
                            <NxButton
                              data-testid="AccountSettingsAccountInfo_PersonalEmailEditButton"
                              disabled={isUnderage}
                              label="Edit"
                              onClick={(): void => {
                                if (!isEditOpen) {
                                  setShowEnterPasswordPrompt(true)
                                }
                                setFocusedEmailField('personal')
                              }}
                              variant="secondary"
                            />
                          </div>
                        </NxTooltip>

                        {(!!personalEmail?.activeUpdateRequest ||
                          pendingAddPersonalEmail) && (
                          <Box
                            alignItems="center"
                            bgcolor={
                              theme.palette.ColorBackgroundWarn ??
                              theme.palette.warning.light
                            }
                            borderRadius="2rem"
                            color={
                              theme.palette.ColorTextWarn ??
                              theme.palette.warning.dark
                            }
                            display="flex"
                            flex="0 0 max-content"
                            fontSize="0.5rem"
                            px={2}
                            py={0.5}
                          >
                            <NxTypography variant="overline">
                              Pending verification
                            </NxTypography>
                          </Box>
                        )}
                      </Box>
                    )}
                    {isEditOpen && (
                      <Box display="flex" flexDirection="row" gridGap="1em">
                        <NxButton
                          data-testid="AccountSettingsAccountInfo_EmailSaveButton"
                          disabled={requestEmailUpdateLoading}
                          label="Save"
                          onClick={handleSubmitEmailUpdate}
                          variant="primary"
                        />
                        <NxButton
                          data-testid="AccountSettingsAccountInfo_EmailCancelButton"
                          disabled={requestEmailUpdateLoading}
                          label="Cancel"
                          onClick={onCancelEditEmail}
                          variant="secondary"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider
              className={classes.accountInfoDivider}
              light
              variant="fullWidth"
            />
          </>
        )}

        {shouldRenderSchool && (
          <Box mb={3}>
            {/* School name */}
            <Box>
              <NxTypography
                className={classes.yourSchoolLabel}
                variant="overline"
              >
                Your School
              </NxTypography>
              <NxTypography className={classes.yourSchoolInfo}>
                {school ? (
                  <span className={classes.fontWeightBold}>{school.name}</span>
                ) : (
                  <span className={classes.fontWeightBold}>-</span>
                )}
              </NxTypography>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              gridGap="2em"
              justifyContent="flex-start"
              my={2}
            >
              {/* School Avatar */}
              <Box>
                {school ? (
                  <Avatar
                    borderSize="1px"
                    ringColor={
                      (theme.palette.BorderLight ??
                        theme.palette.divider) as Colors
                    }
                    size="medium"
                    src={schoolLogoUrl}
                  />
                ) : (
                  <MuiAvatar
                    alt="Default school logo"
                    className={classes.defaultSchoolLogo}
                  >
                    <SchoolOutlined
                      className={classes.icon}
                      height={24}
                      width={24}
                    />
                  </MuiAvatar>
                )}
              </Box>

              {/* Email and Buttons */}
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                flexGrow={1}
                flexWrap="wrap"
                gridGap="1em 2em"
              >
                {school ? (
                  <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    flexGrow={1}
                    flexShrink={1}
                  >
                    {/* Show email if not in edit mode */}
                    <NxTypography
                      className={classes.emailLabel}
                      variant="subtitle2"
                    >
                      School Email
                    </NxTypography>
                    {!isEditOpen && (
                      <NxTypography
                        className={classes.email}
                        data-testid="AccountSettingsAccountInfo_SchoolEmailLabel"
                        variant="body1"
                      >
                        {schoolEmail?.activeUpdateRequest?.newEmail ??
                          schoolEmail?.email ??
                          pendingAddPersonalEmail}
                      </NxTypography>
                    )}

                    {/* Show editable text box if in edit mode */}
                    {isEditOpen && (
                      <>
                        <TextField
                          className={classes.accountEditInput}
                          data-testid="AccountSettingsAccountInfo_NewSchoolEmailTextField"
                          defaultValue=""
                          disabled={requestEmailUpdateLoading}
                          fullWidth
                          name="email"
                          onChange={(
                            evt: ChangeEvent<HTMLInputElement>
                          ): void => {
                            setNewEmail(evt.currentTarget.value)
                          }}
                          onFocus={(): void => {
                            setFocusedEmailField('school')
                          }}
                          onKeyPress={(evt: KeyboardEvent): void => {
                            if (evt.key === 'Enter') {
                              handleSubmitEmailUpdate()
                            }
                          }}
                          placeholder={schoolEmail?.email}
                          type="text"
                          variant="outlined"
                        />
                        {emailErrorMsg && (
                          <NxTypography
                            colorToken="ColorTextError"
                            variant="body2"
                          >
                            {emailErrorMsg}
                          </NxTypography>
                        )}
                      </>
                    )}
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    flexGrow={1}
                    flexShrink={1}
                  >
                    <NxTypography
                      className={classes.missingSchoolEmail}
                      data-testid="AccountSettingsAccountInfo_MissingSchoolEmailLabel"
                      variant="body1"
                    >
                      You don&apos;t have a school linked
                    </NxTypography>
                  </Box>
                )}

                {/* Edit/Save/Cancel/Add School buttons for school email */}
                {school ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    gridGap="1em"
                    justifyContent="flex-end"
                  >
                    {!isEditOpen && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        gridGap="1em"
                        justifyContent="flex-end"
                      >
                        <NxTooltip
                          arrow
                          placement="top"
                          title={
                            isUnderage
                              ? 'Only your parent can edit your email or password'
                              : ''
                          }
                        >
                          <div>
                            <NxButton
                              data-testid="AccountSettingsAccountInfo_SchoolEmailEditButton"
                              disabled={isUnderage}
                              label="Edit"
                              onClick={(): void => {
                                if (!isEditOpen) {
                                  setShowEnterPasswordPrompt(true)
                                }
                                setFocusedEmailField('school')
                              }}
                              variant="secondary"
                            />
                          </div>
                        </NxTooltip>

                        {!!schoolEmail?.activeUpdateRequest && (
                          <Box
                            alignItems="center"
                            bgcolor={
                              theme.palette.ColorBackgroundWarn ??
                              theme.palette.warning.light
                            }
                            borderRadius="2rem"
                            color={
                              theme.palette.ColorTextWarn ??
                              theme.palette.warning.dark
                            }
                            display="flex"
                            flex="0 0 max-content"
                            fontSize="0.5rem"
                            px={2}
                            py={0.5}
                          >
                            <NxTypography variant="overline">
                              Pending verification
                            </NxTypography>
                          </Box>
                        )}
                      </Box>
                    )}
                    {isEditOpen && (
                      <Box display="flex" flexDirection="row" gridGap="1em">
                        <NxButton
                          data-testid="AccountSettingsAccountInfo_PersonalEmailSaveButton"
                          disabled={requestEmailUpdateLoading}
                          label="Save"
                          onClick={handleSubmitEmailUpdate}
                          variant="primary"
                        />
                        <NxButton
                          disabled={requestEmailUpdateLoading}
                          label="Cancel"
                          onClick={onCancelEditEmail}
                          variant="secondary"
                        />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box flexBasis="20em" flexShrink={1}>
                    <Box display="flex" flexDirection="row" gridGap="1em">
                      <NxButton
                        data-testid="AccountSettingsAccountInfo_AddSchoolButton"
                        label="Add School"
                        onClick={handleOnAddSchoolButtonClick}
                        variant="secondary"
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>

            <Box display="flex">
              <NxTypography variant="body1">
                If you are transferring schools or colleges{' '}
                <a className={classes.link} href={`mailto:${SUPPORT_EMAIL}`}>
                  contact us
                </a>{' '}
                to link another school to your account
              </NxTypography>
            </Box>
          </Box>
        )}
      </Box>

      {shouldRenderSchool && (
        <Divider
          className={classes.accountInfoDivider}
          id="divider"
          light
          variant="fullWidth"
        />
      )}

      <NxTypography className={classes.subTitle}>Password</NxTypography>
      <ChangePasswordForm
        isEditOpen={isEditOpen}
        isUnderage={isUnderage}
        onCancel={onCancelEditEmail}
        onEdit={setShowEnterPasswordPrompt}
      />

      {showEnterPasswordPrompt && (
        <AccountSettingsPromptEditPasswordDialog
          isChild={isUnderage}
          onClose={(): void => {
            setShowEnterPasswordPrompt(false)
          }}
          onSuccess={handleRequestAccountEditSuccess}
        />
      )}

      {isFacultyAtOrg && (
        <Box data-testid="AccountSetting_PhoneNumber_Container" mt={5}>
          <NxTypography className={classes.emailLabel} variant="h4">
            Phone Number
          </NxTypography>
          <Box
            alignItems="flex-end"
            display="flex"
            flexDirection="row"
            flexGrow={1}
            gridGap="1em 2em"
            mt={3}
          >
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <NxTypography className={classes.emailLabel} variant="subtitle2">
                Phone Number
              </NxTypography>
              <NxTypography variant="body1">
                Hide my phone number from others
              </NxTypography>
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="flex-end">
              <NxToggle
                checked={!isPhoneNumberVisible}
                disabled={false}
                name="phoneNumberVisible"
                onChange={handleShowPhoneNumberChange}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
