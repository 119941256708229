import React from 'react'

import {
  NxEsportBanner,
  PersonaImageVariant,
  Pill,
} from '@playvs-inc/nexus-components'

import { EsportSlug, MatchStatus } from '@plvs/graphql'
import { MatchHeroContainer } from '@plvs/rally/containers/match/MatchLobbyHeroContainers/MatchHeroContainer'
import { Box } from '@plvs/respawn/features/layout'
import { HeroMatch } from '@plvs/respawn/features/match-lobby/types/Lobby.types'

interface MatchHeroProps {
  isScrimmage: boolean
  esportSlug: EsportSlug | null | undefined
  match: HeroMatch
  breadcrumbHref?: string
}

export const MatchHero: React.FC<MatchHeroProps> = ({
  isScrimmage,
  esportSlug,
  match,
  breadcrumbHref,
}) => {
  const container = (
    <MatchHeroContainer
      breadcrumbHref={breadcrumbHref}
      esportSlug={esportSlug}
      match={match}
      size="large"
    />
  )

  const isByeMatch = match?.status === MatchStatus.Bye

  return (
    <>
      {esportSlug && (
        <Box mb={2} pr={2} pt={2}>
          <NxEsportBanner
            additionalContent={
              <Box ml={3} pr={4} width="100%">
                {isByeMatch ? (
                  <Pill label="Bye" size="large" variant="misc" />
                ) : (
                  container
                )}
              </Box>
            }
            esportSlug={esportSlug}
            size="small"
            useOverlayImage
            variant={
              isScrimmage
                ? PersonaImageVariant.Scrimmage
                : PersonaImageVariant.Match
            }
          />
        </Box>
      )}
    </>
  )
}
