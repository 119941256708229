import { Box } from '@plvs/respawn/features/layout'
import { Callout } from '@plvs/rally/components/callout'
import React from 'react'

interface Props {
  numCoaches: number
}

export const CoachCallout: React.FC<Props> = ({ numCoaches }) => {
  if (numCoaches > 0) {
    return null
  }

  return (
    <Box pt={4}>
      <Callout>
        Your school still needs a coach, but don&apos;t worry, we&apos;re here
        to help! Contact our support team for all the resources you&apos;ll need
        to quickly bring esports to your school, including help finding a coach.
      </Callout>
    </Box>
  )
}
