import React from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import { useTheme } from '@material-ui/core'

type Size = 'tiny' | 'small' | 'medium'
type Location = 'left' | 'right'

const containerStyle: Record<
  Size,
  Pick<React.CSSProperties, 'bottom' | 'height' | 'left' | 'position' | 'width'>
> = {
  tiny: {
    bottom: -3,
    position: 'absolute',
    height: 22,
    width: 22,
  },
  small: {
    bottom: -7,
    position: 'absolute',
    height: 22,
    width: 22,
  },
  medium: {
    bottom: -7,
    position: 'absolute',
    height: 24,
    width: 24,
  },
}

const iconStyle: Record<Size, Pick<React.CSSProperties, 'height' | 'width'>> = {
  tiny: {
    height: 8,
    width: 8,
  },
  small: {
    height: 16,
    width: 16,
  },
  medium: {
    height: 24,
    width: 24,
  },
}

const getLocationValue = (location: Location, size: Size): number => {
  if (location === 'right') return -7
  switch (size) {
    case 'tiny':
      return 5
    case 'small':
      return -3
    default:
      return -8
  }
}

export const ErrorBadge: React.FC<{
  size?: Size
  location?: Location
}> = ({ size = 'small', location = 'right' }) => {
  const theme = useTheme()

  const sharedIconStyle = {
    color: theme.palette.ColorIconError,
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: '50%',
  }

  return (
    <div
      data-testid="ErrorBadge__Container"
      style={{
        ...containerStyle[size],
        [location]: getLocationValue(location, size),
      }}
    >
      <ErrorIcon
        data-testid="ErrorBadge__Icon"
        style={{ ...iconStyle[size], ...sharedIconStyle }}
      />
    </div>
  )
}
