import {
  useCreateSlotExclusionWindowMutation,
  useDeleteSlotExclusionWindowMutation,
  useUpdateSlotExclusionWindowMutation,
  useGetMySchoolSlotExclusionDataQuery,
  useGetTeamsEnrolledSeasonIdsQuery,
} from '@plvs/graphql'
import { asyncSeries, useAutoskipQuery } from '@plvs/utils'
import { flatten, map, range } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  NO_BREAK_WEEK_HASH,
  getFinalSlotExclusionsSelections,
  getFormattedWeekInterval,
  getOptionalSeasonWeeksMap,
  NO_BREAK_WEEK_SLOT_EXCLUSION_SUGGESTION,
} from '@plvs/respawn/containers/enrollment/enrollmentHelpers'
import {
  FinalSelectionsToCreate,
  OptionalWeeksCache,
  SelectionObj,
  UseSlotExclusionsOptions,
  UseSlotExclusions,
  OptionalSlotExclusionRangeData,
} from '@plvs/respawn/containers/enrollment/types'

export const useSlotExclusionsData = ({
  metaseasonId,
  schoolId,
}: UseSlotExclusionsOptions): UseSlotExclusions => {
  const [cachedMetaseasonId, setCachedMetaseasonId] =
    useState<string>(metaseasonId)
  const [enrollmentSeasonIds, setEnrollmentSeasonIds] = useState<string[]>([])
  const [optionalWeeksCache, setOptionalWeeksCache] =
    useState<OptionalWeeksCache>({})

  const [optionalSeasonWeeksCache, setOptionalSeasonWeeksCache] =
    useState<OptionalWeeksCache>({})

  const [error, setError] = useState<Error>()
  const skipQuery = !(cachedMetaseasonId.length && schoolId)

  const {
    data,
    loading,
    error: queryError,
  } = useAutoskipQuery(useGetMySchoolSlotExclusionDataQuery, {
    variables: {
      input: {
        metaseasonId: cachedMetaseasonId,
        organizationId: schoolId,
      },
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    skip: skipQuery,
  })

  const {
    data: teamsEnrolledSeasonIdsData,
    loading: teamsEnrolledSeasonIdsLoading,
  } = useGetTeamsEnrolledSeasonIdsQuery({
    variables: { schoolId: schoolId || '' },
    skip: !schoolId,
  })

  useEffect(() => {
    if (
      teamsEnrolledSeasonIdsLoading ||
      !teamsEnrolledSeasonIdsData?.school?.teams?.length
    ) {
      return
    }
    const enrolledSeasonIds = teamsEnrolledSeasonIdsData.school.teams
      .flatMap((teamData) => {
        return teamData.enrolledSeasons?.map((season) => season.id)
      })
      .filter(Boolean) as string[]

    setEnrollmentSeasonIds([...enrollmentSeasonIds, ...enrolledSeasonIds])
  }, [teamsEnrolledSeasonIdsData, teamsEnrolledSeasonIdsLoading])

  const [createSlotExclusion] = useCreateSlotExclusionWindowMutation()
  const [updateSlotExclusionWindow] = useUpdateSlotExclusionWindowMutation()
  const [deleteSlotExclusionWindow] = useDeleteSlotExclusionWindowMutation()

  const enrollmentSlotExclusionData =
    data?.organizationEnrollmentData?.data?.slotExclusionData

  const seasonEnrollmentSlotExclusionData =
    data?.organizationEnrollmentData?.data?.seasonSlotExclusionData
  const seasonRangeData = useMemo(() => {
    return seasonEnrollmentSlotExclusionData?.rangeData ?? []
  }, [seasonEnrollmentSlotExclusionData])

  const seasonSlotExclusionRangeData = useMemo(() => {
    return seasonRangeData
      .map((datum) => {
        const suggestions = datum.suggestionsForOrganization.map((s, i) => ({
          isAvailable: s.isAvailable,
          startsAt: s.suggestion.template.startsAt,
          endsAt: s.suggestion.template.endsAt,
          hash: s.suggestion.template.hash,
          title: getFormattedWeekInterval({
            weekNumber: i + 1,
            startsAt: s.suggestion.template.startsAt,
            endsAt: s.suggestion.template.endsAt,
          }),
        }))

        suggestions.unshift(NO_BREAK_WEEK_SLOT_EXCLUSION_SUGGESTION)
        const selections = datum.selectionData.selections.map(
          ({ slotExclusionWindow, isEditable }) => ({
            ...slotExclusionWindow,
            isEditable,
          })
        )
        return {
          configuration: {
            ...datum.configuration,
            suggestions,
          },
          seasonId: datum.seasonId || '',
          leagueName: datum.season?.league?.name || '',
          resourceId: datum.selectionData.resourceId,
          resourceType: datum.selectionData.resourceType,
          selections,
          range: `${datum.range.startsAt}-${datum.range.endsAt}`,
        }
      })
      .filter(
        ({ seasonId }) => !!seasonId && enrollmentSeasonIds.includes(seasonId)
      )
  }, [seasonRangeData, enrollmentSeasonIds])

  const rangeData = useMemo(() => {
    return enrollmentSlotExclusionData?.rangeData ?? []
  }, [enrollmentSlotExclusionData])

  const slotExclusionSuggestionsFlattened = flatten(
    map((datum) => datum.suggestionsForOrganization, rangeData)
  )

  const slotExclusionSuggestions = slotExclusionSuggestionsFlattened.map(
    (s, i) => ({
      isAvailable: s.isAvailable,
      startsAt: s.suggestion.template.startsAt,
      endsAt: s.suggestion.template.endsAt,
      hash: s.suggestion.template.hash,
      title: getFormattedWeekInterval({
        weekNumber: i + 1,
        startsAt: s.suggestion.template.startsAt,
        endsAt: s.suggestion.template.endsAt,
      }),
    })
  )

  slotExclusionSuggestions.unshift(NO_BREAK_WEEK_SLOT_EXCLUSION_SUGGESTION)

  const slotExclusionSelectionsFlattened = flatten(
    map(
      (selection) => selection.selections,
      rangeData.map((datum) => datum.selectionData)
    )
  )
  const slotExclusionSelections = slotExclusionSelectionsFlattened.map(
    ({ slotExclusionWindow, isEditable }) => ({
      ...slotExclusionWindow,
      isEditable,
    })
  )

  const slotExclusionRangeData = rangeData.map((datum) => ({
    configuration: {
      ...datum.configuration,
      suggestions: slotExclusionSuggestions,
    },
    resourceId: datum.selectionData.resourceId,
    resourceType: datum.selectionData.resourceType,
    selections: slotExclusionSelections,
    range: `${datum.range.startsAt}-${datum.range.endsAt}`,
  }))

  const optionalWeeksMap: OptionalWeeksCache = {}

  slotExclusionRangeData.forEach((rd) => {
    const {
      range: key,
      resourceId,
      resourceType,
      configuration,
      selections,
    } = rd
    if (!optionalWeeksMap[key]) {
      optionalWeeksMap[key] = {
        resourceType,
        resourceId,
        selections: range(0, configuration.maxSlotExclusions).map((i) => {
          const confirmedSelection = selections[i]
          let initializedSelection = confirmedSelection
            ? {
                ...confirmedSelection,
                formattedSelection:
                  configuration.suggestions.find(
                    (suggestion) => suggestion.hash === confirmedSelection?.hash
                  )?.title ?? 'TBD',
              }
            : null
          if (!initializedSelection) {
            initializedSelection = NO_BREAK_WEEK_SLOT_EXCLUSION_SUGGESTION
          }
          return {
            confirmedSelection: initializedSelection,
            selection: initializedSelection,
          }
        }),
      }
    }
  })

  const optionalSeasonWeeksMap = useMemo(() => {
    return getOptionalSeasonWeeksMap(
      seasonSlotExclusionRangeData as OptionalSlotExclusionRangeData[]
    )
  }, [seasonSlotExclusionRangeData])

  const addEnrollmentSeasonIds = useCallback(
    (seasonIds: string[]): void => {
      setEnrollmentSeasonIds((ids) => [...ids, ...seasonIds])
    },
    [setEnrollmentSeasonIds]
  )

  const removeEnrollmentSeasonId = useCallback(
    (seasonId: string): void => {
      setEnrollmentSeasonIds((ids) => {
        const index = ids.findIndex((id) => id === seasonId)
        if (index !== -1) {
          ids.splice(index, 1)
          return [...ids]
        }
        return [...ids]
      })
    },
    [setEnrollmentSeasonIds]
  )

  const updateSlotExclusionSelection =
    useCallback(async (): Promise<boolean> => {
      const {
        slotExclusionsToCreate,
        slotExclusionsToUpdate,
        slotExclusionsToDelete,
      } = getFinalSlotExclusionsSelections({
        cache: {
          ...optionalWeeksCache,
          ...{ ...optionalSeasonWeeksMap, ...optionalSeasonWeeksCache },
        },
      })
      try {
        if (slotExclusionsToCreate.length) {
          const createResults = await asyncSeries<
            boolean,
            FinalSelectionsToCreate
          >(
            async ({ resourceId, resourceType, selection, seasonId }) => {
              if (selection?.hash === NO_BREAK_WEEK_HASH) {
                return true
              }
              const result = await createSlotExclusion({
                variables: {
                  input: {
                    resourceId,
                    resourceType,
                    startsAt: selection!.startsAt!,
                    endsAt: selection!.endsAt!,
                    seasonId,
                  },
                },
              })
              return !!result.data?.createSlotExclusionWindow
                .slotExclusionWindow.id
            },
            slotExclusionsToCreate,
            false
          )
          if (createResults.some((id) => !id)) {
            throw new Error('Unable to opt-out of the selected break week.')
          }
        }

        if (slotExclusionsToUpdate.length) {
          const updateResults = await asyncSeries<boolean, SelectionObj>(
            async ({ selection, confirmedSelection }) => {
              if (selection?.hash === NO_BREAK_WEEK_HASH) {
                return true
              }
              const result = await updateSlotExclusionWindow({
                variables: {
                  id: confirmedSelection?.id ?? '',
                  attributes: {
                    startsAt: selection?.startsAt ?? '',
                    endsAt: selection?.endsAt ?? '',
                  },
                },
              })
              return !!result.data?.updateSlotExclusionWindow
                .slotExclusionWindow?.id
            },
            slotExclusionsToUpdate,
            false
          )

          if (updateResults.some((id) => !id)) {
            throw new Error('Unable to update the selected break week.')
          }
        }

        if (slotExclusionsToDelete.length) {
          const deleteResults = await asyncSeries<boolean, string>(
            async (id) => {
              const result = await deleteSlotExclusionWindow({
                variables: {
                  id,
                },
              })
              return !!result.data?.deleteSlotExclusionWindow
                .slotExclusionWindow?.id
            },
            slotExclusionsToDelete,
            false
          )

          if (deleteResults.some((id) => !id)) {
            throw new Error('Unable to update the selected break week.')
          }
        }

        return true
      } catch (e: any) {
        return false
      }
    }, [
      cachedMetaseasonId,
      schoolId,
      optionalWeeksCache,
      optionalSeasonWeeksCache,
    ])

  useEffect(() => {
    if (cachedMetaseasonId !== metaseasonId) {
      setCachedMetaseasonId(metaseasonId)
    }
  }, [metaseasonId])

  useEffect(() => {
    if (queryError) {
      setError(queryError)
    }
  }, [queryError])

  useEffect(() => {
    setOptionalWeeksCache(optionalWeeksMap)
  }, [rangeData])
  useEffect(() => {
    setOptionalSeasonWeeksCache(optionalSeasonWeeksMap)
  }, [seasonSlotExclusionRangeData])

  return {
    error,
    loading: loading || teamsEnrolledSeasonIdsLoading,
    optionalSeasonWeeksCache,
    optionalWeeksCache,
    seasonSlotExclusionRangeData,
    addEnrollmentSeasonIds,
    removeEnrollmentSeasonId,
    setOptionalSeasonWeeksCache,
    setOptionalWeeksCache,
    slotExclusionRangeData,
    updateSlotExclusionSelection,
    numSchoolTeams: teamsEnrolledSeasonIdsData?.school?.teams?.length ?? 0,
  }
}
