import { Grid, makeStyles } from '@material-ui/core'
import {
  Box,
  useBreakpointSm,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import React from 'react'
import {
  NxTypography,
  NxPlayVsBanner,
  NxButton,
  PersonaImageVariant,
  NxSearchBox,
} from '@playvs-inc/nexus-components'
import '@playvs-inc/nexus-theme'
import { EsportsResourcesContainer } from '@plvs/respawn/features/resources/EsportsResourcesContainer'
import { TalkToSomeoneContainer } from '@plvs/rally/containers/resources/TalkToSomeoneContainer'
import { CoachHandbook } from '@plvs/rally/containers/resources/CoachHandbook'
import { VideoTutorialsContainer } from '@plvs/respawn/features/resources/VideoTutorialsContainer'

import { ProgramChecklist } from '@plvs/rally/containers/resources/ProgramChecklist/ProgramChecklist'
import {
  useAvailableLeagueEsportsForSchoolQuery,
  useGetChecklistQuery,
} from '@plvs/graphql/generated'
import {
  useSelectedOrganizationFn,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import {
  getAvailableEsportSlugsAndNames,
  getUniqueEsportsById,
} from './resourceUtils'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
  },
  titleText: {
    marginBottom: theme.spacing(2),
  },
  inputWrapper: {
    maxWidth: '684px',
    '& > div:nth-child(1)': {
      '& > div:nth-child(1)': {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        '& > div:nth-child(1)': {
          color: theme.palette.common.black,
        },
      },
    },
    '& fieldset': {
      borderWidth: '1px !important',
    },
    '& input': {
      paddingBottom: `${theme.spacing(1) + 1}px !important`,
    },
  },
  articlesButton: {
    color: theme.palette.OverlayColorOpWhite1,
    margin: theme.spacing(1, 12, 0, 0),
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
      margin: theme.spacing(2, 0, 0),
    },
  },
  headerContainer: {
    width: '100%',
    marginTop: '-40px',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginTop: 'unset',
    },
  },
  titleSelectSpacing: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0),
    },
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      alignItems: 'flex-start',
    },
  },
  startAdornment: {
    color: theme.palette.common.black,
    '& svg': {
      color: theme.palette.common.black,
    },
  },
}))

export const ResourcesWrapper: React.FC = () => {
  const classes = useStyles()
  const isMobileView = useBreakpointSm()
  const { id: orgId } = useSelectedOrganizationFn()
  const { userId, isCoachAtOrg } = useUserIdentityFn()
  const { data, loading } = useGetChecklistQuery({
    variables: { schoolId: orgId },
    skip: !orgId,
  })
  const {
    data: esportsSlugsData,
    loading: esportsLoading,
  } = useAvailableLeagueEsportsForSchoolQuery({
    variables: { schoolId: orgId },
    skip: !orgId,
  })

  const esports = getUniqueEsportsById(
    esportsSlugsData?.school?.availableLeagueEsports ?? []
  )

  const {
    allAvailableEsportsSlugs,
    allAvailableEsportsNames,
  } = getAvailableEsportSlugsAndNames(esports)

  const checklistGroup = data?.getChecklist?.groups

  const searchIntercom = (searchText: string): void => {
    const searchQuery = searchText.split(' ').join('+')

    window.open(`https://help.playvs.com/en/?q=${searchQuery}`, '_blank')
  }

  const showAllArticles = (): void => {
    window.open('https://help.playvs.com/en/', '_blank')
  }

  return (
    <Box className={classes.container} display="flex" flexDirection="column">
      <NxPlayVsBanner
        customContent={
          <Box className={classes.searchContainer}>
            <NxTypography
              className={classes.titleText}
              colorToken="OverlayColorTextBase"
              variant={isMobileView ? 'display3' : 'display'}
            >
              How can we help?
            </NxTypography>
            <NxTypography
              className={classes.titleText}
              colorToken="OverlayColorTextBase"
              variant={isMobileView ? 'body1' : 'subtitle1'}
            >
              Learn everything from rulebooks, online tutorials to esport
              program help.
            </NxTypography>
            <Box
              className={classes.inputWrapper}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <NxSearchBox
                buttonLabel="Search"
                onSubmitSearch={searchIntercom}
                placeholderText="Search articles and resources"
                startAdornmentClassName={classes.startAdornment}
              />
              <NxButton
                className={classes.articlesButton}
                label="Browse All Articles"
                onClick={showAllArticles}
                variant="text"
              />
            </Box>
          </Box>
        }
        hasNoTopNavBar={isMobileView}
        size="large"
        title=""
        variant={PersonaImageVariant.Resources}
      />
      {isCoachAtOrg && (
        <Grid className={classes.headerContainer} container>
          <Grid item lg={7} xs={12}>
            <Box className={classes.titleSelectSpacing}>
              <TalkToSomeoneContainer />
            </Box>
          </Grid>
          <Grid item lg={5} xs={12}>
            <CoachHandbook />
          </Grid>
        </Grid>
      )}

      <Box mt={isCoachAtOrg ? 0 : 5}>
        {allAvailableEsportsSlugs && allAvailableEsportsSlugs.length > 0 && (
          <EsportsResourcesContainer
            allAvailableEsports={allAvailableEsportsSlugs}
          />
        )}
      </Box>

      {isCoachAtOrg && (
        <WaitTillLoaded
          loading={loading || esportsLoading}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          <ProgramChecklist
            allAvailableEsportsNames={allAvailableEsportsNames}
            checklist={checklistGroup}
            schoolId={orgId}
            userId={userId}
          />
        </WaitTillLoaded>
      )}
      <VideoTutorialsContainer />
    </Box>
  )
}
