import { Hidden, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import {
  NxButton,
  NxModal,
  NxTypography,
  Pill,
} from '@playvs-inc/nexus-components'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { CompetitionGroup } from '@plvs/graphql/generated'
import { CoachLeadComponent } from '@plvs/rally/features/coach/coachLeadGen/CoachLeadComponent'
import {
  CompetitionPillLabel,
  CompetitionPillVariant,
} from '@plvs/rally/features/coach/coachLeadGen/SchoolLeadUtils'
import clsx from 'clsx'
import React, { useState } from 'react'

export const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  referButton: {
    marginLeft: theme.spacing(2),
    minWidth: 'max-content',
    maxHeight: theme.spacing(4.5),
  },
  schoolTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // For two line wrap before ellipsis overflow
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  pill: {
    width: 'max-content',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  form: {
    marginBottom: 0,
  },
}))

export const DashboardInviteSchoolItem: React.FC<{
  id: string
  name: string
  state: string
  city: string
  isFacultyAtOrg: boolean
  isMobile: boolean
  competitionGroup?: CompetitionGroup | null
}> = ({
  id,
  name,
  state,
  city,
  isFacultyAtOrg,
  isMobile,
  competitionGroup,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const pillLabel = competitionGroup && CompetitionPillLabel[competitionGroup]
  const pillVariant =
    competitionGroup && CompetitionPillVariant[competitionGroup]

  const handleModalClose = (): void => setOpen(false)
  const handleModalOpen = (): void => setOpen(true)

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      mb={3}
    >
      <Box display="flex" flexDirection="row">
        <Box mr={2}>
          <NxSpot
            domain="school"
            height={41}
            size="small"
            variant="primary"
            width={41}
          />
        </Box>
        <Box>
          {/* Used hidden because nxtypography was not updating variants at breakpoints */}
          <Hidden smUp>
            <Pill
              className={classes.pill}
              label={pillLabel}
              size="small"
              variant={pillVariant}
            />
          </Hidden>
          <Hidden smUp>
            <NxTypography
              className={clsx(classes.textColor, classes.schoolTitle)}
              data-testid="school-name-small"
              variant="body2"
            >
              {name}
            </NxTypography>
          </Hidden>
          <Hidden xsDown>
            <NxTypography
              className={clsx(classes.textColor, classes.schoolTitle)}
              data-testid="school-name-large"
              variant="h4"
            >
              {name}
            </NxTypography>
          </Hidden>
          <NxTypography
            className={classes.textColor}
            variant="body1"
          >{`${city}, ${state}`}</NxTypography>
        </Box>
      </Box>

      <Box alignItems="center" display="flex" flexDirection="row">
        {pillLabel && (
          <Hidden xsDown>
            <Pill
              className={classes.pill}
              data-testid="competition-pill-small"
              label={pillLabel}
              size="small"
              variant={pillVariant}
            />
          </Hidden>
        )}
        <NxButton
          className={clsx(classes.textColor, classes.referButton)}
          label={isMobile ? 'Refer' : 'Refer School'}
          onClick={handleModalOpen}
          variant="secondary"
        />
      </Box>

      <NxModal
        onClose={handleModalClose}
        open={open}
        showTopRightClose
        subtitle="Refer a coach at another school to get them started on PlayVS."
        title="Refer a School Near You"
      >
        <CoachLeadComponent
          className={classes.form}
          gridSpacing={1}
          hideModalPadding
          isBorderless
          isFaculty={isFacultyAtOrg}
          isModalView
          onBackButtonClick={handleModalClose}
          school={{ id, state, name }}
          setAvailableSchool
          showBackButton
          source={
            isFacultyAtOrg
              ? 'faculty-global-coach-lead'
              : 'student-global-coach-lead'
          }
        />
      </NxModal>
    </Box>
  )
}
