import React from 'react'
import {
  useGetLeagueByPublicSlugQuery,
  useGetLeagueSeasonDataQuery,
} from '@plvs/graphql/generated'
import { LeagueStandings } from '../LeagueStandings/LeagueStandings'
import { useLeagueDetails } from './LeagueDetailsProvider'

export const LeagueDetailsStandings: React.FC = () => {
  const { phase, metaseasonId, publicSlug } = useLeagueDetails()

  const { data } = useGetLeagueByPublicSlugQuery({
    skip: !publicSlug,
    variables: { publicSlug },
  })

  const esportSlug = data?.getLeagueByPublicSlug?.esport?.slug
  const leagueId = data?.getLeagueByPublicSlug?.id ?? ''

  const { data: leagueSeasonData } = useGetLeagueSeasonDataQuery({
    skip: !leagueId || !metaseasonId,
    variables: { leagueId, metaseasonId },
  })

  const season = leagueSeasonData?.league?.seasonByMetaseasonId

  return (
    <LeagueStandings
      esportSlug={esportSlug}
      phase={phase}
      seasonId={season?.id ?? ''}
    />
  )
}
