import React, { createContext, useContext } from 'react'

import {
  EnrollmentStatus,
  UseEnrollmentReturn,
} from '@plvs/respawn/containers/enrollment/types'
import { useEnrollment } from './useEnrollment'

export const EnrollmentContext = createContext<UseEnrollmentReturn>({
  changeCompetitionModel: () => {},
  changeEnrollment: async () => false,
  annualPassInstanceMetaseasonId: '',
  hasTeamUpdates: false,
  competitionModel: null,
  competitionModels: [],
  enrolledPlayers: [],
  enrolledPlayersOnTeam: [],
  enrolledPlayersNotOnTeam: [],
  numberOfPassesUsed: 0,
  enrolledTeamIds: [],
  enrolledTeamsCount: 0,
  enrolledSeasonIds: new Set(),
  error: undefined,
  esports: [],
  leagueEsports: [],
  hasAnnualPassInstance: false,
  currentAnnualPassInstance: undefined,
  ineligibleLeagues: [],
  leagues: [],
  loading: false,
  onProductSelected: () => {},
  onPlayersSelected: () => {},
  products: [],
  product: undefined,
  purchaseProduct: async () => false,
  refreshPlayers: async () => {},
  season: undefined,
  schoolId: '',
  schoolName: '',
  schoolLogo: '',
  status: EnrollmentStatus.NotInitialized,
  hasEnrolledTeams: false,
  presubmitTeamsToEnroll: [],
})

export const useEnrollmentProvider = (): UseEnrollmentReturn =>
  useContext(EnrollmentContext)

interface Props {
  isCoach: boolean
  metaseasonId: string
  schoolId: string
  includeOrganizationEnrollment?: boolean
  children: React.ReactNode
}

export const EnrollmentProvider: React.FC<Props> = ({
  isCoach,
  metaseasonId,
  schoolId,
  includeOrganizationEnrollment = false,
  children,
}) => {
  const enrollment = useEnrollment({
    isCoach,
    metaseasonId,
    schoolId,
    includeOrganizationEnrollment,
  })

  return (
    <EnrollmentContext.Provider value={enrollment}>
      {children}
    </EnrollmentContext.Provider>
  )
}
