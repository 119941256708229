import React from 'react'
import { NxTooltip, NxTypography } from '@playvs-inc/nexus-components'
import { defaultTo0, Placing } from '@plvs/utils'
import { MappedMatchResult } from '@plvs/rally/features/match/schedule/schedule'
import { MatchResults } from '../MatchResults'

interface Props {
  isLeaderboard: boolean
  myPlacing?: number | null
  team1Result?: MappedMatchResult
  team2Result?: MappedMatchResult
}

export const MatchRowResults: React.FC<Props> = ({
  isLeaderboard,
  myPlacing,
  team1Result,
  team2Result,
}) => {
  if (isLeaderboard) {
    return (
      <>
        {myPlacing ? (
          <NxTooltip arrow placement="top" title="Your Placement">
            <div>
              <NxTypography variant="h4">{Placing(myPlacing)}</NxTypography>
            </div>
          </NxTooltip>
        ) : (
          <NxTypography variant="subtitle2">VS</NxTypography>
        )}
      </>
    )
  }

  return (
    <MatchResults
      leftTeam={{
        score: defaultTo0(team1Result?.gamesWon),
        placing: defaultTo0(team1Result?.placing),
      }}
      rightTeam={{
        score: defaultTo0(team2Result?.gamesWon),
        placing: defaultTo0(team2Result?.placing),
      }}
    />
  )
}
