import { MatchFormat, MatchStatus } from '@plvs/graphql/generated'
import { head } from 'ramda'

export type GenerateMatchTitleProps = {
  hasUserRole: boolean
  otherTeams: {
    id: string
    name: string | null
  }[]
  myTeam:
    | {
        id: string
        name: string | null
      }
    | undefined
  teams: {
    id: string
    name: string | null
  }[]
  matchFormat: MatchFormat | null
}
export function generateMatchTitle({
  hasUserRole,
  otherTeams,
  myTeam,
  teams,
  matchFormat,
}: GenerateMatchTitleProps): string {
  if (matchFormat === MatchFormat.Leaderboard) {
    return `(Match) ${myTeam?.name ?? 'My Team'} vs. Others`
  }

  if (hasUserRole) {
    const opponentTeamName = head(otherTeams)?.name ?? 'Opponent'
    return `(Match) ${myTeam?.name} vs. ${opponentTeamName}`
  }
  const homeTeamName = teams[0]?.name ?? 'Home Team'
  const awayTeamName = teams[1]?.name ?? 'Away Team'
  return `(Match) ${homeTeamName} vs ${awayTeamName}`
}

export const HOURS_FOR_MATCH_BANNER = 4

export const shouldShowMatchQueueBanner = (
  matchStatus: MatchStatus | undefined,
  hasRoleForSomeResourceIdsReturn: boolean,
  timeSinceStart: number
): boolean => {
  return (
    (matchStatus === MatchStatus.Completed ||
      matchStatus === MatchStatus.Forfeited) &&
    hasRoleForSomeResourceIdsReturn &&
    timeSinceStart < HOURS_FOR_MATCH_BANNER
  )
}
