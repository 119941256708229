import {
  ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS,
  getContextualResourceIdsFromTeams,
  hasRoleForResource,
  isAdminForSystem,
  isCaptainForResource,
  isPlayerForResource,
  isTeamOwnerForResource,
} from '@plvs/utils'
import { ChatRole, MatchStatus, UserRoleName } from '@plvs/graphql/generated'
import { MatchTeamForRenderController } from '../../match/types/matchRenderController.types'
import {
  ChatComponentProps,
  ChatComponentReturn,
} from '../types/chatRenderController.types'
import { UserRolesForMatchLobbyRenderController } from '../../MatchLobbyRenderController.types'

const determineChatRole = ({
  userRoles,
  myTeams,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  myTeams: MatchTeamForRenderController[]
}): ChatRole => {
  const { ids, schoolIds } = getContextualResourceIdsFromTeams(myTeams)
  const isCoachOfAnyTeamOrSchool = hasRoleForResource(
    userRoles,
    [...ids, ...schoolIds],
    UserRoleName.Coach
  )
  const isCaptainOfAnyTeam = isCaptainForResource(userRoles, [...ids])
  const isTeamOwnerOfAnyTeam = isTeamOwnerForResource(userRoles, [...ids])
  // Order matters here. For example, if a user is a captain, they also have a player role on the team.
  // Therefore, we need to check for captain first.
  if (isCoachOfAnyTeamOrSchool) {
    return ChatRole.Coach
  }
  if (isCaptainOfAnyTeam || isTeamOwnerOfAnyTeam) {
    return ChatRole.Captain
  }
  return ChatRole.Player
}

export const determineChatRenderForOrgBasedMatch = ({
  userRoles,
  myTeams,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  myTeams: MatchTeamForRenderController[]
}): ChatComponentReturn => {
  const { ids, schoolIds } = getContextualResourceIdsFromTeams(myTeams)
  const isPlayerOfATeamInMatch = isPlayerForResource(userRoles, [...ids])
  const isCoachOfATeamOrSchoolInMatch = hasRoleForResource(
    userRoles,
    [...ids, ...schoolIds],
    UserRoleName.Coach
  )

  const chatRole = determineChatRole({ userRoles, myTeams })

  return {
    shouldRenderChat: isPlayerOfATeamInMatch || isCoachOfATeamOrSchoolInMatch,
    shouldRenderCoachChat: isCoachOfATeamOrSchoolInMatch,
    chatRole,
  }
}

export const determineChatRenderForNonOrgBasedMatch = ({
  userRoles,
  myTeams,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  myTeams: MatchTeamForRenderController[]
}): ChatComponentReturn => {
  const { ids } = getContextualResourceIdsFromTeams(myTeams)
  // Note that being a player is lowest level of association with a team.
  const isPlayerOfATeamInMatch = isPlayerForResource(userRoles, [...ids])

  const chatRole = determineChatRole({ userRoles, myTeams })

  return {
    shouldRenderChat: isPlayerOfATeamInMatch,
    shouldRenderCoachChat: false,
    chatRole,
  }
}

export const determineChatRender = ({
  userRoles,
  myTeams,
  competitionGroup,
  status,
}: ChatComponentProps): ChatComponentReturn => {
  if (!competitionGroup || status === MatchStatus.Bye) {
    return {
      shouldRenderChat: false,
      shouldRenderCoachChat: false,
      // Player is the lowest level of permissions in chat.
      chatRole: ChatRole.Player,
    }
  }

  const isAdmin = isAdminForSystem(userRoles)
  const isOrgAssociatedCompetitionGroup =
    ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)
  if (isAdmin) {
    return {
      shouldRenderChat: true,
      shouldRenderCoachChat: !!isOrgAssociatedCompetitionGroup,
      chatRole: ChatRole.Admin,
    }
  }

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    return determineChatRenderForOrgBasedMatch({
      userRoles,
      myTeams,
    })
  }

  return determineChatRenderForNonOrgBasedMatch({
    userRoles,
    myTeams,
  })
}
