import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import tz from 'dayjs/plugin/timezone'
import {
  Box,
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { HeroGutter } from '@plvs/rally/components/hero'
import {
  EsportRating,
  useGetLeagueByPublicSlugQuery,
  SeasonStatus,
  useGetLeagueSeasonDataQuery,
} from '@plvs/graphql'
import {
  getCurrentPhase,
  getCurrentSlot,
  removePreseasonPhase,
  sortByStartsAt,
} from '@plvs/utils'
import { isNil, last } from 'ramda'
import { NxTabs, NxTab, NxEmptyState } from '@playvs-inc/nexus-components'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import dayjs from '@plvs/respawn/init/dayjs'

import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { StringParam, useQueryParams } from 'use-query-params'
import { EsrbRating } from '@plvs/respawn/features/match-lobby/esrbRating'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { LeagueDetailsFilters } from '../LeagueDetailsFilters'
import {
  LeagueDetailsTab,
  PhaseFilterType,
  SlotFilterType,
} from '../LeagueDetails.types'
import { LeagueDetailsHero } from '../LeagueDetailsHero'
import {
  getLeagueSponsorLogos,
  getSeasonEnrollmentStatus,
} from '../ExploreHelpers'
import { useLeagueDetails } from './LeagueDetailsProvider'

dayjs.extend(tz)

interface Props {
  isPublic: boolean
  publicSlug: string
}

const tabs: {
  label: string
  path: string
  id?: string
  value: LeagueDetailsTab
}[] = [
  {
    label: 'Overview',
    path: `overview`,
    id: 'league-tabs-overview',
    value: LeagueDetailsTab.Overview,
  },
  {
    label: 'Standings',
    path: `standings`,
    id: 'league-tabs-standings',
    value: LeagueDetailsTab.Standings,
  },
  {
    label: 'Matches',
    path: `matches`,
    id: 'league-tabs-matches',
    value: LeagueDetailsTab.Matches,
  },
  {
    label: 'Teams List',
    path: `teams`,
    id: 'league-tabs-teams',
    value: LeagueDetailsTab.Teams,
  },
]

export const LeagueDetails: React.FC<Props> = ({ isPublic, publicSlug }) => {
  // state
  const [tabValue, setTabValue] = useState<LeagueDetailsTab>(
    LeagueDetailsTab.Overview
  )

  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const handleTabChange = (_: any, newValue: LeagueDetailsTab): void => {
    setTabValue(newValue)
    const tabData = tabs.find((x) => x.value === newValue)
    if (tabData && tabData.path) {
      navigate(tabData.path)
    }
  }
  useEffect(() => {
    const tabPath = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    )
    const currentTab = tabs.find((tab) => tab.path === tabPath)
    if (currentTab) {
      setTabValue(currentTab.value)
    }
  }, [tabs])

  // custom hooks
  const {
    phase,
    setPhase,
    team,
    setSlotFilter,
    slotFilter,
    metaseasonId,
  } = useLeagueDetails()

  const {
    loading: schoolLeagueInfoContextLoading,
    metaseason,
    metaseasons,
    setLeague,
    setMetaseason,
  } = useSchoolLeagueInfoContext()
  const [queryFilters, setQueryFilters] = useQueryParams({
    queryMetaseasonId: StringParam,
    queryPhaseId: StringParam,
    querySlotId: StringParam,
  })
  const { queryPhaseId, querySlotId } = queryFilters

  const metaseasonName = metaseason?.name ?? ''

  const { data, loading } = useGetLeagueByPublicSlugQuery({
    skip: !publicSlug,
    variables: { publicSlug },
  })

  const leagueId = data?.getLeagueByPublicSlug?.id ?? ''

  useEffect(() => {
    if (leagueId) {
      setLeague(leagueId)
    }
  }, [leagueId])

  useEffect(() => {
    return () => {
      /* istanbul ignore next */
      setLeague('')
    }
  }, [])

  const {
    data: leagueSeasonData,
    loading: leagueSeasonDataLoading,
  } = useGetLeagueSeasonDataQuery({
    skip: !leagueId || !metaseasonId || schoolLeagueInfoContextLoading,
    variables: { leagueId, metaseasonId },
  })

  const selectedLeague = data?.getLeagueByPublicSlug
  const competitionGroup = selectedLeague?.competitionGroup
  const hasActiveSeasons = selectedLeague?.seasons?.some(
    (s) => s.status === SeasonStatus.Active
  )

  // Derived data
  const selectedLeagueName =
    selectedLeague?.displayName || selectedLeague?.name || ''

  const esportSlug = selectedLeague?.esport?.slug
  const esportRating = selectedLeague?.esport?.rating ?? EsportRating.General

  const season = leagueSeasonData?.league?.seasonByMetaseasonId

  const seasonPhases = sortByStartsAt(season?.activePhases ?? []).filter(
    removePreseasonPhase
  )

  const lastPhase = last(seasonPhases)

  const enrollmentStatus = getSeasonEnrollmentStatus({
    teamRegistrationEndsAt: season?.teamRegistrationEndsAt ?? undefined,
    registrationStartsAt: season?.registrationStartsAt,
  })

  const esportName = selectedLeague?.esport.name || ''

  useEffect(() => {
    if (metaseasonId && !loading) {
      setMetaseason(metaseasonId)
    }
  }, [loading])

  useEffect(() => {
    if (!leagueSeasonDataLoading && metaseasonId) {
      const currentPhase =
        seasonPhases.find((x) => x.id === queryPhaseId) ||
        seasonPhases.find(getCurrentPhase) ||
        lastPhase
      if (currentPhase) {
        setPhase(currentPhase as PhaseFilterType)
        setQueryFilters({ ...queryFilters, queryPhaseId: currentPhase?.id })
      } else {
        setPhase(undefined)
        setQueryFilters({ ...queryFilters, queryPhaseId: undefined })
      }
    }
  }, [metaseasonId, leagueSeasonDataLoading])

  useEffect(() => {
    if (phase) {
      const currentSlot =
        phase.slots?.find((x) => x.id === querySlotId) ||
        phase.slots?.find(getCurrentSlot) ||
        last(phase?.slots ?? [])
      if (currentSlot) {
        setSlotFilter(currentSlot as SlotFilterType)
        setQueryFilters({ ...queryFilters, querySlotId: currentSlot?.id })
      } else {
        setSlotFilter(undefined)
        setQueryFilters({ ...queryFilters, querySlotId: undefined })
      }
    } else {
      // always reset slot filter if phase is not selected
      setSlotFilter(undefined)
      setQueryFilters({ ...queryFilters, querySlotId: undefined })
    }
  }, [phase])

  const {
    bannerLogo,
    rightSideLogo,
    mobileRightSideLogo,
  } = getLeagueSponsorLogos({
    images: season?.sponsorshipImages ?? [],
    isDarkMode,
  })

  return (
    <WaitTillLoaded
      loading={
        loading || schoolLeagueInfoContextLoading || leagueSeasonDataLoading
      }
      showSpinnerWhileLoading
    >
      {isNil(selectedLeague) || selectedLeague.isHidden || !hasActiveSeasons ? (
        <Box mt={2}>
          <PageContentGutter>
            <NxEmptyState
              spot={
                <NxSpot
                  domain="highAlert"
                  height={200}
                  size="large"
                  variant="secondary"
                  width={200}
                />
              }
              subtitle="The league you are trying to find does not exist or may have moved. Please double check the URL."
              title="League Not Found"
            />
          </PageContentGutter>
        </Box>
      ) : (
        <>
          <HeroGutter mb={2} mt={0}>
            <LeagueDetailsHero
              competitionGroup={competitionGroup}
              enrollmentStatusLabel={enrollmentStatus.label}
              enrollmentStatusVariant={enrollmentStatus.variant}
              esportName={esportName}
              esportRating={esportRating}
              esportSlug={esportSlug}
              isPublic={isPublic}
              metaseasonName={metaseasonName}
              mobileRightSideSponsorLogo={mobileRightSideLogo}
              rightSideLogo={rightSideLogo}
              selectedLeagueName={selectedLeagueName}
              sponsorAvatar={bannerLogo}
            />
          </HeroGutter>
          <PageContentGutter>
            <Box alignItems="center" display="flex" mb={2}>
              <NxTabs onChange={handleTabChange} size="large" value={tabValue}>
                {tabs.map((tab) => (
                  <NxTab
                    key={tab.id}
                    data-path={tab.path}
                    id={tab.id}
                    label={tab.label}
                    selected={tabValue === tab.value}
                    value={tab.value}
                  />
                ))}
              </NxTabs>
            </Box>
            <Box alignItems="center" display="flex" mb={2}>
              <LeagueDetailsFilters
                metaseasonOptions={metaseasons}
                phaseOptions={seasonPhases as PhaseFilterType[]}
                selectedMetaseason={metaseason}
                selectedPhase={phase}
                selectedSlot={slotFilter}
                setMetaseason={setMetaseason}
                setPhase={setPhase}
                setSlot={setSlotFilter}
                slotOptions={phase?.slots ?? []}
                tab={tabValue}
                team={team}
              />
            </Box>

            {/* Renders child routes for league details */}
            <Outlet />

            {esportSlug && <EsrbRating esportSlug={esportSlug} mt={5} />}
          </PageContentGutter>
        </>
      )}
    </WaitTillLoaded>
  )
}
